import { MouseEvent, memo, useState } from 'react';
import { ActionIcon, Checkbox, Flex, useMantineTheme } from '@mantine/core';
import cn from 'classnames';
import AccActionIcon from 'Components/AccActionIcon/AccActionIcon';
import { AccFastIcon } from 'Components/AccFastIcon';
import AccTag from 'Components/AccTag';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import AccessControl from 'Components/AccessControl';
import {
  KEYWORDS_PAGE,
  LinkIconWithFolderFilter,
  OVERVIEW_PAGE,
} from 'Components/Filters/LinkWithFolders/linkWithFolders';
import { RowIndentations } from 'Components/Table/TreeTable';
import { TableScrollShadow } from 'Components/Table/VirtualizedTable';
import AccText from 'Components/Text/AccText';
import {
  useDeleteDynamicTagRulesetFromTagMutation,
  useDeleteStaticTagMutation,
  useDynamicTagRulesetFromTagLazyQuery,
  useStaticTagInformationLazyQuery,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import { useDomainId } from 'Hooks/data/domain/useQueryDomainInfo';
import SERPCell from 'Pages/Keywords/Groupings/ViewMode/components/cells/SERPCell';
import { useGroupingStore } from 'Pages/Keywords/Groupings/support/groupingsStore';
import { useInvalidateKeywordCache } from 'Utilities/Graphql/invalidateCache';
import { getIndentClassNames } from 'Utilities/Table/Tree/treeUtils';
import { propsIsEqualComparison } from 'Utilities/compare';
import { t, tct } from 'Utilities/i18n';
import { sleep } from 'Utilities/promise';
import {
  IconChevronDown,
  IconChevronRight,
  IconEdit,
  IconFolder,
  IconFolderPlus,
  IconTags,
  IconTrash,
} from 'icons/tag-cloud';
import IconTagPlus from 'icons/tag-plus.svg';
import { useIsSelected } from '../../../EditMode/support/state';
import { FolderCellProps } from '../../utils/useFoldersTableConfig';
import styles from './cells.module.scss';

const PathCell = (props: FolderCellProps) => {
  const { rootNode, isOpen, onToggle, classes, isHovered: hovered } = props;

  const { showModal } = useModal();
  const domainId = useDomainId() as string;
  const [getDynamicTagData] = useDynamicTagRulesetFromTagLazyQuery();
  const [getStaticTagData] = useStaticTagInformationLazyQuery();
  const [deleteDynamicTag] = useDeleteDynamicTagRulesetFromTagMutation();
  const [deleteStaticTag] = useDeleteStaticTagMutation();
  const theme = useMantineTheme();

  const invalidateKeywordCache = useInvalidateKeywordCache();
  const groupingStore = useGroupingStore();

  const handleAddTag = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    showModal({
      modalType: 'SelectTagType',
      modalTheme: 'light',
      modalProps: {
        folderId: rootNode.path,
      },
    });
  };

  const handleEditDynamicTag = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const { data } = await getDynamicTagData({
      variables: { domainId: parseInt(domainId), tag: rootNode.name },
      fetchPolicy: 'network-only',
    });
    const dynamicTagData = data?.dynamicTagRulesetFromTag;
    showModal({
      modalType: 'CreateOrEditDynamicTag',
      modalTheme: 'light',
      modalProps: {
        isEdit: true,
        initialValues: {
          id: dynamicTagData?.id,
          folder: dynamicTagData?.folderPath,
          tag: dynamicTagData?.tag,
          rawRuleset: dynamicTagData?.rawRuleset,
        },
        updatedAt: dynamicTagData?.updatedAt,
        createdBy: dynamicTagData?.createdBy,
      },
    });
  };

  const handleCloneDynamicTag = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const { data } = await getDynamicTagData({
      variables: { domainId: parseInt(domainId), tag: rootNode.name },
      fetchPolicy: 'network-only',
    });
    const dynamicTagData = data?.dynamicTagRulesetFromTag;
    showModal({
      modalType: 'CreateOrEditDynamicTag',
      modalTheme: 'light',
      modalProps: {
        isEdit: false,
        initialValues: {
          folder: dynamicTagData?.folderPath,
          tag: `${dynamicTagData?.tag} ${t('(copy)')}`,
          rawRuleset: dynamicTagData?.rawRuleset,
        },
      },
    });
  };

  const handleDeleteDynamicTag = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    showModal({
      modalType: 'Confirmation',
      modalTheme: 'light',
      modalProps: {
        title: t('Delete dynamic tag?'),
        lockDuration: 0,
        description: tct(
          'The dynamic tag "[tag]" will be permanently deleted and be removed from all matching keywords.',
          { tag: rootNode.name },
        ),
        confirmLabel: t('Delete'),
        cancelLabel: t('Cancel'),
        action: async () => {
          await deleteDynamicTag({
            variables: { tag: rootNode.name, domainId: parseInt(domainId) },
          });

          invalidateKeywordCache();

          if (rootNode?.keywords && rootNode?.keywords > 1000) {
            // Sleep some more since dynamic tags take longer to delete when there is many keywords affected
            await sleep(1000);
          }
          await groupingStore.refetch(false); // Dont refetch immediately as dynamic tags takes small amount of time to delete
        },
      },
    });
  };

  const handleDeleteStaticTag = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    showModal({
      modalType: 'Confirmation',
      modalTheme: 'light',
      modalProps: {
        title: t('Delete static tag?'),
        lockDuration: 0,
        description: tct(
          'The static tag "[tag]" will be permanently deleted and be removed from all keywords.',
          { tag: rootNode.name },
        ),
        confirmLabel: t('Delete'),
        cancelLabel: t('Cancel'),
        action: async () => {
          await deleteStaticTag({
            variables: { tag: rootNode.name, domainId: parseInt(domainId) },
          });
          invalidateKeywordCache();
          await groupingStore.refetch(false); // Dont refetch immediately as dynamic tags takes small amount of time to delete
        },
      },
    });
  };

  const handleEditStaticTag = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const { data } = await getStaticTagData({
      variables: { domainId: parseInt(domainId as string), tag: rootNode.name },
      fetchPolicy: 'network-only',
    });
    const staticTagData = data?.staticTagInformation;
    showModal({
      modalType: 'CreateOrEditStaticTag',
      modalTheme: 'light',
      modalProps: {
        initialValues: {
          tag: staticTagData?.tag,
          folder: staticTagData?.folderPath,
          keywords: staticTagData?.keywords,
        },
      },
    });
  };

  const gray = theme.other.iconInactive;
  const iconColor = theme.colors.snorlax[4];

  const [serpCellOpened, setSerpCellOpened] = useState(false);

  const isSelected = useIsSelected(rootNode[props.expandDataKey], props.expandDataKey);
  const indentClassNames = getIndentClassNames(rootNode, isOpen);
  return (
    <Flex
      className={cn(classes.noWrap, styles.pathCell, { [styles.clickable]: rootNode?.is_folder })}
      onClick={(e) => {
        e.stopPropagation();
        if (rootNode?.is_folder) {
          onToggle(rootNode);
        }
      }}
    >
      <AccTooltip tooltip={t('No keywords to compare')} disable={rootNode?.keywords !== 0}>
        <Checkbox
          className={classes.favicon}
          px={8}
          disabled={rootNode?.keywords === 0}
          checked={isSelected}
          key={isSelected?.toString()}
          onChange={(event) => props.updateSelectedNode(props.rootNode, event)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </AccTooltip>
      <RowIndentations
        depth={rootNode.deepness}
        expandIconWidth="28px"
        className={indentClassNames}
        hideConnectors={rootNode.hiddenConnectors}
      />
      {!!rootNode.is_folder &&
        (isOpen ? (
          <AccFastIcon src={IconChevronDown} size={18} color={gray} />
        ) : (
          <AccFastIcon src={IconChevronRight} size={18} color={gray} />
        ))}
      <Flex
        className={styles.iconAndPathContainer}
        align="center"
        gap={6}
        mr="auto"
        data-testid={`table-row-name-${rootNode.name}`}
      >
        {rootNode?.is_folder ? (
          <>
            <ActionIcon
              className={isOpen && rootNode?.children?.length ? 'connectorElement' : ''}
              pos="relative"
              aria-pressed={isOpen}
              aria-label={isOpen ? t('toggle row closed') : t('toggle row open')}
              variant="transparent"
            >
              <AccFastIcon src={IconFolder} size={26} color={gray} />
            </ActionIcon>
            <AccText size="sm" fw={600} overflowEllipsis>
              {rootNode.name}{' '}
              <AccText inline c={gray} fw={400}>
                {rootNode?.n_children ? `(${rootNode?.n_children})` : '(0)'}
              </AccText>
            </AccText>
          </>
        ) : (
          <>
            <AccTag tag={rootNode.name} dynamic={rootNode.is_dynamic} maxWidth="100%" />
          </>
        )}
      </Flex>
      {(hovered || serpCellOpened) && rootNode.is_folder ? (
        <AccessControl>
          <Flex>
            <AccTooltip tooltip={t('Create tag')} old>
              <AccActionIcon variant="subtle" onClick={handleAddTag}>
                <AccFastIcon src={IconTagPlus} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>
            <AccTooltip tooltip={t('Create folder')} old>
              <AccActionIcon
                w={18}
                variant="subtle"
                onClick={(e) => {
                  e.stopPropagation();
                  props?.onDragEnd?.(
                    e,
                    t('You can only add folders in edit mode. Do you want to enter edit mode?'),
                  );
                }}
              >
                <AccFastIcon src={IconFolderPlus} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>
          </Flex>
        </AccessControl>
      ) : null}
      {(hovered || serpCellOpened) && !rootNode.is_folder ? (
        <AccessControl>
          <Flex>
            <AccTooltip
              tooltip={rootNode.is_dynamic ? t('Edit dynamic tag') : t('Edit static tag')}
              old
            >
              <AccActionIcon
                variant="subtle"
                onClick={rootNode.is_dynamic ? handleEditDynamicTag : handleEditStaticTag}
              >
                <AccFastIcon src={IconEdit} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>
            {rootNode.is_dynamic ? (
              <AccTooltip tooltip={t('Clone dynamic tag')} old>
                <AccActionIcon variant="subtle" onClick={handleCloneDynamicTag}>
                  <AccFastIcon src={IconTags} size={18} color={iconColor} />
                </AccActionIcon>
              </AccTooltip>
            ) : null}
            <AccTooltip
              tooltip={rootNode.is_dynamic ? t('Delete dynamic tag') : t('Delete static tag')}
              old
            >
              <AccActionIcon
                variant="subtle"
                onClick={rootNode.is_dynamic ? handleDeleteDynamicTag : handleDeleteStaticTag}
              >
                <AccFastIcon src={IconTrash} size={18} color={iconColor} />
              </AccActionIcon>
            </AccTooltip>
          </Flex>
        </AccessControl>
      ) : null}
      {hovered || serpCellOpened ? (
        <Flex key={'cell-actions'} align={'center'}>
          <LinkIconWithFolderFilter
            path={props.rootNode.path}
            isFolder={props.rootNode.is_folder}
            name={props.rootNode.name}
            pageToGoTo={OVERVIEW_PAGE}
            tooltipText={
              props.rootNode.is_folder
                ? t('Go to dashboard and filter by folder')
                : t('Go to dashboard list and filter by tag')
            }
          />
          <LinkIconWithFolderFilter
            path={props.rootNode.path}
            isFolder={props.rootNode.is_folder}
            name={props.rootNode.name}
            pageToGoTo={KEYWORDS_PAGE}
            tooltipText={
              props.rootNode.is_folder
                ? t('Go to keywords list and filter by folder')
                : t('Go to keywords list and filter by tag')
            }
          />
          <AccTooltip tooltip={t('Show SERP features')} disable={serpCellOpened}>
            {/* stopPropagation avoids folder expand / disband.  */}
            <div onClick={(e) => e.stopPropagation()}>
              <SERPCell
                path={props.rootNode.path}
                isOpen={serpCellOpened}
                setIsOpen={setSerpCellOpened}
              />
            </div>
          </AccTooltip>
        </Flex>
      ) : null}
      <TableScrollShadow />
    </Flex>
  );
};

export default memo(PathCell, propsIsEqualComparison);
