/* eslint-disable import/no-unused-modules */
import { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import isEmpty from 'lodash/isEmpty';
import AccButton from 'Components/AccButton/AccButton';
import { Gutter, GutterSm } from 'Components/Gutter';
import HelpGuideButton from 'Components/HelpGuideButton';
import { ModalTypes } from 'Components/Modal/Content';
import SimpleModal from 'Components/SimpleModal';
import AccText from 'Components/Text/AccText';
import { EnableOveruseArea, useEnableOveruseMutation } from 'Ghql';
import { useAccountMaxKeywords } from 'Hooks/useMaxKeywords';
import toast from 'Hooks/useToast';
import { useOrganizationInfo } from 'Selectors/OrganizationSelector';
import { EventName, useMixpanel } from 'Utilities/Analytics/mixpanel';
import { useHistory } from 'Utilities/Router/withRouter';
import { ErrorsFromServer } from 'Utilities/errors';
import { t, tct } from 'Utilities/i18n';

const MODAL_ZINDEX = 999; // All modals should have the same z-index

export const UpgradePlanModal = ({
  defaultOpened = false,
  onClose,
  trackedLimit = false,
}: {
  defaultOpened?: boolean;
  onClose?: () => void;
  trackedLimit?: boolean;
}) => {
  const [opened, setOpened] = useState(defaultOpened);
  const trackEvent = useMixpanel();

  useEffect(() => {
    if (opened) {
      trackEvent(
        trackedLimit
          ? EventName.UpgradePlanTrackedLimitModalOpen
          : EventName.UpgradePlanActiveLimitModalOpen,
      );
    }
  }, [opened]);

  const maxKeywords = useAccountMaxKeywords();

  const intercom = useIntercom();
  const history = useHistory();

  return (
    <SimpleModal
      title={t('You\'ve Reached Your Keyword Plan Limit - Keep Going!')}
      opened={opened}
      zIndex={MODAL_ZINDEX}
      onClose={() => {
        onClose?.();
      }}
    >
      <AccText>
        {tct(
          'Congratulations, you’ve reached your account limit of [maxKeywords] keywords! But don’t let that stop you - easily upgrade your plan to add more keywords.',
          { maxKeywords },
        )}
        {trackedLimit && (
          <>
            <GutterSm />
            {t('You reached the limit because your account has deleted keywords today.')}
          </>
        )}
        <GutterSm />
        {t(
          'If you have any questions, feel free to contact our support team or your dedicated customer success manager.',
        )}
      </AccText>

      <Gutter />

      <SimpleModal.Footer>
        <AccButton variant="tertiary" onClick={() => intercom.isOpen || intercom.show()}>
          {t('Contact support')}
        </AccButton>

        <AccButton
          variant="tertiary"
          onClick={() => {
            setOpened(false);
            onClose?.();
          }}
        >
          {t('Cancel')}
        </AccButton>

        <AccButton
          variant="primary"
          onClick={() => {
            const modalType: ModalTypes = 'UpgradePlanModal';
            trackEvent(EventName.ModalSubmit, {
              'Modal Type': modalType,
              'Tracked Limit': trackedLimit,
            });
            history.push('/billing/package/select');
          }}
        >
          {t('View plans')}
        </AccButton>
      </SimpleModal.Footer>
    </SimpleModal>
  );
};

export const RequestUpgradePlanModal = ({
  defaultOpened = false,
  onClose,
  trackedLimit = false,
}: {
  defaultOpened?: boolean;
  onClose?: () => void;
  trackedLimit?: boolean;
}) => {
  const [opened, setOpened] = useState(defaultOpened);
  const maxKeywords = useAccountMaxKeywords();

  const trackEvent = useMixpanel();

  useEffect(() => {
    if (opened) {
      trackEvent(
        trackedLimit
          ? EventName.RequestUpgradePlanTrackedLimitModalOpen
          : EventName.RequestUpgradePlanActiveLimitModalOpen,
      );
    }
  }, [opened]);

  return (
    <SimpleModal
      title={t('You\'ve Reached Your Keyword Plan Limit - Keep Going!')}
      opened={opened}
      zIndex={MODAL_ZINDEX}
      onClose={() => {
        onClose?.();
      }}
    >
      <AccText>
        {tct(
          'Congratulations, you’ve reached your account limit of [maxKeywords] keywords! But don’t let that stop you - easily upgrade your plan to add more keywords.',
          { maxKeywords },
        )}
        {trackedLimit && (
          <>
            <GutterSm />
            {t('You reached the limit because your account has deleted keywords today.')}
          </>
        )}
        <GutterSm />
        {t('Contact your account administrator to upgrade your plan.')}
      </AccText>

      <Gutter />

      <SimpleModal.Footer>
        <AccButton
          variant="tertiary"
          onClick={() => {
            setOpened(false);
            onClose?.();
          }}
        >
          {t('Close')}
        </AccButton>
      </SimpleModal.Footer>
    </SimpleModal>
  );
};

export const EnableOveruseModal = ({
  area,
  defaultOpened = false,
  onEnableOveruseSuccess,
  onClose,
  organizationId,
  trackedLimit = false,
}: {
  area: EnableOveruseArea;
  defaultOpened?: boolean;
  onEnableOveruseSuccess?: () => void;
  onClose?: () => void;
  organizationId?: string;
  trackedLimit?: boolean;
}) => {
  const [opened, setOpened] = useState(defaultOpened);

  const [_enableOveruse, { loading }] = useEnableOveruseMutation();

  const organizatonInfo = useOrganizationInfo();
  const maxKeywords = useAccountMaxKeywords();

  const trackEvent = useMixpanel();

  useEffect(() => {
    if (opened) {
      trackEvent(
        trackedLimit
          ? EventName.EnableOveruseTrackedLimitModalOpen
          : EventName.EnableOveruseActiveLimitModalOpen,
        { Area: area },
      );
    }
  }, [opened]);

  const enableOveruse = async () => {
    if (!organizatonInfo.organization?.id) {
      return t('Something went wrong');
    }
    try {
      const result = await _enableOveruse({
        variables: {
          input: {
            id: organizationId ?? organizatonInfo.organization.id,
            area,
          },
        },
      });
      const errors = (result.data?.enableOveruse?.errors ?? null) as ErrorsFromServer | null;
      if (errors !== null && !isEmpty(errors)) {
        return errors[0].messages[0];
      }
      return null;
    } catch (e) {
      return t('Something went wrong');
    }
  };

  return (
    <SimpleModal
      title={t('You\'ve Reached Your Keyword Plan Limit - Keep Going!')}
      opened={opened}
      zIndex={MODAL_ZINDEX}
      onClose={() => {
        onClose?.();
      }}
    >
      <AccText>
        {tct(
          'You have reached your keyword plan limit of [maxKeywords]. But don’t worry, this shouldn’t stop you!',
          { maxKeywords },
        )}
        <GutterSm />
        {t(
          'AccuRanker allows you to exceed your keyword plan limit so you can continue optimising without interruption. You’ll only be charged for the extra keywords that go beyond your current plan’s allowance.',
        )}
      </AccText>

      {trackedLimit && (
        <>
          <GutterSm />
          {t('You reached the limit because your account has deleted keywords today.')}
        </>
      )}
      <GutterSm />

      <AccText nowrap>
        {tct(
          'By clicking “Approve”, you acknowledge our [link:terms and conditions] and approve the changes to your subscription.',
          {
            link: <a href="https://www.accuranker.com/terms/" target="_blank" rel="noreferrer" />,
          },
        )}
      </AccText>

      <Gutter />

      <SimpleModal.Footer>
        <HelpGuideButton helpguideLink="https://www.accuranker.com/help/account/dynamic-keyword-usage" />

        <AccButton
          variant="tertiary"
          onClick={() => {
            setOpened(false);
            onClose?.();
          }}
        >
          {t('Cancel')}
        </AccButton>

        <AccButton
          variant="primary"
          disabled={loading}
          onClick={async () => {
            const modalType: ModalTypes = 'EnableOveruseModal';
            trackEvent(EventName.ModalSubmit, {
              'Modal Type': modalType,
              'Tracked Limit': trackedLimit,
            });

            // Enable overuse
            const error = await enableOveruse();
            if (error !== null) {
              toast.error(error);
              return;
            }

            // Resubmit
            setOpened(false);
            await onEnableOveruseSuccess?.();
          }}
        >
          {t('Approve')}
        </AccButton>
      </SimpleModal.Footer>
    </SimpleModal>
  );
};

export const RequestEnableOveruseModal = ({
  defaultOpened = false,
  onClose,
  trackedLimit = false,
}: {
  defaultOpened?: boolean;
  onClose?: () => void;
  trackedLimit?: boolean;
}) => {
  const [opened, setOpened] = useState(defaultOpened);
  const maxKeywords = useAccountMaxKeywords();

  const trackEvent = useMixpanel();

  useEffect(() => {
    if (opened) {
      trackEvent(
        trackedLimit
          ? EventName.RequestEnableOveruseTrackedLimitModalOpen
          : EventName.RequestEnableOveruseActiveLimitModalOpen,
      );
    }
  }, [opened]);

  return (
    <SimpleModal
      title={t('You\'ve Reached Your Keyword Plan Limit - Keep Going!')}
      opened={opened}
      zIndex={MODAL_ZINDEX}
      onClose={() => {
        onClose?.();
      }}
    >
      <AccText>
        {tct(
          'You have reached your keyword plan limit of [maxKeywords]. But don’t worry, this shouldn’t stop you!',
          { maxKeywords },
        )}
        <GutterSm />
        {t(
          'Contact your account administrator to allow keyword overuse or upgrade to the next plan.',
        )}
      </AccText>

      {trackedLimit && (
        <>
          <GutterSm />
          {t('You reached the limit because your account has deleted keywords today.')}
        </>
      )}

      <Gutter />

      <SimpleModal.Footer>
        <AccButton
          variant="tertiary"
          onClick={() => {
            setOpened(false);
            onClose?.();
          }}
        >
          {t('Close')}
        </AccButton>
      </SimpleModal.Footer>
    </SimpleModal>
  );
};
