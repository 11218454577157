import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  Decimal: { input: string; output: string };
  GenericScalar: { input: any; output: any };
  JSONString: { input: string; output: string };
  UUID: { input: string; output: string };
};

/** This will accept a pending access request. */
export type AcceptMultiAccountRequest = {
  __typename?: 'AcceptMultiAccountRequest';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type AcceptMultiAccountRequestInput = {
  id: Scalars['ID']['input'];
};

export type AcceptedOveruseNode = {
  __typename?: 'AcceptedOveruseNode';
  multiAccountLink?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type AccuApiCreditNode = {
  __typename?: 'AccuApiCreditNode';
  creditsStats?: Maybe<AccuApiCreditStatNode>;
};

export type AccuApiCreditStatNode = {
  __typename?: 'AccuApiCreditStatNode';
  credits?: Maybe<Scalars['BigInt']['output']>;
  getLimit?: Maybe<Scalars['Int']['output']>;
  getLimitRemaning?: Maybe<Scalars['Int']['output']>;
  planCredits?: Maybe<Scalars['BigInt']['output']>;
  postLimit?: Maybe<Scalars['Int']['output']>;
  postLimitRemaning?: Maybe<Scalars['Int']['output']>;
};

export type AccuApiSearchHistoryNode = {
  __typename?: 'AccuApiSearchHistoryNode';
  history?: Maybe<Scalars['GenericScalar']['output']>;
};

export type AccuApiSpendingGraphNode = {
  __typename?: 'AccuApiSpendingGraphNode';
  endDate?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['GenericScalar']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type AccuApiTokenNode = {
  __typename?: 'AccuApiTokenNode';
  token?: Maybe<Scalars['String']['output']>;
};

export type AccurankerAnalytics = {
  __typename?: 'AccurankerAnalytics';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type AccurankerAnalyticsInput = {
  browser: Scalars['String']['input'];
  browserVersion: Scalars['String']['input'];
  deviceType: Scalars['String']['input'];
  domainId?: InputMaybe<Scalars['String']['input']>;
  eventName: Scalars['String']['input'];
  fp?: InputMaybe<Scalars['String']['input']>;
  isGroupView?: InputMaybe<Scalars['Boolean']['input']>;
  landingPage: Scalars['String']['input'];
  os: Scalars['String']['input'];
  otherProperties?: InputMaybe<Array<AccurankerAnalyticsOtherPropertyInput>>;
  screenHeight: Scalars['Int']['input'];
  screenWidth: Scalars['Int']['input'];
  windowHeight: Scalars['Int']['input'];
  windowWidth: Scalars['Int']['input'];
};

export type AccurankerAnalyticsOtherPropertyInput = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['GenericScalar']['input']>;
};

export enum AccurankerMessagesMessageTextIdChoices {
  /** We tried sending: {} to: {} however it failed. Please make sure you are using valid emails as report recipients. */
  A_1 = 'A_1',
  /** We tried sending an email to: {} however it failed. */
  A_2 = 'A_2',
  /** The bulk import of {} has been completed successfully. */
  A_3 = 'A_3',
  /** The bulk import of {} failed with error {}. Please contact support if you need help. */
  A_4 = 'A_4',
  /** Your current plan does not allow any more keywords */
  A_5 = 'A_5',
  /** {} */
  A_6 = 'A_6',
  /** The following keywords already exists, and can therefore not be added to the domain. {} */
  A_7 = 'A_7',
}

export enum AccurankerMessagesServiceMessageMessageTypeIdChoices {
  A_1 = 'A_1',
  /** Scrapers are delayed for {SEARCH_ENGINE} {SEARCH_TYPE} in {COUNTRY}. */
  A_2 = 'A_2',
  /** Google has made a critical update. */
  A_3 = 'A_3',
}

export type AddAdobeMarketingAccount = {
  __typename?: 'AddAdobeMarketingAccount';
  domain?: Maybe<DomainNode>;
};

export type AddAdobeMarketingAccountInput = {
  connectionId: Scalars['String']['input'];
  domainId: Scalars['String']['input'];
  suiteId: Scalars['String']['input'];
};

export type AddAdobeMarketingConnection = {
  __typename?: 'AddAdobeMarketingConnection';
  connection?: Maybe<AdobeMarketingConnectionNode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type AddAdobeMarketingConnectionInput = {
  description: Scalars['String']['input'];
  secret: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type AddAffiliate = {
  __typename?: 'AddAffiliate';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type AddAffiliateInput = {
  contactId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  paypalEmail: Scalars['String']['input'];
};

/**
 * ```graphql
 * mutation {
 *   addClient(input:{organization:16 name:"test test"}) {
 *     errors {
 *       field
 *       messages
 *     }
 *     client {
 *       id
 *     }
 *   }
 * }
 * ```
 */
export type AddClient = {
  __typename?: 'AddClient';
  client?: Maybe<ClientNode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

/** Based on ClientForm */
export type AddClientInput = {
  name: Scalars['String']['input'];
  organization: Scalars['ID']['input'];
};

export type AddCompetitor = {
  __typename?: 'AddCompetitor';
  competitor?: Maybe<CompetitorNode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type AddCompetitorInput = {
  competitorForDomain: Scalars['ID']['input'];
  displayName?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  facebookProfile?: InputMaybe<Scalars['String']['input']>;
  googleBusinessNameList: Array<InputMaybe<Scalars['String']['input']>>;
  instagramProfile?: InputMaybe<Scalars['String']['input']>;
  linkedinProfile?: InputMaybe<Scalars['String']['input']>;
  tiktokProfile?: InputMaybe<Scalars['String']['input']>;
  twitterHandle?: InputMaybe<Scalars['String']['input']>;
};

export type AddDemoContent = {
  __typename?: 'AddDemoContent';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type AddDemoContentInput = {
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  organizations?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type AddDomain = {
  __typename?: 'AddDomain';
  domain?: Maybe<DomainNode>;
  errors?: Maybe<Array<Maybe<Error>>>;
  existingDomainId?: Maybe<Scalars['Int']['output']>;
};

/** Based on AddDomainForm */
export type AddDomainInput = {
  client: Scalars['ID']['input'];
  defaultSearchsettings?: InputMaybe<Array<InputMaybe<DefaultSearchSettingsInput>>>;
  displayName: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  exactMatch: Scalars['Boolean']['input'];
  facebookProfile: Scalars['String']['input'];
  googleBusinessNameList: Array<InputMaybe<Scalars['String']['input']>>;
  googleOauthConnectionYoutube?: InputMaybe<Scalars['ID']['input']>;
  includeSubdomains: Scalars['Boolean']['input'];
  instagramProfile: Scalars['String']['input'];
  linkedinProfile: Scalars['String']['input'];
  shareOfVoicePercentage: Scalars['Boolean']['input'];
  tiktokProfile: Scalars['String']['input'];
  twitterHandle: Scalars['String']['input'];
};

export type AddDynamicTagRuleset = {
  __typename?: 'AddDynamicTagRuleset';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type AddDynamicTagRulesetInput = {
  domainId: Scalars['ID']['input'];
  folderPath?: InputMaybe<Scalars['String']['input']>;
  rawRuleset: Scalars['JSONString']['input'];
  tag: Scalars['String']['input'];
};

export type AddGoogleAnalyticsAccount = {
  __typename?: 'AddGoogleAnalyticsAccount';
  domain?: Maybe<DomainNode>;
};

export type AddGoogleAnalyticsAccountInput = {
  connectionId: Scalars['String']['input'];
  domainId: Scalars['String']['input'];
  gaAccountId: Scalars['String']['input'];
  gaProfileId?: InputMaybe<Scalars['String']['input']>;
  gaPropertyId: Scalars['String']['input'];
};

/**
 * ```graphql
 * mutation {
 *   addGoogleAccount(input: {code:"4/RlhhEFXD8lLZq6IHZJmBEfn0wrwxk1r5D9kXSGctx3g", description:"beskrivelse" redirectUri: "http://localhost.dk:8000/org/googleoauth/callback/"}) {
 *     connection {
 *       id
 *     }
 *   }
 * }
 * ```
 */
export type AddGoogleConnection = {
  __typename?: 'AddGoogleConnection';
  connection?: Maybe<GoogleOAuthConnectionNode>;
};

export type AddGoogleConnectionInput = {
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
  /** old is: http://localhost.dk:8000/org/googleoauth/callback/ */
  redirectUri: Scalars['String']['input'];
  /** 1 = analytics, 2 = drive, 3 = hubspot */
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type AddGoogleSearchConsoleAccount = {
  __typename?: 'AddGoogleSearchConsoleAccount';
  domain?: Maybe<DomainNode>;
};

export type AddGoogleSearchConsoleAccountInput = {
  connectionId: Scalars['String']['input'];
  domainId: Scalars['String']['input'];
  websiteUrl: Scalars['String']['input'];
};

export type AddKeywordResearchDomainSearch = {
  __typename?: 'AddKeywordResearchDomainSearch';
  errors?: Maybe<Array<Maybe<Error>>>;
  searchTerm?: Maybe<Scalars['String']['output']>;
};

/**
 * ```graphql
 * mutation {
 *   addKeywords(input: {
 *     domain: 9
 *     keywords: ["1234"]
 *     countrylocale: 4
 *     locations: []
 *     searchEngines: [
 *       {
 *         id: 1,
 *         searchTypes: [1, 2]
 *       }
 *     ]
 *     ignoreLocalResults: false
 *     ignoreFeaturedSnippet: false
 *     starred: false
 *     tags: []
 *   }){
 *     errors {
 *       field
 *       messages
 *     }
 *   }
 * }
 * ```
 */
export type AddKeywords = {
  __typename?: 'AddKeywords';
  errors?: Maybe<Array<Maybe<Error>>>;
};

/** Based on AddManyKeywordsForm */
export type AddKeywordsInput = {
  countrylocale: Scalars['Int']['input'];
  domain: Scalars['ID']['input'];
  enableAutocorrect: Scalars['Boolean']['input'];
  ignoreFeaturedSnippet: Scalars['Boolean']['input'];
  ignoreInShareOfVoice: Scalars['Boolean']['input'];
  ignoreLocalResults: Scalars['Boolean']['input'];
  keywords: Array<InputMaybe<Scalars['String']['input']>>;
  locations: Array<InputMaybe<Scalars['String']['input']>>;
  searchEngines: Array<InputMaybe<AddKeywordsSeachEngineInput>>;
  starred: Scalars['Boolean']['input'];
  tags: Array<InputMaybe<Scalars['String']['input']>>;
};

export type AddKeywordsInputNew = {
  addKeywordJobId: Scalars['String']['input'];
  domain: Scalars['ID']['input'];
  ignoreInShareOfVoice?: InputMaybe<Scalars['Boolean']['input']>;
  keywords: Array<InputMaybe<Scalars['String']['input']>>;
  locales: Array<InputMaybe<AddKeywordsLocaleInputNew>>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AddKeywordsLocaleInputNew = {
  countrylocale: Scalars['Int']['input'];
  enableAutocorrect: Scalars['Boolean']['input'];
  ignoreFeaturedSnippet: Scalars['Boolean']['input'];
  ignoreLocalResults: Scalars['Boolean']['input'];
  locations: Array<InputMaybe<Scalars['String']['input']>>;
  searchEngines: Array<InputMaybe<AddKeywordsSeachEngineInputNew>>;
};

export type AddKeywordsNew = {
  __typename?: 'AddKeywordsNew';
  code?: Maybe<KeywordOveruseCode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type AddKeywordsSeachEngineInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  searchTypes: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type AddKeywordsSeachEngineInputNew = {
  id: Scalars['Int']['input'];
  searchTypes: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type AddMultiAccountRequest = {
  __typename?: 'AddMultiAccountRequest';
  errors?: Maybe<Array<Maybe<Error>>>;
  multiAccount?: Maybe<MultiAccountLinkNode>;
};

/** based on AddMultiAccountRequestForm */
export type AddMultiAccountRequestInput = {
  fromOrganization: Scalars['ID']['input'];
  isOrgAdmin: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  sendACopy: Scalars['Boolean']['input'];
  subject: Scalars['String']['input'];
  toEmail: Scalars['String']['input'];
};

/**
 * ```graphql
 * mutation {
 *   addNote(input: {
 *     createdAt: "2017-11-11"
 *     domain: 30
 *     note: "test note"
 *     keywords: [568, 571]
 *     filter: [{'attribute': 'period', 'type': 'datetime', 'comparison': 'between', 'value': '["2020-05-04","latest"]'}, {'attribute': 'compare_to', 'type': 'datetime', 'comparison': 'eq', 'value': '2020-05-04'}, {'attribute': 'domains', 'type': 'list', 'comparison': 'contains', 'value': "['6']"}, {'attribute': 'rank', 'type': 'num', 'comparison': 'lt', 'value': '2'}]
 *   }) {
 *     errors {
 *       field
 *       messages
 *     }
 *     note {
 *       id
 *     }
 *   }
 * }
 * ```
 */
export type AddNote = {
  __typename?: 'AddNote';
  errors?: Maybe<Array<Maybe<Error>>>;
  note?: Maybe<NoteNode>;
};

/** Based on AddNoteForm */
export type AddNoteInput = {
  createdAt: Scalars['Date']['input'];
  domain: Scalars['ID']['input'];
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  keywordsToExclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Max length is 2048 chars. */
  note: Scalars['String']['input'];
};

export type AddOrganizationNote = {
  __typename?: 'AddOrganizationNote';
  note?: Maybe<OrganizationNoteNode>;
};

export type AddPrepaidVoucher = {
  __typename?: 'AddPrepaidVoucher';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type AddPrepaidVoucherInput = {
  vouchers: Array<InputMaybe<Scalars['String']['input']>>;
};

export type AddReportFilterInput = {
  filters: Scalars['JSONString']['input'];
};

/**
 * ```graphql
 * mutation {
 *   addReportTemplate(input: {organization: 1, brandColor: "#FFA726", template: "[{}]", name: "test"}) {
 *     reportTemplate {
 *       id
 *       organization {
 *         id
 *       }
 *       brandColor
 *       template
 *       name
 *     }
 *     errors {
 *       field
 *       messages
 *     }
 *   }
 * }
 * ```
 */
export type AddReportTemplate = {
  __typename?: 'AddReportTemplate';
  errors?: Maybe<Array<Maybe<Error>>>;
  reportTemplate?: Maybe<ReportTemplateNode>;
};

export type AddReportTemplateInput = {
  brandColor: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organization: Scalars['ID']['input'];
  template: Scalars['JSONString']['input'];
};

/**
 * If you set isGroupReport you must specify Group. Otherwise you must specify a domain.
 * Domain and group cannot be set at the same time.
 *
 * If isOneTimeReport == false then required filters is not allowed.
 *
 * ```graphql
 * mutation {
 *   addScheduledReport(input: {
 *     isGroupReport: false,
 *     isOneTimeReport: true,
 *     withHistory: false,
 *     domain: 134,
 *     reportType: 1,
 *     reportTemplate: 1,
 *     reportFilter: { filters: "[{"attribute":"period","type":"datetime","comparison":"between","value":"[\"2017-01-01\", \"2017-12-23\"]"},{"attribute":"domains","type":"list","comparison":"contains","value":"[2]"},{"attribute":"compare_to","type":"datetime","comparison":"eq","value":"2017-10-10"}, {"attribute": "keywords", "type": "list", "comparison": "contains", "value": "[1]"}]" }, schedule: 1, recipients: ["cm@accuranker.com"], sender: "cm-sender@accuranker.com", emailSubject: "subject", emailBody: "body", language: "da"}
 *   ) {
 *     scheduledReport {
 *       id
 *     }
 *     errors {
 *       field
 *       messages
 *     }
 *   }
 * }
 * ```
 */
export type AddScheduledReport = {
  __typename?: 'AddScheduledReport';
  errors?: Maybe<Array<Maybe<Error>>>;
  scheduledReport?: Maybe<ScheduledReportNode>;
};

export type AdminOrganizationNode = {
  __typename?: 'AdminOrganizationNode';
  data?: Maybe<Array<Maybe<OrganizationNode>>>;
  pagination?: Maybe<Pagination>;
};

export type AdminSalesCustomerMetric = {
  __typename?: 'AdminSalesCustomerMetric';
  beforeMrr?: Maybe<Scalars['Float']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  netCashFlow?: Maybe<Scalars['Float']['output']>;
  organization?: Maybe<OrganizationNode>;
};

export type AdminSalesMetric = {
  __typename?: 'AdminSalesMetric';
  amount?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type AdminSalesMetricCompanyType = {
  __typename?: 'AdminSalesMetricCompanyType';
  count?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AdminSalesMetricCountry = {
  __typename?: 'AdminSalesMetricCountry';
  count?: Maybe<Scalars['Int']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

export type AdminSalesMetricCustomer = {
  __typename?: 'AdminSalesMetricCustomer';
  cancellations?: Maybe<Scalars['Int']['output']>;
  churnRate?: Maybe<Scalars['Float']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  freePlans?: Maybe<Scalars['Int']['output']>;
  ltv?: Maybe<Scalars['Float']['output']>;
  trialConverted?: Maybe<Scalars['Int']['output']>;
  trialConvertedRate?: Maybe<Scalars['Float']['output']>;
  trials?: Maybe<Scalars['Int']['output']>;
  unconfirmedConverted?: Maybe<Scalars['Int']['output']>;
  unconfirmedConvertedRate?: Maybe<Scalars['Float']['output']>;
};

export type AdminSalesMetricDay = {
  __typename?: 'AdminSalesMetricDay';
  arpa?: Maybe<Scalars['Float']['output']>;
  asp?: Maybe<Scalars['Float']['output']>;
  companyTypes?: Maybe<Array<Maybe<AdminSalesMetricCompanyType>>>;
  countries?: Maybe<Array<Maybe<AdminSalesMetricCountry>>>;
  customers?: Maybe<AdminSalesMetricCustomer>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  metricsCancellations?: Maybe<Array<Maybe<AdminSalesCustomerMetric>>>;
  metricsChurn?: Maybe<Array<Maybe<AdminSalesCustomerMetric>>>;
  metricsContraction?: Maybe<Array<Maybe<AdminSalesCustomerMetric>>>;
  metricsExpansion?: Maybe<Array<Maybe<AdminSalesCustomerMetric>>>;
  metricsNew?: Maybe<Array<Maybe<AdminSalesCustomerMetric>>>;
  metricsReactivation?: Maybe<Array<Maybe<AdminSalesCustomerMetric>>>;
  mrr?: Maybe<AdminSalesMetricMrr>;
  netCashFlow?: Maybe<Scalars['Float']['output']>;
  nps?: Maybe<Scalars['Float']['output']>;
  plans?: Maybe<Array<Maybe<AdminSalesMetricPlan>>>;
  quickRatio?: Maybe<Scalars['Float']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type AdminSalesMetricMrr = {
  __typename?: 'AdminSalesMetricMRR';
  affiliate?: Maybe<AdminSalesMetric>;
  churn?: Maybe<AdminSalesMetric>;
  contraction?: Maybe<AdminSalesMetric>;
  expansion?: Maybe<AdminSalesMetric>;
  grossChurnRate?: Maybe<Scalars['Float']['output']>;
  net?: Maybe<AdminSalesMetric>;
  netChurnRate?: Maybe<Scalars['Float']['output']>;
  new?: Maybe<AdminSalesMetric>;
  reactivation?: Maybe<AdminSalesMetric>;
  recurring?: Maybe<AdminSalesMetric>;
  total?: Maybe<AdminSalesMetric>;
  totalForecast?: Maybe<AdminSalesMetric>;
};

export type AdminSalesMetricPlan = {
  __typename?: 'AdminSalesMetricPlan';
  category?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  keywords?: Maybe<Scalars['Int']['output']>;
  mrr?: Maybe<Scalars['Float']['output']>;
};

export type AdminSearchNode = {
  __typename?: 'AdminSearchNode';
  data?: Maybe<Scalars['JSONString']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  objId?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

export type AdobeMarketingConnectionNode = GrapheneNode & {
  __typename?: 'AdobeMarketingConnectionNode';
  adobeMarketing: DomainNodeConnection;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  organization: OrganizationNode;
  secret: Scalars['String']['output'];
  suiteIds?: Maybe<Array<Maybe<AdobeMarketingConnectionSiteIdNode>>>;
  username: Scalars['String']['output'];
};

export type AdobeMarketingConnectionNodeAdobeMarketingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AdobeMarketingConnectionNodeConnection = {
  __typename?: 'AdobeMarketingConnectionNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AdobeMarketingConnectionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AdobeMarketingConnectionNode` and its cursor. */
export type AdobeMarketingConnectionNodeEdge = {
  __typename?: 'AdobeMarketingConnectionNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AdobeMarketingConnectionNode>;
};

export type AdobeMarketingConnectionSiteIdNode = {
  __typename?: 'AdobeMarketingConnectionSiteIdNode';
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type AffiliateMetricsItemNode = {
  __typename?: 'AffiliateMetricsItemNode';
  amount?: Maybe<Scalars['Float']['output']>;
  campaign?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  placement?: Maybe<Scalars['String']['output']>;
};

export type AffiliateMetricsNode = {
  __typename?: 'AffiliateMetricsNode';
  /** Amount of commission grouped by day */
  commission?: Maybe<Array<Maybe<AffiliateMetricsItemNode>>>;
  /** Amount of customers grouped by day */
  customers?: Maybe<Array<Maybe<AffiliateMetricsItemNode>>>;
  /** Amount of new customers grouped by day */
  newCustomers?: Maybe<Array<Maybe<AffiliateMetricsItemNode>>>;
  /** Amount of new trials grouped by day */
  newTrials?: Maybe<Array<Maybe<AffiliateMetricsItemNode>>>;
  /** Amount of page views grouped by day */
  pageviews?: Maybe<Array<Maybe<AffiliateMetricsItemNode>>>;
  totalCommission?: Maybe<Scalars['Float']['output']>;
  totalNewCustomers?: Maybe<Scalars['Int']['output']>;
  totalNewTrials?: Maybe<Scalars['Int']['output']>;
  totalPageviews?: Maybe<Scalars['Int']['output']>;
  totalVisitorToTrialRate?: Maybe<Scalars['Float']['output']>;
  totalVisitors?: Maybe<Scalars['Int']['output']>;
  /** Amount of trials grouped by day */
  trials?: Maybe<Array<Maybe<AffiliateMetricsItemNode>>>;
  /** Amount of visitors grouped by day */
  visitors?: Maybe<Array<Maybe<AffiliateMetricsItemNode>>>;
};

export type AffiliateMoneyOwedNode = {
  __typename?: 'AffiliateMoneyOwedNode';
  minimumPayout?: Maybe<Scalars['Float']['output']>;
  owedDkk?: Maybe<Scalars['Float']['output']>;
  owedEur?: Maybe<Scalars['Float']['output']>;
  owedTotalUsd?: Maybe<Scalars['Float']['output']>;
  owedUsd?: Maybe<Scalars['Float']['output']>;
};

export type AffiliateNode = GrapheneNode & {
  __typename?: 'AffiliateNode';
  click: Scalars['Int']['output'];
  earnedCommission?: Maybe<Scalars['Float']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  minimumUsage: Scalars['Decimal']['output'];
  organization: OrganizationNode;
  payout: Scalars['Decimal']['output'];
  payoutType: BillingAffiliatePayoutTypeChoices;
  pendingCommission?: Maybe<Scalars['Float']['output']>;
  purseHolding?: Maybe<Scalars['Float']['output']>;
  refCode?: Maybe<Scalars['String']['output']>;
  referralCount?: Maybe<Scalars['Int']['output']>;
  signupBonus: Scalars['Decimal']['output'];
  wallet?: Maybe<Array<Maybe<WalletNode>>>;
};

export enum AffiliateRevShareAffiliateSignupTypeChoices {
  /** Free trials */
  A_1 = 'A_1',
  /** Require CC on trials and set up recurring billing */
  A_2 = 'A_2',
  /** No trials */
  A_3 = 'A_3',
}

export enum AffiliateVisitorAdChannelChoices {
  /** Google Adwords */
  A_1 = 'A_1',
}

export type AffiliateVisitorsNode = {
  __typename?: 'AffiliateVisitorsNode';
  /** Get list of campaign choices for current visitors */
  campaignChoices?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Affiliate rev share details */
  details?: Maybe<RevShareAffiliateNode>;
  /** Get metrics by day */
  metrics?: Maybe<AffiliateMetricsNode>;
  /** Get page views */
  pageviews?: Maybe<Array<Maybe<PageViewNode>>>;
  payments?: Maybe<Array<Maybe<VisitorPaymentNode>>>;
  /** Get list of placement choices for current visitors */
  placementChoices?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get list of visitors grouped by referrer */
  referrers?: Maybe<Array<Maybe<ReferrerNode>>>;
  /** Get list of unique id choices for current visitors */
  uniqueIdChoices?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get all visitors or just customers, trials or just visitors */
  visitors?: Maybe<Array<Maybe<VisitorNode>>>;
};

export type AffiliateVisitorsNodeMetricsArgs = {
  category?: Scalars['String']['input'];
  filters: Array<InputMaybe<FilterInput>>;
};

export type AffiliateVisitorsNodePaymentsArgs = {
  filters: Array<InputMaybe<FilterInput>>;
};

export type AffiliateVisitorsNodeReferrersArgs = {
  filters: Array<InputMaybe<FilterInput>>;
};

export type AffiliateVisitorsNodeVisitorsArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  includeCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  includeJustVisitors?: InputMaybe<Scalars['Boolean']['input']>;
  includeTrials?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AiSearchVolumeHistoryNode = {
  __typename?: 'AiSearchVolumeHistoryNode';
  /** 2017-12-01 */
  month?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
  volumeDesktop?: Maybe<Scalars['Int']['output']>;
  volumeMobile?: Maybe<Scalars['Int']['output']>;
};

export type AiSearchVolumeNode = GrapheneNode & {
  __typename?: 'AiSearchVolumeNode';
  createdAt: Scalars['DateTime']['output'];
  db: Scalars['String']['output'];
  /** Googles geotarget constant for locations and countries */
  geotargetId?: Maybe<Scalars['Int']['output']>;
  history?: Maybe<Array<Maybe<AiSearchVolumeHistoryNode>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isUpdating: Scalars['Boolean']['output'];
  keyword: Scalars['String']['output'];
  keywordAiSearchVolume: KeywordNodeConnection;
  keywordPlannerSearchVolume?: Maybe<SearchVolumeNode>;
  needsUpdate: Scalars['Boolean']['output'];
  searchEngine: SearchEngineNode;
  searchVolumeDesktop?: Maybe<Scalars['Int']['output']>;
  searchVolumeMobile?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AiSearchVolumeNodeKeywordAiSearchVolumeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AiSearchVolumeNodeConnection = {
  __typename?: 'AiSearchVolumeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AiSearchVolumeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AiSearchVolumeNode` and its cursor. */
export type AiSearchVolumeNodeEdge = {
  __typename?: 'AiSearchVolumeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AiSearchVolumeNode>;
};

export type AllKpis = {
  __typename?: 'AllKpis';
  aboveTheFold?: Maybe<Scalars['Float']['output']>;
  aboveTheFoldBefore?: Maybe<Scalars['Float']['output']>;
  aiSearchVolume?: Maybe<Scalars['Float']['output']>;
  aiSearchVolumeBefore?: Maybe<Scalars['Float']['output']>;
  aiShareOfVoiceSum?: Maybe<Scalars['Float']['output']>;
  aiShareOfVoiceSumBefore?: Maybe<Scalars['Float']['output']>;
  aiTrafficValue?: Maybe<Scalars['Float']['output']>;
  aiTrafficValueBefore?: Maybe<Scalars['Float']['output']>;
  avgBaseRank?: Maybe<Scalars['Float']['output']>;
  avgBaseRankBefore?: Maybe<Scalars['Float']['output']>;
  avgMaxBaseRank?: Maybe<Scalars['Float']['output']>;
  avgRank?: Maybe<Scalars['Float']['output']>;
  avgRankBefore?: Maybe<Scalars['Float']['output']>;
  ctr?: Maybe<Scalars['Float']['output']>;
  ctrBefore?: Maybe<Scalars['Float']['output']>;
  firstPage?: Maybe<Scalars['Float']['output']>;
  firstPageBefore?: Maybe<Scalars['Float']['output']>;
  keywords?: Maybe<Scalars['Float']['output']>;
  keywordsBefore?: Maybe<Scalars['Float']['output']>;
  maxAiShareOfVoiceSum?: Maybe<Scalars['Float']['output']>;
  maxAiTrafficValue?: Maybe<Scalars['Float']['output']>;
  maxCtr?: Maybe<Scalars['Float']['output']>;
  maxShareOfVoiceSum?: Maybe<Scalars['Float']['output']>;
  maxTrafficValue?: Maybe<Scalars['Float']['output']>;
  movedDown?: Maybe<Scalars['Float']['output']>;
  movedUp?: Maybe<Scalars['Float']['output']>;
  pixelsFromTop?: Maybe<Scalars['Float']['output']>;
  pixelsFromTopBefore?: Maybe<Scalars['Float']['output']>;
  searchVolume?: Maybe<Scalars['Float']['output']>;
  searchVolumeBefore?: Maybe<Scalars['Float']['output']>;
  shareOfVoiceSum?: Maybe<Scalars['Float']['output']>;
  shareOfVoiceSumBefore?: Maybe<Scalars['Float']['output']>;
  top1?: Maybe<Scalars['Float']['output']>;
  top1Before?: Maybe<Scalars['Float']['output']>;
  top3?: Maybe<Scalars['Float']['output']>;
  top3Before?: Maybe<Scalars['Float']['output']>;
  trafficValue?: Maybe<Scalars['Float']['output']>;
  trafficValueBefore?: Maybe<Scalars['Float']['output']>;
};

export type AnswerFeedbackQuestion = {
  __typename?: 'AnswerFeedbackQuestion';
  answer?: Maybe<FeedbackQuestionAnswerNode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type AnswerFeedbackQuestionInput = {
  id: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};

export type AvgTopTenCtrByPathNode = {
  __typename?: 'AvgTopTenCtrByPathNode';
  avgTopTenCtrByPath?: Maybe<Scalars['GenericScalar']['output']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
};

/**
 * country: which country should the keywords be imported from when querying keydis. The actual grank countries its imported to are specified in locales
 * in the BasicAddKeywordsInput which this one inherits from.
 * filters: list of filters that limit the keyword selected in keydis.
 * ordering: how to order to keywords to be imported ex. if you want the SQL to look like "keyword DESC"
 *           this value should be  ordering.order_by = "keyword", ordering.order = "DESC".
 * n_keywords_to_add: how many keyword to add to the domain.
 */
export type BaseImportKeydisInput = {
  addKeywordJobId?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  domain: Scalars['ID']['input'];
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  ignoreInShareOfVoice?: InputMaybe<Scalars['Boolean']['input']>;
  locales: Array<InputMaybe<AddKeywordsLocaleInputNew>>;
  nKeywordsToAdd: Scalars['Int']['input'];
  ordering?: InputMaybe<OrderingInput>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum BillingAffiliatePayoutTypeChoices {
  /** Static payment */
  A_1 = 'A_1',
  /** Revshare payment */
  A_2 = 'A_2',
}

export type BillingCycleNode = GrapheneNode & {
  __typename?: 'BillingCycleNode';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  long?: Maybe<Scalars['String']['output']>;
  months: Scalars['Int']['output'];
  short?: Maybe<Scalars['String']['output']>;
};

export enum BillingPaymentContactCountryIdChoices {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Laos */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syria */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
}

export enum BillingPaymentContactCurrencyChoices {
  /** DKK */
  Dkk = 'DKK',
  /** EUR */
  Eur = 'EUR',
  /** USD */
  Usd = 'USD',
}

export enum BillingPaymentContactPaymentMethodChoices {
  /** Invoice */
  A_1 = 'A_1',
  /** Credit Card */
  A_2 = 'A_2',
  /** PayPal */
  A_3 = 'A_3',
  /** Prepaid */
  A_4 = 'A_4',
}

export enum BillingPaymentPaymentMethodChoices {
  /** Invoice */
  A_1 = 'A_1',
  /** Online */
  A_2 = 'A_2',
}

export enum BillingPaymentPaymentTypeChoices {
  /** New */
  A_1 = 'A_1',
  /** Recurring */
  A_2 = 'A_2',
  /** Upgrade */
  A_3 = 'A_3',
  /** Downgrade */
  A_4 = 'A_4',
  /** Reactivation */
  A_5 = 'A_5',
  /** Auto Upgrade */
  A_6 = 'A_6',
  /** Adjusted Price */
  A_7 = 'A_7',
  /** Partner API */
  A_10 = 'A_10',
}

export enum BillingWalletCurrencyChoices {
  /** DKK */
  Dkk = 'DKK',
  /** EUR */
  Eur = 'EUR',
  /** USD */
  Usd = 'USD',
}

export enum BillingWalletWalletTypeChoices {
  /** Payment */
  A_1 = 'A_1',
  /** Commission */
  A_2 = 'A_2',
  /** Signup bonus */
  A_3 = 'A_3',
}

export type BraintreeCustomerCardNode = {
  __typename?: 'BraintreeCustomerCardNode';
  cardType?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  maskedNumber?: Maybe<Scalars['String']['output']>;
};

export type BraintreeCustomerNode = {
  __typename?: 'BraintreeCustomerNode';
  card?: Maybe<BraintreeCustomerCardNode>;
  paypal?: Maybe<BraintreeCustomerPaypalNode>;
};

export type BraintreeCustomerPaypalNode = {
  __typename?: 'BraintreeCustomerPaypalNode';
  email?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
};

export type BulkUpgradeUaToGa4 = {
  __typename?: 'BulkUpgradeUAToGA4';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type BulkUpgradeUaToGa4Input = {
  upgrades: Array<InputMaybe<UpgradeUaToGa4Input>>;
};

export type CancelPlan = {
  __typename?: 'CancelPlan';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CancelPlanInput = {
  ignoreMinCancelationPeriod?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  resolve?: InputMaybe<Scalars['String']['input']>;
};

export type CancellationRequestInput = {
  contactPerson: Scalars['ID']['input'];
  reasonCode: Scalars['Int']['input'];
  reasonElaborate?: InputMaybe<Scalars['String']['input']>;
};

export type CancellationRequestMutation = {
  __typename?: 'CancellationRequestMutation';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ChartsNode = {
  __typename?: 'ChartsNode';
  competitorStats?: Maybe<Array<Maybe<OverviewCompetitorStatsChartNodeNode>>>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  name?: Maybe<Scalars['String']['output']>;
  serpFeatureOverview?: Maybe<Array<Maybe<SerpFeatureOverviewChartNodeNode>>>;
  shareOfVoiceByTags?: Maybe<TempOverviewChartTagNode>;
  shareOfVoiceCompetitors?: Maybe<Array<OverviewShareOfVoiceCompetitorChartNodeNode>>;
  shareOfVoiceLandingPages?: Maybe<OverviewChartNode>;
  unknownCompetitors?: Maybe<Array<Maybe<OverviewUnknownCompetitorChartNodeNode>>>;
  winnersAndLosers?: Maybe<Array<Maybe<WinnersAndLosersChartNodeNode>>>;
};

export type ChartsNodeSerpFeatureOverviewArgs = {
  keywordCount?: InputMaybe<Scalars['Boolean']['input']>;
  notOwned?: InputMaybe<Scalars['Boolean']['input']>;
  owned?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChartsNodeShareOfVoiceByTagsArgs = {
  asPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  useAi?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChartsNodeShareOfVoiceCompetitorsArgs = {
  asPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  useAi?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChartsNodeShareOfVoiceLandingPagesArgs = {
  asPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  useAi?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChartsNodeWinnersAndLosersArgs = {
  useAi?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CheckVat = {
  __typename?: 'CheckVAT';
  fixedVatNumber?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ClientCompareInputNode = {
  domains?: InputMaybe<Array<InputMaybe<DomainsCompareInputNode>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  includeSelf?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientNode = GrapheneNode & {
  __typename?: 'ClientNode';
  dateAdded: Scalars['Date']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  domainSet: DomainNodeConnection;
  domains?: Maybe<Array<Maybe<DomainNode>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  importClient: ImportNodeConnection;
  isDemoClient?: Maybe<Scalars['Boolean']['output']>;
  latestCompletedScrapeDate?: Maybe<Scalars['DateTime']['output']>;
  /** Name of the group */
  name: Scalars['String']['output'];
  numberOfDomains?: Maybe<Scalars['Int']['output']>;
  numberOfKeywords?: Maybe<Scalars['Int']['output']>;
  organization: OrganizationNode;
  publicUrl?: Maybe<Scalars['String']['output']>;
  publicreportsettingsSet: PublicReportSettingsNodeConnection;
  scheduledreportnewSet: ScheduledReportNodeConnection;
  slug: Scalars['String']['output'];
  viewkey: Scalars['String']['output'];
  workspaceSet: Array<WorkspaceNode>;
};

export type ClientNodeDomainSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ClientNodeImportClientArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ClientNodePublicreportsettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ClientNodeScheduledreportnewSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CompareInputNode = {
  clients?: InputMaybe<Array<InputMaybe<ClientCompareInputNode>>>;
};

export type CompetitorNode = GrapheneNode & {
  __typename?: 'CompetitorNode';
  competitorForDomain: DomainNode;
  dateAdded: Scalars['Date']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  /** Enable ranking of your Facebook profile. */
  facebookProfile?: Maybe<Scalars['String']['output']>;
  faviconUrl?: Maybe<Scalars['String']['output']>;
  /** For some local results Google does not include a link to the website. To make sure we can still find the domain on the search result page, please enter the exact name of the Google Business page here. */
  googleBusinessName?: Maybe<Scalars['String']['output']>;
  googleBusinessNameList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Enable ranking of your Instagram profile. */
  instagramProfile?: Maybe<Scalars['String']['output']>;
  /** Enable ranking of your LinkedIn profile. */
  linkedinProfile?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  stats?: Maybe<CompetitorStatsNode>;
  /** Enable ranking of your TikTok profile and videos. */
  tiktokProfile?: Maybe<Scalars['String']['output']>;
  /** Enable ranking of your tweets and Twitter profile. */
  twitterHandle?: Maybe<Scalars['String']['output']>;
  youtubeChannelId?: Maybe<Scalars['String']['output']>;
  youtubeChannelName?: Maybe<Scalars['String']['output']>;
  youtubeChannelUsername?: Maybe<Scalars['String']['output']>;
};

export type CompetitorNodeConnection = {
  __typename?: 'CompetitorNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompetitorNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CompetitorNode` and its cursor. */
export type CompetitorNodeEdge = {
  __typename?: 'CompetitorNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CompetitorNode>;
};

export type CompetitorRowNode = {
  __typename?: 'CompetitorRowNode';
  avgRankCompare?: Maybe<Scalars['Float']['output']>;
  avgRankValue?: Maybe<Scalars['Float']['output']>;
  count1To3?: Maybe<Scalars['Int']['output']>;
  count1To3Compare?: Maybe<Scalars['Int']['output']>;
  count4To10?: Maybe<Scalars['Int']['output']>;
  count4To10Compare?: Maybe<Scalars['Int']['output']>;
  count11To20?: Maybe<Scalars['Int']['output']>;
  count11To20Compare?: Maybe<Scalars['Int']['output']>;
  count21To50?: Maybe<Scalars['Int']['output']>;
  count21To50Compare?: Maybe<Scalars['Int']['output']>;
  count51To100?: Maybe<Scalars['Int']['output']>;
  count51To100Compare?: Maybe<Scalars['Int']['output']>;
  countUnranked?: Maybe<Scalars['Int']['output']>;
  countUnrankedCompare?: Maybe<Scalars['Int']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  dynamicCtrCompare?: Maybe<Scalars['Float']['output']>;
  dynamicCtrValue?: Maybe<Scalars['Float']['output']>;
  faviconUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  organicTraffic?: Maybe<Scalars['Int']['output']>;
  organicTrafficCompare?: Maybe<Scalars['Int']['output']>;
  ownDomain?: Maybe<Scalars['Boolean']['output']>;
  shareOfVoiceCompare?: Maybe<Scalars['Float']['output']>;
  shareOfVoicePercentageValue?: Maybe<Scalars['Float']['output']>;
  shareOfVoicePercentageValueCompare?: Maybe<Scalars['Float']['output']>;
  shareOfVoiceValue?: Maybe<Scalars['Float']['output']>;
};

export type CompetitorStatsNode = {
  __typename?: 'CompetitorStatsNode';
  keywords0To3?: Maybe<Scalars['Int']['output']>;
  keywords0To3Change?: Maybe<Scalars['Int']['output']>;
  keywords4To10?: Maybe<Scalars['Int']['output']>;
  keywords4To10Change?: Maybe<Scalars['Int']['output']>;
  keywords11To20?: Maybe<Scalars['Int']['output']>;
  keywords11To20Change?: Maybe<Scalars['Int']['output']>;
  keywords21To50?: Maybe<Scalars['Int']['output']>;
  keywords21To50Change?: Maybe<Scalars['Int']['output']>;
  keywordsAbove50?: Maybe<Scalars['Int']['output']>;
  keywordsAbove50Change?: Maybe<Scalars['Int']['output']>;
  keywordsLosers?: Maybe<Scalars['Int']['output']>;
  keywordsUnranked?: Maybe<Scalars['Int']['output']>;
  keywordsUnrankedChange?: Maybe<Scalars['Int']['output']>;
  keywordsWinners?: Maybe<Scalars['Int']['output']>;
  shareOfVoice?: Maybe<Scalars['Int']['output']>;
  shareOfVoiceChange?: Maybe<Scalars['Int']['output']>;
  shareOfVoicePercentage?: Maybe<Scalars['Float']['output']>;
  shareOfVoicePercentageChange?: Maybe<Scalars['Float']['output']>;
};

export type CountByCountryListNode = {
  __typename?: 'CountByCountryListNode';
  countries?: Maybe<Array<Maybe<CountByCountryNode>>>;
  filters?: Maybe<Array<Maybe<Filter>>>;
};

export type CountByCountryNode = {
  __typename?: 'CountByCountryNode';
  country?: Maybe<Scalars['String']['output']>;
  default?: Maybe<Scalars['Boolean']['output']>;
  keywords?: Maybe<Scalars['Int']['output']>;
  searchSettings?: Maybe<Array<Maybe<SearchSettingNode>>>;
};

export type CountDynamicTagRulesetInput = {
  domainId: Scalars['ID']['input'];
  rawRuleset: Scalars['JSONString']['input'];
};

export type CountNode = {
  __typename?: 'CountNode';
  country?: Maybe<Scalars['Int']['output']>;
  globally?: Maybe<Scalars['Int']['output']>;
};

export type CountryLocaleCountNode = {
  __typename?: 'CountryLocaleCountNode';
  count: Scalars['Int']['output'];
  defaultCountrylocaleId: Scalars['Int']['output'];
};

export type CountryLocaleNode = GrapheneNode & {
  __typename?: 'CountryLocaleNode';
  adwordsLocationId?: Maybe<Scalars['Int']['output']>;
  baiduSupport: Scalars['Boolean']['output'];
  baiduTld?: Maybe<Scalars['String']['output']>;
  bingLng?: Maybe<Scalars['String']['output']>;
  bingSupport: Scalars['Boolean']['output'];
  bingTld?: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
  defaultsearchsettingSet: DefaultSearchSettingNodeConnection;
  domainSet: DomainNodeConnection;
  googleLng?: Maybe<Scalars['String']['output']>;
  googleSupport: Scalars['Boolean']['output'];
  googleTld?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  keywordSet: KeywordNodeConnection;
  /** Language for searches on the keyword, eg. 'Danish' */
  locale: Scalars['String']['output'];
  localeShort: Scalars['String']['output'];
  naverSupport: Scalars['Boolean']['output'];
  primaryTld?: Maybe<Scalars['String']['output']>;
  /** Country for searches on the keyword, eg. 'Denmark' */
  region: Scalars['String']['output'];
  scheduledreportnewSet: ScheduledReportNodeConnection;
  scraperRegion: Scalars['String']['output'];
  yahooSupport: Scalars['Boolean']['output'];
  yahooTld?: Maybe<Scalars['String']['output']>;
  yandexSupport: Scalars['Boolean']['output'];
  yandexTld?: Maybe<Scalars['String']['output']>;
  youtubeSupport: Scalars['Boolean']['output'];
  youtubeTld?: Maybe<Scalars['String']['output']>;
};

export type CountryLocaleNodeDefaultsearchsettingSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CountryLocaleNodeDomainSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CountryLocaleNodeKeywordSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CountryLocaleNodeScheduledreportnewSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CountryNode = {
  __typename?: 'CountryNode';
  id?: Maybe<Scalars['ID']['output']>;
  isEu?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  vatCode?: Maybe<Scalars['String']['output']>;
};

export type CreateAndPaySubAccount = {
  __typename?: 'CreateAndPaySubAccount';
  errors?: Maybe<Array<Maybe<Error>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateAndPaySubAccountInput = {
  billingCycle: Scalars['ID']['input'];
  organizationName: Scalars['String']['input'];
  plan: Scalars['ID']['input'];
};

/**
 * email: email of the user
 *
 * ```
 * mutation {
 *   createCustomTrial(input: {email: "test@test.dk"}) {
 *     unconfirmedUser {
 *         email
 *     }
 *   }
 * }
 */
export type CreateCustomTrial = {
  __typename?: 'CreateCustomTrial';
  errors?: Maybe<Array<Maybe<Error>>>;
  unconfirmedUser?: Maybe<UnconfirmedUserNode>;
};

export type CreateCustomTrialInput = {
  email: Scalars['String']['input'];
  outbound?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateInvoice = {
  __typename?: 'CreateInvoice';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateInvoiceInput = {
  billingCycleId: Scalars['ID']['input'];
  enable10PercentDiscount: Scalars['Boolean']['input'];
  freePeriodBeforePayment: Scalars['Boolean']['input'];
  freePeriodBeforePaymentDate?: InputMaybe<Scalars['Date']['input']>;
  maxKeywords: Scalars['Float']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  monthlyPrice: Scalars['Float']['input'];
  organizationId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
  yearlyPrice: Scalars['Float']['input'];
};

export type CreatePlan = {
  __typename?: 'CreatePlan';
  errors?: Maybe<Array<Maybe<Error>>>;
  plan?: Maybe<PricingPlanNode>;
};

export type CreatePlanInput = {
  category: Scalars['Int']['input'];
  comment: Scalars['String']['input'];
  customPlanForId?: InputMaybe<Scalars['Int']['input']>;
  dealEndDate?: InputMaybe<Scalars['Date']['input']>;
  dealStartDate?: InputMaybe<Scalars['Date']['input']>;
  featureAdvancedMetrics: Scalars['Boolean']['input'];
  featureApiAccess: Scalars['Boolean']['input'];
  featureApiV4WriteAccess?: InputMaybe<Scalars['Boolean']['input']>;
  maxCompetitors: Scalars['Int']['input'];
  maxKeywords: Scalars['Int']['input'];
  maxNumbersOfWorkspaces?: InputMaybe<Scalars['Int']['input']>;
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  priceMonthlyDkk: Scalars['Float']['input'];
  priceMonthlyEur: Scalars['Float']['input'];
  priceMonthlyUsd: Scalars['Float']['input'];
  priceYearlyDkk: Scalars['Float']['input'];
  priceYearlyEur: Scalars['Float']['input'];
  priceYearlyUsd: Scalars['Float']['input'];
  showCountdown?: InputMaybe<Scalars['Boolean']['input']>;
  signonDiscountDkk?: InputMaybe<Scalars['Float']['input']>;
  signonDiscountEur?: InputMaybe<Scalars['Float']['input']>;
  signonDiscountMonths?: InputMaybe<Scalars['Int']['input']>;
  signonDiscountUsd?: InputMaybe<Scalars['Float']['input']>;
  validForNewOnly: Scalars['Boolean']['input'];
};

export type CreateScheduledReportInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['ID']['input']>;
  emailBody?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['ID']['input']>;
  isGroupReport: Scalars['Boolean']['input'];
  isOneTimeReport: Scalars['Boolean']['input'];
  language: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  recipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reportFilter?: InputMaybe<AddReportFilterInput>;
  reportTemplate?: InputMaybe<Scalars['ID']['input']>;
  reportType: Scalars['Int']['input'];
  schedule: Scalars['Int']['input'];
  scheduledDay?: InputMaybe<Scalars['Int']['input']>;
  sender?: InputMaybe<Scalars['String']['input']>;
  withHistory?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateSubAccount = {
  __typename?: 'CreateSubAccount';
  errors?: Maybe<Array<Maybe<Error>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateSubAccountInput = {
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
};

/**
 * ```graphql
 * mutation {
 *   createFilter(input: {name: "random name", type: 1, filters: "[{"attribute":"period","type":"datetime","comparison":"between","value":"[\"2017-01-01\", \"2017-12-23\"]"},{"attribute":"domains","type":"list","comparison":"contains","value":"[2]"},{"attribute":"compare_to","type":"datetime","comparison":"eq","value":"2017-10-10"}]"}) {
 *     errors {
 *       field
 *       messages
 *     }
 *     filter {
 *       id
 *       filters
 *     }
 *   }
 * }
 * ```
 */
export type CreateUserFilter = {
  __typename?: 'CreateUserFilter';
  errors?: Maybe<Array<Maybe<Error>>>;
  filter?: Maybe<UserFilterNode>;
};

/**
 * Based on UserFilterForm
 *
 * name: name of the filter
 * type: any of UserFilterType
 * filters: json as a string, remember to escape it.
 */
export type CreateUserFilterInput = {
  defaultForDomains?: InputMaybe<Scalars['Boolean']['input']>;
  defaultForKeywords?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filters: Scalars['JSONString']['input'];
  name: Scalars['String']['input'];
  type: Scalars['Int']['input'];
};

export type CreateWallet = {
  __typename?: 'CreateWallet';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateWalletInput = {
  amount: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  customerOwesUs: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateWorkspace = {
  __typename?: 'CreateWorkspace';
  errors?: Maybe<Array<Maybe<Error>>>;
  workspace?: Maybe<WorkspaceNode>;
};

export type CreateWorkspaceInput = {
  clients: Array<InputMaybe<Scalars['Int']['input']>>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  users: Array<InputMaybe<Scalars['Int']['input']>>;
};

export type DashboardCardsNode = {
  __typename?: 'DashboardCardsNode';
  clients?: Maybe<Scalars['GenericScalar']['output']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  ordering?: Maybe<Ordering>;
};

export type DefaultSearchSettingNode = GrapheneNode & {
  __typename?: 'DefaultSearchSettingNode';
  countrylocale: CountryLocaleNode;
  domainSet: DomainNodeConnection;
  enableAutocorrect: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  ignoreFeaturedSnippet: Scalars['Boolean']['output'];
  ignoreLocalResults: Scalars['Boolean']['output'];
  locations?: Maybe<Array<Scalars['String']['output']>>;
  /** If True this SearchSetting will be the primary for the domain. */
  primary: Scalars['Boolean']['output'];
  searchEngines?: Maybe<Array<Maybe<DefaultSearchSettingSearchEngineNode>>>;
};

export type DefaultSearchSettingNodeDomainSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DefaultSearchSettingNodeConnection = {
  __typename?: 'DefaultSearchSettingNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DefaultSearchSettingNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DefaultSearchSettingNode` and its cursor. */
export type DefaultSearchSettingNodeEdge = {
  __typename?: 'DefaultSearchSettingNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DefaultSearchSettingNode>;
};

export type DefaultSearchSettingSearchEngineNode = GrapheneNode & {
  __typename?: 'DefaultSearchSettingSearchEngineNode';
  defaultsearchsettingSet: DefaultSearchSettingNodeConnection;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  searchEngine: SearchEngineNode;
  searchTypes: Array<Scalars['Int']['output']>;
};

export type DefaultSearchSettingSearchEngineNodeDefaultsearchsettingSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DefaultSearchSettingSearchEngineNodeConnection = {
  __typename?: 'DefaultSearchSettingSearchEngineNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DefaultSearchSettingSearchEngineNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DefaultSearchSettingSearchEngineNode` and its cursor. */
export type DefaultSearchSettingSearchEngineNodeEdge = {
  __typename?: 'DefaultSearchSettingSearchEngineNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DefaultSearchSettingSearchEngineNode>;
};

export type DefaultSearchSettingsInput = {
  countrylocale: Scalars['ID']['input'];
  enableAutocorrect: Scalars['Boolean']['input'];
  ignoreFeaturedSnippet: Scalars['Boolean']['input'];
  ignoreLocalResults: Scalars['Boolean']['input'];
  locations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  primary: Scalars['Boolean']['input'];
  searchEngines: Array<InputMaybe<DefaultSearchSettingsSearchEngineInput>>;
};

export type DefaultSearchSettingsSearchEngineInput = {
  searchEngine: Scalars['ID']['input'];
  searchTypes: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type DeleteDomain = {
  __typename?: 'DeleteDomain';
  domain?: Maybe<DomainNode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type DeleteDomainInput = {
  id: Scalars['ID']['input'];
};

export type DeleteDynamicTagRuleset = {
  __typename?: 'DeleteDynamicTagRuleset';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type DeleteDynamicTagRulesetFromTag = {
  __typename?: 'DeleteDynamicTagRulesetFromTag';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type DeleteDynamicTagRulesetInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOrganizationNote = {
  __typename?: 'DeleteOrganizationNote';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type DeleteScheduledReport = {
  __typename?: 'DeleteScheduledReport';
  errors?: Maybe<Array<Maybe<Error>>>;
  scheduledReport?: Maybe<ScheduledReportNode>;
};

export type DeleteScheduledReportInput = {
  id: Scalars['ID']['input'];
};

export type DeleteStaticTag = {
  __typename?: 'DeleteStaticTag';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type DeleteUploadImport = {
  __typename?: 'DeleteUploadImport';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type DeleteUploadImportInput = {
  id: Scalars['ID']['input'];
};

export type DeleteUserFilter = {
  __typename?: 'DeleteUserFilter';
  errors?: Maybe<Array<Maybe<Error>>>;
  filter?: Maybe<UserFilterNode>;
};

export type DeleteUserFilterInput = {
  id: Scalars['ID']['input'];
};

export type DeleteWorkspace = {
  __typename?: 'DeleteWorkspace';
  errors?: Maybe<Array<Maybe<Error>>>;
  workspace?: Maybe<WorkspaceNode>;
};

export type DemoDomainInfo = {
  __typename?: 'DemoDomainInfo';
  domainCount?: Maybe<Scalars['Int']['output']>;
  domainId?: Maybe<Scalars['ID']['output']>;
  domainName?: Maybe<Scalars['String']['output']>;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename?: 'DjangoDebug';
  /** Raise exceptions for this API query. */
  exceptions?: Maybe<Array<Maybe<DjangoDebugException>>>;
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single exception raised. */
export type DjangoDebugException = {
  __typename?: 'DjangoDebugException';
  /** The class of the exception */
  excType: Scalars['String']['output'];
  /** The message of the exception */
  message: Scalars['String']['output'];
  /** The stack trace */
  stack: Scalars['String']['output'];
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename?: 'DjangoDebugSQL';
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String']['output'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float']['output'];
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']['output']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean']['output'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean']['output'];
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']['output']>;
  /** JSON encoded database query parameters. */
  params: Scalars['String']['output'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String']['output'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']['output']>;
  /** Start time of this database query. */
  startTime: Scalars['Float']['output'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float']['output'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']['output']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']['output']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String']['output'];
};

export type DomainListNode = {
  __typename?: 'DomainListNode';
  domains?: Maybe<Array<Maybe<DomainNode>>>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
};

export type DomainNode = GrapheneNode & {
  __typename?: 'DomainNode';
  adobeMarketingConnection?: Maybe<AdobeMarketingConnectionNode>;
  adobeSuiteId?: Maybe<Scalars['String']['output']>;
  canRefresh?: Maybe<Scalars['Boolean']['output']>;
  canUpdate?: Maybe<Scalars['Boolean']['output']>;
  client: ClientNode;
  competitor?: Maybe<CompetitorNode>;
  competitorSet: CompetitorNodeConnection;
  competitors?: Maybe<Array<Maybe<CompetitorNode>>>;
  dateAdded: Scalars['Date']['output'];
  dateOfFirstRank?: Maybe<Scalars['Date']['output']>;
  defaultCountryCode?: Maybe<Scalars['String']['output']>;
  defaultCountrylocale?: Maybe<CountryLocaleNode>;
  /** This should only be used if you target a specific area. Bing only supports locations in the US. */
  defaultLocation?: Maybe<Scalars['String']['output']>;
  defaultSearchSettings?: Maybe<Array<Maybe<DefaultSearchSettingNode>>>;
  defaultSearchsettings: DefaultSearchSettingNodeConnection;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  demoDomainBannerInfo?: Maybe<DemoDomainInfo>;
  displayCurrency?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  /** No http:// or www. You can enter a path that must be found. Eg. example.com/path. Search result must then begin with your path to match. */
  domain: Scalars['String']['output'];
  domainHost?: Maybe<Scalars['String']['output']>;
  domainType: DomainsDomainDomainTypeChoices;
  dynamicTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Only include results where the URL found is an exact match to what is entered in domain name */
  exactMatch: Scalars['Boolean']['output'];
  /** Enable ranking of your Facebook profile. */
  facebookProfile?: Maybe<Scalars['String']['output']>;
  faviconUrl?: Maybe<Scalars['String']['output']>;
  filterOptions?: Maybe<FilterOptions>;
  firstRefreshAt?: Maybe<Scalars['DateTime']['output']>;
  folders?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  gaAccountId?: Maybe<Scalars['String']['output']>;
  gaProfileId?: Maybe<Scalars['String']['output']>;
  gaPropertyId?: Maybe<Scalars['String']['output']>;
  gaTimezone?: Maybe<Scalars['String']['output']>;
  /** For some local results Google does not include a link to the website. To make sure we can still find the domain on the search result page, please enter the exact name of the Google Business page here. */
  googleBusinessName?: Maybe<Scalars['String']['output']>;
  /** For some local results Google does not include a link to the website. To make sure we can still find the domain on the search result page, please enter the exact name of the Google Business page here. */
  googleBusinessNameList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  googleOauthConnectionGa?: Maybe<GoogleOAuthConnectionNode>;
  googleOauthConnectionGsc?: Maybe<GoogleOAuthConnectionNode>;
  /** Slow query as we need to contact google. */
  googleOauthConnectionGscKeywords?: Maybe<Array<Maybe<GoogleOAuthConnectionGscKeyword>>>;
  gscKeywords?: Maybe<Scalars['GenericScalar']['output']>;
  gscSiteId?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  importSet: ImportNodeConnection;
  /** Include results from sub.domain.com. */
  includeSubdomains: Scalars['Boolean']['output'];
  /** Enable ranking of your Instagram profile. */
  instagramProfile?: Maybe<Scalars['String']['output']>;
  isDemoDomain: Scalars['Boolean']['output'];
  keywordSet: KeywordNodeConnection;
  landingpageSet: LandingPageNodeConnection;
  lastFullManualScrape?: Maybe<Scalars['DateTime']['output']>;
  lastFullManualScrapeCompleted?: Maybe<Scalars['DateTime']['output']>;
  lastFullScrape?: Maybe<Scalars['DateTime']['output']>;
  lastFullScrapeCompleted?: Maybe<Scalars['DateTime']['output']>;
  lastManualRefreshAt?: Maybe<Scalars['DateTime']['output']>;
  lastScraped: Scalars['DateTime']['output'];
  lastScrapedCompleted?: Maybe<Scalars['DateTime']['output']>;
  lastSingleScrape?: Maybe<Scalars['DateTime']['output']>;
  lastSingleScrapeCompleted?: Maybe<Scalars['DateTime']['output']>;
  /** Enable ranking of your LinkedIn profile. */
  linkedinProfile?: Maybe<Scalars['String']['output']>;
  noteSet: NoteNodeConnection;
  notes?: Maybe<Array<Maybe<NoteNode>>>;
  notifications?: Maybe<Scalars['Int']['output']>;
  /** Put updates on hold. This will free up the keywords the domain is using. Note that you can not un-pause the domain for 7 days. */
  paused: Scalars['Boolean']['output'];
  pausedChangeDate?: Maybe<Scalars['DateTime']['output']>;
  pausedKeywordRate: Scalars['Int']['output'];
  publicUrl?: Maybe<Scalars['String']['output']>;
  publicreportsettingsSet: PublicReportSettingsNodeConnection;
  scheduledreportnewSet: ScheduledReportNodeConnection;
  /** Show Share of Voice in percentage for this domain */
  shareOfVoicePercentage: Scalars['Boolean']['output'];
  showLocalSearchVolume?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  sortName?: Maybe<Scalars['String']['output']>;
  subdomains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Enable ranking of your TikTok profile and videos. */
  tiktokProfile?: Maybe<Scalars['String']['output']>;
  totalDesktopKeywords?: Maybe<Scalars['Int']['output']>;
  totalKeywords?: Maybe<Scalars['Int']['output']>;
  totalMobileKeywords?: Maybe<Scalars['Int']['output']>;
  /** Enable ranking of your tweets and Twitter profile. */
  twitterHandle?: Maybe<Scalars['String']['output']>;
  unrankedWeight?: Maybe<Scalars['Int']['output']>;
  unscrapedRanks?: Maybe<Scalars['Int']['output']>;
  userfilterSet: UserFilterNodeConnection;
  viewkey: Scalars['String']['output'];
  youtubeChannelId?: Maybe<Scalars['String']['output']>;
  youtubeChannelName?: Maybe<Scalars['String']['output']>;
  youtubeChannelUsername?: Maybe<Scalars['String']['output']>;
};

export type DomainNodeCompetitorArgs = {
  id: Scalars['ID']['input'];
};

export type DomainNodeCompetitorSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DomainNodeDefaultSearchsettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DomainNodeImportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DomainNodeKeywordSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DomainNodeLandingpageSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DomainNodeNoteSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DomainNodePublicreportsettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DomainNodeScheduledreportnewSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DomainNodeUserfilterSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DomainNodeConnection = {
  __typename?: 'DomainNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DomainNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DomainNode` and its cursor. */
export type DomainNodeEdge = {
  __typename?: 'DomainNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DomainNode>;
};

export type DomainToCountryNode = {
  __typename?: 'DomainToCountryNode';
  countrylocale?: Maybe<CountryLocaleNode>;
  domain?: Maybe<Scalars['String']['output']>;
};

export type DomainTypeNode = {
  __typename?: 'DomainTypeNode';
  competitors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  domains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  latestSearches?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DomainValidationNode = {
  __typename?: 'DomainValidationNode';
  redirectTo?: Maybe<Scalars['String']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

export type DomainsCompareInputNode = {
  id?: InputMaybe<Scalars['Int']['input']>;
  includeSelf?: InputMaybe<Scalars['Boolean']['input']>;
  paths?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum DomainsDomainDomainTypeChoices {
  /** Standard */
  A_1 = 'A_1',
  /** YouTube */
  A_2 = 'A_2',
  /** YouTube Channel */
  A_3 = 'A_3',
  /** Google */
  A_4 = 'A_4',
  /** Bing */
  A_5 = 'A_5',
  /** Baidu */
  A_6 = 'A_6',
  /** Naver */
  A_7 = 'A_7',
}

export type DynamicTagRulesetListNode = {
  __typename?: 'DynamicTagRulesetListNode';
  domainId?: Maybe<Scalars['Int']['output']>;
  rawRulesetList?: Maybe<Array<Maybe<DynamicTagRulesetNode>>>;
};

export type DynamicTagRulesetNode = {
  __typename?: 'DynamicTagRulesetNode';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  domainId?: Maybe<Scalars['Int']['output']>;
  folderPath?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  rawRuleset: Scalars['String']['output'];
  tag?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EditIntegrationTapclicks = {
  __typename?: 'EditIntegrationTapclicks';
  integrationTapclicks?: Maybe<TapClicksNode>;
};

export type EditIntegrationTapclicksInput = {
  active: Scalars['Boolean']['input'];
  driveConnectionId: Scalars['ID']['input'];
};

export type EditOrganizationNote = {
  __typename?: 'EditOrganizationNote';
  note?: Maybe<OrganizationNoteNode>;
};

export type EnableOveruse = {
  __typename?: 'EnableOveruse';
  errors?: Maybe<Array<Maybe<Error>>>;
};

/** From what "area" overuse enabling was triggered from - just to not break legacy logging */
export enum EnableOveruseArea {
  AddKeydisKeywords = 'ADD_KEYDIS_KEYWORDS',
  AddKeywords = 'ADD_KEYWORDS',
  Manual = 'MANUAL',
  TransferDomain = 'TRANSFER_DOMAIN',
  Unpause = 'UNPAUSE',
}

export type EnableOveruseInput = {
  area: EnableOveruseArea;
  id: Scalars['ID']['input'];
};

/**
 * Field is the name of the field that caused the error or `__all__` which is used on generic errors.
 *
 * Other error types:
 * __domain_plan__
 * __org_plan__
 */
export type Error = {
  __typename?: 'Error';
  field: Scalars['String']['output'];
  messages: Array<Scalars['String']['output']>;
};

export type ErrorType = {
  __typename?: 'ErrorType';
  field: Scalars['String']['output'];
  messages: Array<Scalars['String']['output']>;
};

export type ExchangeRateNode = GrapheneNode & {
  __typename?: 'ExchangeRateNode';
  availableDisplayCurrencies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  baseCurrency: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  rate: Scalars['Decimal']['output'];
};

export type ExpandTrialPlan = {
  __typename?: 'ExpandTrialPlan';
  errors?: Maybe<Array<Maybe<Error>>>;
  plan?: Maybe<OrganizationPlanNode>;
  success: Scalars['Boolean']['output'];
};

export type ExportAdTracking = {
  __typename?: 'ExportAdTracking';
  errors?: Maybe<Array<Maybe<Error>>>;
  file?: Maybe<Scalars['String']['output']>;
};

export type ExportAdTrackingInput = {
  channel?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ExtendPlan = {
  __typename?: 'ExtendPlan';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ExtendPlanInput = {
  days: Scalars['Int']['input'];
  organizationId: Scalars['ID']['input'];
};

export type FailedPaymentNode = GrapheneNode & {
  __typename?: 'FailedPaymentNode';
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  invoiceSet: InvoiceNodeConnection;
  organization?: Maybe<OrganizationNode>;
  plan?: Maybe<OrganizationPlanNode>;
  reason: Scalars['String']['output'];
  reasonCode?: Maybe<Scalars['Int']['output']>;
  reasonType?: Maybe<Scalars['String']['output']>;
};

export type FailedPaymentNodeInvoiceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type FailedPaymentNodeConnection = {
  __typename?: 'FailedPaymentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FailedPaymentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FailedPaymentNode` and its cursor. */
export type FailedPaymentNodeEdge = {
  __typename?: 'FailedPaymentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FailedPaymentNode>;
};

export type FeedbackQuestionAnswerNode = GrapheneNode & {
  __typename?: 'FeedbackQuestionAnswerNode';
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  mainQuestion: FeedbackQuestionNode;
  rate: Scalars['Int']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type FeedbackQuestionAnswerNodeConnection = {
  __typename?: 'FeedbackQuestionAnswerNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FeedbackQuestionAnswerNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FeedbackQuestionAnswerNode` and its cursor. */
export type FeedbackQuestionAnswerNodeEdge = {
  __typename?: 'FeedbackQuestionAnswerNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FeedbackQuestionAnswerNode>;
};

export type FeedbackQuestionNode = GrapheneNode & {
  __typename?: 'FeedbackQuestionNode';
  /** Min number of days since account was created. */
  accountAge?: Maybe<Scalars['Int']['output']>;
  /** Switch it on/off if you want to start/stop asking this question. */
  active: Scalars['Boolean']['output'];
  /** The probability(in percents) to see the question on next app usage. */
  chanceToSeePercent?: Maybe<Scalars['Decimal']['output']>;
  endDate: Scalars['DateTime']['output'];
  feedbackquestionanswerSet: FeedbackQuestionAnswerNodeConnection;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Amount of answers to stop asking the question */
  maxFeedback?: Maybe<Scalars['Int']['output']>;
  shouldShowForTrials: Scalars['Boolean']['output'];
  startDate: Scalars['DateTime']['output'];
  text: Scalars['String']['output'];
  /** Min number of days since the user has joined */
  userAge?: Maybe<Scalars['Int']['output']>;
};

export type FeedbackQuestionNodeFeedbackquestionanswerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Same as FilterInput
 * Objects is the number of objects this filter returns, note that filters are applied in the same order as they are sent in the array. It is always 0 on required fields no minimize database load.
 */
export type Filter = {
  __typename?: 'Filter';
  attribute: Scalars['String']['output'];
  comparison: Scalars['String']['output'];
  objects?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  value: Scalars['GenericScalar']['output'];
};

export type FilterDataNode = {
  __typename?: 'FilterDataNode';
  filterCount?: Maybe<Scalars['Int']['output']>;
  filterOptions?: Maybe<Array<FilterOptionNode>>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  isKeydis?: Maybe<Scalars['Boolean']['output']>;
};

export type FilterDataNodeFilterOptionsArgs = {
  filterType: Scalars['String']['input'];
};

/**
 * We have the following types which can have the following comparisons:
 * * string: contains, not_contains, starts_with, ends_with, eq, ne
 * * num: gt, gte, lt, lte, eq, between
 * * array: any, all, none, empty
 * * list: contains
 * * bool: eq, ne
 * * datetime (%Y-%m-%d - 2008-09-03 OR latest / earliest): gt, gte, lt, lte, eq, between
 */
export type FilterInput = {
  attribute: Scalars['String']['input'];
  comparison: Scalars['String']['input'];
  type: Scalars['String']['input'];
  value: Scalars['GenericScalar']['input'];
};

export type FilterOptionNode = {
  __typename?: 'FilterOptionNode';
  count: Scalars['Int']['output'];
  option: Scalars['String']['output'];
};

export type FilterOptions = {
  __typename?: 'FilterOptions';
  deviceTypes?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  locales?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  searchEngines?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export enum FolderMetric {
  AboveTheFold = 'above_the_fold',
  AiTrafficValue = 'ai_traffic_value',
  AvgBaseRank = 'avg_base_rank',
  AvgCtr = 'avg_ctr',
  AvgRank = 'avg_rank',
  EstimatedVisits = 'estimated_visits',
  SearchVolume = 'search_volume',
  ShareOfVoice = 'share_of_voice',
  TrafficValue = 'traffic_value',
}

/** This is the main node used by the frontend */
export type FolderStructureNode = {
  __typename?: 'FolderStructureNode';
  filters?: Maybe<Array<Maybe<Filter>>>;
  folderStructure?: Maybe<Scalars['GenericScalar']['output']>;
  nested?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<Scalars['String']['output']>;
  orderBy?: Maybe<Scalars['String']['output']>;
};

export type Ga4PropertyNode = {
  __typename?: 'Ga4PropertyNode';
  account?: Maybe<Scalars['String']['output']>;
  createTime?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  industryCategory?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Scalars['String']['output']>;
  propertyType?: Maybe<Scalars['String']['output']>;
  serviceLevel?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  updateTime?: Maybe<Scalars['String']['output']>;
};

export type GeneratedReportNode = GrapheneNode & {
  __typename?: 'GeneratedReportNode';
  createdAt: Scalars['DateTime']['output'];
  file?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  remoteUrl?: Maybe<Scalars['String']['output']>;
  scheduledReportNew?: Maybe<ScheduledReportNode>;
  timeSpendGenerating: Scalars['Int']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type GeneratedReportNodeConnection = {
  __typename?: 'GeneratedReportNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GeneratedReportNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GeneratedReportNode` and its cursor. */
export type GeneratedReportNodeEdge = {
  __typename?: 'GeneratedReportNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<GeneratedReportNode>;
};

export type GoogleAccountUrlInput = {
  /** old is: http://localhost.dk:8000/org/googleoauth/callback/ */
  redirectUri: Scalars['String']['input'];
  /** 1 = analytics, 2 = drive, 3 = hubspot */
  type?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * ```graphql
 * mutation {
 *   googleConnectionUrl(input: {redirectUri: "http://localhost.dk:8000/dashboard/"}) {
 *     authorizeUrl
 *   }
 * }
 *
 * if authorizeUrl is none, then there was an error.
 * ```
 */
export type GoogleConnectionUrl = {
  __typename?: 'GoogleConnectionUrl';
  authorizeUrl?: Maybe<Scalars['String']['output']>;
};

export type GoogleOAuthConnectionGa = {
  __typename?: 'GoogleOAuthConnectionGa';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GoogleOAuthConnectionGaProperty = {
  __typename?: 'GoogleOAuthConnectionGaProperty';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  websiteUrl: Scalars['String']['output'];
};

export type GoogleOAuthConnectionGaPropertyProfile = {
  __typename?: 'GoogleOAuthConnectionGaPropertyProfile';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type GoogleOAuthConnectionGsc = {
  __typename?: 'GoogleOAuthConnectionGsc';
  siteUrl?: Maybe<Scalars['String']['output']>;
};

export type GoogleOAuthConnectionGscKeyword = {
  __typename?: 'GoogleOAuthConnectionGscKeyword';
  clicks?: Maybe<Scalars['Float']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  ctr?: Maybe<Scalars['Float']['output']>;
  exists?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  impressions?: Maybe<Scalars['Float']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Float']['output']>;
};

export type GoogleOAuthConnectionNode = {
  __typename?: 'GoogleOAuthConnectionNode';
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  ga4Properties?: Maybe<Array<Maybe<Ga4PropertyNode>>>;
  /** Slow query as we need to contact google. */
  gaAccounts?: Maybe<Array<Maybe<GoogleOAuthConnectionGa>>>;
  /** Slow query as we need to contact google. */
  gaProfiles?: Maybe<Array<Maybe<GoogleOAuthConnectionGaPropertyProfile>>>;
  /** Slow query as we need to contact google. */
  gaProperties?: Maybe<Array<GoogleOAuthConnectionGaProperty>>;
  /** Slow query as we need to contact google. */
  gscSites?: Maybe<Array<Maybe<GoogleOAuthConnectionGsc>>>;
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GoogleOAuthConnectionNodeGa4PropertiesArgs = {
  gaAccountId: Scalars['String']['input'];
};

export type GoogleOAuthConnectionNodeGaProfilesArgs = {
  gaAccountId: Scalars['String']['input'];
  gaPropertyId: Scalars['String']['input'];
};

export type GoogleOAuthConnectionNodeGaPropertiesArgs = {
  gaAccountId: Scalars['String']['input'];
};

export type GrapheneNode = {
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

export type GraphsNode = {
  __typename?: 'GraphsNode';
  compareAcrossHistory?: Maybe<Scalars['GenericScalar']['output']>;
  compareKeywordsChart?: Maybe<Scalars['GenericScalar']['output']>;
  competitorHistory?: Maybe<Scalars['GenericScalar']['output']>;
  displayCurrency?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  folderPathHistory?: Maybe<Scalars['GenericScalar']['output']>;
  googleTrendsHistory?: Maybe<Scalars['GenericScalar']['output']>;
  gscAndGaHistory?: Maybe<Scalars['GenericScalar']['output']>;
  keywordCompetitorHistory?: Maybe<Scalars['GenericScalar']['output']>;
  keywordHistory?: Maybe<Scalars['GenericScalar']['output']>;
  landingPageHistory?: Maybe<Array<Maybe<LandingpageGraphListNode>>>;
  name?: Maybe<Scalars['String']['output']>;
  overviewHistoryGraphs?: Maybe<Scalars['GenericScalar']['output']>;
  overviewSearchIntent: OverviewSearchIntentGraphNode;
  overviewSearchTrend: Array<OverviewSearchTrendGraphNode>;
  publicKeywordHistory?: Maybe<Scalars['GenericScalar']['output']>;
  publicOverviewHistoryGraphs?: Maybe<Scalars['GenericScalar']['output']>;
  publicOverviewSearchIntent: OverviewSearchIntentGraphNode;
  serpHistory?: Maybe<Scalars['GenericScalar']['output']>;
  unknownCompetitorRankDistribution?: Maybe<
    Array<Maybe<UnknownCompetitorRankDistributionGraphNode>>
  >;
  unknownCompetitorRankDistributionNew?: Maybe<Scalars['GenericScalar']['output']>;
  unknowncompetitorHistory?: Maybe<Array<Maybe<UnkowncompetitorHistoryGraphNode>>>;
};

export type GraphsNodeCompareAcrossHistoryArgs = {
  compareInput: CompareInputNode;
  metric?: InputMaybe<FolderMetric>;
};

export type GraphsNodeCompareKeywordsChartArgs = {
  keywordIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type GraphsNodeCompetitorHistoryArgs = {
  allHistory?: InputMaybe<Scalars['Boolean']['input']>;
  asPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  metric?: InputMaybe<Scalars['String']['input']>;
};

export type GraphsNodeFolderPathHistoryArgs = {
  metric?: InputMaybe<FolderMetric>;
  paths?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GraphsNodeGoogleTrendsHistoryArgs = {
  keywordId?: InputMaybe<Scalars['ID']['input']>;
};

export type GraphsNodeKeywordCompetitorHistoryArgs = {
  allHistory?: InputMaybe<Scalars['Boolean']['input']>;
  keywordId?: InputMaybe<Scalars['ID']['input']>;
};

export type GraphsNodeKeywordHistoryArgs = {
  allHistory?: InputMaybe<Scalars['Boolean']['input']>;
  keywordId?: InputMaybe<Scalars['ID']['input']>;
};

export type GraphsNodeLandingPageHistoryArgs = {
  allHistory?: InputMaybe<Scalars['Boolean']['input']>;
  landingPages: Array<InputMaybe<Scalars['ID']['input']>>;
  metric: Scalars['String']['input'];
};

export type GraphsNodeOverviewHistoryGraphsArgs = {
  allHistory?: InputMaybe<Scalars['Boolean']['input']>;
  charts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GraphsNodePublicKeywordHistoryArgs = {
  allHistory?: InputMaybe<Scalars['Boolean']['input']>;
  keywordId?: InputMaybe<Scalars['ID']['input']>;
  slug: Scalars['String']['input'];
  viewkey: Scalars['String']['input'];
};

export type GraphsNodePublicOverviewHistoryGraphsArgs = {
  allHistory?: InputMaybe<Scalars['Boolean']['input']>;
  charts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: Scalars['String']['input'];
  viewkey: Scalars['String']['input'];
};

export type GraphsNodePublicOverviewSearchIntentArgs = {
  allHistory?: InputMaybe<Scalars['Boolean']['input']>;
  slug: Scalars['String']['input'];
  viewkey: Scalars['String']['input'];
};

export type GraphsNodeSerpHistoryArgs = {
  serp?: InputMaybe<Scalars['String']['input']>;
};

export type GraphsNodeUnknownCompetitorRankDistributionArgs = {
  competitors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GraphsNodeUnknownCompetitorRankDistributionNewArgs = {
  competitors?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GraphsNodeUnknowncompetitorHistoryArgs = {
  allHistory?: InputMaybe<Scalars['Boolean']['input']>;
  keywordId?: InputMaybe<Scalars['ID']['input']>;
};

export type HistoryGraphNode = {
  __typename?: 'HistoryGraphNode';
  searchDate?: Maybe<Scalars['Date']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type ImportColumnInput = {
  id: Scalars['Int']['input'];
  mappedColumnId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type ImportColumnNode = {
  __typename?: 'ImportColumnNode';
  errors: Array<ImportColumnNodeError>;
  examples: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  mappedColumnId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
};

export type ImportColumnNodeError = {
  __typename?: 'ImportColumnNodeError';
  exception?: Maybe<Scalars['String']['output']>;
  line: Array<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type ImportKeydisKeywords = {
  __typename?: 'ImportKeydisKeywords';
  code?: Maybe<KeywordOveruseCode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

/**
 * excluded_keywords: list of keywords to exclude from the queryset in keydis. This list comes from the user deselecting the specific keywords.
 * additional_keywords_to_add: list keywords to add in addition to what ever is returned from the query in keydis. This list comes from the user selecting the specific keywords.
 */
export type ImportKeydisKeywordsInput = {
  addKeywordJobId?: InputMaybe<Scalars['String']['input']>;
  additionalKeywordsToAdd?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country: Scalars['String']['input'];
  domain: Scalars['ID']['input'];
  excludedKeywords?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  ignoreInShareOfVoice?: InputMaybe<Scalars['Boolean']['input']>;
  locales: Array<InputMaybe<AddKeywordsLocaleInputNew>>;
  nKeywordsToAdd: Scalars['Int']['input'];
  ordering?: InputMaybe<OrderingInput>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ImportKeydisKeywordsTopN = {
  __typename?: 'ImportKeydisKeywordsTopN';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ImportMappableColumnNode = {
  __typename?: 'ImportMappableColumnNode';
  description: Scalars['String']['output'];
  examples: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type ImportNode = GrapheneNode & {
  __typename?: 'ImportNode';
  client?: Maybe<ClientNode>;
  columns: Array<ImportColumnNode>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  domain?: Maybe<DomainNode>;
  errors: Array<ImportNodeError>;
  file: Scalars['String']['output'];
  fileContentType: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  mappableColumns: Array<ImportMappableColumnNode>;
  organization: OrganizationNode;
  processingAt?: Maybe<Scalars['DateTime']['output']>;
  progress: Scalars['Int']['output'];
  rankProcessingLastRowIdx?: Maybe<Scalars['Int']['output']>;
  rankProcessingResumedAt?: Maybe<Scalars['DateTime']['output']>;
  rankProcessingResumedRankIdx?: Maybe<Scalars['Int']['output']>;
  rankProcessingStartedAt?: Maybe<Scalars['DateTime']['output']>;
  rankProcessingTotalRows?: Maybe<Scalars['Int']['output']>;
  status: ImporterImportStatusChoices;
  validatingAt?: Maybe<Scalars['DateTime']['output']>;
  validationErrorAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ImportNodeConnection = {
  __typename?: 'ImportNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ImportNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ImportNode` and its cursor. */
export type ImportNodeEdge = {
  __typename?: 'ImportNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ImportNode>;
};

export type ImportNodeError = {
  __typename?: 'ImportNodeError';
  exception?: Maybe<Scalars['String']['output']>;
  line?: Maybe<Array<Scalars['String']['output']>>;
  message: Scalars['String']['output'];
};

export type ImportRedirectNode = {
  __typename?: 'ImportRedirectNode';
  id: Scalars['ID']['output'];
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export enum ImporterImportStatusChoices {
  /** New */
  A_0 = 'A_0',
  /** Validating */
  A_1 = 'A_1',
  /** Validation error */
  A_2 = 'A_2',
  /** Processing */
  A_3 = 'A_3',
  /** Completed */
  A_4 = 'A_4',
}

/**
 * ```graphql
 * mutation {
 *   inviteUser(input: {organization: 17, fullName: "test testersen", email: "test1221233@test.com", isOrgAdmin: true, disableSearchVolume: true}) {
 *     user {
 *       id
 *       fullName
 *       email
 *       language
 *       disableSearchVolume
 *       isOrgAdmin
 *       organization {
 *         id
 *       }
 *     }
 *     errors {
 *       field
 *       messages
 *     }
 *   }
 * }
 * ```
 */
export type InviteUser = {
  __typename?: 'InviteUser';
  errors?: Maybe<Array<Maybe<Error>>>;
  user?: Maybe<UserNode>;
};

/** Based on InviteUserForm */
export type InviteUserInput = {
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  isOrgAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  organization: Scalars['ID']['input'];
  /**
   * User type indicating the role of the user:
   * 1 = Organization Admin
   * 2 = Super User
   * 3 = Write User
   * 4 = Read User
   */
  userType: Scalars['Int']['input'];
  workspaces?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type InvoiceNode = GrapheneNode & {
  __typename?: 'InvoiceNode';
  accountLinkOrganization?: Maybe<OrganizationNode>;
  accrualEndDate?: Maybe<Scalars['Date']['output']>;
  amount: Scalars['Decimal']['output'];
  billysbillingId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creditedInvoice?: Maybe<InvoiceNode>;
  currency: Scalars['String']['output'];
  dueDate?: Maybe<Scalars['Date']['output']>;
  economicId?: Maybe<Scalars['String']['output']>;
  economicPaymentId?: Maybe<Scalars['String']['output']>;
  emailMessageIds?: Maybe<Array<Scalars['String']['output']>>;
  exchangeRate: Scalars['Float']['output'];
  failedPayments: FailedPaymentNodeConnection;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  invoiceSet: InvoiceNodeConnection;
  message: Scalars['String']['output'];
  number?: Maybe<Scalars['Int']['output']>;
  organization: OrganizationNode;
  paid: Scalars['Boolean']['output'];
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  payment?: Maybe<PaymentNode>;
  paymentContact: PaymentContactNode;
  poNumber?: Maybe<Scalars['String']['output']>;
  recipientCity: Scalars['String']['output'];
  recipientCountry: InvoicingInvoiceRecipientCountryChoices;
  recipientName: Scalars['String']['output'];
  recipientState: Scalars['String']['output'];
  recipientStreet: Scalars['String']['output'];
  recipientVatNumber?: Maybe<Scalars['String']['output']>;
  recipientZipCode: Scalars['String']['output'];
  total: Scalars['Decimal']['output'];
  url?: Maybe<Scalars['String']['output']>;
  vat: Scalars['Decimal']['output'];
  vatRate: Scalars['Int']['output'];
};

export type InvoiceNodeFailedPaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type InvoiceNodeInvoiceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type InvoiceNodeConnection = {
  __typename?: 'InvoiceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InvoiceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InvoiceNode` and its cursor. */
export type InvoiceNodeEdge = {
  __typename?: 'InvoiceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<InvoiceNode>;
};

export enum InvoicingInvoiceRecipientCountryChoices {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Laos */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Syria */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
}

export type KeydisCompetitorNode = {
  __typename?: 'KeydisCompetitorNode';
  domain: Scalars['String']['output'];
  keywords: Scalars['Int']['output'];
  searchVolume: Scalars['GenericScalar']['output'];
  shareOfVoice: Scalars['GenericScalar']['output'];
};

export type KeydisImportInfoNodeResolver = {
  __typename?: 'KeydisImportInfoNodeResolver';
  filters?: Maybe<Array<Maybe<Filter>>>;
  keydisImportInfo?: Maybe<KeywordImportInfoNode>;
};

export type KeydisKeywordListNode = {
  __typename?: 'KeydisKeywordListNode';
  country?: Maybe<Scalars['String']['output']>;
  displayCurrency?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  keywords?: Maybe<Scalars['GenericScalar']['output']>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
};

export type KeydisKpiNode = {
  __typename?: 'KeydisKpiNode';
  aboveTheFold?: Maybe<Scalars['Int']['output']>;
  averageCtr?: Maybe<Scalars['Float']['output']>;
  averageRank?: Maybe<Scalars['Float']['output']>;
  commercial?: Maybe<Scalars['Int']['output']>;
  count1to3?: Maybe<Scalars['Int']['output']>;
  count4to10?: Maybe<Scalars['Int']['output']>;
  count11to20?: Maybe<Scalars['Int']['output']>;
  count21to50?: Maybe<Scalars['Int']['output']>;
  count51to100?: Maybe<Scalars['Int']['output']>;
  discoveredKeywords?: Maybe<Scalars['Int']['output']>;
  estimatedVisits?: Maybe<Scalars['GenericScalar']['output']>;
  informational?: Maybe<Scalars['Int']['output']>;
  navigational?: Maybe<Scalars['Int']['output']>;
  serpFeatures?: Maybe<Scalars['GenericScalar']['output']>;
  trafficValue?: Maybe<Scalars['GenericScalar']['output']>;
  transactional?: Maybe<Scalars['Int']['output']>;
};

export type KeydisWordCloudListNode = {
  __typename?: 'KeydisWordCloudListNode';
  country?: Maybe<Scalars['String']['output']>;
  displayCurrency?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  words?: Maybe<Scalars['GenericScalar']['output']>;
};

export type KeydisWordPhraseListNode = {
  __typename?: 'KeydisWordPhraseListNode';
  country?: Maybe<Scalars['String']['output']>;
  displayCurrency?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  nWordsInPhrase?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
  wordPhrases?: Maybe<Scalars['GenericScalar']['output']>;
};

export type KeywordImportInfoNode = {
  __typename?: 'KeywordImportInfoNode';
  country?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Array<Maybe<MinimalKeywordNode>>>;
  searchSettings?: Maybe<SearchSettingNode>;
};

export type KeywordListNode = {
  __typename?: 'KeywordListNode';
  compareFrom?: Maybe<Scalars['DateTime']['output']>;
  compareTo?: Maybe<Scalars['DateTime']['output']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  keyword?: Maybe<KeywordNode>;
  keywords?: Maybe<Array<Maybe<KeywordNode>>>;
  notes?: Maybe<Array<Maybe<NoteNode>>>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
  periodFrom?: Maybe<Scalars['DateTime']['output']>;
  periodTo?: Maybe<Scalars['DateTime']['output']>;
};

export type KeywordListNodeKeywordArgs = {
  id: Scalars['ID']['input'];
};

export type KeywordListNodeNotesArgs = {
  keywordId?: InputMaybe<Scalars['ID']['input']>;
};

export type KeywordNode = GrapheneNode & {
  __typename?: 'KeywordNode';
  aiSearchVolume?: Maybe<AiSearchVolumeNode>;
  aiSearchVolumeLatest?: Maybe<AiSearchVolumeNode>;
  countrylocale: CountryLocaleNode;
  dateAdded: Scalars['Date']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  /** Description of the keyword */
  description?: Maybe<Scalars['String']['output']>;
  domain: DomainNode;
  dynamicTags?: Maybe<Array<Scalars['String']['output']>>;
  /** Enable autocorrect for searches on this keyword */
  enableAutocorrect: Scalars['Boolean']['output'];
  /** 1=green, 2=yellow, 3=red */
  highestRankingPageStatus?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Whether or not ranking on this keyword will ignore featured snippets. */
  ignoreFeaturedSnippet: Scalars['Boolean']['output'];
  /** Whether or not this keyword should be ignored in aggregated share of voice */
  ignoreInShareOfVoice: Scalars['Boolean']['output'];
  /** Whether or not ranking on this keyword will ignore local results. */
  ignoreLocalResults: Scalars['Boolean']['output'];
  ignoreVideoResults: Scalars['Boolean']['output'];
  /** Domain is ignored on the SERP when deciding the rank for the keyword */
  ignoredDomain?: Maybe<Scalars['String']['output']>;
  /** The actual keyword that is used as a search phrase */
  keyword: Scalars['String']['output'];
  keywordrankalertSet: NotificationNodeConnection;
  /** A specific location to narrow down the search for this keyword, eg. 'London, UK' */
  location?: Maybe<Scalars['String']['output']>;
  preferredLandingPage?: Maybe<LandingPageNode>;
  searchEngine: SearchEngineNode;
  /** 1 = Desktop and 2 = Mobile */
  searchType: KeywordsKeywordSearchTypeChoices;
  searchVolume?: Maybe<SearchVolumeNode>;
  searchVolumeLocalTmp?: Maybe<SearchVolumeNode>;
  searchVolumeTmp?: Maybe<SearchVolumeNode>;
  slug: Scalars['String']['output'];
  /** Mark the keyword as starred */
  starred: Scalars['Boolean']['output'];
  /** List of tags for the keyword. Used for filtering or grouping */
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

export type KeywordNodeKeywordrankalertSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type KeywordNodeConnection = {
  __typename?: 'KeywordNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<KeywordNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `KeywordNode` and its cursor. */
export type KeywordNodeEdge = {
  __typename?: 'KeywordNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<KeywordNode>;
};

/** Enum representing possible error codes for adding keywords under various scenarios. */
export enum KeywordOveruseCode {
  EnableOveruseActiveLimit = 'ENABLE_OVERUSE_ACTIVE_LIMIT',
  EnableOveruseTrackedLimit = 'ENABLE_OVERUSE_TRACKED_LIMIT',
  RequestEnableOveruseActiveLimit = 'REQUEST_ENABLE_OVERUSE_ACTIVE_LIMIT',
  RequestEnableOveruseTrackedLimit = 'REQUEST_ENABLE_OVERUSE_TRACKED_LIMIT',
  RequestUpgradePlanActiveLimit = 'REQUEST_UPGRADE_PLAN_ACTIVE_LIMIT',
  RequestUpgradePlanTrackedLimit = 'REQUEST_UPGRADE_PLAN_TRACKED_LIMIT',
  UpgradePlanActiveLimit = 'UPGRADE_PLAN_ACTIVE_LIMIT',
  UpgradePlanTrackedLimit = 'UPGRADE_PLAN_TRACKED_LIMIT',
}

export type KeywordOveruseOverviewListNode = {
  __typename?: 'KeywordOveruseOverviewListNode';
  dates?: Maybe<Array<Maybe<KeywordOveruseOverviewNode>>>;
};

export type KeywordOveruseOverviewNode = {
  __typename?: 'KeywordOveruseOverviewNode';
  countGreen?: Maybe<Scalars['Int']['output']>;
  countRed?: Maybe<Scalars['Int']['output']>;
  countYellow?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  missingScrapes?: Maybe<Scalars['Int']['output']>;
  tense?: Maybe<Scalars['String']['output']>;
};

export type KeywordPriceNode = {
  __typename?: 'KeywordPriceNode';
  pricePerKeywordPerDay?: Maybe<PricePerKeywordPerDayNode>;
  saveOnUpgrade?: Maybe<SaveOnUpgradeNode>;
};

export type KeywordRankWithCompetitorRankNode = {
  __typename?: 'KeywordRankWithCompetitorRankNode';
  aboveTheFold?: Maybe<Scalars['Boolean']['output']>;
  competitor1CompetitorId?: Maybe<Scalars['ID']['output']>;
  competitor1Rank?: Maybe<Scalars['Int']['output']>;
  competitor1RankCompare?: Maybe<Scalars['Int']['output']>;
  competitor2CompetitorId?: Maybe<Scalars['ID']['output']>;
  competitor2Rank?: Maybe<Scalars['Int']['output']>;
  competitor2RankCompare?: Maybe<Scalars['Int']['output']>;
  competitor3CompetitorId?: Maybe<Scalars['ID']['output']>;
  competitor3Rank?: Maybe<Scalars['Int']['output']>;
  competitor3RankCompare?: Maybe<Scalars['Int']['output']>;
  competitor4CompetitorId?: Maybe<Scalars['ID']['output']>;
  competitor4Rank?: Maybe<Scalars['Int']['output']>;
  competitor4RankCompare?: Maybe<Scalars['Int']['output']>;
  competitor5CompetitorId?: Maybe<Scalars['ID']['output']>;
  competitor5Rank?: Maybe<Scalars['Int']['output']>;
  competitor5RankCompare?: Maybe<Scalars['Int']['output']>;
  competitor6CompetitorId?: Maybe<Scalars['ID']['output']>;
  competitor6Rank?: Maybe<Scalars['Int']['output']>;
  competitor6RankCompare?: Maybe<Scalars['Int']['output']>;
  competitor7CompetitorId?: Maybe<Scalars['ID']['output']>;
  competitor7Rank?: Maybe<Scalars['Int']['output']>;
  competitor7RankCompare?: Maybe<Scalars['Int']['output']>;
  competitor8CompetitorId?: Maybe<Scalars['ID']['output']>;
  competitor8Rank?: Maybe<Scalars['Int']['output']>;
  competitor8RankCompare?: Maybe<Scalars['Int']['output']>;
  competitor9CompetitorId?: Maybe<Scalars['ID']['output']>;
  competitor9Rank?: Maybe<Scalars['Int']['output']>;
  competitor9RankCompare?: Maybe<Scalars['Int']['output']>;
  competitor10CompetitorId?: Maybe<Scalars['ID']['output']>;
  competitor10Rank?: Maybe<Scalars['Int']['output']>;
  competitor10RankCompare?: Maybe<Scalars['Int']['output']>;
  countrylocaleId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  keyword?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  noSearchVolumeForLocation?: Maybe<Scalars['Boolean']['output']>;
  rankCompare?: Maybe<Scalars['Int']['output']>;
  rankValue?: Maybe<Scalars['Int']['output']>;
  searchEngineId?: Maybe<Scalars['Int']['output']>;
  searchTypeId?: Maybe<Scalars['Int']['output']>;
  searchVolumeValue?: Maybe<Scalars['Int']['output']>;
  validForSearchVolume?: Maybe<Scalars['Boolean']['output']>;
};

export type KeywordsForDomainNode = {
  __typename?: 'KeywordsForDomainNode';
  count?: Maybe<CountNode>;
  country?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
};

export enum KeywordsKeywordRankAlertTypeChoices {
  /** Goal hit */
  A_10 = 'A_10',
  /** Major jump */
  A_20 = 'A_20',
  /** Started ranking */
  A_30 = 'A_30',
  /** Stopped ranking */
  A_40 = 'A_40',
  /** Major drop */
  A_50 = 'A_50',
  /** Goal missed */
  A_60 = 'A_60',
  /** Preferred URL started ranking */
  A_70 = 'A_70',
  /** Preferred URL stopped ranking */
  A_80 = 'A_80',
}

export enum KeywordsKeywordSearchTypeChoices {
  /** Desktop */
  A_1 = 'A_1',
  /** Mobile */
  A_2 = 'A_2',
}

export type KpisNode = {
  __typename?: 'KpisNode';
  allKpis?: Maybe<AllKpis>;
  displayCurrency?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  name?: Maybe<Scalars['String']['output']>;
  publicAllKpis?: Maybe<AllKpis>;
};

export type KpisNodePublicAllKpisArgs = {
  slug: Scalars['String']['input'];
  viewkey: Scalars['String']['input'];
};

export type LandingPageNode = GrapheneNode & {
  __typename?: 'LandingPageNode';
  active: Scalars['Boolean']['output'];
  dateAdded: Scalars['Date']['output'];
  domain: DomainNode;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  keywordSet: KeywordNodeConnection;
  path?: Maybe<Scalars['String']['output']>;
  starred: Scalars['Boolean']['output'];
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

export type LandingPageNodeKeywordSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type LandingPageNodeConnection = {
  __typename?: 'LandingPageNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LandingPageNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `LandingPageNode` and its cursor. */
export type LandingPageNodeEdge = {
  __typename?: 'LandingPageNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<LandingPageNode>;
};

export type LandingpageGraphListNode = {
  __typename?: 'LandingpageGraphListNode';
  days?: Maybe<Array<Maybe<HistoryGraphNode>>>;
  path?: Maybe<Scalars['String']['output']>;
};

export type LockOrganization = {
  __typename?: 'LockOrganization';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type LockOrganizationInput = {
  accountBlockedCustomMessage?: InputMaybe<Scalars['String']['input']>;
  blockedDomainIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  organizationId: Scalars['ID']['input'];
  type: Scalars['Int']['input'];
};

export type LoggedEmailNode = GrapheneNode & {
  __typename?: 'LoggedEmailNode';
  bouncedAt?: Maybe<Scalars['DateTime']['output']>;
  clickedAt?: Maybe<Scalars['DateTime']['output']>;
  complainedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  fromEmail?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  messageId: Scalars['UUID']['output'];
  mtaAccept?: Maybe<Scalars['Boolean']['output']>;
  mtaError?: Maybe<Scalars['String']['output']>;
  notifications: Array<Scalars['String']['output']>;
  notificationsTypes: Array<Scalars['String']['output']>;
  opened: Scalars['Boolean']['output'];
  openedAt?: Maybe<Scalars['DateTime']['output']>;
  recipients: Array<Scalars['String']['output']>;
  replyTo?: Maybe<Array<Scalars['String']['output']>>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type LoginInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  otpRequired?: Maybe<Scalars['Boolean']['output']>;
  password: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserNode>;
  username: Scalars['String']['output'];
};

export type LoginTwoFactorInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  otpChallenge?: InputMaybe<Scalars['String']['input']>;
  otpDevice?: InputMaybe<Scalars['String']['input']>;
  otpToken?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type LoginTwoFactorPayload = {
  __typename?: 'LoginTwoFactorPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  otpChallenge?: Maybe<Scalars['String']['output']>;
  otpDevice?: Maybe<Scalars['String']['output']>;
  otpToken?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserNode>;
  username: Scalars['String']['output'];
};

export type Logout = {
  __typename?: 'Logout';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MessageNode = GrapheneNode & {
  __typename?: 'MessageNode';
  createdAt: Scalars['DateTime']['output'];
  href?: Maybe<Scalars['String']['output']>;
  hrefText: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  level?: Maybe<Scalars['String']['output']>;
  levelId?: Maybe<Scalars['Int']['output']>;
  organization: OrganizationNode;
  readAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  textId: AccurankerMessagesMessageTextIdChoices;
  textParams: Array<Scalars['String']['output']>;
};

export type MessageNodeConnection = {
  __typename?: 'MessageNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MessageNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MessageNode` and its cursor. */
export type MessageNodeEdge = {
  __typename?: 'MessageNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<MessageNode>;
};

export type MetaDataCountrylocaleNode = {
  __typename?: 'MetaDataCountrylocaleNode';
  countryCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type MetaDataNode = {
  __typename?: 'MetaDataNode';
  countrylocales?: Maybe<Array<Maybe<MetaDataCountrylocaleNode>>>;
  searchEngines?: Maybe<Array<Maybe<MetaDataSearchEngineNode>>>;
  searchTypes?: Maybe<Array<Maybe<MetaDataSearchTypeNode>>>;
  serpFeatures?: Maybe<Array<Maybe<MetaDataSerpFeatureNode>>>;
};

export type MetaDataSearchEngineNode = {
  __typename?: 'MetaDataSearchEngineNode';
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MetaDataSearchTypeNode = {
  __typename?: 'MetaDataSearchTypeNode';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MetaDataSerpFeatureNode = {
  __typename?: 'MetaDataSerpFeatureNode';
  description?: Maybe<Scalars['String']['output']>;
  googleSupport?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  naverDescription?: Maybe<Scalars['String']['output']>;
  naverSupport?: Maybe<Scalars['Boolean']['output']>;
  owned?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  youtubeSupport?: Maybe<Scalars['Boolean']['output']>;
};

export type MinimalKeywordNode = {
  __typename?: 'MinimalKeywordNode';
  keyword?: Maybe<Scalars['String']['output']>;
};

export type MoveDomain = {
  __typename?: 'MoveDomain';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type MoveDomainInput = {
  /** Group id */
  client: Scalars['ID']['input'];
  /** Domain id */
  id: Scalars['ID']['input'];
};

export type MultiAccountLinkNode = GrapheneNode & {
  __typename?: 'MultiAccountLinkNode';
  canImpersonate: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  fromOrganization?: Maybe<OrganizationNode>;
  fromOrganizationDiscountPercent?: Maybe<Scalars['Int']['output']>;
  fromOrganizationDiscountType?: Maybe<OrganizationsMultiAccountLinkFromOrganizationDiscountTypeChoices>;
  fromOrganizationPays: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  isOrgAdmin: Scalars['Boolean']['output'];
  toEmail?: Maybe<Scalars['String']['output']>;
  toOrganization?: Maybe<OrganizationNode>;
};

/** The project root mutation definition */
export type Mutations = {
  __typename?: 'Mutations';
  /** This will accept a pending access request. */
  acceptMultiAccountRequest?: Maybe<AcceptMultiAccountRequest>;
  accurankerAnalytics?: Maybe<AccurankerAnalytics>;
  addAdobeMarketingAccount?: Maybe<AddAdobeMarketingAccount>;
  addAdobeMarketingConnection?: Maybe<AddAdobeMarketingConnection>;
  addAffiliate?: Maybe<AddAffiliate>;
  /**
   * ```graphql
   * mutation {
   *   addClient(input:{organization:16 name:"test test"}) {
   *     errors {
   *       field
   *       messages
   *     }
   *     client {
   *       id
   *     }
   *   }
   * }
   * ```
   */
  addClient?: Maybe<AddClient>;
  addCompetitor?: Maybe<AddCompetitor>;
  addDemoContent?: Maybe<AddDemoContent>;
  addDomain?: Maybe<AddDomain>;
  addDynamicTagRuleset?: Maybe<AddDynamicTagRuleset>;
  addGoogleAnalyticsAccount?: Maybe<AddGoogleAnalyticsAccount>;
  /**
   * ```graphql
   * mutation {
   *   addGoogleAccount(input: {code:"4/RlhhEFXD8lLZq6IHZJmBEfn0wrwxk1r5D9kXSGctx3g", description:"beskrivelse" redirectUri: "http://localhost.dk:8000/org/googleoauth/callback/"}) {
   *     connection {
   *       id
   *     }
   *   }
   * }
   * ```
   */
  addGoogleConnection?: Maybe<AddGoogleConnection>;
  addGoogleSearchConsoleAccount?: Maybe<AddGoogleSearchConsoleAccount>;
  addKeywordResearchDomainSearch?: Maybe<AddKeywordResearchDomainSearch>;
  /**
   * ```graphql
   * mutation {
   *   addKeywords(input: {
   *     domain: 9
   *     keywords: ["1234"]
   *     countrylocale: 4
   *     locations: []
   *     searchEngines: [
   *       {
   *         id: 1,
   *         searchTypes: [1, 2]
   *       }
   *     ]
   *     ignoreLocalResults: false
   *     ignoreFeaturedSnippet: false
   *     starred: false
   *     tags: []
   *   }){
   *     errors {
   *       field
   *       messages
   *     }
   *   }
   * }
   * ```
   */
  addKeywords?: Maybe<AddKeywords>;
  addKeywordsNew?: Maybe<AddKeywordsNew>;
  addMultiAccountRequest?: Maybe<AddMultiAccountRequest>;
  /**
   * ```graphql
   * mutation {
   *   addNote(input: {
   *     createdAt: "2017-11-11"
   *     domain: 30
   *     note: "test note"
   *     keywords: [568, 571]
   *     filter: [{'attribute': 'period', 'type': 'datetime', 'comparison': 'between', 'value': '["2020-05-04","latest"]'}, {'attribute': 'compare_to', 'type': 'datetime', 'comparison': 'eq', 'value': '2020-05-04'}, {'attribute': 'domains', 'type': 'list', 'comparison': 'contains', 'value': "['6']"}, {'attribute': 'rank', 'type': 'num', 'comparison': 'lt', 'value': '2'}]
   *   }) {
   *     errors {
   *       field
   *       messages
   *     }
   *     note {
   *       id
   *     }
   *   }
   * }
   * ```
   */
  addNote?: Maybe<AddNote>;
  addOrganizationNote?: Maybe<AddOrganizationNote>;
  addPrepaidVoucher?: Maybe<AddPrepaidVoucher>;
  /**
   * ```graphql
   * mutation {
   *   addReportTemplate(input: {organization: 1, brandColor: "#FFA726", template: "[{}]", name: "test"}) {
   *     reportTemplate {
   *       id
   *       organization {
   *         id
   *       }
   *       brandColor
   *       template
   *       name
   *     }
   *     errors {
   *       field
   *       messages
   *     }
   *   }
   * }
   * ```
   */
  addReportTemplate?: Maybe<AddReportTemplate>;
  /**
   * If you set isGroupReport you must specify Group. Otherwise you must specify a domain.
   * Domain and group cannot be set at the same time.
   *
   * If isOneTimeReport == false then required filters is not allowed.
   *
   * ```graphql
   * mutation {
   *   addScheduledReport(input: {
   *     isGroupReport: false,
   *     isOneTimeReport: true,
   *     withHistory: false,
   *     domain: 134,
   *     reportType: 1,
   *     reportTemplate: 1,
   *     reportFilter: { filters: "[{"attribute":"period","type":"datetime","comparison":"between","value":"[\"2017-01-01\", \"2017-12-23\"]"},{"attribute":"domains","type":"list","comparison":"contains","value":"[2]"},{"attribute":"compare_to","type":"datetime","comparison":"eq","value":"2017-10-10"}, {"attribute": "keywords", "type": "list", "comparison": "contains", "value": "[1]"}]" }, schedule: 1, recipients: ["cm@accuranker.com"], sender: "cm-sender@accuranker.com", emailSubject: "subject", emailBody: "body", language: "da"}
   *   ) {
   *     scheduledReport {
   *       id
   *     }
   *     errors {
   *       field
   *       messages
   *     }
   *   }
   * }
   * ```
   */
  addScheduledReport?: Maybe<AddScheduledReport>;
  answerFeedbackQuestion?: Maybe<AnswerFeedbackQuestion>;
  bulkUpgradeUaToGa4?: Maybe<BulkUpgradeUaToGa4>;
  cancelPlan?: Maybe<CancelPlan>;
  checkVat?: Maybe<CheckVat>;
  createAndPaySubAccount?: Maybe<CreateAndPaySubAccount>;
  /**
   * email: email of the user
   *
   * ```
   * mutation {
   *   createCustomTrial(input: {email: "test@test.dk"}) {
   *     unconfirmedUser {
   *         email
   *     }
   *   }
   * }
   */
  createCustomTrial?: Maybe<CreateCustomTrial>;
  /**
   * ```graphql
   * mutation {
   *   createFilter(input: {name: "random name", type: 1, filters: "[{"attribute":"period","type":"datetime","comparison":"between","value":"[\"2017-01-01\", \"2017-12-23\"]"},{"attribute":"domains","type":"list","comparison":"contains","value":"[2]"},{"attribute":"compare_to","type":"datetime","comparison":"eq","value":"2017-10-10"}]"}) {
   *     errors {
   *       field
   *       messages
   *     }
   *     filter {
   *       id
   *       filters
   *     }
   *   }
   * }
   * ```
   */
  createFilter?: Maybe<CreateUserFilter>;
  createInvoice?: Maybe<CreateInvoice>;
  createPlan?: Maybe<CreatePlan>;
  createSubAccount?: Maybe<CreateSubAccount>;
  createWallet?: Maybe<CreateWallet>;
  createWorkspace?: Maybe<CreateWorkspace>;
  debug?: Maybe<DjangoDebug>;
  deleteDomain?: Maybe<DeleteDomain>;
  deleteDynamicTagRuleset?: Maybe<DeleteDynamicTagRuleset>;
  deleteDynamicTagRulesetFromTag?: Maybe<DeleteDynamicTagRulesetFromTag>;
  deleteFilter?: Maybe<DeleteUserFilter>;
  deleteOrganizationNote?: Maybe<DeleteOrganizationNote>;
  deleteScheduledReport?: Maybe<DeleteScheduledReport>;
  deleteStaticTag?: Maybe<DeleteStaticTag>;
  deleteUploadImport?: Maybe<DeleteUploadImport>;
  deleteWorkspace?: Maybe<DeleteWorkspace>;
  editIntegrationTapclicks?: Maybe<EditIntegrationTapclicks>;
  editOrganizationNote?: Maybe<EditOrganizationNote>;
  enableOveruse?: Maybe<EnableOveruse>;
  exportAdTracking?: Maybe<ExportAdTracking>;
  extendPlan?: Maybe<ExtendPlan>;
  extendTrial?: Maybe<ExpandTrialPlan>;
  /**
   * ```graphql
   * mutation {
   *   googleConnectionUrl(input: {redirectUri: "http://localhost.dk:8000/dashboard/"}) {
   *     authorizeUrl
   *   }
   * }
   *
   * if authorizeUrl is none, then there was an error.
   * ```
   */
  googleConnectionUrl?: Maybe<GoogleConnectionUrl>;
  importKeydisKeywords?: Maybe<ImportKeydisKeywords>;
  importKeydisKeywordsTopN?: Maybe<ImportKeydisKeywordsTopN>;
  /**
   * ```graphql
   * mutation {
   *   inviteUser(input: {organization: 17, fullName: "test testersen", email: "test1221233@test.com", isOrgAdmin: true, disableSearchVolume: true}) {
   *     user {
   *       id
   *       fullName
   *       email
   *       language
   *       disableSearchVolume
   *       isOrgAdmin
   *       organization {
   *         id
   *       }
   *     }
   *     errors {
   *       field
   *       messages
   *     }
   *   }
   * }
   * ```
   */
  inviteUser?: Maybe<InviteUser>;
  lockOrganization?: Maybe<LockOrganization>;
  moveDomain?: Maybe<MoveDomain>;
  payAffiliate?: Maybe<PayAffiliate>;
  performPasswordReset?: Maybe<PerformPasswordResetPayload>;
  rateFeedbackQuestion?: Maybe<RateFeedbackQuestion>;
  reactivate?: Maybe<ReActivate>;
  refreshAiSearchVolume?: Maybe<RefreshAiSearchVolume>;
  refund?: Maybe<Refund>;
  removeAdobeMarketingAccount?: Maybe<RemoveAdobeMarketingAccount>;
  removeDriveConnection?: Maybe<RemoveDriveConnection>;
  removeGoogleAnalyticsAccount?: Maybe<RemoveGoogleAnalyticsAccount>;
  removeGoogleSearchConsoleAccount?: Maybe<RemoveGoogleSearchConsoleAccount>;
  /** This will remove an approved sub-account. If the from_org is paying the sub-accounts plan will be cancelled. */
  removeMultiAccount?: Maybe<RemoveMultiAccount>;
  /** This will remove a pending access request. */
  removeMultiAccountRequest?: Maybe<RemoveMultiAccountRequest>;
  reportBug?: Maybe<ReportBug>;
  requestCancellation?: Maybe<CancellationRequestMutation>;
  requestPasswordReset?: Maybe<RequestPasswordResetPayload>;
  resetViewkeyClient?: Maybe<ResetViewKeyClient>;
  resetViewkeyDomain?: Maybe<ResetViewKeyDomain>;
  retryPayment?: Maybe<RetryPayment>;
  scheduleCompetitorRanksReport?: Maybe<ScheduleCompetitorRanksReport>;
  scheduleCompetitorsReport?: Maybe<ScheduleCompetitorsReport>;
  scheduleFoldersReport?: Maybe<ScheduleFoldersReport>;
  /**
   * ```graphql
   * mutation {
   *   scrapeDomain(input:{id: 3}) {
   *     domain {
   *       id
   *     }
   *     queued
   *   }
   * }
   * ```
   */
  scrapeDomain?: Maybe<ScrapeDomain>;
  /** Sets an EAN invoice as paid. Required arguments are invoice_id. */
  setEanInvoicePaid?: Maybe<SetEanInvoicePaid>;
  /** Sets an invoice as paid and registers the payment id. Required arguments are invoice_id and payment_id. */
  setInvoicePaid?: Maybe<SetInvoicePaid>;
  setLeadScore?: Maybe<SetLeadScore>;
  setPaymentContact?: Maybe<PaymentContactMutation>;
  setPaymentMethod?: Maybe<PaymentMethodMutation>;
  setSalesManager?: Maybe<SetSalesManager>;
  shouldRecordSession?: Maybe<ShouldRecordSession>;
  simpleLog?: Maybe<SimpleLog>;
  softDeleteUser?: Maybe<SoftDeleteUser>;
  transferMultiAccountDomain?: Maybe<TransferMultiAccountDomain>;
  updateAdobeMarketingConnection?: Maybe<UpdateAdobeMarketingConnection>;
  updateAllRead?: Maybe<UpdateAllRead>;
  /**
   * ```graphql
   * mutation {
   *   updateClient(input:{id: 1 name:"test test"}) {
   *     errors {
   *       field
   *       messages
   *     }
   *     client {
   *       id
   *     }
   *   }
   * }
   * ```
   */
  updateClient?: Maybe<UpdateClient>;
  updateCompetitor?: Maybe<UpdateCompetitor>;
  updateDomain?: Maybe<UpdateDomain>;
  updateDomainDisplayCurrency?: Maybe<UpdateDomainDisplayCurrency>;
  updateDomainSpecialSettings?: Maybe<UpdateDomainSpecialSettings>;
  updateDynamicTagRuleset?: Maybe<UpdateDynamicTagRuleset>;
  /**
   * ```graphql
   * mutation {
   *   updateFilter(input: {id: 2, name:"change name 1" type: 1 filters:"{"bla": "test"}"}){
   *     errors {
   *       field
   *       messages
   *     }
   *     filters {
   *       id
   *       name
   *     }
   *   }
   * }
   * ```
   */
  updateFilter?: Maybe<UpdateUserFilter>;
  updateFolderDescription?: Maybe<UpdateFolderDescription>;
  updateFolderStructure?: Maybe<UpdateFolderStructure>;
  /**
   * ```graphql
   * mutation {
   *   updateGeneratedReport(input: {id: 8, delete: true}) {
   *     reportTemplate {
   *       id
   *       organization {
   *         id
   *       }
   *       brandColor
   *       template
   *       name
   *     }
   *     errors {
   *       field
   *       messages
   *     }
   *   }
   * }
   * ```
   */
  updateGeneratedReport?: Maybe<UpdateGeneratedReport>;
  /**
   * ```graphql
   * mutation {
   *   updateGoogleAccount(input: {id: 123, description:"beskrivelse" delete: false}) {
   *     connection {
   *       id
   *     }
   *   }
   * }
   * ```
   */
  updateGoogleConnection?: Maybe<UpdateGoogleConnection>;
  /**
   * Update the contact details on an issued invoice.
   *
   * It will NOT change the details if country or VAT number has changed.
   */
  updateInvoiceContact?: Maybe<UpdateInvoiceContact>;
  updateKeywords?: Maybe<UpdateKeywords>;
  /**
   * ```graphql
   * mutation {
   *   updateMyUser(input: {fullName: "test testersen", email: "test123@test.com", language:"en"}) {
   *     user {
   *       id
   *       fullName
   *       email
   *       language
   *     }
   *     errors {
   *       field
   *       messages
   *     }
   *   }
   * }
   * ```
   */
  updateMyUser?: Maybe<UpdateMyUser>;
  /**
   * ```graphql
   * mutation {
   *   updateMyUserPassword(input: {oldPassword:"accuranker123" password:"123accuranker"}) {
   *     user {
   *       id
   *     }
   *     errors {
   *       field
   *       messages
   *     }
   *   }
   * }
   * ```
   */
  updateMyUserPassword?: Maybe<UpdateMyUserPassword>;
  /**
   * ```graphql
   * mutation {
   *   updateNote(input: {id: 3, createdAt: "2017-11-11", note: "test note 123"}) {
   *     errors {
   *       field
   *       messages
   *     }
   *     note {
   *       id
   *       note
   *     }
   *   }
   * }
   * ```
   */
  updateNote?: Maybe<UpdateNote>;
  updateOrganization?: Maybe<UpdateOrganization>;
  updateOrganizationPlan?: Maybe<UpdateOrganizationPlan>;
  updatePublicReportSettings?: Maybe<UpdatePublicReportSettings>;
  updateRead?: Maybe<UpdateRead>;
  /**
   * ```graphql
   * mutation {
   *   updateReportTemplate(input: {id: 8, brandColor: "#FFA726", template: "[{}]", name: "test 123", delete: false, default: false}) {
   *     reportTemplate {
   *       id
   *       organization {
   *         id
   *       }
   *       brandColor
   *       template
   *       name
   *     }
   *     errors {
   *       field
   *       messages
   *     }
   *   }
   * }
   * ```
   */
  updateReportTemplate?: Maybe<UpdateReportTemplate>;
  /**
   * Same rules apply here as in AddScheduledReport
   *
   * ```graphql
   * mutation {
   *   updateScheduledReport(input: {id: 1, isGroupReport: false, isOneTimeReport: false, domain: 2, reportType: 1, reportTemplate: 1, reportFilter: 1, schedule: 1, recipients: ["cm@accuranker.com"], sender: "cm-sender@accuranker.com", emailSubject: "subject", emailBody: "body", language: "da"}) {
   *     scheduledReport {
   *       id
   *     }
   *     errors {
   *       field
   *       messages
   *     }
   *   }
   * }
   * ```
   */
  updateScheduledReport?: Maybe<UpdateScheduledReport>;
  /**
   * ```graphql
   * mutation {
   *   updateScheduledReportRecipients(input: {id: 1, recipients: ["cm@accuranker.com"]}) {
   *     scheduledReport {
   *       id
   *     }
   *     errors {
   *       field
   *       messages
   *     }
   *   }
   * }
   * ```
   */
  updateScheduledReportRecipients?: Maybe<UpdateScheduledReportRecipients>;
  updateStaticTag?: Maybe<UpdateStaticTag>;
  updateUploadImport?: Maybe<UpdateUploadImport>;
  /**
   * ```graphql
   * mutation {
   *   updateUser(input: {id: 1, fullName: "test testersen", email: "test123@test.com", isOrgAdmin: true, disableSearchVolume: true}) {
   *     user {
   *       id
   *       fullName
   *       email
   *       language
   *       disableSearchVolume
   *       isOrgAdmin
   *     }
   *     errors {
   *       field
   *       messages
   *     }
   *   }
   * }
   * ```
   */
  updateUser?: Maybe<UpdateUser>;
  updateUserSettings?: Maybe<UpdateUserSettings>;
  updateWizardProgressOrganization?: Maybe<UpdateWizardProgressOrganization>;
  updateWorkspace?: Maybe<UpdateWorkspace>;
  userLogin?: Maybe<LoginPayload>;
  userLoginTwoFactor?: Maybe<LoginTwoFactorPayload>;
  userLogout?: Maybe<Logout>;
  userPhoneVerification?: Maybe<RegisterPhoneVerification>;
  userRegister?: Maybe<Register>;
  userRegisterCompleteWizard?: Maybe<RegisterCompleteWizard>;
  /** Used for prepaid voucher campaigns ONLY */
  userSignup?: Maybe<SignUp>;
  /**
   * backend: `google_oauth2` or `facebook`
   *
   * ```graphql
   * mutation {
   *   userSocialAuthDisconnect(input: {backend: "google_oauth2"}) {
   *     user {
   *       socialAuths {
   *         facebook
   *         facebookUrl
   *         googleOauth2
   *         googleOauth2Url
   *       }
   *     }
   *   }
   * }
   * ```
   */
  userSocialAuthDisconnect?: Maybe<UserSocialAuthDisconnect>;
};

/** The project root mutation definition */
export type MutationsAcceptMultiAccountRequestArgs = {
  input: AcceptMultiAccountRequestInput;
};

/** The project root mutation definition */
export type MutationsAccurankerAnalyticsArgs = {
  input: AccurankerAnalyticsInput;
};

/** The project root mutation definition */
export type MutationsAddAdobeMarketingAccountArgs = {
  input: AddAdobeMarketingAccountInput;
};

/** The project root mutation definition */
export type MutationsAddAdobeMarketingConnectionArgs = {
  input: AddAdobeMarketingConnectionInput;
};

/** The project root mutation definition */
export type MutationsAddAffiliateArgs = {
  input: AddAffiliateInput;
};

/** The project root mutation definition */
export type MutationsAddClientArgs = {
  input: AddClientInput;
};

/** The project root mutation definition */
export type MutationsAddCompetitorArgs = {
  input: AddCompetitorInput;
};

/** The project root mutation definition */
export type MutationsAddDemoContentArgs = {
  input: AddDemoContentInput;
};

/** The project root mutation definition */
export type MutationsAddDomainArgs = {
  input: AddDomainInput;
};

/** The project root mutation definition */
export type MutationsAddDynamicTagRulesetArgs = {
  input: AddDynamicTagRulesetInput;
};

/** The project root mutation definition */
export type MutationsAddGoogleAnalyticsAccountArgs = {
  input: AddGoogleAnalyticsAccountInput;
};

/** The project root mutation definition */
export type MutationsAddGoogleConnectionArgs = {
  input: AddGoogleConnectionInput;
};

/** The project root mutation definition */
export type MutationsAddGoogleSearchConsoleAccountArgs = {
  input: AddGoogleSearchConsoleAccountInput;
};

/** The project root mutation definition */
export type MutationsAddKeywordResearchDomainSearchArgs = {
  searchTerm: Scalars['String']['input'];
};

/** The project root mutation definition */
export type MutationsAddKeywordsArgs = {
  input: AddKeywordsInput;
};

/** The project root mutation definition */
export type MutationsAddKeywordsNewArgs = {
  input: AddKeywordsInputNew;
};

/** The project root mutation definition */
export type MutationsAddMultiAccountRequestArgs = {
  input: AddMultiAccountRequestInput;
};

/** The project root mutation definition */
export type MutationsAddNoteArgs = {
  input: AddNoteInput;
};

/** The project root mutation definition */
export type MutationsAddOrganizationNoteArgs = {
  input: OrganizationNoteAddInput;
};

/** The project root mutation definition */
export type MutationsAddPrepaidVoucherArgs = {
  input: AddPrepaidVoucherInput;
};

/** The project root mutation definition */
export type MutationsAddReportTemplateArgs = {
  input: AddReportTemplateInput;
};

/** The project root mutation definition */
export type MutationsAddScheduledReportArgs = {
  input: CreateScheduledReportInput;
};

/** The project root mutation definition */
export type MutationsAnswerFeedbackQuestionArgs = {
  input: AnswerFeedbackQuestionInput;
};

/** The project root mutation definition */
export type MutationsBulkUpgradeUaToGa4Args = {
  input: BulkUpgradeUaToGa4Input;
};

/** The project root mutation definition */
export type MutationsCancelPlanArgs = {
  input: CancelPlanInput;
};

/** The project root mutation definition */
export type MutationsCheckVatArgs = {
  number: Scalars['String']['input'];
  prefix: Scalars['String']['input'];
};

/** The project root mutation definition */
export type MutationsCreateAndPaySubAccountArgs = {
  input: CreateAndPaySubAccountInput;
};

/** The project root mutation definition */
export type MutationsCreateCustomTrialArgs = {
  input: CreateCustomTrialInput;
};

/** The project root mutation definition */
export type MutationsCreateFilterArgs = {
  input: CreateUserFilterInput;
};

/** The project root mutation definition */
export type MutationsCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};

/** The project root mutation definition */
export type MutationsCreatePlanArgs = {
  input: CreatePlanInput;
};

/** The project root mutation definition */
export type MutationsCreateSubAccountArgs = {
  input: CreateSubAccountInput;
};

/** The project root mutation definition */
export type MutationsCreateWalletArgs = {
  input: CreateWalletInput;
};

/** The project root mutation definition */
export type MutationsCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};

/** The project root mutation definition */
export type MutationsDeleteDomainArgs = {
  input: DeleteDomainInput;
};

/** The project root mutation definition */
export type MutationsDeleteDynamicTagRulesetArgs = {
  input: DeleteDynamicTagRulesetInput;
};

/** The project root mutation definition */
export type MutationsDeleteDynamicTagRulesetFromTagArgs = {
  domainId: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
};

/** The project root mutation definition */
export type MutationsDeleteFilterArgs = {
  input: DeleteUserFilterInput;
};

/** The project root mutation definition */
export type MutationsDeleteOrganizationNoteArgs = {
  input: OrganizationNoteDeleteInput;
};

/** The project root mutation definition */
export type MutationsDeleteScheduledReportArgs = {
  input: DeleteScheduledReportInput;
};

/** The project root mutation definition */
export type MutationsDeleteStaticTagArgs = {
  domainId: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
};

/** The project root mutation definition */
export type MutationsDeleteUploadImportArgs = {
  input: DeleteUploadImportInput;
};

/** The project root mutation definition */
export type MutationsDeleteWorkspaceArgs = {
  workspaceId: Scalars['ID']['input'];
};

/** The project root mutation definition */
export type MutationsEditIntegrationTapclicksArgs = {
  input: EditIntegrationTapclicksInput;
};

/** The project root mutation definition */
export type MutationsEditOrganizationNoteArgs = {
  input: OrganizationNoteEditInput;
};

/** The project root mutation definition */
export type MutationsEnableOveruseArgs = {
  input: EnableOveruseInput;
};

/** The project root mutation definition */
export type MutationsExportAdTrackingArgs = {
  input: ExportAdTrackingInput;
};

/** The project root mutation definition */
export type MutationsExtendPlanArgs = {
  input: ExtendPlanInput;
};

/** The project root mutation definition */
export type MutationsExtendTrialArgs = {
  newSize: Scalars['Int']['input'];
  planId: Scalars['Int']['input'];
};

/** The project root mutation definition */
export type MutationsGoogleConnectionUrlArgs = {
  input: GoogleAccountUrlInput;
};

/** The project root mutation definition */
export type MutationsImportKeydisKeywordsArgs = {
  forceAdd?: InputMaybe<Scalars['Boolean']['input']>;
  input: ImportKeydisKeywordsInput;
};

/** The project root mutation definition */
export type MutationsImportKeydisKeywordsTopNArgs = {
  input: BaseImportKeydisInput;
};

/** The project root mutation definition */
export type MutationsInviteUserArgs = {
  input: InviteUserInput;
};

/** The project root mutation definition */
export type MutationsLockOrganizationArgs = {
  input: LockOrganizationInput;
};

/** The project root mutation definition */
export type MutationsMoveDomainArgs = {
  input: MoveDomainInput;
};

/** The project root mutation definition */
export type MutationsPayAffiliateArgs = {
  input: PayAffiliateInput;
};

/** The project root mutation definition */
export type MutationsPerformPasswordResetArgs = {
  input: PerformPasswordResetInput;
};

/** The project root mutation definition */
export type MutationsRateFeedbackQuestionArgs = {
  input: RateFeedbackQuestionInput;
};

/** The project root mutation definition */
export type MutationsRefreshAiSearchVolumeArgs = {
  keywordId: Scalars['ID']['input'];
};

/** The project root mutation definition */
export type MutationsRefundArgs = {
  input: RefundInput;
};

/** The project root mutation definition */
export type MutationsRemoveAdobeMarketingAccountArgs = {
  input: RemoveAdobeMarketingAccountInput;
};

/** The project root mutation definition */
export type MutationsRemoveGoogleAnalyticsAccountArgs = {
  input: RemoveGoogleAnalyticsAccountInput;
};

/** The project root mutation definition */
export type MutationsRemoveGoogleSearchConsoleAccountArgs = {
  input: RemoveGoogleSearchConsoleAccountInput;
};

/** The project root mutation definition */
export type MutationsRemoveMultiAccountArgs = {
  input: RemoveMultiAccountInput;
};

/** The project root mutation definition */
export type MutationsRemoveMultiAccountRequestArgs = {
  input: RemoveMultiAccountRequestInput;
};

/** The project root mutation definition */
export type MutationsReportBugArgs = {
  input: ReportBugInput;
};

/** The project root mutation definition */
export type MutationsRequestCancellationArgs = {
  input: CancellationRequestInput;
};

/** The project root mutation definition */
export type MutationsRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};

/** The project root mutation definition */
export type MutationsResetViewkeyClientArgs = {
  id: Scalars['ID']['input'];
};

/** The project root mutation definition */
export type MutationsResetViewkeyDomainArgs = {
  id: Scalars['ID']['input'];
};

/** The project root mutation definition */
export type MutationsScheduleCompetitorRanksReportArgs = {
  input: ScheduleCompetitorRanksReportInput;
};

/** The project root mutation definition */
export type MutationsScheduleCompetitorsReportArgs = {
  input: ScheduleCompetitorsReportInput;
};

/** The project root mutation definition */
export type MutationsScheduleFoldersReportArgs = {
  input: ScheduleFoldersReportInput;
};

/** The project root mutation definition */
export type MutationsScrapeDomainArgs = {
  input: ScrapeDomainInput;
};

/** The project root mutation definition */
export type MutationsSetEanInvoicePaidArgs = {
  invoiceId: Scalars['UUID']['input'];
};

/** The project root mutation definition */
export type MutationsSetInvoicePaidArgs = {
  invoiceId?: InputMaybe<Scalars['UUID']['input']>;
  paymentId: Scalars['Int']['input'];
};

/** The project root mutation definition */
export type MutationsSetLeadScoreArgs = {
  input: SetLeadScoreInput;
};

/** The project root mutation definition */
export type MutationsSetPaymentContactArgs = {
  billingCycleId?: InputMaybe<Scalars['Int']['input']>;
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  countryIso: Scalars['String']['input'];
  coupon?: InputMaybe<Scalars['String']['input']>;
  emailInvoiceTo?: InputMaybe<Scalars['String']['input']>;
  paymentNonce?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['String']['input']>;
  poNumber?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
  vatNumber?: InputMaybe<Scalars['String']['input']>;
  vatPrefix?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
};

/** The project root mutation definition */
export type MutationsSetPaymentMethodArgs = {
  paymentNonce: Scalars['String']['input'];
};

/** The project root mutation definition */
export type MutationsSetSalesManagerArgs = {
  input: SetSalesManagerInput;
};

/** The project root mutation definition */
export type MutationsShouldRecordSessionArgs = {
  id: Scalars['ID']['input'];
};

/** The project root mutation definition */
export type MutationsSimpleLogArgs = {
  input: SimpleLogInput;
};

/** The project root mutation definition */
export type MutationsSoftDeleteUserArgs = {
  input: SoftDeleteUserInput;
};

/** The project root mutation definition */
export type MutationsTransferMultiAccountDomainArgs = {
  input: TransferMultiAccountDomainInput;
};

/** The project root mutation definition */
export type MutationsUpdateAdobeMarketingConnectionArgs = {
  input: UpdateAdobeMarketingConnectionInput;
};

/** The project root mutation definition */
export type MutationsUpdateAllReadArgs = {
  read: Scalars['Boolean']['input'];
};

/** The project root mutation definition */
export type MutationsUpdateClientArgs = {
  input: UpdateClientInput;
};

/** The project root mutation definition */
export type MutationsUpdateCompetitorArgs = {
  input: UpdateCompetitorInput;
};

/** The project root mutation definition */
export type MutationsUpdateDomainArgs = {
  input: UpdateDomainInput;
};

/** The project root mutation definition */
export type MutationsUpdateDomainDisplayCurrencyArgs = {
  input: UpdateDomainDisplayCurrencyInput;
};

/** The project root mutation definition */
export type MutationsUpdateDomainSpecialSettingsArgs = {
  input: UpdateDomainSpecialSettingsInput;
};

/** The project root mutation definition */
export type MutationsUpdateDynamicTagRulesetArgs = {
  input: UpdateDynamicTagRulesetInput;
};

/** The project root mutation definition */
export type MutationsUpdateFilterArgs = {
  input: UpdateUserFilterInput;
};

/** The project root mutation definition */
export type MutationsUpdateFolderDescriptionArgs = {
  description: Scalars['String']['input'];
  domainId: Scalars['Int']['input'];
  isFolder: Scalars['Boolean']['input'];
  path: Scalars['String']['input'];
};

/** The project root mutation definition */
export type MutationsUpdateFolderStructureArgs = {
  domainId: Scalars['Int']['input'];
  newTree: Scalars['GenericScalar']['input'];
};

/** The project root mutation definition */
export type MutationsUpdateGeneratedReportArgs = {
  input: UpdateGeneratedReportInput;
};

/** The project root mutation definition */
export type MutationsUpdateGoogleConnectionArgs = {
  input: UpdateGoogleConnectionInput;
};

/** The project root mutation definition */
export type MutationsUpdateInvoiceContactArgs = {
  input: UpdateInvoiceContactInput;
};

/** The project root mutation definition */
export type MutationsUpdateKeywordsArgs = {
  input: UpdateKeywordsInput;
};

/** The project root mutation definition */
export type MutationsUpdateMyUserArgs = {
  input: UpdateMyUserInput;
};

/** The project root mutation definition */
export type MutationsUpdateMyUserPasswordArgs = {
  input: UpdateMyUserPasswordInput;
};

/** The project root mutation definition */
export type MutationsUpdateNoteArgs = {
  input: UpdateNoteInput;
};

/** The project root mutation definition */
export type MutationsUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

/** The project root mutation definition */
export type MutationsUpdateOrganizationPlanArgs = {
  input: UpdateOrganizationPlanInput;
};

/** The project root mutation definition */
export type MutationsUpdatePublicReportSettingsArgs = {
  input: PublicReportSettingsInput;
};

/** The project root mutation definition */
export type MutationsUpdateReadArgs = {
  input: UpdateReadInput;
};

/** The project root mutation definition */
export type MutationsUpdateReportTemplateArgs = {
  input: UpdateReportTemplateInput;
};

/** The project root mutation definition */
export type MutationsUpdateScheduledReportArgs = {
  input: UpdateScheduledReportInput;
};

/** The project root mutation definition */
export type MutationsUpdateScheduledReportRecipientsArgs = {
  input: UpdateScheduledReportRecipientsInput;
};

/** The project root mutation definition */
export type MutationsUpdateStaticTagArgs = {
  input: UpdateStaticTagInput;
};

/** The project root mutation definition */
export type MutationsUpdateUploadImportArgs = {
  input: UpdateUploadImportInput;
};

/** The project root mutation definition */
export type MutationsUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The project root mutation definition */
export type MutationsUpdateUserSettingsArgs = {
  input: UpdateUserSettingsInput;
};

/** The project root mutation definition */
export type MutationsUpdateWizardProgressOrganizationArgs = {
  input: UpdateWizardProgressOrganizationInput;
};

/** The project root mutation definition */
export type MutationsUpdateWorkspaceArgs = {
  input: UpdateWorkspaceInput;
};

/** The project root mutation definition */
export type MutationsUserLoginArgs = {
  input: LoginInput;
};

/** The project root mutation definition */
export type MutationsUserLoginTwoFactorArgs = {
  input: LoginTwoFactorInput;
};

/** The project root mutation definition */
export type MutationsUserPhoneVerificationArgs = {
  input: RegisterPhoneVerificationInput;
};

/** The project root mutation definition */
export type MutationsUserRegisterArgs = {
  input: RegisterInput;
};

/** The project root mutation definition */
export type MutationsUserRegisterCompleteWizardArgs = {
  input: RegisterCompleteWizardInput;
};

/** The project root mutation definition */
export type MutationsUserSignupArgs = {
  input: SignUpInput;
};

/** The project root mutation definition */
export type MutationsUserSocialAuthDisconnectArgs = {
  input: UserSocialAuthDisconnectInput;
};

export type NoteNode = GrapheneNode & {
  __typename?: 'NoteNode';
  createdAt: Scalars['Date']['output'];
  domain: DomainNode;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  keywords?: Maybe<Array<Maybe<KeywordNode>>>;
  note: Scalars['String']['output'];
  numberOfKeywords: Scalars['Int']['output'];
  specialType?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserNode>;
};

export type NoteNodeConnection = {
  __typename?: 'NoteNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NoteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NoteNode` and its cursor. */
export type NoteNodeEdge = {
  __typename?: 'NoteNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<NoteNode>;
};

export type NotificationNode = GrapheneNode & {
  __typename?: 'NotificationNode';
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  keyword: KeywordNode;
  type: KeywordsKeywordRankAlertTypeChoices;
};

export type NotificationNodeConnection = {
  __typename?: 'NotificationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NotificationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NotificationNode` and its cursor. */
export type NotificationNodeEdge = {
  __typename?: 'NotificationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<NotificationNode>;
};

export type Ordering = {
  __typename?: 'Ordering';
  order: Scalars['String']['output'];
  orderBy: Scalars['String']['output'];
};

export type OrderingInput = {
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
};

export type OrganizationBlockedNode = {
  __typename?: 'OrganizationBlockedNode';
  message?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type OrganizationChoiceNode = {
  __typename?: 'OrganizationChoiceNode';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type OrganizationChoicesNode = {
  __typename?: 'OrganizationChoicesNode';
  companySizes?: Maybe<Array<Maybe<OrganizationChoiceNode>>>;
  companyTypes?: Maybe<Array<Maybe<OrganizationChoiceNode>>>;
};

export type OrganizationErrorsNode = {
  __typename?: 'OrganizationErrorsNode';
  failedPayment?: Maybe<Scalars['Boolean']['output']>;
  invoiceStatus?: Maybe<Scalars['Int']['output']>;
  keywordRefreshDisabled?: Maybe<Scalars['Boolean']['output']>;
  planExpired?: Maybe<Scalars['Boolean']['output']>;
  systemHealthNotice?: Maybe<Scalars['Int']['output']>;
  trialExpired?: Maybe<Scalars['Boolean']['output']>;
};

export type OrganizationNode = GrapheneNode & {
  __typename?: 'OrganizationNode';
  acceptedOveruse?: Maybe<AcceptedOveruseNode>;
  accountBlocked: Scalars['Boolean']['output'];
  /** A custom message that can be set through sales tab or django admin and is shown to the user. */
  accountBlockedCustomMessage?: Maybe<Scalars['String']['output']>;
  accountBlockedReason?: Maybe<Array<Maybe<OrganizationBlockedNode>>>;
  accountBlockedType?: Maybe<OrganizationsOrganizationAccountBlockedTypeChoices>;
  accountLinkOrganization: InvoiceNodeConnection;
  /** The token used to authenticate with the AccuAPI. */
  accuApiToken?: Maybe<Scalars['String']['output']>;
  accuApiUser?: Maybe<Scalars['UUID']['output']>;
  active: Scalars['Boolean']['output'];
  activeChanged?: Maybe<Scalars['DateTime']['output']>;
  activePlan?: Maybe<OrganizationPlanNode>;
  additionalData?: Maybe<Scalars['JSONString']['output']>;
  adobeMarketingConnections?: Maybe<Array<Maybe<AdobeMarketingConnectionNode>>>;
  adobemarketingconnectionSet: AdobeMarketingConnectionNodeConnection;
  affiliate?: Maybe<AffiliateNode>;
  apiFilters?: Maybe<Array<Maybe<UserFilterNode>>>;
  autoCancelled: Scalars['Boolean']['output'];
  autoCancelledChanged?: Maybe<Scalars['DateTime']['output']>;
  /** Flag that determines whether or not an organization is eligible for automatic upgrade */
  automaticUpgradeEnabled: Scalars['Boolean']['output'];
  campaignId?: Maybe<Scalars['String']['output']>;
  canUseV1: Scalars['Boolean']['output'];
  cancelOnFirstPaymentFail: Scalars['Boolean']['output'];
  cancellationType?: Maybe<OrganizationsOrganizationCancellationTypeChoices>;
  children: OrganizationNodeConnection;
  clientSet: Array<ClientNode>;
  clients?: Maybe<Array<Maybe<ClientNode>>>;
  country?: Maybe<CountryNode>;
  createdByCountry?: Maybe<Scalars['String']['output']>;
  createdByIp?: Maybe<Scalars['String']['output']>;
  dateAdded: Scalars['DateTime']['output'];
  dealType?: Maybe<Scalars['String']['output']>;
  defaultReportTemplate?: Maybe<ReportTemplateNode>;
  deletedMultiAccountOwners: Array<MultiAccountLinkNode>;
  demoDomains?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  displayCurrency?: Maybe<Scalars['String']['output']>;
  domainWithHighestCompetitors?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<OrganizationErrorsNode>;
  existingMultiAccountOwners?: Maybe<Array<MultiAccountLinkNode>>;
  failedPayments?: Maybe<Array<Maybe<FailedPaymentNode>>>;
  failedpaymentSet: FailedPaymentNodeConnection;
  firstContactType?: Maybe<Scalars['String']['output']>;
  /** This is the email address from which your clients will receive their reports. */
  fromEmail?: Maybe<Scalars['String']['output']>;
  googleConnections?: Maybe<Array<Maybe<GoogleOAuthConnectionNode>>>;
  hasValidGscConnection?: Maybe<Scalars['Boolean']['output']>;
  hidePaypalPopup: Scalars['Boolean']['output'];
  historicKeywordUsages?: Maybe<Scalars['GenericScalar']['output']>;
  hubspotLink?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  importSet: ImportNodeConnection;
  inviteSentFromOwnAccount?: Maybe<Scalars['Boolean']['output']>;
  invoiceSet: InvoiceNodeConnection;
  invoices?: Maybe<Array<Maybe<InvoiceNode>>>;
  isAffiliate?: Maybe<Scalars['Boolean']['output']>;
  isPartner?: Maybe<Scalars['Boolean']['output']>;
  isUsingPaypal?: Maybe<Scalars['Boolean']['output']>;
  /** Enable keyword overuse on your account. */
  keywordOveruseEnabled: Scalars['Boolean']['output'];
  keywordOveruseEnabledChanged?: Maybe<Scalars['DateTime']['output']>;
  /** Extra cost in percentage per overuse keyword. */
  keywordOveruseExtraCost: Scalars['Decimal']['output'];
  keywordOveruseNotificationShown: Scalars['Boolean']['output'];
  keywordOveruseOptout: Scalars['Boolean']['output'];
  leadScore?: Maybe<Scalars['Int']['output']>;
  level: Scalars['Int']['output'];
  lft: Scalars['Int']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  messageSet: MessageNodeConnection;
  multiAccountLink?: Maybe<MultiAccountLinkNode>;
  multiAccountLinks?: Maybe<Array<Maybe<MultiAccountLinkNode>>>;
  multiAccountOwners?: Maybe<Array<Maybe<MultiAccountLinkNode>>>;
  multiAccounts?: Maybe<Array<Maybe<MultiAccountLinkNode>>>;
  /** The name of your account/department. This will show up on reports. */
  name: Scalars['String']['output'];
  nextPlan?: Maybe<OrganizationPlanNode>;
  numberOfCompetitors?: Maybe<Scalars['Int']['output']>;
  numberOfDomains?: Maybe<Scalars['Int']['output']>;
  numberOfDomainsWithGa?: Maybe<Scalars['Int']['output']>;
  numberOfDomainsWithGwt?: Maybe<Scalars['Int']['output']>;
  numberOfKeywords?: Maybe<Scalars['Int']['output']>;
  numberOfUsers?: Maybe<Scalars['Int']['output']>;
  organizationClients?: Maybe<Array<Maybe<ClientNode>>>;
  organizationNotes: Array<OrganizationNoteNode>;
  organizationSet: OrganizationNodeConnection;
  organizationnoteSet: OrganizationNoteNodeConnection;
  organizationplanSet: OrganizationPlanNodeConnection;
  organizationsettings?: Maybe<OrganizationSettingsNode>;
  parent?: Maybe<OrganizationNode>;
  paymentSet: PaymentNodeConnection;
  paymentcontact?: Maybe<PaymentContactNode>;
  pendingMultiAccountOwners?: Maybe<Array<Maybe<MultiAccountLinkNode>>>;
  pendingMultiAccounts?: Maybe<Array<Maybe<MultiAccountLinkNode>>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  plans?: Maybe<Array<Maybe<OrganizationPlanNode>>>;
  previousPlan?: Maybe<OrganizationPlanNode>;
  /** Id for organization this pricing plan belongs to */
  pricingplannewSet: PricingPlanNodeConnection;
  primaryCountryCurrency?: Maybe<Scalars['String']['output']>;
  referer?: Maybe<Scalars['String']['output']>;
  reportfilterSet: ReportFilterNodeConnection;
  reporttemplateSet: ReportTemplateNodeConnection;
  revshareaffiliateSet: RevShareAffiliateNodeConnection;
  rght: Scalars['Int']['output'];
  salesManager?: Maybe<SalesManagerNode>;
  showKeywordUsage?: Maybe<Scalars['Boolean']['output']>;
  showLocalSearchVolume: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  subAccountCreatedBy?: Maybe<OrganizationNode>;
  subAccountDiscountType?: Maybe<OrganizationsOrganizationSubAccountDiscountTypeChoices>;
  subAccountPercent?: Maybe<Scalars['Int']['output']>;
  tapclicksSet: TapClicksNodeConnection;
  treeId: Scalars['Int']['output'];
  treeStructure?: Maybe<Scalars['GenericScalar']['output']>;
  trialAbuseLog?: Maybe<Array<Maybe<TrialAbuseLogNode>>>;
  trialabuselogSet: TrialAbuseLogNodeConnection;
  type?: Maybe<Scalars['String']['output']>;
  unrankedWeight?: Maybe<Scalars['Int']['output']>;
  userfilterSet: UserFilterNodeConnection;
  users?: Maybe<Array<Maybe<UserNode>>>;
  visitorSet: VisitorNodeConnection;
  walletSet: WalletNodeConnection;
  wizardProgress?: Maybe<Scalars['Int']['output']>;
  wizardShouldBeShown?: Maybe<Scalars['Boolean']['output']>;
  workspaceSet: Array<WorkspaceNode>;
};

export type OrganizationNodeAccountLinkOrganizationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeAdobemarketingconnectionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeFailedpaymentSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeImportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeInviteSentFromOwnAccountArgs = {
  multiAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationNodeInvoiceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeMessageSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeOrganizationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeOrganizationnoteSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeOrganizationplanSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodePaymentSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodePricingplannewSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeReportfilterSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeReporttemplateSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeRevshareaffiliateSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeTapclicksSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeTrialabuselogSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeUserfilterSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeVisitorSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeWalletSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationNodeConnection = {
  __typename?: 'OrganizationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrganizationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OrganizationNode` and its cursor. */
export type OrganizationNodeEdge = {
  __typename?: 'OrganizationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<OrganizationNode>;
};

export type OrganizationNoteAddInput = {
  message: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type OrganizationNoteDeleteInput = {
  noteId: Scalars['ID']['input'];
};

export type OrganizationNoteEditInput = {
  message: Scalars['String']['input'];
  noteId: Scalars['ID']['input'];
};

export type OrganizationNoteNode = GrapheneNode & {
  __typename?: 'OrganizationNoteNode';
  createdAt: Scalars['Date']['output'];
  createdBy?: Maybe<Scalars['ID']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  lastEditedAt?: Maybe<Scalars['Date']['output']>;
  message: Scalars['String']['output'];
  organization: OrganizationNode;
};

export type OrganizationNoteNodeConnection = {
  __typename?: 'OrganizationNoteNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrganizationNoteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OrganizationNoteNode` and its cursor. */
export type OrganizationNoteNodeEdge = {
  __typename?: 'OrganizationNoteNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<OrganizationNoteNode>;
};

export type OrganizationPlanNode = GrapheneNode & {
  __typename?: 'OrganizationPlanNode';
  accuApiCredits?: Maybe<Scalars['BigInt']['output']>;
  active: Scalars['Boolean']['output'];
  actualMaxKeywords?: Maybe<Scalars['Int']['output']>;
  /** Determines whether to create a adjusted plan if a PlanPriceAdjustment object exists for this plan */
  adjustPriceNextPayment: Scalars['Boolean']['output'];
  /** Send an email notifying about a plan price adjustment */
  adjustPriceNextPaymentNotification: Scalars['Boolean']['output'];
  autoFromTrial: Scalars['Boolean']['output'];
  billingCycleInMonths: Scalars['Int']['output'];
  billingFailReason?: Maybe<Scalars['String']['output']>;
  /** Used to allow customers to access data / dashboard when they have failed payments. */
  billingFailedCanAccess?: Maybe<Scalars['DateTime']['output']>;
  /** Used for manual customer retries */
  billingLastRetryDate?: Maybe<Scalars['DateTime']['output']>;
  billingRetries: Scalars['Int']['output'];
  billingRetryDate?: Maybe<Scalars['Date']['output']>;
  calculateFuturePrice?: Maybe<Scalars['Float']['output']>;
  calculatePrice?: Maybe<Scalars['Float']['output']>;
  calculateThreedSecurePrice?: Maybe<Scalars['Float']['output']>;
  canManualReActivate?: Maybe<Scalars['Boolean']['output']>;
  canManualRetryPayment?: Maybe<Scalars['Boolean']['output']>;
  cancellationNoticeInDays?: Maybe<Scalars['Int']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  commitmentMonths?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['Date']['output'];
  endDateIfCancelledNow?: Maybe<Scalars['Date']['output']>;
  failedInvoiceUrl?: Maybe<Scalars['String']['output']>;
  failedPayment?: Maybe<FailedPaymentNode>;
  failedpaymentSet: FailedPaymentNodeConnection;
  featureAccuApi: Scalars['Boolean']['output'];
  featureAdvancedMetrics: Scalars['Boolean']['output'];
  featureAdvancedReporting: Scalars['Boolean']['output'];
  featureAnalyticsIntegration: Scalars['Boolean']['output'];
  featureApiAccess: Scalars['Boolean']['output'];
  featureApiV4WriteAccess?: Maybe<Scalars['Boolean']['output']>;
  featureBaidu: Scalars['Boolean']['output'];
  featureCanPause: Scalars['Boolean']['output'];
  featureCompetitorMonitoring: Scalars['Boolean']['output'];
  featureHtmlApi: Scalars['Boolean']['output'];
  featureKeywordRefresh: Scalars['Boolean']['output'];
  featureNaver: Scalars['Boolean']['output'];
  featureReporting: Scalars['Boolean']['output'];
  featureSearchVolume: Scalars['Boolean']['output'];
  featureSocial: Scalars['Boolean']['output'];
  featureYandex: Scalars['Boolean']['output'];
  featureYoutube: Scalars['Boolean']['output'];
  featureYoutubeDailyVideosLimit: Scalars['Int']['output'];
  hasKeydisAccess: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isFree: Scalars['Boolean']['output'];
  isPrepaidVoucher?: Maybe<Scalars['Boolean']['output']>;
  isTrial: Scalars['Boolean']['output'];
  lastCommitmentStartDate?: Maybe<Scalars['Date']['output']>;
  maxCompetitors: Scalars['Int']['output'];
  maxDomains: Scalars['Int']['output'];
  maxKeywords: Scalars['Int']['output'];
  maxNumbersOfWorkspaces: Scalars['Int']['output'];
  maxUsers: Scalars['Int']['output'];
  minutesBetweenRefresh?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  organization: OrganizationNode;
  originPlan?: Maybe<PricingPlanNode>;
  paymentSet: PaymentNodeConnection;
  priceMonthly?: Maybe<Scalars['Float']['output']>;
  priceMonthlyDkk?: Maybe<Scalars['Decimal']['output']>;
  priceMonthlyEur?: Maybe<Scalars['Decimal']['output']>;
  priceMonthlyUsd: Scalars['Decimal']['output'];
  priceOnFailedInvoice?: Maybe<Scalars['Float']['output']>;
  priceYearly?: Maybe<Scalars['Float']['output']>;
  priceYearlyDkk?: Maybe<Scalars['Decimal']['output']>;
  priceYearlyEur?: Maybe<Scalars['Decimal']['output']>;
  priceYearlyUsd: Scalars['Decimal']['output'];
  serpApiLimitFactor: Scalars['Float']['output'];
  signonDiscountDkk?: Maybe<Scalars['Decimal']['output']>;
  signonDiscountEur?: Maybe<Scalars['Decimal']['output']>;
  signonDiscountMonths?: Maybe<Scalars['Int']['output']>;
  signonDiscountMonthsUsed: Scalars['Int']['output'];
  signonDiscountUsd?: Maybe<Scalars['Decimal']['output']>;
  startDate: Scalars['Date']['output'];
  whenCanManualRetryPayment?: Maybe<Scalars['DateTime']['output']>;
};

export type OrganizationPlanNodeFailedpaymentSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationPlanNodePaymentSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationPlanNodeConnection = {
  __typename?: 'OrganizationPlanNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrganizationPlanNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OrganizationPlanNode` and its cursor. */
export type OrganizationPlanNodeEdge = {
  __typename?: 'OrganizationPlanNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<OrganizationPlanNode>;
};

export type OrganizationPlanPeriodNode = {
  __typename?: 'OrganizationPlanPeriodNode';
  periodFrom?: Maybe<Scalars['DateTime']['output']>;
  periodTo?: Maybe<Scalars['DateTime']['output']>;
};

export type OrganizationSettingsNode = GrapheneNode & {
  __typename?: 'OrganizationSettingsNode';
  companySize?: Maybe<OrganizationsOrganizationSettingsCompanySizeChoices>;
  companyType?: Maybe<OrganizationsOrganizationSettingsCompanyTypeChoices>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  defaultReportTemplate?: Maybe<ReportTemplateNode>;
  /** Check this to enable the partner account feature. With this enabled you can access other AccuRanker accounts. */
  enableMultiaccount: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  keywordsSize: Scalars['Int']['output'];
  organization: OrganizationNode;
  /** This is the body of the email clients will receive their reports in. */
  reportEmailBody?: Maybe<Scalars['String']['output']>;
  /** This is the subject of the email clients will receive their reports in. */
  reportEmailSubject?: Maybe<Scalars['String']['output']>;
  targetDomain?: Maybe<Scalars['String']['output']>;
};

export type OrganizationSettingsNodeConnection = {
  __typename?: 'OrganizationSettingsNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrganizationSettingsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OrganizationSettingsNode` and its cursor. */
export type OrganizationSettingsNodeEdge = {
  __typename?: 'OrganizationSettingsNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<OrganizationSettingsNode>;
};

export enum OrganizationsMultiAccountLinkFromOrganizationDiscountTypeChoices {
  /** Kickback */
  A_1 = 'A_1',
  /** Discount */
  A_2 = 'A_2',
}

export enum OrganizationsOrganizationAccountBlockedTypeChoices {
  /** Abuse */
  A_1 = 'A_1',
  /** No contact */
  A_2 = 'A_2',
  /** Paused */
  A_3 = 'A_3',
}

export enum OrganizationsOrganizationCancellationTypeChoices {
  /** Failed payments */
  A_1 = 'A_1',
  /** Trial expired */
  A_2 = 'A_2',
  /** Manual churn */
  A_3 = 'A_3',
  /** Cancelled for abuse */
  A_4 = 'A_4',
  /** Trial expired and failed payment */
  A_5 = 'A_5',
  /** Subaccount cancelled */
  A_6 = 'A_6',
  /** Free expired */
  A_7 = 'A_7',
}

export enum OrganizationsOrganizationSettingsCompanySizeChoices {
  /** 1 */
  A_1 = 'A_1',
  /** 2 to 15 */
  A_2 = 'A_2',
  /** 16 to 50 */
  A_3 = 'A_3',
  /** 51 to 200 */
  A_4 = 'A_4',
  /** 201 to 1,000 */
  A_5 = 'A_5',
  /** 1,000 or more */
  A_6 = 'A_6',
}

export enum OrganizationsOrganizationSettingsCompanyTypeChoices {
  /** Agency */
  A_1 = 'A_1',
  /** E-commerce */
  A_2 = 'A_2',
  /** Brand */
  A_3 = 'A_3',
  /** Independent/Consultant */
  A_4 = 'A_4',
}

export enum OrganizationsOrganizationSubAccountDiscountTypeChoices {
  /** Kickback */
  A_1 = 'A_1',
  /** Discount */
  A_2 = 'A_2',
}

export type OveruseWalletListNode = {
  __typename?: 'OveruseWalletListNode';
  dates?: Maybe<Array<Maybe<OveruseWalletNode>>>;
  periodFrom?: Maybe<Scalars['String']['output']>;
  periodTo?: Maybe<Scalars['String']['output']>;
  planPeriods?: Maybe<Array<Maybe<OrganizationPlanPeriodNode>>>;
};

export type OveruseWalletNode = {
  __typename?: 'OveruseWalletNode';
  currency?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  overusedKeywords?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type OverviewChartNode = {
  __typename?: 'OverviewChartNode';
  rows?: Maybe<Array<Maybe<OverviewChartRowNode>>>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type OverviewChartRowNode = {
  __typename?: 'OverviewChartRowNode';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type OverviewCompetitorStatsChartNodeNode = {
  __typename?: 'OverviewCompetitorStatsChartNodeNode';
  displayName?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['Int']['output']>;
  searchVolume?: Maybe<Scalars['Int']['output']>;
  shareOfVoice?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type OverviewSearchIntentGraphNode = {
  __typename?: 'OverviewSearchIntentGraphNode';
  C: Scalars['Int']['output'];
  I: Scalars['Int']['output'];
  N: Scalars['Int']['output'];
  T: Scalars['Int']['output'];
  keywordCount: Scalars['Int']['output'];
};

export type OverviewSearchTrendGraphNode = {
  __typename?: 'OverviewSearchTrendGraphNode';
  month: Scalars['String']['output'];
  searchVolumeTotal: Scalars['Int']['output'];
};

export type OverviewShareOfVoiceCompetitorChartNodeNode = {
  __typename?: 'OverviewShareOfVoiceCompetitorChartNodeNode';
  displayName?: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  faviconUrl: Scalars['String']['output'];
  shareOfVoice?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type OverviewUnknownCompetitorChartNodeNode = {
  __typename?: 'OverviewUnknownCompetitorChartNodeNode';
  amount?: Maybe<Scalars['Float']['output']>;
  competitor?: Maybe<Scalars['Boolean']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  ownDomain?: Maybe<Scalars['Boolean']['output']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PageViewNode = GrapheneNode & {
  __typename?: 'PageViewNode';
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  page: Scalars['String']['output'];
  visitor: VisitorNode;
};

export type PageViewNodeConnection = {
  __typename?: 'PageViewNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PageViewNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PageViewNode` and its cursor. */
export type PageViewNodeEdge = {
  __typename?: 'PageViewNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PageViewNode>;
};

export type Pagination = {
  __typename?: 'Pagination';
  numPages?: Maybe<Scalars['Int']['output']>;
  numResults?: Maybe<Scalars['Int']['output']>;
  page: Scalars['Int']['output'];
  results: Scalars['Int']['output'];
  startIndex?: Maybe<Scalars['Int']['output']>;
  stopIndex?: Maybe<Scalars['Int']['output']>;
};

export type PaginationInput = {
  page: Scalars['Int']['input'];
  results: Scalars['Int']['input'];
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  stopIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type PayAffiliate = {
  __typename?: 'PayAffiliate';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type PayAffiliateInput = {
  organizationId: Scalars['ID']['input'];
};

export type PaymentContactMutation = {
  __typename?: 'PaymentContactMutation';
  error?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<PaymentNode>;
  success: Scalars['Boolean']['output'];
};

export type PaymentContactNode = GrapheneNode & {
  __typename?: 'PaymentContactNode';
  billysbillingId: Scalars['String']['output'];
  braintreeCustomer?: Maybe<BraintreeCustomerNode>;
  braintreeId?: Maybe<Scalars['String']['output']>;
  braintreeToken?: Maybe<Scalars['String']['output']>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  country?: Maybe<CountryNode>;
  countryId: BillingPaymentContactCountryIdChoices;
  currency: BillingPaymentContactCurrencyChoices;
  eanNo?: Maybe<Scalars['String']['output']>;
  economicId?: Maybe<Scalars['Int']['output']>;
  emailInvoiceTo?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  invoiceSet: InvoiceNodeConnection;
  organization: OrganizationNode;
  overdueInvoices?: Maybe<Scalars['Int']['output']>;
  paymentMethod?: Maybe<BillingPaymentContactPaymentMethodChoices>;
  paymentSet: PaymentNodeConnection;
  paymentTerms?: Maybe<Scalars['Int']['output']>;
  paypalBillingAgreementId?: Maybe<Scalars['String']['output']>;
  poNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
  totalInvoices?: Maybe<Scalars['Int']['output']>;
  unpaidInvoices?: Maybe<Scalars['Int']['output']>;
  vatNo?: Maybe<Scalars['String']['output']>;
  vatNumber?: Maybe<Scalars['String']['output']>;
  vatPrefix?: Maybe<Scalars['String']['output']>;
  zipcode: Scalars['String']['output'];
};

export type PaymentContactNodeBraintreeTokenArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentContactNodeInvoiceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentContactNodePaymentSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentMethodMutation = {
  __typename?: 'PaymentMethodMutation';
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type PaymentNode = GrapheneNode & {
  __typename?: 'PaymentNode';
  amount: Scalars['Decimal']['output'];
  amountBeforeVat: Scalars['Decimal']['output'];
  billysbillingInvoiceId?: Maybe<Scalars['String']['output']>;
  braintreeTransactionId?: Maybe<Scalars['String']['output']>;
  couponCode?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  dateCreated: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  invoiceSet: InvoiceNodeConnection;
  organization: OrganizationNode;
  organizationPlan?: Maybe<OrganizationPlanNode>;
  paymentContact?: Maybe<PaymentContactNode>;
  paymentMethod: BillingPaymentPaymentMethodChoices;
  paymentType?: Maybe<BillingPaymentPaymentTypeChoices>;
  paypalTransactionId?: Maybe<Scalars['String']['output']>;
  refundedDate?: Maybe<Scalars['DateTime']['output']>;
  signonDiscount?: Maybe<Scalars['Decimal']['output']>;
  unpaid: Scalars['Boolean']['output'];
  unpaidChangedAt?: Maybe<Scalars['DateTime']['output']>;
  visitorpaymentSet: VisitorPaymentNodeConnection;
  walletSet: WalletNodeConnection;
  wisepaymentSet: WisePaymentNodeConnection;
};

export type PaymentNodeInvoiceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentNodeVisitorpaymentSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentNodeWalletSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentNodeWisepaymentSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentNodeConnection = {
  __typename?: 'PaymentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PaymentNode` and its cursor. */
export type PaymentNodeEdge = {
  __typename?: 'PaymentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PaymentNode>;
};

export type PerformPasswordResetInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newPassword1: Scalars['String']['input'];
  newPassword2: Scalars['String']['input'];
  token: Scalars['String']['input'];
  uidb64: Scalars['String']['input'];
};

export type PerformPasswordResetPayload = {
  __typename?: 'PerformPasswordResetPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  newPassword1: Scalars['String']['output'];
  newPassword2: Scalars['String']['output'];
  token: Scalars['String']['output'];
  uidb64: Scalars['String']['output'];
  user?: Maybe<UserNode>;
};

export type PlanCategoryNode = GrapheneNode & {
  __typename?: 'PlanCategoryNode';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationplanSet: OrganizationPlanNodeConnection;
  pricingplannewSet: PricingPlanNodeConnection;
};

export type PlanCategoryNodeOrganizationplanSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PlanCategoryNodePricingplannewSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PrepaidVoucherCampaignNode = GrapheneNode & {
  __typename?: 'PrepaidVoucherCampaignNode';
  createdAt: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** How many times can the deal be stacked? */
  maxVouchersPerOrganization: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** This is the plan that you get when using a voucher */
  plan: PricingPlanNode;
  slug: Scalars['String']['output'];
};

export type PrepaidVoucherCampaignNodeConnection = {
  __typename?: 'PrepaidVoucherCampaignNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrepaidVoucherCampaignNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PrepaidVoucherCampaignNode` and its cursor. */
export type PrepaidVoucherCampaignNodeEdge = {
  __typename?: 'PrepaidVoucherCampaignNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PrepaidVoucherCampaignNode>;
};

export type PricePerKeywordPerDayNode = {
  __typename?: 'PricePerKeywordPerDayNode';
  amount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
};

export type PricingPlanCalculatedInterface = {
  couponDiscount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  dealEndDate?: Maybe<Scalars['DateTime']['output']>;
  dealStartDate?: Maybe<Scalars['DateTime']['output']>;
  downgrade?: Maybe<Scalars['Boolean']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isTrial?: Maybe<Scalars['Boolean']['output']>;
  keywordOveruseCredit?: Maybe<Scalars['Float']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  purseCredit?: Maybe<Scalars['Float']['output']>;
  receivable?: Maybe<Scalars['Float']['output']>;
  showCountdown?: Maybe<Scalars['Boolean']['output']>;
  signonDiscount?: Maybe<Scalars['Float']['output']>;
  signonDiscountMonths?: Maybe<Scalars['Float']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  subAccountDiscount?: Maybe<Scalars['Float']['output']>;
  threedSecurePrice?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalBeforeVat?: Maybe<Scalars['Float']['output']>;
  upgrade?: Maybe<Scalars['Boolean']['output']>;
  vat?: Maybe<Scalars['Float']['output']>;
  vatRate?: Maybe<Scalars['Int']['output']>;
};

export type PricingPlanCalculatedNode = PricingPlanCalculatedInterface & {
  __typename?: 'PricingPlanCalculatedNode';
  couponDiscount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  dealEndDate?: Maybe<Scalars['DateTime']['output']>;
  dealStartDate?: Maybe<Scalars['DateTime']['output']>;
  downgrade?: Maybe<Scalars['Boolean']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isTrial?: Maybe<Scalars['Boolean']['output']>;
  keywordOveruseCredit?: Maybe<Scalars['Float']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  nextPlanAfterTrial?: Maybe<PricingPlanCalculatedTrialNode>;
  price?: Maybe<Scalars['Float']['output']>;
  purseCredit?: Maybe<Scalars['Float']['output']>;
  receivable?: Maybe<Scalars['Float']['output']>;
  showCountdown?: Maybe<Scalars['Boolean']['output']>;
  signonDiscount?: Maybe<Scalars['Float']['output']>;
  signonDiscountMonths?: Maybe<Scalars['Float']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  subAccountDiscount?: Maybe<Scalars['Float']['output']>;
  threedSecurePrice?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalBeforeVat?: Maybe<Scalars['Float']['output']>;
  upgrade?: Maybe<Scalars['Boolean']['output']>;
  vat?: Maybe<Scalars['Float']['output']>;
  vatRate?: Maybe<Scalars['Int']['output']>;
};

export type PricingPlanCalculatedTrialNode = PricingPlanCalculatedInterface & {
  __typename?: 'PricingPlanCalculatedTrialNode';
  couponDiscount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  dealEndDate?: Maybe<Scalars['DateTime']['output']>;
  dealStartDate?: Maybe<Scalars['DateTime']['output']>;
  downgrade?: Maybe<Scalars['Boolean']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isTrial?: Maybe<Scalars['Boolean']['output']>;
  keywordOveruseCredit?: Maybe<Scalars['Float']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  purseCredit?: Maybe<Scalars['Float']['output']>;
  receivable?: Maybe<Scalars['Float']['output']>;
  showCountdown?: Maybe<Scalars['Boolean']['output']>;
  signonDiscount?: Maybe<Scalars['Float']['output']>;
  signonDiscountMonths?: Maybe<Scalars['Float']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  subAccountDiscount?: Maybe<Scalars['Float']['output']>;
  threedSecurePrice?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  totalBeforeVat?: Maybe<Scalars['Float']['output']>;
  upgrade?: Maybe<Scalars['Boolean']['output']>;
  vat?: Maybe<Scalars['Float']['output']>;
  vatRate?: Maybe<Scalars['Int']['output']>;
};

export type PricingPlanChoicesNode = {
  __typename?: 'PricingPlanChoicesNode';
  choices?: Maybe<Array<Maybe<PricingPlanNode>>>;
  name?: Maybe<Scalars['String']['output']>;
  originalPlan?: Maybe<PricingPlanNode>;
};

export type PricingPlanNode = GrapheneNode & {
  __typename?: 'PricingPlanNode';
  accuApiCredits?: Maybe<Scalars['BigInt']['output']>;
  archived: Scalars['Boolean']['output'];
  cancellationNoticeInDays?: Maybe<Scalars['Int']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commitmentMonths?: Maybe<Scalars['Int']['output']>;
  /** Datetime for creation of this PricingPlan */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  /** Id for organization this pricing plan belongs to */
  customPlanFor?: Maybe<OrganizationNode>;
  dealEndDate?: Maybe<Scalars['DateTime']['output']>;
  dealStartDate?: Maybe<Scalars['DateTime']['output']>;
  featureAccuApi: Scalars['Boolean']['output'];
  featureAdvancedMetrics: Scalars['Boolean']['output'];
  featureAdvancedReporting: Scalars['Boolean']['output'];
  featureAnalyticsIntegration: Scalars['Boolean']['output'];
  featureApiAccess: Scalars['Boolean']['output'];
  /** Determines whether the holder of the plan can access write endpoints (POST, PUT, DELETE) on API V4 */
  featureApiV4WriteAccess: Scalars['Boolean']['output'];
  featureBaidu: Scalars['Boolean']['output'];
  featureCanPause: Scalars['Boolean']['output'];
  featureCompetitorMonitoring: Scalars['Boolean']['output'];
  featureHtmlApi: Scalars['Boolean']['output'];
  featureKeywordRefresh: Scalars['Boolean']['output'];
  featureNaver: Scalars['Boolean']['output'];
  featureReporting: Scalars['Boolean']['output'];
  featureSearchVolume: Scalars['Boolean']['output'];
  featureSocial: Scalars['Boolean']['output'];
  featureYandex: Scalars['Boolean']['output'];
  featureYoutube: Scalars['Boolean']['output'];
  featureYoutubeDailyVideosLimit: Scalars['Int']['output'];
  hasKeydisAccess: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isCustomPlan: Scalars['Boolean']['output'];
  isDefaultTrial: Scalars['Boolean']['output'];
  isFree: Scalars['Boolean']['output'];
  /** True if this pricing plan is a ghost plan and can be deleted during clean up without conflict */
  isGhost: Scalars['Boolean']['output'];
  isPublicBuyNowPlan: Scalars['Boolean']['output'];
  isPublicPlan: Scalars['Boolean']['output'];
  isTrial: Scalars['Boolean']['output'];
  maxCompetitors: Scalars['Int']['output'];
  maxDomains: Scalars['Int']['output'];
  maxKeywords: Scalars['Int']['output'];
  maxNumbersOfWorkspaces: Scalars['Int']['output'];
  maxUsers: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nextPlanAfterTrial?: Maybe<PricingPlanNode>;
  organizationplanSet: OrganizationPlanNodeConnection;
  /** This is the plan that you get when using a voucher */
  prepaidvouchercampaignSet: PrepaidVoucherCampaignNodeConnection;
  priceMonthly?: Maybe<Scalars['Float']['output']>;
  priceMonthlyDkk?: Maybe<Scalars['Decimal']['output']>;
  priceMonthlyEur?: Maybe<Scalars['Decimal']['output']>;
  priceMonthlyUsd: Scalars['Decimal']['output'];
  priceYearly?: Maybe<Scalars['Float']['output']>;
  priceYearlyDkk?: Maybe<Scalars['Decimal']['output']>;
  priceYearlyEur?: Maybe<Scalars['Decimal']['output']>;
  priceYearlyUsd: Scalars['Decimal']['output'];
  pricingplannewSet: PricingPlanNodeConnection;
  revshareaffiliateSet: RevShareAffiliateNodeConnection;
  salesManager?: Maybe<SalesManagerNode>;
  serpApiLimitFactor: Scalars['Float']['output'];
  showCountdown: Scalars['Boolean']['output'];
  signonDiscount?: Maybe<Scalars['Float']['output']>;
  signonDiscountDkk?: Maybe<Scalars['Decimal']['output']>;
  signonDiscountEur?: Maybe<Scalars['Decimal']['output']>;
  signonDiscountMonths?: Maybe<Scalars['Int']['output']>;
  signonDiscountUsd?: Maybe<Scalars['Decimal']['output']>;
  subscribers?: Maybe<Scalars['Int']['output']>;
  suggested?: Maybe<Scalars['Boolean']['output']>;
  unavailable?: Maybe<Scalars['Boolean']['output']>;
  unavailableReasons?: Maybe<Array<Maybe<PricingPlanUnavailableReasonNode>>>;
  validForNewOnly: Scalars['Boolean']['output'];
};

export type PricingPlanNodeOrganizationplanSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PricingPlanNodePrepaidvouchercampaignSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PricingPlanNodePricingplannewSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PricingPlanNodeRevshareaffiliateSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PricingPlanNodeConnection = {
  __typename?: 'PricingPlanNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PricingPlanNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PricingPlanNode` and its cursor. */
export type PricingPlanNodeEdge = {
  __typename?: 'PricingPlanNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PricingPlanNode>;
};

export type PricingPlanUnavailableReasonNode = {
  __typename?: 'PricingPlanUnavailableReasonNode';
  field: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type PublicReportSettingsInput = {
  defaultDelta?: InputMaybe<Scalars['Int']['input']>;
  defaultOrderColumn?: InputMaybe<Scalars['Int']['input']>;
  defaultStartDate?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  includeAdvancedTableColumns?: InputMaybe<Scalars['Boolean']['input']>;
  includeAverageRankChart?: InputMaybe<Scalars['Boolean']['input']>;
  includeCharts?: InputMaybe<Scalars['Boolean']['input']>;
  includeKpiAboveTheFold?: InputMaybe<Scalars['Boolean']['input']>;
  includeKpiAvgCtr?: InputMaybe<Scalars['Boolean']['input']>;
  includeKpiAvgRank?: InputMaybe<Scalars['Boolean']['input']>;
  includeKpiFirstPage?: InputMaybe<Scalars['Boolean']['input']>;
  includeKpiKeywords?: InputMaybe<Scalars['Boolean']['input']>;
  includeKpiMovedDown?: InputMaybe<Scalars['Boolean']['input']>;
  includeKpiMovedUp?: InputMaybe<Scalars['Boolean']['input']>;
  includeKpiSov?: InputMaybe<Scalars['Boolean']['input']>;
  includeKpiTop1?: InputMaybe<Scalars['Boolean']['input']>;
  includeKpiTop3?: InputMaybe<Scalars['Boolean']['input']>;
  includeKpiTrafficValue?: InputMaybe<Scalars['Boolean']['input']>;
  includeRankingDistributionChart?: InputMaybe<Scalars['Boolean']['input']>;
  includeSearchIntentChart?: InputMaybe<Scalars['Boolean']['input']>;
  includeSearchVolume?: InputMaybe<Scalars['Boolean']['input']>;
  starredOnly?: InputMaybe<Scalars['Boolean']['input']>;
  useAiShareOfVoice?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PublicReportSettingsNode = GrapheneNode & {
  __typename?: 'PublicReportSettingsNode';
  defaultOrderColumn: ReportsPublicReportSettingsDefaultOrderColumnChoices;
  /** Even if there is data older than this, it will not be available to see. */
  defaultStartDate?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<DomainNode>;
  group?: Maybe<ClientNode>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  includeAdvancedTableColumns: Scalars['Boolean']['output'];
  includeAverageRankChart: Scalars['Boolean']['output'];
  includeCharts: Scalars['Boolean']['output'];
  includeKpiAboveTheFold: Scalars['Boolean']['output'];
  includeKpiAvgCtr: Scalars['Boolean']['output'];
  includeKpiAvgRank: Scalars['Boolean']['output'];
  includeKpiFirstPage: Scalars['Boolean']['output'];
  includeKpiKeywords: Scalars['Boolean']['output'];
  includeKpiMovedDown: Scalars['Boolean']['output'];
  includeKpiMovedUp: Scalars['Boolean']['output'];
  includeKpiSov: Scalars['Boolean']['output'];
  includeKpiTop1: Scalars['Boolean']['output'];
  includeKpiTop3: Scalars['Boolean']['output'];
  includeKpiTrafficValue: Scalars['Boolean']['output'];
  includeRankingDistributionChart: Scalars['Boolean']['output'];
  includeSearchIntentChart: Scalars['Boolean']['output'];
  includeSearchVolume: Scalars['Boolean']['output'];
  starredOnly: Scalars['Boolean']['output'];
  useAiShareOfVoice: Scalars['Boolean']['output'];
};

export type PublicReportSettingsNodeConnection = {
  __typename?: 'PublicReportSettingsNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PublicReportSettingsNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PublicReportSettingsNode` and its cursor. */
export type PublicReportSettingsNodeEdge = {
  __typename?: 'PublicReportSettingsNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PublicReportSettingsNode>;
};

/** The project root query definition */
export type Query = {
  __typename?: 'Query';
  accuApiCredits?: Maybe<AccuApiCreditNode>;
  accuApiSearchHistory?: Maybe<AccuApiSearchHistoryNode>;
  accuApiSpendingGraph?: Maybe<AccuApiSpendingGraphNode>;
  accuApiToken?: Maybe<AccuApiTokenNode>;
  activeSessions?: Maybe<Scalars['Int']['output']>;
  adminInvoiceCustomers?: Maybe<Array<Maybe<PaymentContactNode>>>;
  adminLoggedEmail?: Maybe<Array<Maybe<LoggedEmailNode>>>;
  adminLoggedEmailCount?: Maybe<Scalars['Int']['output']>;
  adminOrganization?: Maybe<OrganizationNode>;
  adminOrganizationPlan?: Maybe<OrganizationPlanNode>;
  adminOrganizations?: Maybe<AdminOrganizationNode>;
  adminPlanCategories?: Maybe<Array<Maybe<PlanCategoryNode>>>;
  adminPlans?: Maybe<Array<Maybe<PricingPlanNode>>>;
  adminPublicPlans?: Maybe<Array<Maybe<PricingPlanNode>>>;
  adminSalesDashboard?: Maybe<SalesDashboardNode>;
  adminSalesManager?: Maybe<SalesManagerNode>;
  adminSalesManagers?: Maybe<Array<Maybe<SalesManagerNode>>>;
  adminSalesMetrics?: Maybe<Array<Maybe<AdminSalesMetricDay>>>;
  adminSearch?: Maybe<Array<Maybe<AdminSearchNode>>>;
  affiliate?: Maybe<AffiliateVisitorsNode>;
  affiliateMoneyOwed?: Maybe<AffiliateMoneyOwedNode>;
  avgTopTenCtrByPath?: Maybe<AvgTopTenCtrByPathNode>;
  billingCycle?: Maybe<BillingCycleNode>;
  billingCycles?: Maybe<Array<Maybe<BillingCycleNode>>>;
  charts?: Maybe<ChartsNode>;
  client?: Maybe<ClientNode>;
  clients: Array<ClientNode>;
  clientsSearch?: Maybe<Array<Maybe<ClientNode>>>;
  competitorList?: Maybe<TableCompetitorNode>;
  competitorRankList?: Maybe<TableCompetitorRankListNode>;
  countByCountry?: Maybe<CountByCountryListNode>;
  countries?: Maybe<Array<Maybe<CountryNode>>>;
  country?: Maybe<CountryNode>;
  countrylocaleCounts?: Maybe<Array<CountryLocaleCountNode>>;
  countrylocales?: Maybe<Array<Maybe<CountryLocaleNode>>>;
  dashboardCards?: Maybe<DashboardCardsNode>;
  debug?: Maybe<DjangoDebug>;
  domain?: Maybe<DomainNode>;
  domainToCountry?: Maybe<DomainToCountryNode>;
  domains?: Maybe<DomainListNode>;
  domainsList?: Maybe<Array<Maybe<DomainNode>>>;
  domainsSearch?: Maybe<Array<Maybe<DomainNode>>>;
  dynamicTagRulesetCount?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  dynamicTagRulesetExists?: Maybe<Scalars['Boolean']['output']>;
  dynamicTagRulesetFromTag?: Maybe<DynamicTagRulesetNode>;
  dynamicTagRulesetList?: Maybe<DynamicTagRulesetListNode>;
  exchangeRate?: Maybe<ExchangeRateNode>;
  filterData?: Maybe<FilterDataNode>;
  folderStructure?: Maybe<FolderStructureNode>;
  generatedReport?: Maybe<GeneratedReportNode>;
  generatedReports?: Maybe<Array<Maybe<GeneratedReportNode>>>;
  googleConnection?: Maybe<GoogleOAuthConnectionNode>;
  graphs: GraphsNode;
  importRedirect?: Maybe<ImportRedirectNode>;
  importStatus?: Maybe<Array<Maybe<ImportNode>>>;
  integrationTapclicks?: Maybe<TapClicksNode>;
  keydisCompetitors: Array<KeydisCompetitorNode>;
  keydisImportInfo?: Maybe<KeydisImportInfoNodeResolver>;
  keydisKeywords?: Maybe<KeydisKeywordListNode>;
  keydisKpis?: Maybe<KeydisKpiNode>;
  keydisWordCloud?: Maybe<KeydisWordCloudListNode>;
  keydisWordPhrases?: Maybe<KeydisWordPhraseListNode>;
  keywordOveruseOverview?: Maybe<KeywordOveruseOverviewListNode>;
  keywordOveruseWallet?: Maybe<OveruseWalletListNode>;
  keywordPrice?: Maybe<KeywordPriceNode>;
  keywords?: Maybe<KeywordListNode>;
  keywordsForDomain?: Maybe<KeywordsForDomainNode>;
  keywordsTableHistory?: Maybe<TableKeywordHistoryListNode>;
  kpis?: Maybe<KpisNode>;
  landingPage?: Maybe<LandingPageNode>;
  landingPages?: Maybe<Array<Maybe<LandingPageNode>>>;
  linkedOrganizations?: Maybe<Array<Maybe<OrganizationNode>>>;
  /** subscribe to user and organization changes */
  liveChanges?: Maybe<SubscriptionNode>;
  /** subscribe to specific domain changes (Keyword, KeywordRank, Domain etc) */
  liveChangesDomain?: Maybe<SubscriptionNode>;
  mappableColumns?: Maybe<Array<Maybe<ImportMappableColumnNode>>>;
  message?: Maybe<OrganizationNode>;
  messages: Array<MessageNode>;
  metaData?: Maybe<MetaDataNode>;
  note?: Maybe<NoteNode>;
  notifications?: Maybe<TableNoteficationListNode>;
  organization?: Maybe<OrganizationNode>;
  organizationChoices?: Maybe<OrganizationChoicesNode>;
  organizations?: Maybe<Array<Maybe<OrganizationNode>>>;
  pausedDomainsList?: Maybe<Array<Maybe<DomainNode>>>;
  payNowPlan?: Maybe<PricingPlanNode>;
  paymentContact?: Maybe<PaymentContactNode>;
  prepaidVoucherCampaign?: Maybe<PrepaidVoucherCampaignNode>;
  pricingPlan?: Maybe<PricingPlanNode>;
  pricingPlanCalculated?: Maybe<PricingPlanCalculatedNode>;
  pricingPlans?: Maybe<Array<Maybe<PricingPlanNode>>>;
  pricingPlansChoices?: Maybe<Array<Maybe<PricingPlanChoicesNode>>>;
  pricingPlansSubaccounts?: Maybe<Array<Maybe<PricingPlanNode>>>;
  publicDomain?: Maybe<DomainNode>;
  publicKeywordsTableHistory?: Maybe<TableKeywordHistoryListNode>;
  publicReportSettings?: Maybe<PublicReportSettingsNode>;
  publicTableKeywords?: Maybe<TableKeywordListNode>;
  relevantDomainSearches?: Maybe<RelevantDomainsNode>;
  reportFilters?: Maybe<Array<Maybe<ReportFilterNode>>>;
  reportTemplate?: Maybe<ReportTemplateNode>;
  reportTemplates?: Maybe<Array<Maybe<ReportTemplateNode>>>;
  reportWidgets?: Maybe<Scalars['JSONString']['output']>;
  salesUnmatchedInvoices: Array<InvoiceNode>;
  salesUnmatchedWisePayments: Array<WisePaymentNode>;
  scheduledReport?: Maybe<ScheduledReportNode>;
  scheduledReports?: Maybe<Array<Maybe<ScheduledReportNode>>>;
  serpFeaturesByPath?: Maybe<SerpFeaturesByPathNode>;
  serviceMessageHighestSeverity: Scalars['Int']['output'];
  serviceMessages: Array<ServiceMessageNode>;
  sitemappings?: Maybe<SitemapNode>;
  staticTagInformation?: Maybe<StaticTagInformationNode>;
  tableClients?: Maybe<TableClientsNode>;
  tableDashboard?: Maybe<TableDashboardNode>;
  tableKeywords?: Maybe<TableKeywordListNode>;
  tableLandingPages?: Maybe<TableLandingPagesNode>;
  tableTagCloud?: Maybe<TableTagCloudNode>;
  takeControl?: Maybe<Scalars['Boolean']['output']>;
  titleDescription?: Maybe<TitleDescriptionNode>;
  top10Domains?: Maybe<Array<SimpleUnknownCompetitorRank>>;
  topCompetitorsForKeywords?: Maybe<Array<TopCompetitorsForKeyword>>;
  totalMessagesCount: Scalars['Int']['output'];
  unconfirmedUser?: Maybe<UnconfirmedUserNode>;
  unconfirmedUsers?: Maybe<Array<Maybe<UnconfirmedUserNode>>>;
  uniqueKeywordsNode?: Maybe<UniqueKeywordsNode>;
  unknownCompetitorDomains?: Maybe<SitemapCompetitorNode>;
  uploadImport?: Maybe<ImportNode>;
  uploadedImports?: Maybe<Scalars['GenericScalar']['output']>;
  urls?: Maybe<Array<Maybe<UrlNode>>>;
  user?: Maybe<UserNode>;
  users?: Maybe<Array<Maybe<UserNode>>>;
  usersSearch?: Maybe<Array<Maybe<UserNode>>>;
  validateDomain?: Maybe<ValidateDomainNode>;
  workspace?: Maybe<WorkspaceNode>;
  workspacesByOrganization?: Maybe<Array<Maybe<WorkspaceNode>>>;
};

/** The project root query definition */
export type QueryAccuApiSpendingGraphArgs = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryAdminInvoiceCustomersArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The project root query definition */
export type QueryAdminLoggedEmailArgs = {
  startIndex: Scalars['Int']['input'];
  stopIndex: Scalars['Int']['input'];
};

/** The project root query definition */
export type QueryAdminOrganizationArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryAdminOrganizationPlanArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryAdminOrganizationsArgs = {
  dataType?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  getAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  ordering?: InputMaybe<OrderingInput>;
  pagination?: InputMaybe<PaginationInput>;
  salesManagerId?: InputMaybe<Scalars['ID']['input']>;
};

/** The project root query definition */
export type QueryAdminSalesDashboardArgs = {
  input: SalesDashboardInput;
};

/** The project root query definition */
export type QueryAdminSalesManagerArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryAdminSalesMetricsArgs = {
  compareEndDate?: InputMaybe<Scalars['String']['input']>;
  compareStartDate?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['String']['input'];
  interval: Scalars['String']['input'];
  salesManagerId?: InputMaybe<Scalars['ID']['input']>;
  startDate: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryAdminSearchArgs = {
  q: Scalars['String']['input'];
  types: Array<InputMaybe<Scalars['Int']['input']>>;
};

/** The project root query definition */
export type QueryAffiliateMoneyOwedArgs = {
  organizationId: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryAvgTopTenCtrByPathArgs = {
  filters: Array<InputMaybe<FilterInput>>;
};

/** The project root query definition */
export type QueryBillingCycleArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryChartsArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** The project root query definition */
export type QueryClientArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryClientsSearchArgs = {
  query: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryCompetitorListArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  ordering?: InputMaybe<OrderingInput>;
  pagination?: InputMaybe<PaginationInput>;
};

/** The project root query definition */
export type QueryCompetitorRankListArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  ordering?: InputMaybe<OrderingInput>;
  pagination?: InputMaybe<PaginationInput>;
};

/** The project root query definition */
export type QueryCountByCountryArgs = {
  filters: Array<InputMaybe<FilterInput>>;
};

/** The project root query definition */
export type QueryCountryArgs = {
  id: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryCountrylocaleCountsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** The project root query definition */
export type QueryCountrylocalesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** The project root query definition */
export type QueryDashboardCardsArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
};

/** The project root query definition */
export type QueryDomainArgs = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** The project root query definition */
export type QueryDomainToCountryArgs = {
  domain: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryDomainsArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
};

/** The project root query definition */
export type QueryDomainsListArgs = {
  clientId?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyNotConnectedToGa?: InputMaybe<Scalars['Boolean']['input']>;
  onlyNotConnectedToGsc?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The project root query definition */
export type QueryDomainsSearchArgs = {
  query: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryDynamicTagRulesetCountArgs = {
  dynamicTagRuleset: CountDynamicTagRulesetInput;
};

/** The project root query definition */
export type QueryDynamicTagRulesetExistsArgs = {
  domainId: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryDynamicTagRulesetFromTagArgs = {
  domainId: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryDynamicTagRulesetListArgs = {
  domainId: Scalars['Int']['input'];
};

/** The project root query definition */
export type QueryFilterDataArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  isKeydis: Scalars['Boolean']['input'];
};

/** The project root query definition */
export type QueryFolderStructureArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  nested: Scalars['Boolean']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryGeneratedReportArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryGoogleConnectionArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryGraphsArgs = {
  displayCurrency?: InputMaybe<Scalars['String']['input']>;
  filters: Array<InputMaybe<FilterInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** The project root query definition */
export type QueryImportRedirectArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryImportStatusArgs = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

/** The project root query definition */
export type QueryKeydisCompetitorsArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  filters: Array<InputMaybe<FilterInput>>;
  includeSocials?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The project root query definition */
export type QueryKeydisImportInfoArgs = {
  filters: Array<InputMaybe<FilterInput>>;
};

/** The project root query definition */
export type QueryKeydisKeywordsArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  displayCurrency?: InputMaybe<Scalars['String']['input']>;
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
};

/** The project root query definition */
export type QueryKeydisKpisArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  displayCurrency?: InputMaybe<Scalars['String']['input']>;
  filters: Array<InputMaybe<FilterInput>>;
};

/** The project root query definition */
export type QueryKeydisWordCloudArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  filters: Array<InputMaybe<FilterInput>>;
};

/** The project root query definition */
export type QueryKeydisWordPhrasesArgs = {
  country?: InputMaybe<Scalars['String']['input']>;
  displayCurrency?: InputMaybe<Scalars['String']['input']>;
  filters: Array<InputMaybe<FilterInput>>;
  nWordsInPhrase?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
};

/** The project root query definition */
export type QueryKeywordOveruseWalletArgs = {
  periodFrom?: InputMaybe<Scalars['String']['input']>;
  periodTo?: InputMaybe<Scalars['String']['input']>;
};

/** The project root query definition */
export type QueryKeywordsArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
};

/** The project root query definition */
export type QueryKeywordsForDomainArgs = {
  country: Scalars['String']['input'];
  domain: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryKeywordsTableHistoryArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
};

/** The project root query definition */
export type QueryKpisArgs = {
  displayCurrency?: InputMaybe<Scalars['String']['input']>;
  filters: Array<InputMaybe<FilterInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** The project root query definition */
export type QueryLandingPageArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryLandingPagesArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

/** The project root query definition */
export type QueryLinkedOrganizationsArgs = {
  name: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryLiveChangesDomainArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryMessageArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryMessagesArgs = {
  read: Scalars['Boolean']['input'];
  startIdx: Scalars['Int']['input'];
  stopIdx: Scalars['Int']['input'];
};

/** The project root query definition */
export type QueryNoteArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryNotificationsArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
};

/** The project root query definition */
export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryOrganizationsArgs = {
  name: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryPrepaidVoucherCampaignArgs = {
  slug: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryPricingPlanArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryPricingPlanCalculatedArgs = {
  billingCycle: Scalars['Int']['input'];
  countryId?: InputMaybe<Scalars['String']['input']>;
  coupon?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  validVat?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The project root query definition */
export type QueryPublicDomainArgs = {
  slug: Scalars['String']['input'];
  viewkey: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryPublicKeywordsTableHistoryArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
  slug: Scalars['String']['input'];
  viewkey: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryPublicReportSettingsArgs = {
  domainSlug: Scalars['String']['input'];
  viewkey: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryPublicTableKeywordsArgs = {
  allowPartialScrape?: InputMaybe<Scalars['Boolean']['input']>;
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
  slug: Scalars['String']['input'];
  viewkey: Scalars['String']['input'];
  withAboveTheFold?: InputMaybe<Scalars['Boolean']['input']>;
  withAiSearchVolume?: InputMaybe<Scalars['Boolean']['input']>;
  withAiTrafficValue?: InputMaybe<Scalars['Boolean']['input']>;
  withAiTrafficValueCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withAnalyticsPotential?: InputMaybe<Scalars['Boolean']['input']>;
  withAnalyticsVisitors?: InputMaybe<Scalars['Boolean']['input']>;
  withAvgCostPerClick?: InputMaybe<Scalars['Boolean']['input']>;
  withBaseRank?: InputMaybe<Scalars['Boolean']['input']>;
  withBaseRankCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withCompetition?: InputMaybe<Scalars['Boolean']['input']>;
  withCountrylocaleId?: InputMaybe<Scalars['Boolean']['input']>;
  withDateAdded?: InputMaybe<Scalars['Boolean']['input']>;
  withDescription?: InputMaybe<Scalars['Boolean']['input']>;
  withDisplayName?: InputMaybe<Scalars['Boolean']['input']>;
  withDomain?: InputMaybe<Scalars['Boolean']['input']>;
  withDomainId?: InputMaybe<Scalars['Boolean']['input']>;
  withDynamicCtr?: InputMaybe<Scalars['Boolean']['input']>;
  withDynamicTags?: InputMaybe<Scalars['Boolean']['input']>;
  withEnableAutocorrect?: InputMaybe<Scalars['Boolean']['input']>;
  withExtraRanks?: InputMaybe<Scalars['Boolean']['input']>;
  withFaviconUrl?: InputMaybe<Scalars['Boolean']['input']>;
  withHasExtraRanks?: InputMaybe<Scalars['Boolean']['input']>;
  withHighestRankingPage?: InputMaybe<Scalars['Boolean']['input']>;
  withHistoryUrl?: InputMaybe<Scalars['Boolean']['input']>;
  withId?: InputMaybe<Scalars['Boolean']['input']>;
  withIgnoreFeaturedSnippet?: InputMaybe<Scalars['Boolean']['input']>;
  withIgnoreInShareOfVoice?: InputMaybe<Scalars['Boolean']['input']>;
  withIgnoreLocalResults?: InputMaybe<Scalars['Boolean']['input']>;
  withIgnoredDomain?: InputMaybe<Scalars['Boolean']['input']>;
  withIgnoredDomains?: InputMaybe<Scalars['Boolean']['input']>;
  withKeyword?: InputMaybe<Scalars['Boolean']['input']>;
  withKeywordDescription?: InputMaybe<Scalars['Boolean']['input']>;
  withLandingPageId?: InputMaybe<Scalars['Boolean']['input']>;
  withLocalPackRank?: InputMaybe<Scalars['Boolean']['input']>;
  withLocalPackRankCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withLocation?: InputMaybe<Scalars['Boolean']['input']>;
  withMaxAiSov?: InputMaybe<Scalars['Boolean']['input']>;
  withMaxAiTrafficValue?: InputMaybe<Scalars['Boolean']['input']>;
  withMaxRank?: InputMaybe<Scalars['Boolean']['input']>;
  withMaxSov?: InputMaybe<Scalars['Boolean']['input']>;
  withMaxTrafficValue?: InputMaybe<Scalars['Boolean']['input']>;
  withOrganicTraffic?: InputMaybe<Scalars['Boolean']['input']>;
  withOrganicTrafficCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withPixelsFromTop?: InputMaybe<Scalars['Boolean']['input']>;
  withPixelsFromTopCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withPreferredLandingPageId?: InputMaybe<Scalars['Boolean']['input']>;
  withRankCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withRankId?: InputMaybe<Scalars['Boolean']['input']>;
  withRankValue?: InputMaybe<Scalars['Boolean']['input']>;
  withSearchDate?: InputMaybe<Scalars['Boolean']['input']>;
  withSearchEngineId?: InputMaybe<Scalars['Boolean']['input']>;
  withSearchIntent?: InputMaybe<Scalars['Boolean']['input']>;
  withSearchTypeId?: InputMaybe<Scalars['Boolean']['input']>;
  withSearchVolumeValue?: InputMaybe<Scalars['Boolean']['input']>;
  withSerp?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoiceCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoiceCpcCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoiceCpcValue?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoiceIsPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoicePercentageValue?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoicePercentageValueCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoiceValue?: InputMaybe<Scalars['Boolean']['input']>;
  withStarred?: InputMaybe<Scalars['Boolean']['input']>;
  withTags?: InputMaybe<Scalars['Boolean']['input']>;
  withTitle?: InputMaybe<Scalars['Boolean']['input']>;
  withTopDomain?: InputMaybe<Scalars['Boolean']['input']>;
  withUpdatingKeyword?: InputMaybe<Scalars['Boolean']['input']>;
  withValidForSearchVolume?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The project root query definition */
export type QueryReportTemplateArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryScheduledReportArgs = {
  id: Scalars['ID']['input'];
};

/** The project root query definition */
export type QueryScheduledReportsArgs = {
  isOneTimeReport?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The project root query definition */
export type QuerySerpFeaturesByPathArgs = {
  filters: Array<InputMaybe<FilterInput>>;
};

/** The project root query definition */
export type QuerySitemappingsArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  maxChildren?: InputMaybe<Scalars['Int']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

/** The project root query definition */
export type QueryStaticTagInformationArgs = {
  domainId: Scalars['Int']['input'];
  tag: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryTableClientsArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
};

/** The project root query definition */
export type QueryTableDashboardArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
};

/** The project root query definition */
export type QueryTableKeywordsArgs = {
  allowPartialScrape?: InputMaybe<Scalars['Boolean']['input']>;
  displayCurrency?: InputMaybe<Scalars['String']['input']>;
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
  withAboveTheFold?: InputMaybe<Scalars['Boolean']['input']>;
  withAiSearchVolume?: InputMaybe<Scalars['Boolean']['input']>;
  withAiTrafficValue?: InputMaybe<Scalars['Boolean']['input']>;
  withAiTrafficValueCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withAnalyticsPotential?: InputMaybe<Scalars['Boolean']['input']>;
  withAnalyticsVisitors?: InputMaybe<Scalars['Boolean']['input']>;
  withAvgCostPerClick?: InputMaybe<Scalars['Boolean']['input']>;
  withBaseRank?: InputMaybe<Scalars['Boolean']['input']>;
  withBaseRankCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withCompetition?: InputMaybe<Scalars['Boolean']['input']>;
  withCountrylocaleId?: InputMaybe<Scalars['Boolean']['input']>;
  withDateAdded?: InputMaybe<Scalars['Boolean']['input']>;
  withDescription?: InputMaybe<Scalars['Boolean']['input']>;
  withDisplayName?: InputMaybe<Scalars['Boolean']['input']>;
  withDomain?: InputMaybe<Scalars['Boolean']['input']>;
  withDomainId?: InputMaybe<Scalars['Boolean']['input']>;
  withDynamicCtr?: InputMaybe<Scalars['Boolean']['input']>;
  withDynamicTags?: InputMaybe<Scalars['Boolean']['input']>;
  withEnableAutocorrect?: InputMaybe<Scalars['Boolean']['input']>;
  withExtraRanks?: InputMaybe<Scalars['Boolean']['input']>;
  withFaviconUrl?: InputMaybe<Scalars['Boolean']['input']>;
  withHasExtraRanks?: InputMaybe<Scalars['Boolean']['input']>;
  withHighestRankingPage?: InputMaybe<Scalars['Boolean']['input']>;
  withHistoryUrl?: InputMaybe<Scalars['Boolean']['input']>;
  withId?: InputMaybe<Scalars['Boolean']['input']>;
  withIgnoreFeaturedSnippet?: InputMaybe<Scalars['Boolean']['input']>;
  withIgnoreInShareOfVoice?: InputMaybe<Scalars['Boolean']['input']>;
  withIgnoreLocalResults?: InputMaybe<Scalars['Boolean']['input']>;
  withIgnoredDomain?: InputMaybe<Scalars['Boolean']['input']>;
  withIgnoredDomains?: InputMaybe<Scalars['Boolean']['input']>;
  withKeyword?: InputMaybe<Scalars['Boolean']['input']>;
  withKeywordDescription?: InputMaybe<Scalars['Boolean']['input']>;
  withLandingPageId?: InputMaybe<Scalars['Boolean']['input']>;
  withLocalPackRank?: InputMaybe<Scalars['Boolean']['input']>;
  withLocalPackRankCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withLocation?: InputMaybe<Scalars['Boolean']['input']>;
  withMaxAiSov?: InputMaybe<Scalars['Boolean']['input']>;
  withMaxAiTrafficValue?: InputMaybe<Scalars['Boolean']['input']>;
  withMaxRank?: InputMaybe<Scalars['Boolean']['input']>;
  withMaxSov?: InputMaybe<Scalars['Boolean']['input']>;
  withMaxTrafficValue?: InputMaybe<Scalars['Boolean']['input']>;
  withOrganicTraffic?: InputMaybe<Scalars['Boolean']['input']>;
  withOrganicTrafficCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withPixelsFromTop?: InputMaybe<Scalars['Boolean']['input']>;
  withPixelsFromTopCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withPreferredLandingPageId?: InputMaybe<Scalars['Boolean']['input']>;
  withRankCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withRankId?: InputMaybe<Scalars['Boolean']['input']>;
  withRankValue?: InputMaybe<Scalars['Boolean']['input']>;
  withSearchDate?: InputMaybe<Scalars['Boolean']['input']>;
  withSearchEngineId?: InputMaybe<Scalars['Boolean']['input']>;
  withSearchIntent?: InputMaybe<Scalars['Boolean']['input']>;
  withSearchTypeId?: InputMaybe<Scalars['Boolean']['input']>;
  withSearchVolumeValue?: InputMaybe<Scalars['Boolean']['input']>;
  withSerp?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoiceCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoiceCpcCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoiceCpcValue?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoiceIsPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoicePercentageValue?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoicePercentageValueCompare?: InputMaybe<Scalars['Boolean']['input']>;
  withShareOfVoiceValue?: InputMaybe<Scalars['Boolean']['input']>;
  withStarred?: InputMaybe<Scalars['Boolean']['input']>;
  withTags?: InputMaybe<Scalars['Boolean']['input']>;
  withTitle?: InputMaybe<Scalars['Boolean']['input']>;
  withTopDomain?: InputMaybe<Scalars['Boolean']['input']>;
  withUpdatingKeyword?: InputMaybe<Scalars['Boolean']['input']>;
  withValidForSearchVolume?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The project root query definition */
export type QueryTableLandingPagesArgs = {
  displayCurrency: Scalars['String']['input'];
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
};

/** The project root query definition */
export type QueryTableTagCloudArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  ordering: OrderingInput;
  pagination: PaginationInput;
};

/** The project root query definition */
export type QueryTitleDescriptionArgs = {
  titleDescriptionId: Scalars['Int']['input'];
};

/** The project root query definition */
export type QueryTop10DomainsArgs = {
  domainId?: InputMaybe<Scalars['Int']['input']>;
  keywordId: Scalars['Int']['input'];
  keywordrankId: Scalars['BigInt']['input'];
  searchDate: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryTopCompetitorsForKeywordsArgs = {
  domainId?: InputMaybe<Scalars['Int']['input']>;
  keywordIds: Array<Scalars['Int']['input']>;
};

/** The project root query definition */
export type QueryTotalMessagesCountArgs = {
  read: Scalars['Boolean']['input'];
};

/** The project root query definition */
export type QueryUnconfirmedUserArgs = {
  id: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryUniqueKeywordsNodeArgs = {
  filters: Array<InputMaybe<FilterInput>>;
};

/** The project root query definition */
export type QueryUnknownCompetitorDomainsArgs = {
  filters: Array<InputMaybe<FilterInput>>;
  includeKeydis: Scalars['Boolean']['input'];
  paths: Array<InputMaybe<Scalars['String']['input']>>;
};

/** The project root query definition */
export type QueryUploadImportArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** The project root query definition */
export type QueryUploadedImportsArgs = {
  fields: Array<InputMaybe<Scalars['String']['input']>>;
};

/** The project root query definition */
export type QueryUrlsArgs = {
  domains: Array<InputMaybe<Scalars['ID']['input']>>;
  query: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** The project root query definition */
export type QueryUsersArgs = {
  usersAreAdmins?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The project root query definition */
export type QueryUsersSearchArgs = {
  query: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryValidateDomainArgs = {
  domain: Scalars['String']['input'];
};

/** The project root query definition */
export type QueryWorkspaceArgs = {
  id: Scalars['ID']['input'];
};

export type RateFeedbackQuestion = {
  __typename?: 'RateFeedbackQuestion';
  answer?: Maybe<FeedbackQuestionAnswerNode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type RateFeedbackQuestionInput = {
  mainQuestion: Scalars['ID']['input'];
  /** Should be between 0 and 10 inclusive both. */
  rate: Scalars['Int']['input'];
};

export type ReActivate = {
  __typename?: 'ReActivate';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ReferrerNode = {
  __typename?: 'ReferrerNode';
  amount?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type RefreshAiSearchVolume = {
  __typename?: 'RefreshAiSearchVolume';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Refund = {
  __typename?: 'Refund';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type RefundInput = {
  invoiceId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  partialAmount?: InputMaybe<Scalars['Float']['input']>;
  reason: Scalars['String']['input'];
  skipBraintreeRefund?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Register = {
  __typename?: 'Register';
  errors?: Maybe<Array<Maybe<Error>>>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserNode>;
};

export type RegisterCompleteWizard = {
  __typename?: 'RegisterCompleteWizard';
  domainId?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserNode>;
};

/** Based on fields from last step of wizard */
export type RegisterCompleteWizardInput = {
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  organizationUrl: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  selectedCountry?: InputMaybe<Scalars['String']['input']>;
  targetDomain: Scalars['String']['input'];
};

/** Based on RegisterForm */
export type RegisterInput = {
  billingCycle: Scalars['String']['input'];
  campaignId?: InputMaybe<Scalars['String']['input']>;
  captcha: Scalars['String']['input'];
  companyInfoCity?: InputMaybe<Scalars['String']['input']>;
  companyInfoCountryIso?: InputMaybe<Scalars['String']['input']>;
  companyInfoState?: InputMaybe<Scalars['String']['input']>;
  companyInfoStreet?: InputMaybe<Scalars['String']['input']>;
  companyInfoVatNumber?: InputMaybe<Scalars['String']['input']>;
  companyInfoVatPrefix?: InputMaybe<Scalars['String']['input']>;
  companyInfoZipcode?: InputMaybe<Scalars['String']['input']>;
  coupon?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
  organizationName: Scalars['String']['input'];
  organizationUrl: Scalars['String']['input'];
  paymentNonce?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  planId: Scalars['String']['input'];
  refCode?: InputMaybe<Scalars['String']['input']>;
  referrer?: InputMaybe<Scalars['String']['input']>;
  targetDomain?: InputMaybe<Scalars['String']['input']>;
  unconfirmedUserId: Scalars['String']['input'];
};

export type RegisterPhoneVerification = {
  __typename?: 'RegisterPhoneVerification';
  errors?: Maybe<Array<Maybe<Error>>>;
  phoneNumberVerificationSid?: Maybe<Scalars['String']['output']>;
  verified?: Maybe<Scalars['String']['output']>;
};

export type RegisterPhoneVerificationInput = {
  channel?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  unconfirmedUserId: Scalars['String']['input'];
};

export type RelevantDomainsNode = {
  __typename?: 'RelevantDomainsNode';
  domainTypes?: Maybe<DomainTypeNode>;
};

export type RemoveAdobeMarketingAccount = {
  __typename?: 'RemoveAdobeMarketingAccount';
  domain?: Maybe<DomainNode>;
};

export type RemoveAdobeMarketingAccountInput = {
  domainId: Scalars['String']['input'];
};

export type RemoveDriveConnection = {
  __typename?: 'RemoveDriveConnection';
  user?: Maybe<UserNode>;
};

export type RemoveGoogleAnalyticsAccount = {
  __typename?: 'RemoveGoogleAnalyticsAccount';
  domain?: Maybe<DomainNode>;
};

export type RemoveGoogleAnalyticsAccountInput = {
  domainId: Scalars['String']['input'];
};

export type RemoveGoogleSearchConsoleAccount = {
  __typename?: 'RemoveGoogleSearchConsoleAccount';
  domain?: Maybe<DomainNode>;
};

export type RemoveGoogleSearchConsoleAccountInput = {
  domainId: Scalars['String']['input'];
};

/** This will remove an approved sub-account. If the from_org is paying the sub-accounts plan will be cancelled. */
export type RemoveMultiAccount = {
  __typename?: 'RemoveMultiAccount';
  errors?: Maybe<Array<Maybe<Error>>>;
  multiAccount?: Maybe<MultiAccountLinkNode>;
};

export type RemoveMultiAccountInput = {
  id: Scalars['ID']['input'];
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This will remove a pending access request. */
export type RemoveMultiAccountRequest = {
  __typename?: 'RemoveMultiAccountRequest';
  errors?: Maybe<Array<Maybe<Error>>>;
  multiAccount?: Maybe<MultiAccountLinkNode>;
};

export type RemoveMultiAccountRequestInput = {
  id: Scalars['ID']['input'];
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReportBug = {
  __typename?: 'ReportBug';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ReportBugInput = {
  /** Description */
  description: Scalars['String']['input'];
  /** Domain */
  domain: Scalars['String']['input'];
  /** Expected */
  expected: Scalars['String']['input'];
  /** Intercom link */
  intercomLink?: InputMaybe<Scalars['String']['input']>;
  /** Organization */
  organization: Scalars['String']['input'];
  /** Steps to reproduce */
  steps: Scalars['String']['input'];
  /** Bug title */
  title: Scalars['String']['input'];
  /** User */
  user: Scalars['String']['input'];
};

export type ReportFilterNode = GrapheneNode & {
  __typename?: 'ReportFilterNode';
  filters: Scalars['JSONString']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  organization: OrganizationNode;
  scheduledreportnewSet: ScheduledReportNodeConnection;
};

export type ReportFilterNodeScheduledreportnewSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ReportFilterNodeConnection = {
  __typename?: 'ReportFilterNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReportFilterNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ReportFilterNode` and its cursor. */
export type ReportFilterNodeEdge = {
  __typename?: 'ReportFilterNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ReportFilterNode>;
};

export type ReportTemplateNode = GrapheneNode & {
  __typename?: 'ReportTemplateNode';
  brandColor?: Maybe<Scalars['String']['output']>;
  colorScheme?: Maybe<Scalars['String']['output']>;
  defaultSystemTemplate: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isOrganizationDefaultTemplate?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  organization?: Maybe<OrganizationNode>;
  organizationsettingsSet: OrganizationSettingsNodeConnection;
  scheduledreportnewSet: ScheduledReportNodeConnection;
  systemTemplate: Scalars['Boolean']['output'];
  template: Scalars['JSONString']['output'];
};

export type ReportTemplateNodeOrganizationsettingsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ReportTemplateNodeScheduledreportnewSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ReportTemplateNodeConnection = {
  __typename?: 'ReportTemplateNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReportTemplateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ReportTemplateNode` and its cursor. */
export type ReportTemplateNodeEdge = {
  __typename?: 'ReportTemplateNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ReportTemplateNode>;
};

export enum ReportsPublicReportSettingsDefaultOrderColumnChoices {
  /** Keyword */
  A_1 = 'A_1',
  /** Current rank */
  A_2 = 'A_2',
  /** Initial rank */
  A_3 = 'A_3',
  /** +/- */
  A_4 = 'A_4',
  /** Search volume */
  A_5 = 'A_5',
}

export enum ReportsScheduledReportNewReportTypeChoices {
  /** PDF */
  A_1 = 'A_1',
  /** Excel */
  A_2 = 'A_2',
  /** CSV */
  A_3 = 'A_3',
  /** Google Sheets */
  A_5 = 'A_5',
}

export enum ReportsScheduledReportNewScheduleChoices {
  /** Daily */
  A_1 = 'A_1',
  /** Weekly */
  A_2 = 'A_2',
  /** Monthly */
  A_3 = 'A_3',
}

export enum ReportsScheduledReportNewSearchTypeChoices {
  /** Desktop */
  A_1 = 'A_1',
  /** Mobile */
  A_2 = 'A_2',
}

export enum ReportsScheduledReportNewTagsFilterTypeChoices {
  /** And */
  A_1 = 'A_1',
  /** Or */
  A_2 = 'A_2',
}

export type RequestPasswordResetInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

export type RequestPasswordResetPayload = {
  __typename?: 'RequestPasswordResetPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  errors?: Maybe<Array<Maybe<ErrorType>>>;
};

export type ResetViewKeyClient = {
  __typename?: 'ResetViewKeyClient';
  client?: Maybe<ClientNode>;
};

export type ResetViewKeyDomain = {
  __typename?: 'ResetViewKeyDomain';
  domain?: Maybe<DomainNode>;
};

export type RetryPayment = {
  __typename?: 'RetryPayment';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RevShareAffiliateNode = GrapheneNode & {
  __typename?: 'RevShareAffiliateNode';
  affiliateId: Scalars['String']['output'];
  commission: Scalars['Int']['output'];
  customPlans: PricingPlanNodeConnection;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  minimumPayout: Scalars['Int']['output'];
  organization?: Maybe<OrganizationNode>;
  paypalEmail?: Maybe<Scalars['String']['output']>;
  signupType: AffiliateRevShareAffiliateSignupTypeChoices;
  totalCommission?: Maybe<Scalars['Float']['output']>;
  totalPaidCommission?: Maybe<Scalars['Float']['output']>;
  totalUnpaidCommission?: Maybe<Scalars['Float']['output']>;
  visitorSet: VisitorNodeConnection;
  webhookUrl?: Maybe<Scalars['String']['output']>;
};

export type RevShareAffiliateNodeCustomPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RevShareAffiliateNodeVisitorSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RevShareAffiliateNodeConnection = {
  __typename?: 'RevShareAffiliateNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RevShareAffiliateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RevShareAffiliateNode` and its cursor. */
export type RevShareAffiliateNodeEdge = {
  __typename?: 'RevShareAffiliateNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RevShareAffiliateNode>;
};

export type RunningTaskNode = GrapheneNode & {
  __typename?: 'RunningTaskNode';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  jobId: Scalars['UUID']['output'];
  lastKnownState: Scalars['String']['output'];
  nCompletedSubtasks?: Maybe<Scalars['Int']['output']>;
  progress?: Maybe<RunningTaskProgressNode>;
  totalSubtasks?: Maybe<Scalars['Int']['output']>;
};

export type RunningTaskProgressNode = {
  __typename?: 'RunningTaskProgressNode';
  current?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SalesDashboardInput = {
  filters: Array<InputMaybe<FilterInput>>;
  salesManagerId?: InputMaybe<Scalars['ID']['input']>;
};

export type SalesDashboardNode = {
  __typename?: 'SalesDashboardNode';
  organizations?: Maybe<Array<Maybe<SalesDashboardOrganizationNode>>>;
};

export type SalesDashboardOrganizationNode = {
  __typename?: 'SalesDashboardOrganizationNode';
  abuse?: Maybe<Scalars['Int']['output']>;
  affiliateVisitor?: Maybe<VisitorNode>;
  domains?: Maybe<Scalars['Int']['output']>;
  inDialog?: Maybe<Scalars['Boolean']['output']>;
  isRanktracker?: Maybe<Scalars['Boolean']['output']>;
  keywords?: Maybe<Scalars['Int']['output']>;
  latestPlan?: Maybe<OrganizationPlanNode>;
  organization?: Maybe<OrganizationNode>;
  organizationSettings?: Maybe<OrganizationSettingsNode>;
  subAccount?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<UserNode>;
};

export type SalesManagerNode = GrapheneNode & {
  __typename?: 'SalesManagerNode';
  active: Scalars['Boolean']['output'];
  approvedCountries: Array<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employmentDate: Scalars['Date']['output'];
  handlesChurn: Scalars['Boolean']['output'];
  hubspotId?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isAssignable: Scalars['Boolean']['output'];
  lastLeadAssignedAt: Scalars['DateTime']['output'];
  meetingLink?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationSet: OrganizationNodeConnection;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pipedriveId?: Maybe<Scalars['Int']['output']>;
  pricingplannewSet: PricingPlanNodeConnection;
  slackName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  unconfirmeduserSet: UnconfirmedUserNodeConnection;
  user?: Maybe<UserNode>;
};

export type SalesManagerNodeOrganizationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SalesManagerNodePricingplannewSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SalesManagerNodeUnconfirmeduserSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SaveOnUpgradeNode = {
  __typename?: 'SaveOnUpgradeNode';
  amount?: Maybe<Scalars['Float']['output']>;
  billingPeriod?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  maxKeywordsOnUpgradePlan?: Maybe<Scalars['Int']['output']>;
};

export type ScheduleCompetitorRanksReport = {
  __typename?: 'ScheduleCompetitorRanksReport';
  errors?: Maybe<Array<Maybe<Error>>>;
  scheduledReport?: Maybe<ScheduledReportNode>;
};

export type ScheduleCompetitorRanksReportInput = {
  competitors: Array<InputMaybe<Scalars['ID']['input']>>;
  domainId: Scalars['ID']['input'];
  emailBody?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['JSONString']['input'];
  language: Scalars['String']['input'];
  recipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** 3 = csv, 5 = google drive */
  reportType: Scalars['Int']['input'];
};

export type ScheduleCompetitorsReport = {
  __typename?: 'ScheduleCompetitorsReport';
  errors?: Maybe<Array<Maybe<Error>>>;
  scheduledReport?: Maybe<ScheduledReportNode>;
};

export type ScheduleCompetitorsReportInput = {
  domainId: Scalars['ID']['input'];
  emailBody?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['JSONString']['input'];
  language: Scalars['String']['input'];
  recipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** 3 = csv, 5 = google drive */
  reportType: Scalars['Int']['input'];
};

export type ScheduleFoldersReport = {
  __typename?: 'ScheduleFoldersReport';
  errors?: Maybe<Array<Maybe<Error>>>;
  scheduledReport?: Maybe<ScheduledReportNode>;
};

export type ScheduleFoldersReportInput = {
  domainId: Scalars['ID']['input'];
  emailBody?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['JSONString']['input'];
  language: Scalars['String']['input'];
  recipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reportType: Scalars['Int']['input'];
};

export type ScheduledReportNode = GrapheneNode & {
  __typename?: 'ScheduledReportNode';
  change?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryLocaleNode>;
  createdAt: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedByCascade: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  domain?: Maybe<DomainNode>;
  emailBody: Scalars['String']['output'];
  emailSubject: Scalars['String']['output'];
  generatedreportSet: GeneratedReportNodeConnection;
  group?: Maybe<ClientNode>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isGroupReport: Scalars['Boolean']['output'];
  isOneTimeReport: Scalars['Boolean']['output'];
  keyword?: Maybe<Scalars['String']['output']>;
  language: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  preferredUrl?: Maybe<Scalars['String']['output']>;
  rankFeature?: Maybe<Scalars['String']['output']>;
  rankQuery?: Maybe<Scalars['String']['output']>;
  rankValue?: Maybe<Scalars['Int']['output']>;
  recipients: Array<Scalars['String']['output']>;
  reportFilter?: Maybe<ReportFilterNode>;
  reportTemplate?: Maybe<ReportTemplateNode>;
  reportType: ReportsScheduledReportNewReportTypeChoices;
  schedule: ReportsScheduledReportNewScheduleChoices;
  scheduledDay?: Maybe<Scalars['Int']['output']>;
  searchEngine?: Maybe<SearchEngineNode>;
  searchType?: Maybe<ReportsScheduledReportNewSearchTypeChoices>;
  searchVolume?: Maybe<Scalars['Boolean']['output']>;
  searchVolumeQuery?: Maybe<Scalars['String']['output']>;
  searchVolumeValue?: Maybe<Scalars['Int']['output']>;
  sender: Scalars['String']['output'];
  starred?: Maybe<Scalars['Boolean']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  tagsFilterType?: Maybe<ReportsScheduledReportNewTagsFilterTypeChoices>;
  url?: Maybe<Scalars['String']['output']>;
  visitorsQuery?: Maybe<Scalars['String']['output']>;
  visitorsValue?: Maybe<Scalars['Int']['output']>;
  visitsPotential?: Maybe<Scalars['Boolean']['output']>;
  withHistory: Scalars['Boolean']['output'];
};

export type ScheduledReportNodeGeneratedreportSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ScheduledReportNodeConnection = {
  __typename?: 'ScheduledReportNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ScheduledReportNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ScheduledReportNode` and its cursor. */
export type ScheduledReportNodeEdge = {
  __typename?: 'ScheduledReportNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ScheduledReportNode>;
};

/**
 * ```graphql
 * mutation {
 *   scrapeDomain(input:{id: 3}) {
 *     domain {
 *       id
 *     }
 *     queued
 *   }
 * }
 * ```
 */
export type ScrapeDomain = {
  __typename?: 'ScrapeDomain';
  domain?: Maybe<DomainNode>;
  queued?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export type ScrapeDomainInput = {
  id: Scalars['ID']['input'];
  refreshTaskId?: InputMaybe<Scalars['String']['input']>;
};

export type SearchEngineNode = GrapheneNode & {
  __typename?: 'SearchEngineNode';
  aisearchvolumelatestSet: AiSearchVolumeNodeConnection;
  competitorScrapeDepth: Scalars['Int']['output'];
  defaultsearchsettingsearchengineSet: DefaultSearchSettingSearchEngineNodeConnection;
  icon: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  keywordSet: KeywordNodeConnection;
  /** Name of search engine, eg. 'Google' */
  name: Scalars['String']['output'];
  scheduledreportnewSet: ScheduledReportNodeConnection;
  scrapeDepth: Scalars['Int']['output'];
  searchvolumeSet: SearchVolumeNodeConnection;
};

export type SearchEngineNodeAisearchvolumelatestSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchEngineNodeDefaultsearchsettingsearchengineSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchEngineNodeKeywordSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchEngineNodeScheduledreportnewSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchEngineNodeSearchvolumeSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchSettingNode = {
  __typename?: 'SearchSettingNode';
  countrylocale?: Maybe<CountryLocaleNode>;
  enableAutocorrect?: Maybe<Scalars['Boolean']['output']>;
  ignoreFeaturedSnippet?: Maybe<Scalars['Boolean']['output']>;
  ignoreLocalResults?: Maybe<Scalars['Boolean']['output']>;
  locations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  searchEngines?: Maybe<Array<Maybe<SearchSettingSearchEngineNode>>>;
};

export type SearchSettingSearchEngineNode = {
  __typename?: 'SearchSettingSearchEngineNode';
  searchEngine?: Maybe<SearchEngineNode>;
  searchTypes?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type SearchVolumeHistoryNode = {
  __typename?: 'SearchVolumeHistoryNode';
  /** 2017-12-01 */
  month?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
};

export type SearchVolumeNode = GrapheneNode & {
  __typename?: 'SearchVolumeNode';
  aisearchvolumelatestSet: AiSearchVolumeNodeConnection;
  avgCostPerClick?: Maybe<Scalars['Decimal']['output']>;
  competition?: Maybe<Scalars['Decimal']['output']>;
  costPerClickHigh?: Maybe<Scalars['Decimal']['output']>;
  costPerClickLow?: Maybe<Scalars['Decimal']['output']>;
  createdAt: Scalars['DateTime']['output'];
  db: Scalars['String']['output'];
  /** Googles geotarget constant for locations and countries */
  geotargetId?: Maybe<Scalars['Int']['output']>;
  history?: Maybe<Array<Maybe<SearchVolumeHistoryNode>>>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isUpdating: Scalars['Boolean']['output'];
  keyword: Scalars['String']['output'];
  keywordSearchVolume: KeywordNodeConnection;
  keywordSearchVolumeLocal: KeywordNodeConnection;
  lastMonthWeHaveDataFor?: Maybe<Scalars['Date']['output']>;
  needsUpdate: Scalars['Boolean']['output'];
  searchEngine: SearchEngineNode;
  searchVolume?: Maybe<Scalars['Int']['output']>;
  searchVolumeDesktop?: Maybe<Scalars['Int']['output']>;
  searchVolumeMobile?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SearchVolumeNodeAisearchvolumelatestSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchVolumeNodeKeywordSearchVolumeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchVolumeNodeKeywordSearchVolumeLocalArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchVolumeNodeConnection = {
  __typename?: 'SearchVolumeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SearchVolumeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SearchVolumeNode` and its cursor. */
export type SearchVolumeNodeEdge = {
  __typename?: 'SearchVolumeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SearchVolumeNode>;
};

export enum SearchVolumeSettingInput {
  AccountDefault = 'account_default',
  Country = 'country',
  Local = 'local',
}

export type SerpFeatureOverviewChartNodeNode = {
  __typename?: 'SerpFeatureOverviewChartNodeNode';
  adsBottom?: Maybe<Scalars['Int']['output']>;
  adsInBetween?: Maybe<Scalars['Int']['output']>;
  adsTop?: Maybe<Scalars['Int']['output']>;
  aiOverview?: Maybe<Scalars['Int']['output']>;
  apps?: Maybe<Scalars['Int']['output']>;
  breadcrumbs?: Maybe<Scalars['Int']['output']>;
  breadcrumbsOwned?: Maybe<Scalars['Int']['output']>;
  carousel?: Maybe<Scalars['Int']['output']>;
  directAnswer?: Maybe<Scalars['Int']['output']>;
  discoverMorePlaces?: Maybe<Scalars['Int']['output']>;
  events?: Maybe<Scalars['Int']['output']>;
  faq?: Maybe<Scalars['Int']['output']>;
  faqOwned?: Maybe<Scalars['Int']['output']>;
  featureSnippet?: Maybe<Scalars['Int']['output']>;
  featureSnippetOwned?: Maybe<Scalars['Int']['output']>;
  featuredVideo?: Maybe<Scalars['Int']['output']>;
  featuredVideoOwned?: Maybe<Scalars['Int']['output']>;
  filterMenu?: Maybe<Scalars['Int']['output']>;
  flights?: Maybe<Scalars['Int']['output']>;
  forumPostPosition?: Maybe<Scalars['Int']['output']>;
  forumPostPositionOwned?: Maybe<Scalars['Int']['output']>;
  hotelsPosition?: Maybe<Scalars['Int']['output']>;
  hotelsPositionOwned?: Maybe<Scalars['Int']['output']>;
  https?: Maybe<Scalars['Int']['output']>;
  httpsOwned?: Maybe<Scalars['Int']['output']>;
  imagePack?: Maybe<Scalars['Int']['output']>;
  imageProductPackPosition?: Maybe<Scalars['Int']['output']>;
  imageProductPackPositionOwned?: Maybe<Scalars['Int']['output']>;
  jobPosting?: Maybe<Scalars['Int']['output']>;
  keywordCount?: Maybe<Scalars['Int']['output']>;
  knowledgeCards?: Maybe<Scalars['Int']['output']>;
  knowledgePanel?: Maybe<Scalars['Int']['output']>;
  knowledgePanelOwned?: Maybe<Scalars['Int']['output']>;
  knowledgePanelPriceComparison?: Maybe<Scalars['Int']['output']>;
  knowledgePanelPriceComparisonOwned?: Maybe<Scalars['Int']['output']>;
  mapsLocal?: Maybe<Scalars['Int']['output']>;
  mapsLocalOwned?: Maybe<Scalars['Int']['output']>;
  mapsLocalTeaser?: Maybe<Scalars['Int']['output']>;
  mapsLocalTeaserOwned?: Maybe<Scalars['Int']['output']>;
  mostPopularCarousel?: Maybe<Scalars['Int']['output']>;
  newsPosition?: Maybe<Scalars['Int']['output']>;
  newsPositionOwned?: Maybe<Scalars['Int']['output']>;
  organicStores?: Maybe<Scalars['Int']['output']>;
  organicStoresOwned?: Maybe<Scalars['Int']['output']>;
  podcasts?: Maybe<Scalars['Int']['output']>;
  popularProducts?: Maybe<Scalars['Int']['output']>;
  popularStores?: Maybe<Scalars['Int']['output']>;
  popularStoresOwned?: Maybe<Scalars['Int']['output']>;
  recipesOwned?: Maybe<Scalars['Int']['output']>;
  recipesPosition?: Maybe<Scalars['Int']['output']>;
  recipesPositionOwned?: Maybe<Scalars['Int']['output']>;
  refineCarousel?: Maybe<Scalars['Int']['output']>;
  relatedQuestions?: Maybe<Scalars['Int']['output']>;
  relatedQuestionsOwned?: Maybe<Scalars['Int']['output']>;
  relatedSearchesCarousel?: Maybe<Scalars['Int']['output']>;
  researchProductPosition?: Maybe<Scalars['Int']['output']>;
  researchProductPositionOwned?: Maybe<Scalars['Int']['output']>;
  resultsAbout?: Maybe<Scalars['Int']['output']>;
  reviews?: Maybe<Scalars['Int']['output']>;
  reviewsOwned?: Maybe<Scalars['Int']['output']>;
  richProductInfo?: Maybe<Scalars['Int']['output']>;
  richProductInfoOwned?: Maybe<Scalars['Int']['output']>;
  searchByPhotos?: Maybe<Scalars['Int']['output']>;
  shopStyles?: Maybe<Scalars['Int']['output']>;
  shopStylesOwned?: Maybe<Scalars['Int']['output']>;
  shopping?: Maybe<Scalars['Int']['output']>;
  siteLinks?: Maybe<Scalars['Int']['output']>;
  siteLinksOwned?: Maybe<Scalars['Int']['output']>;
  siteLinksSearchbox?: Maybe<Scalars['Int']['output']>;
  siteLinksSearchboxOwned?: Maybe<Scalars['Int']['output']>;
  thingsTodo?: Maybe<Scalars['Int']['output']>;
  thumbnails?: Maybe<Scalars['Int']['output']>;
  thumbnailsOwned?: Maybe<Scalars['Int']['output']>;
  tripsPopular?: Maybe<Scalars['Int']['output']>;
  tweets?: Maybe<Scalars['Int']['output']>;
  tweetsOwned?: Maybe<Scalars['Int']['output']>;
  video?: Maybe<Scalars['Int']['output']>;
  videoCarouselPosition?: Maybe<Scalars['Int']['output']>;
  videoCarouselPositionOwned?: Maybe<Scalars['Int']['output']>;
  videoOwned?: Maybe<Scalars['Int']['output']>;
  youtubeAds?: Maybe<Scalars['Int']['output']>;
  youtubeChannel?: Maybe<Scalars['Int']['output']>;
  youtubeChannelOwned?: Maybe<Scalars['Int']['output']>;
  youtubeLiveStream?: Maybe<Scalars['Int']['output']>;
  youtubeLiveStreamOwned?: Maybe<Scalars['Int']['output']>;
  youtubeMovie?: Maybe<Scalars['Int']['output']>;
  youtubeMovieOwned?: Maybe<Scalars['Int']['output']>;
  youtubePlaylist?: Maybe<Scalars['Int']['output']>;
  youtubePlaylistOwned?: Maybe<Scalars['Int']['output']>;
  youtubePromotedVideo?: Maybe<Scalars['Int']['output']>;
  youtubePromotedVideoOwned?: Maybe<Scalars['Int']['output']>;
  youtubeRadio?: Maybe<Scalars['Int']['output']>;
  youtubeRadioOwned?: Maybe<Scalars['Int']['output']>;
  youtubeRelatedMovies?: Maybe<Scalars['Int']['output']>;
  youtubeRelatedSearches?: Maybe<Scalars['Int']['output']>;
  youtubeRelatedToYourSearch?: Maybe<Scalars['Int']['output']>;
  youtubeSecondarySearchContainer?: Maybe<Scalars['Int']['output']>;
  youtubeShelfRender?: Maybe<Scalars['Int']['output']>;
  youtubeShow?: Maybe<Scalars['Int']['output']>;
  youtubeShowOwned?: Maybe<Scalars['Int']['output']>;
  youtubeVideo?: Maybe<Scalars['Int']['output']>;
  youtubeVideoOwned?: Maybe<Scalars['Int']['output']>;
};

export type SerpFeaturesByPathNode = {
  __typename?: 'SerpFeaturesByPathNode';
  filters?: Maybe<Array<Maybe<Filter>>>;
  serpFeaturesByPath?: Maybe<Scalars['GenericScalar']['output']>;
};

export type ServiceMessageNode = GrapheneNode & {
  __typename?: 'ServiceMessageNode';
  countryLocaleIds: Array<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customMessage?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  level?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  messageTypeId: AccurankerMessagesServiceMessageMessageTypeIdChoices;
  searchEngines: Array<Scalars['Int']['output']>;
  searchTypes: Array<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Sets an EAN invoice as paid. Required arguments are invoice_id. */
export type SetEanInvoicePaid = {
  __typename?: 'SetEanInvoicePaid';
  invoice?: Maybe<InvoiceNode>;
};

/** Sets an invoice as paid and registers the payment id. Required arguments are invoice_id and payment_id. */
export type SetInvoicePaid = {
  __typename?: 'SetInvoicePaid';
  invoice?: Maybe<InvoiceNode>;
};

export type SetLeadScore = {
  __typename?: 'SetLeadScore';
  organization?: Maybe<OrganizationNode>;
};

export type SetLeadScoreInput = {
  organizationId: Scalars['Int']['input'];
  score: Scalars['Int']['input'];
};

export type SetSalesManager = {
  __typename?: 'SetSalesManager';
  organization?: Maybe<OrganizationNode>;
};

export type SetSalesManagerInput = {
  organizationId: Scalars['String']['input'];
  salesManagerId: Scalars['String']['input'];
};

export type ShouldRecordSession = {
  __typename?: 'ShouldRecordSession';
  user?: Maybe<UserNode>;
};

/** Used for prepaid voucher campaigns ONLY */
export type SignUp = {
  __typename?: 'SignUp';
  errors?: Maybe<Array<Maybe<Error>>>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserNode>;
};

/** Based on SignUpCaptchaForm */
export type SignUpInput = {
  companySize: Scalars['String']['input'];
  companyType: Scalars['String']['input'];
  email: Scalars['String']['input'];
  keywordsSize: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  vouchers: Array<InputMaybe<Scalars['String']['input']>>;
};

export type SimpleCompetitorsNode = {
  __typename?: 'SimpleCompetitorsNode';
  competitorId?: Maybe<Scalars['ID']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
};

export type SimpleLog = {
  __typename?: 'SimpleLog';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SimpleLogInput = {
  action: Scalars['String']['input'];
  value: Scalars['Int']['input'];
};

export type SimpleUnknownCompetitorRank = {
  __typename?: 'SimpleUnknownCompetitorRank';
  ctr?: Maybe<Scalars['Float']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SitemapCompetitorNode = {
  __typename?: 'SitemapCompetitorNode';
  filters?: Maybe<Array<Maybe<Filter>>>;
  includeKeydis?: Maybe<Scalars['Boolean']['output']>;
  paths?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  unknownCompetitorDomains?: Maybe<Scalars['GenericScalar']['output']>;
};

/** This is the main node used by the frontend */
export type SitemapNode = {
  __typename?: 'SitemapNode';
  countrylocale?: Maybe<CountryLocaleNode>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  maxChildren?: Maybe<Scalars['Int']['output']>;
  mode?: Maybe<Scalars['String']['output']>;
  orderBy?: Maybe<Scalars['String']['output']>;
  sitemap?: Maybe<Scalars['GenericScalar']['output']>;
};

export type SoftDeleteUser = {
  __typename?: 'SoftDeleteUser';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type SoftDeleteUserInput = {
  id: Scalars['ID']['input'];
};

export type StaticTagInformationNode = {
  __typename?: 'StaticTagInformationNode';
  folderPath?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Scalars['GenericScalar']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};

/**
 * When you do this subscription you subscripte to a number of changes within your current organization (`context.user.organization`) ofcause it has the usual exception: `obj = USER`, where you only will get changes for your current logged in user.
 *
 * A typical event will look like the following:
 * ```graphql
 * {
 *   "obj": "USER",
 *   "action": "UPDATED",
 *   "id": "2"
 * }
 * ```
 *
 * `obj` determines what object the `id` is the identifier for, it can be any of:
 * - `KEYWORD` keyword has changed. `id = keyword.id`
 * - `KEYWORDS` scrape of all keywords within a domain is complete. `id = domain.id`
 * - `USER` user has changed. `id = user.id`
 * - `GROUP` group/client has changed. `id = client.id`
 * - `DOMAIN` domain has changed. `id = domain.id`
 * - `MESSAGE` message has changed. `id = message.id`
 * - `ORGANIZATION` organization has changed. `id = organization.id`
 * - `SCHEDULED_REPORT` scheduled report has changed. `id = scheduled_report.id`
 * - `GENERATED_REPORT` generated report has changed. `id = generated_report.id`
 * - `IMPORT` import report has changed. `id = import.id`
 *
 * `action` can be any of:
 * - `CREATED`:
 * - `UPDATED`
 * - `DELETED`
 * - `NOCHANGE`
 */
export type SubscriptionNode = {
  __typename?: 'SubscriptionNode';
  /** SubscriptionAction */
  action?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  /** SubscriptionObj */
  obj?: Maybe<Scalars['String']['output']>;
  /** Version */
  version?: Maybe<Scalars['String']['output']>;
};

/** The project root subscription definition */
export type Subscriptions = {
  __typename?: 'Subscriptions';
  debug?: Maybe<DjangoDebug>;
  /** subscribe to user and organization changes */
  liveChanges?: Maybe<SubscriptionNode>;
  /** subscribe to specific domain changes (Keyword, KeywordRank, Domain etc) */
  liveChangesDomain?: Maybe<SubscriptionNode>;
};

/** The project root subscription definition */
export type SubscriptionsLiveChangesDomainArgs = {
  id: Scalars['ID']['input'];
};

export type TempOverviewChartTagNode = {
  __typename?: 'TEMPOverviewChartTagNode';
  rows?: Maybe<Array<Maybe<TempOverviewChartTagRowNode>>>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type TempOverviewChartTagRowNode = {
  __typename?: 'TEMPOverviewChartTagRowNode';
  isDynamic: Scalars['Boolean']['output'];
  isStatic: Scalars['Boolean']['output'];
  key?: Maybe<Scalars['String']['output']>;
  otherTags: Scalars['Boolean']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type TableClientNode = {
  __typename?: 'TableClientNode';
  createdAt?: Maybe<Scalars['Date']['output']>;
  domainIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfDomains?: Maybe<Scalars['Int']['output']>;
  numberOfKeywords?: Maybe<Scalars['Int']['output']>;
};

export type TableClientsNode = {
  __typename?: 'TableClientsNode';
  clients?: Maybe<Array<Maybe<TableClientNode>>>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
};

export type TableCompetitorNode = {
  __typename?: 'TableCompetitorNode';
  asPercentage?: Maybe<Scalars['Boolean']['output']>;
  competitors?: Maybe<Array<Maybe<CompetitorRowNode>>>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
};

export type TableCompetitorRankListNode = {
  __typename?: 'TableCompetitorRankListNode';
  competitors?: Maybe<Array<Maybe<SimpleCompetitorsNode>>>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  keywords?: Maybe<Array<Maybe<KeywordRankWithCompetitorRankNode>>>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
};

export type TableDashboardNode = {
  __typename?: 'TableDashboardNode';
  domains?: Maybe<Scalars['GenericScalar']['output']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
};

export type TableKeywordHistoryListNode = {
  __typename?: 'TableKeywordHistoryListNode';
  filters?: Maybe<Array<Maybe<Filter>>>;
  history?: Maybe<Scalars['GenericScalar']['output']>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
};

export type TableKeywordHistoryListNodeHistoryArgs = {
  keywordId?: InputMaybe<Scalars['ID']['input']>;
};

export type TableKeywordListNode = {
  __typename?: 'TableKeywordListNode';
  allowPartialScrape?: Maybe<Scalars['Boolean']['output']>;
  displayCurrency?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  keywords?: Maybe<Scalars['GenericScalar']['output']>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
};

export type TableLandingPageNode = {
  __typename?: 'TableLandingPageNode';
  avgCostPerClick?: Maybe<Scalars['Float']['output']>;
  avgPageLoadTime?: Maybe<Scalars['Float']['output']>;
  avgPageLoadTimeCompare?: Maybe<Scalars['Float']['output']>;
  avgRevenue?: Maybe<Scalars['Float']['output']>;
  avgRevenueCompare?: Maybe<Scalars['Float']['output']>;
  avgTimeSpentOnPage?: Maybe<Scalars['Float']['output']>;
  avgTimeSpentOnPageCompare?: Maybe<Scalars['Float']['output']>;
  bounceRate?: Maybe<Scalars['Float']['output']>;
  bounceRateCompare?: Maybe<Scalars['Float']['output']>;
  costValue?: Maybe<Scalars['Float']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  dynamicCtrCompare?: Maybe<Scalars['Float']['output']>;
  dynamicCtrValue?: Maybe<Scalars['Float']['output']>;
  goals?: Maybe<Scalars['Float']['output']>;
  goalsCompare?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  keywords?: Maybe<Scalars['Int']['output']>;
  organicPageviews?: Maybe<Scalars['Float']['output']>;
  organicPageviewsCompare?: Maybe<Scalars['Float']['output']>;
  organicTraffic?: Maybe<Scalars['Int']['output']>;
  organicTrafficCompare?: Maybe<Scalars['Int']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  searchVolumeValue?: Maybe<Scalars['Int']['output']>;
  shareOfVoiceCompare?: Maybe<Scalars['Int']['output']>;
  shareOfVoicePercentageValue?: Maybe<Scalars['Float']['output']>;
  shareOfVoicePercentageValueCompare?: Maybe<Scalars['Float']['output']>;
  shareOfVoiceValue?: Maybe<Scalars['Int']['output']>;
  untrackedKeywords?: Maybe<Scalars['Int']['output']>;
};

export type TableLandingPagesNode = {
  __typename?: 'TableLandingPagesNode';
  displayCurrency?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  landingPages?: Maybe<Array<Maybe<TableLandingPageNode>>>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
};

export type TableNoteficationListNode = {
  __typename?: 'TableNoteficationListNode';
  filters?: Maybe<Array<Maybe<Filter>>>;
  notifications?: Maybe<Scalars['GenericScalar']['output']>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
};

export type TableTagCloudNode = {
  __typename?: 'TableTagCloudNode';
  filters?: Maybe<Array<Maybe<Filter>>>;
  ordering?: Maybe<Ordering>;
  pagination?: Maybe<Pagination>;
  tags?: Maybe<Array<Maybe<TableTagsNode>>>;
};

export type TableTagsNode = {
  __typename?: 'TableTagsNode';
  avgCpc?: Maybe<Scalars['Float']['output']>;
  avgRankCompare?: Maybe<Scalars['Float']['output']>;
  avgRankValue?: Maybe<Scalars['Float']['output']>;
  avgRevenue?: Maybe<Scalars['Float']['output']>;
  avgRevenueCompare?: Maybe<Scalars['Float']['output']>;
  avgTimeSpentOnPage?: Maybe<Scalars['Float']['output']>;
  avgTimeSpentOnPageCompare?: Maybe<Scalars['Float']['output']>;
  bounceRate?: Maybe<Scalars['Float']['output']>;
  bounceRateCompare?: Maybe<Scalars['Float']['output']>;
  costValue?: Maybe<Scalars['Float']['output']>;
  dynamicCtrCompare?: Maybe<Scalars['Float']['output']>;
  dynamicCtrValue?: Maybe<Scalars['Float']['output']>;
  goals?: Maybe<Scalars['Float']['output']>;
  goalsCompare?: Maybe<Scalars['Float']['output']>;
  keywords?: Maybe<Scalars['Int']['output']>;
  organicTraffic?: Maybe<Scalars['Int']['output']>;
  organicTrafficCompare?: Maybe<Scalars['Int']['output']>;
  searchVolumeValue?: Maybe<Scalars['Float']['output']>;
  shareOfVoiceCompare?: Maybe<Scalars['Float']['output']>;
  shareOfVoicePercentageValue?: Maybe<Scalars['Float']['output']>;
  shareOfVoicePercentageValueCompare?: Maybe<Scalars['Float']['output']>;
  shareOfVoiceValue?: Maybe<Scalars['Float']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};

export type TapClicksNode = GrapheneNode & {
  __typename?: 'TapClicksNode';
  active: Scalars['Boolean']['output'];
  driveConnection?: Maybe<Scalars['ID']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  key: Scalars['UUID']['output'];
  organization: OrganizationNode;
};

export type TapClicksNodeConnection = {
  __typename?: 'TapClicksNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TapClicksNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TapClicksNode` and its cursor. */
export type TapClicksNodeEdge = {
  __typename?: 'TapClicksNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TapClicksNode>;
};

export type TitleDescriptionNode = {
  __typename?: 'TitleDescriptionNode';
  description?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TopCompetitorsForKeyword = {
  __typename?: 'TopCompetitorsForKeyword';
  domain?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
};

export type TransferMultiAccountDomain = {
  __typename?: 'TransferMultiAccountDomain';
  code?: Maybe<KeywordOveruseCode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type TransferMultiAccountDomainInput = {
  copy: Scalars['Boolean']['input'];
  domainId: Scalars['ID']['input'];
  toClientId: Scalars['ID']['input'];
};

export type TrialAbuseLogNode = GrapheneNode & {
  __typename?: 'TrialAbuseLogNode';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  organization: OrganizationNode;
  value: Scalars['Int']['output'];
};

export type TrialAbuseLogNodeConnection = {
  __typename?: 'TrialAbuseLogNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TrialAbuseLogNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TrialAbuseLogNode` and its cursor. */
export type TrialAbuseLogNodeEdge = {
  __typename?: 'TrialAbuseLogNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TrialAbuseLogNode>;
};

export type UnconfirmedUserNode = GrapheneNode & {
  __typename?: 'UnconfirmedUserNode';
  accountType: UsersUnconfirmedUserAccountTypeChoices;
  campaignId?: Maybe<Scalars['String']['output']>;
  clicked: Scalars['Boolean']['output'];
  converted: Scalars['Boolean']['output'];
  convertedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdByCountry?: Maybe<Scalars['String']['output']>;
  createdByIp?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  fingerprint?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  inviteSent: Scalars['Boolean']['output'];
  inviteSentAt?: Maybe<Scalars['DateTime']['output']>;
  ipStatus?: Maybe<UsersUnconfirmedUserIpStatusChoices>;
  isSocialSignup: Scalars['Boolean']['output'];
  /** True if unconfirmed user is created by us and is an outbound lead */
  outbound: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberVerificationSid?: Maybe<Scalars['String']['output']>;
  phoneNumberVerified?: Maybe<Scalars['DateTime']['output']>;
  prefillFullName?: Maybe<Scalars['String']['output']>;
  refCode?: Maybe<Scalars['String']['output']>;
  referer?: Maybe<Scalars['String']['output']>;
  salesManager?: Maybe<SalesManagerNode>;
  signupType: UsersUnconfirmedUserSignupTypeChoices;
  trialDays?: Maybe<Scalars['String']['output']>;
  trialRequest: Scalars['Boolean']['output'];
  trialRequestAccountType?: Maybe<UsersUnconfirmedUserTrialRequestAccountTypeChoices>;
  trialRequestCompanyCountry?: Maybe<Scalars['String']['output']>;
  trialRequestCompanyName?: Maybe<Scalars['String']['output']>;
  trialRequestCompanyWebsite?: Maybe<Scalars['String']['output']>;
  trialRequestContactMethod: UsersUnconfirmedUserTrialRequestContactMethodChoices;
  trialRequestContactMethodPhone?: Maybe<Scalars['String']['output']>;
  trialRequestDemo: UsersUnconfirmedUserTrialRequestDemoChoices;
  trialRequestExistingTool?: Maybe<Scalars['String']['output']>;
  trialRequestFullName?: Maybe<Scalars['String']['output']>;
  trialRequestIntegrations: Scalars['Boolean']['output'];
  trialRequestKeywords?: Maybe<Scalars['String']['output']>;
  trialRequestMessage: Scalars['String']['output'];
  trialRequestUsingExistingTool: Scalars['Boolean']['output'];
  utmCampaign?: Maybe<Scalars['String']['output']>;
  utmMedium?: Maybe<Scalars['String']['output']>;
  utmSource?: Maybe<Scalars['String']['output']>;
  visitorSet: VisitorNodeConnection;
};

export type UnconfirmedUserNodeVisitorSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UnconfirmedUserNodeConnection = {
  __typename?: 'UnconfirmedUserNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UnconfirmedUserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UnconfirmedUserNode` and its cursor. */
export type UnconfirmedUserNodeEdge = {
  __typename?: 'UnconfirmedUserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UnconfirmedUserNode>;
};

export type UniqueKeywordsNode = {
  __typename?: 'UniqueKeywordsNode';
  filters?: Maybe<Array<Maybe<Filter>>>;
  uniqueKeywords?: Maybe<Array<Scalars['String']['output']>>;
};

export type UnknownCompetitorRankDistributionGraphNode = {
  __typename?: 'UnknownCompetitorRankDistributionGraphNode';
  competitor?: Maybe<Scalars['Boolean']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  ownDomain?: Maybe<Scalars['Boolean']['output']>;
  rank1?: Maybe<Scalars['Int']['output']>;
  rank2?: Maybe<Scalars['Int']['output']>;
  rank3?: Maybe<Scalars['Int']['output']>;
  rank4?: Maybe<Scalars['Int']['output']>;
  rank5?: Maybe<Scalars['Int']['output']>;
  rank6?: Maybe<Scalars['Int']['output']>;
  rank7?: Maybe<Scalars['Int']['output']>;
  rank8?: Maybe<Scalars['Int']['output']>;
  rank9?: Maybe<Scalars['Int']['output']>;
  rank10?: Maybe<Scalars['Int']['output']>;
};

export type UnkowncompetitorHistoryGraphNode = {
  __typename?: 'UnkowncompetitorHistoryGraphNode';
  domain?: Maybe<Scalars['String']['output']>;
  ownDomain?: Maybe<Scalars['Boolean']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  searchDate?: Maybe<Scalars['String']['output']>;
  youtubeChannelName?: Maybe<Scalars['String']['output']>;
};

export type UpdateAdobeMarketingConnection = {
  __typename?: 'UpdateAdobeMarketingConnection';
  connection?: Maybe<AdobeMarketingConnectionNode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateAdobeMarketingConnectionInput = {
  delete: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateAllRead = {
  __typename?: 'UpdateAllRead';
  errors?: Maybe<Array<Maybe<Error>>>;
  messages?: Maybe<Array<Maybe<MessageNode>>>;
};

/**
 * ```graphql
 * mutation {
 *   updateClient(input:{id: 1 name:"test test"}) {
 *     errors {
 *       field
 *       messages
 *     }
 *     client {
 *       id
 *     }
 *   }
 * }
 * ```
 */
export type UpdateClient = {
  __typename?: 'UpdateClient';
  client?: Maybe<ClientNode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

/** Based on ClientForm */
export type UpdateClientInput = {
  delete: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organization: Scalars['ID']['input'];
};

export type UpdateCompetitor = {
  __typename?: 'UpdateCompetitor';
  competitor?: Maybe<CompetitorNode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateCompetitorInput = {
  delete: Scalars['Boolean']['input'];
  displayName: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  facebookProfile: Scalars['String']['input'];
  googleBusinessNameList: Array<InputMaybe<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  instagramProfile: Scalars['String']['input'];
  linkedinProfile: Scalars['String']['input'];
  tiktokProfile: Scalars['String']['input'];
  twitterHandle: Scalars['String']['input'];
};

export type UpdateDomain = {
  __typename?: 'UpdateDomain';
  code?: Maybe<KeywordOveruseCode>;
  domain?: Maybe<DomainNode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateDomainDisplayCurrency = {
  __typename?: 'UpdateDomainDisplayCurrency';
  domain?: Maybe<DomainNode>;
};

export type UpdateDomainDisplayCurrencyInput = {
  currency: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

/** Based on EditDomainForm */
export type UpdateDomainInput = {
  client: Scalars['ID']['input'];
  defaultSearchsettings?: InputMaybe<Array<InputMaybe<DefaultSearchSettingsInput>>>;
  displayName: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  exactMatch: Scalars['Boolean']['input'];
  facebookProfile: Scalars['String']['input'];
  googleBusinessNameList: Array<InputMaybe<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  includeSubdomains: Scalars['Boolean']['input'];
  instagramProfile: Scalars['String']['input'];
  linkedinProfile: Scalars['String']['input'];
  paused: Scalars['Boolean']['input'];
  shareOfVoicePercentage: Scalars['Boolean']['input'];
  tiktokProfile: Scalars['String']['input'];
  twitterHandle: Scalars['String']['input'];
};

export type UpdateDomainSpecialSettings = {
  __typename?: 'UpdateDomainSpecialSettings';
  domain?: Maybe<DomainNode>;
};

export type UpdateDomainSpecialSettingsInput = {
  currency: Scalars['String']['input'];
  domainId: Scalars['ID']['input'];
  searchVolumeSetting: SearchVolumeSettingInput;
  unrankedWeight?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateDynamicTagRuleset = {
  __typename?: 'UpdateDynamicTagRuleset';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateDynamicTagRulesetInput = {
  domainId: Scalars['Int']['input'];
  folderPath?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  rawRuleset?: InputMaybe<Scalars['JSONString']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFolderDescription = {
  __typename?: 'UpdateFolderDescription';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateFolderStructure = {
  __typename?: 'UpdateFolderStructure';
  errors?: Maybe<Array<Maybe<Error>>>;
};

/**
 * ```graphql
 * mutation {
 *   updateGeneratedReport(input: {id: 8, delete: true}) {
 *     reportTemplate {
 *       id
 *       organization {
 *         id
 *       }
 *       brandColor
 *       template
 *       name
 *     }
 *     errors {
 *       field
 *       messages
 *     }
 *   }
 * }
 * ```
 */
export type UpdateGeneratedReport = {
  __typename?: 'UpdateGeneratedReport';
  generatedReport?: Maybe<GeneratedReportNode>;
};

export type UpdateGeneratedReportInput = {
  delete: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

/**
 * ```graphql
 * mutation {
 *   updateGoogleAccount(input: {id: 123, description:"beskrivelse" delete: false}) {
 *     connection {
 *       id
 *     }
 *   }
 * }
 * ```
 */
export type UpdateGoogleConnection = {
  __typename?: 'UpdateGoogleConnection';
  connection?: Maybe<GoogleOAuthConnectionNode>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateGoogleConnectionInput = {
  delete: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

/**
 * Update the contact details on an issued invoice.
 *
 * It will NOT change the details if country or VAT number has changed.
 */
export type UpdateInvoiceContact = {
  __typename?: 'UpdateInvoiceContact';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateInvoiceContactInput = {
  invoiceId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateKeywords = {
  __typename?: 'UpdateKeywords';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateKeywordsInput = {
  addTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enableAutocorrect?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>>>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  ignoreFeaturedSnippet?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreInShareOfVoice?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreLocalResults?: InputMaybe<Scalars['Boolean']['input']>;
  ignoredDomain?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  keywordsToExclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  moveToDomain?: InputMaybe<Scalars['ID']['input']>;
  preferredLandingPage?: InputMaybe<Scalars['String']['input']>;
  removeTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  resetPreferredLandingPage?: InputMaybe<Scalars['Boolean']['input']>;
  scrape?: InputMaybe<Scalars['Boolean']['input']>;
  starred?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * ```graphql
 * mutation {
 *   updateMyUser(input: {fullName: "test testersen", email: "test123@test.com", language:"en"}) {
 *     user {
 *       id
 *       fullName
 *       email
 *       language
 *     }
 *     errors {
 *       field
 *       messages
 *     }
 *   }
 * }
 * ```
 */
export type UpdateMyUser = {
  __typename?: 'UpdateMyUser';
  errors?: Maybe<Array<Maybe<Error>>>;
  user?: Maybe<UserNode>;
};

/** Based on UpdateMyUserForm */
export type UpdateMyUserInput = {
  defaultCompareTo?: InputMaybe<Scalars['String']['input']>;
  defaultKeywordsColumns?: InputMaybe<Scalars['JSONString']['input']>;
  defaultKeywordsPage?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  /** da/en */
  language: Scalars['String']['input'];
  resetApiToken: Scalars['Boolean']['input'];
};

/**
 * ```graphql
 * mutation {
 *   updateMyUserPassword(input: {oldPassword:"accuranker123" password:"123accuranker"}) {
 *     user {
 *       id
 *     }
 *     errors {
 *       field
 *       messages
 *     }
 *   }
 * }
 * ```
 */
export type UpdateMyUserPassword = {
  __typename?: 'UpdateMyUserPassword';
  errors?: Maybe<Array<Maybe<Error>>>;
  user?: Maybe<UserNode>;
};

/** Based on UpdateMyUserPasswordForm */
export type UpdateMyUserPasswordInput = {
  oldPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/**
 * ```graphql
 * mutation {
 *   updateNote(input: {id: 3, createdAt: "2017-11-11", note: "test note 123"}) {
 *     errors {
 *       field
 *       messages
 *     }
 *     note {
 *       id
 *       note
 *     }
 *   }
 * }
 * ```
 */
export type UpdateNote = {
  __typename?: 'UpdateNote';
  errors?: Maybe<Array<Maybe<Error>>>;
  note?: Maybe<NoteNode>;
};

/** Based on EditNoteForm */
export type UpdateNoteInput = {
  createdAt: Scalars['Date']['input'];
  delete: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  keywords?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  keywordsToExclude?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Max length is 2048 chars. */
  note: Scalars['String']['input'];
};

export type UpdateOrganization = {
  __typename?: 'UpdateOrganization';
  errors?: Maybe<Array<Maybe<Error>>>;
  organization?: Maybe<OrganizationNode>;
};

/** based on UpdateOrganizationForm */
export type UpdateOrganizationInput = {
  displayCurrency: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isPartner: Scalars['Boolean']['input'];
  keywordOveruseEnabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  showLocalSearchVolume: Scalars['Boolean']['input'];
  unrankedWeight?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateOrganizationPlan = {
  __typename?: 'UpdateOrganizationPlan';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateOrganizationPlanInput = {
  featureAdvancedMetrics?: InputMaybe<Scalars['Boolean']['input']>;
  featureApiAccess?: InputMaybe<Scalars['Boolean']['input']>;
  featureApiV4WriteAccess?: InputMaybe<Scalars['Boolean']['input']>;
  maxCompetitors?: InputMaybe<Scalars['Int']['input']>;
  maxKeywords?: InputMaybe<Scalars['Int']['input']>;
  maxNumbersOfWorkspaces?: InputMaybe<Scalars['Int']['input']>;
  planId: Scalars['ID']['input'];
};

export type UpdatePublicReportSettings = {
  __typename?: 'UpdatePublicReportSettings';
  errors?: Maybe<Array<Maybe<Error>>>;
  publicReportSettings?: Maybe<PublicReportSettingsNode>;
};

export type UpdateRead = {
  __typename?: 'UpdateRead';
  errors?: Maybe<Array<Maybe<Error>>>;
  messages?: Maybe<Array<Maybe<MessageNode>>>;
};

export type UpdateReadInput = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
  read: Scalars['Boolean']['input'];
};

/**
 * ```graphql
 * mutation {
 *   updateReportTemplate(input: {id: 8, brandColor: "#FFA726", template: "[{}]", name: "test 123", delete: false, default: false}) {
 *     reportTemplate {
 *       id
 *       organization {
 *         id
 *       }
 *       brandColor
 *       template
 *       name
 *     }
 *     errors {
 *       field
 *       messages
 *     }
 *   }
 * }
 * ```
 */
export type UpdateReportTemplate = {
  __typename?: 'UpdateReportTemplate';
  errors?: Maybe<Array<Maybe<Error>>>;
  reportTemplate?: Maybe<ReportTemplateNode>;
};

export type UpdateReportTemplateInput = {
  brandColor: Scalars['String']['input'];
  default: Scalars['Boolean']['input'];
  delete: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  template: Scalars['JSONString']['input'];
};

/**
 * Same rules apply here as in AddScheduledReport
 *
 * ```graphql
 * mutation {
 *   updateScheduledReport(input: {id: 1, isGroupReport: false, isOneTimeReport: false, domain: 2, reportType: 1, reportTemplate: 1, reportFilter: 1, schedule: 1, recipients: ["cm@accuranker.com"], sender: "cm-sender@accuranker.com", emailSubject: "subject", emailBody: "body", language: "da"}) {
 *     scheduledReport {
 *       id
 *     }
 *     errors {
 *       field
 *       messages
 *     }
 *   }
 * }
 * ```
 */
export type UpdateScheduledReport = {
  __typename?: 'UpdateScheduledReport';
  errors?: Maybe<Array<Maybe<Error>>>;
  scheduledReport?: Maybe<ScheduledReportNode>;
};

export type UpdateScheduledReportInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['ID']['input']>;
  emailBody?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  isGroupReport: Scalars['Boolean']['input'];
  isOneTimeReport: Scalars['Boolean']['input'];
  language: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  recipients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  reportFilter?: InputMaybe<AddReportFilterInput>;
  reportTemplate?: InputMaybe<Scalars['ID']['input']>;
  reportType: Scalars['Int']['input'];
  schedule: Scalars['Int']['input'];
  scheduledDay?: InputMaybe<Scalars['Int']['input']>;
  sender?: InputMaybe<Scalars['String']['input']>;
  withHistory?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * ```graphql
 * mutation {
 *   updateScheduledReportRecipients(input: {id: 1, recipients: ["cm@accuranker.com"]}) {
 *     scheduledReport {
 *       id
 *     }
 *     errors {
 *       field
 *       messages
 *     }
 *   }
 * }
 * ```
 */
export type UpdateScheduledReportRecipients = {
  __typename?: 'UpdateScheduledReportRecipients';
  errors?: Maybe<Array<Maybe<Error>>>;
  scheduledReport?: Maybe<ScheduledReportNode>;
};

export type UpdateScheduledReportRecipientsInput = {
  id: Scalars['ID']['input'];
  recipients: Array<InputMaybe<Scalars['String']['input']>>;
};

export type UpdateStaticTag = {
  __typename?: 'UpdateStaticTag';
  errors: Array<Maybe<Error>>;
};

export type UpdateStaticTagInput = {
  domainId: Scalars['Int']['input'];
  newFolderPath: Scalars['String']['input'];
  newKeywordIds: Array<InputMaybe<Scalars['ID']['input']>>;
  newTag: Scalars['String']['input'];
  oldFolderPath: Scalars['String']['input'];
  oldKeywordIds: Array<InputMaybe<Scalars['ID']['input']>>;
  oldTag: Scalars['String']['input'];
};

export type UpdateUploadImport = {
  __typename?: 'UpdateUploadImport';
  uploadImport?: Maybe<ImportNode>;
};

export type UpdateUploadImportInput = {
  client: Scalars['ID']['input'];
  columns: Array<InputMaybe<ImportColumnInput>>;
  id: Scalars['ID']['input'];
};

/**
 * ```graphql
 * mutation {
 *   updateUser(input: {id: 1, fullName: "test testersen", email: "test123@test.com", isOrgAdmin: true, disableSearchVolume: true}) {
 *     user {
 *       id
 *       fullName
 *       email
 *       language
 *       disableSearchVolume
 *       isOrgAdmin
 *     }
 *     errors {
 *       field
 *       messages
 *     }
 *   }
 * }
 * ```
 */
export type UpdateUser = {
  __typename?: 'UpdateUser';
  errors?: Maybe<Array<Maybe<Error>>>;
  user?: Maybe<UserNode>;
};

/**
 * ```graphql
 * mutation {
 *   updateFilter(input: {id: 2, name:"change name 1" type: 1 filters:"{"bla": "test"}"}){
 *     errors {
 *       field
 *       messages
 *     }
 *     filters {
 *       id
 *       name
 *     }
 *   }
 * }
 * ```
 */
export type UpdateUserFilter = {
  __typename?: 'UpdateUserFilter';
  errors?: Maybe<Array<Maybe<Error>>>;
  filter?: Maybe<UserFilterNode>;
};

/** same as CreateUserFilterInput but takes an id and delete bool */
export type UpdateUserFilterInput = {
  apiFilter?: InputMaybe<Scalars['Boolean']['input']>;
  defaultForDomains?: InputMaybe<Scalars['Boolean']['input']>;
  defaultForKeywords?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  filters: Scalars['JSONString']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  type: Scalars['Int']['input'];
};

/** Based on UpdateUserForm */
export type UpdateUserInput = {
  delete: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isOrgAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * User type indicating the role of the user:
   * 1 = Organization Admin
   * 2 = Super User
   * 3 = Write User
   * 4 = Read User
   */
  userType: Scalars['Int']['input'];
  workspaces?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type UpdateUserSettings = {
  __typename?: 'UpdateUserSettings';
  user?: Maybe<UserNode>;
};

export type UpdateUserSettingsInput = {
  defaultCompareTo?: InputMaybe<Scalars['String']['input']>;
  defaultCompetitorsColumns?: InputMaybe<Scalars['JSONString']['input']>;
  defaultFoldersColumns?: InputMaybe<Scalars['JSONString']['input']>;
  defaultHomeColumns?: InputMaybe<Scalars['JSONString']['input']>;
  defaultKeydisColumns?: InputMaybe<Scalars['JSONString']['input']>;
  defaultKeywordsColumns?: InputMaybe<Scalars['JSONString']['input']>;
  defaultKeywordsNotificationsColumns?: InputMaybe<Scalars['JSONString']['input']>;
  defaultKeywordsOverviewVersion?: InputMaybe<Scalars['String']['input']>;
  defaultKeywordsPage?: InputMaybe<Scalars['String']['input']>;
  defaultLandingPagesColumns?: InputMaybe<Scalars['JSONString']['input']>;
  defaultNotesColumns?: InputMaybe<Scalars['JSONString']['input']>;
  defaultOverviewCharts?: InputMaybe<Scalars['JSONString']['input']>;
  defaultTagCloudColumns?: InputMaybe<Scalars['JSONString']['input']>;
  keywordsGroupOrder?: InputMaybe<Scalars['JSONString']['input']>;
};

export type UpdateWizardProgressOrganization = {
  __typename?: 'UpdateWizardProgressOrganization';
  organization?: Maybe<OrganizationNode>;
};

export type UpdateWizardProgressOrganizationInput = {
  id: Scalars['ID']['input'];
  userWantsToBeCalled?: InputMaybe<Scalars['Boolean']['input']>;
  wizardProgress?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateWorkspace = {
  __typename?: 'UpdateWorkspace';
  errors?: Maybe<Array<Maybe<Error>>>;
  workspace?: Maybe<WorkspaceNode>;
};

export type UpdateWorkspaceInput = {
  clients: Array<InputMaybe<Scalars['Int']['input']>>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  users: Array<InputMaybe<Scalars['Int']['input']>>;
  workspaceId: Scalars['ID']['input'];
};

export type UpgradeUaToGa4Input = {
  domainId: Scalars['String']['input'];
  ga4PropertyId: Scalars['String']['input'];
};

export type UrlNode = {
  __typename?: 'UrlNode';
  id?: Maybe<Scalars['ID']['output']>;
  path?: Maybe<Scalars['String']['output']>;
};

export type UserFilterNode = GrapheneNode & {
  __typename?: 'UserFilterNode';
  apiFilter: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  defaultForDomains: Scalars['Boolean']['output'];
  /** List of domain ids */
  defaultForKeywords?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  /** JSON array of filter objects. */
  filters: Scalars['JSONString']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The name of the filter. */
  name: Scalars['String']['output'];
  organization?: Maybe<OrganizationNode>;
  type: UsersUserFilterTypeChoices;
};

export type UserFilterNodeConnection = {
  __typename?: 'UserFilterNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserFilterNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserFilterNode` and its cursor. */
export type UserFilterNodeEdge = {
  __typename?: 'UserFilterNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserFilterNode>;
};

/** DO NOT USE @graphene_require_login */
export type UserNode = {
  __typename?: 'UserNode';
  apiToken?: Maybe<Scalars['String']['output']>;
  appData?: Maybe<MetaDataNode>;
  dateJoined?: Maybe<Scalars['String']['output']>;
  defaultCompareTo?: Maybe<Scalars['String']['output']>;
  defaultCompetitorsColumns?: Maybe<Scalars['JSONString']['output']>;
  defaultFoldersColumns?: Maybe<Scalars['JSONString']['output']>;
  defaultHomeColumns?: Maybe<Scalars['JSONString']['output']>;
  defaultImportGscColumns?: Maybe<Scalars['JSONString']['output']>;
  defaultKeydisColumns?: Maybe<Scalars['JSONString']['output']>;
  defaultKeywordsColumns?: Maybe<Scalars['JSONString']['output']>;
  defaultKeywordsNotificationsColumns?: Maybe<Scalars['JSONString']['output']>;
  defaultKeywordsOverviewVersion?: Maybe<Scalars['String']['output']>;
  defaultKeywordsPage?: Maybe<Scalars['String']['output']>;
  defaultLandingPagesColumns?: Maybe<Scalars['JSONString']['output']>;
  defaultNotesColumns?: Maybe<Scalars['JSONString']['output']>;
  defaultOverviewCharts?: Maybe<Scalars['GenericScalar']['output']>;
  defaultTagCloudColumns?: Maybe<Scalars['JSONString']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  googleConnections?: Maybe<Array<Maybe<GoogleOAuthConnectionNode>>>;
  hasOtpDevice?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  impersonateOriginUser?: Maybe<UserNode>;
  intercomHash?: Maybe<Scalars['String']['output']>;
  isAccurankerSuperuser?: Maybe<Scalars['Boolean']['output']>;
  isAffiliate?: Maybe<Scalars['Boolean']['output']>;
  isAffiliateAdmin?: Maybe<Scalars['Boolean']['output']>;
  isAuthenticated?: Maybe<Scalars['Boolean']['output']>;
  isCfo?: Maybe<Scalars['Boolean']['output']>;
  isImpersonating?: Maybe<Scalars['Boolean']['output']>;
  isOnMultiaccount?: Maybe<Scalars['Boolean']['output']>;
  keywordsGroupOrder?: Maybe<Scalars['JSONString']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  multiaccountOrganization?: Maybe<OrganizationNode>;
  multiaccountOriginUser?: Maybe<UserNode>;
  organization?: Maybe<OrganizationNode>;
  organizations?: Maybe<Array<Maybe<OrganizationNode>>>;
  permissionsEditedAt?: Maybe<Scalars['DateTime']['output']>;
  quickNavigationData?: Maybe<Scalars['GenericScalar']['output']>;
  runningTasks?: Maybe<Array<Maybe<RunningTaskNode>>>;
  salesManager?: Maybe<SalesManagerNode>;
  savedFilters?: Maybe<Array<Maybe<UserFilterNode>>>;
  shouldRecordSession?: Maybe<Scalars['Boolean']['output']>;
  socialAuths?: Maybe<UserSocialAuthsNode>;
  unconfirmedUserId?: Maybe<Scalars['String']['output']>;
  /**
   * User type indicating the role of the user:
   * 1 = Organization Admin
   * 2 = Super User
   * 3 = Write User
   * 4 = Read User
   */
  userType?: Maybe<Scalars['Int']['output']>;
  workspaces?: Maybe<Array<Maybe<WorkspaceNode>>>;
};

/**
 * backend: `google_oauth2` or `facebook`
 *
 * ```graphql
 * mutation {
 *   userSocialAuthDisconnect(input: {backend: "google_oauth2"}) {
 *     user {
 *       socialAuths {
 *         facebook
 *         facebookUrl
 *         googleOauth2
 *         googleOauth2Url
 *       }
 *     }
 *   }
 * }
 * ```
 */
export type UserSocialAuthDisconnect = {
  __typename?: 'UserSocialAuthDisconnect';
  user?: Maybe<UserNode>;
};

export type UserSocialAuthDisconnectInput = {
  backend: Scalars['String']['input'];
};

export type UserSocialAuthsNode = {
  __typename?: 'UserSocialAuthsNode';
  facebook?: Maybe<Scalars['Boolean']['output']>;
  google?: Maybe<Scalars['Boolean']['output']>;
  linkedin?: Maybe<Scalars['Boolean']['output']>;
  microsoft?: Maybe<Scalars['Boolean']['output']>;
};

export enum UsersUnconfirmedUserAccountTypeChoices {
  /** Normal account */
  A_1 = 'A_1',
  /** MCC account */
  A_2 = 'A_2',
}

export enum UsersUnconfirmedUserIpStatusChoices {
  /** Residential or business IP */
  A_0 = 'A_0',
  /** Non-residential IP */
  A_1 = 'A_1',
  /** Non-residential & residential IP */
  A_2 = 'A_2',
}

export enum UsersUnconfirmedUserSignupTypeChoices {
  /** Trial */
  A_1 = 'A_1',
  /** Trial with CC and recurring */
  A_2 = 'A_2',
  /** No trial */
  A_3 = 'A_3',
}

export enum UsersUnconfirmedUserTrialRequestAccountTypeChoices {
  /** Agency */
  A_1 = 'A_1',
  /** E-commerce */
  A_2 = 'A_2',
  /** Brand */
  A_3 = 'A_3',
  /** Independent/Consultant */
  A_4 = 'A_4',
}

export enum UsersUnconfirmedUserTrialRequestContactMethodChoices {
  /** By email */
  A_1 = 'A_1',
  /** By phone */
  A_2 = 'A_2',
}

export enum UsersUnconfirmedUserTrialRequestDemoChoices {
  /** Yes */
  A_1 = 'A_1',
  /** No */
  A_2 = 'A_2',
  /** Maybe */
  A_3 = 'A_3',
}

export enum UsersUserFilterTypeChoices {
  /** Domains filter */
  A_1 = 'A_1',
  /** Keywords filter */
  A_2 = 'A_2',
  /** Keyword Discovery filter */
  A_14 = 'A_14',
  /** Organic Site Explorer filter */
  A_15 = 'A_15',
  /** Tag Cloud filter */
  A_16 = 'A_16',
}

export type ValidateDomainNode = {
  __typename?: 'ValidateDomainNode';
  domain?: Maybe<Scalars['String']['output']>;
  domainValidation?: Maybe<DomainValidationNode>;
};

export type VisitorNode = GrapheneNode & {
  __typename?: 'VisitorNode';
  adChannel: AffiliateVisitorAdChannelChoices;
  adChannelIdentifier?: Maybe<Scalars['String']['output']>;
  affiliate: RevShareAffiliateNode;
  campaign?: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  ip: Scalars['String']['output'];
  irClickId?: Maybe<Scalars['String']['output']>;
  isBot: Scalars['Boolean']['output'];
  organization?: Maybe<OrganizationNode>;
  origin?: Maybe<Scalars['String']['output']>;
  pageviewSet: PageViewNodeConnection;
  placement?: Maybe<Scalars['String']['output']>;
  referrer?: Maybe<Scalars['String']['output']>;
  totalCommission?: Maybe<Scalars['Float']['output']>;
  unconfirmedUser?: Maybe<UnconfirmedUserNode>;
  unique?: Maybe<Scalars['String']['output']>;
  userAgent: Scalars['String']['output'];
  utmCampaign?: Maybe<Scalars['String']['output']>;
  utmContent?: Maybe<Scalars['String']['output']>;
  utmMedium?: Maybe<Scalars['String']['output']>;
  utmSource?: Maybe<Scalars['String']['output']>;
  visitorpaymentSet: VisitorPaymentNodeConnection;
};

export type VisitorNodePageviewSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VisitorNodeVisitorpaymentSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VisitorNodeConnection = {
  __typename?: 'VisitorNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VisitorNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VisitorNode` and its cursor. */
export type VisitorNodeEdge = {
  __typename?: 'VisitorNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VisitorNode>;
};

export type VisitorPaymentNode = GrapheneNode & {
  __typename?: 'VisitorPaymentNode';
  amount: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  paid: Scalars['Boolean']['output'];
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  payment: PaymentNode;
  visitor: VisitorNode;
};

export type VisitorPaymentNodeConnection = {
  __typename?: 'VisitorPaymentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VisitorPaymentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VisitorPaymentNode` and its cursor. */
export type VisitorPaymentNodeEdge = {
  __typename?: 'VisitorPaymentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VisitorPaymentNode>;
};

export type WalletNode = GrapheneNode & {
  __typename?: 'WalletNode';
  amount: Scalars['Decimal']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currency: BillingWalletCurrencyChoices;
  description: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  organization: OrganizationNode;
  payment?: Maybe<PaymentNode>;
  walletType?: Maybe<BillingWalletWalletTypeChoices>;
};

export type WalletNodeConnection = {
  __typename?: 'WalletNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WalletNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `WalletNode` and its cursor. */
export type WalletNodeEdge = {
  __typename?: 'WalletNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<WalletNode>;
};

export type WinnersAndLosersChartNodeNode = {
  __typename?: 'WinnersAndLosersChartNodeNode';
  losersSovLoss: Scalars['Float']['output'];
  periodFrom: Scalars['String']['output'];
  periodTo: Scalars['String']['output'];
  rankLosers: Scalars['Int']['output'];
  rankNoChange: Scalars['Int']['output'];
  rankWinners: Scalars['Int']['output'];
  winnersSovGain: Scalars['Float']['output'];
};

export type WisePaymentNode = GrapheneNode & {
  __typename?: 'WisePaymentNode';
  amount: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  payment?: Maybe<PaymentNode>;
  possibleInvoiceNumbers: Array<Scalars['Int']['output']>;
  reconciled: Scalars['Boolean']['output'];
  reference: Scalars['String']['output'];
  sender: Scalars['String']['output'];
  txId: Scalars['String']['output'];
};

export type WisePaymentNodeConnection = {
  __typename?: 'WisePaymentNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WisePaymentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `WisePaymentNode` and its cursor. */
export type WisePaymentNodeEdge = {
  __typename?: 'WisePaymentNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<WisePaymentNode>;
};

export type WorkspaceNode = {
  __typename?: 'WorkspaceNode';
  clients?: Maybe<Array<Maybe<ClientNode>>>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isAllGroups: Scalars['Boolean']['output'];
  lastEdited?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  organization?: Maybe<OrganizationNode>;
  users?: Maybe<Array<Maybe<UserNode>>>;
};

export type TitleDescriptionByIdQueryVariables = Exact<{
  titleDescriptionId: Scalars['Int']['input'];
}>;

export type TitleDescriptionByIdQuery = {
  __typename?: 'Query';
  titleDescription?: {
    __typename?: 'TitleDescriptionNode';
    title?: string | null;
    description?: string | null;
  } | null;
};

export type Top10DomainsQueryVariables = Exact<{
  domainId?: InputMaybe<Scalars['Int']['input']>;
  keywordrankId: Scalars['BigInt']['input'];
  searchDate: Scalars['String']['input'];
  keywordId: Scalars['Int']['input'];
}>;

export type Top10DomainsQuery = {
  __typename?: 'Query';
  top10Domains?: Array<{
    __typename?: 'SimpleUnknownCompetitorRank';
    domain?: string | null;
    url?: string | null;
    ctr?: number | null;
  }> | null;
};

export type ChartNotesQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  fakePagination: PaginationInput;
  fakeOrdering: OrderingInput;
}>;

export type ChartNotesQuery = {
  __typename?: 'Query';
  keywords?: {
    __typename?: 'KeywordListNode';
    notes?: Array<{
      __typename?: 'NoteNode';
      id: string;
      createdAt: string;
      note: string;
      numberOfKeywords: number;
      user?: { __typename?: 'UserNode'; id?: string | null; fullName?: string | null } | null;
    } | null> | null;
  } | null;
};

export type ChartNotesWithKeywordsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  fakePagination: PaginationInput;
  fakeOrdering: OrderingInput;
  keywordId: Scalars['ID']['input'];
}>;

export type ChartNotesWithKeywordsQuery = {
  __typename?: 'Query';
  keywords?: {
    __typename?: 'KeywordListNode';
    notes?: Array<{
      __typename?: 'NoteNode';
      id: string;
      createdAt: string;
      note: string;
      numberOfKeywords: number;
      user?: { __typename?: 'UserNode'; id?: string | null; fullName?: string | null } | null;
      keywords?: Array<{ __typename?: 'KeywordNode'; id: string } | null> | null;
    } | null> | null;
  } | null;
};

export type CountryLocaleCountsQueryVariables = Exact<{ [key: string]: never }>;

export type CountryLocaleCountsQuery = {
  __typename?: 'Query';
  countrylocaleCounts?: Array<{
    __typename?: 'CountryLocaleCountNode';
    defaultCountrylocaleId: number;
    count: number;
  }> | null;
};

export type DomainFoldersQueryVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
}>;

export type DomainFoldersQuery = {
  __typename?: 'Query';
  domainsList?: Array<{
    __typename?: 'DomainNode';
    folders?: Array<string | null> | null;
  } | null> | null;
};

export type DomainTagsQueryVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
}>;

export type DomainTagsQuery = {
  __typename?: 'Query';
  domainsList?: Array<{
    __typename?: 'DomainNode';
    id: string;
    tags?: Array<string | null> | null;
    dynamicTags?: Array<string | null> | null;
  } | null> | null;
};

export type CountrylocaleEditorQueryVariables = Exact<{ [key: string]: never }>;

export type CountrylocaleEditorQuery = {
  __typename?: 'Query';
  countrylocales?: Array<{
    __typename?: 'CountryLocaleNode';
    id: string;
    countryCode: string;
    region: string;
    locale: string;
  } | null> | null;
};

export type CountrylocaleEditorFragment = {
  __typename?: 'CountryLocaleNode';
  id: string;
  countryCode: string;
  region: string;
  locale: string;
};

export type GetCountryLocaleQueryVariables = Exact<{
  ids: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type GetCountryLocaleQuery = {
  __typename?: 'Query';
  countrylocales?: Array<{
    __typename?: 'CountryLocaleNode';
    id: string;
    countryCode: string;
    region: string;
    locale: string;
  } | null> | null;
};

export type DomainsInput_DomainsListQueryVariables = Exact<{ [key: string]: never }>;

export type DomainsInput_DomainsListQuery = {
  __typename?: 'Query';
  domainsList?: Array<{
    __typename?: 'DomainNode';
    id: string;
    domain: string;
    displayName?: string | null;
    youtubeChannelName?: string | null;
  } | null> | null;
};

export type KeywordsInputQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  ordering: OrderingInput;
}>;

export type KeywordsInputQuery = {
  __typename?: 'Query';
  keywords?: {
    __typename?: 'KeywordListNode';
    keywords?: Array<{
      __typename?: 'KeywordNode';
      id: string;
      keyword: string;
      searchType: KeywordsKeywordSearchTypeChoices;
      location?: string | null;
      searchEngine: { __typename?: 'SearchEngineNode'; name: string; id: string };
      countrylocale: {
        __typename?: 'CountryLocaleNode';
        id: string;
        countryCode: string;
        region: string;
      };
    } | null> | null;
  } | null;
};

export type LandingPagesQueryVariables = Exact<{
  ids: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type LandingPagesQuery = {
  __typename?: 'Query';
  landingPages?: Array<{
    __typename?: 'LandingPageNode';
    id: string;
    path?: string | null;
  } | null> | null;
};

export type LandingPagesSearchQueryVariables = Exact<{
  domains: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
  query: Scalars['String']['input'];
}>;

export type LandingPagesSearchQuery = {
  __typename?: 'Query';
  urls?: Array<{ __typename?: 'UrlNode'; id?: string | null; path?: string | null } | null> | null;
};

export type SubDomainsListQueryVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
}>;

export type SubDomainsListQuery = {
  __typename?: 'Query';
  domainsList?: Array<{
    __typename?: 'DomainNode';
    subdomains?: Array<string | null> | null;
  } | null> | null;
};

export type CreateUserFilterMutationVariables = Exact<{
  input: CreateUserFilterInput;
}>;

export type CreateUserFilterMutation = {
  __typename?: 'Mutations';
  createFilter?: {
    __typename?: 'CreateUserFilter';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
    filter?: {
      __typename?: 'UserFilterNode';
      id: string;
      name: string;
      type: UsersUserFilterTypeChoices;
      filters: string;
    } | null;
  } | null;
};

export type UpdateUserFilterMutationVariables = Exact<{
  input: UpdateUserFilterInput;
}>;

export type UpdateUserFilterMutation = {
  __typename?: 'Mutations';
  updateFilter?: {
    __typename?: 'UpdateUserFilter';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
    filter?: {
      __typename?: 'UserFilterNode';
      id: string;
      name: string;
      defaultForDomains: boolean;
      defaultForKeywords?: Array<string | null> | null;
      apiFilter: boolean;
      filters: string;
    } | null;
  } | null;
};

export type DeleteUserFilterMutationVariables = Exact<{
  input: DeleteUserFilterInput;
}>;

export type DeleteUserFilterMutation = {
  __typename?: 'Mutations';
  deleteFilter?: {
    __typename?: 'DeleteUserFilter';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
    filter?: { __typename?: 'UserFilterNode'; id: string } | null;
  } | null;
};

export type FilterCountQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  isKeydis: Scalars['Boolean']['input'];
}>;

export type FilterCountQuery = {
  __typename?: 'Query';
  filterData?: { __typename?: 'FilterDataNode'; filterCount?: number | null } | null;
};

export type FilterOptionsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  isKeydis: Scalars['Boolean']['input'];
  filterType: Scalars['String']['input'];
}>;

export type FilterOptionsQuery = {
  __typename?: 'Query';
  filterData?: {
    __typename?: 'FilterDataNode';
    filterOptions?: Array<{
      __typename?: 'FilterOptionNode';
      option: string;
      count: number;
    }> | null;
  } | null;
};

export type ImpersonateOrganizationsQueryVariables = Exact<{
  searchQuery: Scalars['String']['input'];
}>;

export type ImpersonateOrganizationsQuery = {
  __typename?: 'Query';
  organizations?: Array<{
    __typename?: 'OrganizationNode';
    id: string;
    name: string;
    slug: string;
    active: boolean;
    activePlan?: {
      __typename?: 'OrganizationPlanNode';
      id: string;
      priceMonthly?: number | null;
      currency?: string | null;
    } | null;
  } | null> | null;
};

export type IntercomMultiAccountUserQueryVariables = Exact<{ [key: string]: never }>;

export type IntercomMultiAccountUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    multiaccountOriginUser?: {
      __typename?: 'UserNode';
      id?: string | null;
      isImpersonating?: boolean | null;
      email?: string | null;
      fullName?: string | null;
      intercomHash?: string | null;
      dateJoined?: string | null;
      language?: string | null;
    } | null;
    multiaccountOrganization?: {
      __typename?: 'OrganizationNode';
      id: string;
      name: string;
      dateAdded: string;
      active: boolean;
      type?: string | null;
      isPartner?: boolean | null;
      phoneNumber?: string | null;
      numberOfDomains?: number | null;
      numberOfDomainsWithGa?: number | null;
      numberOfDomainsWithGwt?: number | null;
      numberOfKeywords?: number | null;
      activePlan?: {
        __typename?: 'OrganizationPlanNode';
        category?: string | null;
        priceMonthly?: number | null;
        maxKeywords: number;
        isTrial: boolean;
        endDate: string;
        billingCycleInMonths: number;
      } | null;
    } | null;
  } | null;
};

export type UniqueKeywordsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
}>;

export type UniqueKeywordsQuery = {
  __typename?: 'Query';
  uniqueKeywordsNode?: {
    __typename?: 'UniqueKeywordsNode';
    uniqueKeywords?: Array<string> | null;
  } | null;
};

export type LinkedAccountsOrganizationsQueryVariables = Exact<{
  searchQuery: Scalars['String']['input'];
}>;

export type LinkedAccountsOrganizationsQuery = {
  __typename?: 'Query';
  linkedOrganizations?: Array<{
    __typename?: 'OrganizationNode';
    id: string;
    name: string;
    multiAccountLink?: { __typename?: 'MultiAccountLinkNode'; id: string } | null;
  } | null> | null;
};

export type LinkedOrganizationFragment = {
  __typename?: 'OrganizationNode';
  id: string;
  name: string;
  multiAccountLink?: { __typename?: 'MultiAccountLinkNode'; id: string } | null;
};

export type AddGroupMutationVariables = Exact<{
  input: AddClientInput;
}>;

export type AddGroupMutation = {
  __typename?: 'Mutations';
  addClient?: {
    __typename?: 'AddClient';
    client?: { __typename?: 'ClientNode'; id: string } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type CountryLocalesQueryVariables = Exact<{ [key: string]: never }>;

export type CountryLocalesQuery = {
  __typename?: 'Query';
  countrylocales?: Array<{
    __typename?: 'CountryLocaleNode';
    id: string;
    locale: string;
    region: string;
    countryCode: string;
    localeShort: string;
    googleSupport: boolean;
    bingSupport: boolean;
    baiduSupport: boolean;
    youtubeSupport: boolean;
    naverSupport: boolean;
  } | null> | null;
};

export type DomainInfoAddKeywordsQueryVariables = Exact<{
  domainId: Scalars['ID']['input'];
}>;

export type DomainInfoAddKeywordsQuery = {
  __typename?: 'Query';
  domain?: {
    __typename?: 'DomainNode';
    id: string;
    domainType: DomainsDomainDomainTypeChoices;
    displayName?: string | null;
    domain: string;
    defaultSearchSettings?: Array<{
      __typename?: 'DefaultSearchSettingNode';
      id: string;
      locations?: Array<string> | null;
      ignoreLocalResults: boolean;
      ignoreFeaturedSnippet: boolean;
      enableAutocorrect: boolean;
      countrylocale: { __typename?: 'CountryLocaleNode'; id: string; region: string };
      searchEngines?: Array<{
        __typename?: 'DefaultSearchSettingSearchEngineNode';
        id: string;
        searchTypes: Array<number>;
        searchEngine: { __typename?: 'SearchEngineNode'; id: string; name: string };
      } | null> | null;
    } | null> | null;
    googleOauthConnectionGsc?: {
      __typename?: 'GoogleOAuthConnectionNode';
      id?: string | null;
    } | null;
  } | null;
};

export type ImportKeydisAddKeywordsMutationVariables = Exact<{
  input: ImportKeydisKeywordsInput;
}>;

export type ImportKeydisAddKeywordsMutation = {
  __typename?: 'Mutations';
  importKeydisKeywords?: {
    __typename?: 'ImportKeydisKeywords';
    code?: KeywordOveruseCode | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type AddKeywordsMutationVariables = Exact<{
  input: AddKeywordsInputNew;
}>;

export type AddKeywordsMutation = {
  __typename?: 'Mutations';
  addKeywordsNew?: {
    __typename?: 'AddKeywordsNew';
    code?: KeywordOveruseCode | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type AddKeywordsFormKeywordOveruseEnabledQueryVariables = Exact<{ [key: string]: never }>;

export type AddKeywordsFormKeywordOveruseEnabledQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      keywordOveruseEnabled: boolean;
    } | null;
  } | null;
};

export type AddKeywordsFormSimpleLogMutationVariables = Exact<{
  input: SimpleLogInput;
}>;

export type AddKeywordsFormSimpleLogMutation = {
  __typename?: 'Mutations';
  simpleLog?: { __typename?: 'SimpleLog'; success?: boolean | null } | null;
};

export type AddKeywordsFormAccountUsageUserQueryVariables = Exact<{ [key: string]: never }>;

export type AddKeywordsFormAccountUsageUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      numberOfKeywords?: number | null;
    } | null;
  } | null;
};

export type AddUserFormInviteUserMutationVariables = Exact<{
  inviteUserInput: InviteUserInput;
}>;

export type AddUserFormInviteUserMutation = {
  __typename?: 'Mutations';
  inviteUser?: {
    __typename?: 'InviteUser';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type AddWalletItemMutationVariables = Exact<{
  input: CreateWalletInput;
}>;

export type AddWalletItemMutation = {
  __typename?: 'Mutations';
  createWallet?: {
    __typename?: 'CreateWallet';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type CreateWorkspaceMutationVariables = Exact<{
  roleInput: CreateWorkspaceInput;
}>;

export type CreateWorkspaceMutation = {
  __typename?: 'Mutations';
  createWorkspace?: {
    __typename?: 'CreateWorkspace';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type AffiliateInfoQueryVariables = Exact<{ [key: string]: never }>;

export type AffiliateInfoQuery = {
  __typename?: 'Query';
  affiliate?: {
    __typename?: 'AffiliateVisitorsNode';
    details?: {
      __typename?: 'RevShareAffiliateNode';
      commission: number;
      totalCommission?: number | null;
      totalUnpaidCommission?: number | null;
      totalPaidCommission?: number | null;
      affiliateId: string;
      paypalEmail?: string | null;
      minimumPayout: number;
    } | null;
  } | null;
};

export type AddCompetitorMutationVariables = Exact<{
  addCompetitorInput: AddCompetitorInput;
}>;

export type AddCompetitorMutation = {
  __typename?: 'Mutations';
  addCompetitor?: {
    __typename?: 'AddCompetitor';
    competitor?: { __typename?: 'CompetitorNode'; id: string } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type UpdateCompetitorMutationVariables = Exact<{
  updateCompetitorInput: UpdateCompetitorInput;
}>;

export type UpdateCompetitorMutation = {
  __typename?: 'Mutations';
  updateCompetitor?: {
    __typename?: 'UpdateCompetitor';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type GetCompetitorDataQueryVariables = Exact<{
  domainId: Scalars['ID']['input'];
  competitorId: Scalars['ID']['input'];
}>;

export type GetCompetitorDataQuery = {
  __typename?: 'Query';
  domain?: {
    __typename?: 'DomainNode';
    id: string;
    competitor?: {
      __typename?: 'CompetitorNode';
      id: string;
      domain?: string | null;
      displayName?: string | null;
      googleBusinessNameList?: Array<string | null> | null;
      twitterHandle?: string | null;
      facebookProfile?: string | null;
      tiktokProfile?: string | null;
      instagramProfile?: string | null;
      linkedinProfile?: string | null;
    } | null;
  } | null;
};

export type AddDomainForm_ClientsQueryVariables = Exact<{ [key: string]: never }>;

export type AddDomainForm_ClientsQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'ClientNode';
    id: string;
    name: string;
    isDemoClient?: boolean | null;
  }>;
};

export type AddDomainForm_CountrylocalesQueryVariables = Exact<{ [key: string]: never }>;

export type AddDomainForm_CountrylocalesQuery = {
  __typename?: 'Query';
  countrylocales?: Array<{
    __typename?: 'CountryLocaleNode';
    id: string;
    locale: string;
    region: string;
    countryCode: string;
    localeShort: string;
    googleSupport: boolean;
    bingSupport: boolean;
    yandexSupport: boolean;
    baiduSupport: boolean;
    youtubeSupport: boolean;
    naverSupport: boolean;
  } | null> | null;
};

export type AddDomainForm_AddDomainMutationVariables = Exact<{
  addDomainInput: AddDomainInput;
}>;

export type AddDomainForm_AddDomainMutation = {
  __typename?: 'Mutations';
  addDomain?: {
    __typename?: 'AddDomain';
    domain?: { __typename?: 'DomainNode'; id: string } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type GetDomainInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetDomainInfoQuery = {
  __typename?: 'Query';
  domain?: {
    __typename?: 'DomainNode';
    id: string;
    domain: string;
    isDemoDomain: boolean;
    pausedKeywordRate: number;
    totalKeywords?: number | null;
    includeSubdomains: boolean;
    exactMatch: boolean;
    displayName?: string | null;
    googleBusinessNameList?: Array<string | null> | null;
    twitterHandle?: string | null;
    facebookProfile?: string | null;
    tiktokProfile?: string | null;
    instagramProfile?: string | null;
    linkedinProfile?: string | null;
    shareOfVoicePercentage: boolean;
    paused: boolean;
    gaPropertyId?: string | null;
    gaAccountId?: string | null;
    defaultCountrylocale?: { __typename?: 'CountryLocaleNode'; id: string } | null;
    defaultSearchSettings?: Array<{
      __typename?: 'DefaultSearchSettingNode';
      id: string;
      locations?: Array<string> | null;
      ignoreLocalResults: boolean;
      ignoreFeaturedSnippet: boolean;
      enableAutocorrect: boolean;
      primary: boolean;
      countrylocale: { __typename?: 'CountryLocaleNode'; id: string };
      searchEngines?: Array<{
        __typename?: 'DefaultSearchSettingSearchEngineNode';
        id: string;
        searchTypes: Array<number>;
        searchEngine: { __typename?: 'SearchEngineNode'; id: string; name: string };
      } | null> | null;
    } | null> | null;
    client: { __typename?: 'ClientNode'; id: string };
    googleOauthConnectionGa?: {
      __typename?: 'GoogleOAuthConnectionNode';
      id?: string | null;
    } | null;
    googleOauthConnectionGsc?: {
      __typename?: 'GoogleOAuthConnectionNode';
      id?: string | null;
    } | null;
    adobeMarketingConnection?: { __typename?: 'AdobeMarketingConnectionNode'; id: string } | null;
  } | null;
};

export type RemoveAdobeAnalyticsAccountMutationVariables = Exact<{
  input: RemoveAdobeMarketingAccountInput;
}>;

export type RemoveAdobeAnalyticsAccountMutation = {
  __typename?: 'Mutations';
  removeAdobeMarketingAccount?: {
    __typename?: 'RemoveAdobeMarketingAccount';
    domain?: { __typename?: 'DomainNode'; id: string } | null;
  } | null;
};

export type RemoveGoogleAnalyticsAccountMutationVariables = Exact<{
  input: RemoveGoogleAnalyticsAccountInput;
}>;

export type RemoveGoogleAnalyticsAccountMutation = {
  __typename?: 'Mutations';
  removeGoogleAnalyticsAccount?: {
    __typename?: 'RemoveGoogleAnalyticsAccount';
    domain?: { __typename?: 'DomainNode'; id: string } | null;
  } | null;
};

export type RemoveGoogleSearchConsoleAccountMutationVariables = Exact<{
  input: RemoveGoogleSearchConsoleAccountInput;
}>;

export type RemoveGoogleSearchConsoleAccountMutation = {
  __typename?: 'Mutations';
  removeGoogleSearchConsoleAccount?: {
    __typename?: 'RemoveGoogleSearchConsoleAccount';
    domain?: { __typename?: 'DomainNode'; id: string } | null;
  } | null;
};

export type BuildDomainUpdateDomainMutationVariables = Exact<{
  updateDomainInput: UpdateDomainInput;
}>;

export type BuildDomainUpdateDomainMutation = {
  __typename?: 'Mutations';
  updateDomain?: {
    __typename?: 'UpdateDomain';
    domain?: {
      __typename?: 'DomainNode';
      id: string;
      shareOfVoicePercentage: boolean;
      defaultSearchSettings?: Array<{
        __typename?: 'DefaultSearchSettingNode';
        id: string;
        locations?: Array<string> | null;
        ignoreLocalResults: boolean;
        ignoreFeaturedSnippet: boolean;
        enableAutocorrect: boolean;
        countrylocale: { __typename?: 'CountryLocaleNode'; id: string };
        searchEngines?: Array<{
          __typename?: 'DefaultSearchSettingSearchEngineNode';
          id: string;
          searchTypes: Array<number>;
          searchEngine: { __typename?: 'SearchEngineNode'; id: string; name: string };
        } | null> | null;
      } | null> | null;
    } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type CompetitorsReportForm_CreateReportMutationMutationVariables = Exact<{
  input: ScheduleCompetitorsReportInput;
}>;

export type CompetitorsReportForm_CreateReportMutationMutation = {
  __typename?: 'Mutations';
  scheduleCompetitorsReport?: {
    __typename?: 'ScheduleCompetitorsReport';
    scheduledReport?: { __typename?: 'ScheduledReportNode'; id: string } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type UpdateStaticTagMutationVariables = Exact<{
  input: UpdateStaticTagInput;
}>;

export type UpdateStaticTagMutation = {
  __typename?: 'Mutations';
  updateStaticTag?: {
    __typename?: 'UpdateStaticTag';
    errors: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null>;
  } | null;
};

export type EditGroupMutationVariables = Exact<{
  input: UpdateClientInput;
}>;

export type EditGroupMutation = {
  __typename?: 'Mutations';
  updateClient?: {
    __typename?: 'UpdateClient';
    client?: { __typename?: 'ClientNode'; id: string; name: string } | null;
  } | null;
};

export type TopCompetitorsForKeywordsQueryVariables = Exact<{
  keywordIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  domainId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type TopCompetitorsForKeywordsQuery = {
  __typename?: 'Query';
  topCompetitorsForKeywords?: Array<{
    __typename?: 'TopCompetitorsForKeyword';
    domain?: string | null;
    score?: number | null;
  }> | null;
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetUserByIdQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    fullName?: string | null;
    email?: string | null;
    userType?: number | null;
    workspaces?: Array<{ __typename?: 'WorkspaceNode'; id: string } | null> | null;
  } | null;
};

export type EditUserForm_EditUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;

export type EditUserForm_EditUserMutation = {
  __typename?: 'Mutations';
  updateUser?: {
    __typename?: 'UpdateUser';
    user?: { __typename?: 'UserNode'; id?: string | null } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type GetWorkspaceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetWorkspaceQuery = {
  __typename?: 'Query';
  workspace?: {
    __typename?: 'WorkspaceNode';
    id: string;
    name: string;
    description?: string | null;
    lastEdited?: string | null;
    clients?: Array<{ __typename?: 'ClientNode'; id: string; name: string } | null> | null;
    users?: Array<{
      __typename?: 'UserNode';
      id?: string | null;
      fullName?: string | null;
    } | null> | null;
  } | null;
};

export type EditWorkspaceMutationVariables = Exact<{
  roleInput: UpdateWorkspaceInput;
}>;

export type EditWorkspaceMutation = {
  __typename?: 'Mutations';
  updateWorkspace?: {
    __typename?: 'UpdateWorkspace';
    workspace?: {
      __typename?: 'WorkspaceNode';
      id: string;
      name: string;
      description?: string | null;
      organization?: { __typename?: 'OrganizationNode'; id: string } | null;
      clients?: Array<{ __typename?: 'ClientNode'; name: string; id: string } | null> | null;
    } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type DownloadFolderReportMutationVariables = Exact<{
  input: ScheduleFoldersReportInput;
}>;

export type DownloadFolderReportMutation = {
  __typename?: 'Mutations';
  scheduleFoldersReport?: {
    __typename?: 'ScheduleFoldersReport';
    scheduledReport?: { __typename?: 'ScheduledReportNode'; id: string } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type KeywordHistoryChartRanksQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  keywordId: Scalars['ID']['input'];
}>;

export type KeywordHistoryChartRanksQuery = {
  __typename?: 'Query';
  graphs: { __typename?: 'GraphsNode'; keywordHistory?: any | null };
};

export type KeywordHistoryCompetitorsRanksChartQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  keywordId: Scalars['ID']['input'];
}>;

export type KeywordHistoryCompetitorsRanksChartQuery = {
  __typename?: 'Query';
  graphs: { __typename?: 'GraphsNode'; keywordCompetitorHistory?: any | null };
};

export type KeywordHistoryTableQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  keywordId: Scalars['ID']['input'];
  pagination: PaginationInput;
  ordering: OrderingInput;
}>;

export type KeywordHistoryTableQuery = {
  __typename?: 'Query';
  keywordsTableHistory?: {
    __typename?: 'TableKeywordHistoryListNode';
    history?: any | null;
    pagination?: { __typename?: 'Pagination'; numResults?: number | null } | null;
  } | null;
};

export type GoogleTrendsChartQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  keywordId: Scalars['ID']['input'];
}>;

export type GoogleTrendsChartQuery = {
  __typename?: 'Query';
  graphs: { __typename?: 'GraphsNode'; googleTrendsHistory?: any | null };
};

export type TopCompetitorsChartQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  keywordId: Scalars['ID']['input'];
}>;

export type TopCompetitorsChartQuery = {
  __typename?: 'Query';
  graphs: {
    __typename?: 'GraphsNode';
    unknowncompetitorHistory?: Array<{
      __typename?: 'UnkowncompetitorHistoryGraphNode';
      domain?: string | null;
      rank?: number | null;
      ownDomain?: boolean | null;
      searchDate?: string | null;
      youtubeChannelName?: string | null;
    } | null> | null;
  };
};

export type KeywordInfoQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
  keywordId: Scalars['ID']['input'];
}>;

export type KeywordInfoQuery = {
  __typename?: 'Query';
  keywords?: {
    __typename?: 'KeywordListNode';
    keyword?: {
      __typename?: 'KeywordNode';
      id: string;
      keyword: string;
      searchType: KeywordsKeywordSearchTypeChoices;
      location?: string | null;
      searchEngine: { __typename?: 'SearchEngineNode'; name: string; id: string };
      countrylocale: {
        __typename?: 'CountryLocaleNode';
        id: string;
        countryCode: string;
        region: string;
      };
      domain: { __typename?: 'DomainNode'; id: string; domain: string };
    } | null;
  } | null;
};

export type KeywordCompareChartQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  keywordIds:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type KeywordCompareChartQuery = {
  __typename?: 'Query';
  graphs: { __typename?: 'GraphsNode'; compareKeywordsChart?: any | null };
};

export type MessagesReadQueryVariables = Exact<{
  startIdx: Scalars['Int']['input'];
  stopIdx: Scalars['Int']['input'];
}>;

export type MessagesReadQuery = {
  __typename?: 'Query';
  messages: Array<{
    __typename?: 'MessageNode';
    id: string;
    text?: string | null;
    level?: string | null;
    levelId?: number | null;
    createdAt: string;
  }>;
};

export type MessagesReadCountQueryVariables = Exact<{ [key: string]: never }>;

export type MessagesReadCountQuery = { __typename?: 'Query'; totalMessagesCount: number };

export type MessagesDropdownMessagesQueryVariables = Exact<{
  startIdx: Scalars['Int']['input'];
  stopIdx: Scalars['Int']['input'];
}>;

export type MessagesDropdownMessagesQuery = {
  __typename?: 'Query';
  messages: Array<{
    __typename?: 'MessageNode';
    id: string;
    text?: string | null;
    level?: string | null;
    levelId?: number | null;
    createdAt: string;
  }>;
};

export type MessagesDropdownTotalMessagesCountQueryVariables = Exact<{ [key: string]: never }>;

export type MessagesDropdownTotalMessagesCountQuery = {
  __typename?: 'Query';
  totalMessagesCount: number;
};

export type MessagesDropdownUpdateReadMutationVariables = Exact<{
  input: UpdateReadInput;
}>;

export type MessagesDropdownUpdateReadMutation = {
  __typename?: 'Mutations';
  updateRead?: {
    __typename?: 'UpdateRead';
    errors?: Array<{ __typename?: 'Error'; field: string } | null> | null;
  } | null;
};

export type MessagesDropdownUpdateAllReadMutationVariables = Exact<{
  read: Scalars['Boolean']['input'];
}>;

export type MessagesDropdownUpdateAllReadMutation = {
  __typename?: 'Mutations';
  updateAllRead?: {
    __typename?: 'UpdateAllRead';
    errors?: Array<{ __typename?: 'Error'; field: string } | null> | null;
  } | null;
};

export type MoveKeywordsFormGroupsAndDomainsQueryVariables = Exact<{ [key: string]: never }>;

export type MoveKeywordsFormGroupsAndDomainsQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'ClientNode';
    name: string;
    domains?: Array<{
      __typename?: 'DomainNode';
      id: string;
      domain: string;
      displayName?: string | null;
    } | null> | null;
  }>;
};

export type SalesCreateInvoiceQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type SalesCreateInvoiceQuery = {
  __typename?: 'Query';
  adminPublicPlans?: Array<{
    __typename?: 'PricingPlanNode';
    id: string;
    name: string;
    priceMonthlyUsd: string;
    priceYearlyUsd: string;
    priceMonthlyEur?: string | null;
    priceYearlyEur?: string | null;
    priceMonthlyDkk?: string | null;
    priceYearlyDkk?: string | null;
    maxKeywords: number;
  } | null> | null;
  billingCycles?: Array<{
    __typename?: 'BillingCycleNode';
    id: string;
    months: number;
  } | null> | null;
  adminOrganization?: {
    __typename?: 'OrganizationNode';
    id: string;
    name: string;
    paymentcontact?: {
      __typename?: 'PaymentContactNode';
      id: string;
      currency: BillingPaymentContactCurrencyChoices;
      paymentMethod?: BillingPaymentContactPaymentMethodChoices | null;
      paymentTerms?: number | null;
    } | null;
    activePlan?: {
      __typename?: 'OrganizationPlanNode';
      id: string;
      isFree: boolean;
      isTrial: boolean;
      active: boolean;
      priceMonthlyUsd: string;
    } | null;
  } | null;
};

export type CreateInvoiceMutationMutationVariables = Exact<{
  input: CreateInvoiceInput;
}>;

export type CreateInvoiceMutationMutation = {
  __typename?: 'Mutations';
  createInvoice?: {
    __typename?: 'CreateInvoice';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type LockOrganizationMutationVariables = Exact<{
  input: LockOrganizationInput;
}>;

export type LockOrganizationMutation = {
  __typename?: 'Mutations';
  lockOrganization?: {
    __typename?: 'LockOrganization';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type OrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OrganizationQuery = {
  __typename?: 'Query';
  adminOrganization?: {
    __typename?: 'OrganizationNode';
    id: string;
    organizationClients?: Array<{
      __typename?: 'ClientNode';
      id: string;
      name: string;
      domains?: Array<{
        __typename?: 'DomainNode';
        id: string;
        domain: string;
        displayName?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type EditOrganizationNoteMutationVariables = Exact<{
  input: OrganizationNoteEditInput;
}>;

export type EditOrganizationNoteMutation = {
  __typename?: 'Mutations';
  editOrganizationNote?: {
    __typename?: 'EditOrganizationNote';
    note?: { __typename?: 'OrganizationNoteNode'; message: string } | null;
  } | null;
};

export type AddOrganizationNoteMutationVariables = Exact<{
  input: OrganizationNoteAddInput;
}>;

export type AddOrganizationNoteMutation = {
  __typename?: 'Mutations';
  addOrganizationNote?: {
    __typename?: 'AddOrganizationNote';
    note?: { __typename?: 'OrganizationNoteNode'; message: string } | null;
  } | null;
};

export type DeleteOrganizationNoteMutationVariables = Exact<{
  input: OrganizationNoteDeleteInput;
}>;

export type DeleteOrganizationNoteMutation = {
  __typename?: 'Mutations';
  deleteOrganizationNote?: {
    __typename?: 'DeleteOrganizationNote';
    errors?: Array<{ __typename?: 'Error'; messages: Array<string> } | null> | null;
  } | null;
};

export type SalesOrganizationPlanQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SalesOrganizationPlanQuery = {
  __typename?: 'Query';
  adminOrganizationPlan?: {
    __typename?: 'OrganizationPlanNode';
    id: string;
    maxKeywords: number;
    maxCompetitors: number;
    maxNumbersOfWorkspaces: number;
    featureAdvancedMetrics: boolean;
    featureApiAccess: boolean;
    featureApiV4WriteAccess?: boolean | null;
  } | null;
};

export type UpdateSalesOrganizationPlanMutationVariables = Exact<{
  input: UpdateOrganizationPlanInput;
}>;

export type UpdateSalesOrganizationPlanMutation = {
  __typename?: 'Mutations';
  updateOrganizationPlan?: {
    __typename?: 'UpdateOrganizationPlan';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type AffiliateGetMoneyOwedQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;

export type AffiliateGetMoneyOwedQuery = {
  __typename?: 'Query';
  affiliateMoneyOwed?: {
    __typename?: 'AffiliateMoneyOwedNode';
    minimumPayout?: number | null;
    owedUsd?: number | null;
    owedEur?: number | null;
    owedDkk?: number | null;
    owedTotalUsd?: number | null;
  } | null;
};

export type PayAffiliateMutationVariables = Exact<{
  input: PayAffiliateInput;
}>;

export type PayAffiliateMutation = {
  __typename?: 'Mutations';
  payAffiliate?: {
    __typename?: 'PayAffiliate';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type RefundInvoiceMutationVariables = Exact<{
  input: RefundInput;
}>;

export type RefundInvoiceMutation = {
  __typename?: 'Mutations';
  refund?: {
    __typename?: 'Refund';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type Users_AdminUsersQueryVariables = Exact<{ [key: string]: never }>;

export type Users_AdminUsersQuery = {
  __typename?: 'Query';
  user?: {
    __typename: 'UserNode';
    id?: string | null;
    fullName?: string | null;
    email?: string | null;
    userType?: number | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      activePlan?: { __typename?: 'OrganizationPlanNode'; id: string; maxUsers: number } | null;
    } | null;
  } | null;
  users?: Array<{
    __typename: 'UserNode';
    id?: string | null;
    fullName?: string | null;
    email?: string | null;
    userType?: number | null;
  } | null> | null;
};

export type Take_ControlQueryVariables = Exact<{ [key: string]: never }>;

export type Take_ControlQuery = { __typename?: 'Query'; takeControl?: boolean | null };

export type Active_SessionsQueryVariables = Exact<{ [key: string]: never }>;

export type Active_SessionsQuery = { __typename?: 'Query'; activeSessions?: number | null };

export type ModalServiceMessagesQueryVariables = Exact<{ [key: string]: never }>;

export type ModalServiceMessagesQuery = {
  __typename?: 'Query';
  serviceMessages: Array<{
    __typename?: 'ServiceMessageNode';
    id: string;
    title?: string | null;
    message?: string | null;
    level?: number | null;
    createdAt: string;
  }>;
};

export type CountrylocalesQueryVariables = Exact<{ [key: string]: never }>;

export type CountrylocalesQuery = {
  __typename?: 'Query';
  countrylocales?: Array<{
    __typename?: 'CountryLocaleNode';
    id: string;
    countryCode: string;
    localeShort: string;
    region: string;
    locale: string;
    yandexSupport: boolean;
    googleSupport: boolean;
    bingSupport: boolean;
    baiduSupport: boolean;
    youtubeSupport: boolean;
  } | null> | null;
};

export type CountrylocaleFragment = {
  __typename?: 'CountryLocaleNode';
  id: string;
  countryCode: string;
  localeShort: string;
  region: string;
  locale: string;
  yandexSupport: boolean;
  googleSupport: boolean;
  bingSupport: boolean;
  baiduSupport: boolean;
  youtubeSupport: boolean;
};

export type GoaAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GoaAccountsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      googleConnections?: Array<{
        __typename?: 'GoogleOAuthConnectionNode';
        id?: string | null;
        type?: string | null;
        description?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type AddGoogleAnalyticsAccountMutationVariables = Exact<{
  addGoogleAnalyticsAccountInput: AddGoogleAnalyticsAccountInput;
}>;

export type AddGoogleAnalyticsAccountMutation = {
  __typename?: 'Mutations';
  addGoogleAnalyticsAccount?: {
    __typename?: 'AddGoogleAnalyticsAccount';
    domain?: { __typename?: 'DomainNode'; id: string } | null;
  } | null;
};

export type ConnectToGaPropertyUaPropertiesQueryVariables = Exact<{
  connectionId: Scalars['ID']['input'];
  accountId: Scalars['String']['input'];
}>;

export type ConnectToGaPropertyUaPropertiesQuery = {
  __typename?: 'Query';
  googleConnection?: {
    __typename?: 'GoogleOAuthConnectionNode';
    gaProperties?: Array<{
      __typename?: 'GoogleOAuthConnectionGaProperty';
      id: string;
      name: string;
      websiteUrl: string;
    }> | null;
  } | null;
};

export type ConnectToGaPropertyGa4PropertiesQueryVariables = Exact<{
  connectionId: Scalars['ID']['input'];
  accountId: Scalars['String']['input'];
}>;

export type ConnectToGaPropertyGa4PropertiesQuery = {
  __typename?: 'Query';
  googleConnection?: {
    __typename?: 'GoogleOAuthConnectionNode';
    ga4Properties?: Array<{
      __typename?: 'Ga4PropertyNode';
      name?: string | null;
      displayName?: string | null;
    } | null> | null;
  } | null;
};

export type Ga_Connect_DomainsListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onlyNotConnectedToGa?: InputMaybe<Scalars['Boolean']['input']>;
  onlyNotConnectedToGsc?: InputMaybe<Scalars['Boolean']['input']>;
  clientId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type Ga_Connect_DomainsListQuery = {
  __typename?: 'Query';
  domainsList?: Array<{
    __typename?: 'DomainNode';
    id: string;
    domain: string;
    displayName?: string | null;
    youtubeChannelName?: string | null;
    client: { __typename?: 'ClientNode'; id: string; numberOfDomains?: number | null };
    googleOauthConnectionGsc?: {
      __typename?: 'GoogleOAuthConnectionNode';
      id?: string | null;
    } | null;
  } | null> | null;
};

export type ConnectToGa_DomainQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ConnectToGa_DomainQuery = {
  __typename?: 'Query';
  domain?: {
    __typename?: 'DomainNode';
    id: string;
    googleOauthConnectionGa?: {
      __typename?: 'GoogleOAuthConnectionNode';
      id?: string | null;
    } | null;
  } | null;
};

export type Gsc_WebsitesQueryVariables = Exact<{
  goaAccountID: Scalars['ID']['input'];
}>;

export type Gsc_WebsitesQuery = {
  __typename?: 'Query';
  googleConnection?: {
    __typename?: 'GoogleOAuthConnectionNode';
    gscSites?: Array<{
      __typename?: 'GoogleOAuthConnectionGsc';
      siteUrl?: string | null;
    } | null> | null;
  } | null;
};

export type Add_Google_Search_Console_AccountMutationVariables = Exact<{
  addGoogleSearchConsoleAccountInput: AddGoogleSearchConsoleAccountInput;
}>;

export type Add_Google_Search_Console_AccountMutation = {
  __typename?: 'Mutations';
  addGoogleSearchConsoleAccount?: {
    __typename?: 'AddGoogleSearchConsoleAccount';
    domain?: { __typename?: 'DomainNode'; id: string } | null;
  } | null;
};

export type AddGscAccountMutationVariables = Exact<{
  addGoogleSearchConsoleAccountInput: AddGoogleSearchConsoleAccountInput;
}>;

export type AddGscAccountMutation = {
  __typename?: 'Mutations';
  addGoogleSearchConsoleAccount?: {
    __typename?: 'AddGoogleSearchConsoleAccount';
    domain?: { __typename?: 'DomainNode'; id: string } | null;
  } | null;
};

export type GscWebsitePage_GscWebsitesQueryVariables = Exact<{
  goaAccountID: Scalars['ID']['input'];
}>;

export type GscWebsitePage_GscWebsitesQuery = {
  __typename?: 'Query';
  googleConnection?: {
    __typename?: 'GoogleOAuthConnectionNode';
    gscSites?: Array<{
      __typename?: 'GoogleOAuthConnectionGsc';
      siteUrl?: string | null;
    } | null> | null;
  } | null;
};

export type GetAffiliateLinkDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAffiliateLinkDetailsQuery = {
  __typename?: 'Query';
  affiliate?: {
    __typename?: 'AffiliateVisitorsNode';
    details?: { __typename?: 'RevShareAffiliateNode'; affiliateId: string } | null;
  } | null;
};

export type EnableOveruseMutationVariables = Exact<{
  input: EnableOveruseInput;
}>;

export type EnableOveruseMutation = {
  __typename?: 'Mutations';
  enableOveruse?: {
    __typename?: 'EnableOveruse';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type LatestCompletedScrapeDateQueryVariables = Exact<{ [key: string]: never }>;

export type LatestCompletedScrapeDateQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'ClientNode';
    id: string;
    latestCompletedScrapeDate?: string | null;
    domains?: Array<{ __typename?: 'DomainNode'; id: string } | null> | null;
  }>;
};

export type QuickNavigationDataQueryVariables = Exact<{ [key: string]: never }>;

export type QuickNavigationDataQuery = {
  __typename?: 'Query';
  user?: { __typename?: 'UserNode'; quickNavigationData?: any | null } | null;
};

export type RefreshKeywordsMutationVariables = Exact<{
  domainId: Scalars['ID']['input'];
  refreshTaskId: Scalars['String']['input'];
}>;

export type RefreshKeywordsMutation = {
  __typename?: 'Mutations';
  scrapeDomain?: {
    __typename?: 'ScrapeDomain';
    queued?: boolean | null;
    reason?: string | null;
    domain?: { __typename?: 'DomainNode'; id: string } | null;
  } | null;
};

export type MaxKeywordsQueryVariables = Exact<{ [key: string]: never }>;

export type MaxKeywordsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      activePlan?: {
        __typename?: 'OrganizationPlanNode';
        actualMaxKeywords?: number | null;
      } | null;
    } | null;
  } | null;
};

export type DomainUnrankedWeightQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DomainUnrankedWeightQuery = {
  __typename?: 'Query';
  domain?: { __typename?: 'DomainNode'; unrankedWeight?: number | null } | null;
};

export type OrganizationUnrankedWeightQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OrganizationUnrankedWeightQuery = {
  __typename?: 'Query';
  organization?: { __typename?: 'OrganizationNode'; unrankedWeight?: number | null } | null;
};

export type AvailableDisplayCurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableDisplayCurrenciesQuery = {
  __typename?: 'Query';
  exchangeRate?: {
    __typename?: 'ExchangeRateNode';
    availableDisplayCurrencies?: Array<string | null> | null;
  } | null;
};

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;

export type UpdateOrganizationMutation = {
  __typename?: 'Mutations';
  updateOrganization?: {
    __typename?: 'UpdateOrganization';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type GetOrganizationLogoQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganizationLogoQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    organization?: { __typename?: 'OrganizationNode'; logo?: string | null } | null;
  } | null;
};

export type PendingRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type PendingRequestsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      pendingMultiAccounts?: Array<{
        __typename?: 'MultiAccountLinkNode';
        id: string;
        toEmail?: string | null;
        isOrgAdmin: boolean;
        createdAt: string;
      } | null> | null;
    } | null;
  } | null;
};

export type PendingRequestFragment = {
  __typename?: 'MultiAccountLinkNode';
  id: string;
  toEmail?: string | null;
  isOrgAdmin: boolean;
  createdAt: string;
};

export type RemoveAccessRequestMutationVariables = Exact<{
  removeAccessRequestInput: RemoveMultiAccountRequestInput;
}>;

export type RemoveAccessRequestMutation = {
  __typename?: 'Mutations';
  removeMultiAccountRequest?: {
    __typename?: 'RemoveMultiAccountRequest';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type SubAccountsInformationForm_UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;

export type SubAccountsInformationForm_UpdateOrganizationMutation = {
  __typename?: 'Mutations';
  updateOrganization?: {
    __typename?: 'UpdateOrganization';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type SubAccountsTableRemoveAccessMutationVariables = Exact<{
  removeAccessInput: RemoveMultiAccountInput;
}>;

export type SubAccountsTableRemoveAccessMutation = {
  __typename?: 'Mutations';
  removeMultiAccount?: {
    __typename?: 'RemoveMultiAccount';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type SubAccountsTableAvailableAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type SubAccountsTableAvailableAccountsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      multiAccounts?: Array<{
        __typename?: 'MultiAccountLinkNode';
        id: string;
        isOrgAdmin: boolean;
        fromOrganizationPays: boolean;
        toOrganization?: {
          __typename?: 'OrganizationNode';
          id: string;
          name: string;
          activePlan?: { __typename?: 'OrganizationPlanNode'; endDate: string } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type TransferMultiAccountDomainFormMultiAccountsDomainsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TransferMultiAccountDomainFormMultiAccountsDomainsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      multiAccounts?: Array<{
        __typename?: 'MultiAccountLinkNode';
        id: string;
        toOrganization?: {
          __typename?: 'OrganizationNode';
          id: string;
          name: string;
          clients?: Array<{
            __typename?: 'ClientNode';
            id: string;
            name: string;
            domains?: Array<{
              __typename?: 'DomainNode';
              id: string;
              domain: string;
              displayName?: string | null;
            } | null> | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type TransferMultiAccountDomainFormDomainsQueryVariables = Exact<{ [key: string]: never }>;

export type TransferMultiAccountDomainFormDomainsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      name: string;
      clients?: Array<{
        __typename?: 'ClientNode';
        id: string;
        name: string;
        domains?: Array<{
          __typename?: 'DomainNode';
          id: string;
          domain: string;
          displayName?: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
  } | null;
};

export type TransferMultiAccountDomainMutationVariables = Exact<{
  input: TransferMultiAccountDomainInput;
}>;

export type TransferMultiAccountDomainMutation = {
  __typename?: 'Mutations';
  transferMultiAccountDomain?: {
    __typename?: 'TransferMultiAccountDomain';
    code?: KeywordOveruseCode | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type AccuApiSearchHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type AccuApiSearchHistoryQuery = {
  __typename?: 'Query';
  accuApiSearchHistory?: { __typename?: 'AccuApiSearchHistoryNode'; history?: any | null } | null;
};

export type AccuApiSpendingQueryVariables = Exact<{
  startDate: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
}>;

export type AccuApiSpendingQuery = {
  __typename?: 'Query';
  accuApiSpendingGraph?: { __typename?: 'AccuApiSpendingGraphNode'; hours?: any | null } | null;
};

export type AccuApiCreditsQueryVariables = Exact<{ [key: string]: never }>;

export type AccuApiCreditsQuery = {
  __typename?: 'Query';
  accuApiCredits?: {
    __typename?: 'AccuApiCreditNode';
    creditsStats?: {
      __typename?: 'AccuApiCreditStatNode';
      planCredits?: number | null;
      credits?: number | null;
      getLimit?: number | null;
      postLimit?: number | null;
      getLimitRemaning?: number | null;
      postLimitRemaning?: number | null;
    } | null;
  } | null;
};

export type AccuApiTokenQueryVariables = Exact<{ [key: string]: never }>;

export type AccuApiTokenQuery = {
  __typename?: 'Query';
  accuApiToken?: { __typename?: 'AccuApiTokenNode'; token?: string | null } | null;
};

export type AffiliateCommissionsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
}>;

export type AffiliateCommissionsQuery = {
  __typename?: 'Query';
  affiliate?: {
    __typename?: 'AffiliateVisitorsNode';
    payments?: Array<{
      __typename?: 'VisitorPaymentNode';
      id: string;
      createdAt: string;
      amount: string;
      currency: string;
      paid: boolean;
      payment: {
        __typename?: 'PaymentNode';
        paymentType?: BillingPaymentPaymentTypeChoices | null;
      };
    } | null> | null;
  } | null;
};

export type VisitorPaymentFragment = {
  __typename?: 'VisitorPaymentNode';
  id: string;
  createdAt: string;
  amount: string;
  currency: string;
  paid: boolean;
  payment: { __typename?: 'PaymentNode'; paymentType?: BillingPaymentPaymentTypeChoices | null };
};

export type AffiliateCustomersQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
}>;

export type AffiliateCustomersQuery = {
  __typename?: 'Query';
  affiliate?: {
    __typename?: 'AffiliateVisitorsNode';
    visitors?: Array<{
      __typename?: 'VisitorNode';
      id: string;
      referrer?: string | null;
      totalCommission?: number | null;
      organization?: {
        __typename?: 'OrganizationNode';
        id: string;
        dateAdded: string;
        activePlan?: {
          __typename?: 'OrganizationPlanNode';
          id: string;
          name: string;
          isTrial: boolean;
          currency?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type AffiliateVisitorFragment = {
  __typename?: 'VisitorNode';
  id: string;
  referrer?: string | null;
  totalCommission?: number | null;
  organization?: {
    __typename?: 'OrganizationNode';
    id: string;
    dateAdded: string;
    activePlan?: {
      __typename?: 'OrganizationPlanNode';
      id: string;
      name: string;
      isTrial: boolean;
      currency?: string | null;
    } | null;
  } | null;
};

export type AffiliatePaymentsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
}>;

export type AffiliatePaymentsQuery = {
  __typename?: 'Query';
  affiliate?: {
    __typename?: 'AffiliateVisitorsNode';
    payments?: Array<{
      __typename?: 'VisitorPaymentNode';
      id: string;
      createdAt: string;
      amount: string;
      currency: string;
      paid: boolean;
      payment: {
        __typename?: 'PaymentNode';
        paymentType?: BillingPaymentPaymentTypeChoices | null;
      };
    } | null> | null;
  } | null;
};

export type AffiliateReferrersQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
}>;

export type AffiliateReferrersQuery = {
  __typename?: 'Query';
  affiliate?: {
    __typename?: 'AffiliateVisitorsNode';
    referrers?: Array<{
      __typename?: 'ReferrerNode';
      url?: string | null;
      amount?: number | null;
    } | null> | null;
  } | null;
};

export type ReferrerFragment = {
  __typename?: 'ReferrerNode';
  url?: string | null;
  amount?: number | null;
};

export type LoginUserMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginUserMutation = {
  __typename?: 'Mutations';
  userLogin?: {
    __typename?: 'LoginPayload';
    otpRequired?: boolean | null;
    token?: string | null;
    errors?: Array<{ __typename?: 'Error'; messages: Array<string>; field: string } | null> | null;
  } | null;
};

export type LoginTwoFactorMutationVariables = Exact<{
  input: LoginTwoFactorInput;
}>;

export type LoginTwoFactorMutation = {
  __typename?: 'Mutations';
  userLoginTwoFactor?: {
    __typename?: 'LoginTwoFactorPayload';
    username: string;
    password: string;
    otpDevice?: string | null;
    otpToken?: string | null;
    otpChallenge?: string | null;
    token?: string | null;
    errors?: Array<{ __typename?: 'Error'; messages: Array<string>; field: string } | null> | null;
  } | null;
};

export type PasswordPerformPasswordResetMutationVariables = Exact<{
  input: PerformPasswordResetInput;
}>;

export type PasswordPerformPasswordResetMutation = {
  __typename?: 'Mutations';
  performPasswordReset?: {
    __typename?: 'PerformPasswordResetPayload';
    errors?: Array<{ __typename?: 'Error'; messages: Array<string>; field: string } | null> | null;
  } | null;
};

export type RequestPasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;

export type RequestPasswordResetMutation = {
  __typename?: 'Mutations';
  requestPasswordReset?: {
    __typename?: 'RequestPasswordResetPayload';
    email: string;
    errors?: Array<{
      __typename?: 'ErrorType';
      messages: Array<string>;
      field: string;
    } | null> | null;
  } | null;
};

export type CancelPlanMutationVariables = Exact<{
  cancelPlanInput: CancelPlanInput;
}>;

export type CancelPlanMutation = {
  __typename?: 'Mutations';
  cancelPlan?: {
    __typename?: 'CancelPlan';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type CheckoutInfoWidgetGetPricingDetailsQueryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  billingCycle: Scalars['Int']['input'];
  countryId?: InputMaybe<Scalars['String']['input']>;
  validVat?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CheckoutInfoWidgetGetPricingDetailsQueryQuery = {
  __typename?: 'Query';
  pricingPlanCalculated?: {
    __typename?: 'PricingPlanCalculatedNode';
    upgrade?: boolean | null;
    downgrade?: boolean | null;
  } | null;
};

export type AccountOrganizationPlanInfoQueryVariables = Exact<{ [key: string]: never }>;

export type AccountOrganizationPlanInfoQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      activePlan?: {
        __typename?: 'OrganizationPlanNode';
        id: string;
        name: string;
        isFree: boolean;
        isTrial: boolean;
        category?: string | null;
        priceMonthly?: number | null;
        priceYearly?: number | null;
        endDate: string;
        billingCycleInMonths: number;
        currency?: string | null;
        maxKeywords: number;
        calculateFuturePrice?: number | null;
      } | null;
      nextPlan?: {
        __typename?: 'OrganizationPlanNode';
        id: string;
        name: string;
        startDate: string;
      } | null;
    } | null;
  } | null;
};

export type BillingPricingPlanQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type BillingPricingPlanQuery = {
  __typename?: 'Query';
  pricingPlan?: { __typename?: 'PricingPlanNode'; id: string; name: string } | null;
};

export type BillingPricingPlanCalculatedQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  billingCycle: Scalars['Int']['input'];
  countryId: Scalars['String']['input'];
}>;

export type BillingPricingPlanCalculatedQuery = {
  __typename?: 'Query';
  pricingPlanCalculated?: {
    __typename?: 'PricingPlanCalculatedNode';
    currency?: string | null;
    price?: number | null;
    receivable?: number | null;
    signonDiscount?: number | null;
    couponDiscount?: number | null;
    purseCredit?: number | null;
    keywordOveruseCredit?: number | null;
    subAccountDiscount?: number | null;
    totalBeforeVat?: number | null;
  } | null;
};

export type ConnectedAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type ConnectedAccountsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      googleConnections?: Array<{
        __typename?: 'GoogleOAuthConnectionNode';
        id?: string | null;
        description?: string | null;
        createdAt?: string | null;
        createdBy?: string | null;
        type?: string | null;
      } | null> | null;
      adobeMarketingConnections?: Array<{
        __typename?: 'AdobeMarketingConnectionNode';
        id: string;
        description: string;
        createdAt: string;
        createdBy?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GoogleConnectionFragment = {
  __typename?: 'GoogleOAuthConnectionNode';
  id?: string | null;
  description?: string | null;
  createdAt?: string | null;
  createdBy?: string | null;
  type?: string | null;
};

export type ConnectedAccountsDomainsListQueryVariables = Exact<{ [key: string]: never }>;

export type ConnectedAccountsDomainsListQuery = {
  __typename?: 'Query';
  domainsList?: Array<{
    __typename?: 'DomainNode';
    id: string;
    googleOauthConnectionGa?: {
      __typename?: 'GoogleOAuthConnectionNode';
      id?: string | null;
    } | null;
    googleOauthConnectionGsc?: {
      __typename?: 'GoogleOAuthConnectionNode';
      id?: string | null;
    } | null;
    adobeMarketingConnection?: { __typename?: 'AdobeMarketingConnectionNode'; id: string } | null;
  } | null> | null;
};

export type RemoveAdobeAccessMutationVariables = Exact<{
  input: UpdateAdobeMarketingConnectionInput;
}>;

export type RemoveAdobeAccessMutation = {
  __typename?: 'Mutations';
  updateAdobeMarketingConnection?: {
    __typename?: 'UpdateAdobeMarketingConnection';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type RemoveGoogleAccessMutationVariables = Exact<{
  input: UpdateGoogleConnectionInput;
}>;

export type RemoveGoogleAccessMutation = {
  __typename?: 'Mutations';
  updateGoogleConnection?: {
    __typename?: 'UpdateGoogleConnection';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type ErrorFragment = { __typename?: 'Error'; field: string; messages: Array<string> };

export type DynamicTagRulesetExistsQueryVariables = Exact<{
  tag: Scalars['String']['input'];
  domainId: Scalars['Int']['input'];
}>;

export type DynamicTagRulesetExistsQuery = {
  __typename?: 'Query';
  dynamicTagRulesetExists?: boolean | null;
};

export type UpdateDomainDisplaySettingsMutationVariables = Exact<{
  input: UpdateDomainSpecialSettingsInput;
}>;

export type UpdateDomainDisplaySettingsMutation = {
  __typename?: 'Mutations';
  updateDomainSpecialSettings?: {
    __typename?: 'UpdateDomainSpecialSettings';
    domain?: {
      __typename?: 'DomainNode';
      displayCurrency?: string | null;
      showLocalSearchVolume?: boolean | null;
    } | null;
  } | null;
};

export type DomainDisplaySettingsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DomainDisplaySettingsQuery = {
  __typename?: 'Query';
  domain?: {
    __typename?: 'DomainNode';
    displayCurrency?: string | null;
    showLocalSearchVolume?: boolean | null;
  } | null;
};

export type DeleteDomainsMutationVariables = Exact<{
  input: DeleteDomainInput;
}>;

export type DeleteDomainsMutation = {
  __typename?: 'Mutations';
  deleteDomain?: {
    __typename?: 'DeleteDomain';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type DomainsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
}>;

export type DomainsQuery = {
  __typename?: 'Query';
  tableDashboard?: {
    __typename?: 'TableDashboardNode';
    domains?: any | null;
    pagination?: {
      __typename?: 'Pagination';
      numResults?: number | null;
      startIndex?: number | null;
      stopIndex?: number | null;
    } | null;
  } | null;
};

export type PlanExpiredPreviousPlanQueryVariables = Exact<{ [key: string]: never }>;

export type PlanExpiredPreviousPlanQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      previousPlan?: {
        __typename?: 'OrganizationPlanNode';
        id: string;
        name: string;
        canManualReActivate?: boolean | null;
        calculatePrice?: number | null;
        calculateFuturePrice?: number | null;
        currency?: string | null;
        billingCycleInMonths: number;
        isFree: boolean;
      } | null;
    } | null;
  } | null;
};

export type PlanExpiredReActivateMutationVariables = Exact<{ [key: string]: never }>;

export type PlanExpiredReActivateMutation = {
  __typename?: 'Mutations';
  reactivate?: {
    __typename?: 'ReActivate';
    error?: string | null;
    success?: boolean | null;
  } | null;
};

export type MultiAccountOwnersQueryVariables = Exact<{ [key: string]: never }>;

export type MultiAccountOwnersQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      multiAccountOwners?: Array<{
        __typename?: 'MultiAccountLinkNode';
        id: string;
        fromOrganizationPays: boolean;
        isOrgAdmin: boolean;
        isHidden: boolean;
        canImpersonate: boolean;
        fromOrganization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type MultiAccountOwnerFragment = {
  __typename?: 'MultiAccountLinkNode';
  id: string;
  fromOrganizationPays: boolean;
  isOrgAdmin: boolean;
  isHidden: boolean;
  canImpersonate: boolean;
  fromOrganization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
};

export type RemoveMultiAccountMutationVariables = Exact<{
  input: RemoveMultiAccountInput;
}>;

export type RemoveMultiAccountMutation = {
  __typename?: 'Mutations';
  removeMultiAccount?: {
    __typename?: 'RemoveMultiAccount';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type PendingMultiAccountOwnersQueryVariables = Exact<{ [key: string]: never }>;

export type PendingMultiAccountOwnersQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      pendingMultiAccountOwners?: Array<{
        __typename?: 'MultiAccountLinkNode';
        id: string;
        fromOrganizationPays: boolean;
        isOrgAdmin: boolean;
        fromOrganization?: { __typename?: 'OrganizationNode'; name: string } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PendingMultiAccountOwnerFragment = {
  __typename?: 'MultiAccountLinkNode';
  id: string;
  fromOrganizationPays: boolean;
  isOrgAdmin: boolean;
  fromOrganization?: { __typename?: 'OrganizationNode'; name: string } | null;
};

export type DenyMultiAccountAccessMutationVariables = Exact<{
  input: RemoveMultiAccountRequestInput;
}>;

export type DenyMultiAccountAccessMutation = {
  __typename?: 'Mutations';
  removeMultiAccountRequest?: {
    __typename?: 'RemoveMultiAccountRequest';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type AcceptMultiAccountAccessMutationVariables = Exact<{
  input: AcceptMultiAccountRequestInput;
}>;

export type AcceptMultiAccountAccessMutation = {
  __typename?: 'Mutations';
  acceptMultiAccountRequest?: {
    __typename?: 'AcceptMultiAccountRequest';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type MultiAccountPendingRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type MultiAccountPendingRequestsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      pendingMultiAccountOwners?: Array<{
        __typename?: 'MultiAccountLinkNode';
        id: string;
        fromOrganizationPays: boolean;
        isOrgAdmin: boolean;
        fromOrganization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
      } | null> | null;
      deletedMultiAccountOwners: Array<{ __typename?: 'MultiAccountLinkNode'; id: string }>;
      existingMultiAccountOwners?: Array<{
        __typename?: 'MultiAccountLinkNode';
        id: string;
        fromOrganization?: { __typename?: 'OrganizationNode'; name: string } | null;
      }> | null;
    } | null;
  } | null;
};

export type MultiAccountInviteSentFromOwnAccountQueryVariables = Exact<{
  multiAccountId: Scalars['String']['input'];
}>;

export type MultiAccountInviteSentFromOwnAccountQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    organization?: {
      __typename?: 'OrganizationNode';
      inviteSentFromOwnAccount?: boolean | null;
    } | null;
  } | null;
};

export type MultiAccountAcceptMultiAccountRequestMutationVariables = Exact<{
  input: AcceptMultiAccountRequestInput;
}>;

export type MultiAccountAcceptMultiAccountRequestMutation = {
  __typename?: 'Mutations';
  acceptMultiAccountRequest?: {
    __typename?: 'AcceptMultiAccountRequest';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type MultiAccountRemoveAccessRequestMutationVariables = Exact<{
  input: RemoveMultiAccountRequestInput;
}>;

export type MultiAccountRemoveAccessRequestMutation = {
  __typename?: 'Mutations';
  removeMultiAccountRequest?: {
    __typename?: 'RemoveMultiAccountRequest';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type GroupsTableClientsQueryVariables = Exact<{ [key: string]: never }>;

export type GroupsTableClientsQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'ClientNode';
    id: string;
    name: string;
    dateAdded: string;
    numberOfDomains?: number | null;
    numberOfKeywords?: number | null;
    organization: { __typename?: 'OrganizationNode'; id: string };
    domains?: Array<{ __typename?: 'DomainNode'; id: string } | null> | null;
  }>;
};

export type GroupsTableUpdateClientMutationVariables = Exact<{
  input: UpdateClientInput;
}>;

export type GroupsTableUpdateClientMutation = {
  __typename?: 'Mutations';
  updateClient?: {
    __typename?: 'UpdateClient';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type GroupsTableQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  ordering: OrderingInput;
  pagination: PaginationInput;
}>;

export type GroupsTableQuery = {
  __typename?: 'Query';
  tableClients?: {
    __typename?: 'TableClientsNode';
    clients?: Array<{
      __typename?: 'TableClientNode';
      id?: number | null;
      name?: string | null;
      numberOfDomains?: number | null;
      numberOfKeywords?: number | null;
      createdAt?: string | null;
      domainIds?: Array<number | null> | null;
    } | null> | null;
    pagination?: {
      __typename?: 'Pagination';
      numResults?: number | null;
      startIndex?: number | null;
      stopIndex?: number | null;
    } | null;
  } | null;
};

export type GscKeywordsQueryVariables = Exact<{
  domainId: Scalars['ID']['input'];
}>;

export type GscKeywordsQuery = {
  __typename?: 'Query';
  domain?: { __typename?: 'DomainNode'; id: string; gscKeywords?: any | null } | null;
};

export type ImportValidationPageImportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ImportValidationPageImportQuery = {
  __typename?: 'Query';
  uploadImport?: {
    __typename?: 'ImportNode';
    id: string;
    status: ImporterImportStatusChoices;
    progress: number;
    file: string;
    createdAt: string;
    validatingAt?: string | null;
    validationErrorAt?: string | null;
    processingAt?: string | null;
    completedAt?: string | null;
    rankProcessingStartedAt?: string | null;
    rankProcessingLastRowIdx?: number | null;
    rankProcessingResumedAt?: string | null;
    rankProcessingResumedRankIdx?: number | null;
    rankProcessingTotalRows?: number | null;
    errors: Array<{
      __typename?: 'ImportNodeError';
      message: string;
      exception?: string | null;
      line?: Array<string> | null;
    }>;
    domain?: { __typename?: 'DomainNode'; id: string } | null;
    client?: { __typename?: 'ClientNode'; id: string } | null;
    columns: Array<{
      __typename?: 'ImportColumnNode';
      id: number;
      name: string;
      mappedColumnId?: number | null;
      examples: Array<string>;
      errors: Array<{
        __typename?: 'ImportColumnNodeError';
        line: Array<string>;
        message: string;
        exception?: string | null;
      }>;
    }>;
    mappableColumns: Array<{
      __typename?: 'ImportMappableColumnNode';
      id: number;
      name: string;
      type: string;
      description: string;
      examples: Array<string>;
      required: boolean;
    }>;
  } | null;
};

export type ImportValidationPageClientsQueryVariables = Exact<{ [key: string]: never }>;

export type ImportValidationPageClientsQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'ClientNode';
    id: string;
    name: string;
    isDemoClient?: boolean | null;
    domains?: Array<{
      __typename?: 'DomainNode';
      id: string;
      domain: string;
      displayName?: string | null;
    } | null> | null;
  }>;
};

export type ImportValidationPageUpdateImportMutationVariables = Exact<{
  input: UpdateUploadImportInput;
}>;

export type ImportValidationPageUpdateImportMutation = {
  __typename?: 'Mutations';
  updateUploadImport?: {
    __typename?: 'UpdateUploadImport';
    uploadImport?: {
      __typename?: 'ImportNode';
      id: string;
      status: ImporterImportStatusChoices;
    } | null;
  } | null;
};

export type ValidationImportRedirectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ValidationImportRedirectQuery = {
  __typename?: 'Query';
  importRedirect?: { __typename?: 'ImportRedirectNode'; redirectUrl?: string | null } | null;
};

export type ImportPageUploadedImportsQueryVariables = Exact<{ [key: string]: never }>;

export type ImportPageUploadedImportsQuery = { __typename?: 'Query'; uploadedImports?: any | null };

export type ImportRedirectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ImportRedirectQuery = {
  __typename?: 'Query';
  importRedirect?: { __typename?: 'ImportRedirectNode'; redirectUrl?: string | null } | null;
};

export type ImportPageDeleteImportMutationVariables = Exact<{
  input: DeleteUploadImportInput;
}>;

export type ImportPageDeleteImportMutation = {
  __typename?: 'Mutations';
  deleteUploadImport?: {
    __typename?: 'DeleteUploadImport';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type ApiFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type ApiFiltersQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    apiToken?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      apiFilters?: Array<{
        __typename?: 'UserFilterNode';
        id: string;
        name: string;
        createdAt: string;
        filters: string;
      } | null> | null;
    } | null;
  } | null;
};

export type ApiFilterFragment = {
  __typename?: 'UserFilterNode';
  id: string;
  name: string;
  createdAt: string;
  filters: string;
};

export type InvoicesTableQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type InvoicesTableQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'OrganizationNode';
    id: string;
    name: string;
    invoices?: Array<{
      __typename?: 'InvoiceNode';
      number?: number | null;
      createdAt: string;
      paid: boolean;
      currency: string;
      vat: string;
      amount: string;
      total: string;
      url?: string | null;
    } | null> | null;
  } | null;
};

export type RelevantDomainSearchesQueryVariables = Exact<{ [key: string]: never }>;

export type RelevantDomainSearchesQuery = {
  __typename?: 'Query';
  relevantDomainSearches?: {
    __typename?: 'RelevantDomainsNode';
    domainTypes?: {
      __typename?: 'DomainTypeNode';
      latestSearches?: Array<string | null> | null;
      domains?: Array<string | null> | null;
      competitors?: Array<string | null> | null;
    } | null;
  } | null;
};

export type AddDomainSearchMutationVariables = Exact<{
  searchTerm: Scalars['String']['input'];
}>;

export type AddDomainSearchMutation = {
  __typename?: 'Mutations';
  addKeywordResearchDomainSearch?: {
    __typename?: 'AddKeywordResearchDomainSearch';
    searchTerm?: string | null;
    errors?: Array<{ __typename?: 'Error'; messages: Array<string> } | null> | null;
  } | null;
};

export type ValidateDomainQueryVariables = Exact<{
  domain: Scalars['String']['input'];
}>;

export type ValidateDomainQuery = {
  __typename?: 'Query';
  validateDomain?: {
    __typename?: 'ValidateDomainNode';
    domain?: string | null;
    domainValidation?: {
      __typename?: 'DomainValidationNode';
      redirectTo?: string | null;
      valid?: boolean | null;
    } | null;
  } | null;
};

export type WordPhrasesQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
  country?: InputMaybe<Scalars['String']['input']>;
  displayCurrency: Scalars['String']['input'];
}>;

export type WordPhrasesQuery = {
  __typename?: 'Query';
  keydisWordPhrases?: {
    __typename?: 'KeydisWordPhraseListNode';
    wordPhrases?: any | null;
    pagination?: {
      __typename?: 'Pagination';
      numResults?: number | null;
      startIndex?: number | null;
      stopIndex?: number | null;
    } | null;
  } | null;
};

export type KeydisWordCloudQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  country?: InputMaybe<Scalars['String']['input']>;
}>;

export type KeydisWordCloudQuery = {
  __typename?: 'Query';
  keydisWordCloud?: { __typename?: 'KeydisWordCloudListNode'; words?: any | null } | null;
};

export type CountByCountryQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
}>;

export type CountByCountryQuery = {
  __typename?: 'Query';
  countByCountry?: {
    __typename?: 'CountByCountryListNode';
    countries?: Array<{
      __typename?: 'CountByCountryNode';
      keywords?: number | null;
      country?: string | null;
      default?: boolean | null;
      searchSettings?: Array<{
        __typename?: 'SearchSettingNode';
        locations?: Array<string | null> | null;
        ignoreLocalResults?: boolean | null;
        ignoreFeaturedSnippet?: boolean | null;
        enableAutocorrect?: boolean | null;
        countrylocale?: { __typename?: 'CountryLocaleNode'; id: string; region: string } | null;
        searchEngines?: Array<{
          __typename?: 'SearchSettingSearchEngineNode';
          searchTypes?: Array<number | null> | null;
          searchEngine?: { __typename?: 'SearchEngineNode'; id: string; name: string } | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type KeydisKeywordsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
  country?: InputMaybe<Scalars['String']['input']>;
  displayCurrency: Scalars['String']['input'];
}>;

export type KeydisKeywordsQuery = {
  __typename?: 'Query';
  keydisKeywords?: {
    __typename?: 'KeydisKeywordListNode';
    keywords?: any | null;
    pagination?: {
      __typename?: 'Pagination';
      numResults?: number | null;
      startIndex?: number | null;
      stopIndex?: number | null;
    } | null;
  } | null;
};

export type KeydisImportInfoQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
}>;

export type KeydisImportInfoQuery = {
  __typename?: 'Query';
  keydisImportInfo?: {
    __typename?: 'KeydisImportInfoNodeResolver';
    keydisImportInfo?: {
      __typename?: 'KeywordImportInfoNode';
      country?: string | null;
      keywords?: Array<{
        __typename?: 'MinimalKeywordNode';
        keyword?: string | null;
      } | null> | null;
      searchSettings?: {
        __typename?: 'SearchSettingNode';
        locations?: Array<string | null> | null;
        ignoreLocalResults?: boolean | null;
        ignoreFeaturedSnippet?: boolean | null;
        enableAutocorrect?: boolean | null;
        countrylocale?: { __typename?: 'CountryLocaleNode'; id: string; region: string } | null;
        searchEngines?: Array<{
          __typename?: 'SearchSettingSearchEngineNode';
          searchTypes?: Array<number | null> | null;
          searchEngine?: { __typename?: 'SearchEngineNode'; id: string; name: string } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type KeydisKpisQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  displayCurrency: Scalars['String']['input'];
}>;

export type KeydisKpisQuery = {
  __typename?: 'Query';
  keydisKpis?: {
    __typename?: 'KeydisKpiNode';
    discoveredKeywords?: number | null;
    trafficValue?: any | null;
    estimatedVisits?: any | null;
    averageRank?: number | null;
    aboveTheFold?: number | null;
    averageCtr?: number | null;
    commercial?: number | null;
    transactional?: number | null;
    navigational?: number | null;
    informational?: number | null;
    serpFeatures?: any | null;
  } | null;
};

export type KeydisCompetitorsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  country?: InputMaybe<Scalars['String']['input']>;
}>;

export type KeydisCompetitorsQuery = {
  __typename?: 'Query';
  keydisCompetitors: Array<{
    __typename?: 'KeydisCompetitorNode';
    domain: string;
    shareOfVoice: any;
    searchVolume: any;
    keywords: number;
  }>;
};

export type CompetitorsChartQueryVariables = Exact<{
  allHistory?: InputMaybe<Scalars['Boolean']['input']>;
  metric?: InputMaybe<Scalars['String']['input']>;
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  asPercentage?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CompetitorsChartQuery = {
  __typename?: 'Query';
  graphs: { __typename?: 'GraphsNode'; competitorHistory?: any | null };
};

export type CompetitorsRanksQueryQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
}>;

export type CompetitorsRanksQueryQuery = {
  __typename?: 'Query';
  competitorRankList?: {
    __typename?: 'TableCompetitorRankListNode';
    keywords?: Array<{
      __typename?: 'KeywordRankWithCompetitorRankNode';
      id?: string | null;
      keyword?: string | null;
      searchTypeId?: number | null;
      searchEngineId?: number | null;
      searchVolumeValue?: number | null;
      rankValue?: number | null;
      aboveTheFold?: boolean | null;
      rankCompare?: number | null;
      location?: string | null;
      validForSearchVolume?: boolean | null;
      noSearchVolumeForLocation?: boolean | null;
      countrylocaleId?: number | null;
      competitor1Rank?: number | null;
      competitor1RankCompare?: number | null;
      competitor1CompetitorId?: string | null;
      competitor2Rank?: number | null;
      competitor2RankCompare?: number | null;
      competitor2CompetitorId?: string | null;
      competitor3Rank?: number | null;
      competitor3RankCompare?: number | null;
      competitor3CompetitorId?: string | null;
      competitor4Rank?: number | null;
      competitor4RankCompare?: number | null;
      competitor4CompetitorId?: string | null;
      competitor5Rank?: number | null;
      competitor5RankCompare?: number | null;
      competitor5CompetitorId?: string | null;
      competitor6Rank?: number | null;
      competitor6RankCompare?: number | null;
      competitor6CompetitorId?: string | null;
      competitor7Rank?: number | null;
      competitor7RankCompare?: number | null;
      competitor7CompetitorId?: string | null;
      competitor8Rank?: number | null;
      competitor8RankCompare?: number | null;
      competitor8CompetitorId?: string | null;
      competitor9Rank?: number | null;
      competitor9RankCompare?: number | null;
      competitor9CompetitorId?: string | null;
      competitor10Rank?: number | null;
      competitor10RankCompare?: number | null;
      competitor10CompetitorId?: string | null;
    } | null> | null;
    competitors?: Array<{
      __typename?: 'SimpleCompetitorsNode';
      competitorId?: string | null;
      displayName?: string | null;
      domain?: string | null;
    } | null> | null;
    pagination?: {
      __typename?: 'Pagination';
      page: number;
      results: number;
      numResults?: number | null;
      numPages?: number | null;
    } | null;
  } | null;
};

export type DeleteCompetitorMutationVariables = Exact<{
  input: UpdateCompetitorInput;
}>;

export type DeleteCompetitorMutation = {
  __typename?: 'Mutations';
  updateCompetitor?: {
    __typename?: 'UpdateCompetitor';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type CompetitorsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
  withId: Scalars['Boolean']['input'];
  withDomain: Scalars['Boolean']['input'];
  withFaviconUrl: Scalars['Boolean']['input'];
  withDisplayName: Scalars['Boolean']['input'];
  withOrganicTraffic: Scalars['Boolean']['input'];
  withOrganicTrafficCompare: Scalars['Boolean']['input'];
  withDynamicCtrValue: Scalars['Boolean']['input'];
  withDynamicCtrCompare: Scalars['Boolean']['input'];
  withShareOfVoiceValue: Scalars['Boolean']['input'];
  withShareOfVoiceCompare: Scalars['Boolean']['input'];
  withShareOfVoicePercentageValue: Scalars['Boolean']['input'];
  withShareOfVoicePercentageValueCompare: Scalars['Boolean']['input'];
  withCount1To3: Scalars['Boolean']['input'];
  withCount1To3Compare: Scalars['Boolean']['input'];
  withCount4To10: Scalars['Boolean']['input'];
  withCount4To10Compare: Scalars['Boolean']['input'];
  withCount11To20: Scalars['Boolean']['input'];
  withCount11To20Compare: Scalars['Boolean']['input'];
  withCount21To50: Scalars['Boolean']['input'];
  withCount21To50Compare: Scalars['Boolean']['input'];
  withCount51To100: Scalars['Boolean']['input'];
  withCount51To100Compare: Scalars['Boolean']['input'];
  withCountUnranked: Scalars['Boolean']['input'];
  withCountUnrankedCompare: Scalars['Boolean']['input'];
  withAvgRankValue: Scalars['Boolean']['input'];
  withAvgRankCompare: Scalars['Boolean']['input'];
}>;

export type CompetitorsQuery = {
  __typename?: 'Query';
  competitorList?: {
    __typename?: 'TableCompetitorNode';
    competitors?: Array<{
      __typename?: 'CompetitorRowNode';
      id?: number | null;
      domain?: string | null;
      ownDomain?: boolean | null;
      faviconUrl?: string | null;
      displayName?: string | null;
      organicTraffic?: number | null;
      organicTrafficCompare?: number | null;
      dynamicCtrValue?: number | null;
      dynamicCtrCompare?: number | null;
      shareOfVoiceValue?: number | null;
      shareOfVoiceCompare?: number | null;
      shareOfVoicePercentageValue?: number | null;
      shareOfVoicePercentageValueCompare?: number | null;
      count1To3?: number | null;
      count1To3Compare?: number | null;
      count4To10?: number | null;
      count4To10Compare?: number | null;
      count11To20?: number | null;
      count11To20Compare?: number | null;
      count21To50?: number | null;
      count21To50Compare?: number | null;
      count51To100?: number | null;
      count51To100Compare?: number | null;
      countUnranked?: number | null;
      countUnrankedCompare?: number | null;
      avgRankValue?: number | null;
      avgRankCompare?: number | null;
    } | null> | null;
  } | null;
};

export type FolderStructureQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  nested: Scalars['Boolean']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
}>;

export type FolderStructureQuery = {
  __typename?: 'Query';
  folderStructure?: { __typename?: 'FolderStructureNode'; folderStructure?: any | null } | null;
};

export type AvgTopTenCtrByPathQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
}>;

export type AvgTopTenCtrByPathQuery = {
  __typename?: 'Query';
  avgTopTenCtrByPath?: {
    __typename?: 'AvgTopTenCtrByPathNode';
    avgTopTenCtrByPath?: any | null;
  } | null;
};

export type SerpFeaturesByPathQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
}>;

export type SerpFeaturesByPathQuery = {
  __typename?: 'Query';
  serpFeaturesByPath?: {
    __typename?: 'SerpFeaturesByPathNode';
    serpFeaturesByPath?: any | null;
  } | null;
};

export type FolderPathHistoryQueryVariables = Exact<{
  paths: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  metric: FolderMetric;
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
}>;

export type FolderPathHistoryQuery = {
  __typename?: 'Query';
  graphs: { __typename?: 'GraphsNode'; folderPathHistory?: any | null };
};

export type DynamicTagRulesetFromTagQueryVariables = Exact<{
  tag: Scalars['String']['input'];
  domainId: Scalars['Int']['input'];
}>;

export type DynamicTagRulesetFromTagQuery = {
  __typename?: 'Query';
  dynamicTagRulesetFromTag?: {
    __typename?: 'DynamicTagRulesetNode';
    id?: number | null;
    tag?: string | null;
    folderPath?: string | null;
    rawRuleset: string;
    createdBy?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type StaticTagInformationQueryVariables = Exact<{
  tag: Scalars['String']['input'];
  domainId: Scalars['Int']['input'];
}>;

export type StaticTagInformationQuery = {
  __typename?: 'Query';
  staticTagInformation?: {
    __typename?: 'StaticTagInformationNode';
    tag?: string | null;
    folderPath?: string | null;
    keywords?: any | null;
  } | null;
};

export type UpdateFolderStructureMutationVariables = Exact<{
  newTree: Scalars['GenericScalar']['input'];
  domainId: Scalars['Int']['input'];
}>;

export type UpdateFolderStructureMutation = {
  __typename?: 'Mutations';
  updateFolderStructure?: {
    __typename?: 'UpdateFolderStructure';
    errors?: Array<{ __typename?: 'Error'; messages: Array<string> } | null> | null;
  } | null;
};

export type UpdateFolderDescriptionMutationVariables = Exact<{
  description: Scalars['String']['input'];
  path: Scalars['String']['input'];
  isFolder: Scalars['Boolean']['input'];
  domainId: Scalars['Int']['input'];
}>;

export type UpdateFolderDescriptionMutation = {
  __typename?: 'Mutations';
  updateFolderDescription?: {
    __typename?: 'UpdateFolderDescription';
    errors?: Array<{ __typename?: 'Error'; messages: Array<string> } | null> | null;
  } | null;
};

export type DeleteDynamicTagRulesetFromTagMutationVariables = Exact<{
  tag: Scalars['String']['input'];
  domainId: Scalars['Int']['input'];
}>;

export type DeleteDynamicTagRulesetFromTagMutation = {
  __typename?: 'Mutations';
  deleteDynamicTagRulesetFromTag?: {
    __typename?: 'DeleteDynamicTagRulesetFromTag';
    errors?: Array<{ __typename?: 'Error'; messages: Array<string> } | null> | null;
  } | null;
};

export type DeleteStaticTagMutationVariables = Exact<{
  tag: Scalars['String']['input'];
  domainId: Scalars['Int']['input'];
}>;

export type DeleteStaticTagMutation = {
  __typename?: 'Mutations';
  deleteStaticTag?: {
    __typename?: 'DeleteStaticTag';
    errors?: Array<{ __typename?: 'Error'; messages: Array<string> } | null> | null;
  } | null;
};

export type CompetitorBubbleChartQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  name: Scalars['String']['input'];
}>;

export type CompetitorBubbleChartQuery = {
  __typename?: 'Query';
  charts?: {
    __typename?: 'ChartsNode';
    competitorStats?: Array<{
      __typename?: 'OverviewCompetitorStatsChartNodeNode';
      keywords?: number | null;
      searchVolume?: number | null;
      shareOfVoice?: number | null;
      domain?: string | null;
      displayName?: string | null;
      type?: string | null;
    } | null> | null;
  } | null;
};

export type GscAndGaHistoryQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  name: Scalars['String']['input'];
}>;

export type GscAndGaHistoryQuery = {
  __typename?: 'Query';
  graphs: { __typename?: 'GraphsNode'; gscAndGaHistory?: any | null };
};

export type HistoryChartsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  name: Scalars['String']['input'];
  charts: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;

export type HistoryChartsQuery = {
  __typename?: 'Query';
  graphs: { __typename?: 'GraphsNode'; overviewHistoryGraphs?: any | null };
};

export type KpiBarQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  name: Scalars['String']['input'];
  displayCurrency: Scalars['String']['input'];
}>;

export type KpiBarQuery = {
  __typename?: 'Query';
  kpis?: {
    __typename?: 'KpisNode';
    allKpis?: {
      __typename?: 'AllKpis';
      keywords?: number | null;
      keywordsBefore?: number | null;
      trafficValue?: number | null;
      trafficValueBefore?: number | null;
      maxTrafficValue?: number | null;
      shareOfVoiceSum?: number | null;
      shareOfVoiceSumBefore?: number | null;
      maxShareOfVoiceSum?: number | null;
      aiShareOfVoiceSum?: number | null;
      aiShareOfVoiceSumBefore?: number | null;
      maxAiShareOfVoiceSum?: number | null;
      avgRank?: number | null;
      avgRankBefore?: number | null;
      aboveTheFold?: number | null;
      aboveTheFoldBefore?: number | null;
      ctr?: number | null;
      ctrBefore?: number | null;
      maxCtr?: number | null;
      aiTrafficValue?: number | null;
      aiTrafficValueBefore?: number | null;
      maxAiTrafficValue?: number | null;
      avgBaseRank?: number | null;
      avgBaseRankBefore?: number | null;
      avgMaxBaseRank?: number | null;
      pixelsFromTop?: number | null;
      pixelsFromTopBefore?: number | null;
      searchVolume?: number | null;
      searchVolumeBefore?: number | null;
      aiSearchVolume?: number | null;
      aiSearchVolumeBefore?: number | null;
    } | null;
  } | null;
};

export type DiscoveredKeywordsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
}>;

export type DiscoveredKeywordsQuery = {
  __typename?: 'Query';
  keydisKpis?: { __typename?: 'KeydisKpiNode'; discoveredKeywords?: number | null } | null;
};

export type OverviewNotificationsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
}>;

export type OverviewNotificationsQuery = {
  __typename?: 'Query';
  notifications?: {
    __typename?: 'TableNoteficationListNode';
    notifications?: any | null;
    pagination?: {
      __typename?: 'Pagination';
      numResults?: number | null;
      startIndex?: number | null;
      stopIndex?: number | null;
    } | null;
  } | null;
};

export type SearchIntentOverviewQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type SearchIntentOverviewQuery = {
  __typename?: 'Query';
  graphs: {
    __typename?: 'GraphsNode';
    overviewSearchIntent: {
      __typename?: 'OverviewSearchIntentGraphNode';
      I: number;
      N: number;
      T: number;
      C: number;
      keywordCount: number;
    };
  };
};

export type SearchTrendQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  name: Scalars['String']['input'];
}>;

export type SearchTrendQuery = {
  __typename?: 'Query';
  graphs: {
    __typename?: 'GraphsNode';
    overviewSearchTrend: Array<{
      __typename?: 'OverviewSearchTrendGraphNode';
      month: string;
      searchVolumeTotal: number;
    }>;
  };
};

export type ShareOfVoiceCompetitorsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  asPercentage: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  useAi: Scalars['Boolean']['input'];
}>;

export type ShareOfVoiceCompetitorsQuery = {
  __typename?: 'Query';
  charts?: {
    __typename?: 'ChartsNode';
    shareOfVoiceCompetitors?: Array<{
      __typename?: 'OverviewShareOfVoiceCompetitorChartNodeNode';
      domain: string;
      shareOfVoice?: number | null;
      type: string;
      faviconUrl: string;
      displayName?: string | null;
    }> | null;
  } | null;
};

export type ShareOfVoiceTagsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  asPercentage: Scalars['Boolean']['input'];
  useAi: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
}>;

export type ShareOfVoiceTagsQuery = {
  __typename?: 'Query';
  charts?: {
    __typename?: 'ChartsNode';
    shareOfVoiceByTags?: {
      __typename?: 'TEMPOverviewChartTagNode';
      total?: number | null;
      rows?: Array<{
        __typename?: 'TEMPOverviewChartTagRowNode';
        key?: string | null;
        value?: number | null;
        isDynamic: boolean;
        isStatic: boolean;
        otherTags: boolean;
      } | null> | null;
    } | null;
  } | null;
};

export type ShareOfVoiceLandingPagesQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  asPercentage: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  useAi: Scalars['Boolean']['input'];
}>;

export type ShareOfVoiceLandingPagesQuery = {
  __typename?: 'Query';
  charts?: {
    __typename?: 'ChartsNode';
    shareOfVoiceLandingPages?: {
      __typename?: 'OverviewChartNode';
      total?: number | null;
      rows?: Array<{
        __typename?: 'OverviewChartRowNode';
        key?: string | null;
        value?: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type UnknownCompetitorsBarChartQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  name: Scalars['String']['input'];
}>;

export type UnknownCompetitorsBarChartQuery = {
  __typename?: 'Query';
  charts?: {
    __typename?: 'ChartsNode';
    unknownCompetitors?: Array<{
      __typename?: 'OverviewUnknownCompetitorChartNodeNode';
      domain?: string | null;
      ownDomain?: boolean | null;
      competitor?: boolean | null;
      amount?: number | null;
    } | null> | null;
  } | null;
};

export type UnknownCompetitorRankDistributionQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  competitors:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}>;

export type UnknownCompetitorRankDistributionQuery = {
  __typename?: 'Query';
  graphs: {
    __typename?: 'GraphsNode';
    unknownCompetitorRankDistribution?: Array<{
      __typename?: 'UnknownCompetitorRankDistributionGraphNode';
      domain?: string | null;
      ownDomain?: boolean | null;
      competitor?: boolean | null;
      rank1?: number | null;
      rank2?: number | null;
      rank3?: number | null;
      rank4?: number | null;
      rank5?: number | null;
      rank6?: number | null;
      rank7?: number | null;
      rank8?: number | null;
      rank9?: number | null;
      rank10?: number | null;
    } | null> | null;
  };
};

export type UnknownCompetitorRankDistributionOverTimeQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  competitors:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}>;

export type UnknownCompetitorRankDistributionOverTimeQuery = {
  __typename?: 'Query';
  graphs: { __typename?: 'GraphsNode'; unknownCompetitorRankDistributionNew?: any | null };
};

export type WinnerAndLosersQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  name: Scalars['String']['input'];
  useAi: Scalars['Boolean']['input'];
}>;

export type WinnerAndLosersQuery = {
  __typename?: 'Query';
  charts?: {
    __typename?: 'ChartsNode';
    winnersAndLosers?: Array<{
      __typename?: 'WinnersAndLosersChartNodeNode';
      rankWinners: number;
      rankLosers: number;
      rankNoChange: number;
      winnersSovGain: number;
      losersSovLoss: number;
      periodFrom: string;
      periodTo: string;
    } | null> | null;
  } | null;
};

export type WinnerAndLosersTableQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
  withId: Scalars['Boolean']['input'];
  withKeyword: Scalars['Boolean']['input'];
  withStarred: Scalars['Boolean']['input'];
  withTags: Scalars['Boolean']['input'];
  withDynamicTags: Scalars['Boolean']['input'];
  withDynamicCtr: Scalars['Boolean']['input'];
  withOrganicTraffic: Scalars['Boolean']['input'];
  withOrganicTrafficCompare: Scalars['Boolean']['input'];
  withSearchIntent: Scalars['Boolean']['input'];
  withIgnoreInShareOfVoice: Scalars['Boolean']['input'];
  withIgnoreLocalResults: Scalars['Boolean']['input'];
  withIgnoreFeaturedSnippet: Scalars['Boolean']['input'];
  withEnableAutocorrect: Scalars['Boolean']['input'];
  withIgnoredDomains: Scalars['Boolean']['input'];
  withSearchTypeId: Scalars['Boolean']['input'];
  withUpdatingKeyword: Scalars['Boolean']['input'];
  withSearchEngineId: Scalars['Boolean']['input'];
  withDomain: Scalars['Boolean']['input'];
  withDomainId: Scalars['Boolean']['input'];
  withDisplayName: Scalars['Boolean']['input'];
  withKeywordDescription: Scalars['Boolean']['input'];
  withLocation: Scalars['Boolean']['input'];
  withCountrylocaleId: Scalars['Boolean']['input'];
  withRankId: Scalars['Boolean']['input'];
  withRankValue: Scalars['Boolean']['input'];
  withRankCompare: Scalars['Boolean']['input'];
  withHasExtraRanks: Scalars['Boolean']['input'];
  withExtraRanks: Scalars['Boolean']['input'];
  withAboveTheFold: Scalars['Boolean']['input'];
  withTitle: Scalars['Boolean']['input'];
  withDescription: Scalars['Boolean']['input'];
  withPreferredLandingPageId: Scalars['Boolean']['input'];
  withLandingPageId: Scalars['Boolean']['input'];
  withHighestRankingPage: Scalars['Boolean']['input'];
  withSearchVolumeValue: Scalars['Boolean']['input'];
  withValidForSearchVolume: Scalars['Boolean']['input'];
  withAvgCostPerClick: Scalars['Boolean']['input'];
  withCompetition: Scalars['Boolean']['input'];
  withAnalyticsPotential: Scalars['Boolean']['input'];
  withAnalyticsVisitors: Scalars['Boolean']['input'];
  withShareOfVoiceIsPercentage: Scalars['Boolean']['input'];
  withShareOfVoiceValue: Scalars['Boolean']['input'];
  withShareOfVoiceCompare: Scalars['Boolean']['input'];
  withShareOfVoicePercentageValue: Scalars['Boolean']['input'];
  withShareOfVoicePercentageValueCompare: Scalars['Boolean']['input'];
  withShareOfVoiceCpcValue: Scalars['Boolean']['input'];
  withShareOfVoiceCpcCompare: Scalars['Boolean']['input'];
  withSerp: Scalars['Boolean']['input'];
  withDateAdded: Scalars['Boolean']['input'];
  withSearchDate: Scalars['Boolean']['input'];
  withHistoryUrl: Scalars['Boolean']['input'];
  allowPartialScrape: Scalars['Boolean']['input'];
}>;

export type WinnerAndLosersTableQuery = {
  __typename?: 'Query';
  tableKeywords?: {
    __typename?: 'TableKeywordListNode';
    keywords?: any | null;
    pagination?: {
      __typename?: 'Pagination';
      numResults?: number | null;
      startIndex?: number | null;
      stopIndex?: number | null;
    } | null;
  } | null;
};

export type UpdateOverviewChartsMutationVariables = Exact<{
  input: UpdateUserSettingsInput;
}>;

export type UpdateOverviewChartsMutation = {
  __typename?: 'Mutations';
  updateUserSettings?: {
    __typename?: 'UpdateUserSettings';
    user?: {
      __typename?: 'UserNode';
      id?: string | null;
      defaultOverviewCharts?: any | null;
    } | null;
  } | null;
};

export type SerpsDataQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
}>;

export type SerpsDataQuery = {
  __typename?: 'Query';
  charts?: {
    __typename?: 'ChartsNode';
    serpFeatureOverview?: Array<{
      __typename?: 'SerpFeatureOverviewChartNodeNode';
      adsTop?: number | null;
      adsInBetween?: number | null;
      adsBottom?: number | null;
      shopping?: number | null;
      mapsLocalTeaser?: number | null;
      mapsLocal?: number | null;
      relatedQuestions?: number | null;
      carousel?: number | null;
      imagePack?: number | null;
      reviews?: number | null;
      tweets?: number | null;
      siteLinks?: number | null;
      featureSnippet?: number | null;
      knowledgePanel?: number | null;
      knowledgePanelPriceComparison?: number | null;
      knowledgeCards?: number | null;
      video?: number | null;
      thumbnails?: number | null;
      breadcrumbs?: number | null;
      https?: number | null;
      directAnswer?: number | null;
      jobPosting?: number | null;
      siteLinksSearchbox?: number | null;
      thingsTodo?: number | null;
      featuredVideo?: number | null;
      faq?: number | null;
      tripsPopular?: number | null;
      podcasts?: number | null;
      aiOverview?: number | null;
      relatedSearchesCarousel?: number | null;
      mostPopularCarousel?: number | null;
      refineCarousel?: number | null;
      resultsAbout?: number | null;
      apps?: number | null;
      searchByPhotos?: number | null;
      discoverMorePlaces?: number | null;
      flights?: number | null;
      events?: number | null;
      organicStores?: number | null;
      popularStores?: number | null;
      richProductInfo?: number | null;
      filterMenu?: number | null;
      mapsLocalTeaserOwned?: number | null;
      mapsLocalOwned?: number | null;
      relatedQuestionsOwned?: number | null;
      reviewsOwned?: number | null;
      tweetsOwned?: number | null;
      siteLinksOwned?: number | null;
      featureSnippetOwned?: number | null;
      knowledgePanelOwned?: number | null;
      knowledgePanelPriceComparisonOwned?: number | null;
      videoOwned?: number | null;
      thumbnailsOwned?: number | null;
      breadcrumbsOwned?: number | null;
      httpsOwned?: number | null;
      siteLinksSearchboxOwned?: number | null;
      faqOwned?: number | null;
      featuredVideoOwned?: number | null;
      recipesOwned?: number | null;
      organicStoresOwned?: number | null;
      popularStoresOwned?: number | null;
      richProductInfoOwned?: number | null;
      newsPosition?: number | null;
      newsPositionOwned?: number | null;
      videoCarouselPosition?: number | null;
      videoCarouselPositionOwned?: number | null;
      recipesPosition?: number | null;
      recipesPositionOwned?: number | null;
      researchProductPosition?: number | null;
      researchProductPositionOwned?: number | null;
      hotelsPosition?: number | null;
      hotelsPositionOwned?: number | null;
      forumPostPosition?: number | null;
      forumPostPositionOwned?: number | null;
      imageProductPackPosition?: number | null;
      imageProductPackPositionOwned?: number | null;
      shopStyles?: number | null;
      shopStylesOwned?: number | null;
      youtubeAds?: number | null;
      youtubePromotedVideo?: number | null;
      youtubePromotedVideoOwned?: number | null;
      youtubeRelatedSearches?: number | null;
      youtubeRelatedToYourSearch?: number | null;
      youtubeShelfRender?: number | null;
      youtubeRelatedMovies?: number | null;
      youtubeSecondarySearchContainer?: number | null;
      youtubeChannel?: number | null;
      youtubeVideo?: number | null;
      youtubeShow?: number | null;
      youtubePlaylist?: number | null;
      youtubeMovie?: number | null;
      youtubeLiveStream?: number | null;
      youtubeRadio?: number | null;
      youtubeChannelOwned?: number | null;
      youtubeVideoOwned?: number | null;
      youtubeShowOwned?: number | null;
      youtubePlaylistOwned?: number | null;
      youtubeMovieOwned?: number | null;
      youtubeLiveStreamOwned?: number | null;
      youtubeRadioOwned?: number | null;
      keywordCount?: number | null;
    } | null> | null;
  } | null;
};

export type ChartsFragment = {
  __typename?: 'ChartsNode';
  serpFeatureOverview?: Array<{
    __typename?: 'SerpFeatureOverviewChartNodeNode';
    adsTop?: number | null;
    adsInBetween?: number | null;
    adsBottom?: number | null;
    shopping?: number | null;
    mapsLocalTeaser?: number | null;
    mapsLocal?: number | null;
    relatedQuestions?: number | null;
    carousel?: number | null;
    imagePack?: number | null;
    reviews?: number | null;
    tweets?: number | null;
    siteLinks?: number | null;
    featureSnippet?: number | null;
    knowledgePanel?: number | null;
    knowledgePanelPriceComparison?: number | null;
    knowledgeCards?: number | null;
    video?: number | null;
    thumbnails?: number | null;
    breadcrumbs?: number | null;
    https?: number | null;
    directAnswer?: number | null;
    jobPosting?: number | null;
    siteLinksSearchbox?: number | null;
    thingsTodo?: number | null;
    featuredVideo?: number | null;
    faq?: number | null;
    tripsPopular?: number | null;
    podcasts?: number | null;
    aiOverview?: number | null;
    relatedSearchesCarousel?: number | null;
    mostPopularCarousel?: number | null;
    refineCarousel?: number | null;
    resultsAbout?: number | null;
    apps?: number | null;
    searchByPhotos?: number | null;
    discoverMorePlaces?: number | null;
    flights?: number | null;
    events?: number | null;
    organicStores?: number | null;
    popularStores?: number | null;
    richProductInfo?: number | null;
    filterMenu?: number | null;
    mapsLocalTeaserOwned?: number | null;
    mapsLocalOwned?: number | null;
    relatedQuestionsOwned?: number | null;
    reviewsOwned?: number | null;
    tweetsOwned?: number | null;
    siteLinksOwned?: number | null;
    featureSnippetOwned?: number | null;
    knowledgePanelOwned?: number | null;
    knowledgePanelPriceComparisonOwned?: number | null;
    videoOwned?: number | null;
    thumbnailsOwned?: number | null;
    breadcrumbsOwned?: number | null;
    httpsOwned?: number | null;
    siteLinksSearchboxOwned?: number | null;
    faqOwned?: number | null;
    featuredVideoOwned?: number | null;
    recipesOwned?: number | null;
    organicStoresOwned?: number | null;
    popularStoresOwned?: number | null;
    richProductInfoOwned?: number | null;
    newsPosition?: number | null;
    newsPositionOwned?: number | null;
    videoCarouselPosition?: number | null;
    videoCarouselPositionOwned?: number | null;
    recipesPosition?: number | null;
    recipesPositionOwned?: number | null;
    researchProductPosition?: number | null;
    researchProductPositionOwned?: number | null;
    hotelsPosition?: number | null;
    hotelsPositionOwned?: number | null;
    forumPostPosition?: number | null;
    forumPostPositionOwned?: number | null;
    imageProductPackPosition?: number | null;
    imageProductPackPositionOwned?: number | null;
    shopStyles?: number | null;
    shopStylesOwned?: number | null;
    youtubeAds?: number | null;
    youtubePromotedVideo?: number | null;
    youtubePromotedVideoOwned?: number | null;
    youtubeRelatedSearches?: number | null;
    youtubeRelatedToYourSearch?: number | null;
    youtubeShelfRender?: number | null;
    youtubeRelatedMovies?: number | null;
    youtubeSecondarySearchContainer?: number | null;
    youtubeChannel?: number | null;
    youtubeVideo?: number | null;
    youtubeShow?: number | null;
    youtubePlaylist?: number | null;
    youtubeMovie?: number | null;
    youtubeLiveStream?: number | null;
    youtubeRadio?: number | null;
    youtubeChannelOwned?: number | null;
    youtubeVideoOwned?: number | null;
    youtubeShowOwned?: number | null;
    youtubePlaylistOwned?: number | null;
    youtubeMovieOwned?: number | null;
    youtubeLiveStreamOwned?: number | null;
    youtubeRadioOwned?: number | null;
    keywordCount?: number | null;
  } | null> | null;
};

export type KeywordsSerpHistoryQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  serp: Scalars['String']['input'];
}>;

export type KeywordsSerpHistoryQuery = {
  __typename?: 'Query';
  graphs: { __typename?: 'GraphsNode'; serpHistory?: any | null };
};

export type KeywordsSearchVolumeQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
}>;

export type KeywordsSearchVolumeQuery = {
  __typename?: 'Query';
  keywords?: {
    __typename?: 'KeywordListNode';
    keywords?: Array<{
      __typename?: 'KeywordNode';
      id: string;
      keyword: string;
      searchVolume?: {
        __typename?: 'SearchVolumeNode';
        id: string;
        history?: Array<{
          __typename?: 'SearchVolumeHistoryNode';
          month?: string | null;
          volume?: number | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type KeywordsAiSearchVolumeQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
}>;

export type KeywordsAiSearchVolumeQuery = {
  __typename?: 'Query';
  keywords?: {
    __typename?: 'KeywordListNode';
    keywords?: Array<{
      __typename?: 'KeywordNode';
      id: string;
      keyword: string;
      aiSearchVolume?: {
        __typename?: 'AiSearchVolumeNode';
        id: string;
        history?: Array<{
          __typename?: 'AiSearchVolumeHistoryNode';
          month?: string | null;
          volume?: number | null;
          volumeMobile?: number | null;
          volumeDesktop?: number | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UpdateAiSvMutationVariables = Exact<{
  keywordId: Scalars['ID']['input'];
}>;

export type UpdateAiSvMutation = {
  __typename?: 'Mutations';
  refreshAiSearchVolume?: {
    __typename?: 'RefreshAiSearchVolume';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type PaginatedTableKeywordsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
  withId: Scalars['Boolean']['input'];
  withKeyword: Scalars['Boolean']['input'];
  withStarred: Scalars['Boolean']['input'];
  withTags: Scalars['Boolean']['input'];
  withDynamicTags: Scalars['Boolean']['input'];
  withDynamicCtr: Scalars['Boolean']['input'];
  withOrganicTraffic: Scalars['Boolean']['input'];
  withOrganicTrafficCompare: Scalars['Boolean']['input'];
  withSearchIntent: Scalars['Boolean']['input'];
  withIgnoreInShareOfVoice: Scalars['Boolean']['input'];
  withIgnoreLocalResults: Scalars['Boolean']['input'];
  withIgnoreFeaturedSnippet: Scalars['Boolean']['input'];
  withEnableAutocorrect: Scalars['Boolean']['input'];
  withIgnoredDomains: Scalars['Boolean']['input'];
  withSearchTypeId: Scalars['Boolean']['input'];
  withUpdatingKeyword: Scalars['Boolean']['input'];
  withSearchEngineId: Scalars['Boolean']['input'];
  withDomain: Scalars['Boolean']['input'];
  withDomainId: Scalars['Boolean']['input'];
  withDisplayName: Scalars['Boolean']['input'];
  withKeywordDescription: Scalars['Boolean']['input'];
  withLocation: Scalars['Boolean']['input'];
  withCountrylocaleId: Scalars['Boolean']['input'];
  withRankId: Scalars['Boolean']['input'];
  withRankValue: Scalars['Boolean']['input'];
  withRankCompare: Scalars['Boolean']['input'];
  withHasExtraRanks: Scalars['Boolean']['input'];
  withExtraRanks: Scalars['Boolean']['input'];
  withAboveTheFold: Scalars['Boolean']['input'];
  withTitle: Scalars['Boolean']['input'];
  withDescription: Scalars['Boolean']['input'];
  withPreferredLandingPageId: Scalars['Boolean']['input'];
  withLandingPageId: Scalars['Boolean']['input'];
  withHighestRankingPage: Scalars['Boolean']['input'];
  withSearchVolumeValue: Scalars['Boolean']['input'];
  withAiSearchVolume: Scalars['Boolean']['input'];
  withValidForSearchVolume: Scalars['Boolean']['input'];
  withAvgCostPerClick: Scalars['Boolean']['input'];
  withCompetition: Scalars['Boolean']['input'];
  withAnalyticsPotential: Scalars['Boolean']['input'];
  withAnalyticsVisitors: Scalars['Boolean']['input'];
  withShareOfVoiceIsPercentage: Scalars['Boolean']['input'];
  withShareOfVoiceValue: Scalars['Boolean']['input'];
  withShareOfVoiceCompare: Scalars['Boolean']['input'];
  withShareOfVoicePercentageValue: Scalars['Boolean']['input'];
  withShareOfVoicePercentageValueCompare: Scalars['Boolean']['input'];
  withShareOfVoiceCpcValue: Scalars['Boolean']['input'];
  withShareOfVoiceCpcCompare: Scalars['Boolean']['input'];
  withSerp: Scalars['Boolean']['input'];
  withDateAdded: Scalars['Boolean']['input'];
  withSearchDate: Scalars['Boolean']['input'];
  withHistoryUrl: Scalars['Boolean']['input'];
  displayCurrency: Scalars['String']['input'];
  withIgnoredDomain: Scalars['Boolean']['input'];
  withBaseRank: Scalars['Boolean']['input'];
  withBaseRankCompare: Scalars['Boolean']['input'];
  withMaxRank: Scalars['Boolean']['input'];
  withLocalPackRank: Scalars['Boolean']['input'];
  withLocalPackRankCompare: Scalars['Boolean']['input'];
  withMaxAiSov: Scalars['Boolean']['input'];
  withMaxAiTrafficValue: Scalars['Boolean']['input'];
  withAiTrafficValue: Scalars['Boolean']['input'];
  withAiTrafficValueCompare: Scalars['Boolean']['input'];
  withMaxTrafficValue: Scalars['Boolean']['input'];
  withMaxSov: Scalars['Boolean']['input'];
  withPixelsFromTop: Scalars['Boolean']['input'];
  withPixelsFromTopCompare: Scalars['Boolean']['input'];
  withTopDomain: Scalars['Boolean']['input'];
}>;

export type PaginatedTableKeywordsQuery = {
  __typename?: 'Query';
  tableKeywords?: {
    __typename?: 'TableKeywordListNode';
    keywords?: any | null;
    pagination?: {
      __typename?: 'Pagination';
      numResults?: number | null;
      startIndex?: number | null;
      stopIndex?: number | null;
    } | null;
  } | null;
};

export type GetKeywordsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
  withId: Scalars['Boolean']['input'];
  withKeyword: Scalars['Boolean']['input'];
  withStarred: Scalars['Boolean']['input'];
  withTags: Scalars['Boolean']['input'];
  withDynamicTags: Scalars['Boolean']['input'];
  withDynamicCtr: Scalars['Boolean']['input'];
  withOrganicTraffic: Scalars['Boolean']['input'];
  withOrganicTrafficCompare: Scalars['Boolean']['input'];
  withSearchIntent: Scalars['Boolean']['input'];
  withIgnoreInShareOfVoice: Scalars['Boolean']['input'];
  withIgnoreLocalResults: Scalars['Boolean']['input'];
  withIgnoreFeaturedSnippet: Scalars['Boolean']['input'];
  withEnableAutocorrect: Scalars['Boolean']['input'];
  withIgnoredDomains: Scalars['Boolean']['input'];
  withSearchTypeId: Scalars['Boolean']['input'];
  withUpdatingKeyword: Scalars['Boolean']['input'];
  withSearchEngineId: Scalars['Boolean']['input'];
  withDomain: Scalars['Boolean']['input'];
  withDomainId: Scalars['Boolean']['input'];
  withDisplayName: Scalars['Boolean']['input'];
  withKeywordDescription: Scalars['Boolean']['input'];
  withLocation: Scalars['Boolean']['input'];
  withCountrylocaleId: Scalars['Boolean']['input'];
  withRankId: Scalars['Boolean']['input'];
  withRankValue: Scalars['Boolean']['input'];
  withRankCompare: Scalars['Boolean']['input'];
  withHasExtraRanks: Scalars['Boolean']['input'];
  withExtraRanks: Scalars['Boolean']['input'];
  withAboveTheFold: Scalars['Boolean']['input'];
  withTitle: Scalars['Boolean']['input'];
  withDescription: Scalars['Boolean']['input'];
  withPreferredLandingPageId: Scalars['Boolean']['input'];
  withLandingPageId: Scalars['Boolean']['input'];
  withHighestRankingPage: Scalars['Boolean']['input'];
  withSearchVolumeValue: Scalars['Boolean']['input'];
  withValidForSearchVolume: Scalars['Boolean']['input'];
  withAvgCostPerClick: Scalars['Boolean']['input'];
  withCompetition: Scalars['Boolean']['input'];
  withAnalyticsPotential: Scalars['Boolean']['input'];
  withAnalyticsVisitors: Scalars['Boolean']['input'];
  withShareOfVoiceIsPercentage: Scalars['Boolean']['input'];
  withShareOfVoiceValue: Scalars['Boolean']['input'];
  withShareOfVoiceCompare: Scalars['Boolean']['input'];
  withShareOfVoicePercentageValue: Scalars['Boolean']['input'];
  withShareOfVoicePercentageValueCompare: Scalars['Boolean']['input'];
  withShareOfVoiceCpcValue: Scalars['Boolean']['input'];
  withShareOfVoiceCpcCompare: Scalars['Boolean']['input'];
  withSerp: Scalars['Boolean']['input'];
  withDateAdded: Scalars['Boolean']['input'];
  withSearchDate: Scalars['Boolean']['input'];
  withHistoryUrl: Scalars['Boolean']['input'];
  displayCurrency: Scalars['String']['input'];
  withIgnoredDomain: Scalars['Boolean']['input'];
  withBaseRank: Scalars['Boolean']['input'];
  withBaseRankCompare: Scalars['Boolean']['input'];
  withMaxRank: Scalars['Boolean']['input'];
  withLocalPackRank: Scalars['Boolean']['input'];
  withLocalPackRankCompare: Scalars['Boolean']['input'];
  withMaxAiSov: Scalars['Boolean']['input'];
  withMaxAiTrafficValue: Scalars['Boolean']['input'];
  withAiTrafficValue: Scalars['Boolean']['input'];
  withAiTrafficValueCompare: Scalars['Boolean']['input'];
  withMaxTrafficValue: Scalars['Boolean']['input'];
  withMaxSov: Scalars['Boolean']['input'];
  withPixelsFromTop: Scalars['Boolean']['input'];
  withPixelsFromTopCompare: Scalars['Boolean']['input'];
  withTopDomain: Scalars['Boolean']['input'];
}>;

export type GetKeywordsQuery = {
  __typename?: 'Query';
  tableKeywords?: {
    __typename?: 'TableKeywordListNode';
    keywords?: any | null;
    pagination?: {
      __typename?: 'Pagination';
      numResults?: number | null;
      startIndex?: number | null;
      stopIndex?: number | null;
    } | null;
  } | null;
};

export type QueryIsDemoClientQueryVariables = Exact<{
  input: Scalars['ID']['input'];
}>;

export type QueryIsDemoClientQuery = {
  __typename?: 'Query';
  domain?: {
    __typename?: 'DomainNode';
    client: { __typename?: 'ClientNode'; isDemoClient?: boolean | null };
    demoDomainBannerInfo?: {
      __typename?: 'DemoDomainInfo';
      domainCount?: number | null;
      domainId?: string | null;
      domainName?: string | null;
    } | null;
  } | null;
};

export type GroupsAndDomainClientsQueryVariables = Exact<{ [key: string]: never }>;

export type GroupsAndDomainClientsQuery = {
  __typename?: 'Query';
  clients: Array<{
    __typename?: 'ClientNode';
    id: string;
    name: string;
    isDemoClient?: boolean | null;
    latestCompletedScrapeDate?: string | null;
    organization: { __typename?: 'OrganizationNode'; id: string };
    domains?: Array<{
      __typename?: 'DomainNode';
      id: string;
      domain: string;
      displayName?: string | null;
    } | null> | null;
  }>;
};

export type GroupsAndDomainsDeleteDomainMutationVariables = Exact<{
  input: DeleteDomainInput;
}>;

export type GroupsAndDomainsDeleteDomainMutation = {
  __typename?: 'Mutations';
  deleteDomain?: {
    __typename?: 'DeleteDomain';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type GroupsAndDomainsUpdateClientMutationVariables = Exact<{
  input: UpdateClientInput;
}>;

export type GroupsAndDomainsUpdateClientMutation = {
  __typename?: 'Mutations';
  updateClient?: {
    __typename?: 'UpdateClient';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type StatusServiceMessagesQueryVariables = Exact<{ [key: string]: never }>;

export type StatusServiceMessagesQuery = {
  __typename?: 'Query';
  serviceMessageHighestSeverity: number;
};

export type NotesTableDomainQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type NotesTableDomainQuery = {
  __typename?: 'Query';
  domain?: {
    __typename?: 'DomainNode';
    id: string;
    notes?: Array<{
      __typename?: 'NoteNode';
      id: string;
      createdAt: string;
      note: string;
      keywords?: Array<{ __typename?: 'KeywordNode'; id: string; keyword: string } | null> | null;
      user?: { __typename?: 'UserNode'; id?: string | null; fullName?: string | null } | null;
    } | null> | null;
  } | null;
};

export type EditNoteMutationVariables = Exact<{
  input: UpdateNoteInput;
}>;

export type EditNoteMutation = {
  __typename?: 'Mutations';
  updateNote?: {
    __typename?: 'UpdateNote';
    note?: { __typename?: 'NoteNode'; id: string } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type PausedDomainsUpdateDomainMutationVariables = Exact<{
  input: UpdateDomainInput;
}>;

export type PausedDomainsUpdateDomainMutation = {
  __typename?: 'Mutations';
  updateDomain?: {
    __typename?: 'UpdateDomain';
    code?: KeywordOveruseCode | null;
    domain?: { __typename?: 'DomainNode'; id: string } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type PausedDomainsListQueryVariables = Exact<{ [key: string]: never }>;

export type PausedDomainsListQuery = {
  __typename?: 'Query';
  pausedDomainsList?: Array<{
    __typename?: 'DomainNode';
    id: string;
    domain: string;
    displayName?: string | null;
    faviconUrl?: string | null;
    totalKeywords?: number | null;
    dateAdded: string;
    pausedChangeDate?: string | null;
    includeSubdomains: boolean;
    shareOfVoicePercentage: boolean;
    exactMatch: boolean;
    defaultLocation?: string | null;
    googleBusinessNameList?: Array<string | null> | null;
    twitterHandle?: string | null;
    instagramProfile?: string | null;
    facebookProfile?: string | null;
    tiktokProfile?: string | null;
    linkedinProfile?: string | null;
    client: { __typename?: 'ClientNode'; name: string; id: string };
    defaultCountrylocale?: { __typename?: 'CountryLocaleNode'; id: string } | null;
  } | null> | null;
};

export type PausedDomainFragment = {
  __typename?: 'DomainNode';
  id: string;
  domain: string;
  displayName?: string | null;
  faviconUrl?: string | null;
  totalKeywords?: number | null;
  dateAdded: string;
  pausedChangeDate?: string | null;
  includeSubdomains: boolean;
  shareOfVoicePercentage: boolean;
  exactMatch: boolean;
  defaultLocation?: string | null;
  googleBusinessNameList?: Array<string | null> | null;
  twitterHandle?: string | null;
  instagramProfile?: string | null;
  facebookProfile?: string | null;
  tiktokProfile?: string | null;
  linkedinProfile?: string | null;
  client: { __typename?: 'ClientNode'; name: string; id: string };
  defaultCountrylocale?: { __typename?: 'CountryLocaleNode'; id: string } | null;
};

export type PausedDomainsDeleteDomainMutationVariables = Exact<{
  input: DeleteDomainInput;
}>;

export type PausedDomainsDeleteDomainMutation = {
  __typename?: 'Mutations';
  deleteDomain?: {
    __typename?: 'DeleteDomain';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type ChangeUserPasswordMutationVariables = Exact<{
  updatePasswordInput: UpdateMyUserPasswordInput;
}>;

export type ChangeUserPasswordMutation = {
  __typename?: 'Mutations';
  updateMyUserPassword?: {
    __typename?: 'UpdateMyUserPassword';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type EditProfileUserSocialConnectionsQueryVariables = Exact<{ [key: string]: never }>;

export type EditProfileUserSocialConnectionsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    hasOtpDevice?: boolean | null;
    socialAuths?: {
      __typename?: 'UserSocialAuthsNode';
      google?: boolean | null;
      linkedin?: boolean | null;
      microsoft?: boolean | null;
      facebook?: boolean | null;
    } | null;
  } | null;
};

export type EditProfileApiTokenQueryVariables = Exact<{ [key: string]: never }>;

export type EditProfileApiTokenQuery = {
  __typename?: 'Query';
  user?: { __typename?: 'UserNode'; id?: string | null; apiToken?: string | null } | null;
};

export type EditProfileDisconnectSocialAuthMutationVariables = Exact<{
  input: UserSocialAuthDisconnectInput;
}>;

export type EditProfileDisconnectSocialAuthMutation = {
  __typename?: 'Mutations';
  userSocialAuthDisconnect?: {
    __typename?: 'UserSocialAuthDisconnect';
    user?: { __typename?: 'UserNode'; id?: string | null } | null;
  } | null;
};

export type EditUserProfileMutationVariables = Exact<{
  updateMyUserInput: UpdateMyUserInput;
}>;

export type EditUserProfileMutation = {
  __typename?: 'Mutations';
  updateMyUser?: {
    __typename?: 'UpdateMyUser';
    user?: {
      __typename?: 'UserNode';
      id?: string | null;
      fullName?: string | null;
      email?: string | null;
      language?: string | null;
      defaultKeywordsPage?: string | null;
      defaultCompareTo?: string | null;
    } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type EditProfileResetApiTokenMutationVariables = Exact<{
  updateMyUserInput: UpdateMyUserInput;
}>;

export type EditProfileResetApiTokenMutation = {
  __typename?: 'Mutations';
  updateMyUser?: {
    __typename?: 'UpdateMyUser';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type EditProfileRemoveDriveConnectionMutationVariables = Exact<{ [key: string]: never }>;

export type EditProfileRemoveDriveConnectionMutation = {
  __typename?: 'Mutations';
  removeDriveConnection?: {
    __typename?: 'RemoveDriveConnection';
    user?: {
      __typename?: 'UserNode';
      id?: string | null;
      googleConnections?: Array<{
        __typename?: 'GoogleOAuthConnectionNode';
        id?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PublicOverviewHistoryGraphsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  domainSlug: Scalars['String']['input'];
  viewkey: Scalars['String']['input'];
  charts?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type PublicOverviewHistoryGraphsQuery = {
  __typename?: 'Query';
  graphs: { __typename?: 'GraphsNode'; publicOverviewHistoryGraphs?: any | null };
};

export type PublicOverviewSearchIntentQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  domainSlug: Scalars['String']['input'];
  viewkey: Scalars['String']['input'];
}>;

export type PublicOverviewSearchIntentQuery = {
  __typename?: 'Query';
  graphs: {
    __typename?: 'GraphsNode';
    publicOverviewSearchIntent: {
      __typename?: 'OverviewSearchIntentGraphNode';
      I: number;
      N: number;
      T: number;
      C: number;
      keywordCount: number;
    };
  };
};

export type GetAllPublicKpisQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  viewkey: Scalars['String']['input'];
  domainSlug: Scalars['String']['input'];
}>;

export type GetAllPublicKpisQuery = {
  __typename?: 'Query';
  kpis?: {
    __typename?: 'KpisNode';
    displayCurrency?: string | null;
    allKpis?: {
      __typename?: 'AllKpis';
      keywords?: number | null;
      keywordsBefore?: number | null;
      trafficValue?: number | null;
      trafficValueBefore?: number | null;
      maxTrafficValue?: number | null;
      shareOfVoiceSum?: number | null;
      shareOfVoiceSumBefore?: number | null;
      maxShareOfVoiceSum?: number | null;
      aiShareOfVoiceSum?: number | null;
      aiShareOfVoiceSumBefore?: number | null;
      maxAiShareOfVoiceSum?: number | null;
      avgRank?: number | null;
      avgRankBefore?: number | null;
      aboveTheFold?: number | null;
      aboveTheFoldBefore?: number | null;
      ctr?: number | null;
      ctrBefore?: number | null;
      maxCtr?: number | null;
      aiTrafficValue?: number | null;
      aiTrafficValueBefore?: number | null;
      maxAiTrafficValue?: number | null;
      avgBaseRank?: number | null;
      avgBaseRankBefore?: number | null;
      avgMaxBaseRank?: number | null;
      pixelsFromTop?: number | null;
      pixelsFromTopBefore?: number | null;
      searchVolume?: number | null;
      searchVolumeBefore?: number | null;
      aiSearchVolume?: number | null;
      aiSearchVolumeBefore?: number | null;
      top1?: number | null;
      top1Before?: number | null;
      top3?: number | null;
      top3Before?: number | null;
      firstPage?: number | null;
      firstPageBefore?: number | null;
      movedUp?: number | null;
      movedDown?: number | null;
    } | null;
  } | null;
};

export type MetaDataQueryVariables = Exact<{ [key: string]: never }>;

export type MetaDataQuery = {
  __typename?: 'Query';
  metaData?: {
    __typename?: 'MetaDataNode';
    countrylocales?: Array<{
      __typename?: 'MetaDataCountrylocaleNode';
      id?: number | null;
      countryCode?: string | null;
      locale?: string | null;
      region?: string | null;
    } | null> | null;
    searchEngines?: Array<{
      __typename?: 'MetaDataSearchEngineNode';
      id?: number | null;
      name?: string | null;
      icon?: string | null;
    } | null> | null;
    searchTypes?: Array<{
      __typename?: 'MetaDataSearchTypeNode';
      id?: number | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type PublicMetaDataFragment = {
  __typename?: 'MetaDataNode';
  countrylocales?: Array<{
    __typename?: 'MetaDataCountrylocaleNode';
    id?: number | null;
    countryCode?: string | null;
    locale?: string | null;
    region?: string | null;
  } | null> | null;
  searchEngines?: Array<{
    __typename?: 'MetaDataSearchEngineNode';
    id?: number | null;
    name?: string | null;
    icon?: string | null;
  } | null> | null;
  searchTypes?: Array<{
    __typename?: 'MetaDataSearchTypeNode';
    id?: number | null;
    name?: string | null;
  } | null> | null;
};

export type GetPublicReportSettingsQueryVariables = Exact<{
  viewkey: Scalars['String']['input'];
  domainSlug: Scalars['String']['input'];
}>;

export type GetPublicReportSettingsQuery = {
  __typename?: 'Query';
  publicReportSettings?: {
    __typename?: 'PublicReportSettingsNode';
    id: string;
    defaultStartDate?: string | null;
    description?: string | null;
    useAiShareOfVoice: boolean;
    includeAverageRankChart: boolean;
    includeRankingDistributionChart: boolean;
    includeSearchIntentChart: boolean;
    includeKpiTop1: boolean;
    includeKpiTop3: boolean;
    includeKpiFirstPage: boolean;
    includeKpiMovedUp: boolean;
    includeKpiMovedDown: boolean;
    includeKpiKeywords: boolean;
    includeKpiAvgRank: boolean;
    includeKpiAvgCtr: boolean;
    includeKpiSov: boolean;
    includeKpiTrafficValue: boolean;
    includeKpiAboveTheFold: boolean;
    includeAdvancedTableColumns: boolean;
    domain?: { __typename?: 'DomainNode'; id: string } | null;
    group?: { __typename?: 'ClientNode'; id: string } | null;
  } | null;
};

export type PublicReportSettingsFragment = {
  __typename?: 'PublicReportSettingsNode';
  id: string;
  defaultStartDate?: string | null;
  description?: string | null;
  useAiShareOfVoice: boolean;
  includeAverageRankChart: boolean;
  includeRankingDistributionChart: boolean;
  includeSearchIntentChart: boolean;
  includeKpiTop1: boolean;
  includeKpiTop3: boolean;
  includeKpiFirstPage: boolean;
  includeKpiMovedUp: boolean;
  includeKpiMovedDown: boolean;
  includeKpiKeywords: boolean;
  includeKpiAvgRank: boolean;
  includeKpiAvgCtr: boolean;
  includeKpiSov: boolean;
  includeKpiTrafficValue: boolean;
  includeKpiAboveTheFold: boolean;
  includeAdvancedTableColumns: boolean;
  domain?: { __typename?: 'DomainNode'; id: string } | null;
  group?: { __typename?: 'ClientNode'; id: string } | null;
};

export type UpdatePublicReportSettingsMutationVariables = Exact<{
  input: PublicReportSettingsInput;
}>;

export type UpdatePublicReportSettingsMutation = {
  __typename?: 'Mutations';
  updatePublicReportSettings?: {
    __typename?: 'UpdatePublicReportSettings';
    publicReportSettings?: { __typename?: 'PublicReportSettingsNode'; id: string } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type PublicDomainQueryVariables = Exact<{
  viewkey: Scalars['String']['input'];
  domainSlug: Scalars['String']['input'];
}>;

export type PublicDomainQuery = {
  __typename?: 'Query';
  publicDomain?: {
    __typename?: 'DomainNode';
    id: string;
    firstRefreshAt?: string | null;
    lastFullScrape?: string | null;
    lastScraped: string;
    lastFullScrapeCompleted?: string | null;
    displayName?: string | null;
    domain: string;
    client: {
      __typename?: 'ClientNode';
      organization: { __typename?: 'OrganizationNode'; logo?: string | null };
    };
  } | null;
};

export type PublicDomainFragment = {
  __typename?: 'DomainNode';
  id: string;
  firstRefreshAt?: string | null;
  lastFullScrape?: string | null;
  lastScraped: string;
  lastFullScrapeCompleted?: string | null;
  displayName?: string | null;
  domain: string;
  client: {
    __typename?: 'ClientNode';
    organization: { __typename?: 'OrganizationNode'; logo?: string | null };
  };
};

export type GetPublicKeywordHistoryQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  viewkey: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  keywordId: Scalars['ID']['input'];
}>;

export type GetPublicKeywordHistoryQuery = {
  __typename?: 'Query';
  graphs: { __typename?: 'GraphsNode'; publicKeywordHistory?: any | null };
};

export type GetPublicTableKeywordsQueryVariables = Exact<{
  filters: Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>;
  pagination: PaginationInput;
  ordering: OrderingInput;
  viewkey: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  withSearchEngineId: Scalars['Boolean']['input'];
  withSearchTypeId: Scalars['Boolean']['input'];
  withSearchIntent: Scalars['Boolean']['input'];
  withCountrylocaleId: Scalars['Boolean']['input'];
  withLocation: Scalars['Boolean']['input'];
  withRankId: Scalars['Boolean']['input'];
  withRankValue: Scalars['Boolean']['input'];
  withRankCompare: Scalars['Boolean']['input'];
  withSearchVolumeValue: Scalars['Boolean']['input'];
  withValidForSearchVolume: Scalars['Boolean']['input'];
  withDynamicCtr: Scalars['Boolean']['input'];
}>;

export type GetPublicTableKeywordsQuery = {
  __typename?: 'Query';
  publicTableKeywords?: {
    __typename?: 'TableKeywordListNode';
    keywords?: any | null;
    pagination?: {
      __typename?: 'Pagination';
      numResults?: number | null;
      startIndex?: number | null;
      stopIndex?: number | null;
    } | null;
  } | null;
};

export type RegisterPagePayNowPlanQueryVariables = Exact<{ [key: string]: never }>;

export type RegisterPagePayNowPlanQuery = {
  __typename?: 'Query';
  payNowPlan?: {
    __typename?: 'PricingPlanNode';
    id: string;
    priceMonthly?: number | null;
    maxKeywords: number;
    category?: string | null;
    signonDiscount?: number | null;
    signonDiscountMonths?: number | null;
    currency?: string | null;
    nextPlanAfterTrial?: {
      __typename: 'PricingPlanNode';
      priceMonthly?: number | null;
      maxKeywords: number;
      category?: string | null;
      signonDiscountUsd?: string | null;
      signonDiscountEur?: string | null;
      signonDiscountDkk?: string | null;
      signonDiscountMonths?: number | null;
    } | null;
  } | null;
};

export type RegisterPageUnconfirmedUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RegisterPageUnconfirmedUserQuery = {
  __typename?: 'Query';
  unconfirmedUser?: {
    __typename?: 'UnconfirmedUserNode';
    id: string;
    email: string;
    createdByCountry?: string | null;
    createdByIp?: string | null;
    signupType: UsersUnconfirmedUserSignupTypeChoices;
    trialDays?: string | null;
    phoneNumber?: string | null;
    phoneNumberVerificationSid?: string | null;
    phoneNumberVerified?: string | null;
    prefillFullName?: string | null;
    isSocialSignup: boolean;
    referer?: string | null;
  } | null;
};

export type RegisterPagePricingPlanQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RegisterPagePricingPlanQuery = {
  __typename?: 'Query';
  pricingPlan?: {
    __typename?: 'PricingPlanNode';
    id: string;
    priceMonthly?: number | null;
    maxKeywords: number;
    category?: string | null;
    signonDiscount?: number | null;
    signonDiscountMonths?: number | null;
    currency?: string | null;
    nextPlanAfterTrial?: {
      __typename?: 'PricingPlanNode';
      priceMonthly?: number | null;
      maxKeywords: number;
      category?: string | null;
      signonDiscountUsd?: string | null;
      signonDiscountEur?: string | null;
      signonDiscountDkk?: string | null;
      signonDiscountMonths?: number | null;
    } | null;
  } | null;
};

export type RegisterPageCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type RegisterPageCountriesQuery = {
  __typename?: 'Query';
  countries?: Array<{
    __typename?: 'CountryNode';
    id?: string | null;
    name?: string | null;
    isEu?: boolean | null;
    vatCode?: string | null;
  } | null> | null;
};

export type RegisterPageAccountInfoUserLogoutMutationVariables = Exact<{ [key: string]: never }>;

export type RegisterPageAccountInfoUserLogoutMutation = {
  __typename?: 'Mutations';
  userLogout?: { __typename?: 'Logout'; success?: boolean | null } | null;
};

export type RegisterPageUserRegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;

export type RegisterPageUserRegisterMutation = {
  __typename?: 'Mutations';
  userRegister?: {
    __typename?: 'Register';
    token?: string | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type UpdateWizardAccountInfoMutationVariables = Exact<{
  input: RegisterCompleteWizardInput;
}>;

export type UpdateWizardAccountInfoMutation = {
  __typename?: 'Mutations';
  userRegisterCompleteWizard?: {
    __typename?: 'RegisterCompleteWizard';
    token?: string | null;
    domainId?: number | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
    user?: {
      __typename?: 'UserNode';
      id?: string | null;
      organization?: {
        __typename?: 'OrganizationNode';
        id: string;
        wizardShouldBeShown?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type AddReportTemplateMutationVariables = Exact<{
  input: AddReportTemplateInput;
}>;

export type AddReportTemplateMutation = {
  __typename?: 'Mutations';
  addReportTemplate?: {
    __typename?: 'AddReportTemplate';
    reportTemplate?: { __typename?: 'ReportTemplateNode'; id: string } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type EditReportTemplateQueryVariables = Exact<{ [key: string]: never }>;

export type EditReportTemplateQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: { __typename?: 'OrganizationNode'; id: string } | null;
  } | null;
};

export type ReportTemplateByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ReportTemplateByIdQuery = {
  __typename?: 'Query';
  reportTemplate?: {
    __typename?: 'ReportTemplateNode';
    id: string;
    name: string;
    systemTemplate: boolean;
    brandColor?: string | null;
    template: string;
  } | null;
};

export type ReportTemplateUserOrgQueryVariables = Exact<{ [key: string]: never }>;

export type ReportTemplateUserOrgQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: { __typename?: 'OrganizationNode'; id: string } | null;
  } | null;
};

export type UpdateReportTemplateMutationVariables = Exact<{
  input: UpdateReportTemplateInput;
}>;

export type UpdateReportTemplateMutation = {
  __typename?: 'Mutations';
  updateReportTemplate?: {
    __typename?: 'UpdateReportTemplate';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type DefaultReportTemplateQueryVariables = Exact<{ [key: string]: never }>;

export type DefaultReportTemplateQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      defaultReportTemplate?: { __typename?: 'ReportTemplateNode'; id: string } | null;
    } | null;
  } | null;
};

export type ReportTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type ReportTemplatesQuery = {
  __typename?: 'Query';
  reportTemplates?: Array<{
    __typename?: 'ReportTemplateNode';
    id: string;
    brandColor?: string | null;
    name: string;
    template: string;
    systemTemplate: boolean;
    defaultSystemTemplate: boolean;
    isOrganizationDefaultTemplate?: boolean | null;
  } | null> | null;
};

export type ReportTemplateFragment = {
  __typename?: 'ReportTemplateNode';
  id: string;
  brandColor?: string | null;
  name: string;
  template: string;
  systemTemplate: boolean;
  defaultSystemTemplate: boolean;
  isOrganizationDefaultTemplate?: boolean | null;
};

export type ReportWidgetsQueryVariables = Exact<{ [key: string]: never }>;

export type ReportWidgetsQuery = { __typename?: 'Query'; reportWidgets?: string | null };

export type DeleteGeneratedReportMutationVariables = Exact<{
  input: UpdateGeneratedReportInput;
}>;

export type DeleteGeneratedReportMutation = {
  __typename?: 'Mutations';
  updateGeneratedReport?: {
    __typename?: 'UpdateGeneratedReport';
    generatedReport?: { __typename?: 'GeneratedReportNode'; id: string } | null;
  } | null;
};

export type GeneratedReportsQueryVariables = Exact<{ [key: string]: never }>;

export type GeneratedReportsQuery = {
  __typename?: 'Query';
  generatedReports?: Array<{
    __typename?: 'GeneratedReportNode';
    id: string;
    createdAt: string;
    url?: string | null;
    scheduledReportNew?: {
      __typename?: 'ScheduledReportNode';
      isGroupReport: boolean;
      withHistory: boolean;
      reportType: ReportsScheduledReportNewReportTypeChoices;
      domain?: {
        __typename?: 'DomainNode';
        id: string;
        domain: string;
        displayName?: string | null;
      } | null;
      group?: { __typename?: 'ClientNode'; id: string; name: string } | null;
    } | null;
  } | null> | null;
};

export type GeneratedReportFragment = {
  __typename?: 'GeneratedReportNode';
  id: string;
  createdAt: string;
  url?: string | null;
  scheduledReportNew?: {
    __typename?: 'ScheduledReportNode';
    isGroupReport: boolean;
    withHistory: boolean;
    reportType: ReportsScheduledReportNewReportTypeChoices;
    domain?: {
      __typename?: 'DomainNode';
      id: string;
      domain: string;
      displayName?: string | null;
    } | null;
    group?: { __typename?: 'ClientNode'; id: string; name: string } | null;
  } | null;
};

export type DeleteScheduledReportMutationVariables = Exact<{
  input: DeleteScheduledReportInput;
}>;

export type DeleteScheduledReportMutation = {
  __typename?: 'Mutations';
  deleteScheduledReport?: {
    __typename?: 'DeleteScheduledReport';
    scheduledReport?: { __typename?: 'ScheduledReportNode'; id: string } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type ScheduledReportsQueryVariables = Exact<{ [key: string]: never }>;

export type ScheduledReportsQuery = {
  __typename?: 'Query';
  scheduledReports?: Array<{
    __typename?: 'ScheduledReportNode';
    id: string;
    schedule: ReportsScheduledReportNewScheduleChoices;
    scheduledDay?: number | null;
    reportType: ReportsScheduledReportNewReportTypeChoices;
    name: string;
    description: string;
    isGroupReport: boolean;
    isOneTimeReport: boolean;
    language: string;
    emailSubject: string;
    emailBody: string;
    sender: string;
    recipients: Array<string>;
    domain?: {
      __typename?: 'DomainNode';
      id: string;
      domain: string;
      displayName?: string | null;
    } | null;
    group?: { __typename?: 'ClientNode'; id: string; name: string } | null;
    reportTemplate?: {
      __typename?: 'ReportTemplateNode';
      id: string;
      systemTemplate: boolean;
      name: string;
    } | null;
    reportFilter?: { __typename?: 'ReportFilterNode'; id: string; filters: string } | null;
  } | null> | null;
};

export type ScheduledReportFragment = {
  __typename?: 'ScheduledReportNode';
  id: string;
  schedule: ReportsScheduledReportNewScheduleChoices;
  scheduledDay?: number | null;
  reportType: ReportsScheduledReportNewReportTypeChoices;
  name: string;
  description: string;
  isGroupReport: boolean;
  isOneTimeReport: boolean;
  language: string;
  emailSubject: string;
  emailBody: string;
  sender: string;
  recipients: Array<string>;
  domain?: {
    __typename?: 'DomainNode';
    id: string;
    domain: string;
    displayName?: string | null;
  } | null;
  group?: { __typename?: 'ClientNode'; id: string; name: string } | null;
  reportTemplate?: {
    __typename?: 'ReportTemplateNode';
    id: string;
    systemTemplate: boolean;
    name: string;
  } | null;
  reportFilter?: { __typename?: 'ReportFilterNode'; id: string; filters: string } | null;
};

export type CreatePlanMutationVariables = Exact<{
  input: CreatePlanInput;
}>;

export type CreatePlanMutation = {
  __typename?: 'Mutations';
  createPlan?: {
    __typename?: 'CreatePlan';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
    plan?: { __typename?: 'PricingPlanNode'; id: string } | null;
  } | null;
};

export type AdminPlanCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type AdminPlanCategoriesQuery = {
  __typename?: 'Query';
  adminPlanCategories?: Array<{
    __typename?: 'PlanCategoryNode';
    id: string;
    name: string;
  } | null> | null;
};

export type AdminPublicPlansQueryVariables = Exact<{ [key: string]: never }>;

export type AdminPublicPlansQuery = {
  __typename?: 'Query';
  adminPublicPlans?: Array<{
    __typename?: 'PricingPlanNode';
    id: string;
    name: string;
    priceMonthlyUsd: string;
    priceMonthlyEur?: string | null;
    priceMonthlyDkk?: string | null;
    priceYearlyUsd: string;
    priceYearlyEur?: string | null;
    priceYearlyDkk?: string | null;
    isFree: boolean;
    category?: string | null;
    isTrial: boolean;
    maxKeywords: number;
    maxDomains: number;
    maxUsers: number;
    maxCompetitors: number;
    maxNumbersOfWorkspaces: number;
    signonDiscountUsd?: string | null;
    signonDiscountEur?: string | null;
    signonDiscountDkk?: string | null;
    signonDiscountMonths?: number | null;
    featureApiAccess: boolean;
    featureApiV4WriteAccess: boolean;
    featureCompetitorMonitoring: boolean;
    featureAnalyticsIntegration: boolean;
    featureSearchVolume: boolean;
    featureReporting: boolean;
    featureKeywordRefresh: boolean;
    featureAdvancedReporting: boolean;
    featureCanPause: boolean;
    featureSocial: boolean;
    featureAdvancedMetrics: boolean;
    isPublicPlan: boolean;
    isDefaultTrial: boolean;
    isCustomPlan: boolean;
    comment?: string | null;
    dealStartDate?: string | null;
    dealEndDate?: string | null;
    showCountdown: boolean;
    message?: string | null;
    validForNewOnly: boolean;
  } | null> | null;
};

export type PublicPlanFragment = {
  __typename?: 'PricingPlanNode';
  id: string;
  name: string;
  priceMonthlyUsd: string;
  priceMonthlyEur?: string | null;
  priceMonthlyDkk?: string | null;
  priceYearlyUsd: string;
  priceYearlyEur?: string | null;
  priceYearlyDkk?: string | null;
  isFree: boolean;
  category?: string | null;
  isTrial: boolean;
  maxKeywords: number;
  maxDomains: number;
  maxUsers: number;
  maxCompetitors: number;
  maxNumbersOfWorkspaces: number;
  signonDiscountUsd?: string | null;
  signonDiscountEur?: string | null;
  signonDiscountDkk?: string | null;
  signonDiscountMonths?: number | null;
  featureApiAccess: boolean;
  featureApiV4WriteAccess: boolean;
  featureCompetitorMonitoring: boolean;
  featureAnalyticsIntegration: boolean;
  featureSearchVolume: boolean;
  featureReporting: boolean;
  featureKeywordRefresh: boolean;
  featureAdvancedReporting: boolean;
  featureCanPause: boolean;
  featureSocial: boolean;
  featureAdvancedMetrics: boolean;
  isPublicPlan: boolean;
  isDefaultTrial: boolean;
  isCustomPlan: boolean;
  comment?: string | null;
  dealStartDate?: string | null;
  dealEndDate?: string | null;
  showCountdown: boolean;
  message?: string | null;
  validForNewOnly: boolean;
};

export type AdminSalesManagersQueryVariables = Exact<{ [key: string]: never }>;

export type AdminSalesManagersQuery = {
  __typename?: 'Query';
  adminSalesManagers?: Array<{
    __typename?: 'SalesManagerNode';
    id: string;
    name: string;
    active: boolean;
  } | null> | null;
};

export type SalesManagerFragment = {
  __typename?: 'SalesManagerNode';
  id: string;
  name: string;
  active: boolean;
};

export type AdminOrganizationsQueryVariables = Exact<{
  salesManagerId?: InputMaybe<Scalars['ID']['input']>;
  getAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  dataType?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<FilterInput>> | InputMaybe<FilterInput>>;
  ordering?: InputMaybe<OrderingInput>;
  pagination?: InputMaybe<PaginationInput>;
}>;

export type AdminOrganizationsQuery = {
  __typename?: 'Query';
  adminOrganizations?: {
    __typename?: 'AdminOrganizationNode';
    data?: Array<{
      __typename?: 'OrganizationNode';
      id: string;
      name: string;
      dateAdded: string;
      active: boolean;
      additionalData?: string | null;
      salesManager?: {
        __typename?: 'SalesManagerNode';
        id: string;
        name: string;
        user?: { __typename?: 'UserNode'; email?: string | null } | null;
      } | null;
    } | null> | null;
    pagination?: {
      __typename?: 'Pagination';
      numResults?: number | null;
      startIndex?: number | null;
      stopIndex?: number | null;
    } | null;
  } | null;
};

export type AdminOrganizationFragment = {
  __typename?: 'OrganizationNode';
  id: string;
  name: string;
  dateAdded: string;
  active: boolean;
  additionalData?: string | null;
  salesManager?: {
    __typename?: 'SalesManagerNode';
    id: string;
    name: string;
    user?: { __typename?: 'UserNode'; email?: string | null } | null;
  } | null;
};

export type SetSalesManagersMutationVariables = Exact<{
  input: SetSalesManagerInput;
}>;

export type SetSalesManagersMutation = {
  __typename?: 'Mutations';
  setSalesManager?: {
    __typename?: 'SetSalesManager';
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      salesManager?: {
        __typename?: 'SalesManagerNode';
        id: string;
        user?: { __typename?: 'UserNode'; id?: string | null; email?: string | null } | null;
      } | null;
    } | null;
  } | null;
};

export type InvoiceAdminOrganizationsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type InvoiceAdminOrganizationsQuery = {
  __typename?: 'Query';
  adminInvoiceCustomers?: Array<{
    __typename?: 'PaymentContactNode';
    id: string;
    paymentTerms?: number | null;
    unpaidInvoices?: number | null;
    totalInvoices?: number | null;
    overdueInvoices?: number | null;
    organization: {
      __typename?: 'OrganizationNode';
      id: string;
      name: string;
      dateAdded: string;
      active: boolean;
    };
  } | null> | null;
};

export type InvoiceAdminOrganizationFragment = {
  __typename?: 'PaymentContactNode';
  id: string;
  paymentTerms?: number | null;
  unpaidInvoices?: number | null;
  totalInvoices?: number | null;
  overdueInvoices?: number | null;
  organization: {
    __typename?: 'OrganizationNode';
    id: string;
    name: string;
    dateAdded: string;
    active: boolean;
  };
};

export type SalesMailsTableQueryVariables = Exact<{
  startIndex: Scalars['Int']['input'];
  stopIndex: Scalars['Int']['input'];
}>;

export type SalesMailsTableQuery = {
  __typename?: 'Query';
  adminLoggedEmailCount?: number | null;
  adminLoggedEmail?: Array<{
    __typename?: 'LoggedEmailNode';
    id: string;
    createdAt: string;
    messageId: string;
    subject?: string | null;
    recipients: Array<string>;
    fromEmail?: string | null;
    replyTo?: Array<string> | null;
    mtaAccept?: boolean | null;
    mtaError?: string | null;
    notificationsTypes: Array<string>;
    notifications: Array<string>;
    opened: boolean;
  } | null> | null;
};

export type HistoricKeywordUsageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type HistoricKeywordUsageQuery = {
  __typename?: 'Query';
  adminOrganization?: {
    __typename?: 'OrganizationNode';
    historicKeywordUsages?: any | null;
  } | null;
};

export type SalesOrgSetSalesManagerMutationVariables = Exact<{
  input: SetSalesManagerInput;
}>;

export type SalesOrgSetSalesManagerMutation = {
  __typename?: 'Mutations';
  setSalesManager?: {
    __typename?: 'SetSalesManager';
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      salesManager?: {
        __typename?: 'SalesManagerNode';
        id: string;
        user?: { __typename?: 'UserNode'; id?: string | null; email?: string | null } | null;
      } | null;
    } | null;
  } | null;
};

export type SalesOrgAdminOrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SalesOrgAdminOrganizationQuery = {
  __typename?: 'Query';
  adminOrganization?: {
    __typename?: 'OrganizationNode';
    id: string;
    name: string;
    active: boolean;
    slug: string;
    dateAdded: string;
    isPartner?: boolean | null;
    isAffiliate?: boolean | null;
    hubspotLink?: string | null;
    keywordOveruseEnabled: boolean;
    paymentcontact?: {
      __typename?: 'PaymentContactNode';
      id: string;
      companyName: string;
      paymentMethod?: BillingPaymentContactPaymentMethodChoices | null;
      vatNo?: string | null;
      vatNumber?: string | null;
    } | null;
    organizationClients?: Array<{
      __typename?: 'ClientNode';
      id: string;
      name: string;
      dateAdded: string;
      domains?: Array<{
        __typename?: 'DomainNode';
        id: string;
        domain: string;
        displayName?: string | null;
        dateAdded: string;
        totalKeywords?: number | null;
        adobeSuiteId?: string | null;
        googleOauthConnectionGa?: {
          __typename?: 'GoogleOAuthConnectionNode';
          id?: string | null;
        } | null;
        googleOauthConnectionGsc?: {
          __typename?: 'GoogleOAuthConnectionNode';
          id?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
    users?: Array<{
      __typename?: 'UserNode';
      id?: string | null;
      dateJoined?: string | null;
      email?: string | null;
      fullName?: string | null;
      userType?: number | null;
      isAccurankerSuperuser?: boolean | null;
      hasOtpDevice?: boolean | null;
      salesManager?: { __typename?: 'SalesManagerNode'; id: string } | null;
    } | null> | null;
    invoices?: Array<{
      __typename?: 'InvoiceNode';
      id: string;
      number?: number | null;
      createdAt: string;
      dueDate?: string | null;
      paid: boolean;
      currency: string;
      vat: string;
      amount: string;
      total: string;
      url?: string | null;
      emailMessageIds?: Array<string> | null;
      payment?: { __typename?: 'PaymentNode'; refundedDate?: string | null } | null;
      creditedInvoice?: { __typename?: 'InvoiceNode'; id: string; number?: number | null } | null;
    } | null> | null;
    multiAccountLinks?: Array<{
      __typename?: 'MultiAccountLinkNode';
      id: string;
      isOrgAdmin: boolean;
      fromOrganizationPays: boolean;
      fromOrganization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
    } | null> | null;
    multiAccounts?: Array<{
      __typename?: 'MultiAccountLinkNode';
      id: string;
      isOrgAdmin: boolean;
      createdAt: string;
      fromOrganizationPays: boolean;
      fromOrganizationDiscountType?: OrganizationsMultiAccountLinkFromOrganizationDiscountTypeChoices | null;
      fromOrganizationDiscountPercent?: number | null;
      toOrganization?: { __typename?: 'OrganizationNode'; id: string; name: string } | null;
    } | null> | null;
    plans?: Array<{
      __typename?: 'OrganizationPlanNode';
      id: string;
      active: boolean;
      startDate: string;
      endDate: string;
      name: string;
      priceMonthly?: number | null;
      priceYearly?: number | null;
      priceMonthlyUsd: string;
      priceYearlyUsd: string;
      currency?: string | null;
      isTrial: boolean;
      maxKeywords: number;
      billingCycleInMonths: number;
      maxDomains: number;
      maxUsers: number;
      maxCompetitors: number;
      maxNumbersOfWorkspaces: number;
      signonDiscountUsd?: string | null;
      signonDiscountEur?: string | null;
      signonDiscountDkk?: string | null;
      signonDiscountMonths?: number | null;
      signonDiscountMonthsUsed: number;
      featureApiAccess: boolean;
      featureApiV4WriteAccess?: boolean | null;
      featureAdvancedMetrics: boolean;
      featureCompetitorMonitoring: boolean;
      featureAnalyticsIntegration: boolean;
      featureSearchVolume: boolean;
      featureReporting: boolean;
      featureKeywordRefresh: boolean;
      featureAdvancedReporting: boolean;
      featureCanPause: boolean;
      featureSocial: boolean;
      featureYandex: boolean;
      featureBaidu: boolean;
      featureYoutube: boolean;
      featureYoutubeDailyVideosLimit: number;
      featureNaver: boolean;
      featureAccuApi: boolean;
      accuApiCredits?: number | null;
      cancellationNoticeInDays?: number | null;
      commitmentMonths?: number | null;
    } | null> | null;
    trialAbuseLog?: Array<{
      __typename?: 'TrialAbuseLogNode';
      id: string;
      createdAt: string;
      description: string;
      value: number;
    } | null> | null;
    salesManager?: {
      __typename?: 'SalesManagerNode';
      id: string;
      name: string;
      user?: {
        __typename?: 'UserNode';
        id?: string | null;
        fullName?: string | null;
        email?: string | null;
      } | null;
    } | null;
    failedPayments?: Array<{
      __typename?: 'FailedPaymentNode';
      id: string;
      createdAt: string;
      reason: string;
    } | null> | null;
    acceptedOveruse?: {
      __typename?: 'AcceptedOveruseNode';
      userId?: number | null;
      name?: string | null;
      multiAccountLink?: number | null;
    } | null;
    organizationNotes: Array<{
      __typename?: 'OrganizationNoteNode';
      id: string;
      createdAt: string;
      createdBy?: string | null;
      lastEditedAt?: string | null;
      message: string;
    }>;
  } | null;
};

export type OrganizationNoteFragment = {
  __typename?: 'OrganizationNoteNode';
  id: string;
  createdAt: string;
  createdBy?: string | null;
  lastEditedAt?: string | null;
  message: string;
};

export type OrganizationPlanFragment = {
  __typename?: 'OrganizationPlanNode';
  id: string;
  active: boolean;
  startDate: string;
  endDate: string;
  name: string;
  priceMonthly?: number | null;
  priceYearly?: number | null;
  priceMonthlyUsd: string;
  priceYearlyUsd: string;
  currency?: string | null;
  isTrial: boolean;
  maxKeywords: number;
  billingCycleInMonths: number;
  maxDomains: number;
  maxUsers: number;
  maxCompetitors: number;
  maxNumbersOfWorkspaces: number;
  signonDiscountUsd?: string | null;
  signonDiscountEur?: string | null;
  signonDiscountDkk?: string | null;
  signonDiscountMonths?: number | null;
  signonDiscountMonthsUsed: number;
  featureApiAccess: boolean;
  featureApiV4WriteAccess?: boolean | null;
  featureAdvancedMetrics: boolean;
  featureCompetitorMonitoring: boolean;
  featureAnalyticsIntegration: boolean;
  featureSearchVolume: boolean;
  featureReporting: boolean;
  featureKeywordRefresh: boolean;
  featureAdvancedReporting: boolean;
  featureCanPause: boolean;
  featureSocial: boolean;
  featureYandex: boolean;
  featureBaidu: boolean;
  featureYoutube: boolean;
  featureYoutubeDailyVideosLimit: number;
  featureNaver: boolean;
  featureAccuApi: boolean;
  accuApiCredits?: number | null;
  cancellationNoticeInDays?: number | null;
  commitmentMonths?: number | null;
};

export type SalesOrgSoftDeleteUserMutationVariables = Exact<{
  input: SoftDeleteUserInput;
}>;

export type SalesOrgSoftDeleteUserMutation = {
  __typename?: 'Mutations';
  softDeleteUser?: {
    __typename?: 'SoftDeleteUser';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type SalesOrgAdminSalesManagersQueryVariables = Exact<{ [key: string]: never }>;

export type SalesOrgAdminSalesManagersQuery = {
  __typename?: 'Query';
  adminSalesManagers?: Array<{
    __typename?: 'SalesManagerNode';
    id: string;
    name: string;
    active: boolean;
  } | null> | null;
};

export type SalesPlansTableAdminPlansQueryVariables = Exact<{ [key: string]: never }>;

export type SalesPlansTableAdminPlansQuery = {
  __typename?: 'Query';
  adminPlans?: Array<{
    __typename?: 'PricingPlanNode';
    id: string;
    name: string;
    priceMonthlyUsd: string;
    priceYearlyUsd: string;
    priceMonthlyEur?: string | null;
    priceYearlyEur?: string | null;
    priceMonthlyDkk?: string | null;
    priceYearlyDkk?: string | null;
    comment?: string | null;
    subscribers?: number | null;
  } | null> | null;
};

export type AdminPlanFragment = {
  __typename?: 'PricingPlanNode';
  id: string;
  name: string;
  priceMonthlyUsd: string;
  priceYearlyUsd: string;
  priceMonthlyEur?: string | null;
  priceYearlyEur?: string | null;
  priceMonthlyDkk?: string | null;
  priceYearlyDkk?: string | null;
  comment?: string | null;
  subscribers?: number | null;
};

export type ReconcileUnmatchedInvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type ReconcileUnmatchedInvoicesQuery = {
  __typename?: 'Query';
  salesUnmatchedInvoices: Array<{
    __typename?: 'InvoiceNode';
    id: string;
    number?: number | null;
    amount: string;
    vat: string;
    total: string;
    vatRate: number;
    currency: string;
    createdAt: string;
    dueDate?: string | null;
    organization: { __typename?: 'OrganizationNode'; name: string };
    paymentContact: {
      __typename?: 'PaymentContactNode';
      companyName: string;
      eanNo?: string | null;
    };
  }>;
};

export type ReconcileUnmatchedWisePaymentsQueryVariables = Exact<{ [key: string]: never }>;

export type ReconcileUnmatchedWisePaymentsQuery = {
  __typename?: 'Query';
  salesUnmatchedWisePayments: Array<{
    __typename?: 'WisePaymentNode';
    id: string;
    createdAt: string;
    sender: string;
    reference: string;
    amount: string;
    currency: string;
    reconciled: boolean;
  }>;
};

export type ReconcileSetInvoicePaidMutationVariables = Exact<{
  invoiceId?: InputMaybe<Scalars['UUID']['input']>;
  paymentId: Scalars['Int']['input'];
}>;

export type ReconcileSetInvoicePaidMutation = {
  __typename?: 'Mutations';
  setInvoicePaid?: {
    __typename?: 'SetInvoicePaid';
    invoice?: { __typename?: 'InvoiceNode'; paid: boolean } | null;
  } | null;
};

export type SetEanInvoicePaidMutationVariables = Exact<{
  invoiceId: Scalars['UUID']['input'];
}>;

export type SetEanInvoicePaidMutation = {
  __typename?: 'Mutations';
  setEanInvoicePaid?: {
    __typename?: 'SetEanInvoicePaid';
    invoice?: { __typename?: 'InvoiceNode'; paid: boolean } | null;
  } | null;
};

export type SalesSearchAdminSearchQueryVariables = Exact<{
  q: Scalars['String']['input'];
  types: Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>;
}>;

export type SalesSearchAdminSearchQuery = {
  __typename?: 'Query';
  adminSearch?: Array<{
    __typename?: 'AdminSearchNode';
    objId?: string | null;
    name?: string | null;
    type?: number | null;
    data?: string | null;
  } | null> | null;
};

export type SalesTrialsDomainsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SalesTrialsDomainsQuery = {
  __typename?: 'Query';
  adminOrganization?: {
    __typename?: 'OrganizationNode';
    id: string;
    organizationClients?: Array<{
      __typename?: 'ClientNode';
      id: string;
      name: string;
      domains?: Array<{
        __typename?: 'DomainNode';
        id: string;
        domain: string;
        displayName?: string | null;
        totalKeywords?: number | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type SalesTrialsTableQueryVariables = Exact<{
  input: SalesDashboardInput;
}>;

export type SalesTrialsTableQuery = {
  __typename?: 'Query';
  adminSalesDashboard?: {
    __typename?: 'SalesDashboardNode';
    organizations?: Array<{
      __typename?: 'SalesDashboardOrganizationNode';
      subAccount?: boolean | null;
      abuse?: number | null;
      domains?: number | null;
      keywords?: number | null;
      inDialog?: boolean | null;
      isRanktracker?: boolean | null;
      organization?: {
        __typename?: 'OrganizationNode';
        active: boolean;
        id: string;
        phoneNumber?: string | null;
        dateAdded: string;
        name: string;
        slug: string;
        accountBlocked: boolean;
        leadScore?: number | null;
        country?: { __typename?: 'CountryNode'; name?: string | null; id?: string | null } | null;
        salesManager?: {
          __typename?: 'SalesManagerNode';
          id: string;
          name: string;
          user?: { __typename?: 'UserNode'; email?: string | null } | null;
        } | null;
      } | null;
      organizationSettings?: {
        __typename?: 'OrganizationSettingsNode';
        companyType?: OrganizationsOrganizationSettingsCompanyTypeChoices | null;
        companySize?: OrganizationsOrganizationSettingsCompanySizeChoices | null;
        keywordsSize: number;
      } | null;
      user?: { __typename?: 'UserNode'; email?: string | null } | null;
      affiliateVisitor?: { __typename?: 'VisitorNode'; id: string; origin?: string | null } | null;
      latestPlan?: {
        __typename?: 'OrganizationPlanNode';
        id: string;
        endDate: string;
        isTrial: boolean;
        maxKeywords: number;
      } | null;
    } | null> | null;
  } | null;
};

export type SalesTrialsOrganizationFragment = {
  __typename?: 'SalesDashboardOrganizationNode';
  subAccount?: boolean | null;
  abuse?: number | null;
  domains?: number | null;
  keywords?: number | null;
  inDialog?: boolean | null;
  isRanktracker?: boolean | null;
  organization?: {
    __typename?: 'OrganizationNode';
    active: boolean;
    id: string;
    phoneNumber?: string | null;
    dateAdded: string;
    name: string;
    slug: string;
    accountBlocked: boolean;
    leadScore?: number | null;
    country?: { __typename?: 'CountryNode'; name?: string | null; id?: string | null } | null;
    salesManager?: {
      __typename?: 'SalesManagerNode';
      id: string;
      name: string;
      user?: { __typename?: 'UserNode'; email?: string | null } | null;
    } | null;
  } | null;
  organizationSettings?: {
    __typename?: 'OrganizationSettingsNode';
    companyType?: OrganizationsOrganizationSettingsCompanyTypeChoices | null;
    companySize?: OrganizationsOrganizationSettingsCompanySizeChoices | null;
    keywordsSize: number;
  } | null;
  user?: { __typename?: 'UserNode'; email?: string | null } | null;
  affiliateVisitor?: { __typename?: 'VisitorNode'; id: string; origin?: string | null } | null;
  latestPlan?: {
    __typename?: 'OrganizationPlanNode';
    id: string;
    endDate: string;
    isTrial: boolean;
    maxKeywords: number;
  } | null;
};

export type SetLeadScoreMutationVariables = Exact<{
  input: SetLeadScoreInput;
}>;

export type SetLeadScoreMutation = {
  __typename?: 'Mutations';
  setLeadScore?: {
    __typename?: 'SetLeadScore';
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      leadScore?: number | null;
    } | null;
  } | null;
};

export type SetSalesManagerMutationVariables = Exact<{
  input: SetSalesManagerInput;
}>;

export type SetSalesManagerMutation = {
  __typename?: 'Mutations';
  setSalesManager?: {
    __typename?: 'SetSalesManager';
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      salesManager?: {
        __typename?: 'SalesManagerNode';
        id: string;
        name: string;
        user?: { __typename?: 'UserNode'; id?: string | null; email?: string | null } | null;
      } | null;
    } | null;
  } | null;
};

export type CreateCustomTrialMutationVariables = Exact<{
  input: CreateCustomTrialInput;
}>;

export type CreateCustomTrialMutation = {
  __typename?: 'Mutations';
  createCustomTrial?: {
    __typename?: 'CreateCustomTrial';
    unconfirmedUser?: { __typename?: 'UnconfirmedUserNode'; email: string } | null;
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type PublicReportDomainPublicUrlQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PublicReportDomainPublicUrlQuery = {
  __typename?: 'Query';
  domain?: { __typename?: 'DomainNode'; id: string; publicUrl?: string | null } | null;
};

export type PublicReportRequestDomainPublicUrlMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PublicReportRequestDomainPublicUrlMutation = {
  __typename?: 'Mutations';
  resetViewkeyDomain?: {
    __typename?: 'ResetViewKeyDomain';
    domain?: { __typename?: 'DomainNode'; publicUrl?: string | null } | null;
  } | null;
};

export type PublicReportClientPublicUrlQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PublicReportClientPublicUrlQuery = {
  __typename?: 'Query';
  client?: { __typename?: 'ClientNode'; id: string; publicUrl?: string | null } | null;
};

export type PublicReportRequestClientPublicUrlMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PublicReportRequestClientPublicUrlMutation = {
  __typename?: 'Mutations';
  resetViewkeyClient?: {
    __typename?: 'ResetViewKeyClient';
    client?: { __typename?: 'ClientNode'; publicUrl?: string | null } | null;
  } | null;
};

export type ScheduledReportQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ScheduledReportQuery = {
  __typename?: 'Query';
  scheduledReport?: {
    __typename?: 'ScheduledReportNode';
    id: string;
    name: string;
    description: string;
    reportType: ReportsScheduledReportNewReportTypeChoices;
    recipients: Array<string>;
    sender: string;
    language: string;
    emailSubject: string;
    emailBody: string;
    isGroupReport: boolean;
    schedule: ReportsScheduledReportNewScheduleChoices;
    scheduledDay?: number | null;
    domain?: {
      __typename?: 'DomainNode';
      id: string;
      domain: string;
      displayName?: string | null;
    } | null;
    group?: { __typename?: 'ClientNode'; id: string; name: string } | null;
    reportFilter?: { __typename?: 'ReportFilterNode'; id: string; filters: string } | null;
    reportTemplate?: { __typename?: 'ReportTemplateNode'; id: string; name: string } | null;
  } | null;
};

export type UpdateScheduledReportMutationVariables = Exact<{
  input: UpdateScheduledReportInput;
}>;

export type UpdateScheduledReportMutation = {
  __typename?: 'Mutations';
  updateScheduledReport?: {
    __typename?: 'UpdateScheduledReport';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type UsersQueryVariables = Exact<{ [key: string]: never }>;

export type UsersQuery = {
  __typename?: 'Query';
  users?: Array<{
    __typename?: 'UserNode';
    id?: string | null;
    fullName?: string | null;
    email?: string | null;
    userType?: number | null;
    permissionsEditedAt?: string | null;
    dateJoined?: string | null;
    workspaces?: Array<{ __typename?: 'WorkspaceNode'; id: string } | null> | null;
  } | null> | null;
};

export type UserFragment = {
  __typename?: 'UserNode';
  id?: string | null;
  fullName?: string | null;
  email?: string | null;
  userType?: number | null;
  permissionsEditedAt?: string | null;
  dateJoined?: string | null;
  workspaces?: Array<{ __typename?: 'WorkspaceNode'; id: string } | null> | null;
};

export type TotalUsersQueryVariables = Exact<{ [key: string]: never }>;

export type TotalUsersQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    organization?: {
      __typename?: 'OrganizationNode';
      numberOfUsers?: number | null;
      activePlan?: { __typename?: 'OrganizationPlanNode'; maxUsers: number } | null;
    } | null;
  } | null;
};

export type EditUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;

export type EditUserMutation = {
  __typename?: 'Mutations';
  updateUser?: {
    __typename?: 'UpdateUser';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export type WalletsQueryVariables = Exact<{ [key: string]: never }>;

export type WalletsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      affiliate?: {
        __typename?: 'AffiliateNode';
        id: string;
        wallet?: Array<{
          __typename?: 'WalletNode';
          id: string;
          amount: string;
          currency: BillingWalletCurrencyChoices;
          description: string;
          walletType?: BillingWalletWalletTypeChoices | null;
          createdAt: string;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type WalletFragment = {
  __typename?: 'WalletNode';
  id: string;
  amount: string;
  currency: BillingWalletCurrencyChoices;
  description: string;
  walletType?: BillingWalletWalletTypeChoices | null;
  createdAt: string;
};

export type TotalWorkspacesQueryVariables = Exact<{ [key: string]: never }>;

export type TotalWorkspacesQuery = {
  __typename?: 'Query';
  workspacesByOrganization?: Array<{ __typename?: 'WorkspaceNode'; id: string } | null> | null;
  user?: {
    __typename?: 'UserNode';
    organization?: {
      __typename?: 'OrganizationNode';
      activePlan?: { __typename?: 'OrganizationPlanNode'; maxNumbersOfWorkspaces: number } | null;
    } | null;
  } | null;
};

export type GetWorkspacesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorkspacesQuery = {
  __typename?: 'Query';
  workspacesByOrganization?: Array<{
    __typename?: 'WorkspaceNode';
    id: string;
    name: string;
    description?: string | null;
    lastEdited?: string | null;
    clients?: Array<{ __typename?: 'ClientNode'; id: string } | null> | null;
    users?: Array<{ __typename?: 'UserNode'; id?: string | null } | null> | null;
  } | null> | null;
};

export type WorkspaceFragment = {
  __typename?: 'WorkspaceNode';
  id: string;
  name: string;
  description?: string | null;
  lastEdited?: string | null;
  clients?: Array<{ __typename?: 'ClientNode'; id: string } | null> | null;
  users?: Array<{ __typename?: 'UserNode'; id?: string | null } | null> | null;
};

export type DeleteWorkspaceMutationVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
}>;

export type DeleteWorkspaceMutation = {
  __typename?: 'Mutations';
  deleteWorkspace?: {
    __typename?: 'DeleteWorkspace';
    errors?: Array<{ __typename?: 'Error'; messages: Array<string> } | null> | null;
  } | null;
};

export type ImportExamplePageMappableColumnsQueryVariables = Exact<{ [key: string]: never }>;

export type ImportExamplePageMappableColumnsQuery = {
  __typename?: 'Query';
  mappableColumns?: Array<{
    __typename?: 'ImportMappableColumnNode';
    id: number;
    name: string;
    type: string;
    description: string;
    examples: Array<string>;
    required: boolean;
  } | null> | null;
};

export type StoreDomainListQueryVariables = Exact<{ [key: string]: never }>;

export type StoreDomainListQuery = {
  __typename?: 'Query';
  domainsList?: Array<{ __typename?: 'DomainNode'; id: string } | null> | null;
  metaData?: {
    __typename?: 'MetaDataNode';
    serpFeatures?: Array<{
      __typename?: 'MetaDataSerpFeatureNode';
      id?: string | null;
      owned?: string | null;
      label?: string | null;
      url?: string | null;
      description?: string | null;
      naverSupport?: boolean | null;
      googleSupport?: boolean | null;
      youtubeSupport?: boolean | null;
      naverDescription?: string | null;
    } | null> | null;
    countrylocales?: Array<{
      __typename?: 'MetaDataCountrylocaleNode';
      id?: number | null;
      countryCode?: string | null;
      locale?: string | null;
      region?: string | null;
    } | null> | null;
    searchEngines?: Array<{
      __typename?: 'MetaDataSearchEngineNode';
      id?: number | null;
      name?: string | null;
      icon?: string | null;
    } | null> | null;
    searchTypes?: Array<{
      __typename?: 'MetaDataSearchTypeNode';
      id?: number | null;
      name?: string | null;
    } | null> | null;
  } | null;
};

export type MetaDataFragment = {
  __typename?: 'MetaDataNode';
  serpFeatures?: Array<{
    __typename?: 'MetaDataSerpFeatureNode';
    id?: string | null;
    owned?: string | null;
    label?: string | null;
    url?: string | null;
    description?: string | null;
    naverSupport?: boolean | null;
    googleSupport?: boolean | null;
    youtubeSupport?: boolean | null;
    naverDescription?: string | null;
  } | null> | null;
  countrylocales?: Array<{
    __typename?: 'MetaDataCountrylocaleNode';
    id?: number | null;
    countryCode?: string | null;
    locale?: string | null;
    region?: string | null;
  } | null> | null;
  searchEngines?: Array<{
    __typename?: 'MetaDataSearchEngineNode';
    id?: number | null;
    name?: string | null;
    icon?: string | null;
  } | null> | null;
  searchTypes?: Array<{
    __typename?: 'MetaDataSearchTypeNode';
    id?: number | null;
    name?: string | null;
  } | null> | null;
};

export type MetaDataCountrylocaleFragment = {
  __typename?: 'MetaDataCountrylocaleNode';
  id?: number | null;
  countryCode?: string | null;
  locale?: string | null;
  region?: string | null;
};

export type SearchEngineFragment = {
  __typename?: 'MetaDataSearchEngineNode';
  id?: number | null;
  name?: string | null;
  icon?: string | null;
};

export type SearchTypeFragment = {
  __typename?: 'MetaDataSearchTypeNode';
  id?: number | null;
  name?: string | null;
};

export type GenericSubscribeSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GenericSubscribeSubscription = {
  __typename?: 'Subscriptions';
  liveChanges?: {
    __typename?: 'SubscriptionNode';
    id?: string | null;
    obj?: string | null;
    action?: string | null;
  } | null;
};

export type GenericGetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GenericGetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserNode';
    id?: string | null;
    isAuthenticated?: boolean | null;
    email?: string | null;
    fullName?: string | null;
    intercomHash?: string | null;
    dateJoined?: string | null;
    userType?: number | null;
    isImpersonating?: boolean | null;
    isOnMultiaccount?: boolean | null;
    isAffiliateAdmin?: boolean | null;
    shouldRecordSession?: boolean | null;
    language?: string | null;
    isAccurankerSuperuser?: boolean | null;
    isCfo?: boolean | null;
    isAffiliate?: boolean | null;
    defaultKeywordsColumns?: string | null;
    keywordsGroupOrder?: string | null;
    defaultCompetitorsColumns?: string | null;
    defaultLandingPagesColumns?: string | null;
    defaultTagCloudColumns?: string | null;
    defaultFoldersColumns?: string | null;
    defaultKeydisColumns?: string | null;
    defaultNotesColumns?: string | null;
    defaultKeywordsNotificationsColumns?: string | null;
    defaultHomeColumns?: string | null;
    defaultKeywordsPage?: string | null;
    defaultKeywordsOverviewVersion?: string | null;
    defaultCompareTo?: string | null;
    defaultOverviewCharts?: any | null;
    unconfirmedUserId?: string | null;
    impersonateOriginUser?: {
      __typename?: 'UserNode';
      isAccurankerSuperuser?: boolean | null;
    } | null;
    salesManager?: { __typename?: 'SalesManagerNode'; id: string } | null;
    workspaces?: Array<{ __typename?: 'WorkspaceNode'; isAllGroups: boolean } | null> | null;
    organization?: {
      __typename?: 'OrganizationNode';
      id: string;
      name: string;
      dealType?: string | null;
      wizardShouldBeShown?: boolean | null;
      wizardProgress?: number | null;
      createdByCountry?: string | null;
      type?: string | null;
      active: boolean;
      accountBlocked: boolean;
      isPartner?: boolean | null;
      dateAdded: string;
      phoneNumber?: string | null;
      numberOfUsers?: number | null;
      numberOfCompetitors?: number | null;
      domainWithHighestCompetitors?: number | null;
      numberOfDomains?: number | null;
      numberOfDomainsWithGa?: number | null;
      numberOfDomainsWithGwt?: number | null;
      hasValidGscConnection?: boolean | null;
      numberOfKeywords?: number | null;
      keywordOveruseEnabled: boolean;
      showKeywordUsage?: boolean | null;
      displayCurrency?: string | null;
      showLocalSearchVolume: boolean;
      primaryCountryCurrency?: string | null;
      logo?: string | null;
      unrankedWeight?: number | null;
      accountBlockedReason?: Array<{
        __typename?: 'OrganizationBlockedNode';
        title?: string | null;
        message?: string | null;
      } | null> | null;
      activePlan?: {
        __typename?: 'OrganizationPlanNode';
        id: string;
        name: string;
        isTrial: boolean;
        isFree: boolean;
        category?: string | null;
        isPrepaidVoucher?: boolean | null;
        priceMonthly?: number | null;
        priceYearly?: number | null;
        currency?: string | null;
        startDate: string;
        endDate: string;
        endDateIfCancelledNow?: string | null;
        maxUsers: number;
        maxKeywords: number;
        maxCompetitors: number;
        billingCycleInMonths: number;
        featureCanPause: boolean;
        featureAdvancedMetrics: boolean;
        featureYandex: boolean;
        featureBaidu: boolean;
        featureYoutube: boolean;
        featureNaver: boolean;
        minutesBetweenRefresh?: number | null;
        hasKeydisAccess: boolean;
        featureAccuApi: boolean;
        originPlan?: { __typename?: 'PricingPlanNode'; id: string } | null;
      } | null;
      nextPlan?: { __typename?: 'OrganizationPlanNode'; id: string } | null;
      errors?: {
        __typename?: 'OrganizationErrorsNode';
        failedPayment?: boolean | null;
        trialExpired?: boolean | null;
        planExpired?: boolean | null;
        keywordRefreshDisabled?: boolean | null;
        systemHealthNotice?: number | null;
        invoiceStatus?: number | null;
      } | null;
      multiAccountOwners?: Array<{ __typename?: 'MultiAccountLinkNode'; id: string } | null> | null;
      salesManager?: { __typename?: 'SalesManagerNode'; meetingLink?: string | null } | null;
    } | null;
    savedFilters?: Array<{
      __typename?: 'UserFilterNode';
      id: string;
      name: string;
      type: UsersUserFilterTypeChoices;
      defaultForDomains: boolean;
      defaultForKeywords?: Array<string | null> | null;
      filters: string;
    } | null> | null;
    googleConnections?: Array<{
      __typename?: 'GoogleOAuthConnectionNode';
      id?: string | null;
    } | null> | null;
  } | null;
};

export type OrganizationActivePlanFragment = {
  __typename?: 'OrganizationPlanNode';
  id: string;
  name: string;
  isTrial: boolean;
  isFree: boolean;
  category?: string | null;
  isPrepaidVoucher?: boolean | null;
  priceMonthly?: number | null;
  priceYearly?: number | null;
  currency?: string | null;
  startDate: string;
  endDate: string;
  endDateIfCancelledNow?: string | null;
  maxUsers: number;
  maxKeywords: number;
  maxCompetitors: number;
  billingCycleInMonths: number;
  featureCanPause: boolean;
  featureAdvancedMetrics: boolean;
  featureYandex: boolean;
  featureBaidu: boolean;
  featureYoutube: boolean;
  featureNaver: boolean;
  minutesBetweenRefresh?: number | null;
  hasKeydisAccess: boolean;
  featureAccuApi: boolean;
  originPlan?: { __typename?: 'PricingPlanNode'; id: string } | null;
};

export type Generic_UpdateUserSettingsMutationVariables = Exact<{
  input: UpdateUserSettingsInput;
}>;

export type Generic_UpdateUserSettingsMutation = {
  __typename?: 'Mutations';
  updateUserSettings?: {
    __typename?: 'UpdateUserSettings';
    user?: {
      __typename?: 'UserNode';
      id?: string | null;
      defaultKeywordsColumns?: string | null;
      defaultCompetitorsColumns?: string | null;
      defaultLandingPagesColumns?: string | null;
      defaultTagCloudColumns?: string | null;
      defaultFoldersColumns?: string | null;
      defaultNotesColumns?: string | null;
      defaultKeywordsNotificationsColumns?: string | null;
      defaultKeywordsPage?: string | null;
      defaultKeywordsOverviewVersion?: string | null;
      defaultCompareTo?: string | null;
    } | null;
  } | null;
};

export type DomainInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
}>;

export type DomainInfoQuery = {
  __typename?: 'Query';
  domain?: {
    __typename?: 'DomainNode';
    id: string;
    isDemoDomain: boolean;
    domain: string;
    domainType: DomainsDomainDomainTypeChoices;
    displayName?: string | null;
    youtubeChannelName?: string | null;
    canRefresh?: boolean | null;
    canUpdate?: boolean | null;
    lastManualRefreshAt?: string | null;
    firstRefreshAt?: string | null;
    lastScrapedCompleted?: string | null;
    lastFullScrape?: string | null;
    lastFullScrapeCompleted?: string | null;
    lastScraped: string;
    shareOfVoicePercentage: boolean;
    gscSiteId?: string | null;
    gaPropertyId?: string | null;
    gaAccountId?: string | null;
    competitors?: Array<{
      __typename?: 'CompetitorNode';
      displayName?: string | null;
      domain?: string | null;
      youtubeChannelName?: string | null;
    } | null> | null;
    defaultSearchSettings?: Array<{
      __typename?: 'DefaultSearchSettingNode';
      countrylocale: { __typename?: 'CountryLocaleNode'; id: string };
    } | null> | null;
    googleOauthConnectionGa?: {
      __typename?: 'GoogleOAuthConnectionNode';
      id?: string | null;
      description?: string | null;
    } | null;
    googleOauthConnectionGsc?: {
      __typename?: 'GoogleOAuthConnectionNode';
      id?: string | null;
      description?: string | null;
    } | null;
    adobeMarketingConnection?: {
      __typename?: 'AdobeMarketingConnectionNode';
      id: string;
      description: string;
    } | null;
    demoDomainBannerInfo?: {
      __typename?: 'DemoDomainInfo';
      domainCount?: number | null;
      domainId?: string | null;
      domainName?: string | null;
    } | null;
  } | null;
};

export type DomainInfoDomainFragment = {
  __typename?: 'DomainNode';
  id: string;
  isDemoDomain: boolean;
  domain: string;
  domainType: DomainsDomainDomainTypeChoices;
  displayName?: string | null;
  youtubeChannelName?: string | null;
  canRefresh?: boolean | null;
  canUpdate?: boolean | null;
  lastManualRefreshAt?: string | null;
  firstRefreshAt?: string | null;
  lastScrapedCompleted?: string | null;
  lastFullScrape?: string | null;
  lastFullScrapeCompleted?: string | null;
  lastScraped: string;
  shareOfVoicePercentage: boolean;
  gscSiteId?: string | null;
  gaPropertyId?: string | null;
  gaAccountId?: string | null;
  competitors?: Array<{
    __typename?: 'CompetitorNode';
    displayName?: string | null;
    domain?: string | null;
    youtubeChannelName?: string | null;
  } | null> | null;
  defaultSearchSettings?: Array<{
    __typename?: 'DefaultSearchSettingNode';
    countrylocale: { __typename?: 'CountryLocaleNode'; id: string };
  } | null> | null;
  googleOauthConnectionGa?: {
    __typename?: 'GoogleOAuthConnectionNode';
    id?: string | null;
    description?: string | null;
  } | null;
  googleOauthConnectionGsc?: {
    __typename?: 'GoogleOAuthConnectionNode';
    id?: string | null;
    description?: string | null;
  } | null;
  adobeMarketingConnection?: {
    __typename?: 'AdobeMarketingConnectionNode';
    id: string;
    description: string;
  } | null;
  demoDomainBannerInfo?: {
    __typename?: 'DemoDomainInfo';
    domainCount?: number | null;
    domainId?: string | null;
    domainName?: string | null;
  } | null;
};

export type MixpanelTrackEventMutationVariables = Exact<{
  input: AccurankerAnalyticsInput;
}>;

export type MixpanelTrackEventMutation = {
  __typename?: 'Mutations';
  accurankerAnalytics?: {
    __typename?: 'AccurankerAnalytics';
    errors?: Array<{ __typename?: 'Error'; field: string; messages: Array<string> } | null> | null;
  } | null;
};

export const CountrylocaleEditorFragmentDoc = gql`
  fragment CountrylocaleEditor on CountryLocaleNode {
    id
    countryCode
    region
    locale
  }
`;
export const LinkedOrganizationFragmentDoc = gql`
  fragment linkedOrganization on OrganizationNode {
    id
    name
    multiAccountLink {
      id
    }
  }
`;
export const CountrylocaleFragmentDoc = gql`
  fragment Countrylocale on CountryLocaleNode {
    id
    countryCode
    localeShort
    region
    locale
    yandexSupport
    googleSupport
    bingSupport
    baiduSupport
    youtubeSupport
  }
`;
export const PendingRequestFragmentDoc = gql`
  fragment PendingRequest on MultiAccountLinkNode {
    id
    toEmail
    isOrgAdmin
    createdAt
  }
`;
export const VisitorPaymentFragmentDoc = gql`
  fragment VisitorPayment on VisitorPaymentNode {
    id
    createdAt
    amount
    currency
    payment {
      paymentType
    }
    paid
  }
`;
export const AffiliateVisitorFragmentDoc = gql`
  fragment AffiliateVisitor on VisitorNode {
    id
    referrer
    totalCommission
    organization {
      id
      dateAdded
      activePlan {
        id
        name
        isTrial
        currency
      }
    }
  }
`;
export const ReferrerFragmentDoc = gql`
  fragment Referrer on ReferrerNode {
    url
    amount
  }
`;
export const GoogleConnectionFragmentDoc = gql`
  fragment GoogleConnection on GoogleOAuthConnectionNode {
    id
    description
    createdAt
    createdBy
    type
  }
`;
export const ErrorFragmentDoc = gql`
  fragment Error on Error {
    field
    messages
  }
`;
export const MultiAccountOwnerFragmentDoc = gql`
  fragment multiAccountOwner on MultiAccountLinkNode {
    id
    fromOrganization {
      id
      name
    }
    fromOrganizationPays
    isOrgAdmin
    isHidden
    canImpersonate
  }
`;
export const PendingMultiAccountOwnerFragmentDoc = gql`
  fragment pendingMultiAccountOwner on MultiAccountLinkNode {
    id
    fromOrganization {
      name
    }
    fromOrganizationPays
    isOrgAdmin
  }
`;
export const ApiFilterFragmentDoc = gql`
  fragment ApiFilter on UserFilterNode {
    id
    name
    createdAt
    filters
  }
`;
export const ChartsFragmentDoc = gql`
  fragment Charts on ChartsNode {
    serpFeatureOverview {
      adsTop
      adsInBetween
      adsBottom
      shopping
      mapsLocalTeaser
      mapsLocal
      relatedQuestions
      carousel
      imagePack
      reviews
      tweets
      siteLinks
      featureSnippet
      knowledgePanel
      knowledgePanelPriceComparison
      knowledgeCards
      video
      thumbnails
      breadcrumbs
      https
      directAnswer
      jobPosting
      siteLinksSearchbox
      thingsTodo
      featuredVideo
      faq
      tripsPopular
      podcasts
      aiOverview
      relatedSearchesCarousel
      mostPopularCarousel
      refineCarousel
      resultsAbout
      apps
      searchByPhotos
      discoverMorePlaces
      flights
      events
      organicStores
      popularStores
      richProductInfo
      filterMenu
      mapsLocalTeaserOwned
      mapsLocalOwned
      relatedQuestionsOwned
      reviewsOwned
      tweetsOwned
      siteLinksOwned
      featureSnippetOwned
      knowledgePanelOwned
      knowledgePanelPriceComparisonOwned
      videoOwned
      thumbnailsOwned
      breadcrumbsOwned
      httpsOwned
      siteLinksSearchboxOwned
      faqOwned
      featuredVideoOwned
      recipesOwned
      organicStoresOwned
      popularStoresOwned
      richProductInfoOwned
      newsPosition
      newsPositionOwned
      videoCarouselPosition
      videoCarouselPositionOwned
      recipesPosition
      recipesPositionOwned
      researchProductPosition
      researchProductPositionOwned
      hotelsPosition
      hotelsPositionOwned
      forumPostPosition
      forumPostPositionOwned
      imageProductPackPosition
      imageProductPackPositionOwned
      shopStyles
      shopStylesOwned
      youtubeAds
      youtubePromotedVideo
      youtubePromotedVideoOwned
      youtubeRelatedSearches
      youtubeRelatedToYourSearch
      youtubeShelfRender
      youtubeRelatedMovies
      youtubeSecondarySearchContainer
      youtubeChannel
      youtubeVideo
      youtubeShow
      youtubePlaylist
      youtubeMovie
      youtubeLiveStream
      youtubeRadio
      youtubeChannelOwned
      youtubeVideoOwned
      youtubeShowOwned
      youtubePlaylistOwned
      youtubeMovieOwned
      youtubeLiveStreamOwned
      youtubeRadioOwned
      keywordCount
    }
  }
`;
export const PausedDomainFragmentDoc = gql`
  fragment PausedDomain on DomainNode {
    id
    domain
    displayName
    faviconUrl
    totalKeywords
    dateAdded
    pausedChangeDate
    client {
      name
    }
    includeSubdomains
    shareOfVoicePercentage
    exactMatch
    defaultCountrylocale {
      id
    }
    client {
      id
    }
    defaultLocation
    googleBusinessNameList
    twitterHandle
    instagramProfile
    facebookProfile
    tiktokProfile
    linkedinProfile
  }
`;
export const MetaDataCountrylocaleFragmentDoc = gql`
  fragment MetaDataCountrylocale on MetaDataCountrylocaleNode {
    id
    countryCode
    locale
    region
  }
`;
export const SearchEngineFragmentDoc = gql`
  fragment SearchEngine on MetaDataSearchEngineNode {
    id
    name
    icon
  }
`;
export const SearchTypeFragmentDoc = gql`
  fragment SearchType on MetaDataSearchTypeNode {
    id
    name
  }
`;
export const PublicMetaDataFragmentDoc = gql`
  fragment PublicMetaData on MetaDataNode {
    countrylocales {
      ...MetaDataCountrylocale
    }
    searchEngines {
      ...SearchEngine
    }
    searchTypes {
      ...SearchType
    }
  }
  ${MetaDataCountrylocaleFragmentDoc}
  ${SearchEngineFragmentDoc}
  ${SearchTypeFragmentDoc}
`;
export const PublicReportSettingsFragmentDoc = gql`
  fragment PublicReportSettings on PublicReportSettingsNode {
    id
    domain {
      id
    }
    group {
      id
    }
    defaultStartDate
    description
    useAiShareOfVoice
    includeAverageRankChart
    includeRankingDistributionChart
    includeSearchIntentChart
    includeKpiTop1
    includeKpiTop3
    includeKpiFirstPage
    includeKpiMovedUp
    includeKpiMovedDown
    includeKpiKeywords
    includeKpiAvgRank
    includeKpiAvgCtr
    includeKpiSov
    includeKpiTrafficValue
    includeKpiAboveTheFold
    includeAdvancedTableColumns
  }
`;
export const PublicDomainFragmentDoc = gql`
  fragment PublicDomain on DomainNode {
    id
    firstRefreshAt
    lastFullScrape
    lastScraped
    lastFullScrapeCompleted
    displayName
    domain
    client {
      organization {
        logo
      }
    }
  }
`;
export const ReportTemplateFragmentDoc = gql`
  fragment ReportTemplate on ReportTemplateNode {
    id
    brandColor
    name
    template
    systemTemplate
    defaultSystemTemplate
    isOrganizationDefaultTemplate
  }
`;
export const GeneratedReportFragmentDoc = gql`
  fragment GeneratedReport on GeneratedReportNode {
    id
    createdAt
    url
    scheduledReportNew {
      isGroupReport
      withHistory
      domain {
        id
        domain
        displayName
      }
      group {
        id
        name
      }
      reportType
    }
  }
`;
export const ScheduledReportFragmentDoc = gql`
  fragment ScheduledReport on ScheduledReportNode {
    id
    domain {
      id
      domain
      displayName
    }
    group {
      id
      name
    }
    schedule
    scheduledDay
    reportType
    name
    description
    isGroupReport
    isOneTimeReport
    language
    emailSubject
    emailBody
    sender
    recipients
    reportTemplate {
      id
      systemTemplate
      name
    }
    reportFilter {
      id
      filters
    }
  }
`;
export const PublicPlanFragmentDoc = gql`
  fragment PublicPlan on PricingPlanNode {
    id
    name
    priceMonthlyUsd
    priceMonthlyEur
    priceMonthlyDkk
    priceYearlyUsd
    priceYearlyEur
    priceYearlyDkk
    isFree
    category
    isTrial
    maxKeywords
    maxDomains
    maxUsers
    maxCompetitors
    maxNumbersOfWorkspaces
    signonDiscountUsd
    signonDiscountEur
    signonDiscountDkk
    signonDiscountMonths
    featureApiAccess
    featureApiV4WriteAccess
    featureCompetitorMonitoring
    featureAnalyticsIntegration
    featureSearchVolume
    featureReporting
    featureKeywordRefresh
    featureAdvancedReporting
    featureCanPause
    featureSocial
    featureAdvancedMetrics
    isPublicPlan
    isDefaultTrial
    isCustomPlan
    comment
    dealStartDate
    dealEndDate
    showCountdown
    message
    validForNewOnly
  }
`;
export const SalesManagerFragmentDoc = gql`
  fragment SalesManager on SalesManagerNode {
    id
    name
    active
  }
`;
export const AdminOrganizationFragmentDoc = gql`
  fragment AdminOrganization on OrganizationNode {
    id
    name
    dateAdded
    active
    salesManager {
      id
      name
      user {
        email
      }
    }
    additionalData
  }
`;
export const InvoiceAdminOrganizationFragmentDoc = gql`
  fragment InvoiceAdminOrganization on PaymentContactNode {
    id
    paymentTerms
    unpaidInvoices
    totalInvoices
    overdueInvoices
    organization {
      id
      name
      dateAdded
      active
    }
  }
`;
export const OrganizationNoteFragmentDoc = gql`
  fragment OrganizationNote on OrganizationNoteNode {
    id
    createdAt
    createdBy
    lastEditedAt
    message
  }
`;
export const OrganizationPlanFragmentDoc = gql`
  fragment OrganizationPlan on OrganizationPlanNode {
    id
    active
    startDate
    endDate
    name
    priceMonthly
    priceYearly
    priceMonthlyUsd
    priceYearlyUsd
    currency
    isTrial
    maxKeywords
    billingCycleInMonths
    maxDomains
    maxUsers
    maxCompetitors
    maxNumbersOfWorkspaces
    signonDiscountUsd
    signonDiscountEur
    signonDiscountDkk
    signonDiscountMonths
    signonDiscountMonthsUsed
    featureApiAccess
    featureApiV4WriteAccess
    featureAdvancedMetrics
    featureCompetitorMonitoring
    featureAnalyticsIntegration
    featureSearchVolume
    featureReporting
    featureKeywordRefresh
    featureAdvancedReporting
    featureCanPause
    featureSocial
    featureAdvancedMetrics
    featureYandex
    featureBaidu
    featureYoutube
    featureYoutubeDailyVideosLimit
    featureNaver
    featureAccuApi
    accuApiCredits
    cancellationNoticeInDays
    commitmentMonths
  }
`;
export const AdminPlanFragmentDoc = gql`
  fragment AdminPlan on PricingPlanNode {
    id
    name
    priceMonthlyUsd
    priceYearlyUsd
    priceMonthlyEur
    priceYearlyEur
    priceMonthlyDkk
    priceYearlyDkk
    comment
    subscribers
  }
`;
export const SalesTrialsOrganizationFragmentDoc = gql`
  fragment SalesTrialsOrganization on SalesDashboardOrganizationNode {
    subAccount
    organization {
      active
      id
      phoneNumber
      dateAdded
      name
      slug
      accountBlocked
      leadScore
      country {
        name
        id
      }
      salesManager {
        id
        name
        user {
          email
        }
      }
    }
    organizationSettings {
      companyType
      companySize
      keywordsSize
    }
    abuse
    user {
      email
    }
    affiliateVisitor {
      id
      origin
    }
    latestPlan {
      id
      endDate
      isTrial
      maxKeywords
    }
    domains
    keywords
    inDialog
    isRanktracker
  }
`;
export const UserFragmentDoc = gql`
  fragment User on UserNode {
    id
    fullName
    email
    userType
    workspaces {
      id
    }
    permissionsEditedAt
    dateJoined
  }
`;
export const WalletFragmentDoc = gql`
  fragment Wallet on WalletNode {
    id
    amount
    currency
    description
    walletType
    createdAt
  }
`;
export const WorkspaceFragmentDoc = gql`
  fragment Workspace on WorkspaceNode {
    id
    name
    description
    clients {
      id
    }
    lastEdited
    users {
      id
    }
  }
`;
export const MetaDataFragmentDoc = gql`
  fragment MetaData on MetaDataNode {
    serpFeatures {
      id
      owned
      label
      url
      description
      naverSupport
      googleSupport
      youtubeSupport
      naverDescription
    }
    countrylocales {
      ...MetaDataCountrylocale
    }
    searchEngines {
      ...SearchEngine
    }
    searchTypes {
      ...SearchType
    }
  }
  ${MetaDataCountrylocaleFragmentDoc}
  ${SearchEngineFragmentDoc}
  ${SearchTypeFragmentDoc}
`;
export const OrganizationActivePlanFragmentDoc = gql`
  fragment OrganizationActivePlan on OrganizationPlanNode {
    id
    name
    isTrial
    isFree
    category
    originPlan {
      id
    }
    isPrepaidVoucher
    priceMonthly
    priceYearly
    currency
    startDate
    endDate
    endDateIfCancelledNow
    maxUsers
    maxKeywords
    maxCompetitors
    billingCycleInMonths
    featureCanPause
    featureAdvancedMetrics
    featureYandex
    featureBaidu
    featureYoutube
    featureNaver
    minutesBetweenRefresh
    hasKeydisAccess
    featureAccuApi
  }
`;
export const DomainInfoDomainFragmentDoc = gql`
  fragment DomainInfoDomain on DomainNode {
    id
    isDemoDomain
    domain
    domainType
    competitors {
      displayName
      domain
      youtubeChannelName
    }
    displayName
    youtubeChannelName
    defaultSearchSettings {
      countrylocale {
        id
      }
    }
    canRefresh
    canUpdate
    lastManualRefreshAt
    firstRefreshAt
    lastScrapedCompleted
    lastFullScrape
    lastFullScrapeCompleted
    lastScraped
    shareOfVoicePercentage
    gscSiteId
    googleOauthConnectionGa {
      id
      description
    }
    googleOauthConnectionGsc {
      id
      description
    }
    adobeMarketingConnection {
      id
      description
    }
    gaPropertyId
    gaAccountId
    demoDomainBannerInfo {
      domainCount
      domainId
      domainName
    }
  }
`;
export const TitleDescriptionByIdDocument = gql`
  query TitleDescriptionById($titleDescriptionId: Int!) {
    titleDescription(titleDescriptionId: $titleDescriptionId) {
      title
      description
    }
  }
`;

/**
 * __useTitleDescriptionByIdQuery__
 *
 * To run a query within a React component, call `useTitleDescriptionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTitleDescriptionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTitleDescriptionByIdQuery({
 *   variables: {
 *      titleDescriptionId: // value for 'titleDescriptionId'
 *   },
 * });
 */
export function useTitleDescriptionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    TitleDescriptionByIdQuery,
    TitleDescriptionByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TitleDescriptionByIdQuery, TitleDescriptionByIdQueryVariables>(
    TitleDescriptionByIdDocument,
    options,
  );
}
export function useTitleDescriptionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TitleDescriptionByIdQuery,
    TitleDescriptionByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TitleDescriptionByIdQuery, TitleDescriptionByIdQueryVariables>(
    TitleDescriptionByIdDocument,
    options,
  );
}
export type TitleDescriptionByIdQueryHookResult = ReturnType<typeof useTitleDescriptionByIdQuery>;
export type TitleDescriptionByIdLazyQueryHookResult = ReturnType<
  typeof useTitleDescriptionByIdLazyQuery
>;
export type TitleDescriptionByIdQueryResult = Apollo.QueryResult<
  TitleDescriptionByIdQuery,
  TitleDescriptionByIdQueryVariables
>;
export const Top10DomainsDocument = gql`
  query Top10Domains(
    $domainId: Int
    $keywordrankId: BigInt!
    $searchDate: String!
    $keywordId: Int!
  ) {
    top10Domains(
      domainId: $domainId
      keywordrankId: $keywordrankId
      searchDate: $searchDate
      keywordId: $keywordId
    ) {
      domain
      url
      ctr
    }
  }
`;

/**
 * __useTop10DomainsQuery__
 *
 * To run a query within a React component, call `useTop10DomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTop10DomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTop10DomainsQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *      keywordrankId: // value for 'keywordrankId'
 *      searchDate: // value for 'searchDate'
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useTop10DomainsQuery(
  baseOptions: Apollo.QueryHookOptions<Top10DomainsQuery, Top10DomainsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Top10DomainsQuery, Top10DomainsQueryVariables>(
    Top10DomainsDocument,
    options,
  );
}
export function useTop10DomainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Top10DomainsQuery, Top10DomainsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Top10DomainsQuery, Top10DomainsQueryVariables>(
    Top10DomainsDocument,
    options,
  );
}
export type Top10DomainsQueryHookResult = ReturnType<typeof useTop10DomainsQuery>;
export type Top10DomainsLazyQueryHookResult = ReturnType<typeof useTop10DomainsLazyQuery>;
export type Top10DomainsQueryResult = Apollo.QueryResult<
  Top10DomainsQuery,
  Top10DomainsQueryVariables
>;
export const ChartNotesDocument = gql`
  query ChartNotes(
    $filters: [FilterInput]!
    $fakePagination: PaginationInput!
    $fakeOrdering: OrderingInput!
  ) {
    keywords(filters: $filters, pagination: $fakePagination, ordering: $fakeOrdering) {
      notes {
        id
        createdAt
        user {
          id
          fullName
        }
        note
        numberOfKeywords
      }
    }
  }
`;

/**
 * __useChartNotesQuery__
 *
 * To run a query within a React component, call `useChartNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartNotesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      fakePagination: // value for 'fakePagination'
 *      fakeOrdering: // value for 'fakeOrdering'
 *   },
 * });
 */
export function useChartNotesQuery(
  baseOptions: Apollo.QueryHookOptions<ChartNotesQuery, ChartNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChartNotesQuery, ChartNotesQueryVariables>(ChartNotesDocument, options);
}
export function useChartNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChartNotesQuery, ChartNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChartNotesQuery, ChartNotesQueryVariables>(
    ChartNotesDocument,
    options,
  );
}
export type ChartNotesQueryHookResult = ReturnType<typeof useChartNotesQuery>;
export type ChartNotesLazyQueryHookResult = ReturnType<typeof useChartNotesLazyQuery>;
export type ChartNotesQueryResult = Apollo.QueryResult<ChartNotesQuery, ChartNotesQueryVariables>;
export const ChartNotesWithKeywordsDocument = gql`
  query ChartNotesWithKeywords(
    $filters: [FilterInput]!
    $fakePagination: PaginationInput!
    $fakeOrdering: OrderingInput!
    $keywordId: ID!
  ) {
    keywords(filters: $filters, pagination: $fakePagination, ordering: $fakeOrdering) {
      notes(keywordId: $keywordId) {
        id
        createdAt
        user {
          id
          fullName
        }
        keywords {
          id
        }
        note
        numberOfKeywords
      }
    }
  }
`;

/**
 * __useChartNotesWithKeywordsQuery__
 *
 * To run a query within a React component, call `useChartNotesWithKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartNotesWithKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartNotesWithKeywordsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      fakePagination: // value for 'fakePagination'
 *      fakeOrdering: // value for 'fakeOrdering'
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useChartNotesWithKeywordsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChartNotesWithKeywordsQuery,
    ChartNotesWithKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChartNotesWithKeywordsQuery, ChartNotesWithKeywordsQueryVariables>(
    ChartNotesWithKeywordsDocument,
    options,
  );
}
export function useChartNotesWithKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChartNotesWithKeywordsQuery,
    ChartNotesWithKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChartNotesWithKeywordsQuery, ChartNotesWithKeywordsQueryVariables>(
    ChartNotesWithKeywordsDocument,
    options,
  );
}
export type ChartNotesWithKeywordsQueryHookResult = ReturnType<
  typeof useChartNotesWithKeywordsQuery
>;
export type ChartNotesWithKeywordsLazyQueryHookResult = ReturnType<
  typeof useChartNotesWithKeywordsLazyQuery
>;
export type ChartNotesWithKeywordsQueryResult = Apollo.QueryResult<
  ChartNotesWithKeywordsQuery,
  ChartNotesWithKeywordsQueryVariables
>;
export const CountryLocaleCountsDocument = gql`
  query countryLocaleCounts {
    countrylocaleCounts {
      defaultCountrylocaleId
      count
    }
  }
`;

/**
 * __useCountryLocaleCountsQuery__
 *
 * To run a query within a React component, call `useCountryLocaleCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryLocaleCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryLocaleCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountryLocaleCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountryLocaleCountsQuery,
    CountryLocaleCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountryLocaleCountsQuery, CountryLocaleCountsQueryVariables>(
    CountryLocaleCountsDocument,
    options,
  );
}
export function useCountryLocaleCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountryLocaleCountsQuery,
    CountryLocaleCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountryLocaleCountsQuery, CountryLocaleCountsQueryVariables>(
    CountryLocaleCountsDocument,
    options,
  );
}
export type CountryLocaleCountsQueryHookResult = ReturnType<typeof useCountryLocaleCountsQuery>;
export type CountryLocaleCountsLazyQueryHookResult = ReturnType<
  typeof useCountryLocaleCountsLazyQuery
>;
export type CountryLocaleCountsQueryResult = Apollo.QueryResult<
  CountryLocaleCountsQuery,
  CountryLocaleCountsQueryVariables
>;
export const DomainFoldersDocument = gql`
  query DomainFolders($ids: [ID]) {
    domainsList(ids: $ids) {
      folders
    }
  }
`;

/**
 * __useDomainFoldersQuery__
 *
 * To run a query within a React component, call `useDomainFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainFoldersQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDomainFoldersQuery(
  baseOptions?: Apollo.QueryHookOptions<DomainFoldersQuery, DomainFoldersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DomainFoldersQuery, DomainFoldersQueryVariables>(
    DomainFoldersDocument,
    options,
  );
}
export function useDomainFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DomainFoldersQuery, DomainFoldersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DomainFoldersQuery, DomainFoldersQueryVariables>(
    DomainFoldersDocument,
    options,
  );
}
export type DomainFoldersQueryHookResult = ReturnType<typeof useDomainFoldersQuery>;
export type DomainFoldersLazyQueryHookResult = ReturnType<typeof useDomainFoldersLazyQuery>;
export type DomainFoldersQueryResult = Apollo.QueryResult<
  DomainFoldersQuery,
  DomainFoldersQueryVariables
>;
export const DomainTagsDocument = gql`
  query DomainTags($ids: [ID]) {
    domainsList(ids: $ids) {
      id
      tags
      dynamicTags
    }
  }
`;

/**
 * __useDomainTagsQuery__
 *
 * To run a query within a React component, call `useDomainTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainTagsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDomainTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<DomainTagsQuery, DomainTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DomainTagsQuery, DomainTagsQueryVariables>(DomainTagsDocument, options);
}
export function useDomainTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DomainTagsQuery, DomainTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DomainTagsQuery, DomainTagsQueryVariables>(
    DomainTagsDocument,
    options,
  );
}
export type DomainTagsQueryHookResult = ReturnType<typeof useDomainTagsQuery>;
export type DomainTagsLazyQueryHookResult = ReturnType<typeof useDomainTagsLazyQuery>;
export type DomainTagsQueryResult = Apollo.QueryResult<DomainTagsQuery, DomainTagsQueryVariables>;
export const CountrylocaleEditorDocument = gql`
  query CountrylocaleEditor {
    countrylocales {
      ...CountrylocaleEditor
    }
  }
  ${CountrylocaleEditorFragmentDoc}
`;

/**
 * __useCountrylocaleEditorQuery__
 *
 * To run a query within a React component, call `useCountrylocaleEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountrylocaleEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountrylocaleEditorQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountrylocaleEditorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountrylocaleEditorQuery,
    CountrylocaleEditorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountrylocaleEditorQuery, CountrylocaleEditorQueryVariables>(
    CountrylocaleEditorDocument,
    options,
  );
}
export function useCountrylocaleEditorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountrylocaleEditorQuery,
    CountrylocaleEditorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountrylocaleEditorQuery, CountrylocaleEditorQueryVariables>(
    CountrylocaleEditorDocument,
    options,
  );
}
export type CountrylocaleEditorQueryHookResult = ReturnType<typeof useCountrylocaleEditorQuery>;
export type CountrylocaleEditorLazyQueryHookResult = ReturnType<
  typeof useCountrylocaleEditorLazyQuery
>;
export type CountrylocaleEditorQueryResult = Apollo.QueryResult<
  CountrylocaleEditorQuery,
  CountrylocaleEditorQueryVariables
>;
export const GetCountryLocaleDocument = gql`
  query getCountryLocale($ids: [ID]!) {
    countrylocales(ids: $ids) {
      id
      countryCode
      region
      locale
    }
  }
`;

/**
 * __useGetCountryLocaleQuery__
 *
 * To run a query within a React component, call `useGetCountryLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryLocaleQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetCountryLocaleQuery(
  baseOptions: Apollo.QueryHookOptions<GetCountryLocaleQuery, GetCountryLocaleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountryLocaleQuery, GetCountryLocaleQueryVariables>(
    GetCountryLocaleDocument,
    options,
  );
}
export function useGetCountryLocaleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCountryLocaleQuery, GetCountryLocaleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCountryLocaleQuery, GetCountryLocaleQueryVariables>(
    GetCountryLocaleDocument,
    options,
  );
}
export type GetCountryLocaleQueryHookResult = ReturnType<typeof useGetCountryLocaleQuery>;
export type GetCountryLocaleLazyQueryHookResult = ReturnType<typeof useGetCountryLocaleLazyQuery>;
export type GetCountryLocaleQueryResult = Apollo.QueryResult<
  GetCountryLocaleQuery,
  GetCountryLocaleQueryVariables
>;
export const DomainsInput_DomainsListDocument = gql`
  query domainsInput_domainsList {
    domainsList {
      id
      domain
      displayName
      youtubeChannelName
    }
  }
`;

/**
 * __useDomainsInput_DomainsListQuery__
 *
 * To run a query within a React component, call `useDomainsInput_DomainsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainsInput_DomainsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainsInput_DomainsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useDomainsInput_DomainsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DomainsInput_DomainsListQuery,
    DomainsInput_DomainsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DomainsInput_DomainsListQuery, DomainsInput_DomainsListQueryVariables>(
    DomainsInput_DomainsListDocument,
    options,
  );
}
export function useDomainsInput_DomainsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DomainsInput_DomainsListQuery,
    DomainsInput_DomainsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DomainsInput_DomainsListQuery, DomainsInput_DomainsListQueryVariables>(
    DomainsInput_DomainsListDocument,
    options,
  );
}
export type DomainsInput_DomainsListQueryHookResult = ReturnType<
  typeof useDomainsInput_DomainsListQuery
>;
export type DomainsInput_DomainsListLazyQueryHookResult = ReturnType<
  typeof useDomainsInput_DomainsListLazyQuery
>;
export type DomainsInput_DomainsListQueryResult = Apollo.QueryResult<
  DomainsInput_DomainsListQuery,
  DomainsInput_DomainsListQueryVariables
>;
export const KeywordsInputDocument = gql`
  query KeywordsInput($filters: [FilterInput]!, $ordering: OrderingInput!) {
    keywords(filters: $filters, pagination: { page: 1, results: 25 }, ordering: $ordering) {
      keywords {
        id
        keyword
        searchType
        searchEngine {
          name
          id
        }
        location
        countrylocale {
          id
          countryCode
          region
        }
      }
    }
  }
`;

/**
 * __useKeywordsInputQuery__
 *
 * To run a query within a React component, call `useKeywordsInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordsInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordsInputQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useKeywordsInputQuery(
  baseOptions: Apollo.QueryHookOptions<KeywordsInputQuery, KeywordsInputQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeywordsInputQuery, KeywordsInputQueryVariables>(
    KeywordsInputDocument,
    options,
  );
}
export function useKeywordsInputLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KeywordsInputQuery, KeywordsInputQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeywordsInputQuery, KeywordsInputQueryVariables>(
    KeywordsInputDocument,
    options,
  );
}
export type KeywordsInputQueryHookResult = ReturnType<typeof useKeywordsInputQuery>;
export type KeywordsInputLazyQueryHookResult = ReturnType<typeof useKeywordsInputLazyQuery>;
export type KeywordsInputQueryResult = Apollo.QueryResult<
  KeywordsInputQuery,
  KeywordsInputQueryVariables
>;
export const LandingPagesDocument = gql`
  query LandingPages($ids: [ID]!) {
    landingPages(ids: $ids) {
      id
      path
    }
  }
`;

/**
 * __useLandingPagesQuery__
 *
 * To run a query within a React component, call `useLandingPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandingPagesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLandingPagesQuery(
  baseOptions: Apollo.QueryHookOptions<LandingPagesQuery, LandingPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandingPagesQuery, LandingPagesQueryVariables>(
    LandingPagesDocument,
    options,
  );
}
export function useLandingPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LandingPagesQuery, LandingPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LandingPagesQuery, LandingPagesQueryVariables>(
    LandingPagesDocument,
    options,
  );
}
export type LandingPagesQueryHookResult = ReturnType<typeof useLandingPagesQuery>;
export type LandingPagesLazyQueryHookResult = ReturnType<typeof useLandingPagesLazyQuery>;
export type LandingPagesQueryResult = Apollo.QueryResult<
  LandingPagesQuery,
  LandingPagesQueryVariables
>;
export const LandingPagesSearchDocument = gql`
  query LandingPagesSearch($domains: [ID]!, $query: String!) {
    urls(domains: $domains, query: $query) {
      id
      path
    }
  }
`;

/**
 * __useLandingPagesSearchQuery__
 *
 * To run a query within a React component, call `useLandingPagesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingPagesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandingPagesSearchQuery({
 *   variables: {
 *      domains: // value for 'domains'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useLandingPagesSearchQuery(
  baseOptions: Apollo.QueryHookOptions<LandingPagesSearchQuery, LandingPagesSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandingPagesSearchQuery, LandingPagesSearchQueryVariables>(
    LandingPagesSearchDocument,
    options,
  );
}
export function useLandingPagesSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LandingPagesSearchQuery,
    LandingPagesSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LandingPagesSearchQuery, LandingPagesSearchQueryVariables>(
    LandingPagesSearchDocument,
    options,
  );
}
export type LandingPagesSearchQueryHookResult = ReturnType<typeof useLandingPagesSearchQuery>;
export type LandingPagesSearchLazyQueryHookResult = ReturnType<
  typeof useLandingPagesSearchLazyQuery
>;
export type LandingPagesSearchQueryResult = Apollo.QueryResult<
  LandingPagesSearchQuery,
  LandingPagesSearchQueryVariables
>;
export const SubDomainsListDocument = gql`
  query SubDomainsList($ids: [ID]) {
    domainsList(ids: $ids) {
      subdomains
    }
  }
`;

/**
 * __useSubDomainsListQuery__
 *
 * To run a query within a React component, call `useSubDomainsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubDomainsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubDomainsListQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSubDomainsListQuery(
  baseOptions?: Apollo.QueryHookOptions<SubDomainsListQuery, SubDomainsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubDomainsListQuery, SubDomainsListQueryVariables>(
    SubDomainsListDocument,
    options,
  );
}
export function useSubDomainsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubDomainsListQuery, SubDomainsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubDomainsListQuery, SubDomainsListQueryVariables>(
    SubDomainsListDocument,
    options,
  );
}
export type SubDomainsListQueryHookResult = ReturnType<typeof useSubDomainsListQuery>;
export type SubDomainsListLazyQueryHookResult = ReturnType<typeof useSubDomainsListLazyQuery>;
export type SubDomainsListQueryResult = Apollo.QueryResult<
  SubDomainsListQuery,
  SubDomainsListQueryVariables
>;
export const CreateUserFilterDocument = gql`
  mutation createUserFilter($input: CreateUserFilterInput!) {
    createFilter(input: $input) {
      errors {
        field
        messages
      }
      filter {
        id
        name
        type
        filters
      }
    }
  }
`;
export type CreateUserFilterMutationFn = Apollo.MutationFunction<
  CreateUserFilterMutation,
  CreateUserFilterMutationVariables
>;

/**
 * __useCreateUserFilterMutation__
 *
 * To run a mutation, you first call `useCreateUserFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFilterMutation, { data, loading, error }] = useCreateUserFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserFilterMutation,
    CreateUserFilterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserFilterMutation, CreateUserFilterMutationVariables>(
    CreateUserFilterDocument,
    options,
  );
}
export type CreateUserFilterMutationHookResult = ReturnType<typeof useCreateUserFilterMutation>;
export type CreateUserFilterMutationResult = Apollo.MutationResult<CreateUserFilterMutation>;
export type CreateUserFilterMutationOptions = Apollo.BaseMutationOptions<
  CreateUserFilterMutation,
  CreateUserFilterMutationVariables
>;
export const UpdateUserFilterDocument = gql`
  mutation updateUserFilter($input: UpdateUserFilterInput!) {
    updateFilter(input: $input) {
      errors {
        field
        messages
      }
      filter {
        id
        name
        defaultForDomains
        defaultForKeywords
        apiFilter
        filters
      }
    }
  }
`;
export type UpdateUserFilterMutationFn = Apollo.MutationFunction<
  UpdateUserFilterMutation,
  UpdateUserFilterMutationVariables
>;

/**
 * __useUpdateUserFilterMutation__
 *
 * To run a mutation, you first call `useUpdateUserFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFilterMutation, { data, loading, error }] = useUpdateUserFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserFilterMutation,
    UpdateUserFilterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserFilterMutation, UpdateUserFilterMutationVariables>(
    UpdateUserFilterDocument,
    options,
  );
}
export type UpdateUserFilterMutationHookResult = ReturnType<typeof useUpdateUserFilterMutation>;
export type UpdateUserFilterMutationResult = Apollo.MutationResult<UpdateUserFilterMutation>;
export type UpdateUserFilterMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserFilterMutation,
  UpdateUserFilterMutationVariables
>;
export const DeleteUserFilterDocument = gql`
  mutation deleteUserFilter($input: DeleteUserFilterInput!) {
    deleteFilter(input: $input) {
      errors {
        field
        messages
      }
      filter {
        id
      }
    }
  }
`;
export type DeleteUserFilterMutationFn = Apollo.MutationFunction<
  DeleteUserFilterMutation,
  DeleteUserFilterMutationVariables
>;

/**
 * __useDeleteUserFilterMutation__
 *
 * To run a mutation, you first call `useDeleteUserFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFilterMutation, { data, loading, error }] = useDeleteUserFilterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserFilterMutation,
    DeleteUserFilterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserFilterMutation, DeleteUserFilterMutationVariables>(
    DeleteUserFilterDocument,
    options,
  );
}
export type DeleteUserFilterMutationHookResult = ReturnType<typeof useDeleteUserFilterMutation>;
export type DeleteUserFilterMutationResult = Apollo.MutationResult<DeleteUserFilterMutation>;
export type DeleteUserFilterMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserFilterMutation,
  DeleteUserFilterMutationVariables
>;
export const FilterCountDocument = gql`
  query filterCount($filters: [FilterInput]!, $isKeydis: Boolean!) {
    filterData(filters: $filters, isKeydis: $isKeydis) {
      filterCount
    }
  }
`;

/**
 * __useFilterCountQuery__
 *
 * To run a query within a React component, call `useFilterCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterCountQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      isKeydis: // value for 'isKeydis'
 *   },
 * });
 */
export function useFilterCountQuery(
  baseOptions: Apollo.QueryHookOptions<FilterCountQuery, FilterCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterCountQuery, FilterCountQueryVariables>(FilterCountDocument, options);
}
export function useFilterCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterCountQuery, FilterCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterCountQuery, FilterCountQueryVariables>(
    FilterCountDocument,
    options,
  );
}
export type FilterCountQueryHookResult = ReturnType<typeof useFilterCountQuery>;
export type FilterCountLazyQueryHookResult = ReturnType<typeof useFilterCountLazyQuery>;
export type FilterCountQueryResult = Apollo.QueryResult<
  FilterCountQuery,
  FilterCountQueryVariables
>;
export const FilterOptionsDocument = gql`
  query filterOptions($filters: [FilterInput]!, $isKeydis: Boolean!, $filterType: String!) {
    filterData(filters: $filters, isKeydis: $isKeydis) {
      filterOptions(filterType: $filterType) {
        option
        count
      }
    }
  }
`;

/**
 * __useFilterOptionsQuery__
 *
 * To run a query within a React component, call `useFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterOptionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      isKeydis: // value for 'isKeydis'
 *      filterType: // value for 'filterType'
 *   },
 * });
 */
export function useFilterOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<FilterOptionsQuery, FilterOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterOptionsQuery, FilterOptionsQueryVariables>(
    FilterOptionsDocument,
    options,
  );
}
export function useFilterOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterOptionsQuery, FilterOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterOptionsQuery, FilterOptionsQueryVariables>(
    FilterOptionsDocument,
    options,
  );
}
export type FilterOptionsQueryHookResult = ReturnType<typeof useFilterOptionsQuery>;
export type FilterOptionsLazyQueryHookResult = ReturnType<typeof useFilterOptionsLazyQuery>;
export type FilterOptionsQueryResult = Apollo.QueryResult<
  FilterOptionsQuery,
  FilterOptionsQueryVariables
>;
export const ImpersonateOrganizationsDocument = gql`
  query ImpersonateOrganizations($searchQuery: String!) {
    organizations(name: $searchQuery) {
      id
      name
      slug
      active
      activePlan {
        id
        priceMonthly
        currency
      }
    }
  }
`;

/**
 * __useImpersonateOrganizationsQuery__
 *
 * To run a query within a React component, call `useImpersonateOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpersonateOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpersonateOrganizationsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useImpersonateOrganizationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImpersonateOrganizationsQuery,
    ImpersonateOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ImpersonateOrganizationsQuery, ImpersonateOrganizationsQueryVariables>(
    ImpersonateOrganizationsDocument,
    options,
  );
}
export function useImpersonateOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImpersonateOrganizationsQuery,
    ImpersonateOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ImpersonateOrganizationsQuery, ImpersonateOrganizationsQueryVariables>(
    ImpersonateOrganizationsDocument,
    options,
  );
}
export type ImpersonateOrganizationsQueryHookResult = ReturnType<
  typeof useImpersonateOrganizationsQuery
>;
export type ImpersonateOrganizationsLazyQueryHookResult = ReturnType<
  typeof useImpersonateOrganizationsLazyQuery
>;
export type ImpersonateOrganizationsQueryResult = Apollo.QueryResult<
  ImpersonateOrganizationsQuery,
  ImpersonateOrganizationsQueryVariables
>;
export const IntercomMultiAccountUserDocument = gql`
  query IntercomMultiAccountUser {
    user {
      id
      multiaccountOriginUser {
        id
        isImpersonating
        email
        fullName
        intercomHash
        dateJoined
        language
      }
      multiaccountOrganization {
        id
        name
        dateAdded
        active
        type
        isPartner
        phoneNumber
        numberOfDomains
        numberOfDomainsWithGa
        numberOfDomainsWithGwt
        numberOfKeywords
        activePlan {
          category
          priceMonthly
          maxKeywords
          isTrial
          endDate
          billingCycleInMonths
        }
      }
    }
  }
`;

/**
 * __useIntercomMultiAccountUserQuery__
 *
 * To run a query within a React component, call `useIntercomMultiAccountUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntercomMultiAccountUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntercomMultiAccountUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntercomMultiAccountUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IntercomMultiAccountUserQuery,
    IntercomMultiAccountUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IntercomMultiAccountUserQuery, IntercomMultiAccountUserQueryVariables>(
    IntercomMultiAccountUserDocument,
    options,
  );
}
export function useIntercomMultiAccountUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntercomMultiAccountUserQuery,
    IntercomMultiAccountUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IntercomMultiAccountUserQuery, IntercomMultiAccountUserQueryVariables>(
    IntercomMultiAccountUserDocument,
    options,
  );
}
export type IntercomMultiAccountUserQueryHookResult = ReturnType<
  typeof useIntercomMultiAccountUserQuery
>;
export type IntercomMultiAccountUserLazyQueryHookResult = ReturnType<
  typeof useIntercomMultiAccountUserLazyQuery
>;
export type IntercomMultiAccountUserQueryResult = Apollo.QueryResult<
  IntercomMultiAccountUserQuery,
  IntercomMultiAccountUserQueryVariables
>;
export const UniqueKeywordsDocument = gql`
  query uniqueKeywords($filters: [FilterInput]!) {
    uniqueKeywordsNode(filters: $filters) {
      uniqueKeywords
    }
  }
`;

/**
 * __useUniqueKeywordsQuery__
 *
 * To run a query within a React component, call `useUniqueKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniqueKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniqueKeywordsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUniqueKeywordsQuery(
  baseOptions: Apollo.QueryHookOptions<UniqueKeywordsQuery, UniqueKeywordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UniqueKeywordsQuery, UniqueKeywordsQueryVariables>(
    UniqueKeywordsDocument,
    options,
  );
}
export function useUniqueKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UniqueKeywordsQuery, UniqueKeywordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UniqueKeywordsQuery, UniqueKeywordsQueryVariables>(
    UniqueKeywordsDocument,
    options,
  );
}
export type UniqueKeywordsQueryHookResult = ReturnType<typeof useUniqueKeywordsQuery>;
export type UniqueKeywordsLazyQueryHookResult = ReturnType<typeof useUniqueKeywordsLazyQuery>;
export type UniqueKeywordsQueryResult = Apollo.QueryResult<
  UniqueKeywordsQuery,
  UniqueKeywordsQueryVariables
>;
export const LinkedAccountsOrganizationsDocument = gql`
  query LinkedAccountsOrganizations($searchQuery: String!) {
    linkedOrganizations(name: $searchQuery) {
      ...linkedOrganization
    }
  }
  ${LinkedOrganizationFragmentDoc}
`;

/**
 * __useLinkedAccountsOrganizationsQuery__
 *
 * To run a query within a React component, call `useLinkedAccountsOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkedAccountsOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkedAccountsOrganizationsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useLinkedAccountsOrganizationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinkedAccountsOrganizationsQuery,
    LinkedAccountsOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LinkedAccountsOrganizationsQuery,
    LinkedAccountsOrganizationsQueryVariables
  >(LinkedAccountsOrganizationsDocument, options);
}
export function useLinkedAccountsOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinkedAccountsOrganizationsQuery,
    LinkedAccountsOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LinkedAccountsOrganizationsQuery,
    LinkedAccountsOrganizationsQueryVariables
  >(LinkedAccountsOrganizationsDocument, options);
}
export type LinkedAccountsOrganizationsQueryHookResult = ReturnType<
  typeof useLinkedAccountsOrganizationsQuery
>;
export type LinkedAccountsOrganizationsLazyQueryHookResult = ReturnType<
  typeof useLinkedAccountsOrganizationsLazyQuery
>;
export type LinkedAccountsOrganizationsQueryResult = Apollo.QueryResult<
  LinkedAccountsOrganizationsQuery,
  LinkedAccountsOrganizationsQueryVariables
>;
export const AddGroupDocument = gql`
  mutation AddGroup($input: AddClientInput!) {
    addClient(input: $input) {
      client {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type AddGroupMutationFn = Apollo.MutationFunction<
  AddGroupMutation,
  AddGroupMutationVariables
>;

/**
 * __useAddGroupMutation__
 *
 * To run a mutation, you first call `useAddGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMutation, { data, loading, error }] = useAddGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<AddGroupMutation, AddGroupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddGroupMutation, AddGroupMutationVariables>(AddGroupDocument, options);
}
export type AddGroupMutationHookResult = ReturnType<typeof useAddGroupMutation>;
export type AddGroupMutationResult = Apollo.MutationResult<AddGroupMutation>;
export type AddGroupMutationOptions = Apollo.BaseMutationOptions<
  AddGroupMutation,
  AddGroupMutationVariables
>;
export const CountryLocalesDocument = gql`
  query CountryLocales {
    countrylocales {
      id
      locale
      region
      countryCode
      localeShort
      googleSupport
      bingSupport
      baiduSupport
      youtubeSupport
      naverSupport
    }
  }
`;

/**
 * __useCountryLocalesQuery__
 *
 * To run a query within a React component, call `useCountryLocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryLocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryLocalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountryLocalesQuery(
  baseOptions?: Apollo.QueryHookOptions<CountryLocalesQuery, CountryLocalesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountryLocalesQuery, CountryLocalesQueryVariables>(
    CountryLocalesDocument,
    options,
  );
}
export function useCountryLocalesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountryLocalesQuery, CountryLocalesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountryLocalesQuery, CountryLocalesQueryVariables>(
    CountryLocalesDocument,
    options,
  );
}
export type CountryLocalesQueryHookResult = ReturnType<typeof useCountryLocalesQuery>;
export type CountryLocalesLazyQueryHookResult = ReturnType<typeof useCountryLocalesLazyQuery>;
export type CountryLocalesQueryResult = Apollo.QueryResult<
  CountryLocalesQuery,
  CountryLocalesQueryVariables
>;
export const DomainInfoAddKeywordsDocument = gql`
  query DomainInfoAddKeywords($domainId: ID!) {
    domain(id: $domainId) {
      id
      domainType
      displayName
      domain
      defaultSearchSettings {
        id
        countrylocale {
          id
          region
        }
        locations
        searchEngines {
          id
          searchEngine {
            id
            name
          }
          searchTypes
        }
        ignoreLocalResults
        ignoreFeaturedSnippet
        enableAutocorrect
      }
      googleOauthConnectionGsc {
        id
      }
    }
  }
`;

/**
 * __useDomainInfoAddKeywordsQuery__
 *
 * To run a query within a React component, call `useDomainInfoAddKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainInfoAddKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainInfoAddKeywordsQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useDomainInfoAddKeywordsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DomainInfoAddKeywordsQuery,
    DomainInfoAddKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DomainInfoAddKeywordsQuery, DomainInfoAddKeywordsQueryVariables>(
    DomainInfoAddKeywordsDocument,
    options,
  );
}
export function useDomainInfoAddKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DomainInfoAddKeywordsQuery,
    DomainInfoAddKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DomainInfoAddKeywordsQuery, DomainInfoAddKeywordsQueryVariables>(
    DomainInfoAddKeywordsDocument,
    options,
  );
}
export type DomainInfoAddKeywordsQueryHookResult = ReturnType<typeof useDomainInfoAddKeywordsQuery>;
export type DomainInfoAddKeywordsLazyQueryHookResult = ReturnType<
  typeof useDomainInfoAddKeywordsLazyQuery
>;
export type DomainInfoAddKeywordsQueryResult = Apollo.QueryResult<
  DomainInfoAddKeywordsQuery,
  DomainInfoAddKeywordsQueryVariables
>;
export const ImportKeydisAddKeywordsDocument = gql`
  mutation ImportKeydisAddKeywords($input: ImportKeydisKeywordsInput!) {
    importKeydisKeywords(input: $input) {
      code
      errors {
        field
        messages
      }
    }
  }
`;
export type ImportKeydisAddKeywordsMutationFn = Apollo.MutationFunction<
  ImportKeydisAddKeywordsMutation,
  ImportKeydisAddKeywordsMutationVariables
>;

/**
 * __useImportKeydisAddKeywordsMutation__
 *
 * To run a mutation, you first call `useImportKeydisAddKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportKeydisAddKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importKeydisAddKeywordsMutation, { data, loading, error }] = useImportKeydisAddKeywordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportKeydisAddKeywordsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportKeydisAddKeywordsMutation,
    ImportKeydisAddKeywordsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportKeydisAddKeywordsMutation,
    ImportKeydisAddKeywordsMutationVariables
  >(ImportKeydisAddKeywordsDocument, options);
}
export type ImportKeydisAddKeywordsMutationHookResult = ReturnType<
  typeof useImportKeydisAddKeywordsMutation
>;
export type ImportKeydisAddKeywordsMutationResult =
  Apollo.MutationResult<ImportKeydisAddKeywordsMutation>;
export type ImportKeydisAddKeywordsMutationOptions = Apollo.BaseMutationOptions<
  ImportKeydisAddKeywordsMutation,
  ImportKeydisAddKeywordsMutationVariables
>;
export const AddKeywordsDocument = gql`
  mutation AddKeywords($input: AddKeywordsInputNew!) {
    addKeywordsNew(input: $input) {
      code
      errors {
        field
        messages
      }
    }
  }
`;
export type AddKeywordsMutationFn = Apollo.MutationFunction<
  AddKeywordsMutation,
  AddKeywordsMutationVariables
>;

/**
 * __useAddKeywordsMutation__
 *
 * To run a mutation, you first call `useAddKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKeywordsMutation, { data, loading, error }] = useAddKeywordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddKeywordsMutation(
  baseOptions?: Apollo.MutationHookOptions<AddKeywordsMutation, AddKeywordsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddKeywordsMutation, AddKeywordsMutationVariables>(
    AddKeywordsDocument,
    options,
  );
}
export type AddKeywordsMutationHookResult = ReturnType<typeof useAddKeywordsMutation>;
export type AddKeywordsMutationResult = Apollo.MutationResult<AddKeywordsMutation>;
export type AddKeywordsMutationOptions = Apollo.BaseMutationOptions<
  AddKeywordsMutation,
  AddKeywordsMutationVariables
>;
export const AddKeywordsFormKeywordOveruseEnabledDocument = gql`
  query AddKeywordsFormKeywordOveruseEnabled {
    user {
      id
      organization {
        id
        keywordOveruseEnabled
      }
    }
  }
`;

/**
 * __useAddKeywordsFormKeywordOveruseEnabledQuery__
 *
 * To run a query within a React component, call `useAddKeywordsFormKeywordOveruseEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddKeywordsFormKeywordOveruseEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddKeywordsFormKeywordOveruseEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddKeywordsFormKeywordOveruseEnabledQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AddKeywordsFormKeywordOveruseEnabledQuery,
    AddKeywordsFormKeywordOveruseEnabledQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AddKeywordsFormKeywordOveruseEnabledQuery,
    AddKeywordsFormKeywordOveruseEnabledQueryVariables
  >(AddKeywordsFormKeywordOveruseEnabledDocument, options);
}
export function useAddKeywordsFormKeywordOveruseEnabledLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddKeywordsFormKeywordOveruseEnabledQuery,
    AddKeywordsFormKeywordOveruseEnabledQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AddKeywordsFormKeywordOveruseEnabledQuery,
    AddKeywordsFormKeywordOveruseEnabledQueryVariables
  >(AddKeywordsFormKeywordOveruseEnabledDocument, options);
}
export type AddKeywordsFormKeywordOveruseEnabledQueryHookResult = ReturnType<
  typeof useAddKeywordsFormKeywordOveruseEnabledQuery
>;
export type AddKeywordsFormKeywordOveruseEnabledLazyQueryHookResult = ReturnType<
  typeof useAddKeywordsFormKeywordOveruseEnabledLazyQuery
>;
export type AddKeywordsFormKeywordOveruseEnabledQueryResult = Apollo.QueryResult<
  AddKeywordsFormKeywordOveruseEnabledQuery,
  AddKeywordsFormKeywordOveruseEnabledQueryVariables
>;
export const AddKeywordsFormSimpleLogDocument = gql`
  mutation AddKeywordsFormSimpleLog($input: SimpleLogInput!) {
    simpleLog(input: $input) {
      success
    }
  }
`;
export type AddKeywordsFormSimpleLogMutationFn = Apollo.MutationFunction<
  AddKeywordsFormSimpleLogMutation,
  AddKeywordsFormSimpleLogMutationVariables
>;

/**
 * __useAddKeywordsFormSimpleLogMutation__
 *
 * To run a mutation, you first call `useAddKeywordsFormSimpleLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKeywordsFormSimpleLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKeywordsFormSimpleLogMutation, { data, loading, error }] = useAddKeywordsFormSimpleLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddKeywordsFormSimpleLogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddKeywordsFormSimpleLogMutation,
    AddKeywordsFormSimpleLogMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddKeywordsFormSimpleLogMutation,
    AddKeywordsFormSimpleLogMutationVariables
  >(AddKeywordsFormSimpleLogDocument, options);
}
export type AddKeywordsFormSimpleLogMutationHookResult = ReturnType<
  typeof useAddKeywordsFormSimpleLogMutation
>;
export type AddKeywordsFormSimpleLogMutationResult =
  Apollo.MutationResult<AddKeywordsFormSimpleLogMutation>;
export type AddKeywordsFormSimpleLogMutationOptions = Apollo.BaseMutationOptions<
  AddKeywordsFormSimpleLogMutation,
  AddKeywordsFormSimpleLogMutationVariables
>;
export const AddKeywordsFormAccountUsageUserDocument = gql`
  query AddKeywordsFormAccountUsageUser {
    user {
      id
      organization {
        id
        numberOfKeywords
      }
    }
  }
`;

/**
 * __useAddKeywordsFormAccountUsageUserQuery__
 *
 * To run a query within a React component, call `useAddKeywordsFormAccountUsageUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddKeywordsFormAccountUsageUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddKeywordsFormAccountUsageUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddKeywordsFormAccountUsageUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AddKeywordsFormAccountUsageUserQuery,
    AddKeywordsFormAccountUsageUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AddKeywordsFormAccountUsageUserQuery,
    AddKeywordsFormAccountUsageUserQueryVariables
  >(AddKeywordsFormAccountUsageUserDocument, options);
}
export function useAddKeywordsFormAccountUsageUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddKeywordsFormAccountUsageUserQuery,
    AddKeywordsFormAccountUsageUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AddKeywordsFormAccountUsageUserQuery,
    AddKeywordsFormAccountUsageUserQueryVariables
  >(AddKeywordsFormAccountUsageUserDocument, options);
}
export type AddKeywordsFormAccountUsageUserQueryHookResult = ReturnType<
  typeof useAddKeywordsFormAccountUsageUserQuery
>;
export type AddKeywordsFormAccountUsageUserLazyQueryHookResult = ReturnType<
  typeof useAddKeywordsFormAccountUsageUserLazyQuery
>;
export type AddKeywordsFormAccountUsageUserQueryResult = Apollo.QueryResult<
  AddKeywordsFormAccountUsageUserQuery,
  AddKeywordsFormAccountUsageUserQueryVariables
>;
export const AddUserFormInviteUserDocument = gql`
  mutation AddUserFormInviteUser($inviteUserInput: InviteUserInput!) {
    inviteUser(input: $inviteUserInput) {
      errors {
        field
        messages
      }
    }
  }
`;
export type AddUserFormInviteUserMutationFn = Apollo.MutationFunction<
  AddUserFormInviteUserMutation,
  AddUserFormInviteUserMutationVariables
>;

/**
 * __useAddUserFormInviteUserMutation__
 *
 * To run a mutation, you first call `useAddUserFormInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserFormInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserFormInviteUserMutation, { data, loading, error }] = useAddUserFormInviteUserMutation({
 *   variables: {
 *      inviteUserInput: // value for 'inviteUserInput'
 *   },
 * });
 */
export function useAddUserFormInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserFormInviteUserMutation,
    AddUserFormInviteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUserFormInviteUserMutation, AddUserFormInviteUserMutationVariables>(
    AddUserFormInviteUserDocument,
    options,
  );
}
export type AddUserFormInviteUserMutationHookResult = ReturnType<
  typeof useAddUserFormInviteUserMutation
>;
export type AddUserFormInviteUserMutationResult =
  Apollo.MutationResult<AddUserFormInviteUserMutation>;
export type AddUserFormInviteUserMutationOptions = Apollo.BaseMutationOptions<
  AddUserFormInviteUserMutation,
  AddUserFormInviteUserMutationVariables
>;
export const AddWalletItemDocument = gql`
  mutation AddWalletItem($input: CreateWalletInput!) {
    createWallet(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type AddWalletItemMutationFn = Apollo.MutationFunction<
  AddWalletItemMutation,
  AddWalletItemMutationVariables
>;

/**
 * __useAddWalletItemMutation__
 *
 * To run a mutation, you first call `useAddWalletItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWalletItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWalletItemMutation, { data, loading, error }] = useAddWalletItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWalletItemMutation(
  baseOptions?: Apollo.MutationHookOptions<AddWalletItemMutation, AddWalletItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddWalletItemMutation, AddWalletItemMutationVariables>(
    AddWalletItemDocument,
    options,
  );
}
export type AddWalletItemMutationHookResult = ReturnType<typeof useAddWalletItemMutation>;
export type AddWalletItemMutationResult = Apollo.MutationResult<AddWalletItemMutation>;
export type AddWalletItemMutationOptions = Apollo.BaseMutationOptions<
  AddWalletItemMutation,
  AddWalletItemMutationVariables
>;
export const CreateWorkspaceDocument = gql`
  mutation createWorkspace($roleInput: CreateWorkspaceInput!) {
    createWorkspace(input: $roleInput) {
      errors {
        field
        messages
      }
    }
  }
`;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      roleInput: // value for 'roleInput'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkspaceMutation,
    CreateWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(
    CreateWorkspaceDocument,
    options,
  );
}
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>;
export const AffiliateInfoDocument = gql`
  query AffiliateInfo {
    affiliate {
      details {
        commission
        totalCommission
        totalUnpaidCommission
        totalPaidCommission
        affiliateId
        paypalEmail
        minimumPayout
      }
    }
  }
`;

/**
 * __useAffiliateInfoQuery__
 *
 * To run a query within a React component, call `useAffiliateInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAffiliateInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<AffiliateInfoQuery, AffiliateInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AffiliateInfoQuery, AffiliateInfoQueryVariables>(
    AffiliateInfoDocument,
    options,
  );
}
export function useAffiliateInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AffiliateInfoQuery, AffiliateInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AffiliateInfoQuery, AffiliateInfoQueryVariables>(
    AffiliateInfoDocument,
    options,
  );
}
export type AffiliateInfoQueryHookResult = ReturnType<typeof useAffiliateInfoQuery>;
export type AffiliateInfoLazyQueryHookResult = ReturnType<typeof useAffiliateInfoLazyQuery>;
export type AffiliateInfoQueryResult = Apollo.QueryResult<
  AffiliateInfoQuery,
  AffiliateInfoQueryVariables
>;
export const AddCompetitorDocument = gql`
  mutation addCompetitor($addCompetitorInput: AddCompetitorInput!) {
    addCompetitor(input: $addCompetitorInput) {
      competitor {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type AddCompetitorMutationFn = Apollo.MutationFunction<
  AddCompetitorMutation,
  AddCompetitorMutationVariables
>;

/**
 * __useAddCompetitorMutation__
 *
 * To run a mutation, you first call `useAddCompetitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompetitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompetitorMutation, { data, loading, error }] = useAddCompetitorMutation({
 *   variables: {
 *      addCompetitorInput: // value for 'addCompetitorInput'
 *   },
 * });
 */
export function useAddCompetitorMutation(
  baseOptions?: Apollo.MutationHookOptions<AddCompetitorMutation, AddCompetitorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCompetitorMutation, AddCompetitorMutationVariables>(
    AddCompetitorDocument,
    options,
  );
}
export type AddCompetitorMutationHookResult = ReturnType<typeof useAddCompetitorMutation>;
export type AddCompetitorMutationResult = Apollo.MutationResult<AddCompetitorMutation>;
export type AddCompetitorMutationOptions = Apollo.BaseMutationOptions<
  AddCompetitorMutation,
  AddCompetitorMutationVariables
>;
export const UpdateCompetitorDocument = gql`
  mutation updateCompetitor($updateCompetitorInput: UpdateCompetitorInput!) {
    updateCompetitor(input: $updateCompetitorInput) {
      errors {
        field
        messages
      }
    }
  }
`;
export type UpdateCompetitorMutationFn = Apollo.MutationFunction<
  UpdateCompetitorMutation,
  UpdateCompetitorMutationVariables
>;

/**
 * __useUpdateCompetitorMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitorMutation, { data, loading, error }] = useUpdateCompetitorMutation({
 *   variables: {
 *      updateCompetitorInput: // value for 'updateCompetitorInput'
 *   },
 * });
 */
export function useUpdateCompetitorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompetitorMutation,
    UpdateCompetitorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompetitorMutation, UpdateCompetitorMutationVariables>(
    UpdateCompetitorDocument,
    options,
  );
}
export type UpdateCompetitorMutationHookResult = ReturnType<typeof useUpdateCompetitorMutation>;
export type UpdateCompetitorMutationResult = Apollo.MutationResult<UpdateCompetitorMutation>;
export type UpdateCompetitorMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompetitorMutation,
  UpdateCompetitorMutationVariables
>;
export const GetCompetitorDataDocument = gql`
  query getCompetitorData($domainId: ID!, $competitorId: ID!) {
    domain(id: $domainId) {
      id
      competitor(id: $competitorId) {
        id
        domain
        displayName
        googleBusinessNameList
        twitterHandle
        facebookProfile
        tiktokProfile
        instagramProfile
        linkedinProfile
      }
    }
  }
`;

/**
 * __useGetCompetitorDataQuery__
 *
 * To run a query within a React component, call `useGetCompetitorDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompetitorDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompetitorDataQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *      competitorId: // value for 'competitorId'
 *   },
 * });
 */
export function useGetCompetitorDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompetitorDataQuery, GetCompetitorDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompetitorDataQuery, GetCompetitorDataQueryVariables>(
    GetCompetitorDataDocument,
    options,
  );
}
export function useGetCompetitorDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompetitorDataQuery,
    GetCompetitorDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompetitorDataQuery, GetCompetitorDataQueryVariables>(
    GetCompetitorDataDocument,
    options,
  );
}
export type GetCompetitorDataQueryHookResult = ReturnType<typeof useGetCompetitorDataQuery>;
export type GetCompetitorDataLazyQueryHookResult = ReturnType<typeof useGetCompetitorDataLazyQuery>;
export type GetCompetitorDataQueryResult = Apollo.QueryResult<
  GetCompetitorDataQuery,
  GetCompetitorDataQueryVariables
>;
export const AddDomainForm_ClientsDocument = gql`
  query addDomainForm_clients {
    clients {
      id
      name
      isDemoClient
    }
  }
`;

/**
 * __useAddDomainForm_ClientsQuery__
 *
 * To run a query within a React component, call `useAddDomainForm_ClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddDomainForm_ClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddDomainForm_ClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddDomainForm_ClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AddDomainForm_ClientsQuery,
    AddDomainForm_ClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddDomainForm_ClientsQuery, AddDomainForm_ClientsQueryVariables>(
    AddDomainForm_ClientsDocument,
    options,
  );
}
export function useAddDomainForm_ClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddDomainForm_ClientsQuery,
    AddDomainForm_ClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddDomainForm_ClientsQuery, AddDomainForm_ClientsQueryVariables>(
    AddDomainForm_ClientsDocument,
    options,
  );
}
export type AddDomainForm_ClientsQueryHookResult = ReturnType<typeof useAddDomainForm_ClientsQuery>;
export type AddDomainForm_ClientsLazyQueryHookResult = ReturnType<
  typeof useAddDomainForm_ClientsLazyQuery
>;
export type AddDomainForm_ClientsQueryResult = Apollo.QueryResult<
  AddDomainForm_ClientsQuery,
  AddDomainForm_ClientsQueryVariables
>;
export const AddDomainForm_CountrylocalesDocument = gql`
  query addDomainForm_countrylocales {
    countrylocales {
      id
      locale
      region
      countryCode
      localeShort
      googleSupport
      bingSupport
      yandexSupport
      baiduSupport
      youtubeSupport
      naverSupport
    }
  }
`;

/**
 * __useAddDomainForm_CountrylocalesQuery__
 *
 * To run a query within a React component, call `useAddDomainForm_CountrylocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddDomainForm_CountrylocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddDomainForm_CountrylocalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddDomainForm_CountrylocalesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AddDomainForm_CountrylocalesQuery,
    AddDomainForm_CountrylocalesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AddDomainForm_CountrylocalesQuery,
    AddDomainForm_CountrylocalesQueryVariables
  >(AddDomainForm_CountrylocalesDocument, options);
}
export function useAddDomainForm_CountrylocalesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddDomainForm_CountrylocalesQuery,
    AddDomainForm_CountrylocalesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AddDomainForm_CountrylocalesQuery,
    AddDomainForm_CountrylocalesQueryVariables
  >(AddDomainForm_CountrylocalesDocument, options);
}
export type AddDomainForm_CountrylocalesQueryHookResult = ReturnType<
  typeof useAddDomainForm_CountrylocalesQuery
>;
export type AddDomainForm_CountrylocalesLazyQueryHookResult = ReturnType<
  typeof useAddDomainForm_CountrylocalesLazyQuery
>;
export type AddDomainForm_CountrylocalesQueryResult = Apollo.QueryResult<
  AddDomainForm_CountrylocalesQuery,
  AddDomainForm_CountrylocalesQueryVariables
>;
export const AddDomainForm_AddDomainDocument = gql`
  mutation addDomainForm_addDomain($addDomainInput: AddDomainInput!) {
    addDomain(input: $addDomainInput) {
      domain {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type AddDomainForm_AddDomainMutationFn = Apollo.MutationFunction<
  AddDomainForm_AddDomainMutation,
  AddDomainForm_AddDomainMutationVariables
>;

/**
 * __useAddDomainForm_AddDomainMutation__
 *
 * To run a mutation, you first call `useAddDomainForm_AddDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDomainForm_AddDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDomainFormAddDomainMutation, { data, loading, error }] = useAddDomainForm_AddDomainMutation({
 *   variables: {
 *      addDomainInput: // value for 'addDomainInput'
 *   },
 * });
 */
export function useAddDomainForm_AddDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDomainForm_AddDomainMutation,
    AddDomainForm_AddDomainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddDomainForm_AddDomainMutation,
    AddDomainForm_AddDomainMutationVariables
  >(AddDomainForm_AddDomainDocument, options);
}
export type AddDomainForm_AddDomainMutationHookResult = ReturnType<
  typeof useAddDomainForm_AddDomainMutation
>;
export type AddDomainForm_AddDomainMutationResult =
  Apollo.MutationResult<AddDomainForm_AddDomainMutation>;
export type AddDomainForm_AddDomainMutationOptions = Apollo.BaseMutationOptions<
  AddDomainForm_AddDomainMutation,
  AddDomainForm_AddDomainMutationVariables
>;
export const GetDomainInfoDocument = gql`
  query getDomainInfo($id: ID!) {
    domain(id: $id) {
      id
      domain
      isDemoDomain
      pausedKeywordRate
      totalKeywords
      includeSubdomains
      exactMatch
      displayName
      defaultCountrylocale {
        id
      }
      defaultSearchSettings {
        id
        countrylocale {
          id
        }
        locations
        searchEngines {
          id
          searchEngine {
            id
            name
          }
          searchTypes
        }
        ignoreLocalResults
        ignoreFeaturedSnippet
        enableAutocorrect
        primary
      }
      googleBusinessNameList
      twitterHandle
      facebookProfile
      tiktokProfile
      instagramProfile
      linkedinProfile
      shareOfVoicePercentage
      paused
      client {
        id
      }
      googleOauthConnectionGa {
        id
      }
      googleOauthConnectionGsc {
        id
      }
      adobeMarketingConnection {
        id
      }
      gaPropertyId
      gaAccountId
    }
  }
`;

/**
 * __useGetDomainInfoQuery__
 *
 * To run a query within a React component, call `useGetDomainInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDomainInfoQuery(
  baseOptions: Apollo.QueryHookOptions<GetDomainInfoQuery, GetDomainInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDomainInfoQuery, GetDomainInfoQueryVariables>(
    GetDomainInfoDocument,
    options,
  );
}
export function useGetDomainInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDomainInfoQuery, GetDomainInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDomainInfoQuery, GetDomainInfoQueryVariables>(
    GetDomainInfoDocument,
    options,
  );
}
export type GetDomainInfoQueryHookResult = ReturnType<typeof useGetDomainInfoQuery>;
export type GetDomainInfoLazyQueryHookResult = ReturnType<typeof useGetDomainInfoLazyQuery>;
export type GetDomainInfoQueryResult = Apollo.QueryResult<
  GetDomainInfoQuery,
  GetDomainInfoQueryVariables
>;
export const RemoveAdobeAnalyticsAccountDocument = gql`
  mutation removeAdobeAnalyticsAccount($input: RemoveAdobeMarketingAccountInput!) {
    removeAdobeMarketingAccount(input: $input) {
      domain {
        id
      }
    }
  }
`;
export type RemoveAdobeAnalyticsAccountMutationFn = Apollo.MutationFunction<
  RemoveAdobeAnalyticsAccountMutation,
  RemoveAdobeAnalyticsAccountMutationVariables
>;

/**
 * __useRemoveAdobeAnalyticsAccountMutation__
 *
 * To run a mutation, you first call `useRemoveAdobeAnalyticsAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdobeAnalyticsAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdobeAnalyticsAccountMutation, { data, loading, error }] = useRemoveAdobeAnalyticsAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAdobeAnalyticsAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAdobeAnalyticsAccountMutation,
    RemoveAdobeAnalyticsAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAdobeAnalyticsAccountMutation,
    RemoveAdobeAnalyticsAccountMutationVariables
  >(RemoveAdobeAnalyticsAccountDocument, options);
}
export type RemoveAdobeAnalyticsAccountMutationHookResult = ReturnType<
  typeof useRemoveAdobeAnalyticsAccountMutation
>;
export type RemoveAdobeAnalyticsAccountMutationResult =
  Apollo.MutationResult<RemoveAdobeAnalyticsAccountMutation>;
export type RemoveAdobeAnalyticsAccountMutationOptions = Apollo.BaseMutationOptions<
  RemoveAdobeAnalyticsAccountMutation,
  RemoveAdobeAnalyticsAccountMutationVariables
>;
export const RemoveGoogleAnalyticsAccountDocument = gql`
  mutation removeGoogleAnalyticsAccount($input: RemoveGoogleAnalyticsAccountInput!) {
    removeGoogleAnalyticsAccount(input: $input) {
      domain {
        id
      }
    }
  }
`;
export type RemoveGoogleAnalyticsAccountMutationFn = Apollo.MutationFunction<
  RemoveGoogleAnalyticsAccountMutation,
  RemoveGoogleAnalyticsAccountMutationVariables
>;

/**
 * __useRemoveGoogleAnalyticsAccountMutation__
 *
 * To run a mutation, you first call `useRemoveGoogleAnalyticsAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGoogleAnalyticsAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGoogleAnalyticsAccountMutation, { data, loading, error }] = useRemoveGoogleAnalyticsAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGoogleAnalyticsAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGoogleAnalyticsAccountMutation,
    RemoveGoogleAnalyticsAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGoogleAnalyticsAccountMutation,
    RemoveGoogleAnalyticsAccountMutationVariables
  >(RemoveGoogleAnalyticsAccountDocument, options);
}
export type RemoveGoogleAnalyticsAccountMutationHookResult = ReturnType<
  typeof useRemoveGoogleAnalyticsAccountMutation
>;
export type RemoveGoogleAnalyticsAccountMutationResult =
  Apollo.MutationResult<RemoveGoogleAnalyticsAccountMutation>;
export type RemoveGoogleAnalyticsAccountMutationOptions = Apollo.BaseMutationOptions<
  RemoveGoogleAnalyticsAccountMutation,
  RemoveGoogleAnalyticsAccountMutationVariables
>;
export const RemoveGoogleSearchConsoleAccountDocument = gql`
  mutation removeGoogleSearchConsoleAccount($input: RemoveGoogleSearchConsoleAccountInput!) {
    removeGoogleSearchConsoleAccount(input: $input) {
      domain {
        id
      }
    }
  }
`;
export type RemoveGoogleSearchConsoleAccountMutationFn = Apollo.MutationFunction<
  RemoveGoogleSearchConsoleAccountMutation,
  RemoveGoogleSearchConsoleAccountMutationVariables
>;

/**
 * __useRemoveGoogleSearchConsoleAccountMutation__
 *
 * To run a mutation, you first call `useRemoveGoogleSearchConsoleAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGoogleSearchConsoleAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGoogleSearchConsoleAccountMutation, { data, loading, error }] = useRemoveGoogleSearchConsoleAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGoogleSearchConsoleAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGoogleSearchConsoleAccountMutation,
    RemoveGoogleSearchConsoleAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGoogleSearchConsoleAccountMutation,
    RemoveGoogleSearchConsoleAccountMutationVariables
  >(RemoveGoogleSearchConsoleAccountDocument, options);
}
export type RemoveGoogleSearchConsoleAccountMutationHookResult = ReturnType<
  typeof useRemoveGoogleSearchConsoleAccountMutation
>;
export type RemoveGoogleSearchConsoleAccountMutationResult =
  Apollo.MutationResult<RemoveGoogleSearchConsoleAccountMutation>;
export type RemoveGoogleSearchConsoleAccountMutationOptions = Apollo.BaseMutationOptions<
  RemoveGoogleSearchConsoleAccountMutation,
  RemoveGoogleSearchConsoleAccountMutationVariables
>;
export const BuildDomainUpdateDomainDocument = gql`
  mutation buildDomainUpdateDomain($updateDomainInput: UpdateDomainInput!) {
    updateDomain(input: $updateDomainInput) {
      domain {
        id
        shareOfVoicePercentage
        defaultSearchSettings {
          id
          countrylocale {
            id
          }
          locations
          searchEngines {
            id
            searchEngine {
              id
              name
            }
            searchTypes
          }
          ignoreLocalResults
          ignoreFeaturedSnippet
          enableAutocorrect
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type BuildDomainUpdateDomainMutationFn = Apollo.MutationFunction<
  BuildDomainUpdateDomainMutation,
  BuildDomainUpdateDomainMutationVariables
>;

/**
 * __useBuildDomainUpdateDomainMutation__
 *
 * To run a mutation, you first call `useBuildDomainUpdateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildDomainUpdateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildDomainUpdateDomainMutation, { data, loading, error }] = useBuildDomainUpdateDomainMutation({
 *   variables: {
 *      updateDomainInput: // value for 'updateDomainInput'
 *   },
 * });
 */
export function useBuildDomainUpdateDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuildDomainUpdateDomainMutation,
    BuildDomainUpdateDomainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BuildDomainUpdateDomainMutation,
    BuildDomainUpdateDomainMutationVariables
  >(BuildDomainUpdateDomainDocument, options);
}
export type BuildDomainUpdateDomainMutationHookResult = ReturnType<
  typeof useBuildDomainUpdateDomainMutation
>;
export type BuildDomainUpdateDomainMutationResult =
  Apollo.MutationResult<BuildDomainUpdateDomainMutation>;
export type BuildDomainUpdateDomainMutationOptions = Apollo.BaseMutationOptions<
  BuildDomainUpdateDomainMutation,
  BuildDomainUpdateDomainMutationVariables
>;
export const CompetitorsReportForm_CreateReportMutationDocument = gql`
  mutation competitorsReportForm_createReportMutation($input: ScheduleCompetitorsReportInput!) {
    scheduleCompetitorsReport(input: $input) {
      scheduledReport {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type CompetitorsReportForm_CreateReportMutationMutationFn = Apollo.MutationFunction<
  CompetitorsReportForm_CreateReportMutationMutation,
  CompetitorsReportForm_CreateReportMutationMutationVariables
>;

/**
 * __useCompetitorsReportForm_CreateReportMutationMutation__
 *
 * To run a mutation, you first call `useCompetitorsReportForm_CreateReportMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompetitorsReportForm_CreateReportMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [competitorsReportFormCreateReportMutationMutation, { data, loading, error }] = useCompetitorsReportForm_CreateReportMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompetitorsReportForm_CreateReportMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompetitorsReportForm_CreateReportMutationMutation,
    CompetitorsReportForm_CreateReportMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompetitorsReportForm_CreateReportMutationMutation,
    CompetitorsReportForm_CreateReportMutationMutationVariables
  >(CompetitorsReportForm_CreateReportMutationDocument, options);
}
export type CompetitorsReportForm_CreateReportMutationMutationHookResult = ReturnType<
  typeof useCompetitorsReportForm_CreateReportMutationMutation
>;
export type CompetitorsReportForm_CreateReportMutationMutationResult =
  Apollo.MutationResult<CompetitorsReportForm_CreateReportMutationMutation>;
export type CompetitorsReportForm_CreateReportMutationMutationOptions = Apollo.BaseMutationOptions<
  CompetitorsReportForm_CreateReportMutationMutation,
  CompetitorsReportForm_CreateReportMutationMutationVariables
>;
export const UpdateStaticTagDocument = gql`
  mutation UpdateStaticTag($input: UpdateStaticTagInput!) {
    updateStaticTag(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type UpdateStaticTagMutationFn = Apollo.MutationFunction<
  UpdateStaticTagMutation,
  UpdateStaticTagMutationVariables
>;

/**
 * __useUpdateStaticTagMutation__
 *
 * To run a mutation, you first call `useUpdateStaticTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaticTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaticTagMutation, { data, loading, error }] = useUpdateStaticTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStaticTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStaticTagMutation,
    UpdateStaticTagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateStaticTagMutation, UpdateStaticTagMutationVariables>(
    UpdateStaticTagDocument,
    options,
  );
}
export type UpdateStaticTagMutationHookResult = ReturnType<typeof useUpdateStaticTagMutation>;
export type UpdateStaticTagMutationResult = Apollo.MutationResult<UpdateStaticTagMutation>;
export type UpdateStaticTagMutationOptions = Apollo.BaseMutationOptions<
  UpdateStaticTagMutation,
  UpdateStaticTagMutationVariables
>;
export const EditGroupDocument = gql`
  mutation EditGroup($input: UpdateClientInput!) {
    updateClient(input: $input) {
      client {
        id
        name
      }
    }
  }
`;
export type EditGroupMutationFn = Apollo.MutationFunction<
  EditGroupMutation,
  EditGroupMutationVariables
>;

/**
 * __useEditGroupMutation__
 *
 * To run a mutation, you first call `useEditGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGroupMutation, { data, loading, error }] = useEditGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<EditGroupMutation, EditGroupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditGroupMutation, EditGroupMutationVariables>(
    EditGroupDocument,
    options,
  );
}
export type EditGroupMutationHookResult = ReturnType<typeof useEditGroupMutation>;
export type EditGroupMutationResult = Apollo.MutationResult<EditGroupMutation>;
export type EditGroupMutationOptions = Apollo.BaseMutationOptions<
  EditGroupMutation,
  EditGroupMutationVariables
>;
export const TopCompetitorsForKeywordsDocument = gql`
  query topCompetitorsForKeywords($keywordIds: [Int!]!, $domainId: Int) {
    topCompetitorsForKeywords(keywordIds: $keywordIds, domainId: $domainId) {
      domain
      score
    }
  }
`;

/**
 * __useTopCompetitorsForKeywordsQuery__
 *
 * To run a query within a React component, call `useTopCompetitorsForKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopCompetitorsForKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopCompetitorsForKeywordsQuery({
 *   variables: {
 *      keywordIds: // value for 'keywordIds'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useTopCompetitorsForKeywordsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TopCompetitorsForKeywordsQuery,
    TopCompetitorsForKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopCompetitorsForKeywordsQuery, TopCompetitorsForKeywordsQueryVariables>(
    TopCompetitorsForKeywordsDocument,
    options,
  );
}
export function useTopCompetitorsForKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopCompetitorsForKeywordsQuery,
    TopCompetitorsForKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TopCompetitorsForKeywordsQuery,
    TopCompetitorsForKeywordsQueryVariables
  >(TopCompetitorsForKeywordsDocument, options);
}
export type TopCompetitorsForKeywordsQueryHookResult = ReturnType<
  typeof useTopCompetitorsForKeywordsQuery
>;
export type TopCompetitorsForKeywordsLazyQueryHookResult = ReturnType<
  typeof useTopCompetitorsForKeywordsLazyQuery
>;
export type TopCompetitorsForKeywordsQueryResult = Apollo.QueryResult<
  TopCompetitorsForKeywordsQuery,
  TopCompetitorsForKeywordsQueryVariables
>;
export const GetUserByIdDocument = gql`
  query GetUserById($id: ID!) {
    user(id: $id) {
      id
      fullName
      email
      userType
      workspaces {
        id
      }
    }
  }
`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
}
export function useGetUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options,
  );
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<
  GetUserByIdQuery,
  GetUserByIdQueryVariables
>;
export const EditUserForm_EditUserDocument = gql`
  mutation editUserForm_editUser($updateUserInput: UpdateUserInput!) {
    updateUser(input: $updateUserInput) {
      user {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type EditUserForm_EditUserMutationFn = Apollo.MutationFunction<
  EditUserForm_EditUserMutation,
  EditUserForm_EditUserMutationVariables
>;

/**
 * __useEditUserForm_EditUserMutation__
 *
 * To run a mutation, you first call `useEditUserForm_EditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserForm_EditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserFormEditUserMutation, { data, loading, error }] = useEditUserForm_EditUserMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useEditUserForm_EditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditUserForm_EditUserMutation,
    EditUserForm_EditUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditUserForm_EditUserMutation, EditUserForm_EditUserMutationVariables>(
    EditUserForm_EditUserDocument,
    options,
  );
}
export type EditUserForm_EditUserMutationHookResult = ReturnType<
  typeof useEditUserForm_EditUserMutation
>;
export type EditUserForm_EditUserMutationResult =
  Apollo.MutationResult<EditUserForm_EditUserMutation>;
export type EditUserForm_EditUserMutationOptions = Apollo.BaseMutationOptions<
  EditUserForm_EditUserMutation,
  EditUserForm_EditUserMutationVariables
>;
export const GetWorkspaceDocument = gql`
  query GetWorkspace($id: ID!) {
    workspace(id: $id) {
      id
      name
      description
      clients {
        id
        name
      }
      lastEdited
      users {
        id
        fullName
      }
    }
  }
`;

/**
 * __useGetWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<GetWorkspaceQuery, GetWorkspaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(
    GetWorkspaceDocument,
    options,
  );
}
export function useGetWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceQuery, GetWorkspaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(
    GetWorkspaceDocument,
    options,
  );
}
export type GetWorkspaceQueryHookResult = ReturnType<typeof useGetWorkspaceQuery>;
export type GetWorkspaceLazyQueryHookResult = ReturnType<typeof useGetWorkspaceLazyQuery>;
export type GetWorkspaceQueryResult = Apollo.QueryResult<
  GetWorkspaceQuery,
  GetWorkspaceQueryVariables
>;
export const EditWorkspaceDocument = gql`
  mutation EditWorkspace($roleInput: UpdateWorkspaceInput!) {
    updateWorkspace(input: $roleInput) {
      workspace {
        id
        name
        description
        organization {
          id
        }
        clients {
          name
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type EditWorkspaceMutationFn = Apollo.MutationFunction<
  EditWorkspaceMutation,
  EditWorkspaceMutationVariables
>;

/**
 * __useEditWorkspaceMutation__
 *
 * To run a mutation, you first call `useEditWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWorkspaceMutation, { data, loading, error }] = useEditWorkspaceMutation({
 *   variables: {
 *      roleInput: // value for 'roleInput'
 *   },
 * });
 */
export function useEditWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<EditWorkspaceMutation, EditWorkspaceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditWorkspaceMutation, EditWorkspaceMutationVariables>(
    EditWorkspaceDocument,
    options,
  );
}
export type EditWorkspaceMutationHookResult = ReturnType<typeof useEditWorkspaceMutation>;
export type EditWorkspaceMutationResult = Apollo.MutationResult<EditWorkspaceMutation>;
export type EditWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  EditWorkspaceMutation,
  EditWorkspaceMutationVariables
>;
export const DownloadFolderReportDocument = gql`
  mutation downloadFolderReport($input: ScheduleFoldersReportInput!) {
    scheduleFoldersReport(input: $input) {
      scheduledReport {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type DownloadFolderReportMutationFn = Apollo.MutationFunction<
  DownloadFolderReportMutation,
  DownloadFolderReportMutationVariables
>;

/**
 * __useDownloadFolderReportMutation__
 *
 * To run a mutation, you first call `useDownloadFolderReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadFolderReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadFolderReportMutation, { data, loading, error }] = useDownloadFolderReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadFolderReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DownloadFolderReportMutation,
    DownloadFolderReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DownloadFolderReportMutation, DownloadFolderReportMutationVariables>(
    DownloadFolderReportDocument,
    options,
  );
}
export type DownloadFolderReportMutationHookResult = ReturnType<
  typeof useDownloadFolderReportMutation
>;
export type DownloadFolderReportMutationResult =
  Apollo.MutationResult<DownloadFolderReportMutation>;
export type DownloadFolderReportMutationOptions = Apollo.BaseMutationOptions<
  DownloadFolderReportMutation,
  DownloadFolderReportMutationVariables
>;
export const KeywordHistoryChartRanksDocument = gql`
  query KeywordHistoryChartRanks($filters: [FilterInput]!, $keywordId: ID!) {
    graphs(filters: $filters) {
      keywordHistory(keywordId: $keywordId, allHistory: true)
    }
  }
`;

/**
 * __useKeywordHistoryChartRanksQuery__
 *
 * To run a query within a React component, call `useKeywordHistoryChartRanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordHistoryChartRanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordHistoryChartRanksQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useKeywordHistoryChartRanksQuery(
  baseOptions: Apollo.QueryHookOptions<
    KeywordHistoryChartRanksQuery,
    KeywordHistoryChartRanksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeywordHistoryChartRanksQuery, KeywordHistoryChartRanksQueryVariables>(
    KeywordHistoryChartRanksDocument,
    options,
  );
}
export function useKeywordHistoryChartRanksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KeywordHistoryChartRanksQuery,
    KeywordHistoryChartRanksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeywordHistoryChartRanksQuery, KeywordHistoryChartRanksQueryVariables>(
    KeywordHistoryChartRanksDocument,
    options,
  );
}
export type KeywordHistoryChartRanksQueryHookResult = ReturnType<
  typeof useKeywordHistoryChartRanksQuery
>;
export type KeywordHistoryChartRanksLazyQueryHookResult = ReturnType<
  typeof useKeywordHistoryChartRanksLazyQuery
>;
export type KeywordHistoryChartRanksQueryResult = Apollo.QueryResult<
  KeywordHistoryChartRanksQuery,
  KeywordHistoryChartRanksQueryVariables
>;
export const KeywordHistoryCompetitorsRanksChartDocument = gql`
  query KeywordHistoryCompetitorsRanksChart($filters: [FilterInput]!, $keywordId: ID!) {
    graphs(filters: $filters) {
      keywordCompetitorHistory(keywordId: $keywordId, allHistory: true)
    }
  }
`;

/**
 * __useKeywordHistoryCompetitorsRanksChartQuery__
 *
 * To run a query within a React component, call `useKeywordHistoryCompetitorsRanksChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordHistoryCompetitorsRanksChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordHistoryCompetitorsRanksChartQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useKeywordHistoryCompetitorsRanksChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    KeywordHistoryCompetitorsRanksChartQuery,
    KeywordHistoryCompetitorsRanksChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    KeywordHistoryCompetitorsRanksChartQuery,
    KeywordHistoryCompetitorsRanksChartQueryVariables
  >(KeywordHistoryCompetitorsRanksChartDocument, options);
}
export function useKeywordHistoryCompetitorsRanksChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KeywordHistoryCompetitorsRanksChartQuery,
    KeywordHistoryCompetitorsRanksChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    KeywordHistoryCompetitorsRanksChartQuery,
    KeywordHistoryCompetitorsRanksChartQueryVariables
  >(KeywordHistoryCompetitorsRanksChartDocument, options);
}
export type KeywordHistoryCompetitorsRanksChartQueryHookResult = ReturnType<
  typeof useKeywordHistoryCompetitorsRanksChartQuery
>;
export type KeywordHistoryCompetitorsRanksChartLazyQueryHookResult = ReturnType<
  typeof useKeywordHistoryCompetitorsRanksChartLazyQuery
>;
export type KeywordHistoryCompetitorsRanksChartQueryResult = Apollo.QueryResult<
  KeywordHistoryCompetitorsRanksChartQuery,
  KeywordHistoryCompetitorsRanksChartQueryVariables
>;
export const KeywordHistoryTableDocument = gql`
  query KeywordHistoryTable(
    $filters: [FilterInput]!
    $keywordId: ID!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
  ) {
    keywordsTableHistory(filters: $filters, pagination: $pagination, ordering: $ordering) {
      history(keywordId: $keywordId)
      pagination {
        numResults
      }
    }
  }
`;

/**
 * __useKeywordHistoryTableQuery__
 *
 * To run a query within a React component, call `useKeywordHistoryTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordHistoryTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordHistoryTableQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      keywordId: // value for 'keywordId'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useKeywordHistoryTableQuery(
  baseOptions: Apollo.QueryHookOptions<KeywordHistoryTableQuery, KeywordHistoryTableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeywordHistoryTableQuery, KeywordHistoryTableQueryVariables>(
    KeywordHistoryTableDocument,
    options,
  );
}
export function useKeywordHistoryTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KeywordHistoryTableQuery,
    KeywordHistoryTableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeywordHistoryTableQuery, KeywordHistoryTableQueryVariables>(
    KeywordHistoryTableDocument,
    options,
  );
}
export type KeywordHistoryTableQueryHookResult = ReturnType<typeof useKeywordHistoryTableQuery>;
export type KeywordHistoryTableLazyQueryHookResult = ReturnType<
  typeof useKeywordHistoryTableLazyQuery
>;
export type KeywordHistoryTableQueryResult = Apollo.QueryResult<
  KeywordHistoryTableQuery,
  KeywordHistoryTableQueryVariables
>;
export const GoogleTrendsChartDocument = gql`
  query GoogleTrendsChart($filters: [FilterInput]!, $keywordId: ID!) {
    graphs(filters: $filters) {
      googleTrendsHistory(keywordId: $keywordId)
    }
  }
`;

/**
 * __useGoogleTrendsChartQuery__
 *
 * To run a query within a React component, call `useGoogleTrendsChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleTrendsChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleTrendsChartQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useGoogleTrendsChartQuery(
  baseOptions: Apollo.QueryHookOptions<GoogleTrendsChartQuery, GoogleTrendsChartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GoogleTrendsChartQuery, GoogleTrendsChartQueryVariables>(
    GoogleTrendsChartDocument,
    options,
  );
}
export function useGoogleTrendsChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GoogleTrendsChartQuery,
    GoogleTrendsChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GoogleTrendsChartQuery, GoogleTrendsChartQueryVariables>(
    GoogleTrendsChartDocument,
    options,
  );
}
export type GoogleTrendsChartQueryHookResult = ReturnType<typeof useGoogleTrendsChartQuery>;
export type GoogleTrendsChartLazyQueryHookResult = ReturnType<typeof useGoogleTrendsChartLazyQuery>;
export type GoogleTrendsChartQueryResult = Apollo.QueryResult<
  GoogleTrendsChartQuery,
  GoogleTrendsChartQueryVariables
>;
export const TopCompetitorsChartDocument = gql`
  query TopCompetitorsChart($filters: [FilterInput]!, $keywordId: ID!) {
    graphs(filters: $filters) {
      unknowncompetitorHistory(keywordId: $keywordId) {
        domain
        rank
        ownDomain
        searchDate
        youtubeChannelName
      }
    }
  }
`;

/**
 * __useTopCompetitorsChartQuery__
 *
 * To run a query within a React component, call `useTopCompetitorsChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopCompetitorsChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopCompetitorsChartQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useTopCompetitorsChartQuery(
  baseOptions: Apollo.QueryHookOptions<TopCompetitorsChartQuery, TopCompetitorsChartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopCompetitorsChartQuery, TopCompetitorsChartQueryVariables>(
    TopCompetitorsChartDocument,
    options,
  );
}
export function useTopCompetitorsChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopCompetitorsChartQuery,
    TopCompetitorsChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopCompetitorsChartQuery, TopCompetitorsChartQueryVariables>(
    TopCompetitorsChartDocument,
    options,
  );
}
export type TopCompetitorsChartQueryHookResult = ReturnType<typeof useTopCompetitorsChartQuery>;
export type TopCompetitorsChartLazyQueryHookResult = ReturnType<
  typeof useTopCompetitorsChartLazyQuery
>;
export type TopCompetitorsChartQueryResult = Apollo.QueryResult<
  TopCompetitorsChartQuery,
  TopCompetitorsChartQueryVariables
>;
export const KeywordInfoDocument = gql`
  query keywordInfo(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
    $keywordId: ID!
  ) {
    keywords(filters: $filters, pagination: $pagination, ordering: $ordering) {
      keyword(id: $keywordId) {
        id
        keyword
        searchType
        searchEngine {
          name
          id
        }
        location
        countrylocale {
          id
          countryCode
          region
        }
        domain {
          id
          domain
        }
      }
    }
  }
`;

/**
 * __useKeywordInfoQuery__
 *
 * To run a query within a React component, call `useKeywordInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordInfoQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useKeywordInfoQuery(
  baseOptions: Apollo.QueryHookOptions<KeywordInfoQuery, KeywordInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeywordInfoQuery, KeywordInfoQueryVariables>(KeywordInfoDocument, options);
}
export function useKeywordInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KeywordInfoQuery, KeywordInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeywordInfoQuery, KeywordInfoQueryVariables>(
    KeywordInfoDocument,
    options,
  );
}
export type KeywordInfoQueryHookResult = ReturnType<typeof useKeywordInfoQuery>;
export type KeywordInfoLazyQueryHookResult = ReturnType<typeof useKeywordInfoLazyQuery>;
export type KeywordInfoQueryResult = Apollo.QueryResult<
  KeywordInfoQuery,
  KeywordInfoQueryVariables
>;
export const KeywordCompareChartDocument = gql`
  query KeywordCompareChart($filters: [FilterInput]!, $keywordIds: [String]!) {
    graphs(filters: $filters) {
      compareKeywordsChart(keywordIds: $keywordIds)
    }
  }
`;

/**
 * __useKeywordCompareChartQuery__
 *
 * To run a query within a React component, call `useKeywordCompareChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordCompareChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordCompareChartQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      keywordIds: // value for 'keywordIds'
 *   },
 * });
 */
export function useKeywordCompareChartQuery(
  baseOptions: Apollo.QueryHookOptions<KeywordCompareChartQuery, KeywordCompareChartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeywordCompareChartQuery, KeywordCompareChartQueryVariables>(
    KeywordCompareChartDocument,
    options,
  );
}
export function useKeywordCompareChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KeywordCompareChartQuery,
    KeywordCompareChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeywordCompareChartQuery, KeywordCompareChartQueryVariables>(
    KeywordCompareChartDocument,
    options,
  );
}
export type KeywordCompareChartQueryHookResult = ReturnType<typeof useKeywordCompareChartQuery>;
export type KeywordCompareChartLazyQueryHookResult = ReturnType<
  typeof useKeywordCompareChartLazyQuery
>;
export type KeywordCompareChartQueryResult = Apollo.QueryResult<
  KeywordCompareChartQuery,
  KeywordCompareChartQueryVariables
>;
export const MessagesReadDocument = gql`
  query MessagesRead($startIdx: Int!, $stopIdx: Int!) {
    messages(read: true, startIdx: $startIdx, stopIdx: $stopIdx) {
      id
      text
      level
      levelId
      createdAt
    }
  }
`;

/**
 * __useMessagesReadQuery__
 *
 * To run a query within a React component, call `useMessagesReadQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesReadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesReadQuery({
 *   variables: {
 *      startIdx: // value for 'startIdx'
 *      stopIdx: // value for 'stopIdx'
 *   },
 * });
 */
export function useMessagesReadQuery(
  baseOptions: Apollo.QueryHookOptions<MessagesReadQuery, MessagesReadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessagesReadQuery, MessagesReadQueryVariables>(
    MessagesReadDocument,
    options,
  );
}
export function useMessagesReadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MessagesReadQuery, MessagesReadQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessagesReadQuery, MessagesReadQueryVariables>(
    MessagesReadDocument,
    options,
  );
}
export type MessagesReadQueryHookResult = ReturnType<typeof useMessagesReadQuery>;
export type MessagesReadLazyQueryHookResult = ReturnType<typeof useMessagesReadLazyQuery>;
export type MessagesReadQueryResult = Apollo.QueryResult<
  MessagesReadQuery,
  MessagesReadQueryVariables
>;
export const MessagesReadCountDocument = gql`
  query MessagesReadCount {
    totalMessagesCount(read: true)
  }
`;

/**
 * __useMessagesReadCountQuery__
 *
 * To run a query within a React component, call `useMessagesReadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesReadCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesReadCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessagesReadCountQuery(
  baseOptions?: Apollo.QueryHookOptions<MessagesReadCountQuery, MessagesReadCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessagesReadCountQuery, MessagesReadCountQueryVariables>(
    MessagesReadCountDocument,
    options,
  );
}
export function useMessagesReadCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessagesReadCountQuery,
    MessagesReadCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessagesReadCountQuery, MessagesReadCountQueryVariables>(
    MessagesReadCountDocument,
    options,
  );
}
export type MessagesReadCountQueryHookResult = ReturnType<typeof useMessagesReadCountQuery>;
export type MessagesReadCountLazyQueryHookResult = ReturnType<typeof useMessagesReadCountLazyQuery>;
export type MessagesReadCountQueryResult = Apollo.QueryResult<
  MessagesReadCountQuery,
  MessagesReadCountQueryVariables
>;
export const MessagesDropdownMessagesDocument = gql`
  query MessagesDropdownMessages($startIdx: Int!, $stopIdx: Int!) {
    messages(read: false, startIdx: $startIdx, stopIdx: $stopIdx) {
      id
      text
      level
      levelId
      createdAt
    }
  }
`;

/**
 * __useMessagesDropdownMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesDropdownMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesDropdownMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesDropdownMessagesQuery({
 *   variables: {
 *      startIdx: // value for 'startIdx'
 *      stopIdx: // value for 'stopIdx'
 *   },
 * });
 */
export function useMessagesDropdownMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MessagesDropdownMessagesQuery,
    MessagesDropdownMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessagesDropdownMessagesQuery, MessagesDropdownMessagesQueryVariables>(
    MessagesDropdownMessagesDocument,
    options,
  );
}
export function useMessagesDropdownMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessagesDropdownMessagesQuery,
    MessagesDropdownMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessagesDropdownMessagesQuery, MessagesDropdownMessagesQueryVariables>(
    MessagesDropdownMessagesDocument,
    options,
  );
}
export type MessagesDropdownMessagesQueryHookResult = ReturnType<
  typeof useMessagesDropdownMessagesQuery
>;
export type MessagesDropdownMessagesLazyQueryHookResult = ReturnType<
  typeof useMessagesDropdownMessagesLazyQuery
>;
export type MessagesDropdownMessagesQueryResult = Apollo.QueryResult<
  MessagesDropdownMessagesQuery,
  MessagesDropdownMessagesQueryVariables
>;
export const MessagesDropdownTotalMessagesCountDocument = gql`
  query MessagesDropdownTotalMessagesCount {
    totalMessagesCount(read: false)
  }
`;

/**
 * __useMessagesDropdownTotalMessagesCountQuery__
 *
 * To run a query within a React component, call `useMessagesDropdownTotalMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesDropdownTotalMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesDropdownTotalMessagesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessagesDropdownTotalMessagesCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MessagesDropdownTotalMessagesCountQuery,
    MessagesDropdownTotalMessagesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MessagesDropdownTotalMessagesCountQuery,
    MessagesDropdownTotalMessagesCountQueryVariables
  >(MessagesDropdownTotalMessagesCountDocument, options);
}
export function useMessagesDropdownTotalMessagesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessagesDropdownTotalMessagesCountQuery,
    MessagesDropdownTotalMessagesCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MessagesDropdownTotalMessagesCountQuery,
    MessagesDropdownTotalMessagesCountQueryVariables
  >(MessagesDropdownTotalMessagesCountDocument, options);
}
export type MessagesDropdownTotalMessagesCountQueryHookResult = ReturnType<
  typeof useMessagesDropdownTotalMessagesCountQuery
>;
export type MessagesDropdownTotalMessagesCountLazyQueryHookResult = ReturnType<
  typeof useMessagesDropdownTotalMessagesCountLazyQuery
>;
export type MessagesDropdownTotalMessagesCountQueryResult = Apollo.QueryResult<
  MessagesDropdownTotalMessagesCountQuery,
  MessagesDropdownTotalMessagesCountQueryVariables
>;
export const MessagesDropdownUpdateReadDocument = gql`
  mutation MessagesDropdownUpdateRead($input: UpdateReadInput!) {
    updateRead(input: $input) {
      errors {
        field
      }
    }
  }
`;
export type MessagesDropdownUpdateReadMutationFn = Apollo.MutationFunction<
  MessagesDropdownUpdateReadMutation,
  MessagesDropdownUpdateReadMutationVariables
>;

/**
 * __useMessagesDropdownUpdateReadMutation__
 *
 * To run a mutation, you first call `useMessagesDropdownUpdateReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessagesDropdownUpdateReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messagesDropdownUpdateReadMutation, { data, loading, error }] = useMessagesDropdownUpdateReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessagesDropdownUpdateReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MessagesDropdownUpdateReadMutation,
    MessagesDropdownUpdateReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MessagesDropdownUpdateReadMutation,
    MessagesDropdownUpdateReadMutationVariables
  >(MessagesDropdownUpdateReadDocument, options);
}
export type MessagesDropdownUpdateReadMutationHookResult = ReturnType<
  typeof useMessagesDropdownUpdateReadMutation
>;
export type MessagesDropdownUpdateReadMutationResult =
  Apollo.MutationResult<MessagesDropdownUpdateReadMutation>;
export type MessagesDropdownUpdateReadMutationOptions = Apollo.BaseMutationOptions<
  MessagesDropdownUpdateReadMutation,
  MessagesDropdownUpdateReadMutationVariables
>;
export const MessagesDropdownUpdateAllReadDocument = gql`
  mutation MessagesDropdownUpdateAllRead($read: Boolean!) {
    updateAllRead(read: $read) {
      errors {
        field
      }
    }
  }
`;
export type MessagesDropdownUpdateAllReadMutationFn = Apollo.MutationFunction<
  MessagesDropdownUpdateAllReadMutation,
  MessagesDropdownUpdateAllReadMutationVariables
>;

/**
 * __useMessagesDropdownUpdateAllReadMutation__
 *
 * To run a mutation, you first call `useMessagesDropdownUpdateAllReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessagesDropdownUpdateAllReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messagesDropdownUpdateAllReadMutation, { data, loading, error }] = useMessagesDropdownUpdateAllReadMutation({
 *   variables: {
 *      read: // value for 'read'
 *   },
 * });
 */
export function useMessagesDropdownUpdateAllReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MessagesDropdownUpdateAllReadMutation,
    MessagesDropdownUpdateAllReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MessagesDropdownUpdateAllReadMutation,
    MessagesDropdownUpdateAllReadMutationVariables
  >(MessagesDropdownUpdateAllReadDocument, options);
}
export type MessagesDropdownUpdateAllReadMutationHookResult = ReturnType<
  typeof useMessagesDropdownUpdateAllReadMutation
>;
export type MessagesDropdownUpdateAllReadMutationResult =
  Apollo.MutationResult<MessagesDropdownUpdateAllReadMutation>;
export type MessagesDropdownUpdateAllReadMutationOptions = Apollo.BaseMutationOptions<
  MessagesDropdownUpdateAllReadMutation,
  MessagesDropdownUpdateAllReadMutationVariables
>;
export const MoveKeywordsFormGroupsAndDomainsDocument = gql`
  query MoveKeywordsFormGroupsAndDomains {
    clients {
      name
      domains {
        id
        domain
        displayName
      }
    }
  }
`;

/**
 * __useMoveKeywordsFormGroupsAndDomainsQuery__
 *
 * To run a query within a React component, call `useMoveKeywordsFormGroupsAndDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveKeywordsFormGroupsAndDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveKeywordsFormGroupsAndDomainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMoveKeywordsFormGroupsAndDomainsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MoveKeywordsFormGroupsAndDomainsQuery,
    MoveKeywordsFormGroupsAndDomainsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MoveKeywordsFormGroupsAndDomainsQuery,
    MoveKeywordsFormGroupsAndDomainsQueryVariables
  >(MoveKeywordsFormGroupsAndDomainsDocument, options);
}
export function useMoveKeywordsFormGroupsAndDomainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MoveKeywordsFormGroupsAndDomainsQuery,
    MoveKeywordsFormGroupsAndDomainsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MoveKeywordsFormGroupsAndDomainsQuery,
    MoveKeywordsFormGroupsAndDomainsQueryVariables
  >(MoveKeywordsFormGroupsAndDomainsDocument, options);
}
export type MoveKeywordsFormGroupsAndDomainsQueryHookResult = ReturnType<
  typeof useMoveKeywordsFormGroupsAndDomainsQuery
>;
export type MoveKeywordsFormGroupsAndDomainsLazyQueryHookResult = ReturnType<
  typeof useMoveKeywordsFormGroupsAndDomainsLazyQuery
>;
export type MoveKeywordsFormGroupsAndDomainsQueryResult = Apollo.QueryResult<
  MoveKeywordsFormGroupsAndDomainsQuery,
  MoveKeywordsFormGroupsAndDomainsQueryVariables
>;
export const SalesCreateInvoiceDocument = gql`
  query salesCreateInvoice($organizationId: ID!) {
    adminPublicPlans {
      id
      name
      priceMonthlyUsd
      priceYearlyUsd
      priceMonthlyEur
      priceYearlyEur
      priceMonthlyDkk
      priceYearlyDkk
      maxKeywords
    }
    billingCycles {
      id
      months
    }
    adminOrganization(id: $organizationId) {
      id
      name
      paymentcontact {
        id
        currency
        paymentMethod
        paymentTerms
      }
      activePlan {
        id
        isFree
        isTrial
        active
        priceMonthlyUsd
      }
    }
  }
`;

/**
 * __useSalesCreateInvoiceQuery__
 *
 * To run a query within a React component, call `useSalesCreateInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesCreateInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesCreateInvoiceQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSalesCreateInvoiceQuery(
  baseOptions: Apollo.QueryHookOptions<SalesCreateInvoiceQuery, SalesCreateInvoiceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesCreateInvoiceQuery, SalesCreateInvoiceQueryVariables>(
    SalesCreateInvoiceDocument,
    options,
  );
}
export function useSalesCreateInvoiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesCreateInvoiceQuery,
    SalesCreateInvoiceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SalesCreateInvoiceQuery, SalesCreateInvoiceQueryVariables>(
    SalesCreateInvoiceDocument,
    options,
  );
}
export type SalesCreateInvoiceQueryHookResult = ReturnType<typeof useSalesCreateInvoiceQuery>;
export type SalesCreateInvoiceLazyQueryHookResult = ReturnType<
  typeof useSalesCreateInvoiceLazyQuery
>;
export type SalesCreateInvoiceQueryResult = Apollo.QueryResult<
  SalesCreateInvoiceQuery,
  SalesCreateInvoiceQueryVariables
>;
export const CreateInvoiceMutationDocument = gql`
  mutation createInvoiceMutation($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type CreateInvoiceMutationMutationFn = Apollo.MutationFunction<
  CreateInvoiceMutationMutation,
  CreateInvoiceMutationMutationVariables
>;

/**
 * __useCreateInvoiceMutationMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutationMutation, { data, loading, error }] = useCreateInvoiceMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvoiceMutationMutation,
    CreateInvoiceMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInvoiceMutationMutation, CreateInvoiceMutationMutationVariables>(
    CreateInvoiceMutationDocument,
    options,
  );
}
export type CreateInvoiceMutationMutationHookResult = ReturnType<
  typeof useCreateInvoiceMutationMutation
>;
export type CreateInvoiceMutationMutationResult =
  Apollo.MutationResult<CreateInvoiceMutationMutation>;
export type CreateInvoiceMutationMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceMutationMutation,
  CreateInvoiceMutationMutationVariables
>;
export const LockOrganizationDocument = gql`
  mutation LockOrganization($input: LockOrganizationInput!) {
    lockOrganization(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type LockOrganizationMutationFn = Apollo.MutationFunction<
  LockOrganizationMutation,
  LockOrganizationMutationVariables
>;

/**
 * __useLockOrganizationMutation__
 *
 * To run a mutation, you first call `useLockOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockOrganizationMutation, { data, loading, error }] = useLockOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLockOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LockOrganizationMutation,
    LockOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LockOrganizationMutation, LockOrganizationMutationVariables>(
    LockOrganizationDocument,
    options,
  );
}
export type LockOrganizationMutationHookResult = ReturnType<typeof useLockOrganizationMutation>;
export type LockOrganizationMutationResult = Apollo.MutationResult<LockOrganizationMutation>;
export type LockOrganizationMutationOptions = Apollo.BaseMutationOptions<
  LockOrganizationMutation,
  LockOrganizationMutationVariables
>;
export const OrganizationDocument = gql`
  query Organization($id: ID!) {
    adminOrganization(id: $id) {
      id
      organizationClients {
        id
        name
        domains {
          id
          domain
          displayName
        }
      }
    }
  }
`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    options,
  );
}
export function useOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    options,
  );
}
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<
  OrganizationQuery,
  OrganizationQueryVariables
>;
export const EditOrganizationNoteDocument = gql`
  mutation EditOrganizationNote($input: OrganizationNoteEditInput!) {
    editOrganizationNote(input: $input) {
      note {
        message
      }
    }
  }
`;
export type EditOrganizationNoteMutationFn = Apollo.MutationFunction<
  EditOrganizationNoteMutation,
  EditOrganizationNoteMutationVariables
>;

/**
 * __useEditOrganizationNoteMutation__
 *
 * To run a mutation, you first call `useEditOrganizationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrganizationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrganizationNoteMutation, { data, loading, error }] = useEditOrganizationNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrganizationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditOrganizationNoteMutation,
    EditOrganizationNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditOrganizationNoteMutation, EditOrganizationNoteMutationVariables>(
    EditOrganizationNoteDocument,
    options,
  );
}
export type EditOrganizationNoteMutationHookResult = ReturnType<
  typeof useEditOrganizationNoteMutation
>;
export type EditOrganizationNoteMutationResult =
  Apollo.MutationResult<EditOrganizationNoteMutation>;
export type EditOrganizationNoteMutationOptions = Apollo.BaseMutationOptions<
  EditOrganizationNoteMutation,
  EditOrganizationNoteMutationVariables
>;
export const AddOrganizationNoteDocument = gql`
  mutation AddOrganizationNote($input: OrganizationNoteAddInput!) {
    addOrganizationNote(input: $input) {
      note {
        message
      }
    }
  }
`;
export type AddOrganizationNoteMutationFn = Apollo.MutationFunction<
  AddOrganizationNoteMutation,
  AddOrganizationNoteMutationVariables
>;

/**
 * __useAddOrganizationNoteMutation__
 *
 * To run a mutation, you first call `useAddOrganizationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationNoteMutation, { data, loading, error }] = useAddOrganizationNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrganizationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOrganizationNoteMutation,
    AddOrganizationNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOrganizationNoteMutation, AddOrganizationNoteMutationVariables>(
    AddOrganizationNoteDocument,
    options,
  );
}
export type AddOrganizationNoteMutationHookResult = ReturnType<
  typeof useAddOrganizationNoteMutation
>;
export type AddOrganizationNoteMutationResult = Apollo.MutationResult<AddOrganizationNoteMutation>;
export type AddOrganizationNoteMutationOptions = Apollo.BaseMutationOptions<
  AddOrganizationNoteMutation,
  AddOrganizationNoteMutationVariables
>;
export const DeleteOrganizationNoteDocument = gql`
  mutation DeleteOrganizationNote($input: OrganizationNoteDeleteInput!) {
    deleteOrganizationNote(input: $input) {
      errors {
        messages
      }
    }
  }
`;
export type DeleteOrganizationNoteMutationFn = Apollo.MutationFunction<
  DeleteOrganizationNoteMutation,
  DeleteOrganizationNoteMutationVariables
>;

/**
 * __useDeleteOrganizationNoteMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationNoteMutation, { data, loading, error }] = useDeleteOrganizationNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrganizationNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrganizationNoteMutation,
    DeleteOrganizationNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOrganizationNoteMutation,
    DeleteOrganizationNoteMutationVariables
  >(DeleteOrganizationNoteDocument, options);
}
export type DeleteOrganizationNoteMutationHookResult = ReturnType<
  typeof useDeleteOrganizationNoteMutation
>;
export type DeleteOrganizationNoteMutationResult =
  Apollo.MutationResult<DeleteOrganizationNoteMutation>;
export type DeleteOrganizationNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganizationNoteMutation,
  DeleteOrganizationNoteMutationVariables
>;
export const SalesOrganizationPlanDocument = gql`
  query SalesOrganizationPlan($id: ID!) {
    adminOrganizationPlan(id: $id) {
      id
      maxKeywords
      maxCompetitors
      maxNumbersOfWorkspaces
      featureAdvancedMetrics
      featureApiAccess
      featureApiV4WriteAccess
    }
  }
`;

/**
 * __useSalesOrganizationPlanQuery__
 *
 * To run a query within a React component, call `useSalesOrganizationPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesOrganizationPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesOrganizationPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSalesOrganizationPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesOrganizationPlanQuery,
    SalesOrganizationPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesOrganizationPlanQuery, SalesOrganizationPlanQueryVariables>(
    SalesOrganizationPlanDocument,
    options,
  );
}
export function useSalesOrganizationPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesOrganizationPlanQuery,
    SalesOrganizationPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SalesOrganizationPlanQuery, SalesOrganizationPlanQueryVariables>(
    SalesOrganizationPlanDocument,
    options,
  );
}
export type SalesOrganizationPlanQueryHookResult = ReturnType<typeof useSalesOrganizationPlanQuery>;
export type SalesOrganizationPlanLazyQueryHookResult = ReturnType<
  typeof useSalesOrganizationPlanLazyQuery
>;
export type SalesOrganizationPlanQueryResult = Apollo.QueryResult<
  SalesOrganizationPlanQuery,
  SalesOrganizationPlanQueryVariables
>;
export const UpdateSalesOrganizationPlanDocument = gql`
  mutation UpdateSalesOrganizationPlan($input: UpdateOrganizationPlanInput!) {
    updateOrganizationPlan(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type UpdateSalesOrganizationPlanMutationFn = Apollo.MutationFunction<
  UpdateSalesOrganizationPlanMutation,
  UpdateSalesOrganizationPlanMutationVariables
>;

/**
 * __useUpdateSalesOrganizationPlanMutation__
 *
 * To run a mutation, you first call `useUpdateSalesOrganizationPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesOrganizationPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesOrganizationPlanMutation, { data, loading, error }] = useUpdateSalesOrganizationPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalesOrganizationPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSalesOrganizationPlanMutation,
    UpdateSalesOrganizationPlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSalesOrganizationPlanMutation,
    UpdateSalesOrganizationPlanMutationVariables
  >(UpdateSalesOrganizationPlanDocument, options);
}
export type UpdateSalesOrganizationPlanMutationHookResult = ReturnType<
  typeof useUpdateSalesOrganizationPlanMutation
>;
export type UpdateSalesOrganizationPlanMutationResult =
  Apollo.MutationResult<UpdateSalesOrganizationPlanMutation>;
export type UpdateSalesOrganizationPlanMutationOptions = Apollo.BaseMutationOptions<
  UpdateSalesOrganizationPlanMutation,
  UpdateSalesOrganizationPlanMutationVariables
>;
export const AffiliateGetMoneyOwedDocument = gql`
  query AffiliateGetMoneyOwed($organizationId: ID!) {
    affiliateMoneyOwed(organizationId: $organizationId) {
      minimumPayout
      owedUsd
      owedEur
      owedDkk
      owedTotalUsd
    }
  }
`;

/**
 * __useAffiliateGetMoneyOwedQuery__
 *
 * To run a query within a React component, call `useAffiliateGetMoneyOwedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateGetMoneyOwedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateGetMoneyOwedQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useAffiliateGetMoneyOwedQuery(
  baseOptions: Apollo.QueryHookOptions<
    AffiliateGetMoneyOwedQuery,
    AffiliateGetMoneyOwedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AffiliateGetMoneyOwedQuery, AffiliateGetMoneyOwedQueryVariables>(
    AffiliateGetMoneyOwedDocument,
    options,
  );
}
export function useAffiliateGetMoneyOwedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AffiliateGetMoneyOwedQuery,
    AffiliateGetMoneyOwedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AffiliateGetMoneyOwedQuery, AffiliateGetMoneyOwedQueryVariables>(
    AffiliateGetMoneyOwedDocument,
    options,
  );
}
export type AffiliateGetMoneyOwedQueryHookResult = ReturnType<typeof useAffiliateGetMoneyOwedQuery>;
export type AffiliateGetMoneyOwedLazyQueryHookResult = ReturnType<
  typeof useAffiliateGetMoneyOwedLazyQuery
>;
export type AffiliateGetMoneyOwedQueryResult = Apollo.QueryResult<
  AffiliateGetMoneyOwedQuery,
  AffiliateGetMoneyOwedQueryVariables
>;
export const PayAffiliateDocument = gql`
  mutation PayAffiliate($input: PayAffiliateInput!) {
    payAffiliate(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type PayAffiliateMutationFn = Apollo.MutationFunction<
  PayAffiliateMutation,
  PayAffiliateMutationVariables
>;

/**
 * __usePayAffiliateMutation__
 *
 * To run a mutation, you first call `usePayAffiliateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayAffiliateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payAffiliateMutation, { data, loading, error }] = usePayAffiliateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayAffiliateMutation(
  baseOptions?: Apollo.MutationHookOptions<PayAffiliateMutation, PayAffiliateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayAffiliateMutation, PayAffiliateMutationVariables>(
    PayAffiliateDocument,
    options,
  );
}
export type PayAffiliateMutationHookResult = ReturnType<typeof usePayAffiliateMutation>;
export type PayAffiliateMutationResult = Apollo.MutationResult<PayAffiliateMutation>;
export type PayAffiliateMutationOptions = Apollo.BaseMutationOptions<
  PayAffiliateMutation,
  PayAffiliateMutationVariables
>;
export const RefundInvoiceDocument = gql`
  mutation RefundInvoice($input: RefundInput!) {
    refund(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type RefundInvoiceMutationFn = Apollo.MutationFunction<
  RefundInvoiceMutation,
  RefundInvoiceMutationVariables
>;

/**
 * __useRefundInvoiceMutation__
 *
 * To run a mutation, you first call `useRefundInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundInvoiceMutation, { data, loading, error }] = useRefundInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefundInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<RefundInvoiceMutation, RefundInvoiceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefundInvoiceMutation, RefundInvoiceMutationVariables>(
    RefundInvoiceDocument,
    options,
  );
}
export type RefundInvoiceMutationHookResult = ReturnType<typeof useRefundInvoiceMutation>;
export type RefundInvoiceMutationResult = Apollo.MutationResult<RefundInvoiceMutation>;
export type RefundInvoiceMutationOptions = Apollo.BaseMutationOptions<
  RefundInvoiceMutation,
  RefundInvoiceMutationVariables
>;
export const Users_AdminUsersDocument = gql`
  query users_adminUsers {
    user {
      id
      fullName
      email
      userType
      __typename
      organization {
        id
        activePlan {
          id
          maxUsers
        }
      }
    }
    users(usersAreAdmins: true) {
      id
      fullName
      email
      userType
      __typename
    }
  }
`;

/**
 * __useUsers_AdminUsersQuery__
 *
 * To run a query within a React component, call `useUsers_AdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsers_AdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsers_AdminUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsers_AdminUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<Users_AdminUsersQuery, Users_AdminUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Users_AdminUsersQuery, Users_AdminUsersQueryVariables>(
    Users_AdminUsersDocument,
    options,
  );
}
export function useUsers_AdminUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Users_AdminUsersQuery, Users_AdminUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Users_AdminUsersQuery, Users_AdminUsersQueryVariables>(
    Users_AdminUsersDocument,
    options,
  );
}
export type Users_AdminUsersQueryHookResult = ReturnType<typeof useUsers_AdminUsersQuery>;
export type Users_AdminUsersLazyQueryHookResult = ReturnType<typeof useUsers_AdminUsersLazyQuery>;
export type Users_AdminUsersQueryResult = Apollo.QueryResult<
  Users_AdminUsersQuery,
  Users_AdminUsersQueryVariables
>;
export const Take_ControlDocument = gql`
  query take_control {
    takeControl
  }
`;

/**
 * __useTake_ControlQuery__
 *
 * To run a query within a React component, call `useTake_ControlQuery` and pass it any options that fit your needs.
 * When your component renders, `useTake_ControlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTake_ControlQuery({
 *   variables: {
 *   },
 * });
 */
export function useTake_ControlQuery(
  baseOptions?: Apollo.QueryHookOptions<Take_ControlQuery, Take_ControlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Take_ControlQuery, Take_ControlQueryVariables>(
    Take_ControlDocument,
    options,
  );
}
export function useTake_ControlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Take_ControlQuery, Take_ControlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Take_ControlQuery, Take_ControlQueryVariables>(
    Take_ControlDocument,
    options,
  );
}
export type Take_ControlQueryHookResult = ReturnType<typeof useTake_ControlQuery>;
export type Take_ControlLazyQueryHookResult = ReturnType<typeof useTake_ControlLazyQuery>;
export type Take_ControlQueryResult = Apollo.QueryResult<
  Take_ControlQuery,
  Take_ControlQueryVariables
>;
export const Active_SessionsDocument = gql`
  query active_sessions {
    activeSessions
  }
`;

/**
 * __useActive_SessionsQuery__
 *
 * To run a query within a React component, call `useActive_SessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActive_SessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActive_SessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActive_SessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<Active_SessionsQuery, Active_SessionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Active_SessionsQuery, Active_SessionsQueryVariables>(
    Active_SessionsDocument,
    options,
  );
}
export function useActive_SessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Active_SessionsQuery, Active_SessionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Active_SessionsQuery, Active_SessionsQueryVariables>(
    Active_SessionsDocument,
    options,
  );
}
export type Active_SessionsQueryHookResult = ReturnType<typeof useActive_SessionsQuery>;
export type Active_SessionsLazyQueryHookResult = ReturnType<typeof useActive_SessionsLazyQuery>;
export type Active_SessionsQueryResult = Apollo.QueryResult<
  Active_SessionsQuery,
  Active_SessionsQueryVariables
>;
export const ModalServiceMessagesDocument = gql`
  query ModalServiceMessages {
    serviceMessages {
      id
      title
      message
      level
      createdAt
    }
  }
`;

/**
 * __useModalServiceMessagesQuery__
 *
 * To run a query within a React component, call `useModalServiceMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalServiceMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalServiceMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useModalServiceMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ModalServiceMessagesQuery,
    ModalServiceMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ModalServiceMessagesQuery, ModalServiceMessagesQueryVariables>(
    ModalServiceMessagesDocument,
    options,
  );
}
export function useModalServiceMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModalServiceMessagesQuery,
    ModalServiceMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ModalServiceMessagesQuery, ModalServiceMessagesQueryVariables>(
    ModalServiceMessagesDocument,
    options,
  );
}
export type ModalServiceMessagesQueryHookResult = ReturnType<typeof useModalServiceMessagesQuery>;
export type ModalServiceMessagesLazyQueryHookResult = ReturnType<
  typeof useModalServiceMessagesLazyQuery
>;
export type ModalServiceMessagesQueryResult = Apollo.QueryResult<
  ModalServiceMessagesQuery,
  ModalServiceMessagesQueryVariables
>;
export const CountrylocalesDocument = gql`
  query Countrylocales {
    countrylocales {
      ...Countrylocale
    }
  }
  ${CountrylocaleFragmentDoc}
`;

/**
 * __useCountrylocalesQuery__
 *
 * To run a query within a React component, call `useCountrylocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountrylocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountrylocalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountrylocalesQuery(
  baseOptions?: Apollo.QueryHookOptions<CountrylocalesQuery, CountrylocalesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountrylocalesQuery, CountrylocalesQueryVariables>(
    CountrylocalesDocument,
    options,
  );
}
export function useCountrylocalesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountrylocalesQuery, CountrylocalesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountrylocalesQuery, CountrylocalesQueryVariables>(
    CountrylocalesDocument,
    options,
  );
}
export type CountrylocalesQueryHookResult = ReturnType<typeof useCountrylocalesQuery>;
export type CountrylocalesLazyQueryHookResult = ReturnType<typeof useCountrylocalesLazyQuery>;
export type CountrylocalesQueryResult = Apollo.QueryResult<
  CountrylocalesQuery,
  CountrylocalesQueryVariables
>;
export const GoaAccountsDocument = gql`
  query GoaAccounts {
    user {
      id
      organization {
        id
        googleConnections {
          id
          type
          description
        }
      }
    }
  }
`;

/**
 * __useGoaAccountsQuery__
 *
 * To run a query within a React component, call `useGoaAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoaAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoaAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoaAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<GoaAccountsQuery, GoaAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GoaAccountsQuery, GoaAccountsQueryVariables>(GoaAccountsDocument, options);
}
export function useGoaAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GoaAccountsQuery, GoaAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GoaAccountsQuery, GoaAccountsQueryVariables>(
    GoaAccountsDocument,
    options,
  );
}
export type GoaAccountsQueryHookResult = ReturnType<typeof useGoaAccountsQuery>;
export type GoaAccountsLazyQueryHookResult = ReturnType<typeof useGoaAccountsLazyQuery>;
export type GoaAccountsQueryResult = Apollo.QueryResult<
  GoaAccountsQuery,
  GoaAccountsQueryVariables
>;
export const AddGoogleAnalyticsAccountDocument = gql`
  mutation addGoogleAnalyticsAccount(
    $addGoogleAnalyticsAccountInput: AddGoogleAnalyticsAccountInput!
  ) {
    addGoogleAnalyticsAccount(input: $addGoogleAnalyticsAccountInput) {
      domain {
        id
      }
    }
  }
`;
export type AddGoogleAnalyticsAccountMutationFn = Apollo.MutationFunction<
  AddGoogleAnalyticsAccountMutation,
  AddGoogleAnalyticsAccountMutationVariables
>;

/**
 * __useAddGoogleAnalyticsAccountMutation__
 *
 * To run a mutation, you first call `useAddGoogleAnalyticsAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGoogleAnalyticsAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGoogleAnalyticsAccountMutation, { data, loading, error }] = useAddGoogleAnalyticsAccountMutation({
 *   variables: {
 *      addGoogleAnalyticsAccountInput: // value for 'addGoogleAnalyticsAccountInput'
 *   },
 * });
 */
export function useAddGoogleAnalyticsAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGoogleAnalyticsAccountMutation,
    AddGoogleAnalyticsAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGoogleAnalyticsAccountMutation,
    AddGoogleAnalyticsAccountMutationVariables
  >(AddGoogleAnalyticsAccountDocument, options);
}
export type AddGoogleAnalyticsAccountMutationHookResult = ReturnType<
  typeof useAddGoogleAnalyticsAccountMutation
>;
export type AddGoogleAnalyticsAccountMutationResult =
  Apollo.MutationResult<AddGoogleAnalyticsAccountMutation>;
export type AddGoogleAnalyticsAccountMutationOptions = Apollo.BaseMutationOptions<
  AddGoogleAnalyticsAccountMutation,
  AddGoogleAnalyticsAccountMutationVariables
>;
export const ConnectToGaPropertyUaPropertiesDocument = gql`
  query ConnectToGAPropertyUAProperties($connectionId: ID!, $accountId: String!) {
    googleConnection(id: $connectionId) {
      gaProperties(gaAccountId: $accountId) {
        id
        name
        websiteUrl
      }
    }
  }
`;

/**
 * __useConnectToGaPropertyUaPropertiesQuery__
 *
 * To run a query within a React component, call `useConnectToGaPropertyUaPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectToGaPropertyUaPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectToGaPropertyUaPropertiesQuery({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useConnectToGaPropertyUaPropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectToGaPropertyUaPropertiesQuery,
    ConnectToGaPropertyUaPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConnectToGaPropertyUaPropertiesQuery,
    ConnectToGaPropertyUaPropertiesQueryVariables
  >(ConnectToGaPropertyUaPropertiesDocument, options);
}
export function useConnectToGaPropertyUaPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectToGaPropertyUaPropertiesQuery,
    ConnectToGaPropertyUaPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConnectToGaPropertyUaPropertiesQuery,
    ConnectToGaPropertyUaPropertiesQueryVariables
  >(ConnectToGaPropertyUaPropertiesDocument, options);
}
export type ConnectToGaPropertyUaPropertiesQueryHookResult = ReturnType<
  typeof useConnectToGaPropertyUaPropertiesQuery
>;
export type ConnectToGaPropertyUaPropertiesLazyQueryHookResult = ReturnType<
  typeof useConnectToGaPropertyUaPropertiesLazyQuery
>;
export type ConnectToGaPropertyUaPropertiesQueryResult = Apollo.QueryResult<
  ConnectToGaPropertyUaPropertiesQuery,
  ConnectToGaPropertyUaPropertiesQueryVariables
>;
export const ConnectToGaPropertyGa4PropertiesDocument = gql`
  query ConnectToGAPropertyGA4Properties($connectionId: ID!, $accountId: String!) {
    googleConnection(id: $connectionId) {
      ga4Properties(gaAccountId: $accountId) {
        name
        displayName
      }
    }
  }
`;

/**
 * __useConnectToGaPropertyGa4PropertiesQuery__
 *
 * To run a query within a React component, call `useConnectToGaPropertyGa4PropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectToGaPropertyGa4PropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectToGaPropertyGa4PropertiesQuery({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useConnectToGaPropertyGa4PropertiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectToGaPropertyGa4PropertiesQuery,
    ConnectToGaPropertyGa4PropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConnectToGaPropertyGa4PropertiesQuery,
    ConnectToGaPropertyGa4PropertiesQueryVariables
  >(ConnectToGaPropertyGa4PropertiesDocument, options);
}
export function useConnectToGaPropertyGa4PropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectToGaPropertyGa4PropertiesQuery,
    ConnectToGaPropertyGa4PropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConnectToGaPropertyGa4PropertiesQuery,
    ConnectToGaPropertyGa4PropertiesQueryVariables
  >(ConnectToGaPropertyGa4PropertiesDocument, options);
}
export type ConnectToGaPropertyGa4PropertiesQueryHookResult = ReturnType<
  typeof useConnectToGaPropertyGa4PropertiesQuery
>;
export type ConnectToGaPropertyGa4PropertiesLazyQueryHookResult = ReturnType<
  typeof useConnectToGaPropertyGa4PropertiesLazyQuery
>;
export type ConnectToGaPropertyGa4PropertiesQueryResult = Apollo.QueryResult<
  ConnectToGaPropertyGa4PropertiesQuery,
  ConnectToGaPropertyGa4PropertiesQueryVariables
>;
export const Ga_Connect_DomainsListDocument = gql`
  query ga_connect_domainsList(
    $limit: Int
    $offset: Int
    $onlyNotConnectedToGa: Boolean
    $onlyNotConnectedToGsc: Boolean
    $clientId: Int
  ) {
    domainsList(
      limit: $limit
      offset: $offset
      onlyNotConnectedToGa: $onlyNotConnectedToGa
      onlyNotConnectedToGsc: $onlyNotConnectedToGsc
      clientId: $clientId
    ) {
      id
      domain
      displayName
      client {
        id
        numberOfDomains
      }
      youtubeChannelName
      googleOauthConnectionGsc {
        id
      }
    }
  }
`;

/**
 * __useGa_Connect_DomainsListQuery__
 *
 * To run a query within a React component, call `useGa_Connect_DomainsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGa_Connect_DomainsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGa_Connect_DomainsListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      onlyNotConnectedToGa: // value for 'onlyNotConnectedToGa'
 *      onlyNotConnectedToGsc: // value for 'onlyNotConnectedToGsc'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGa_Connect_DomainsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Ga_Connect_DomainsListQuery,
    Ga_Connect_DomainsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Ga_Connect_DomainsListQuery, Ga_Connect_DomainsListQueryVariables>(
    Ga_Connect_DomainsListDocument,
    options,
  );
}
export function useGa_Connect_DomainsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Ga_Connect_DomainsListQuery,
    Ga_Connect_DomainsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Ga_Connect_DomainsListQuery, Ga_Connect_DomainsListQueryVariables>(
    Ga_Connect_DomainsListDocument,
    options,
  );
}
export type Ga_Connect_DomainsListQueryHookResult = ReturnType<
  typeof useGa_Connect_DomainsListQuery
>;
export type Ga_Connect_DomainsListLazyQueryHookResult = ReturnType<
  typeof useGa_Connect_DomainsListLazyQuery
>;
export type Ga_Connect_DomainsListQueryResult = Apollo.QueryResult<
  Ga_Connect_DomainsListQuery,
  Ga_Connect_DomainsListQueryVariables
>;
export const ConnectToGa_DomainDocument = gql`
  query ConnectToGA_domain($id: ID!) {
    domain(id: $id) {
      id
      googleOauthConnectionGa {
        id
      }
    }
  }
`;

/**
 * __useConnectToGa_DomainQuery__
 *
 * To run a query within a React component, call `useConnectToGa_DomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectToGa_DomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectToGa_DomainQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConnectToGa_DomainQuery(
  baseOptions: Apollo.QueryHookOptions<ConnectToGa_DomainQuery, ConnectToGa_DomainQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConnectToGa_DomainQuery, ConnectToGa_DomainQueryVariables>(
    ConnectToGa_DomainDocument,
    options,
  );
}
export function useConnectToGa_DomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectToGa_DomainQuery,
    ConnectToGa_DomainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConnectToGa_DomainQuery, ConnectToGa_DomainQueryVariables>(
    ConnectToGa_DomainDocument,
    options,
  );
}
export type ConnectToGa_DomainQueryHookResult = ReturnType<typeof useConnectToGa_DomainQuery>;
export type ConnectToGa_DomainLazyQueryHookResult = ReturnType<
  typeof useConnectToGa_DomainLazyQuery
>;
export type ConnectToGa_DomainQueryResult = Apollo.QueryResult<
  ConnectToGa_DomainQuery,
  ConnectToGa_DomainQueryVariables
>;
export const Gsc_WebsitesDocument = gql`
  query gsc_websites($goaAccountID: ID!) {
    googleConnection(id: $goaAccountID) {
      gscSites {
        siteUrl
      }
    }
  }
`;

/**
 * __useGsc_WebsitesQuery__
 *
 * To run a query within a React component, call `useGsc_WebsitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGsc_WebsitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGsc_WebsitesQuery({
 *   variables: {
 *      goaAccountID: // value for 'goaAccountID'
 *   },
 * });
 */
export function useGsc_WebsitesQuery(
  baseOptions: Apollo.QueryHookOptions<Gsc_WebsitesQuery, Gsc_WebsitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Gsc_WebsitesQuery, Gsc_WebsitesQueryVariables>(
    Gsc_WebsitesDocument,
    options,
  );
}
export function useGsc_WebsitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Gsc_WebsitesQuery, Gsc_WebsitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Gsc_WebsitesQuery, Gsc_WebsitesQueryVariables>(
    Gsc_WebsitesDocument,
    options,
  );
}
export type Gsc_WebsitesQueryHookResult = ReturnType<typeof useGsc_WebsitesQuery>;
export type Gsc_WebsitesLazyQueryHookResult = ReturnType<typeof useGsc_WebsitesLazyQuery>;
export type Gsc_WebsitesQueryResult = Apollo.QueryResult<
  Gsc_WebsitesQuery,
  Gsc_WebsitesQueryVariables
>;
export const Add_Google_Search_Console_AccountDocument = gql`
  mutation add_google_search_console_account(
    $addGoogleSearchConsoleAccountInput: AddGoogleSearchConsoleAccountInput!
  ) {
    addGoogleSearchConsoleAccount(input: $addGoogleSearchConsoleAccountInput) {
      domain {
        id
      }
    }
  }
`;
export type Add_Google_Search_Console_AccountMutationFn = Apollo.MutationFunction<
  Add_Google_Search_Console_AccountMutation,
  Add_Google_Search_Console_AccountMutationVariables
>;

/**
 * __useAdd_Google_Search_Console_AccountMutation__
 *
 * To run a mutation, you first call `useAdd_Google_Search_Console_AccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Google_Search_Console_AccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGoogleSearchConsoleAccountMutation, { data, loading, error }] = useAdd_Google_Search_Console_AccountMutation({
 *   variables: {
 *      addGoogleSearchConsoleAccountInput: // value for 'addGoogleSearchConsoleAccountInput'
 *   },
 * });
 */
export function useAdd_Google_Search_Console_AccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Add_Google_Search_Console_AccountMutation,
    Add_Google_Search_Console_AccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Add_Google_Search_Console_AccountMutation,
    Add_Google_Search_Console_AccountMutationVariables
  >(Add_Google_Search_Console_AccountDocument, options);
}
export type Add_Google_Search_Console_AccountMutationHookResult = ReturnType<
  typeof useAdd_Google_Search_Console_AccountMutation
>;
export type Add_Google_Search_Console_AccountMutationResult =
  Apollo.MutationResult<Add_Google_Search_Console_AccountMutation>;
export type Add_Google_Search_Console_AccountMutationOptions = Apollo.BaseMutationOptions<
  Add_Google_Search_Console_AccountMutation,
  Add_Google_Search_Console_AccountMutationVariables
>;
export const AddGscAccountDocument = gql`
  mutation AddGSCAccount($addGoogleSearchConsoleAccountInput: AddGoogleSearchConsoleAccountInput!) {
    addGoogleSearchConsoleAccount(input: $addGoogleSearchConsoleAccountInput) {
      domain {
        id
      }
    }
  }
`;
export type AddGscAccountMutationFn = Apollo.MutationFunction<
  AddGscAccountMutation,
  AddGscAccountMutationVariables
>;

/**
 * __useAddGscAccountMutation__
 *
 * To run a mutation, you first call `useAddGscAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGscAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGscAccountMutation, { data, loading, error }] = useAddGscAccountMutation({
 *   variables: {
 *      addGoogleSearchConsoleAccountInput: // value for 'addGoogleSearchConsoleAccountInput'
 *   },
 * });
 */
export function useAddGscAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<AddGscAccountMutation, AddGscAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddGscAccountMutation, AddGscAccountMutationVariables>(
    AddGscAccountDocument,
    options,
  );
}
export type AddGscAccountMutationHookResult = ReturnType<typeof useAddGscAccountMutation>;
export type AddGscAccountMutationResult = Apollo.MutationResult<AddGscAccountMutation>;
export type AddGscAccountMutationOptions = Apollo.BaseMutationOptions<
  AddGscAccountMutation,
  AddGscAccountMutationVariables
>;
export const GscWebsitePage_GscWebsitesDocument = gql`
  query gscWebsitePage_gscWebsites($goaAccountID: ID!) {
    googleConnection(id: $goaAccountID) {
      gscSites {
        siteUrl
      }
    }
  }
`;

/**
 * __useGscWebsitePage_GscWebsitesQuery__
 *
 * To run a query within a React component, call `useGscWebsitePage_GscWebsitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGscWebsitePage_GscWebsitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGscWebsitePage_GscWebsitesQuery({
 *   variables: {
 *      goaAccountID: // value for 'goaAccountID'
 *   },
 * });
 */
export function useGscWebsitePage_GscWebsitesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GscWebsitePage_GscWebsitesQuery,
    GscWebsitePage_GscWebsitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GscWebsitePage_GscWebsitesQuery, GscWebsitePage_GscWebsitesQueryVariables>(
    GscWebsitePage_GscWebsitesDocument,
    options,
  );
}
export function useGscWebsitePage_GscWebsitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GscWebsitePage_GscWebsitesQuery,
    GscWebsitePage_GscWebsitesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GscWebsitePage_GscWebsitesQuery,
    GscWebsitePage_GscWebsitesQueryVariables
  >(GscWebsitePage_GscWebsitesDocument, options);
}
export type GscWebsitePage_GscWebsitesQueryHookResult = ReturnType<
  typeof useGscWebsitePage_GscWebsitesQuery
>;
export type GscWebsitePage_GscWebsitesLazyQueryHookResult = ReturnType<
  typeof useGscWebsitePage_GscWebsitesLazyQuery
>;
export type GscWebsitePage_GscWebsitesQueryResult = Apollo.QueryResult<
  GscWebsitePage_GscWebsitesQuery,
  GscWebsitePage_GscWebsitesQueryVariables
>;
export const GetAffiliateLinkDetailsDocument = gql`
  query GetAffiliateLinkDetails {
    affiliate {
      details {
        affiliateId
      }
    }
  }
`;

/**
 * __useGetAffiliateLinkDetailsQuery__
 *
 * To run a query within a React component, call `useGetAffiliateLinkDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAffiliateLinkDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAffiliateLinkDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAffiliateLinkDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAffiliateLinkDetailsQuery,
    GetAffiliateLinkDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAffiliateLinkDetailsQuery, GetAffiliateLinkDetailsQueryVariables>(
    GetAffiliateLinkDetailsDocument,
    options,
  );
}
export function useGetAffiliateLinkDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAffiliateLinkDetailsQuery,
    GetAffiliateLinkDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAffiliateLinkDetailsQuery, GetAffiliateLinkDetailsQueryVariables>(
    GetAffiliateLinkDetailsDocument,
    options,
  );
}
export type GetAffiliateLinkDetailsQueryHookResult = ReturnType<
  typeof useGetAffiliateLinkDetailsQuery
>;
export type GetAffiliateLinkDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAffiliateLinkDetailsLazyQuery
>;
export type GetAffiliateLinkDetailsQueryResult = Apollo.QueryResult<
  GetAffiliateLinkDetailsQuery,
  GetAffiliateLinkDetailsQueryVariables
>;
export const EnableOveruseDocument = gql`
  mutation EnableOveruse($input: EnableOveruseInput!) {
    enableOveruse(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type EnableOveruseMutationFn = Apollo.MutationFunction<
  EnableOveruseMutation,
  EnableOveruseMutationVariables
>;

/**
 * __useEnableOveruseMutation__
 *
 * To run a mutation, you first call `useEnableOveruseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableOveruseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableOveruseMutation, { data, loading, error }] = useEnableOveruseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableOveruseMutation(
  baseOptions?: Apollo.MutationHookOptions<EnableOveruseMutation, EnableOveruseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnableOveruseMutation, EnableOveruseMutationVariables>(
    EnableOveruseDocument,
    options,
  );
}
export type EnableOveruseMutationHookResult = ReturnType<typeof useEnableOveruseMutation>;
export type EnableOveruseMutationResult = Apollo.MutationResult<EnableOveruseMutation>;
export type EnableOveruseMutationOptions = Apollo.BaseMutationOptions<
  EnableOveruseMutation,
  EnableOveruseMutationVariables
>;
export const LatestCompletedScrapeDateDocument = gql`
  query latestCompletedScrapeDate {
    clients {
      id
      latestCompletedScrapeDate
      domains {
        id
      }
    }
  }
`;

/**
 * __useLatestCompletedScrapeDateQuery__
 *
 * To run a query within a React component, call `useLatestCompletedScrapeDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestCompletedScrapeDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestCompletedScrapeDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestCompletedScrapeDateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LatestCompletedScrapeDateQuery,
    LatestCompletedScrapeDateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LatestCompletedScrapeDateQuery, LatestCompletedScrapeDateQueryVariables>(
    LatestCompletedScrapeDateDocument,
    options,
  );
}
export function useLatestCompletedScrapeDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestCompletedScrapeDateQuery,
    LatestCompletedScrapeDateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LatestCompletedScrapeDateQuery,
    LatestCompletedScrapeDateQueryVariables
  >(LatestCompletedScrapeDateDocument, options);
}
export type LatestCompletedScrapeDateQueryHookResult = ReturnType<
  typeof useLatestCompletedScrapeDateQuery
>;
export type LatestCompletedScrapeDateLazyQueryHookResult = ReturnType<
  typeof useLatestCompletedScrapeDateLazyQuery
>;
export type LatestCompletedScrapeDateQueryResult = Apollo.QueryResult<
  LatestCompletedScrapeDateQuery,
  LatestCompletedScrapeDateQueryVariables
>;
export const QuickNavigationDataDocument = gql`
  query quickNavigationData {
    user {
      quickNavigationData
    }
  }
`;

/**
 * __useQuickNavigationDataQuery__
 *
 * To run a query within a React component, call `useQuickNavigationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickNavigationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickNavigationDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickNavigationDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QuickNavigationDataQuery,
    QuickNavigationDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuickNavigationDataQuery, QuickNavigationDataQueryVariables>(
    QuickNavigationDataDocument,
    options,
  );
}
export function useQuickNavigationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuickNavigationDataQuery,
    QuickNavigationDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuickNavigationDataQuery, QuickNavigationDataQueryVariables>(
    QuickNavigationDataDocument,
    options,
  );
}
export type QuickNavigationDataQueryHookResult = ReturnType<typeof useQuickNavigationDataQuery>;
export type QuickNavigationDataLazyQueryHookResult = ReturnType<
  typeof useQuickNavigationDataLazyQuery
>;
export type QuickNavigationDataQueryResult = Apollo.QueryResult<
  QuickNavigationDataQuery,
  QuickNavigationDataQueryVariables
>;
export const RefreshKeywordsDocument = gql`
  mutation RefreshKeywords($domainId: ID!, $refreshTaskId: String!) {
    scrapeDomain(input: { id: $domainId, refreshTaskId: $refreshTaskId }) {
      domain {
        id
      }
      queued
      reason
    }
  }
`;
export type RefreshKeywordsMutationFn = Apollo.MutationFunction<
  RefreshKeywordsMutation,
  RefreshKeywordsMutationVariables
>;

/**
 * __useRefreshKeywordsMutation__
 *
 * To run a mutation, you first call `useRefreshKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshKeywordsMutation, { data, loading, error }] = useRefreshKeywordsMutation({
 *   variables: {
 *      domainId: // value for 'domainId'
 *      refreshTaskId: // value for 'refreshTaskId'
 *   },
 * });
 */
export function useRefreshKeywordsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshKeywordsMutation,
    RefreshKeywordsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshKeywordsMutation, RefreshKeywordsMutationVariables>(
    RefreshKeywordsDocument,
    options,
  );
}
export type RefreshKeywordsMutationHookResult = ReturnType<typeof useRefreshKeywordsMutation>;
export type RefreshKeywordsMutationResult = Apollo.MutationResult<RefreshKeywordsMutation>;
export type RefreshKeywordsMutationOptions = Apollo.BaseMutationOptions<
  RefreshKeywordsMutation,
  RefreshKeywordsMutationVariables
>;
export const MaxKeywordsDocument = gql`
  query MaxKeywords {
    user {
      id
      organization {
        id
        activePlan {
          actualMaxKeywords
        }
      }
    }
  }
`;

/**
 * __useMaxKeywordsQuery__
 *
 * To run a query within a React component, call `useMaxKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaxKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaxKeywordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaxKeywordsQuery(
  baseOptions?: Apollo.QueryHookOptions<MaxKeywordsQuery, MaxKeywordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MaxKeywordsQuery, MaxKeywordsQueryVariables>(MaxKeywordsDocument, options);
}
export function useMaxKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MaxKeywordsQuery, MaxKeywordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MaxKeywordsQuery, MaxKeywordsQueryVariables>(
    MaxKeywordsDocument,
    options,
  );
}
export type MaxKeywordsQueryHookResult = ReturnType<typeof useMaxKeywordsQuery>;
export type MaxKeywordsLazyQueryHookResult = ReturnType<typeof useMaxKeywordsLazyQuery>;
export type MaxKeywordsQueryResult = Apollo.QueryResult<
  MaxKeywordsQuery,
  MaxKeywordsQueryVariables
>;
export const DomainUnrankedWeightDocument = gql`
  query DomainUnrankedWeight($id: ID!) {
    domain(id: $id) {
      unrankedWeight
    }
  }
`;

/**
 * __useDomainUnrankedWeightQuery__
 *
 * To run a query within a React component, call `useDomainUnrankedWeightQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainUnrankedWeightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainUnrankedWeightQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDomainUnrankedWeightQuery(
  baseOptions: Apollo.QueryHookOptions<
    DomainUnrankedWeightQuery,
    DomainUnrankedWeightQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DomainUnrankedWeightQuery, DomainUnrankedWeightQueryVariables>(
    DomainUnrankedWeightDocument,
    options,
  );
}
export function useDomainUnrankedWeightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DomainUnrankedWeightQuery,
    DomainUnrankedWeightQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DomainUnrankedWeightQuery, DomainUnrankedWeightQueryVariables>(
    DomainUnrankedWeightDocument,
    options,
  );
}
export type DomainUnrankedWeightQueryHookResult = ReturnType<typeof useDomainUnrankedWeightQuery>;
export type DomainUnrankedWeightLazyQueryHookResult = ReturnType<
  typeof useDomainUnrankedWeightLazyQuery
>;
export type DomainUnrankedWeightQueryResult = Apollo.QueryResult<
  DomainUnrankedWeightQuery,
  DomainUnrankedWeightQueryVariables
>;
export const OrganizationUnrankedWeightDocument = gql`
  query OrganizationUnrankedWeight($id: ID!) {
    organization(id: $id) {
      unrankedWeight
    }
  }
`;

/**
 * __useOrganizationUnrankedWeightQuery__
 *
 * To run a query within a React component, call `useOrganizationUnrankedWeightQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUnrankedWeightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUnrankedWeightQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationUnrankedWeightQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationUnrankedWeightQuery,
    OrganizationUnrankedWeightQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationUnrankedWeightQuery, OrganizationUnrankedWeightQueryVariables>(
    OrganizationUnrankedWeightDocument,
    options,
  );
}
export function useOrganizationUnrankedWeightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationUnrankedWeightQuery,
    OrganizationUnrankedWeightQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationUnrankedWeightQuery,
    OrganizationUnrankedWeightQueryVariables
  >(OrganizationUnrankedWeightDocument, options);
}
export type OrganizationUnrankedWeightQueryHookResult = ReturnType<
  typeof useOrganizationUnrankedWeightQuery
>;
export type OrganizationUnrankedWeightLazyQueryHookResult = ReturnType<
  typeof useOrganizationUnrankedWeightLazyQuery
>;
export type OrganizationUnrankedWeightQueryResult = Apollo.QueryResult<
  OrganizationUnrankedWeightQuery,
  OrganizationUnrankedWeightQueryVariables
>;
export const AvailableDisplayCurrenciesDocument = gql`
  query AvailableDisplayCurrencies {
    exchangeRate {
      availableDisplayCurrencies
    }
  }
`;

/**
 * __useAvailableDisplayCurrenciesQuery__
 *
 * To run a query within a React component, call `useAvailableDisplayCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableDisplayCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableDisplayCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableDisplayCurrenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableDisplayCurrenciesQuery,
    AvailableDisplayCurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailableDisplayCurrenciesQuery, AvailableDisplayCurrenciesQueryVariables>(
    AvailableDisplayCurrenciesDocument,
    options,
  );
}
export function useAvailableDisplayCurrenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableDisplayCurrenciesQuery,
    AvailableDisplayCurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableDisplayCurrenciesQuery,
    AvailableDisplayCurrenciesQueryVariables
  >(AvailableDisplayCurrenciesDocument, options);
}
export type AvailableDisplayCurrenciesQueryHookResult = ReturnType<
  typeof useAvailableDisplayCurrenciesQuery
>;
export type AvailableDisplayCurrenciesLazyQueryHookResult = ReturnType<
  typeof useAvailableDisplayCurrenciesLazyQuery
>;
export type AvailableDisplayCurrenciesQueryResult = Apollo.QueryResult<
  AvailableDisplayCurrenciesQuery,
  AvailableDisplayCurrenciesQueryVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(
    UpdateOrganizationDocument,
    options,
  );
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const GetOrganizationLogoDocument = gql`
  query GetOrganizationLogo {
    user {
      organization {
        logo
      }
    }
  }
`;

/**
 * __useGetOrganizationLogoQuery__
 *
 * To run a query within a React component, call `useGetOrganizationLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationLogoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationLogoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationLogoQuery,
    GetOrganizationLogoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationLogoQuery, GetOrganizationLogoQueryVariables>(
    GetOrganizationLogoDocument,
    options,
  );
}
export function useGetOrganizationLogoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationLogoQuery,
    GetOrganizationLogoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganizationLogoQuery, GetOrganizationLogoQueryVariables>(
    GetOrganizationLogoDocument,
    options,
  );
}
export type GetOrganizationLogoQueryHookResult = ReturnType<typeof useGetOrganizationLogoQuery>;
export type GetOrganizationLogoLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationLogoLazyQuery
>;
export type GetOrganizationLogoQueryResult = Apollo.QueryResult<
  GetOrganizationLogoQuery,
  GetOrganizationLogoQueryVariables
>;
export const PendingRequestsDocument = gql`
  query PendingRequests {
    user {
      id
      organization {
        id
        pendingMultiAccounts {
          ...PendingRequest
        }
      }
    }
  }
  ${PendingRequestFragmentDoc}
`;

/**
 * __usePendingRequestsQuery__
 *
 * To run a query within a React component, call `usePendingRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<PendingRequestsQuery, PendingRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PendingRequestsQuery, PendingRequestsQueryVariables>(
    PendingRequestsDocument,
    options,
  );
}
export function usePendingRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PendingRequestsQuery, PendingRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PendingRequestsQuery, PendingRequestsQueryVariables>(
    PendingRequestsDocument,
    options,
  );
}
export type PendingRequestsQueryHookResult = ReturnType<typeof usePendingRequestsQuery>;
export type PendingRequestsLazyQueryHookResult = ReturnType<typeof usePendingRequestsLazyQuery>;
export type PendingRequestsQueryResult = Apollo.QueryResult<
  PendingRequestsQuery,
  PendingRequestsQueryVariables
>;
export const RemoveAccessRequestDocument = gql`
  mutation RemoveAccessRequest($removeAccessRequestInput: RemoveMultiAccountRequestInput!) {
    removeMultiAccountRequest(input: $removeAccessRequestInput) {
      errors {
        field
        messages
      }
    }
  }
`;
export type RemoveAccessRequestMutationFn = Apollo.MutationFunction<
  RemoveAccessRequestMutation,
  RemoveAccessRequestMutationVariables
>;

/**
 * __useRemoveAccessRequestMutation__
 *
 * To run a mutation, you first call `useRemoveAccessRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccessRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccessRequestMutation, { data, loading, error }] = useRemoveAccessRequestMutation({
 *   variables: {
 *      removeAccessRequestInput: // value for 'removeAccessRequestInput'
 *   },
 * });
 */
export function useRemoveAccessRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAccessRequestMutation,
    RemoveAccessRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveAccessRequestMutation, RemoveAccessRequestMutationVariables>(
    RemoveAccessRequestDocument,
    options,
  );
}
export type RemoveAccessRequestMutationHookResult = ReturnType<
  typeof useRemoveAccessRequestMutation
>;
export type RemoveAccessRequestMutationResult = Apollo.MutationResult<RemoveAccessRequestMutation>;
export type RemoveAccessRequestMutationOptions = Apollo.BaseMutationOptions<
  RemoveAccessRequestMutation,
  RemoveAccessRequestMutationVariables
>;
export const SubAccountsInformationForm_UpdateOrganizationDocument = gql`
  mutation subAccountsInformationForm_updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type SubAccountsInformationForm_UpdateOrganizationMutationFn = Apollo.MutationFunction<
  SubAccountsInformationForm_UpdateOrganizationMutation,
  SubAccountsInformationForm_UpdateOrganizationMutationVariables
>;

/**
 * __useSubAccountsInformationForm_UpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useSubAccountsInformationForm_UpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubAccountsInformationForm_UpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subAccountsInformationFormUpdateOrganizationMutation, { data, loading, error }] = useSubAccountsInformationForm_UpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubAccountsInformationForm_UpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubAccountsInformationForm_UpdateOrganizationMutation,
    SubAccountsInformationForm_UpdateOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubAccountsInformationForm_UpdateOrganizationMutation,
    SubAccountsInformationForm_UpdateOrganizationMutationVariables
  >(SubAccountsInformationForm_UpdateOrganizationDocument, options);
}
export type SubAccountsInformationForm_UpdateOrganizationMutationHookResult = ReturnType<
  typeof useSubAccountsInformationForm_UpdateOrganizationMutation
>;
export type SubAccountsInformationForm_UpdateOrganizationMutationResult =
  Apollo.MutationResult<SubAccountsInformationForm_UpdateOrganizationMutation>;
export type SubAccountsInformationForm_UpdateOrganizationMutationOptions =
  Apollo.BaseMutationOptions<
    SubAccountsInformationForm_UpdateOrganizationMutation,
    SubAccountsInformationForm_UpdateOrganizationMutationVariables
  >;
export const SubAccountsTableRemoveAccessDocument = gql`
  mutation SubAccountsTableRemoveAccess($removeAccessInput: RemoveMultiAccountInput!) {
    removeMultiAccount(input: $removeAccessInput) {
      errors {
        field
        messages
      }
    }
  }
`;
export type SubAccountsTableRemoveAccessMutationFn = Apollo.MutationFunction<
  SubAccountsTableRemoveAccessMutation,
  SubAccountsTableRemoveAccessMutationVariables
>;

/**
 * __useSubAccountsTableRemoveAccessMutation__
 *
 * To run a mutation, you first call `useSubAccountsTableRemoveAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubAccountsTableRemoveAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subAccountsTableRemoveAccessMutation, { data, loading, error }] = useSubAccountsTableRemoveAccessMutation({
 *   variables: {
 *      removeAccessInput: // value for 'removeAccessInput'
 *   },
 * });
 */
export function useSubAccountsTableRemoveAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubAccountsTableRemoveAccessMutation,
    SubAccountsTableRemoveAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubAccountsTableRemoveAccessMutation,
    SubAccountsTableRemoveAccessMutationVariables
  >(SubAccountsTableRemoveAccessDocument, options);
}
export type SubAccountsTableRemoveAccessMutationHookResult = ReturnType<
  typeof useSubAccountsTableRemoveAccessMutation
>;
export type SubAccountsTableRemoveAccessMutationResult =
  Apollo.MutationResult<SubAccountsTableRemoveAccessMutation>;
export type SubAccountsTableRemoveAccessMutationOptions = Apollo.BaseMutationOptions<
  SubAccountsTableRemoveAccessMutation,
  SubAccountsTableRemoveAccessMutationVariables
>;
export const SubAccountsTableAvailableAccountsDocument = gql`
  query SubAccountsTableAvailableAccounts {
    user {
      id
      organization {
        id
        multiAccounts {
          id
          isOrgAdmin
          fromOrganizationPays
          toOrganization {
            id
            name
            activePlan {
              endDate
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSubAccountsTableAvailableAccountsQuery__
 *
 * To run a query within a React component, call `useSubAccountsTableAvailableAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubAccountsTableAvailableAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubAccountsTableAvailableAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubAccountsTableAvailableAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubAccountsTableAvailableAccountsQuery,
    SubAccountsTableAvailableAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SubAccountsTableAvailableAccountsQuery,
    SubAccountsTableAvailableAccountsQueryVariables
  >(SubAccountsTableAvailableAccountsDocument, options);
}
export function useSubAccountsTableAvailableAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubAccountsTableAvailableAccountsQuery,
    SubAccountsTableAvailableAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SubAccountsTableAvailableAccountsQuery,
    SubAccountsTableAvailableAccountsQueryVariables
  >(SubAccountsTableAvailableAccountsDocument, options);
}
export type SubAccountsTableAvailableAccountsQueryHookResult = ReturnType<
  typeof useSubAccountsTableAvailableAccountsQuery
>;
export type SubAccountsTableAvailableAccountsLazyQueryHookResult = ReturnType<
  typeof useSubAccountsTableAvailableAccountsLazyQuery
>;
export type SubAccountsTableAvailableAccountsQueryResult = Apollo.QueryResult<
  SubAccountsTableAvailableAccountsQuery,
  SubAccountsTableAvailableAccountsQueryVariables
>;
export const TransferMultiAccountDomainFormMultiAccountsDomainsDocument = gql`
  query transferMultiAccountDomainFormMultiAccountsDomains {
    user {
      id
      organization {
        id
        multiAccounts {
          id
          toOrganization {
            id
            name
            clients {
              id
              name
              domains {
                id
                domain
                displayName
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTransferMultiAccountDomainFormMultiAccountsDomainsQuery__
 *
 * To run a query within a React component, call `useTransferMultiAccountDomainFormMultiAccountsDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferMultiAccountDomainFormMultiAccountsDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferMultiAccountDomainFormMultiAccountsDomainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransferMultiAccountDomainFormMultiAccountsDomainsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TransferMultiAccountDomainFormMultiAccountsDomainsQuery,
    TransferMultiAccountDomainFormMultiAccountsDomainsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TransferMultiAccountDomainFormMultiAccountsDomainsQuery,
    TransferMultiAccountDomainFormMultiAccountsDomainsQueryVariables
  >(TransferMultiAccountDomainFormMultiAccountsDomainsDocument, options);
}
export function useTransferMultiAccountDomainFormMultiAccountsDomainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransferMultiAccountDomainFormMultiAccountsDomainsQuery,
    TransferMultiAccountDomainFormMultiAccountsDomainsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TransferMultiAccountDomainFormMultiAccountsDomainsQuery,
    TransferMultiAccountDomainFormMultiAccountsDomainsQueryVariables
  >(TransferMultiAccountDomainFormMultiAccountsDomainsDocument, options);
}
export type TransferMultiAccountDomainFormMultiAccountsDomainsQueryHookResult = ReturnType<
  typeof useTransferMultiAccountDomainFormMultiAccountsDomainsQuery
>;
export type TransferMultiAccountDomainFormMultiAccountsDomainsLazyQueryHookResult = ReturnType<
  typeof useTransferMultiAccountDomainFormMultiAccountsDomainsLazyQuery
>;
export type TransferMultiAccountDomainFormMultiAccountsDomainsQueryResult = Apollo.QueryResult<
  TransferMultiAccountDomainFormMultiAccountsDomainsQuery,
  TransferMultiAccountDomainFormMultiAccountsDomainsQueryVariables
>;
export const TransferMultiAccountDomainFormDomainsDocument = gql`
  query transferMultiAccountDomainFormDomains {
    user {
      id
      organization {
        id
        name
        clients {
          id
          name
          domains {
            id
            domain
            displayName
          }
        }
      }
    }
  }
`;

/**
 * __useTransferMultiAccountDomainFormDomainsQuery__
 *
 * To run a query within a React component, call `useTransferMultiAccountDomainFormDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferMultiAccountDomainFormDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferMultiAccountDomainFormDomainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransferMultiAccountDomainFormDomainsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TransferMultiAccountDomainFormDomainsQuery,
    TransferMultiAccountDomainFormDomainsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TransferMultiAccountDomainFormDomainsQuery,
    TransferMultiAccountDomainFormDomainsQueryVariables
  >(TransferMultiAccountDomainFormDomainsDocument, options);
}
export function useTransferMultiAccountDomainFormDomainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransferMultiAccountDomainFormDomainsQuery,
    TransferMultiAccountDomainFormDomainsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TransferMultiAccountDomainFormDomainsQuery,
    TransferMultiAccountDomainFormDomainsQueryVariables
  >(TransferMultiAccountDomainFormDomainsDocument, options);
}
export type TransferMultiAccountDomainFormDomainsQueryHookResult = ReturnType<
  typeof useTransferMultiAccountDomainFormDomainsQuery
>;
export type TransferMultiAccountDomainFormDomainsLazyQueryHookResult = ReturnType<
  typeof useTransferMultiAccountDomainFormDomainsLazyQuery
>;
export type TransferMultiAccountDomainFormDomainsQueryResult = Apollo.QueryResult<
  TransferMultiAccountDomainFormDomainsQuery,
  TransferMultiAccountDomainFormDomainsQueryVariables
>;
export const TransferMultiAccountDomainDocument = gql`
  mutation transferMultiAccountDomain($input: TransferMultiAccountDomainInput!) {
    transferMultiAccountDomain(input: $input) {
      code
      errors {
        field
        messages
      }
    }
  }
`;
export type TransferMultiAccountDomainMutationFn = Apollo.MutationFunction<
  TransferMultiAccountDomainMutation,
  TransferMultiAccountDomainMutationVariables
>;

/**
 * __useTransferMultiAccountDomainMutation__
 *
 * To run a mutation, you first call `useTransferMultiAccountDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferMultiAccountDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferMultiAccountDomainMutation, { data, loading, error }] = useTransferMultiAccountDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferMultiAccountDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransferMultiAccountDomainMutation,
    TransferMultiAccountDomainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TransferMultiAccountDomainMutation,
    TransferMultiAccountDomainMutationVariables
  >(TransferMultiAccountDomainDocument, options);
}
export type TransferMultiAccountDomainMutationHookResult = ReturnType<
  typeof useTransferMultiAccountDomainMutation
>;
export type TransferMultiAccountDomainMutationResult =
  Apollo.MutationResult<TransferMultiAccountDomainMutation>;
export type TransferMultiAccountDomainMutationOptions = Apollo.BaseMutationOptions<
  TransferMultiAccountDomainMutation,
  TransferMultiAccountDomainMutationVariables
>;
export const AccuApiSearchHistoryDocument = gql`
  query AccuApiSearchHistory {
    accuApiSearchHistory {
      history
    }
  }
`;

/**
 * __useAccuApiSearchHistoryQuery__
 *
 * To run a query within a React component, call `useAccuApiSearchHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccuApiSearchHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccuApiSearchHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccuApiSearchHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccuApiSearchHistoryQuery,
    AccuApiSearchHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccuApiSearchHistoryQuery, AccuApiSearchHistoryQueryVariables>(
    AccuApiSearchHistoryDocument,
    options,
  );
}
export function useAccuApiSearchHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccuApiSearchHistoryQuery,
    AccuApiSearchHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccuApiSearchHistoryQuery, AccuApiSearchHistoryQueryVariables>(
    AccuApiSearchHistoryDocument,
    options,
  );
}
export type AccuApiSearchHistoryQueryHookResult = ReturnType<typeof useAccuApiSearchHistoryQuery>;
export type AccuApiSearchHistoryLazyQueryHookResult = ReturnType<
  typeof useAccuApiSearchHistoryLazyQuery
>;
export type AccuApiSearchHistoryQueryResult = Apollo.QueryResult<
  AccuApiSearchHistoryQuery,
  AccuApiSearchHistoryQueryVariables
>;
export const AccuApiSpendingDocument = gql`
  query AccuApiSpending($startDate: String!, $endDate: String!) {
    accuApiSpendingGraph(startDate: $startDate, endDate: $endDate) {
      hours
    }
  }
`;

/**
 * __useAccuApiSpendingQuery__
 *
 * To run a query within a React component, call `useAccuApiSpendingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccuApiSpendingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccuApiSpendingQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAccuApiSpendingQuery(
  baseOptions: Apollo.QueryHookOptions<AccuApiSpendingQuery, AccuApiSpendingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccuApiSpendingQuery, AccuApiSpendingQueryVariables>(
    AccuApiSpendingDocument,
    options,
  );
}
export function useAccuApiSpendingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccuApiSpendingQuery, AccuApiSpendingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccuApiSpendingQuery, AccuApiSpendingQueryVariables>(
    AccuApiSpendingDocument,
    options,
  );
}
export type AccuApiSpendingQueryHookResult = ReturnType<typeof useAccuApiSpendingQuery>;
export type AccuApiSpendingLazyQueryHookResult = ReturnType<typeof useAccuApiSpendingLazyQuery>;
export type AccuApiSpendingQueryResult = Apollo.QueryResult<
  AccuApiSpendingQuery,
  AccuApiSpendingQueryVariables
>;
export const AccuApiCreditsDocument = gql`
  query AccuApiCredits {
    accuApiCredits {
      creditsStats {
        planCredits
        credits
        getLimit
        postLimit
        getLimitRemaning
        postLimitRemaning
      }
    }
  }
`;

/**
 * __useAccuApiCreditsQuery__
 *
 * To run a query within a React component, call `useAccuApiCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccuApiCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccuApiCreditsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccuApiCreditsQuery(
  baseOptions?: Apollo.QueryHookOptions<AccuApiCreditsQuery, AccuApiCreditsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccuApiCreditsQuery, AccuApiCreditsQueryVariables>(
    AccuApiCreditsDocument,
    options,
  );
}
export function useAccuApiCreditsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccuApiCreditsQuery, AccuApiCreditsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccuApiCreditsQuery, AccuApiCreditsQueryVariables>(
    AccuApiCreditsDocument,
    options,
  );
}
export type AccuApiCreditsQueryHookResult = ReturnType<typeof useAccuApiCreditsQuery>;
export type AccuApiCreditsLazyQueryHookResult = ReturnType<typeof useAccuApiCreditsLazyQuery>;
export type AccuApiCreditsQueryResult = Apollo.QueryResult<
  AccuApiCreditsQuery,
  AccuApiCreditsQueryVariables
>;
export const AccuApiTokenDocument = gql`
  query AccuApiToken {
    accuApiToken {
      token
    }
  }
`;

/**
 * __useAccuApiTokenQuery__
 *
 * To run a query within a React component, call `useAccuApiTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccuApiTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccuApiTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccuApiTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<AccuApiTokenQuery, AccuApiTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccuApiTokenQuery, AccuApiTokenQueryVariables>(
    AccuApiTokenDocument,
    options,
  );
}
export function useAccuApiTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccuApiTokenQuery, AccuApiTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccuApiTokenQuery, AccuApiTokenQueryVariables>(
    AccuApiTokenDocument,
    options,
  );
}
export type AccuApiTokenQueryHookResult = ReturnType<typeof useAccuApiTokenQuery>;
export type AccuApiTokenLazyQueryHookResult = ReturnType<typeof useAccuApiTokenLazyQuery>;
export type AccuApiTokenQueryResult = Apollo.QueryResult<
  AccuApiTokenQuery,
  AccuApiTokenQueryVariables
>;
export const AffiliateCommissionsDocument = gql`
  query AffiliateCommissions($filters: [FilterInput]!) {
    affiliate {
      payments(filters: $filters) {
        ...VisitorPayment
      }
    }
  }
  ${VisitorPaymentFragmentDoc}
`;

/**
 * __useAffiliateCommissionsQuery__
 *
 * To run a query within a React component, call `useAffiliateCommissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateCommissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateCommissionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAffiliateCommissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AffiliateCommissionsQuery,
    AffiliateCommissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AffiliateCommissionsQuery, AffiliateCommissionsQueryVariables>(
    AffiliateCommissionsDocument,
    options,
  );
}
export function useAffiliateCommissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AffiliateCommissionsQuery,
    AffiliateCommissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AffiliateCommissionsQuery, AffiliateCommissionsQueryVariables>(
    AffiliateCommissionsDocument,
    options,
  );
}
export type AffiliateCommissionsQueryHookResult = ReturnType<typeof useAffiliateCommissionsQuery>;
export type AffiliateCommissionsLazyQueryHookResult = ReturnType<
  typeof useAffiliateCommissionsLazyQuery
>;
export type AffiliateCommissionsQueryResult = Apollo.QueryResult<
  AffiliateCommissionsQuery,
  AffiliateCommissionsQueryVariables
>;
export const AffiliateCustomersDocument = gql`
  query AffiliateCustomers($filters: [FilterInput]!) {
    affiliate {
      visitors(filters: $filters, includeTrials: true, includeCustomers: true) {
        ...AffiliateVisitor
      }
    }
  }
  ${AffiliateVisitorFragmentDoc}
`;

/**
 * __useAffiliateCustomersQuery__
 *
 * To run a query within a React component, call `useAffiliateCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateCustomersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAffiliateCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<AffiliateCustomersQuery, AffiliateCustomersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AffiliateCustomersQuery, AffiliateCustomersQueryVariables>(
    AffiliateCustomersDocument,
    options,
  );
}
export function useAffiliateCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AffiliateCustomersQuery,
    AffiliateCustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AffiliateCustomersQuery, AffiliateCustomersQueryVariables>(
    AffiliateCustomersDocument,
    options,
  );
}
export type AffiliateCustomersQueryHookResult = ReturnType<typeof useAffiliateCustomersQuery>;
export type AffiliateCustomersLazyQueryHookResult = ReturnType<
  typeof useAffiliateCustomersLazyQuery
>;
export type AffiliateCustomersQueryResult = Apollo.QueryResult<
  AffiliateCustomersQuery,
  AffiliateCustomersQueryVariables
>;
export const AffiliatePaymentsDocument = gql`
  query AffiliatePayments($filters: [FilterInput]!) {
    affiliate {
      payments(filters: $filters) {
        ...VisitorPayment
      }
    }
  }
  ${VisitorPaymentFragmentDoc}
`;

/**
 * __useAffiliatePaymentsQuery__
 *
 * To run a query within a React component, call `useAffiliatePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliatePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliatePaymentsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAffiliatePaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<AffiliatePaymentsQuery, AffiliatePaymentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AffiliatePaymentsQuery, AffiliatePaymentsQueryVariables>(
    AffiliatePaymentsDocument,
    options,
  );
}
export function useAffiliatePaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AffiliatePaymentsQuery,
    AffiliatePaymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AffiliatePaymentsQuery, AffiliatePaymentsQueryVariables>(
    AffiliatePaymentsDocument,
    options,
  );
}
export type AffiliatePaymentsQueryHookResult = ReturnType<typeof useAffiliatePaymentsQuery>;
export type AffiliatePaymentsLazyQueryHookResult = ReturnType<typeof useAffiliatePaymentsLazyQuery>;
export type AffiliatePaymentsQueryResult = Apollo.QueryResult<
  AffiliatePaymentsQuery,
  AffiliatePaymentsQueryVariables
>;
export const AffiliateReferrersDocument = gql`
  query AffiliateReferrers($filters: [FilterInput]!) {
    affiliate {
      referrers(filters: $filters) {
        ...Referrer
      }
    }
  }
  ${ReferrerFragmentDoc}
`;

/**
 * __useAffiliateReferrersQuery__
 *
 * To run a query within a React component, call `useAffiliateReferrersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAffiliateReferrersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAffiliateReferrersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAffiliateReferrersQuery(
  baseOptions: Apollo.QueryHookOptions<AffiliateReferrersQuery, AffiliateReferrersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AffiliateReferrersQuery, AffiliateReferrersQueryVariables>(
    AffiliateReferrersDocument,
    options,
  );
}
export function useAffiliateReferrersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AffiliateReferrersQuery,
    AffiliateReferrersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AffiliateReferrersQuery, AffiliateReferrersQueryVariables>(
    AffiliateReferrersDocument,
    options,
  );
}
export type AffiliateReferrersQueryHookResult = ReturnType<typeof useAffiliateReferrersQuery>;
export type AffiliateReferrersLazyQueryHookResult = ReturnType<
  typeof useAffiliateReferrersLazyQuery
>;
export type AffiliateReferrersQueryResult = Apollo.QueryResult<
  AffiliateReferrersQuery,
  AffiliateReferrersQueryVariables
>;
export const LoginUserDocument = gql`
  mutation LoginUser($input: LoginInput!) {
    userLogin(input: $input) {
      otpRequired
      token
      errors {
        messages
        field
      }
    }
  }
`;
export type LoginUserMutationFn = Apollo.MutationFunction<
  LoginUserMutation,
  LoginUserMutationVariables
>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(
    LoginUserDocument,
    options,
  );
}
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>;
export const LoginTwoFactorDocument = gql`
  mutation LoginTwoFactor($input: LoginTwoFactorInput!) {
    userLoginTwoFactor(input: $input) {
      username
      password
      otpDevice
      otpToken
      otpChallenge
      token
      errors {
        messages
        field
      }
    }
  }
`;
export type LoginTwoFactorMutationFn = Apollo.MutationFunction<
  LoginTwoFactorMutation,
  LoginTwoFactorMutationVariables
>;

/**
 * __useLoginTwoFactorMutation__
 *
 * To run a mutation, you first call `useLoginTwoFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginTwoFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginTwoFactorMutation, { data, loading, error }] = useLoginTwoFactorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginTwoFactorMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginTwoFactorMutation, LoginTwoFactorMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginTwoFactorMutation, LoginTwoFactorMutationVariables>(
    LoginTwoFactorDocument,
    options,
  );
}
export type LoginTwoFactorMutationHookResult = ReturnType<typeof useLoginTwoFactorMutation>;
export type LoginTwoFactorMutationResult = Apollo.MutationResult<LoginTwoFactorMutation>;
export type LoginTwoFactorMutationOptions = Apollo.BaseMutationOptions<
  LoginTwoFactorMutation,
  LoginTwoFactorMutationVariables
>;
export const PasswordPerformPasswordResetDocument = gql`
  mutation passwordPerformPasswordReset($input: PerformPasswordResetInput!) {
    performPasswordReset(input: $input) {
      errors {
        messages
        field
      }
    }
  }
`;
export type PasswordPerformPasswordResetMutationFn = Apollo.MutationFunction<
  PasswordPerformPasswordResetMutation,
  PasswordPerformPasswordResetMutationVariables
>;

/**
 * __usePasswordPerformPasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordPerformPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordPerformPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordPerformPasswordResetMutation, { data, loading, error }] = usePasswordPerformPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePasswordPerformPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordPerformPasswordResetMutation,
    PasswordPerformPasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordPerformPasswordResetMutation,
    PasswordPerformPasswordResetMutationVariables
  >(PasswordPerformPasswordResetDocument, options);
}
export type PasswordPerformPasswordResetMutationHookResult = ReturnType<
  typeof usePasswordPerformPasswordResetMutation
>;
export type PasswordPerformPasswordResetMutationResult =
  Apollo.MutationResult<PasswordPerformPasswordResetMutation>;
export type PasswordPerformPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  PasswordPerformPasswordResetMutation,
  PasswordPerformPasswordResetMutationVariables
>;
export const RequestPasswordResetDocument = gql`
  mutation requestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input) {
      email
      errors {
        messages
        field
      }
    }
  }
`;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(
    RequestPasswordResetDocument,
    options,
  );
}
export type RequestPasswordResetMutationHookResult = ReturnType<
  typeof useRequestPasswordResetMutation
>;
export type RequestPasswordResetMutationResult =
  Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const CancelPlanDocument = gql`
  mutation CancelPlan($cancelPlanInput: CancelPlanInput!) {
    cancelPlan(input: $cancelPlanInput) {
      errors {
        field
        messages
      }
    }
  }
`;
export type CancelPlanMutationFn = Apollo.MutationFunction<
  CancelPlanMutation,
  CancelPlanMutationVariables
>;

/**
 * __useCancelPlanMutation__
 *
 * To run a mutation, you first call `useCancelPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPlanMutation, { data, loading, error }] = useCancelPlanMutation({
 *   variables: {
 *      cancelPlanInput: // value for 'cancelPlanInput'
 *   },
 * });
 */
export function useCancelPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelPlanMutation, CancelPlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelPlanMutation, CancelPlanMutationVariables>(
    CancelPlanDocument,
    options,
  );
}
export type CancelPlanMutationHookResult = ReturnType<typeof useCancelPlanMutation>;
export type CancelPlanMutationResult = Apollo.MutationResult<CancelPlanMutation>;
export type CancelPlanMutationOptions = Apollo.BaseMutationOptions<
  CancelPlanMutation,
  CancelPlanMutationVariables
>;
export const CheckoutInfoWidgetGetPricingDetailsQueryDocument = gql`
  query CheckoutInfoWidgetGetPricingDetailsQuery(
    $id: ID!
    $billingCycle: Int!
    $countryId: String
    $validVat: Boolean
  ) {
    pricingPlanCalculated(
      id: $id
      billingCycle: $billingCycle
      countryId: $countryId
      validVat: $validVat
    ) {
      upgrade
      downgrade
    }
  }
`;

/**
 * __useCheckoutInfoWidgetGetPricingDetailsQueryQuery__
 *
 * To run a query within a React component, call `useCheckoutInfoWidgetGetPricingDetailsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutInfoWidgetGetPricingDetailsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutInfoWidgetGetPricingDetailsQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      billingCycle: // value for 'billingCycle'
 *      countryId: // value for 'countryId'
 *      validVat: // value for 'validVat'
 *   },
 * });
 */
export function useCheckoutInfoWidgetGetPricingDetailsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckoutInfoWidgetGetPricingDetailsQueryQuery,
    CheckoutInfoWidgetGetPricingDetailsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckoutInfoWidgetGetPricingDetailsQueryQuery,
    CheckoutInfoWidgetGetPricingDetailsQueryQueryVariables
  >(CheckoutInfoWidgetGetPricingDetailsQueryDocument, options);
}
export function useCheckoutInfoWidgetGetPricingDetailsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckoutInfoWidgetGetPricingDetailsQueryQuery,
    CheckoutInfoWidgetGetPricingDetailsQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckoutInfoWidgetGetPricingDetailsQueryQuery,
    CheckoutInfoWidgetGetPricingDetailsQueryQueryVariables
  >(CheckoutInfoWidgetGetPricingDetailsQueryDocument, options);
}
export type CheckoutInfoWidgetGetPricingDetailsQueryQueryHookResult = ReturnType<
  typeof useCheckoutInfoWidgetGetPricingDetailsQueryQuery
>;
export type CheckoutInfoWidgetGetPricingDetailsQueryLazyQueryHookResult = ReturnType<
  typeof useCheckoutInfoWidgetGetPricingDetailsQueryLazyQuery
>;
export type CheckoutInfoWidgetGetPricingDetailsQueryQueryResult = Apollo.QueryResult<
  CheckoutInfoWidgetGetPricingDetailsQueryQuery,
  CheckoutInfoWidgetGetPricingDetailsQueryQueryVariables
>;
export const AccountOrganizationPlanInfoDocument = gql`
  query AccountOrganizationPlanInfo {
    user {
      id
      organization {
        id
        activePlan {
          id
          name
          isFree
          isTrial
          category
          priceMonthly
          priceYearly
          endDate
          billingCycleInMonths
          currency
          maxKeywords
          calculateFuturePrice
        }
        nextPlan {
          id
          name
          startDate
        }
      }
    }
  }
`;

/**
 * __useAccountOrganizationPlanInfoQuery__
 *
 * To run a query within a React component, call `useAccountOrganizationPlanInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountOrganizationPlanInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountOrganizationPlanInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountOrganizationPlanInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AccountOrganizationPlanInfoQuery,
    AccountOrganizationPlanInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AccountOrganizationPlanInfoQuery,
    AccountOrganizationPlanInfoQueryVariables
  >(AccountOrganizationPlanInfoDocument, options);
}
export function useAccountOrganizationPlanInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountOrganizationPlanInfoQuery,
    AccountOrganizationPlanInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AccountOrganizationPlanInfoQuery,
    AccountOrganizationPlanInfoQueryVariables
  >(AccountOrganizationPlanInfoDocument, options);
}
export type AccountOrganizationPlanInfoQueryHookResult = ReturnType<
  typeof useAccountOrganizationPlanInfoQuery
>;
export type AccountOrganizationPlanInfoLazyQueryHookResult = ReturnType<
  typeof useAccountOrganizationPlanInfoLazyQuery
>;
export type AccountOrganizationPlanInfoQueryResult = Apollo.QueryResult<
  AccountOrganizationPlanInfoQuery,
  AccountOrganizationPlanInfoQueryVariables
>;
export const BillingPricingPlanDocument = gql`
  query BillingPricingPlan($id: ID!) {
    pricingPlan(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useBillingPricingPlanQuery__
 *
 * To run a query within a React component, call `useBillingPricingPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingPricingPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingPricingPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBillingPricingPlanQuery(
  baseOptions: Apollo.QueryHookOptions<BillingPricingPlanQuery, BillingPricingPlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingPricingPlanQuery, BillingPricingPlanQueryVariables>(
    BillingPricingPlanDocument,
    options,
  );
}
export function useBillingPricingPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillingPricingPlanQuery,
    BillingPricingPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingPricingPlanQuery, BillingPricingPlanQueryVariables>(
    BillingPricingPlanDocument,
    options,
  );
}
export type BillingPricingPlanQueryHookResult = ReturnType<typeof useBillingPricingPlanQuery>;
export type BillingPricingPlanLazyQueryHookResult = ReturnType<
  typeof useBillingPricingPlanLazyQuery
>;
export type BillingPricingPlanQueryResult = Apollo.QueryResult<
  BillingPricingPlanQuery,
  BillingPricingPlanQueryVariables
>;
export const BillingPricingPlanCalculatedDocument = gql`
  query BillingPricingPlanCalculated($id: ID!, $billingCycle: Int!, $countryId: String!) {
    pricingPlanCalculated(id: $id, billingCycle: $billingCycle, countryId: $countryId) {
      currency
      price
      receivable
      signonDiscount
      couponDiscount
      purseCredit
      keywordOveruseCredit
      subAccountDiscount
      totalBeforeVat
    }
  }
`;

/**
 * __useBillingPricingPlanCalculatedQuery__
 *
 * To run a query within a React component, call `useBillingPricingPlanCalculatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingPricingPlanCalculatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingPricingPlanCalculatedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      billingCycle: // value for 'billingCycle'
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function useBillingPricingPlanCalculatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    BillingPricingPlanCalculatedQuery,
    BillingPricingPlanCalculatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BillingPricingPlanCalculatedQuery,
    BillingPricingPlanCalculatedQueryVariables
  >(BillingPricingPlanCalculatedDocument, options);
}
export function useBillingPricingPlanCalculatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillingPricingPlanCalculatedQuery,
    BillingPricingPlanCalculatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BillingPricingPlanCalculatedQuery,
    BillingPricingPlanCalculatedQueryVariables
  >(BillingPricingPlanCalculatedDocument, options);
}
export type BillingPricingPlanCalculatedQueryHookResult = ReturnType<
  typeof useBillingPricingPlanCalculatedQuery
>;
export type BillingPricingPlanCalculatedLazyQueryHookResult = ReturnType<
  typeof useBillingPricingPlanCalculatedLazyQuery
>;
export type BillingPricingPlanCalculatedQueryResult = Apollo.QueryResult<
  BillingPricingPlanCalculatedQuery,
  BillingPricingPlanCalculatedQueryVariables
>;
export const ConnectedAccountsDocument = gql`
  query ConnectedAccounts {
    user {
      id
      organization {
        id
        googleConnections {
          ...GoogleConnection
        }
        adobeMarketingConnections {
          id
          description
          createdAt
          createdBy
        }
      }
    }
  }
  ${GoogleConnectionFragmentDoc}
`;

/**
 * __useConnectedAccountsQuery__
 *
 * To run a query within a React component, call `useConnectedAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConnectedAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<ConnectedAccountsQuery, ConnectedAccountsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConnectedAccountsQuery, ConnectedAccountsQueryVariables>(
    ConnectedAccountsDocument,
    options,
  );
}
export function useConnectedAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectedAccountsQuery,
    ConnectedAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConnectedAccountsQuery, ConnectedAccountsQueryVariables>(
    ConnectedAccountsDocument,
    options,
  );
}
export type ConnectedAccountsQueryHookResult = ReturnType<typeof useConnectedAccountsQuery>;
export type ConnectedAccountsLazyQueryHookResult = ReturnType<typeof useConnectedAccountsLazyQuery>;
export type ConnectedAccountsQueryResult = Apollo.QueryResult<
  ConnectedAccountsQuery,
  ConnectedAccountsQueryVariables
>;
export const ConnectedAccountsDomainsListDocument = gql`
  query ConnectedAccountsDomainsList {
    domainsList {
      id
      googleOauthConnectionGa {
        id
      }
      googleOauthConnectionGsc {
        id
      }
      adobeMarketingConnection {
        id
      }
    }
  }
`;

/**
 * __useConnectedAccountsDomainsListQuery__
 *
 * To run a query within a React component, call `useConnectedAccountsDomainsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedAccountsDomainsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedAccountsDomainsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useConnectedAccountsDomainsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ConnectedAccountsDomainsListQuery,
    ConnectedAccountsDomainsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConnectedAccountsDomainsListQuery,
    ConnectedAccountsDomainsListQueryVariables
  >(ConnectedAccountsDomainsListDocument, options);
}
export function useConnectedAccountsDomainsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectedAccountsDomainsListQuery,
    ConnectedAccountsDomainsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConnectedAccountsDomainsListQuery,
    ConnectedAccountsDomainsListQueryVariables
  >(ConnectedAccountsDomainsListDocument, options);
}
export type ConnectedAccountsDomainsListQueryHookResult = ReturnType<
  typeof useConnectedAccountsDomainsListQuery
>;
export type ConnectedAccountsDomainsListLazyQueryHookResult = ReturnType<
  typeof useConnectedAccountsDomainsListLazyQuery
>;
export type ConnectedAccountsDomainsListQueryResult = Apollo.QueryResult<
  ConnectedAccountsDomainsListQuery,
  ConnectedAccountsDomainsListQueryVariables
>;
export const RemoveAdobeAccessDocument = gql`
  mutation RemoveAdobeAccess($input: UpdateAdobeMarketingConnectionInput!) {
    updateAdobeMarketingConnection(input: $input) {
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RemoveAdobeAccessMutationFn = Apollo.MutationFunction<
  RemoveAdobeAccessMutation,
  RemoveAdobeAccessMutationVariables
>;

/**
 * __useRemoveAdobeAccessMutation__
 *
 * To run a mutation, you first call `useRemoveAdobeAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdobeAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdobeAccessMutation, { data, loading, error }] = useRemoveAdobeAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAdobeAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAdobeAccessMutation,
    RemoveAdobeAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveAdobeAccessMutation, RemoveAdobeAccessMutationVariables>(
    RemoveAdobeAccessDocument,
    options,
  );
}
export type RemoveAdobeAccessMutationHookResult = ReturnType<typeof useRemoveAdobeAccessMutation>;
export type RemoveAdobeAccessMutationResult = Apollo.MutationResult<RemoveAdobeAccessMutation>;
export type RemoveAdobeAccessMutationOptions = Apollo.BaseMutationOptions<
  RemoveAdobeAccessMutation,
  RemoveAdobeAccessMutationVariables
>;
export const RemoveGoogleAccessDocument = gql`
  mutation RemoveGoogleAccess($input: UpdateGoogleConnectionInput!) {
    updateGoogleConnection(input: $input) {
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RemoveGoogleAccessMutationFn = Apollo.MutationFunction<
  RemoveGoogleAccessMutation,
  RemoveGoogleAccessMutationVariables
>;

/**
 * __useRemoveGoogleAccessMutation__
 *
 * To run a mutation, you first call `useRemoveGoogleAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGoogleAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGoogleAccessMutation, { data, loading, error }] = useRemoveGoogleAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveGoogleAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGoogleAccessMutation,
    RemoveGoogleAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveGoogleAccessMutation, RemoveGoogleAccessMutationVariables>(
    RemoveGoogleAccessDocument,
    options,
  );
}
export type RemoveGoogleAccessMutationHookResult = ReturnType<typeof useRemoveGoogleAccessMutation>;
export type RemoveGoogleAccessMutationResult = Apollo.MutationResult<RemoveGoogleAccessMutation>;
export type RemoveGoogleAccessMutationOptions = Apollo.BaseMutationOptions<
  RemoveGoogleAccessMutation,
  RemoveGoogleAccessMutationVariables
>;
export const DynamicTagRulesetExistsDocument = gql`
  query DynamicTagRulesetExists($tag: String!, $domainId: Int!) {
    dynamicTagRulesetExists(tag: $tag, domainId: $domainId)
  }
`;

/**
 * __useDynamicTagRulesetExistsQuery__
 *
 * To run a query within a React component, call `useDynamicTagRulesetExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDynamicTagRulesetExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDynamicTagRulesetExistsQuery({
 *   variables: {
 *      tag: // value for 'tag'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useDynamicTagRulesetExistsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DynamicTagRulesetExistsQuery,
    DynamicTagRulesetExistsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DynamicTagRulesetExistsQuery, DynamicTagRulesetExistsQueryVariables>(
    DynamicTagRulesetExistsDocument,
    options,
  );
}
export function useDynamicTagRulesetExistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DynamicTagRulesetExistsQuery,
    DynamicTagRulesetExistsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DynamicTagRulesetExistsQuery, DynamicTagRulesetExistsQueryVariables>(
    DynamicTagRulesetExistsDocument,
    options,
  );
}
export type DynamicTagRulesetExistsQueryHookResult = ReturnType<
  typeof useDynamicTagRulesetExistsQuery
>;
export type DynamicTagRulesetExistsLazyQueryHookResult = ReturnType<
  typeof useDynamicTagRulesetExistsLazyQuery
>;
export type DynamicTagRulesetExistsQueryResult = Apollo.QueryResult<
  DynamicTagRulesetExistsQuery,
  DynamicTagRulesetExistsQueryVariables
>;
export const UpdateDomainDisplaySettingsDocument = gql`
  mutation UpdateDomainDisplaySettings($input: UpdateDomainSpecialSettingsInput!) {
    updateDomainSpecialSettings(input: $input) {
      domain {
        displayCurrency
        showLocalSearchVolume
      }
    }
  }
`;
export type UpdateDomainDisplaySettingsMutationFn = Apollo.MutationFunction<
  UpdateDomainDisplaySettingsMutation,
  UpdateDomainDisplaySettingsMutationVariables
>;

/**
 * __useUpdateDomainDisplaySettingsMutation__
 *
 * To run a mutation, you first call `useUpdateDomainDisplaySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDomainDisplaySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDomainDisplaySettingsMutation, { data, loading, error }] = useUpdateDomainDisplaySettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDomainDisplaySettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDomainDisplaySettingsMutation,
    UpdateDomainDisplaySettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDomainDisplaySettingsMutation,
    UpdateDomainDisplaySettingsMutationVariables
  >(UpdateDomainDisplaySettingsDocument, options);
}
export type UpdateDomainDisplaySettingsMutationHookResult = ReturnType<
  typeof useUpdateDomainDisplaySettingsMutation
>;
export type UpdateDomainDisplaySettingsMutationResult =
  Apollo.MutationResult<UpdateDomainDisplaySettingsMutation>;
export type UpdateDomainDisplaySettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateDomainDisplaySettingsMutation,
  UpdateDomainDisplaySettingsMutationVariables
>;
export const DomainDisplaySettingsDocument = gql`
  query DomainDisplaySettings($id: ID!) {
    domain(id: $id) {
      displayCurrency
      showLocalSearchVolume
    }
  }
`;

/**
 * __useDomainDisplaySettingsQuery__
 *
 * To run a query within a React component, call `useDomainDisplaySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainDisplaySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainDisplaySettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDomainDisplaySettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    DomainDisplaySettingsQuery,
    DomainDisplaySettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DomainDisplaySettingsQuery, DomainDisplaySettingsQueryVariables>(
    DomainDisplaySettingsDocument,
    options,
  );
}
export function useDomainDisplaySettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DomainDisplaySettingsQuery,
    DomainDisplaySettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DomainDisplaySettingsQuery, DomainDisplaySettingsQueryVariables>(
    DomainDisplaySettingsDocument,
    options,
  );
}
export type DomainDisplaySettingsQueryHookResult = ReturnType<typeof useDomainDisplaySettingsQuery>;
export type DomainDisplaySettingsLazyQueryHookResult = ReturnType<
  typeof useDomainDisplaySettingsLazyQuery
>;
export type DomainDisplaySettingsQueryResult = Apollo.QueryResult<
  DomainDisplaySettingsQuery,
  DomainDisplaySettingsQueryVariables
>;
export const DeleteDomainsDocument = gql`
  mutation DeleteDomains($input: DeleteDomainInput!) {
    deleteDomain(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type DeleteDomainsMutationFn = Apollo.MutationFunction<
  DeleteDomainsMutation,
  DeleteDomainsMutationVariables
>;

/**
 * __useDeleteDomainsMutation__
 *
 * To run a mutation, you first call `useDeleteDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDomainsMutation, { data, loading, error }] = useDeleteDomainsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDomainsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDomainsMutation, DeleteDomainsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDomainsMutation, DeleteDomainsMutationVariables>(
    DeleteDomainsDocument,
    options,
  );
}
export type DeleteDomainsMutationHookResult = ReturnType<typeof useDeleteDomainsMutation>;
export type DeleteDomainsMutationResult = Apollo.MutationResult<DeleteDomainsMutation>;
export type DeleteDomainsMutationOptions = Apollo.BaseMutationOptions<
  DeleteDomainsMutation,
  DeleteDomainsMutationVariables
>;
export const DomainsDocument = gql`
  query Domains(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
  ) {
    tableDashboard(filters: $filters, pagination: $pagination, ordering: $ordering) {
      domains
      pagination {
        numResults
        startIndex
        stopIndex
      }
    }
  }
`;

/**
 * __useDomainsQuery__
 *
 * To run a query within a React component, call `useDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useDomainsQuery(
  baseOptions: Apollo.QueryHookOptions<DomainsQuery, DomainsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DomainsQuery, DomainsQueryVariables>(DomainsDocument, options);
}
export function useDomainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DomainsQuery, DomainsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DomainsQuery, DomainsQueryVariables>(DomainsDocument, options);
}
export type DomainsQueryHookResult = ReturnType<typeof useDomainsQuery>;
export type DomainsLazyQueryHookResult = ReturnType<typeof useDomainsLazyQuery>;
export type DomainsQueryResult = Apollo.QueryResult<DomainsQuery, DomainsQueryVariables>;
export const PlanExpiredPreviousPlanDocument = gql`
  query planExpiredPreviousPlan {
    user {
      id
      organization {
        id
        previousPlan {
          id
          name
          canManualReActivate
          calculatePrice
          calculateFuturePrice
          currency
          billingCycleInMonths
          isFree
        }
      }
    }
  }
`;

/**
 * __usePlanExpiredPreviousPlanQuery__
 *
 * To run a query within a React component, call `usePlanExpiredPreviousPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanExpiredPreviousPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanExpiredPreviousPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanExpiredPreviousPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PlanExpiredPreviousPlanQuery,
    PlanExpiredPreviousPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlanExpiredPreviousPlanQuery, PlanExpiredPreviousPlanQueryVariables>(
    PlanExpiredPreviousPlanDocument,
    options,
  );
}
export function usePlanExpiredPreviousPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlanExpiredPreviousPlanQuery,
    PlanExpiredPreviousPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlanExpiredPreviousPlanQuery, PlanExpiredPreviousPlanQueryVariables>(
    PlanExpiredPreviousPlanDocument,
    options,
  );
}
export type PlanExpiredPreviousPlanQueryHookResult = ReturnType<
  typeof usePlanExpiredPreviousPlanQuery
>;
export type PlanExpiredPreviousPlanLazyQueryHookResult = ReturnType<
  typeof usePlanExpiredPreviousPlanLazyQuery
>;
export type PlanExpiredPreviousPlanQueryResult = Apollo.QueryResult<
  PlanExpiredPreviousPlanQuery,
  PlanExpiredPreviousPlanQueryVariables
>;
export const PlanExpiredReActivateDocument = gql`
  mutation planExpiredReActivate {
    reactivate {
      error
      success
    }
  }
`;
export type PlanExpiredReActivateMutationFn = Apollo.MutationFunction<
  PlanExpiredReActivateMutation,
  PlanExpiredReActivateMutationVariables
>;

/**
 * __usePlanExpiredReActivateMutation__
 *
 * To run a mutation, you first call `usePlanExpiredReActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlanExpiredReActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [planExpiredReActivateMutation, { data, loading, error }] = usePlanExpiredReActivateMutation({
 *   variables: {
 *   },
 * });
 */
export function usePlanExpiredReActivateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlanExpiredReActivateMutation,
    PlanExpiredReActivateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PlanExpiredReActivateMutation, PlanExpiredReActivateMutationVariables>(
    PlanExpiredReActivateDocument,
    options,
  );
}
export type PlanExpiredReActivateMutationHookResult = ReturnType<
  typeof usePlanExpiredReActivateMutation
>;
export type PlanExpiredReActivateMutationResult =
  Apollo.MutationResult<PlanExpiredReActivateMutation>;
export type PlanExpiredReActivateMutationOptions = Apollo.BaseMutationOptions<
  PlanExpiredReActivateMutation,
  PlanExpiredReActivateMutationVariables
>;
export const MultiAccountOwnersDocument = gql`
  query MultiAccountOwners {
    user {
      id
      organization {
        id
        multiAccountOwners {
          ...multiAccountOwner
        }
      }
    }
  }
  ${MultiAccountOwnerFragmentDoc}
`;

/**
 * __useMultiAccountOwnersQuery__
 *
 * To run a query within a React component, call `useMultiAccountOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiAccountOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiAccountOwnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useMultiAccountOwnersQuery(
  baseOptions?: Apollo.QueryHookOptions<MultiAccountOwnersQuery, MultiAccountOwnersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MultiAccountOwnersQuery, MultiAccountOwnersQueryVariables>(
    MultiAccountOwnersDocument,
    options,
  );
}
export function useMultiAccountOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MultiAccountOwnersQuery,
    MultiAccountOwnersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MultiAccountOwnersQuery, MultiAccountOwnersQueryVariables>(
    MultiAccountOwnersDocument,
    options,
  );
}
export type MultiAccountOwnersQueryHookResult = ReturnType<typeof useMultiAccountOwnersQuery>;
export type MultiAccountOwnersLazyQueryHookResult = ReturnType<
  typeof useMultiAccountOwnersLazyQuery
>;
export type MultiAccountOwnersQueryResult = Apollo.QueryResult<
  MultiAccountOwnersQuery,
  MultiAccountOwnersQueryVariables
>;
export const RemoveMultiAccountDocument = gql`
  mutation RemoveMultiAccount($input: RemoveMultiAccountInput!) {
    removeMultiAccount(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type RemoveMultiAccountMutationFn = Apollo.MutationFunction<
  RemoveMultiAccountMutation,
  RemoveMultiAccountMutationVariables
>;

/**
 * __useRemoveMultiAccountMutation__
 *
 * To run a mutation, you first call `useRemoveMultiAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMultiAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMultiAccountMutation, { data, loading, error }] = useRemoveMultiAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMultiAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMultiAccountMutation,
    RemoveMultiAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMultiAccountMutation, RemoveMultiAccountMutationVariables>(
    RemoveMultiAccountDocument,
    options,
  );
}
export type RemoveMultiAccountMutationHookResult = ReturnType<typeof useRemoveMultiAccountMutation>;
export type RemoveMultiAccountMutationResult = Apollo.MutationResult<RemoveMultiAccountMutation>;
export type RemoveMultiAccountMutationOptions = Apollo.BaseMutationOptions<
  RemoveMultiAccountMutation,
  RemoveMultiAccountMutationVariables
>;
export const PendingMultiAccountOwnersDocument = gql`
  query PendingMultiAccountOwners {
    user {
      id
      organization {
        id
        pendingMultiAccountOwners {
          ...pendingMultiAccountOwner
        }
      }
    }
  }
  ${PendingMultiAccountOwnerFragmentDoc}
`;

/**
 * __usePendingMultiAccountOwnersQuery__
 *
 * To run a query within a React component, call `usePendingMultiAccountOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingMultiAccountOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingMultiAccountOwnersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingMultiAccountOwnersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PendingMultiAccountOwnersQuery,
    PendingMultiAccountOwnersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PendingMultiAccountOwnersQuery, PendingMultiAccountOwnersQueryVariables>(
    PendingMultiAccountOwnersDocument,
    options,
  );
}
export function usePendingMultiAccountOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingMultiAccountOwnersQuery,
    PendingMultiAccountOwnersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PendingMultiAccountOwnersQuery,
    PendingMultiAccountOwnersQueryVariables
  >(PendingMultiAccountOwnersDocument, options);
}
export type PendingMultiAccountOwnersQueryHookResult = ReturnType<
  typeof usePendingMultiAccountOwnersQuery
>;
export type PendingMultiAccountOwnersLazyQueryHookResult = ReturnType<
  typeof usePendingMultiAccountOwnersLazyQuery
>;
export type PendingMultiAccountOwnersQueryResult = Apollo.QueryResult<
  PendingMultiAccountOwnersQuery,
  PendingMultiAccountOwnersQueryVariables
>;
export const DenyMultiAccountAccessDocument = gql`
  mutation DenyMultiAccountAccess($input: RemoveMultiAccountRequestInput!) {
    removeMultiAccountRequest(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type DenyMultiAccountAccessMutationFn = Apollo.MutationFunction<
  DenyMultiAccountAccessMutation,
  DenyMultiAccountAccessMutationVariables
>;

/**
 * __useDenyMultiAccountAccessMutation__
 *
 * To run a mutation, you first call `useDenyMultiAccountAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDenyMultiAccountAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [denyMultiAccountAccessMutation, { data, loading, error }] = useDenyMultiAccountAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDenyMultiAccountAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DenyMultiAccountAccessMutation,
    DenyMultiAccountAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DenyMultiAccountAccessMutation,
    DenyMultiAccountAccessMutationVariables
  >(DenyMultiAccountAccessDocument, options);
}
export type DenyMultiAccountAccessMutationHookResult = ReturnType<
  typeof useDenyMultiAccountAccessMutation
>;
export type DenyMultiAccountAccessMutationResult =
  Apollo.MutationResult<DenyMultiAccountAccessMutation>;
export type DenyMultiAccountAccessMutationOptions = Apollo.BaseMutationOptions<
  DenyMultiAccountAccessMutation,
  DenyMultiAccountAccessMutationVariables
>;
export const AcceptMultiAccountAccessDocument = gql`
  mutation AcceptMultiAccountAccess($input: AcceptMultiAccountRequestInput!) {
    acceptMultiAccountRequest(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type AcceptMultiAccountAccessMutationFn = Apollo.MutationFunction<
  AcceptMultiAccountAccessMutation,
  AcceptMultiAccountAccessMutationVariables
>;

/**
 * __useAcceptMultiAccountAccessMutation__
 *
 * To run a mutation, you first call `useAcceptMultiAccountAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptMultiAccountAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptMultiAccountAccessMutation, { data, loading, error }] = useAcceptMultiAccountAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptMultiAccountAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptMultiAccountAccessMutation,
    AcceptMultiAccountAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptMultiAccountAccessMutation,
    AcceptMultiAccountAccessMutationVariables
  >(AcceptMultiAccountAccessDocument, options);
}
export type AcceptMultiAccountAccessMutationHookResult = ReturnType<
  typeof useAcceptMultiAccountAccessMutation
>;
export type AcceptMultiAccountAccessMutationResult =
  Apollo.MutationResult<AcceptMultiAccountAccessMutation>;
export type AcceptMultiAccountAccessMutationOptions = Apollo.BaseMutationOptions<
  AcceptMultiAccountAccessMutation,
  AcceptMultiAccountAccessMutationVariables
>;
export const MultiAccountPendingRequestsDocument = gql`
  query MultiAccountPendingRequests {
    user {
      id
      organization {
        id
        pendingMultiAccountOwners {
          id
          fromOrganization {
            id
            name
          }
          fromOrganizationPays
          isOrgAdmin
        }
        deletedMultiAccountOwners {
          id
        }
        existingMultiAccountOwners {
          id
          fromOrganization {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useMultiAccountPendingRequestsQuery__
 *
 * To run a query within a React component, call `useMultiAccountPendingRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiAccountPendingRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiAccountPendingRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMultiAccountPendingRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MultiAccountPendingRequestsQuery,
    MultiAccountPendingRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MultiAccountPendingRequestsQuery,
    MultiAccountPendingRequestsQueryVariables
  >(MultiAccountPendingRequestsDocument, options);
}
export function useMultiAccountPendingRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MultiAccountPendingRequestsQuery,
    MultiAccountPendingRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MultiAccountPendingRequestsQuery,
    MultiAccountPendingRequestsQueryVariables
  >(MultiAccountPendingRequestsDocument, options);
}
export type MultiAccountPendingRequestsQueryHookResult = ReturnType<
  typeof useMultiAccountPendingRequestsQuery
>;
export type MultiAccountPendingRequestsLazyQueryHookResult = ReturnType<
  typeof useMultiAccountPendingRequestsLazyQuery
>;
export type MultiAccountPendingRequestsQueryResult = Apollo.QueryResult<
  MultiAccountPendingRequestsQuery,
  MultiAccountPendingRequestsQueryVariables
>;
export const MultiAccountInviteSentFromOwnAccountDocument = gql`
  query MultiAccountInviteSentFromOwnAccount($multiAccountId: String!) {
    user {
      organization {
        inviteSentFromOwnAccount(multiAccountId: $multiAccountId)
      }
    }
  }
`;

/**
 * __useMultiAccountInviteSentFromOwnAccountQuery__
 *
 * To run a query within a React component, call `useMultiAccountInviteSentFromOwnAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiAccountInviteSentFromOwnAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiAccountInviteSentFromOwnAccountQuery({
 *   variables: {
 *      multiAccountId: // value for 'multiAccountId'
 *   },
 * });
 */
export function useMultiAccountInviteSentFromOwnAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    MultiAccountInviteSentFromOwnAccountQuery,
    MultiAccountInviteSentFromOwnAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MultiAccountInviteSentFromOwnAccountQuery,
    MultiAccountInviteSentFromOwnAccountQueryVariables
  >(MultiAccountInviteSentFromOwnAccountDocument, options);
}
export function useMultiAccountInviteSentFromOwnAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MultiAccountInviteSentFromOwnAccountQuery,
    MultiAccountInviteSentFromOwnAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MultiAccountInviteSentFromOwnAccountQuery,
    MultiAccountInviteSentFromOwnAccountQueryVariables
  >(MultiAccountInviteSentFromOwnAccountDocument, options);
}
export type MultiAccountInviteSentFromOwnAccountQueryHookResult = ReturnType<
  typeof useMultiAccountInviteSentFromOwnAccountQuery
>;
export type MultiAccountInviteSentFromOwnAccountLazyQueryHookResult = ReturnType<
  typeof useMultiAccountInviteSentFromOwnAccountLazyQuery
>;
export type MultiAccountInviteSentFromOwnAccountQueryResult = Apollo.QueryResult<
  MultiAccountInviteSentFromOwnAccountQuery,
  MultiAccountInviteSentFromOwnAccountQueryVariables
>;
export const MultiAccountAcceptMultiAccountRequestDocument = gql`
  mutation MultiAccountAcceptMultiAccountRequest($input: AcceptMultiAccountRequestInput!) {
    acceptMultiAccountRequest(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type MultiAccountAcceptMultiAccountRequestMutationFn = Apollo.MutationFunction<
  MultiAccountAcceptMultiAccountRequestMutation,
  MultiAccountAcceptMultiAccountRequestMutationVariables
>;

/**
 * __useMultiAccountAcceptMultiAccountRequestMutation__
 *
 * To run a mutation, you first call `useMultiAccountAcceptMultiAccountRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultiAccountAcceptMultiAccountRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multiAccountAcceptMultiAccountRequestMutation, { data, loading, error }] = useMultiAccountAcceptMultiAccountRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMultiAccountAcceptMultiAccountRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MultiAccountAcceptMultiAccountRequestMutation,
    MultiAccountAcceptMultiAccountRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MultiAccountAcceptMultiAccountRequestMutation,
    MultiAccountAcceptMultiAccountRequestMutationVariables
  >(MultiAccountAcceptMultiAccountRequestDocument, options);
}
export type MultiAccountAcceptMultiAccountRequestMutationHookResult = ReturnType<
  typeof useMultiAccountAcceptMultiAccountRequestMutation
>;
export type MultiAccountAcceptMultiAccountRequestMutationResult =
  Apollo.MutationResult<MultiAccountAcceptMultiAccountRequestMutation>;
export type MultiAccountAcceptMultiAccountRequestMutationOptions = Apollo.BaseMutationOptions<
  MultiAccountAcceptMultiAccountRequestMutation,
  MultiAccountAcceptMultiAccountRequestMutationVariables
>;
export const MultiAccountRemoveAccessRequestDocument = gql`
  mutation MultiAccountRemoveAccessRequest($input: RemoveMultiAccountRequestInput!) {
    removeMultiAccountRequest(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type MultiAccountRemoveAccessRequestMutationFn = Apollo.MutationFunction<
  MultiAccountRemoveAccessRequestMutation,
  MultiAccountRemoveAccessRequestMutationVariables
>;

/**
 * __useMultiAccountRemoveAccessRequestMutation__
 *
 * To run a mutation, you first call `useMultiAccountRemoveAccessRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultiAccountRemoveAccessRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multiAccountRemoveAccessRequestMutation, { data, loading, error }] = useMultiAccountRemoveAccessRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMultiAccountRemoveAccessRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MultiAccountRemoveAccessRequestMutation,
    MultiAccountRemoveAccessRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MultiAccountRemoveAccessRequestMutation,
    MultiAccountRemoveAccessRequestMutationVariables
  >(MultiAccountRemoveAccessRequestDocument, options);
}
export type MultiAccountRemoveAccessRequestMutationHookResult = ReturnType<
  typeof useMultiAccountRemoveAccessRequestMutation
>;
export type MultiAccountRemoveAccessRequestMutationResult =
  Apollo.MutationResult<MultiAccountRemoveAccessRequestMutation>;
export type MultiAccountRemoveAccessRequestMutationOptions = Apollo.BaseMutationOptions<
  MultiAccountRemoveAccessRequestMutation,
  MultiAccountRemoveAccessRequestMutationVariables
>;
export const GroupsTableClientsDocument = gql`
  query groupsTableClients {
    clients {
      id
      name
      dateAdded
      numberOfDomains
      numberOfKeywords
      organization {
        id
      }
      domains {
        id
      }
    }
  }
`;

/**
 * __useGroupsTableClientsQuery__
 *
 * To run a query within a React component, call `useGroupsTableClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsTableClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsTableClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsTableClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupsTableClientsQuery, GroupsTableClientsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupsTableClientsQuery, GroupsTableClientsQueryVariables>(
    GroupsTableClientsDocument,
    options,
  );
}
export function useGroupsTableClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupsTableClientsQuery,
    GroupsTableClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupsTableClientsQuery, GroupsTableClientsQueryVariables>(
    GroupsTableClientsDocument,
    options,
  );
}
export type GroupsTableClientsQueryHookResult = ReturnType<typeof useGroupsTableClientsQuery>;
export type GroupsTableClientsLazyQueryHookResult = ReturnType<
  typeof useGroupsTableClientsLazyQuery
>;
export type GroupsTableClientsQueryResult = Apollo.QueryResult<
  GroupsTableClientsQuery,
  GroupsTableClientsQueryVariables
>;
export const GroupsTableUpdateClientDocument = gql`
  mutation groupsTableUpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type GroupsTableUpdateClientMutationFn = Apollo.MutationFunction<
  GroupsTableUpdateClientMutation,
  GroupsTableUpdateClientMutationVariables
>;

/**
 * __useGroupsTableUpdateClientMutation__
 *
 * To run a mutation, you first call `useGroupsTableUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupsTableUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupsTableUpdateClientMutation, { data, loading, error }] = useGroupsTableUpdateClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupsTableUpdateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupsTableUpdateClientMutation,
    GroupsTableUpdateClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GroupsTableUpdateClientMutation,
    GroupsTableUpdateClientMutationVariables
  >(GroupsTableUpdateClientDocument, options);
}
export type GroupsTableUpdateClientMutationHookResult = ReturnType<
  typeof useGroupsTableUpdateClientMutation
>;
export type GroupsTableUpdateClientMutationResult =
  Apollo.MutationResult<GroupsTableUpdateClientMutation>;
export type GroupsTableUpdateClientMutationOptions = Apollo.BaseMutationOptions<
  GroupsTableUpdateClientMutation,
  GroupsTableUpdateClientMutationVariables
>;
export const GroupsTableDocument = gql`
  query groupsTable(
    $filters: [FilterInput]!
    $ordering: OrderingInput!
    $pagination: PaginationInput!
  ) {
    tableClients(filters: $filters, ordering: $ordering, pagination: $pagination) {
      clients {
        id
        name
        numberOfDomains
        numberOfKeywords
        createdAt
        domainIds
      }
      pagination {
        numResults
        startIndex
        stopIndex
      }
    }
  }
`;

/**
 * __useGroupsTableQuery__
 *
 * To run a query within a React component, call `useGroupsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsTableQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      ordering: // value for 'ordering'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGroupsTableQuery(
  baseOptions: Apollo.QueryHookOptions<GroupsTableQuery, GroupsTableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupsTableQuery, GroupsTableQueryVariables>(GroupsTableDocument, options);
}
export function useGroupsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupsTableQuery, GroupsTableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupsTableQuery, GroupsTableQueryVariables>(
    GroupsTableDocument,
    options,
  );
}
export type GroupsTableQueryHookResult = ReturnType<typeof useGroupsTableQuery>;
export type GroupsTableLazyQueryHookResult = ReturnType<typeof useGroupsTableLazyQuery>;
export type GroupsTableQueryResult = Apollo.QueryResult<
  GroupsTableQuery,
  GroupsTableQueryVariables
>;
export const GscKeywordsDocument = gql`
  query GscKeywords($domainId: ID!) {
    domain(id: $domainId) {
      id
      gscKeywords
    }
  }
`;

/**
 * __useGscKeywordsQuery__
 *
 * To run a query within a React component, call `useGscKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGscKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGscKeywordsQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useGscKeywordsQuery(
  baseOptions: Apollo.QueryHookOptions<GscKeywordsQuery, GscKeywordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GscKeywordsQuery, GscKeywordsQueryVariables>(GscKeywordsDocument, options);
}
export function useGscKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GscKeywordsQuery, GscKeywordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GscKeywordsQuery, GscKeywordsQueryVariables>(
    GscKeywordsDocument,
    options,
  );
}
export type GscKeywordsQueryHookResult = ReturnType<typeof useGscKeywordsQuery>;
export type GscKeywordsLazyQueryHookResult = ReturnType<typeof useGscKeywordsLazyQuery>;
export type GscKeywordsQueryResult = Apollo.QueryResult<
  GscKeywordsQuery,
  GscKeywordsQueryVariables
>;
export const ImportValidationPageImportDocument = gql`
  query ImportValidationPageImport($id: ID!) {
    uploadImport(id: $id) {
      id
      status
      progress
      file
      errors {
        message
        exception
        line
      }
      createdAt
      validatingAt
      validationErrorAt
      processingAt
      completedAt
      domain {
        id
      }
      client {
        id
      }
      columns {
        id
        name
        mappedColumnId
        examples
        errors {
          line
          message
          exception
        }
      }
      mappableColumns {
        id
        name
        type
        description
        examples
        required
      }
      rankProcessingStartedAt
      rankProcessingLastRowIdx
      rankProcessingResumedAt
      rankProcessingResumedRankIdx
      rankProcessingTotalRows
    }
  }
`;

/**
 * __useImportValidationPageImportQuery__
 *
 * To run a query within a React component, call `useImportValidationPageImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportValidationPageImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportValidationPageImportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useImportValidationPageImportQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImportValidationPageImportQuery,
    ImportValidationPageImportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ImportValidationPageImportQuery, ImportValidationPageImportQueryVariables>(
    ImportValidationPageImportDocument,
    options,
  );
}
export function useImportValidationPageImportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImportValidationPageImportQuery,
    ImportValidationPageImportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImportValidationPageImportQuery,
    ImportValidationPageImportQueryVariables
  >(ImportValidationPageImportDocument, options);
}
export type ImportValidationPageImportQueryHookResult = ReturnType<
  typeof useImportValidationPageImportQuery
>;
export type ImportValidationPageImportLazyQueryHookResult = ReturnType<
  typeof useImportValidationPageImportLazyQuery
>;
export type ImportValidationPageImportQueryResult = Apollo.QueryResult<
  ImportValidationPageImportQuery,
  ImportValidationPageImportQueryVariables
>;
export const ImportValidationPageClientsDocument = gql`
  query ImportValidationPageClients {
    clients {
      id
      name
      isDemoClient
      domains {
        id
        domain
        displayName
      }
    }
  }
`;

/**
 * __useImportValidationPageClientsQuery__
 *
 * To run a query within a React component, call `useImportValidationPageClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportValidationPageClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportValidationPageClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useImportValidationPageClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ImportValidationPageClientsQuery,
    ImportValidationPageClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImportValidationPageClientsQuery,
    ImportValidationPageClientsQueryVariables
  >(ImportValidationPageClientsDocument, options);
}
export function useImportValidationPageClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImportValidationPageClientsQuery,
    ImportValidationPageClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImportValidationPageClientsQuery,
    ImportValidationPageClientsQueryVariables
  >(ImportValidationPageClientsDocument, options);
}
export type ImportValidationPageClientsQueryHookResult = ReturnType<
  typeof useImportValidationPageClientsQuery
>;
export type ImportValidationPageClientsLazyQueryHookResult = ReturnType<
  typeof useImportValidationPageClientsLazyQuery
>;
export type ImportValidationPageClientsQueryResult = Apollo.QueryResult<
  ImportValidationPageClientsQuery,
  ImportValidationPageClientsQueryVariables
>;
export const ImportValidationPageUpdateImportDocument = gql`
  mutation ImportValidationPageUpdateImport($input: UpdateUploadImportInput!) {
    updateUploadImport(input: $input) {
      uploadImport {
        id
        status
      }
    }
  }
`;
export type ImportValidationPageUpdateImportMutationFn = Apollo.MutationFunction<
  ImportValidationPageUpdateImportMutation,
  ImportValidationPageUpdateImportMutationVariables
>;

/**
 * __useImportValidationPageUpdateImportMutation__
 *
 * To run a mutation, you first call `useImportValidationPageUpdateImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportValidationPageUpdateImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importValidationPageUpdateImportMutation, { data, loading, error }] = useImportValidationPageUpdateImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportValidationPageUpdateImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportValidationPageUpdateImportMutation,
    ImportValidationPageUpdateImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportValidationPageUpdateImportMutation,
    ImportValidationPageUpdateImportMutationVariables
  >(ImportValidationPageUpdateImportDocument, options);
}
export type ImportValidationPageUpdateImportMutationHookResult = ReturnType<
  typeof useImportValidationPageUpdateImportMutation
>;
export type ImportValidationPageUpdateImportMutationResult =
  Apollo.MutationResult<ImportValidationPageUpdateImportMutation>;
export type ImportValidationPageUpdateImportMutationOptions = Apollo.BaseMutationOptions<
  ImportValidationPageUpdateImportMutation,
  ImportValidationPageUpdateImportMutationVariables
>;
export const ValidationImportRedirectDocument = gql`
  query ValidationImportRedirect($id: ID!) {
    importRedirect(id: $id) {
      redirectUrl
    }
  }
`;

/**
 * __useValidationImportRedirectQuery__
 *
 * To run a query within a React component, call `useValidationImportRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidationImportRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidationImportRedirectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useValidationImportRedirectQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidationImportRedirectQuery,
    ValidationImportRedirectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidationImportRedirectQuery, ValidationImportRedirectQueryVariables>(
    ValidationImportRedirectDocument,
    options,
  );
}
export function useValidationImportRedirectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidationImportRedirectQuery,
    ValidationImportRedirectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidationImportRedirectQuery, ValidationImportRedirectQueryVariables>(
    ValidationImportRedirectDocument,
    options,
  );
}
export type ValidationImportRedirectQueryHookResult = ReturnType<
  typeof useValidationImportRedirectQuery
>;
export type ValidationImportRedirectLazyQueryHookResult = ReturnType<
  typeof useValidationImportRedirectLazyQuery
>;
export type ValidationImportRedirectQueryResult = Apollo.QueryResult<
  ValidationImportRedirectQuery,
  ValidationImportRedirectQueryVariables
>;
export const ImportPageUploadedImportsDocument = gql`
  query ImportPageUploadedImports {
    uploadedImports(
      fields: ["id", "status", "progress", "createdAt", "file", "client.id", "client.name"]
    )
  }
`;

/**
 * __useImportPageUploadedImportsQuery__
 *
 * To run a query within a React component, call `useImportPageUploadedImportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportPageUploadedImportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportPageUploadedImportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useImportPageUploadedImportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ImportPageUploadedImportsQuery,
    ImportPageUploadedImportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ImportPageUploadedImportsQuery, ImportPageUploadedImportsQueryVariables>(
    ImportPageUploadedImportsDocument,
    options,
  );
}
export function useImportPageUploadedImportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImportPageUploadedImportsQuery,
    ImportPageUploadedImportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImportPageUploadedImportsQuery,
    ImportPageUploadedImportsQueryVariables
  >(ImportPageUploadedImportsDocument, options);
}
export type ImportPageUploadedImportsQueryHookResult = ReturnType<
  typeof useImportPageUploadedImportsQuery
>;
export type ImportPageUploadedImportsLazyQueryHookResult = ReturnType<
  typeof useImportPageUploadedImportsLazyQuery
>;
export type ImportPageUploadedImportsQueryResult = Apollo.QueryResult<
  ImportPageUploadedImportsQuery,
  ImportPageUploadedImportsQueryVariables
>;
export const ImportRedirectDocument = gql`
  query ImportRedirect($id: ID!) {
    importRedirect(id: $id) {
      redirectUrl
    }
  }
`;

/**
 * __useImportRedirectQuery__
 *
 * To run a query within a React component, call `useImportRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportRedirectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useImportRedirectQuery(
  baseOptions: Apollo.QueryHookOptions<ImportRedirectQuery, ImportRedirectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ImportRedirectQuery, ImportRedirectQueryVariables>(
    ImportRedirectDocument,
    options,
  );
}
export function useImportRedirectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ImportRedirectQuery, ImportRedirectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ImportRedirectQuery, ImportRedirectQueryVariables>(
    ImportRedirectDocument,
    options,
  );
}
export type ImportRedirectQueryHookResult = ReturnType<typeof useImportRedirectQuery>;
export type ImportRedirectLazyQueryHookResult = ReturnType<typeof useImportRedirectLazyQuery>;
export type ImportRedirectQueryResult = Apollo.QueryResult<
  ImportRedirectQuery,
  ImportRedirectQueryVariables
>;
export const ImportPageDeleteImportDocument = gql`
  mutation ImportPageDeleteImport($input: DeleteUploadImportInput!) {
    deleteUploadImport(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type ImportPageDeleteImportMutationFn = Apollo.MutationFunction<
  ImportPageDeleteImportMutation,
  ImportPageDeleteImportMutationVariables
>;

/**
 * __useImportPageDeleteImportMutation__
 *
 * To run a mutation, you first call `useImportPageDeleteImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPageDeleteImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPageDeleteImportMutation, { data, loading, error }] = useImportPageDeleteImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportPageDeleteImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImportPageDeleteImportMutation,
    ImportPageDeleteImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImportPageDeleteImportMutation,
    ImportPageDeleteImportMutationVariables
  >(ImportPageDeleteImportDocument, options);
}
export type ImportPageDeleteImportMutationHookResult = ReturnType<
  typeof useImportPageDeleteImportMutation
>;
export type ImportPageDeleteImportMutationResult =
  Apollo.MutationResult<ImportPageDeleteImportMutation>;
export type ImportPageDeleteImportMutationOptions = Apollo.BaseMutationOptions<
  ImportPageDeleteImportMutation,
  ImportPageDeleteImportMutationVariables
>;
export const ApiFiltersDocument = gql`
  query ApiFilters {
    user {
      id
      apiToken
      organization {
        id
        apiFilters {
          ...ApiFilter
        }
      }
    }
  }
  ${ApiFilterFragmentDoc}
`;

/**
 * __useApiFiltersQuery__
 *
 * To run a query within a React component, call `useApiFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<ApiFiltersQuery, ApiFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiFiltersQuery, ApiFiltersQueryVariables>(ApiFiltersDocument, options);
}
export function useApiFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ApiFiltersQuery, ApiFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiFiltersQuery, ApiFiltersQueryVariables>(
    ApiFiltersDocument,
    options,
  );
}
export type ApiFiltersQueryHookResult = ReturnType<typeof useApiFiltersQuery>;
export type ApiFiltersLazyQueryHookResult = ReturnType<typeof useApiFiltersLazyQuery>;
export type ApiFiltersQueryResult = Apollo.QueryResult<ApiFiltersQuery, ApiFiltersQueryVariables>;
export const InvoicesTableDocument = gql`
  query InvoicesTable($id: ID!) {
    organization(id: $id) {
      id
      name
      invoices {
        number
        createdAt
        paid
        currency
        vat
        amount
        total
        url
      }
    }
  }
`;

/**
 * __useInvoicesTableQuery__
 *
 * To run a query within a React component, call `useInvoicesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesTableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoicesTableQuery(
  baseOptions: Apollo.QueryHookOptions<InvoicesTableQuery, InvoicesTableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoicesTableQuery, InvoicesTableQueryVariables>(
    InvoicesTableDocument,
    options,
  );
}
export function useInvoicesTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvoicesTableQuery, InvoicesTableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoicesTableQuery, InvoicesTableQueryVariables>(
    InvoicesTableDocument,
    options,
  );
}
export type InvoicesTableQueryHookResult = ReturnType<typeof useInvoicesTableQuery>;
export type InvoicesTableLazyQueryHookResult = ReturnType<typeof useInvoicesTableLazyQuery>;
export type InvoicesTableQueryResult = Apollo.QueryResult<
  InvoicesTableQuery,
  InvoicesTableQueryVariables
>;
export const RelevantDomainSearchesDocument = gql`
  query relevantDomainSearches {
    relevantDomainSearches {
      domainTypes {
        latestSearches
        domains
        competitors
      }
    }
  }
`;

/**
 * __useRelevantDomainSearchesQuery__
 *
 * To run a query within a React component, call `useRelevantDomainSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelevantDomainSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelevantDomainSearchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRelevantDomainSearchesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RelevantDomainSearchesQuery,
    RelevantDomainSearchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RelevantDomainSearchesQuery, RelevantDomainSearchesQueryVariables>(
    RelevantDomainSearchesDocument,
    options,
  );
}
export function useRelevantDomainSearchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RelevantDomainSearchesQuery,
    RelevantDomainSearchesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RelevantDomainSearchesQuery, RelevantDomainSearchesQueryVariables>(
    RelevantDomainSearchesDocument,
    options,
  );
}
export type RelevantDomainSearchesQueryHookResult = ReturnType<
  typeof useRelevantDomainSearchesQuery
>;
export type RelevantDomainSearchesLazyQueryHookResult = ReturnType<
  typeof useRelevantDomainSearchesLazyQuery
>;
export type RelevantDomainSearchesQueryResult = Apollo.QueryResult<
  RelevantDomainSearchesQuery,
  RelevantDomainSearchesQueryVariables
>;
export const AddDomainSearchDocument = gql`
  mutation addDomainSearch($searchTerm: String!) {
    addKeywordResearchDomainSearch(searchTerm: $searchTerm) {
      errors {
        messages
      }
      searchTerm
    }
  }
`;
export type AddDomainSearchMutationFn = Apollo.MutationFunction<
  AddDomainSearchMutation,
  AddDomainSearchMutationVariables
>;

/**
 * __useAddDomainSearchMutation__
 *
 * To run a mutation, you first call `useAddDomainSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDomainSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDomainSearchMutation, { data, loading, error }] = useAddDomainSearchMutation({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useAddDomainSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDomainSearchMutation,
    AddDomainSearchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDomainSearchMutation, AddDomainSearchMutationVariables>(
    AddDomainSearchDocument,
    options,
  );
}
export type AddDomainSearchMutationHookResult = ReturnType<typeof useAddDomainSearchMutation>;
export type AddDomainSearchMutationResult = Apollo.MutationResult<AddDomainSearchMutation>;
export type AddDomainSearchMutationOptions = Apollo.BaseMutationOptions<
  AddDomainSearchMutation,
  AddDomainSearchMutationVariables
>;
export const ValidateDomainDocument = gql`
  query ValidateDomain($domain: String!) {
    validateDomain(domain: $domain) {
      domain
      domainValidation {
        redirectTo
        valid
      }
    }
  }
`;

/**
 * __useValidateDomainQuery__
 *
 * To run a query within a React component, call `useValidateDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateDomainQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useValidateDomainQuery(
  baseOptions: Apollo.QueryHookOptions<ValidateDomainQuery, ValidateDomainQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateDomainQuery, ValidateDomainQueryVariables>(
    ValidateDomainDocument,
    options,
  );
}
export function useValidateDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidateDomainQuery, ValidateDomainQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidateDomainQuery, ValidateDomainQueryVariables>(
    ValidateDomainDocument,
    options,
  );
}
export type ValidateDomainQueryHookResult = ReturnType<typeof useValidateDomainQuery>;
export type ValidateDomainLazyQueryHookResult = ReturnType<typeof useValidateDomainLazyQuery>;
export type ValidateDomainQueryResult = Apollo.QueryResult<
  ValidateDomainQuery,
  ValidateDomainQueryVariables
>;
export const WordPhrasesDocument = gql`
  query wordPhrases(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
    $country: String
    $displayCurrency: String!
  ) {
    keydisWordPhrases(
      filters: $filters
      pagination: $pagination
      ordering: $ordering
      country: $country
      displayCurrency: $displayCurrency
    ) {
      wordPhrases
      pagination {
        numResults
        startIndex
        stopIndex
      }
    }
  }
`;

/**
 * __useWordPhrasesQuery__
 *
 * To run a query within a React component, call `useWordPhrasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWordPhrasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWordPhrasesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      country: // value for 'country'
 *      displayCurrency: // value for 'displayCurrency'
 *   },
 * });
 */
export function useWordPhrasesQuery(
  baseOptions: Apollo.QueryHookOptions<WordPhrasesQuery, WordPhrasesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WordPhrasesQuery, WordPhrasesQueryVariables>(WordPhrasesDocument, options);
}
export function useWordPhrasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WordPhrasesQuery, WordPhrasesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WordPhrasesQuery, WordPhrasesQueryVariables>(
    WordPhrasesDocument,
    options,
  );
}
export type WordPhrasesQueryHookResult = ReturnType<typeof useWordPhrasesQuery>;
export type WordPhrasesLazyQueryHookResult = ReturnType<typeof useWordPhrasesLazyQuery>;
export type WordPhrasesQueryResult = Apollo.QueryResult<
  WordPhrasesQuery,
  WordPhrasesQueryVariables
>;
export const KeydisWordCloudDocument = gql`
  query keydisWordCloud($filters: [FilterInput]!, $country: String) {
    keydisWordCloud(filters: $filters, country: $country) {
      words
    }
  }
`;

/**
 * __useKeydisWordCloudQuery__
 *
 * To run a query within a React component, call `useKeydisWordCloudQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeydisWordCloudQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeydisWordCloudQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useKeydisWordCloudQuery(
  baseOptions: Apollo.QueryHookOptions<KeydisWordCloudQuery, KeydisWordCloudQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeydisWordCloudQuery, KeydisWordCloudQueryVariables>(
    KeydisWordCloudDocument,
    options,
  );
}
export function useKeydisWordCloudLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KeydisWordCloudQuery, KeydisWordCloudQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeydisWordCloudQuery, KeydisWordCloudQueryVariables>(
    KeydisWordCloudDocument,
    options,
  );
}
export type KeydisWordCloudQueryHookResult = ReturnType<typeof useKeydisWordCloudQuery>;
export type KeydisWordCloudLazyQueryHookResult = ReturnType<typeof useKeydisWordCloudLazyQuery>;
export type KeydisWordCloudQueryResult = Apollo.QueryResult<
  KeydisWordCloudQuery,
  KeydisWordCloudQueryVariables
>;
export const CountByCountryDocument = gql`
  query CountByCountry($filters: [FilterInput]!) {
    countByCountry(filters: $filters) {
      countries {
        keywords
        country
        default
        searchSettings {
          countrylocale {
            id
            region
          }
          locations
          searchEngines {
            searchEngine {
              id
              name
            }
            searchTypes
          }
          ignoreLocalResults
          ignoreFeaturedSnippet
          enableAutocorrect
        }
      }
    }
  }
`;

/**
 * __useCountByCountryQuery__
 *
 * To run a query within a React component, call `useCountByCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountByCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountByCountryQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCountByCountryQuery(
  baseOptions: Apollo.QueryHookOptions<CountByCountryQuery, CountByCountryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountByCountryQuery, CountByCountryQueryVariables>(
    CountByCountryDocument,
    options,
  );
}
export function useCountByCountryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountByCountryQuery, CountByCountryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountByCountryQuery, CountByCountryQueryVariables>(
    CountByCountryDocument,
    options,
  );
}
export type CountByCountryQueryHookResult = ReturnType<typeof useCountByCountryQuery>;
export type CountByCountryLazyQueryHookResult = ReturnType<typeof useCountByCountryLazyQuery>;
export type CountByCountryQueryResult = Apollo.QueryResult<
  CountByCountryQuery,
  CountByCountryQueryVariables
>;
export const KeydisKeywordsDocument = gql`
  query keydisKeywords(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
    $country: String
    $displayCurrency: String!
  ) {
    keydisKeywords(
      filters: $filters
      pagination: $pagination
      ordering: $ordering
      country: $country
      displayCurrency: $displayCurrency
    ) {
      keywords
      pagination {
        numResults
        startIndex
        stopIndex
      }
    }
  }
`;

/**
 * __useKeydisKeywordsQuery__
 *
 * To run a query within a React component, call `useKeydisKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeydisKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeydisKeywordsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      country: // value for 'country'
 *      displayCurrency: // value for 'displayCurrency'
 *   },
 * });
 */
export function useKeydisKeywordsQuery(
  baseOptions: Apollo.QueryHookOptions<KeydisKeywordsQuery, KeydisKeywordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeydisKeywordsQuery, KeydisKeywordsQueryVariables>(
    KeydisKeywordsDocument,
    options,
  );
}
export function useKeydisKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KeydisKeywordsQuery, KeydisKeywordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeydisKeywordsQuery, KeydisKeywordsQueryVariables>(
    KeydisKeywordsDocument,
    options,
  );
}
export type KeydisKeywordsQueryHookResult = ReturnType<typeof useKeydisKeywordsQuery>;
export type KeydisKeywordsLazyQueryHookResult = ReturnType<typeof useKeydisKeywordsLazyQuery>;
export type KeydisKeywordsQueryResult = Apollo.QueryResult<
  KeydisKeywordsQuery,
  KeydisKeywordsQueryVariables
>;
export const KeydisImportInfoDocument = gql`
  query keydisImportInfo($filters: [FilterInput]!) {
    keydisImportInfo(filters: $filters) {
      keydisImportInfo {
        keywords {
          keyword
        }
        country
        searchSettings {
          countrylocale {
            id
            region
          }
          locations
          searchEngines {
            searchEngine {
              id
              name
            }
            searchTypes
          }
          ignoreLocalResults
          ignoreFeaturedSnippet
          enableAutocorrect
        }
      }
    }
  }
`;

/**
 * __useKeydisImportInfoQuery__
 *
 * To run a query within a React component, call `useKeydisImportInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeydisImportInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeydisImportInfoQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useKeydisImportInfoQuery(
  baseOptions: Apollo.QueryHookOptions<KeydisImportInfoQuery, KeydisImportInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeydisImportInfoQuery, KeydisImportInfoQueryVariables>(
    KeydisImportInfoDocument,
    options,
  );
}
export function useKeydisImportInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KeydisImportInfoQuery, KeydisImportInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeydisImportInfoQuery, KeydisImportInfoQueryVariables>(
    KeydisImportInfoDocument,
    options,
  );
}
export type KeydisImportInfoQueryHookResult = ReturnType<typeof useKeydisImportInfoQuery>;
export type KeydisImportInfoLazyQueryHookResult = ReturnType<typeof useKeydisImportInfoLazyQuery>;
export type KeydisImportInfoQueryResult = Apollo.QueryResult<
  KeydisImportInfoQuery,
  KeydisImportInfoQueryVariables
>;
export const KeydisKpisDocument = gql`
  query keydisKpis($filters: [FilterInput]!, $country: String, $displayCurrency: String!) {
    keydisKpis(filters: $filters, country: $country, displayCurrency: $displayCurrency) {
      discoveredKeywords
      trafficValue
      estimatedVisits
      averageRank
      aboveTheFold
      averageCtr
      commercial
      transactional
      navigational
      informational
      serpFeatures
    }
  }
`;

/**
 * __useKeydisKpisQuery__
 *
 * To run a query within a React component, call `useKeydisKpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeydisKpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeydisKpisQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      country: // value for 'country'
 *      displayCurrency: // value for 'displayCurrency'
 *   },
 * });
 */
export function useKeydisKpisQuery(
  baseOptions: Apollo.QueryHookOptions<KeydisKpisQuery, KeydisKpisQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeydisKpisQuery, KeydisKpisQueryVariables>(KeydisKpisDocument, options);
}
export function useKeydisKpisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KeydisKpisQuery, KeydisKpisQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeydisKpisQuery, KeydisKpisQueryVariables>(
    KeydisKpisDocument,
    options,
  );
}
export type KeydisKpisQueryHookResult = ReturnType<typeof useKeydisKpisQuery>;
export type KeydisKpisLazyQueryHookResult = ReturnType<typeof useKeydisKpisLazyQuery>;
export type KeydisKpisQueryResult = Apollo.QueryResult<KeydisKpisQuery, KeydisKpisQueryVariables>;
export const KeydisCompetitorsDocument = gql`
  query keydisCompetitors($filters: [FilterInput]!, $country: String) {
    keydisCompetitors(filters: $filters, country: $country) {
      domain
      shareOfVoice
      searchVolume
      keywords
    }
  }
`;

/**
 * __useKeydisCompetitorsQuery__
 *
 * To run a query within a React component, call `useKeydisCompetitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeydisCompetitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeydisCompetitorsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useKeydisCompetitorsQuery(
  baseOptions: Apollo.QueryHookOptions<KeydisCompetitorsQuery, KeydisCompetitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeydisCompetitorsQuery, KeydisCompetitorsQueryVariables>(
    KeydisCompetitorsDocument,
    options,
  );
}
export function useKeydisCompetitorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KeydisCompetitorsQuery,
    KeydisCompetitorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeydisCompetitorsQuery, KeydisCompetitorsQueryVariables>(
    KeydisCompetitorsDocument,
    options,
  );
}
export type KeydisCompetitorsQueryHookResult = ReturnType<typeof useKeydisCompetitorsQuery>;
export type KeydisCompetitorsLazyQueryHookResult = ReturnType<typeof useKeydisCompetitorsLazyQuery>;
export type KeydisCompetitorsQueryResult = Apollo.QueryResult<
  KeydisCompetitorsQuery,
  KeydisCompetitorsQueryVariables
>;
export const CompetitorsChartDocument = gql`
  query CompetitorsChart(
    $allHistory: Boolean
    $metric: String
    $filters: [FilterInput]!
    $asPercentage: Boolean
  ) {
    graphs(filters: $filters) {
      competitorHistory(allHistory: $allHistory, metric: $metric, asPercentage: $asPercentage)
    }
  }
`;

/**
 * __useCompetitorsChartQuery__
 *
 * To run a query within a React component, call `useCompetitorsChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetitorsChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetitorsChartQuery({
 *   variables: {
 *      allHistory: // value for 'allHistory'
 *      metric: // value for 'metric'
 *      filters: // value for 'filters'
 *      asPercentage: // value for 'asPercentage'
 *   },
 * });
 */
export function useCompetitorsChartQuery(
  baseOptions: Apollo.QueryHookOptions<CompetitorsChartQuery, CompetitorsChartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompetitorsChartQuery, CompetitorsChartQueryVariables>(
    CompetitorsChartDocument,
    options,
  );
}
export function useCompetitorsChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompetitorsChartQuery, CompetitorsChartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompetitorsChartQuery, CompetitorsChartQueryVariables>(
    CompetitorsChartDocument,
    options,
  );
}
export type CompetitorsChartQueryHookResult = ReturnType<typeof useCompetitorsChartQuery>;
export type CompetitorsChartLazyQueryHookResult = ReturnType<typeof useCompetitorsChartLazyQuery>;
export type CompetitorsChartQueryResult = Apollo.QueryResult<
  CompetitorsChartQuery,
  CompetitorsChartQueryVariables
>;
export const CompetitorsRanksQueryDocument = gql`
  query CompetitorsRanksQuery(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
  ) {
    competitorRankList(filters: $filters, pagination: $pagination, ordering: $ordering) {
      keywords {
        id
        keyword
        searchTypeId
        searchEngineId
        searchVolumeValue
        rankValue
        aboveTheFold
        rankCompare
        location
        validForSearchVolume
        noSearchVolumeForLocation
        countrylocaleId
        competitor1Rank
        competitor1RankCompare
        competitor1CompetitorId
        competitor2Rank
        competitor2RankCompare
        competitor2CompetitorId
        competitor3Rank
        competitor3RankCompare
        competitor3CompetitorId
        competitor4Rank
        competitor4RankCompare
        competitor4CompetitorId
        competitor5Rank
        competitor5RankCompare
        competitor5CompetitorId
        competitor6Rank
        competitor6RankCompare
        competitor6CompetitorId
        competitor7Rank
        competitor7RankCompare
        competitor7CompetitorId
        competitor8Rank
        competitor8RankCompare
        competitor8CompetitorId
        competitor9Rank
        competitor9RankCompare
        competitor9CompetitorId
        competitor10Rank
        competitor10RankCompare
        competitor10CompetitorId
      }
      competitors {
        competitorId
        displayName
        domain
      }
      pagination {
        page
        results
        numResults
        numPages
      }
    }
  }
`;

/**
 * __useCompetitorsRanksQueryQuery__
 *
 * To run a query within a React component, call `useCompetitorsRanksQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetitorsRanksQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetitorsRanksQueryQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useCompetitorsRanksQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompetitorsRanksQueryQuery,
    CompetitorsRanksQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompetitorsRanksQueryQuery, CompetitorsRanksQueryQueryVariables>(
    CompetitorsRanksQueryDocument,
    options,
  );
}
export function useCompetitorsRanksQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompetitorsRanksQueryQuery,
    CompetitorsRanksQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompetitorsRanksQueryQuery, CompetitorsRanksQueryQueryVariables>(
    CompetitorsRanksQueryDocument,
    options,
  );
}
export type CompetitorsRanksQueryQueryHookResult = ReturnType<typeof useCompetitorsRanksQueryQuery>;
export type CompetitorsRanksQueryLazyQueryHookResult = ReturnType<
  typeof useCompetitorsRanksQueryLazyQuery
>;
export type CompetitorsRanksQueryQueryResult = Apollo.QueryResult<
  CompetitorsRanksQueryQuery,
  CompetitorsRanksQueryQueryVariables
>;
export const DeleteCompetitorDocument = gql`
  mutation DeleteCompetitor($input: UpdateCompetitorInput!) {
    updateCompetitor(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type DeleteCompetitorMutationFn = Apollo.MutationFunction<
  DeleteCompetitorMutation,
  DeleteCompetitorMutationVariables
>;

/**
 * __useDeleteCompetitorMutation__
 *
 * To run a mutation, you first call `useDeleteCompetitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompetitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompetitorMutation, { data, loading, error }] = useDeleteCompetitorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompetitorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCompetitorMutation,
    DeleteCompetitorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCompetitorMutation, DeleteCompetitorMutationVariables>(
    DeleteCompetitorDocument,
    options,
  );
}
export type DeleteCompetitorMutationHookResult = ReturnType<typeof useDeleteCompetitorMutation>;
export type DeleteCompetitorMutationResult = Apollo.MutationResult<DeleteCompetitorMutation>;
export type DeleteCompetitorMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompetitorMutation,
  DeleteCompetitorMutationVariables
>;
export const CompetitorsDocument = gql`
  query Competitors(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
    $withId: Boolean!
    $withDomain: Boolean!
    $withFaviconUrl: Boolean!
    $withDisplayName: Boolean!
    $withOrganicTraffic: Boolean!
    $withOrganicTrafficCompare: Boolean!
    $withDynamicCtrValue: Boolean!
    $withDynamicCtrCompare: Boolean!
    $withShareOfVoiceValue: Boolean!
    $withShareOfVoiceCompare: Boolean!
    $withShareOfVoicePercentageValue: Boolean!
    $withShareOfVoicePercentageValueCompare: Boolean!
    $withCount1To3: Boolean!
    $withCount1To3Compare: Boolean!
    $withCount4To10: Boolean!
    $withCount4To10Compare: Boolean!
    $withCount11To20: Boolean!
    $withCount11To20Compare: Boolean!
    $withCount21To50: Boolean!
    $withCount21To50Compare: Boolean!
    $withCount51To100: Boolean!
    $withCount51To100Compare: Boolean!
    $withCountUnranked: Boolean!
    $withCountUnrankedCompare: Boolean!
    $withAvgRankValue: Boolean!
    $withAvgRankCompare: Boolean!
  ) {
    competitorList(filters: $filters, pagination: $pagination, ordering: $ordering) {
      competitors {
        id @include(if: $withId)
        domain @include(if: $withDomain)
        ownDomain @include(if: $withDomain)
        faviconUrl @include(if: $withFaviconUrl)
        displayName @include(if: $withDisplayName)
        organicTraffic @include(if: $withOrganicTraffic)
        organicTrafficCompare @include(if: $withOrganicTrafficCompare)
        dynamicCtrValue @include(if: $withDynamicCtrValue)
        dynamicCtrCompare @include(if: $withDynamicCtrCompare)
        shareOfVoiceValue @include(if: $withShareOfVoiceValue)
        shareOfVoiceCompare @include(if: $withShareOfVoiceCompare)
        shareOfVoicePercentageValue @include(if: $withShareOfVoicePercentageValue)
        shareOfVoicePercentageValueCompare @include(if: $withShareOfVoicePercentageValueCompare)
        count1To3 @include(if: $withCount1To3)
        count1To3Compare @include(if: $withCount1To3Compare)
        count4To10 @include(if: $withCount4To10)
        count4To10Compare @include(if: $withCount4To10Compare)
        count11To20 @include(if: $withCount11To20)
        count11To20Compare @include(if: $withCount11To20Compare)
        count21To50 @include(if: $withCount21To50)
        count21To50Compare @include(if: $withCount21To50Compare)
        count51To100 @include(if: $withCount51To100)
        count51To100Compare @include(if: $withCount51To100Compare)
        countUnranked @include(if: $withCountUnranked)
        countUnrankedCompare @include(if: $withCountUnrankedCompare)
        avgRankValue @include(if: $withAvgRankValue)
        avgRankCompare @include(if: $withAvgRankCompare)
      }
    }
  }
`;

/**
 * __useCompetitorsQuery__
 *
 * To run a query within a React component, call `useCompetitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetitorsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      withId: // value for 'withId'
 *      withDomain: // value for 'withDomain'
 *      withFaviconUrl: // value for 'withFaviconUrl'
 *      withDisplayName: // value for 'withDisplayName'
 *      withOrganicTraffic: // value for 'withOrganicTraffic'
 *      withOrganicTrafficCompare: // value for 'withOrganicTrafficCompare'
 *      withDynamicCtrValue: // value for 'withDynamicCtrValue'
 *      withDynamicCtrCompare: // value for 'withDynamicCtrCompare'
 *      withShareOfVoiceValue: // value for 'withShareOfVoiceValue'
 *      withShareOfVoiceCompare: // value for 'withShareOfVoiceCompare'
 *      withShareOfVoicePercentageValue: // value for 'withShareOfVoicePercentageValue'
 *      withShareOfVoicePercentageValueCompare: // value for 'withShareOfVoicePercentageValueCompare'
 *      withCount1To3: // value for 'withCount1To3'
 *      withCount1To3Compare: // value for 'withCount1To3Compare'
 *      withCount4To10: // value for 'withCount4To10'
 *      withCount4To10Compare: // value for 'withCount4To10Compare'
 *      withCount11To20: // value for 'withCount11To20'
 *      withCount11To20Compare: // value for 'withCount11To20Compare'
 *      withCount21To50: // value for 'withCount21To50'
 *      withCount21To50Compare: // value for 'withCount21To50Compare'
 *      withCount51To100: // value for 'withCount51To100'
 *      withCount51To100Compare: // value for 'withCount51To100Compare'
 *      withCountUnranked: // value for 'withCountUnranked'
 *      withCountUnrankedCompare: // value for 'withCountUnrankedCompare'
 *      withAvgRankValue: // value for 'withAvgRankValue'
 *      withAvgRankCompare: // value for 'withAvgRankCompare'
 *   },
 * });
 */
export function useCompetitorsQuery(
  baseOptions: Apollo.QueryHookOptions<CompetitorsQuery, CompetitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompetitorsQuery, CompetitorsQueryVariables>(CompetitorsDocument, options);
}
export function useCompetitorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompetitorsQuery, CompetitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompetitorsQuery, CompetitorsQueryVariables>(
    CompetitorsDocument,
    options,
  );
}
export type CompetitorsQueryHookResult = ReturnType<typeof useCompetitorsQuery>;
export type CompetitorsLazyQueryHookResult = ReturnType<typeof useCompetitorsLazyQuery>;
export type CompetitorsQueryResult = Apollo.QueryResult<
  CompetitorsQuery,
  CompetitorsQueryVariables
>;
export const FolderStructureDocument = gql`
  query folderStructure(
    $filters: [FilterInput]!
    $nested: Boolean!
    $orderBy: String!
    $order: String!
  ) {
    folderStructure(filters: $filters, nested: $nested, orderBy: $orderBy, order: $order) {
      folderStructure
    }
  }
`;

/**
 * __useFolderStructureQuery__
 *
 * To run a query within a React component, call `useFolderStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderStructureQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      nested: // value for 'nested'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFolderStructureQuery(
  baseOptions: Apollo.QueryHookOptions<FolderStructureQuery, FolderStructureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FolderStructureQuery, FolderStructureQueryVariables>(
    FolderStructureDocument,
    options,
  );
}
export function useFolderStructureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FolderStructureQuery, FolderStructureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FolderStructureQuery, FolderStructureQueryVariables>(
    FolderStructureDocument,
    options,
  );
}
export type FolderStructureQueryHookResult = ReturnType<typeof useFolderStructureQuery>;
export type FolderStructureLazyQueryHookResult = ReturnType<typeof useFolderStructureLazyQuery>;
export type FolderStructureQueryResult = Apollo.QueryResult<
  FolderStructureQuery,
  FolderStructureQueryVariables
>;
export const AvgTopTenCtrByPathDocument = gql`
  query avgTopTenCtrByPath($filters: [FilterInput]!) {
    avgTopTenCtrByPath(filters: $filters) {
      avgTopTenCtrByPath
    }
  }
`;

/**
 * __useAvgTopTenCtrByPathQuery__
 *
 * To run a query within a React component, call `useAvgTopTenCtrByPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvgTopTenCtrByPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvgTopTenCtrByPathQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAvgTopTenCtrByPathQuery(
  baseOptions: Apollo.QueryHookOptions<AvgTopTenCtrByPathQuery, AvgTopTenCtrByPathQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvgTopTenCtrByPathQuery, AvgTopTenCtrByPathQueryVariables>(
    AvgTopTenCtrByPathDocument,
    options,
  );
}
export function useAvgTopTenCtrByPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvgTopTenCtrByPathQuery,
    AvgTopTenCtrByPathQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvgTopTenCtrByPathQuery, AvgTopTenCtrByPathQueryVariables>(
    AvgTopTenCtrByPathDocument,
    options,
  );
}
export type AvgTopTenCtrByPathQueryHookResult = ReturnType<typeof useAvgTopTenCtrByPathQuery>;
export type AvgTopTenCtrByPathLazyQueryHookResult = ReturnType<
  typeof useAvgTopTenCtrByPathLazyQuery
>;
export type AvgTopTenCtrByPathQueryResult = Apollo.QueryResult<
  AvgTopTenCtrByPathQuery,
  AvgTopTenCtrByPathQueryVariables
>;
export const SerpFeaturesByPathDocument = gql`
  query serpFeaturesByPath($filters: [FilterInput]!) {
    serpFeaturesByPath(filters: $filters) {
      serpFeaturesByPath
    }
  }
`;

/**
 * __useSerpFeaturesByPathQuery__
 *
 * To run a query within a React component, call `useSerpFeaturesByPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useSerpFeaturesByPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSerpFeaturesByPathQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSerpFeaturesByPathQuery(
  baseOptions: Apollo.QueryHookOptions<SerpFeaturesByPathQuery, SerpFeaturesByPathQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SerpFeaturesByPathQuery, SerpFeaturesByPathQueryVariables>(
    SerpFeaturesByPathDocument,
    options,
  );
}
export function useSerpFeaturesByPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SerpFeaturesByPathQuery,
    SerpFeaturesByPathQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SerpFeaturesByPathQuery, SerpFeaturesByPathQueryVariables>(
    SerpFeaturesByPathDocument,
    options,
  );
}
export type SerpFeaturesByPathQueryHookResult = ReturnType<typeof useSerpFeaturesByPathQuery>;
export type SerpFeaturesByPathLazyQueryHookResult = ReturnType<
  typeof useSerpFeaturesByPathLazyQuery
>;
export type SerpFeaturesByPathQueryResult = Apollo.QueryResult<
  SerpFeaturesByPathQuery,
  SerpFeaturesByPathQueryVariables
>;
export const FolderPathHistoryDocument = gql`
  query FolderPathHistory($paths: [String]!, $metric: FolderMetric!, $filters: [FilterInput]!) {
    graphs(filters: $filters) {
      folderPathHistory(paths: $paths, metric: $metric)
    }
  }
`;

/**
 * __useFolderPathHistoryQuery__
 *
 * To run a query within a React component, call `useFolderPathHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderPathHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderPathHistoryQuery({
 *   variables: {
 *      paths: // value for 'paths'
 *      metric: // value for 'metric'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFolderPathHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<FolderPathHistoryQuery, FolderPathHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FolderPathHistoryQuery, FolderPathHistoryQueryVariables>(
    FolderPathHistoryDocument,
    options,
  );
}
export function useFolderPathHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FolderPathHistoryQuery,
    FolderPathHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FolderPathHistoryQuery, FolderPathHistoryQueryVariables>(
    FolderPathHistoryDocument,
    options,
  );
}
export type FolderPathHistoryQueryHookResult = ReturnType<typeof useFolderPathHistoryQuery>;
export type FolderPathHistoryLazyQueryHookResult = ReturnType<typeof useFolderPathHistoryLazyQuery>;
export type FolderPathHistoryQueryResult = Apollo.QueryResult<
  FolderPathHistoryQuery,
  FolderPathHistoryQueryVariables
>;
export const DynamicTagRulesetFromTagDocument = gql`
  query DynamicTagRulesetFromTag($tag: String!, $domainId: Int!) {
    dynamicTagRulesetFromTag(tag: $tag, domainId: $domainId) {
      id
      tag
      folderPath
      rawRuleset
      createdBy
      updatedAt
    }
  }
`;

/**
 * __useDynamicTagRulesetFromTagQuery__
 *
 * To run a query within a React component, call `useDynamicTagRulesetFromTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useDynamicTagRulesetFromTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDynamicTagRulesetFromTagQuery({
 *   variables: {
 *      tag: // value for 'tag'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useDynamicTagRulesetFromTagQuery(
  baseOptions: Apollo.QueryHookOptions<
    DynamicTagRulesetFromTagQuery,
    DynamicTagRulesetFromTagQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DynamicTagRulesetFromTagQuery, DynamicTagRulesetFromTagQueryVariables>(
    DynamicTagRulesetFromTagDocument,
    options,
  );
}
export function useDynamicTagRulesetFromTagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DynamicTagRulesetFromTagQuery,
    DynamicTagRulesetFromTagQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DynamicTagRulesetFromTagQuery, DynamicTagRulesetFromTagQueryVariables>(
    DynamicTagRulesetFromTagDocument,
    options,
  );
}
export type DynamicTagRulesetFromTagQueryHookResult = ReturnType<
  typeof useDynamicTagRulesetFromTagQuery
>;
export type DynamicTagRulesetFromTagLazyQueryHookResult = ReturnType<
  typeof useDynamicTagRulesetFromTagLazyQuery
>;
export type DynamicTagRulesetFromTagQueryResult = Apollo.QueryResult<
  DynamicTagRulesetFromTagQuery,
  DynamicTagRulesetFromTagQueryVariables
>;
export const StaticTagInformationDocument = gql`
  query StaticTagInformation($tag: String!, $domainId: Int!) {
    staticTagInformation(tag: $tag, domainId: $domainId) {
      tag
      folderPath
      keywords
    }
  }
`;

/**
 * __useStaticTagInformationQuery__
 *
 * To run a query within a React component, call `useStaticTagInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaticTagInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaticTagInformationQuery({
 *   variables: {
 *      tag: // value for 'tag'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useStaticTagInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    StaticTagInformationQuery,
    StaticTagInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StaticTagInformationQuery, StaticTagInformationQueryVariables>(
    StaticTagInformationDocument,
    options,
  );
}
export function useStaticTagInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StaticTagInformationQuery,
    StaticTagInformationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StaticTagInformationQuery, StaticTagInformationQueryVariables>(
    StaticTagInformationDocument,
    options,
  );
}
export type StaticTagInformationQueryHookResult = ReturnType<typeof useStaticTagInformationQuery>;
export type StaticTagInformationLazyQueryHookResult = ReturnType<
  typeof useStaticTagInformationLazyQuery
>;
export type StaticTagInformationQueryResult = Apollo.QueryResult<
  StaticTagInformationQuery,
  StaticTagInformationQueryVariables
>;
export const UpdateFolderStructureDocument = gql`
  mutation updateFolderStructure($newTree: GenericScalar!, $domainId: Int!) {
    updateFolderStructure(newTree: $newTree, domainId: $domainId) {
      errors {
        messages
      }
    }
  }
`;
export type UpdateFolderStructureMutationFn = Apollo.MutationFunction<
  UpdateFolderStructureMutation,
  UpdateFolderStructureMutationVariables
>;

/**
 * __useUpdateFolderStructureMutation__
 *
 * To run a mutation, you first call `useUpdateFolderStructureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderStructureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderStructureMutation, { data, loading, error }] = useUpdateFolderStructureMutation({
 *   variables: {
 *      newTree: // value for 'newTree'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useUpdateFolderStructureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFolderStructureMutation,
    UpdateFolderStructureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFolderStructureMutation, UpdateFolderStructureMutationVariables>(
    UpdateFolderStructureDocument,
    options,
  );
}
export type UpdateFolderStructureMutationHookResult = ReturnType<
  typeof useUpdateFolderStructureMutation
>;
export type UpdateFolderStructureMutationResult =
  Apollo.MutationResult<UpdateFolderStructureMutation>;
export type UpdateFolderStructureMutationOptions = Apollo.BaseMutationOptions<
  UpdateFolderStructureMutation,
  UpdateFolderStructureMutationVariables
>;
export const UpdateFolderDescriptionDocument = gql`
  mutation updateFolderDescription(
    $description: String!
    $path: String!
    $isFolder: Boolean!
    $domainId: Int!
  ) {
    updateFolderDescription(
      description: $description
      path: $path
      isFolder: $isFolder
      domainId: $domainId
    ) {
      errors {
        messages
      }
    }
  }
`;
export type UpdateFolderDescriptionMutationFn = Apollo.MutationFunction<
  UpdateFolderDescriptionMutation,
  UpdateFolderDescriptionMutationVariables
>;

/**
 * __useUpdateFolderDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateFolderDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderDescriptionMutation, { data, loading, error }] = useUpdateFolderDescriptionMutation({
 *   variables: {
 *      description: // value for 'description'
 *      path: // value for 'path'
 *      isFolder: // value for 'isFolder'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useUpdateFolderDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFolderDescriptionMutation,
    UpdateFolderDescriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFolderDescriptionMutation,
    UpdateFolderDescriptionMutationVariables
  >(UpdateFolderDescriptionDocument, options);
}
export type UpdateFolderDescriptionMutationHookResult = ReturnType<
  typeof useUpdateFolderDescriptionMutation
>;
export type UpdateFolderDescriptionMutationResult =
  Apollo.MutationResult<UpdateFolderDescriptionMutation>;
export type UpdateFolderDescriptionMutationOptions = Apollo.BaseMutationOptions<
  UpdateFolderDescriptionMutation,
  UpdateFolderDescriptionMutationVariables
>;
export const DeleteDynamicTagRulesetFromTagDocument = gql`
  mutation DeleteDynamicTagRulesetFromTag($tag: String!, $domainId: Int!) {
    deleteDynamicTagRulesetFromTag(tag: $tag, domainId: $domainId) {
      errors {
        messages
      }
    }
  }
`;
export type DeleteDynamicTagRulesetFromTagMutationFn = Apollo.MutationFunction<
  DeleteDynamicTagRulesetFromTagMutation,
  DeleteDynamicTagRulesetFromTagMutationVariables
>;

/**
 * __useDeleteDynamicTagRulesetFromTagMutation__
 *
 * To run a mutation, you first call `useDeleteDynamicTagRulesetFromTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDynamicTagRulesetFromTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDynamicTagRulesetFromTagMutation, { data, loading, error }] = useDeleteDynamicTagRulesetFromTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useDeleteDynamicTagRulesetFromTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDynamicTagRulesetFromTagMutation,
    DeleteDynamicTagRulesetFromTagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDynamicTagRulesetFromTagMutation,
    DeleteDynamicTagRulesetFromTagMutationVariables
  >(DeleteDynamicTagRulesetFromTagDocument, options);
}
export type DeleteDynamicTagRulesetFromTagMutationHookResult = ReturnType<
  typeof useDeleteDynamicTagRulesetFromTagMutation
>;
export type DeleteDynamicTagRulesetFromTagMutationResult =
  Apollo.MutationResult<DeleteDynamicTagRulesetFromTagMutation>;
export type DeleteDynamicTagRulesetFromTagMutationOptions = Apollo.BaseMutationOptions<
  DeleteDynamicTagRulesetFromTagMutation,
  DeleteDynamicTagRulesetFromTagMutationVariables
>;
export const DeleteStaticTagDocument = gql`
  mutation DeleteStaticTag($tag: String!, $domainId: Int!) {
    deleteStaticTag(tag: $tag, domainId: $domainId) {
      errors {
        messages
      }
    }
  }
`;
export type DeleteStaticTagMutationFn = Apollo.MutationFunction<
  DeleteStaticTagMutation,
  DeleteStaticTagMutationVariables
>;

/**
 * __useDeleteStaticTagMutation__
 *
 * To run a mutation, you first call `useDeleteStaticTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStaticTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStaticTagMutation, { data, loading, error }] = useDeleteStaticTagMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useDeleteStaticTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteStaticTagMutation,
    DeleteStaticTagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteStaticTagMutation, DeleteStaticTagMutationVariables>(
    DeleteStaticTagDocument,
    options,
  );
}
export type DeleteStaticTagMutationHookResult = ReturnType<typeof useDeleteStaticTagMutation>;
export type DeleteStaticTagMutationResult = Apollo.MutationResult<DeleteStaticTagMutation>;
export type DeleteStaticTagMutationOptions = Apollo.BaseMutationOptions<
  DeleteStaticTagMutation,
  DeleteStaticTagMutationVariables
>;
export const CompetitorBubbleChartDocument = gql`
  query CompetitorBubbleChart($filters: [FilterInput]!, $name: String!) {
    charts(filters: $filters, name: $name) {
      competitorStats {
        keywords
        searchVolume
        shareOfVoice
        domain
        displayName
        type
      }
    }
  }
`;

/**
 * __useCompetitorBubbleChartQuery__
 *
 * To run a query within a React component, call `useCompetitorBubbleChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetitorBubbleChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetitorBubbleChartQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCompetitorBubbleChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompetitorBubbleChartQuery,
    CompetitorBubbleChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompetitorBubbleChartQuery, CompetitorBubbleChartQueryVariables>(
    CompetitorBubbleChartDocument,
    options,
  );
}
export function useCompetitorBubbleChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompetitorBubbleChartQuery,
    CompetitorBubbleChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompetitorBubbleChartQuery, CompetitorBubbleChartQueryVariables>(
    CompetitorBubbleChartDocument,
    options,
  );
}
export type CompetitorBubbleChartQueryHookResult = ReturnType<typeof useCompetitorBubbleChartQuery>;
export type CompetitorBubbleChartLazyQueryHookResult = ReturnType<
  typeof useCompetitorBubbleChartLazyQuery
>;
export type CompetitorBubbleChartQueryResult = Apollo.QueryResult<
  CompetitorBubbleChartQuery,
  CompetitorBubbleChartQueryVariables
>;
export const GscAndGaHistoryDocument = gql`
  query gscAndGaHistory($filters: [FilterInput]!, $name: String!) {
    graphs(filters: $filters, name: $name) {
      gscAndGaHistory
    }
  }
`;

/**
 * __useGscAndGaHistoryQuery__
 *
 * To run a query within a React component, call `useGscAndGaHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGscAndGaHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGscAndGaHistoryQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGscAndGaHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<GscAndGaHistoryQuery, GscAndGaHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GscAndGaHistoryQuery, GscAndGaHistoryQueryVariables>(
    GscAndGaHistoryDocument,
    options,
  );
}
export function useGscAndGaHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GscAndGaHistoryQuery, GscAndGaHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GscAndGaHistoryQuery, GscAndGaHistoryQueryVariables>(
    GscAndGaHistoryDocument,
    options,
  );
}
export type GscAndGaHistoryQueryHookResult = ReturnType<typeof useGscAndGaHistoryQuery>;
export type GscAndGaHistoryLazyQueryHookResult = ReturnType<typeof useGscAndGaHistoryLazyQuery>;
export type GscAndGaHistoryQueryResult = Apollo.QueryResult<
  GscAndGaHistoryQuery,
  GscAndGaHistoryQueryVariables
>;
export const HistoryChartsDocument = gql`
  query HistoryCharts($filters: [FilterInput]!, $name: String!, $charts: [String]!) {
    graphs(filters: $filters, name: $name) {
      overviewHistoryGraphs(allHistory: true, charts: $charts)
    }
  }
`;

/**
 * __useHistoryChartsQuery__
 *
 * To run a query within a React component, call `useHistoryChartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryChartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryChartsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      name: // value for 'name'
 *      charts: // value for 'charts'
 *   },
 * });
 */
export function useHistoryChartsQuery(
  baseOptions: Apollo.QueryHookOptions<HistoryChartsQuery, HistoryChartsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HistoryChartsQuery, HistoryChartsQueryVariables>(
    HistoryChartsDocument,
    options,
  );
}
export function useHistoryChartsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HistoryChartsQuery, HistoryChartsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HistoryChartsQuery, HistoryChartsQueryVariables>(
    HistoryChartsDocument,
    options,
  );
}
export type HistoryChartsQueryHookResult = ReturnType<typeof useHistoryChartsQuery>;
export type HistoryChartsLazyQueryHookResult = ReturnType<typeof useHistoryChartsLazyQuery>;
export type HistoryChartsQueryResult = Apollo.QueryResult<
  HistoryChartsQuery,
  HistoryChartsQueryVariables
>;
export const KpiBarDocument = gql`
  query KpiBar($filters: [FilterInput]!, $name: String!, $displayCurrency: String!) {
    kpis(filters: $filters, name: $name, displayCurrency: $displayCurrency) {
      allKpis {
        keywords
        keywordsBefore
        trafficValue
        trafficValueBefore
        maxTrafficValue
        shareOfVoiceSum
        shareOfVoiceSumBefore
        maxShareOfVoiceSum
        aiShareOfVoiceSum
        aiShareOfVoiceSumBefore
        maxAiShareOfVoiceSum
        avgRank
        avgRankBefore
        aboveTheFold
        aboveTheFoldBefore
        ctr
        ctrBefore
        maxCtr
        aiTrafficValue
        aiTrafficValueBefore
        maxAiTrafficValue
        avgBaseRank
        avgBaseRankBefore
        avgMaxBaseRank
        pixelsFromTop
        pixelsFromTopBefore
        searchVolume
        searchVolumeBefore
        aiSearchVolume
        aiSearchVolumeBefore
      }
    }
  }
`;

/**
 * __useKpiBarQuery__
 *
 * To run a query within a React component, call `useKpiBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiBarQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      name: // value for 'name'
 *      displayCurrency: // value for 'displayCurrency'
 *   },
 * });
 */
export function useKpiBarQuery(
  baseOptions: Apollo.QueryHookOptions<KpiBarQuery, KpiBarQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KpiBarQuery, KpiBarQueryVariables>(KpiBarDocument, options);
}
export function useKpiBarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<KpiBarQuery, KpiBarQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KpiBarQuery, KpiBarQueryVariables>(KpiBarDocument, options);
}
export type KpiBarQueryHookResult = ReturnType<typeof useKpiBarQuery>;
export type KpiBarLazyQueryHookResult = ReturnType<typeof useKpiBarLazyQuery>;
export type KpiBarQueryResult = Apollo.QueryResult<KpiBarQuery, KpiBarQueryVariables>;
export const DiscoveredKeywordsDocument = gql`
  query discoveredKeywords($filters: [FilterInput]!) {
    keydisKpis(filters: $filters) {
      discoveredKeywords
    }
  }
`;

/**
 * __useDiscoveredKeywordsQuery__
 *
 * To run a query within a React component, call `useDiscoveredKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscoveredKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscoveredKeywordsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDiscoveredKeywordsQuery(
  baseOptions: Apollo.QueryHookOptions<DiscoveredKeywordsQuery, DiscoveredKeywordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DiscoveredKeywordsQuery, DiscoveredKeywordsQueryVariables>(
    DiscoveredKeywordsDocument,
    options,
  );
}
export function useDiscoveredKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DiscoveredKeywordsQuery,
    DiscoveredKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DiscoveredKeywordsQuery, DiscoveredKeywordsQueryVariables>(
    DiscoveredKeywordsDocument,
    options,
  );
}
export type DiscoveredKeywordsQueryHookResult = ReturnType<typeof useDiscoveredKeywordsQuery>;
export type DiscoveredKeywordsLazyQueryHookResult = ReturnType<
  typeof useDiscoveredKeywordsLazyQuery
>;
export type DiscoveredKeywordsQueryResult = Apollo.QueryResult<
  DiscoveredKeywordsQuery,
  DiscoveredKeywordsQueryVariables
>;
export const OverviewNotificationsDocument = gql`
  query OverviewNotifications(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
  ) {
    notifications(filters: $filters, pagination: $pagination, ordering: $ordering) {
      notifications
      pagination {
        numResults
        startIndex
        stopIndex
      }
    }
  }
`;

/**
 * __useOverviewNotificationsQuery__
 *
 * To run a query within a React component, call `useOverviewNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewNotificationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useOverviewNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OverviewNotificationsQuery,
    OverviewNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OverviewNotificationsQuery, OverviewNotificationsQueryVariables>(
    OverviewNotificationsDocument,
    options,
  );
}
export function useOverviewNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OverviewNotificationsQuery,
    OverviewNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OverviewNotificationsQuery, OverviewNotificationsQueryVariables>(
    OverviewNotificationsDocument,
    options,
  );
}
export type OverviewNotificationsQueryHookResult = ReturnType<typeof useOverviewNotificationsQuery>;
export type OverviewNotificationsLazyQueryHookResult = ReturnType<
  typeof useOverviewNotificationsLazyQuery
>;
export type OverviewNotificationsQueryResult = Apollo.QueryResult<
  OverviewNotificationsQuery,
  OverviewNotificationsQueryVariables
>;
export const SearchIntentOverviewDocument = gql`
  query SearchIntentOverview($filters: [FilterInput]!, $name: String) {
    graphs(filters: $filters, name: $name) {
      overviewSearchIntent {
        I
        N
        T
        C
        keywordCount
      }
    }
  }
`;

/**
 * __useSearchIntentOverviewQuery__
 *
 * To run a query within a React component, call `useSearchIntentOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchIntentOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchIntentOverviewQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSearchIntentOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchIntentOverviewQuery,
    SearchIntentOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchIntentOverviewQuery, SearchIntentOverviewQueryVariables>(
    SearchIntentOverviewDocument,
    options,
  );
}
export function useSearchIntentOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchIntentOverviewQuery,
    SearchIntentOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchIntentOverviewQuery, SearchIntentOverviewQueryVariables>(
    SearchIntentOverviewDocument,
    options,
  );
}
export type SearchIntentOverviewQueryHookResult = ReturnType<typeof useSearchIntentOverviewQuery>;
export type SearchIntentOverviewLazyQueryHookResult = ReturnType<
  typeof useSearchIntentOverviewLazyQuery
>;
export type SearchIntentOverviewQueryResult = Apollo.QueryResult<
  SearchIntentOverviewQuery,
  SearchIntentOverviewQueryVariables
>;
export const SearchTrendDocument = gql`
  query SearchTrend($filters: [FilterInput]!, $name: String!) {
    graphs(filters: $filters, name: $name) {
      overviewSearchTrend {
        month
        searchVolumeTotal
      }
    }
  }
`;

/**
 * __useSearchTrendQuery__
 *
 * To run a query within a React component, call `useSearchTrendQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTrendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTrendQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSearchTrendQuery(
  baseOptions: Apollo.QueryHookOptions<SearchTrendQuery, SearchTrendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchTrendQuery, SearchTrendQueryVariables>(SearchTrendDocument, options);
}
export function useSearchTrendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchTrendQuery, SearchTrendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchTrendQuery, SearchTrendQueryVariables>(
    SearchTrendDocument,
    options,
  );
}
export type SearchTrendQueryHookResult = ReturnType<typeof useSearchTrendQuery>;
export type SearchTrendLazyQueryHookResult = ReturnType<typeof useSearchTrendLazyQuery>;
export type SearchTrendQueryResult = Apollo.QueryResult<
  SearchTrendQuery,
  SearchTrendQueryVariables
>;
export const ShareOfVoiceCompetitorsDocument = gql`
  query ShareOfVoiceCompetitors(
    $filters: [FilterInput]!
    $asPercentage: Boolean!
    $name: String!
    $useAi: Boolean!
  ) {
    charts(filters: $filters, name: $name) {
      shareOfVoiceCompetitors(asPercentage: $asPercentage, useAi: $useAi) {
        domain
        shareOfVoice
        type
        faviconUrl
        displayName
      }
    }
  }
`;

/**
 * __useShareOfVoiceCompetitorsQuery__
 *
 * To run a query within a React component, call `useShareOfVoiceCompetitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareOfVoiceCompetitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareOfVoiceCompetitorsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      asPercentage: // value for 'asPercentage'
 *      name: // value for 'name'
 *      useAi: // value for 'useAi'
 *   },
 * });
 */
export function useShareOfVoiceCompetitorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShareOfVoiceCompetitorsQuery,
    ShareOfVoiceCompetitorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShareOfVoiceCompetitorsQuery, ShareOfVoiceCompetitorsQueryVariables>(
    ShareOfVoiceCompetitorsDocument,
    options,
  );
}
export function useShareOfVoiceCompetitorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShareOfVoiceCompetitorsQuery,
    ShareOfVoiceCompetitorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShareOfVoiceCompetitorsQuery, ShareOfVoiceCompetitorsQueryVariables>(
    ShareOfVoiceCompetitorsDocument,
    options,
  );
}
export type ShareOfVoiceCompetitorsQueryHookResult = ReturnType<
  typeof useShareOfVoiceCompetitorsQuery
>;
export type ShareOfVoiceCompetitorsLazyQueryHookResult = ReturnType<
  typeof useShareOfVoiceCompetitorsLazyQuery
>;
export type ShareOfVoiceCompetitorsQueryResult = Apollo.QueryResult<
  ShareOfVoiceCompetitorsQuery,
  ShareOfVoiceCompetitorsQueryVariables
>;
export const ShareOfVoiceTagsDocument = gql`
  query ShareOfVoiceTags(
    $filters: [FilterInput]!
    $asPercentage: Boolean!
    $useAi: Boolean!
    $name: String!
  ) {
    charts(filters: $filters, name: $name) {
      shareOfVoiceByTags(asPercentage: $asPercentage, useAi: $useAi) {
        total
        rows {
          key
          value
          isDynamic
          isStatic
          otherTags
        }
      }
    }
  }
`;

/**
 * __useShareOfVoiceTagsQuery__
 *
 * To run a query within a React component, call `useShareOfVoiceTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareOfVoiceTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareOfVoiceTagsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      asPercentage: // value for 'asPercentage'
 *      useAi: // value for 'useAi'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useShareOfVoiceTagsQuery(
  baseOptions: Apollo.QueryHookOptions<ShareOfVoiceTagsQuery, ShareOfVoiceTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShareOfVoiceTagsQuery, ShareOfVoiceTagsQueryVariables>(
    ShareOfVoiceTagsDocument,
    options,
  );
}
export function useShareOfVoiceTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShareOfVoiceTagsQuery, ShareOfVoiceTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShareOfVoiceTagsQuery, ShareOfVoiceTagsQueryVariables>(
    ShareOfVoiceTagsDocument,
    options,
  );
}
export type ShareOfVoiceTagsQueryHookResult = ReturnType<typeof useShareOfVoiceTagsQuery>;
export type ShareOfVoiceTagsLazyQueryHookResult = ReturnType<typeof useShareOfVoiceTagsLazyQuery>;
export type ShareOfVoiceTagsQueryResult = Apollo.QueryResult<
  ShareOfVoiceTagsQuery,
  ShareOfVoiceTagsQueryVariables
>;
export const ShareOfVoiceLandingPagesDocument = gql`
  query ShareOfVoiceLandingPages(
    $filters: [FilterInput]!
    $asPercentage: Boolean!
    $name: String!
    $useAi: Boolean!
  ) {
    charts(filters: $filters, name: $name) {
      shareOfVoiceLandingPages(asPercentage: $asPercentage, useAi: $useAi) {
        total
        rows {
          key
          value
        }
      }
    }
  }
`;

/**
 * __useShareOfVoiceLandingPagesQuery__
 *
 * To run a query within a React component, call `useShareOfVoiceLandingPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShareOfVoiceLandingPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShareOfVoiceLandingPagesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      asPercentage: // value for 'asPercentage'
 *      name: // value for 'name'
 *      useAi: // value for 'useAi'
 *   },
 * });
 */
export function useShareOfVoiceLandingPagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShareOfVoiceLandingPagesQuery,
    ShareOfVoiceLandingPagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShareOfVoiceLandingPagesQuery, ShareOfVoiceLandingPagesQueryVariables>(
    ShareOfVoiceLandingPagesDocument,
    options,
  );
}
export function useShareOfVoiceLandingPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShareOfVoiceLandingPagesQuery,
    ShareOfVoiceLandingPagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShareOfVoiceLandingPagesQuery, ShareOfVoiceLandingPagesQueryVariables>(
    ShareOfVoiceLandingPagesDocument,
    options,
  );
}
export type ShareOfVoiceLandingPagesQueryHookResult = ReturnType<
  typeof useShareOfVoiceLandingPagesQuery
>;
export type ShareOfVoiceLandingPagesLazyQueryHookResult = ReturnType<
  typeof useShareOfVoiceLandingPagesLazyQuery
>;
export type ShareOfVoiceLandingPagesQueryResult = Apollo.QueryResult<
  ShareOfVoiceLandingPagesQuery,
  ShareOfVoiceLandingPagesQueryVariables
>;
export const UnknownCompetitorsBarChartDocument = gql`
  query unknownCompetitorsBarChart($filters: [FilterInput]!, $name: String!) {
    charts(filters: $filters, name: $name) {
      unknownCompetitors {
        domain
        ownDomain
        competitor
        amount
      }
    }
  }
`;

/**
 * __useUnknownCompetitorsBarChartQuery__
 *
 * To run a query within a React component, call `useUnknownCompetitorsBarChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnknownCompetitorsBarChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnknownCompetitorsBarChartQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUnknownCompetitorsBarChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnknownCompetitorsBarChartQuery,
    UnknownCompetitorsBarChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnknownCompetitorsBarChartQuery, UnknownCompetitorsBarChartQueryVariables>(
    UnknownCompetitorsBarChartDocument,
    options,
  );
}
export function useUnknownCompetitorsBarChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnknownCompetitorsBarChartQuery,
    UnknownCompetitorsBarChartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UnknownCompetitorsBarChartQuery,
    UnknownCompetitorsBarChartQueryVariables
  >(UnknownCompetitorsBarChartDocument, options);
}
export type UnknownCompetitorsBarChartQueryHookResult = ReturnType<
  typeof useUnknownCompetitorsBarChartQuery
>;
export type UnknownCompetitorsBarChartLazyQueryHookResult = ReturnType<
  typeof useUnknownCompetitorsBarChartLazyQuery
>;
export type UnknownCompetitorsBarChartQueryResult = Apollo.QueryResult<
  UnknownCompetitorsBarChartQuery,
  UnknownCompetitorsBarChartQueryVariables
>;
export const UnknownCompetitorRankDistributionDocument = gql`
  query unknownCompetitorRankDistribution(
    $filters: [FilterInput]!
    $competitors: [String]!
    $name: String!
  ) {
    graphs(filters: $filters, name: $name) {
      unknownCompetitorRankDistribution(competitors: $competitors) {
        domain
        ownDomain
        competitor
        rank1
        rank2
        rank3
        rank4
        rank5
        rank6
        rank7
        rank8
        rank9
        rank10
      }
    }
  }
`;

/**
 * __useUnknownCompetitorRankDistributionQuery__
 *
 * To run a query within a React component, call `useUnknownCompetitorRankDistributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnknownCompetitorRankDistributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnknownCompetitorRankDistributionQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      competitors: // value for 'competitors'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUnknownCompetitorRankDistributionQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnknownCompetitorRankDistributionQuery,
    UnknownCompetitorRankDistributionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UnknownCompetitorRankDistributionQuery,
    UnknownCompetitorRankDistributionQueryVariables
  >(UnknownCompetitorRankDistributionDocument, options);
}
export function useUnknownCompetitorRankDistributionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnknownCompetitorRankDistributionQuery,
    UnknownCompetitorRankDistributionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UnknownCompetitorRankDistributionQuery,
    UnknownCompetitorRankDistributionQueryVariables
  >(UnknownCompetitorRankDistributionDocument, options);
}
export type UnknownCompetitorRankDistributionQueryHookResult = ReturnType<
  typeof useUnknownCompetitorRankDistributionQuery
>;
export type UnknownCompetitorRankDistributionLazyQueryHookResult = ReturnType<
  typeof useUnknownCompetitorRankDistributionLazyQuery
>;
export type UnknownCompetitorRankDistributionQueryResult = Apollo.QueryResult<
  UnknownCompetitorRankDistributionQuery,
  UnknownCompetitorRankDistributionQueryVariables
>;
export const UnknownCompetitorRankDistributionOverTimeDocument = gql`
  query unknownCompetitorRankDistributionOverTime(
    $filters: [FilterInput]!
    $competitors: [String]!
    $name: String!
  ) {
    graphs(filters: $filters, name: $name) {
      unknownCompetitorRankDistributionNew(competitors: $competitors)
    }
  }
`;

/**
 * __useUnknownCompetitorRankDistributionOverTimeQuery__
 *
 * To run a query within a React component, call `useUnknownCompetitorRankDistributionOverTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnknownCompetitorRankDistributionOverTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnknownCompetitorRankDistributionOverTimeQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      competitors: // value for 'competitors'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUnknownCompetitorRankDistributionOverTimeQuery(
  baseOptions: Apollo.QueryHookOptions<
    UnknownCompetitorRankDistributionOverTimeQuery,
    UnknownCompetitorRankDistributionOverTimeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UnknownCompetitorRankDistributionOverTimeQuery,
    UnknownCompetitorRankDistributionOverTimeQueryVariables
  >(UnknownCompetitorRankDistributionOverTimeDocument, options);
}
export function useUnknownCompetitorRankDistributionOverTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnknownCompetitorRankDistributionOverTimeQuery,
    UnknownCompetitorRankDistributionOverTimeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UnknownCompetitorRankDistributionOverTimeQuery,
    UnknownCompetitorRankDistributionOverTimeQueryVariables
  >(UnknownCompetitorRankDistributionOverTimeDocument, options);
}
export type UnknownCompetitorRankDistributionOverTimeQueryHookResult = ReturnType<
  typeof useUnknownCompetitorRankDistributionOverTimeQuery
>;
export type UnknownCompetitorRankDistributionOverTimeLazyQueryHookResult = ReturnType<
  typeof useUnknownCompetitorRankDistributionOverTimeLazyQuery
>;
export type UnknownCompetitorRankDistributionOverTimeQueryResult = Apollo.QueryResult<
  UnknownCompetitorRankDistributionOverTimeQuery,
  UnknownCompetitorRankDistributionOverTimeQueryVariables
>;
export const WinnerAndLosersDocument = gql`
  query WinnerAndLosers($filters: [FilterInput]!, $name: String!, $useAi: Boolean!) {
    charts(filters: $filters, name: $name) {
      winnersAndLosers(useAi: $useAi) {
        rankWinners
        rankLosers
        rankNoChange
        winnersSovGain
        losersSovLoss
        periodFrom
        periodTo
      }
    }
  }
`;

/**
 * __useWinnerAndLosersQuery__
 *
 * To run a query within a React component, call `useWinnerAndLosersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinnerAndLosersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinnerAndLosersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      name: // value for 'name'
 *      useAi: // value for 'useAi'
 *   },
 * });
 */
export function useWinnerAndLosersQuery(
  baseOptions: Apollo.QueryHookOptions<WinnerAndLosersQuery, WinnerAndLosersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WinnerAndLosersQuery, WinnerAndLosersQueryVariables>(
    WinnerAndLosersDocument,
    options,
  );
}
export function useWinnerAndLosersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WinnerAndLosersQuery, WinnerAndLosersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WinnerAndLosersQuery, WinnerAndLosersQueryVariables>(
    WinnerAndLosersDocument,
    options,
  );
}
export type WinnerAndLosersQueryHookResult = ReturnType<typeof useWinnerAndLosersQuery>;
export type WinnerAndLosersLazyQueryHookResult = ReturnType<typeof useWinnerAndLosersLazyQuery>;
export type WinnerAndLosersQueryResult = Apollo.QueryResult<
  WinnerAndLosersQuery,
  WinnerAndLosersQueryVariables
>;
export const WinnerAndLosersTableDocument = gql`
  query WinnerAndLosersTable(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
    $withId: Boolean!
    $withKeyword: Boolean!
    $withStarred: Boolean!
    $withTags: Boolean!
    $withDynamicTags: Boolean!
    $withDynamicCtr: Boolean!
    $withOrganicTraffic: Boolean!
    $withOrganicTrafficCompare: Boolean!
    $withSearchIntent: Boolean!
    $withIgnoreInShareOfVoice: Boolean!
    $withIgnoreLocalResults: Boolean!
    $withIgnoreFeaturedSnippet: Boolean!
    $withEnableAutocorrect: Boolean!
    $withIgnoredDomains: Boolean!
    $withSearchTypeId: Boolean!
    $withUpdatingKeyword: Boolean!
    $withSearchEngineId: Boolean!
    $withDomain: Boolean!
    $withDomainId: Boolean!
    $withDisplayName: Boolean!
    $withKeywordDescription: Boolean!
    $withLocation: Boolean!
    $withCountrylocaleId: Boolean!
    $withRankId: Boolean!
    $withRankValue: Boolean!
    $withRankCompare: Boolean!
    $withHasExtraRanks: Boolean!
    $withExtraRanks: Boolean!
    $withAboveTheFold: Boolean!
    $withTitle: Boolean!
    $withDescription: Boolean!
    $withPreferredLandingPageId: Boolean!
    $withLandingPageId: Boolean!
    $withHighestRankingPage: Boolean!
    $withSearchVolumeValue: Boolean!
    $withValidForSearchVolume: Boolean!
    $withAvgCostPerClick: Boolean!
    $withCompetition: Boolean!
    $withAnalyticsPotential: Boolean!
    $withAnalyticsVisitors: Boolean!
    $withShareOfVoiceIsPercentage: Boolean!
    $withShareOfVoiceValue: Boolean!
    $withShareOfVoiceCompare: Boolean!
    $withShareOfVoicePercentageValue: Boolean!
    $withShareOfVoicePercentageValueCompare: Boolean!
    $withShareOfVoiceCpcValue: Boolean!
    $withShareOfVoiceCpcCompare: Boolean!
    $withSerp: Boolean!
    $withDateAdded: Boolean!
    $withSearchDate: Boolean!
    $withHistoryUrl: Boolean!
    $allowPartialScrape: Boolean!
  ) {
    tableKeywords(
      filters: $filters
      pagination: $pagination
      ordering: $ordering
      withId: $withId
      withKeyword: $withKeyword
      withStarred: $withStarred
      withTags: $withTags
      withDynamicTags: $withDynamicTags
      withDynamicCtr: $withDynamicCtr
      withOrganicTraffic: $withOrganicTraffic
      withOrganicTrafficCompare: $withOrganicTrafficCompare
      withSearchIntent: $withSearchIntent
      withIgnoreInShareOfVoice: $withIgnoreInShareOfVoice
      withIgnoreLocalResults: $withIgnoreLocalResults
      withIgnoreFeaturedSnippet: $withIgnoreFeaturedSnippet
      withEnableAutocorrect: $withEnableAutocorrect
      withIgnoredDomains: $withIgnoredDomains
      withSearchTypeId: $withSearchTypeId
      withUpdatingKeyword: $withUpdatingKeyword
      withSearchEngineId: $withSearchEngineId
      withDomain: $withDomain
      withDomainId: $withDomainId
      withDisplayName: $withDisplayName
      withKeywordDescription: $withKeywordDescription
      withLocation: $withLocation
      withCountrylocaleId: $withCountrylocaleId
      withRankId: $withRankId
      withRankValue: $withRankValue
      withRankCompare: $withRankCompare
      withHasExtraRanks: $withHasExtraRanks
      withExtraRanks: $withExtraRanks
      withAboveTheFold: $withAboveTheFold
      withTitle: $withTitle
      withDescription: $withDescription
      withPreferredLandingPageId: $withPreferredLandingPageId
      withLandingPageId: $withLandingPageId
      withHighestRankingPage: $withHighestRankingPage
      withSearchVolumeValue: $withSearchVolumeValue
      withValidForSearchVolume: $withValidForSearchVolume
      withAvgCostPerClick: $withAvgCostPerClick
      withCompetition: $withCompetition
      withAnalyticsPotential: $withAnalyticsPotential
      withAnalyticsVisitors: $withAnalyticsVisitors
      withShareOfVoiceIsPercentage: $withShareOfVoiceIsPercentage
      withShareOfVoiceValue: $withShareOfVoiceValue
      withShareOfVoiceCompare: $withShareOfVoiceCompare
      withShareOfVoicePercentageValue: $withShareOfVoicePercentageValue
      withShareOfVoicePercentageValueCompare: $withShareOfVoicePercentageValueCompare
      withShareOfVoiceCpcValue: $withShareOfVoiceCpcValue
      withShareOfVoiceCpcCompare: $withShareOfVoiceCpcCompare
      withSerp: $withSerp
      withDateAdded: $withDateAdded
      withSearchDate: $withSearchDate
      withHistoryUrl: $withHistoryUrl
      allowPartialScrape: $allowPartialScrape
    ) {
      keywords
      pagination {
        numResults
        startIndex
        stopIndex
      }
    }
  }
`;

/**
 * __useWinnerAndLosersTableQuery__
 *
 * To run a query within a React component, call `useWinnerAndLosersTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useWinnerAndLosersTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWinnerAndLosersTableQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      withId: // value for 'withId'
 *      withKeyword: // value for 'withKeyword'
 *      withStarred: // value for 'withStarred'
 *      withTags: // value for 'withTags'
 *      withDynamicTags: // value for 'withDynamicTags'
 *      withDynamicCtr: // value for 'withDynamicCtr'
 *      withOrganicTraffic: // value for 'withOrganicTraffic'
 *      withOrganicTrafficCompare: // value for 'withOrganicTrafficCompare'
 *      withSearchIntent: // value for 'withSearchIntent'
 *      withIgnoreInShareOfVoice: // value for 'withIgnoreInShareOfVoice'
 *      withIgnoreLocalResults: // value for 'withIgnoreLocalResults'
 *      withIgnoreFeaturedSnippet: // value for 'withIgnoreFeaturedSnippet'
 *      withEnableAutocorrect: // value for 'withEnableAutocorrect'
 *      withIgnoredDomains: // value for 'withIgnoredDomains'
 *      withSearchTypeId: // value for 'withSearchTypeId'
 *      withUpdatingKeyword: // value for 'withUpdatingKeyword'
 *      withSearchEngineId: // value for 'withSearchEngineId'
 *      withDomain: // value for 'withDomain'
 *      withDomainId: // value for 'withDomainId'
 *      withDisplayName: // value for 'withDisplayName'
 *      withKeywordDescription: // value for 'withKeywordDescription'
 *      withLocation: // value for 'withLocation'
 *      withCountrylocaleId: // value for 'withCountrylocaleId'
 *      withRankId: // value for 'withRankId'
 *      withRankValue: // value for 'withRankValue'
 *      withRankCompare: // value for 'withRankCompare'
 *      withHasExtraRanks: // value for 'withHasExtraRanks'
 *      withExtraRanks: // value for 'withExtraRanks'
 *      withAboveTheFold: // value for 'withAboveTheFold'
 *      withTitle: // value for 'withTitle'
 *      withDescription: // value for 'withDescription'
 *      withPreferredLandingPageId: // value for 'withPreferredLandingPageId'
 *      withLandingPageId: // value for 'withLandingPageId'
 *      withHighestRankingPage: // value for 'withHighestRankingPage'
 *      withSearchVolumeValue: // value for 'withSearchVolumeValue'
 *      withValidForSearchVolume: // value for 'withValidForSearchVolume'
 *      withAvgCostPerClick: // value for 'withAvgCostPerClick'
 *      withCompetition: // value for 'withCompetition'
 *      withAnalyticsPotential: // value for 'withAnalyticsPotential'
 *      withAnalyticsVisitors: // value for 'withAnalyticsVisitors'
 *      withShareOfVoiceIsPercentage: // value for 'withShareOfVoiceIsPercentage'
 *      withShareOfVoiceValue: // value for 'withShareOfVoiceValue'
 *      withShareOfVoiceCompare: // value for 'withShareOfVoiceCompare'
 *      withShareOfVoicePercentageValue: // value for 'withShareOfVoicePercentageValue'
 *      withShareOfVoicePercentageValueCompare: // value for 'withShareOfVoicePercentageValueCompare'
 *      withShareOfVoiceCpcValue: // value for 'withShareOfVoiceCpcValue'
 *      withShareOfVoiceCpcCompare: // value for 'withShareOfVoiceCpcCompare'
 *      withSerp: // value for 'withSerp'
 *      withDateAdded: // value for 'withDateAdded'
 *      withSearchDate: // value for 'withSearchDate'
 *      withHistoryUrl: // value for 'withHistoryUrl'
 *      allowPartialScrape: // value for 'allowPartialScrape'
 *   },
 * });
 */
export function useWinnerAndLosersTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    WinnerAndLosersTableQuery,
    WinnerAndLosersTableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WinnerAndLosersTableQuery, WinnerAndLosersTableQueryVariables>(
    WinnerAndLosersTableDocument,
    options,
  );
}
export function useWinnerAndLosersTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WinnerAndLosersTableQuery,
    WinnerAndLosersTableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WinnerAndLosersTableQuery, WinnerAndLosersTableQueryVariables>(
    WinnerAndLosersTableDocument,
    options,
  );
}
export type WinnerAndLosersTableQueryHookResult = ReturnType<typeof useWinnerAndLosersTableQuery>;
export type WinnerAndLosersTableLazyQueryHookResult = ReturnType<
  typeof useWinnerAndLosersTableLazyQuery
>;
export type WinnerAndLosersTableQueryResult = Apollo.QueryResult<
  WinnerAndLosersTableQuery,
  WinnerAndLosersTableQueryVariables
>;
export const UpdateOverviewChartsDocument = gql`
  mutation UpdateOverviewCharts($input: UpdateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      user {
        id
        defaultOverviewCharts
      }
    }
  }
`;
export type UpdateOverviewChartsMutationFn = Apollo.MutationFunction<
  UpdateOverviewChartsMutation,
  UpdateOverviewChartsMutationVariables
>;

/**
 * __useUpdateOverviewChartsMutation__
 *
 * To run a mutation, you first call `useUpdateOverviewChartsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOverviewChartsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOverviewChartsMutation, { data, loading, error }] = useUpdateOverviewChartsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOverviewChartsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOverviewChartsMutation,
    UpdateOverviewChartsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOverviewChartsMutation, UpdateOverviewChartsMutationVariables>(
    UpdateOverviewChartsDocument,
    options,
  );
}
export type UpdateOverviewChartsMutationHookResult = ReturnType<
  typeof useUpdateOverviewChartsMutation
>;
export type UpdateOverviewChartsMutationResult =
  Apollo.MutationResult<UpdateOverviewChartsMutation>;
export type UpdateOverviewChartsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOverviewChartsMutation,
  UpdateOverviewChartsMutationVariables
>;
export const SerpsDataDocument = gql`
  query SerpsData($filters: [FilterInput]!) {
    charts(filters: $filters) {
      ...Charts
    }
  }
  ${ChartsFragmentDoc}
`;

/**
 * __useSerpsDataQuery__
 *
 * To run a query within a React component, call `useSerpsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSerpsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSerpsDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSerpsDataQuery(
  baseOptions: Apollo.QueryHookOptions<SerpsDataQuery, SerpsDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SerpsDataQuery, SerpsDataQueryVariables>(SerpsDataDocument, options);
}
export function useSerpsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SerpsDataQuery, SerpsDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SerpsDataQuery, SerpsDataQueryVariables>(SerpsDataDocument, options);
}
export type SerpsDataQueryHookResult = ReturnType<typeof useSerpsDataQuery>;
export type SerpsDataLazyQueryHookResult = ReturnType<typeof useSerpsDataLazyQuery>;
export type SerpsDataQueryResult = Apollo.QueryResult<SerpsDataQuery, SerpsDataQueryVariables>;
export const KeywordsSerpHistoryDocument = gql`
  query KeywordsSerpHistory($filters: [FilterInput]!, $serp: String!) {
    graphs(filters: $filters) {
      serpHistory(serp: $serp)
    }
  }
`;

/**
 * __useKeywordsSerpHistoryQuery__
 *
 * To run a query within a React component, call `useKeywordsSerpHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordsSerpHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordsSerpHistoryQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      serp: // value for 'serp'
 *   },
 * });
 */
export function useKeywordsSerpHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<KeywordsSerpHistoryQuery, KeywordsSerpHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeywordsSerpHistoryQuery, KeywordsSerpHistoryQueryVariables>(
    KeywordsSerpHistoryDocument,
    options,
  );
}
export function useKeywordsSerpHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KeywordsSerpHistoryQuery,
    KeywordsSerpHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeywordsSerpHistoryQuery, KeywordsSerpHistoryQueryVariables>(
    KeywordsSerpHistoryDocument,
    options,
  );
}
export type KeywordsSerpHistoryQueryHookResult = ReturnType<typeof useKeywordsSerpHistoryQuery>;
export type KeywordsSerpHistoryLazyQueryHookResult = ReturnType<
  typeof useKeywordsSerpHistoryLazyQuery
>;
export type KeywordsSerpHistoryQueryResult = Apollo.QueryResult<
  KeywordsSerpHistoryQuery,
  KeywordsSerpHistoryQueryVariables
>;
export const KeywordsSearchVolumeDocument = gql`
  query keywordsSearchVolume(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
  ) {
    keywords(filters: $filters, pagination: $pagination, ordering: $ordering) {
      keywords {
        id
        keyword
        searchVolume {
          id
          history {
            month
            volume
          }
        }
      }
    }
  }
`;

/**
 * __useKeywordsSearchVolumeQuery__
 *
 * To run a query within a React component, call `useKeywordsSearchVolumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordsSearchVolumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordsSearchVolumeQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useKeywordsSearchVolumeQuery(
  baseOptions: Apollo.QueryHookOptions<
    KeywordsSearchVolumeQuery,
    KeywordsSearchVolumeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeywordsSearchVolumeQuery, KeywordsSearchVolumeQueryVariables>(
    KeywordsSearchVolumeDocument,
    options,
  );
}
export function useKeywordsSearchVolumeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KeywordsSearchVolumeQuery,
    KeywordsSearchVolumeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeywordsSearchVolumeQuery, KeywordsSearchVolumeQueryVariables>(
    KeywordsSearchVolumeDocument,
    options,
  );
}
export type KeywordsSearchVolumeQueryHookResult = ReturnType<typeof useKeywordsSearchVolumeQuery>;
export type KeywordsSearchVolumeLazyQueryHookResult = ReturnType<
  typeof useKeywordsSearchVolumeLazyQuery
>;
export type KeywordsSearchVolumeQueryResult = Apollo.QueryResult<
  KeywordsSearchVolumeQuery,
  KeywordsSearchVolumeQueryVariables
>;
export const KeywordsAiSearchVolumeDocument = gql`
  query keywordsAiSearchVolume(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
  ) {
    keywords(filters: $filters, pagination: $pagination, ordering: $ordering) {
      keywords {
        id
        keyword
        aiSearchVolume {
          id
          history {
            month
            volume
            volumeMobile
            volumeDesktop
          }
        }
      }
    }
  }
`;

/**
 * __useKeywordsAiSearchVolumeQuery__
 *
 * To run a query within a React component, call `useKeywordsAiSearchVolumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordsAiSearchVolumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordsAiSearchVolumeQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *   },
 * });
 */
export function useKeywordsAiSearchVolumeQuery(
  baseOptions: Apollo.QueryHookOptions<
    KeywordsAiSearchVolumeQuery,
    KeywordsAiSearchVolumeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<KeywordsAiSearchVolumeQuery, KeywordsAiSearchVolumeQueryVariables>(
    KeywordsAiSearchVolumeDocument,
    options,
  );
}
export function useKeywordsAiSearchVolumeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    KeywordsAiSearchVolumeQuery,
    KeywordsAiSearchVolumeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<KeywordsAiSearchVolumeQuery, KeywordsAiSearchVolumeQueryVariables>(
    KeywordsAiSearchVolumeDocument,
    options,
  );
}
export type KeywordsAiSearchVolumeQueryHookResult = ReturnType<
  typeof useKeywordsAiSearchVolumeQuery
>;
export type KeywordsAiSearchVolumeLazyQueryHookResult = ReturnType<
  typeof useKeywordsAiSearchVolumeLazyQuery
>;
export type KeywordsAiSearchVolumeQueryResult = Apollo.QueryResult<
  KeywordsAiSearchVolumeQuery,
  KeywordsAiSearchVolumeQueryVariables
>;
export const UpdateAiSvDocument = gql`
  mutation updateAiSv($keywordId: ID!) {
    refreshAiSearchVolume(keywordId: $keywordId) {
      errors {
        field
        messages
      }
    }
  }
`;
export type UpdateAiSvMutationFn = Apollo.MutationFunction<
  UpdateAiSvMutation,
  UpdateAiSvMutationVariables
>;

/**
 * __useUpdateAiSvMutation__
 *
 * To run a mutation, you first call `useUpdateAiSvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAiSvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAiSvMutation, { data, loading, error }] = useUpdateAiSvMutation({
 *   variables: {
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useUpdateAiSvMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAiSvMutation, UpdateAiSvMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAiSvMutation, UpdateAiSvMutationVariables>(
    UpdateAiSvDocument,
    options,
  );
}
export type UpdateAiSvMutationHookResult = ReturnType<typeof useUpdateAiSvMutation>;
export type UpdateAiSvMutationResult = Apollo.MutationResult<UpdateAiSvMutation>;
export type UpdateAiSvMutationOptions = Apollo.BaseMutationOptions<
  UpdateAiSvMutation,
  UpdateAiSvMutationVariables
>;
export const PaginatedTableKeywordsDocument = gql`
  query PaginatedTableKeywords(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
    $withId: Boolean!
    $withKeyword: Boolean!
    $withStarred: Boolean!
    $withTags: Boolean!
    $withDynamicTags: Boolean!
    $withDynamicCtr: Boolean!
    $withOrganicTraffic: Boolean!
    $withOrganicTrafficCompare: Boolean!
    $withSearchIntent: Boolean!
    $withIgnoreInShareOfVoice: Boolean!
    $withIgnoreLocalResults: Boolean!
    $withIgnoreFeaturedSnippet: Boolean!
    $withEnableAutocorrect: Boolean!
    $withIgnoredDomains: Boolean!
    $withSearchTypeId: Boolean!
    $withUpdatingKeyword: Boolean!
    $withSearchEngineId: Boolean!
    $withDomain: Boolean!
    $withDomainId: Boolean!
    $withDisplayName: Boolean!
    $withKeywordDescription: Boolean!
    $withLocation: Boolean!
    $withCountrylocaleId: Boolean!
    $withRankId: Boolean!
    $withRankValue: Boolean!
    $withRankCompare: Boolean!
    $withHasExtraRanks: Boolean!
    $withExtraRanks: Boolean!
    $withAboveTheFold: Boolean!
    $withTitle: Boolean!
    $withDescription: Boolean!
    $withPreferredLandingPageId: Boolean!
    $withLandingPageId: Boolean!
    $withHighestRankingPage: Boolean!
    $withSearchVolumeValue: Boolean!
    $withAiSearchVolume: Boolean!
    $withValidForSearchVolume: Boolean!
    $withAvgCostPerClick: Boolean!
    $withCompetition: Boolean!
    $withAnalyticsPotential: Boolean!
    $withAnalyticsVisitors: Boolean!
    $withShareOfVoiceIsPercentage: Boolean!
    $withShareOfVoiceValue: Boolean!
    $withShareOfVoiceCompare: Boolean!
    $withShareOfVoicePercentageValue: Boolean!
    $withShareOfVoicePercentageValueCompare: Boolean!
    $withShareOfVoiceCpcValue: Boolean!
    $withShareOfVoiceCpcCompare: Boolean!
    $withSerp: Boolean!
    $withDateAdded: Boolean!
    $withSearchDate: Boolean!
    $withHistoryUrl: Boolean!
    $displayCurrency: String!
    $withIgnoredDomain: Boolean!
    $withBaseRank: Boolean!
    $withBaseRankCompare: Boolean!
    $withMaxRank: Boolean!
    $withLocalPackRank: Boolean!
    $withLocalPackRankCompare: Boolean!
    $withMaxAiSov: Boolean!
    $withMaxAiTrafficValue: Boolean!
    $withAiTrafficValue: Boolean!
    $withAiTrafficValueCompare: Boolean!
    $withMaxTrafficValue: Boolean!
    $withMaxSov: Boolean!
    $withPixelsFromTop: Boolean!
    $withPixelsFromTopCompare: Boolean!
    $withTopDomain: Boolean!
  ) {
    tableKeywords(
      filters: $filters
      pagination: $pagination
      ordering: $ordering
      withId: $withId
      withKeyword: $withKeyword
      withStarred: $withStarred
      withTags: $withTags
      withDynamicTags: $withDynamicTags
      withDynamicCtr: $withDynamicCtr
      withOrganicTraffic: $withOrganicTraffic
      withOrganicTrafficCompare: $withOrganicTrafficCompare
      withSearchIntent: $withSearchIntent
      withIgnoreInShareOfVoice: $withIgnoreInShareOfVoice
      withIgnoreLocalResults: $withIgnoreLocalResults
      withIgnoreFeaturedSnippet: $withIgnoreFeaturedSnippet
      withEnableAutocorrect: $withEnableAutocorrect
      withIgnoredDomains: $withIgnoredDomains
      withSearchTypeId: $withSearchTypeId
      withUpdatingKeyword: $withUpdatingKeyword
      withSearchEngineId: $withSearchEngineId
      withDomain: $withDomain
      withDomainId: $withDomainId
      withDisplayName: $withDisplayName
      withKeywordDescription: $withKeywordDescription
      withLocation: $withLocation
      withCountrylocaleId: $withCountrylocaleId
      withRankId: $withRankId
      withRankValue: $withRankValue
      withRankCompare: $withRankCompare
      withHasExtraRanks: $withHasExtraRanks
      withExtraRanks: $withExtraRanks
      withAboveTheFold: $withAboveTheFold
      withTitle: $withTitle
      withDescription: $withDescription
      withPreferredLandingPageId: $withPreferredLandingPageId
      withLandingPageId: $withLandingPageId
      withHighestRankingPage: $withHighestRankingPage
      withSearchVolumeValue: $withSearchVolumeValue
      withAiSearchVolume: $withAiSearchVolume
      withValidForSearchVolume: $withValidForSearchVolume
      withAvgCostPerClick: $withAvgCostPerClick
      withCompetition: $withCompetition
      withAnalyticsPotential: $withAnalyticsPotential
      withAnalyticsVisitors: $withAnalyticsVisitors
      withShareOfVoiceIsPercentage: $withShareOfVoiceIsPercentage
      withShareOfVoiceValue: $withShareOfVoiceValue
      withShareOfVoiceCompare: $withShareOfVoiceCompare
      withShareOfVoicePercentageValue: $withShareOfVoicePercentageValue
      withShareOfVoicePercentageValueCompare: $withShareOfVoicePercentageValueCompare
      withShareOfVoiceCpcValue: $withShareOfVoiceCpcValue
      withShareOfVoiceCpcCompare: $withShareOfVoiceCpcCompare
      withSerp: $withSerp
      withDateAdded: $withDateAdded
      withSearchDate: $withSearchDate
      withHistoryUrl: $withHistoryUrl
      displayCurrency: $displayCurrency
      withIgnoredDomain: $withIgnoredDomain
      withBaseRank: $withBaseRank
      withBaseRankCompare: $withBaseRankCompare
      withMaxRank: $withMaxRank
      withLocalPackRank: $withLocalPackRank
      withLocalPackRankCompare: $withLocalPackRankCompare
      withMaxAiSov: $withMaxAiSov
      withMaxAiTrafficValue: $withMaxAiTrafficValue
      withAiTrafficValue: $withAiTrafficValue
      withAiTrafficValueCompare: $withAiTrafficValueCompare
      withMaxTrafficValue: $withMaxTrafficValue
      withMaxSov: $withMaxSov
      withPixelsFromTop: $withPixelsFromTop
      withPixelsFromTopCompare: $withPixelsFromTopCompare
      withTopDomain: $withTopDomain
    ) {
      keywords
      pagination {
        numResults
        startIndex
        stopIndex
      }
    }
  }
`;

/**
 * __usePaginatedTableKeywordsQuery__
 *
 * To run a query within a React component, call `usePaginatedTableKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedTableKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedTableKeywordsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      withId: // value for 'withId'
 *      withKeyword: // value for 'withKeyword'
 *      withStarred: // value for 'withStarred'
 *      withTags: // value for 'withTags'
 *      withDynamicTags: // value for 'withDynamicTags'
 *      withDynamicCtr: // value for 'withDynamicCtr'
 *      withOrganicTraffic: // value for 'withOrganicTraffic'
 *      withOrganicTrafficCompare: // value for 'withOrganicTrafficCompare'
 *      withSearchIntent: // value for 'withSearchIntent'
 *      withIgnoreInShareOfVoice: // value for 'withIgnoreInShareOfVoice'
 *      withIgnoreLocalResults: // value for 'withIgnoreLocalResults'
 *      withIgnoreFeaturedSnippet: // value for 'withIgnoreFeaturedSnippet'
 *      withEnableAutocorrect: // value for 'withEnableAutocorrect'
 *      withIgnoredDomains: // value for 'withIgnoredDomains'
 *      withSearchTypeId: // value for 'withSearchTypeId'
 *      withUpdatingKeyword: // value for 'withUpdatingKeyword'
 *      withSearchEngineId: // value for 'withSearchEngineId'
 *      withDomain: // value for 'withDomain'
 *      withDomainId: // value for 'withDomainId'
 *      withDisplayName: // value for 'withDisplayName'
 *      withKeywordDescription: // value for 'withKeywordDescription'
 *      withLocation: // value for 'withLocation'
 *      withCountrylocaleId: // value for 'withCountrylocaleId'
 *      withRankId: // value for 'withRankId'
 *      withRankValue: // value for 'withRankValue'
 *      withRankCompare: // value for 'withRankCompare'
 *      withHasExtraRanks: // value for 'withHasExtraRanks'
 *      withExtraRanks: // value for 'withExtraRanks'
 *      withAboveTheFold: // value for 'withAboveTheFold'
 *      withTitle: // value for 'withTitle'
 *      withDescription: // value for 'withDescription'
 *      withPreferredLandingPageId: // value for 'withPreferredLandingPageId'
 *      withLandingPageId: // value for 'withLandingPageId'
 *      withHighestRankingPage: // value for 'withHighestRankingPage'
 *      withSearchVolumeValue: // value for 'withSearchVolumeValue'
 *      withAiSearchVolume: // value for 'withAiSearchVolume'
 *      withValidForSearchVolume: // value for 'withValidForSearchVolume'
 *      withAvgCostPerClick: // value for 'withAvgCostPerClick'
 *      withCompetition: // value for 'withCompetition'
 *      withAnalyticsPotential: // value for 'withAnalyticsPotential'
 *      withAnalyticsVisitors: // value for 'withAnalyticsVisitors'
 *      withShareOfVoiceIsPercentage: // value for 'withShareOfVoiceIsPercentage'
 *      withShareOfVoiceValue: // value for 'withShareOfVoiceValue'
 *      withShareOfVoiceCompare: // value for 'withShareOfVoiceCompare'
 *      withShareOfVoicePercentageValue: // value for 'withShareOfVoicePercentageValue'
 *      withShareOfVoicePercentageValueCompare: // value for 'withShareOfVoicePercentageValueCompare'
 *      withShareOfVoiceCpcValue: // value for 'withShareOfVoiceCpcValue'
 *      withShareOfVoiceCpcCompare: // value for 'withShareOfVoiceCpcCompare'
 *      withSerp: // value for 'withSerp'
 *      withDateAdded: // value for 'withDateAdded'
 *      withSearchDate: // value for 'withSearchDate'
 *      withHistoryUrl: // value for 'withHistoryUrl'
 *      displayCurrency: // value for 'displayCurrency'
 *      withIgnoredDomain: // value for 'withIgnoredDomain'
 *      withBaseRank: // value for 'withBaseRank'
 *      withBaseRankCompare: // value for 'withBaseRankCompare'
 *      withMaxRank: // value for 'withMaxRank'
 *      withLocalPackRank: // value for 'withLocalPackRank'
 *      withLocalPackRankCompare: // value for 'withLocalPackRankCompare'
 *      withMaxAiSov: // value for 'withMaxAiSov'
 *      withMaxAiTrafficValue: // value for 'withMaxAiTrafficValue'
 *      withAiTrafficValue: // value for 'withAiTrafficValue'
 *      withAiTrafficValueCompare: // value for 'withAiTrafficValueCompare'
 *      withMaxTrafficValue: // value for 'withMaxTrafficValue'
 *      withMaxSov: // value for 'withMaxSov'
 *      withPixelsFromTop: // value for 'withPixelsFromTop'
 *      withPixelsFromTopCompare: // value for 'withPixelsFromTopCompare'
 *      withTopDomain: // value for 'withTopDomain'
 *   },
 * });
 */
export function usePaginatedTableKeywordsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaginatedTableKeywordsQuery,
    PaginatedTableKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginatedTableKeywordsQuery, PaginatedTableKeywordsQueryVariables>(
    PaginatedTableKeywordsDocument,
    options,
  );
}
export function usePaginatedTableKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaginatedTableKeywordsQuery,
    PaginatedTableKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginatedTableKeywordsQuery, PaginatedTableKeywordsQueryVariables>(
    PaginatedTableKeywordsDocument,
    options,
  );
}
export type PaginatedTableKeywordsQueryHookResult = ReturnType<
  typeof usePaginatedTableKeywordsQuery
>;
export type PaginatedTableKeywordsLazyQueryHookResult = ReturnType<
  typeof usePaginatedTableKeywordsLazyQuery
>;
export type PaginatedTableKeywordsQueryResult = Apollo.QueryResult<
  PaginatedTableKeywordsQuery,
  PaginatedTableKeywordsQueryVariables
>;
export const GetKeywordsDocument = gql`
  query GetKeywords(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
    $withId: Boolean!
    $withKeyword: Boolean!
    $withStarred: Boolean!
    $withTags: Boolean!
    $withDynamicTags: Boolean!
    $withDynamicCtr: Boolean!
    $withOrganicTraffic: Boolean!
    $withOrganicTrafficCompare: Boolean!
    $withSearchIntent: Boolean!
    $withIgnoreInShareOfVoice: Boolean!
    $withIgnoreLocalResults: Boolean!
    $withIgnoreFeaturedSnippet: Boolean!
    $withEnableAutocorrect: Boolean!
    $withIgnoredDomains: Boolean!
    $withSearchTypeId: Boolean!
    $withUpdatingKeyword: Boolean!
    $withSearchEngineId: Boolean!
    $withDomain: Boolean!
    $withDomainId: Boolean!
    $withDisplayName: Boolean!
    $withKeywordDescription: Boolean!
    $withLocation: Boolean!
    $withCountrylocaleId: Boolean!
    $withRankId: Boolean!
    $withRankValue: Boolean!
    $withRankCompare: Boolean!
    $withHasExtraRanks: Boolean!
    $withExtraRanks: Boolean!
    $withAboveTheFold: Boolean!
    $withTitle: Boolean!
    $withDescription: Boolean!
    $withPreferredLandingPageId: Boolean!
    $withLandingPageId: Boolean!
    $withHighestRankingPage: Boolean!
    $withSearchVolumeValue: Boolean!
    $withValidForSearchVolume: Boolean!
    $withAvgCostPerClick: Boolean!
    $withCompetition: Boolean!
    $withAnalyticsPotential: Boolean!
    $withAnalyticsVisitors: Boolean!
    $withShareOfVoiceIsPercentage: Boolean!
    $withShareOfVoiceValue: Boolean!
    $withShareOfVoiceCompare: Boolean!
    $withShareOfVoicePercentageValue: Boolean!
    $withShareOfVoicePercentageValueCompare: Boolean!
    $withShareOfVoiceCpcValue: Boolean!
    $withShareOfVoiceCpcCompare: Boolean!
    $withSerp: Boolean!
    $withDateAdded: Boolean!
    $withSearchDate: Boolean!
    $withHistoryUrl: Boolean!
    $displayCurrency: String!
    $withIgnoredDomain: Boolean!
    $withBaseRank: Boolean!
    $withBaseRankCompare: Boolean!
    $withMaxRank: Boolean!
    $withLocalPackRank: Boolean!
    $withLocalPackRankCompare: Boolean!
    $withMaxAiSov: Boolean!
    $withMaxAiTrafficValue: Boolean!
    $withAiTrafficValue: Boolean!
    $withAiTrafficValueCompare: Boolean!
    $withMaxTrafficValue: Boolean!
    $withMaxSov: Boolean!
    $withPixelsFromTop: Boolean!
    $withPixelsFromTopCompare: Boolean!
    $withTopDomain: Boolean!
  ) {
    tableKeywords(
      filters: $filters
      pagination: $pagination
      ordering: $ordering
      withId: $withId
      withKeyword: $withKeyword
      withStarred: $withStarred
      withTags: $withTags
      withDynamicTags: $withDynamicTags
      withDynamicCtr: $withDynamicCtr
      withOrganicTraffic: $withOrganicTraffic
      withOrganicTrafficCompare: $withOrganicTrafficCompare
      withSearchIntent: $withSearchIntent
      withIgnoreInShareOfVoice: $withIgnoreInShareOfVoice
      withIgnoreLocalResults: $withIgnoreLocalResults
      withIgnoreFeaturedSnippet: $withIgnoreFeaturedSnippet
      withEnableAutocorrect: $withEnableAutocorrect
      withIgnoredDomains: $withIgnoredDomains
      withSearchTypeId: $withSearchTypeId
      withUpdatingKeyword: $withUpdatingKeyword
      withSearchEngineId: $withSearchEngineId
      withDomain: $withDomain
      withDomainId: $withDomainId
      withDisplayName: $withDisplayName
      withKeywordDescription: $withKeywordDescription
      withLocation: $withLocation
      withCountrylocaleId: $withCountrylocaleId
      withRankId: $withRankId
      withRankValue: $withRankValue
      withRankCompare: $withRankCompare
      withHasExtraRanks: $withHasExtraRanks
      withExtraRanks: $withExtraRanks
      withAboveTheFold: $withAboveTheFold
      withTitle: $withTitle
      withDescription: $withDescription
      withPreferredLandingPageId: $withPreferredLandingPageId
      withLandingPageId: $withLandingPageId
      withHighestRankingPage: $withHighestRankingPage
      withSearchVolumeValue: $withSearchVolumeValue
      withValidForSearchVolume: $withValidForSearchVolume
      withAvgCostPerClick: $withAvgCostPerClick
      withCompetition: $withCompetition
      withAnalyticsPotential: $withAnalyticsPotential
      withAnalyticsVisitors: $withAnalyticsVisitors
      withShareOfVoiceIsPercentage: $withShareOfVoiceIsPercentage
      withShareOfVoiceValue: $withShareOfVoiceValue
      withShareOfVoiceCompare: $withShareOfVoiceCompare
      withShareOfVoicePercentageValue: $withShareOfVoicePercentageValue
      withShareOfVoicePercentageValueCompare: $withShareOfVoicePercentageValueCompare
      withShareOfVoiceCpcValue: $withShareOfVoiceCpcValue
      withShareOfVoiceCpcCompare: $withShareOfVoiceCpcCompare
      withSerp: $withSerp
      withDateAdded: $withDateAdded
      withSearchDate: $withSearchDate
      withHistoryUrl: $withHistoryUrl
      displayCurrency: $displayCurrency
      withIgnoredDomain: $withIgnoredDomain
      withBaseRank: $withBaseRank
      withBaseRankCompare: $withBaseRankCompare
      withMaxRank: $withMaxRank
      withLocalPackRank: $withLocalPackRank
      withLocalPackRankCompare: $withLocalPackRankCompare
      withMaxAiSov: $withMaxAiSov
      withMaxAiTrafficValue: $withMaxAiTrafficValue
      withAiTrafficValue: $withAiTrafficValue
      withAiTrafficValueCompare: $withAiTrafficValueCompare
      withMaxTrafficValue: $withMaxTrafficValue
      withMaxSov: $withMaxSov
      withPixelsFromTop: $withPixelsFromTop
      withPixelsFromTopCompare: $withPixelsFromTopCompare
      withTopDomain: $withTopDomain
    ) {
      keywords
      pagination {
        numResults
        startIndex
        stopIndex
      }
    }
  }
`;

/**
 * __useGetKeywordsQuery__
 *
 * To run a query within a React component, call `useGetKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeywordsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      withId: // value for 'withId'
 *      withKeyword: // value for 'withKeyword'
 *      withStarred: // value for 'withStarred'
 *      withTags: // value for 'withTags'
 *      withDynamicTags: // value for 'withDynamicTags'
 *      withDynamicCtr: // value for 'withDynamicCtr'
 *      withOrganicTraffic: // value for 'withOrganicTraffic'
 *      withOrganicTrafficCompare: // value for 'withOrganicTrafficCompare'
 *      withSearchIntent: // value for 'withSearchIntent'
 *      withIgnoreInShareOfVoice: // value for 'withIgnoreInShareOfVoice'
 *      withIgnoreLocalResults: // value for 'withIgnoreLocalResults'
 *      withIgnoreFeaturedSnippet: // value for 'withIgnoreFeaturedSnippet'
 *      withEnableAutocorrect: // value for 'withEnableAutocorrect'
 *      withIgnoredDomains: // value for 'withIgnoredDomains'
 *      withSearchTypeId: // value for 'withSearchTypeId'
 *      withUpdatingKeyword: // value for 'withUpdatingKeyword'
 *      withSearchEngineId: // value for 'withSearchEngineId'
 *      withDomain: // value for 'withDomain'
 *      withDomainId: // value for 'withDomainId'
 *      withDisplayName: // value for 'withDisplayName'
 *      withKeywordDescription: // value for 'withKeywordDescription'
 *      withLocation: // value for 'withLocation'
 *      withCountrylocaleId: // value for 'withCountrylocaleId'
 *      withRankId: // value for 'withRankId'
 *      withRankValue: // value for 'withRankValue'
 *      withRankCompare: // value for 'withRankCompare'
 *      withHasExtraRanks: // value for 'withHasExtraRanks'
 *      withExtraRanks: // value for 'withExtraRanks'
 *      withAboveTheFold: // value for 'withAboveTheFold'
 *      withTitle: // value for 'withTitle'
 *      withDescription: // value for 'withDescription'
 *      withPreferredLandingPageId: // value for 'withPreferredLandingPageId'
 *      withLandingPageId: // value for 'withLandingPageId'
 *      withHighestRankingPage: // value for 'withHighestRankingPage'
 *      withSearchVolumeValue: // value for 'withSearchVolumeValue'
 *      withValidForSearchVolume: // value for 'withValidForSearchVolume'
 *      withAvgCostPerClick: // value for 'withAvgCostPerClick'
 *      withCompetition: // value for 'withCompetition'
 *      withAnalyticsPotential: // value for 'withAnalyticsPotential'
 *      withAnalyticsVisitors: // value for 'withAnalyticsVisitors'
 *      withShareOfVoiceIsPercentage: // value for 'withShareOfVoiceIsPercentage'
 *      withShareOfVoiceValue: // value for 'withShareOfVoiceValue'
 *      withShareOfVoiceCompare: // value for 'withShareOfVoiceCompare'
 *      withShareOfVoicePercentageValue: // value for 'withShareOfVoicePercentageValue'
 *      withShareOfVoicePercentageValueCompare: // value for 'withShareOfVoicePercentageValueCompare'
 *      withShareOfVoiceCpcValue: // value for 'withShareOfVoiceCpcValue'
 *      withShareOfVoiceCpcCompare: // value for 'withShareOfVoiceCpcCompare'
 *      withSerp: // value for 'withSerp'
 *      withDateAdded: // value for 'withDateAdded'
 *      withSearchDate: // value for 'withSearchDate'
 *      withHistoryUrl: // value for 'withHistoryUrl'
 *      displayCurrency: // value for 'displayCurrency'
 *      withIgnoredDomain: // value for 'withIgnoredDomain'
 *      withBaseRank: // value for 'withBaseRank'
 *      withBaseRankCompare: // value for 'withBaseRankCompare'
 *      withMaxRank: // value for 'withMaxRank'
 *      withLocalPackRank: // value for 'withLocalPackRank'
 *      withLocalPackRankCompare: // value for 'withLocalPackRankCompare'
 *      withMaxAiSov: // value for 'withMaxAiSov'
 *      withMaxAiTrafficValue: // value for 'withMaxAiTrafficValue'
 *      withAiTrafficValue: // value for 'withAiTrafficValue'
 *      withAiTrafficValueCompare: // value for 'withAiTrafficValueCompare'
 *      withMaxTrafficValue: // value for 'withMaxTrafficValue'
 *      withMaxSov: // value for 'withMaxSov'
 *      withPixelsFromTop: // value for 'withPixelsFromTop'
 *      withPixelsFromTopCompare: // value for 'withPixelsFromTopCompare'
 *      withTopDomain: // value for 'withTopDomain'
 *   },
 * });
 */
export function useGetKeywordsQuery(
  baseOptions: Apollo.QueryHookOptions<GetKeywordsQuery, GetKeywordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetKeywordsQuery, GetKeywordsQueryVariables>(GetKeywordsDocument, options);
}
export function useGetKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetKeywordsQuery, GetKeywordsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetKeywordsQuery, GetKeywordsQueryVariables>(
    GetKeywordsDocument,
    options,
  );
}
export type GetKeywordsQueryHookResult = ReturnType<typeof useGetKeywordsQuery>;
export type GetKeywordsLazyQueryHookResult = ReturnType<typeof useGetKeywordsLazyQuery>;
export type GetKeywordsQueryResult = Apollo.QueryResult<
  GetKeywordsQuery,
  GetKeywordsQueryVariables
>;
export const QueryIsDemoClientDocument = gql`
  query queryIsDemoClient($input: ID!) {
    domain(id: $input) {
      client {
        isDemoClient
      }
      demoDomainBannerInfo {
        domainCount
        domainId
        domainName
      }
    }
  }
`;

/**
 * __useQueryIsDemoClientQuery__
 *
 * To run a query within a React component, call `useQueryIsDemoClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryIsDemoClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryIsDemoClientQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueryIsDemoClientQuery(
  baseOptions: Apollo.QueryHookOptions<QueryIsDemoClientQuery, QueryIsDemoClientQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryIsDemoClientQuery, QueryIsDemoClientQueryVariables>(
    QueryIsDemoClientDocument,
    options,
  );
}
export function useQueryIsDemoClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryIsDemoClientQuery,
    QueryIsDemoClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryIsDemoClientQuery, QueryIsDemoClientQueryVariables>(
    QueryIsDemoClientDocument,
    options,
  );
}
export type QueryIsDemoClientQueryHookResult = ReturnType<typeof useQueryIsDemoClientQuery>;
export type QueryIsDemoClientLazyQueryHookResult = ReturnType<typeof useQueryIsDemoClientLazyQuery>;
export type QueryIsDemoClientQueryResult = Apollo.QueryResult<
  QueryIsDemoClientQuery,
  QueryIsDemoClientQueryVariables
>;
export const GroupsAndDomainClientsDocument = gql`
  query GroupsAndDomainClients {
    clients {
      id
      name
      organization {
        id
      }
      domains {
        id
        domain
        displayName
      }
      isDemoClient
      latestCompletedScrapeDate
    }
  }
`;

/**
 * __useGroupsAndDomainClientsQuery__
 *
 * To run a query within a React component, call `useGroupsAndDomainClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsAndDomainClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsAndDomainClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsAndDomainClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GroupsAndDomainClientsQuery,
    GroupsAndDomainClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupsAndDomainClientsQuery, GroupsAndDomainClientsQueryVariables>(
    GroupsAndDomainClientsDocument,
    options,
  );
}
export function useGroupsAndDomainClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupsAndDomainClientsQuery,
    GroupsAndDomainClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupsAndDomainClientsQuery, GroupsAndDomainClientsQueryVariables>(
    GroupsAndDomainClientsDocument,
    options,
  );
}
export type GroupsAndDomainClientsQueryHookResult = ReturnType<
  typeof useGroupsAndDomainClientsQuery
>;
export type GroupsAndDomainClientsLazyQueryHookResult = ReturnType<
  typeof useGroupsAndDomainClientsLazyQuery
>;
export type GroupsAndDomainClientsQueryResult = Apollo.QueryResult<
  GroupsAndDomainClientsQuery,
  GroupsAndDomainClientsQueryVariables
>;
export const GroupsAndDomainsDeleteDomainDocument = gql`
  mutation GroupsAndDomainsDeleteDomain($input: DeleteDomainInput!) {
    deleteDomain(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type GroupsAndDomainsDeleteDomainMutationFn = Apollo.MutationFunction<
  GroupsAndDomainsDeleteDomainMutation,
  GroupsAndDomainsDeleteDomainMutationVariables
>;

/**
 * __useGroupsAndDomainsDeleteDomainMutation__
 *
 * To run a mutation, you first call `useGroupsAndDomainsDeleteDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupsAndDomainsDeleteDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupsAndDomainsDeleteDomainMutation, { data, loading, error }] = useGroupsAndDomainsDeleteDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupsAndDomainsDeleteDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupsAndDomainsDeleteDomainMutation,
    GroupsAndDomainsDeleteDomainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GroupsAndDomainsDeleteDomainMutation,
    GroupsAndDomainsDeleteDomainMutationVariables
  >(GroupsAndDomainsDeleteDomainDocument, options);
}
export type GroupsAndDomainsDeleteDomainMutationHookResult = ReturnType<
  typeof useGroupsAndDomainsDeleteDomainMutation
>;
export type GroupsAndDomainsDeleteDomainMutationResult =
  Apollo.MutationResult<GroupsAndDomainsDeleteDomainMutation>;
export type GroupsAndDomainsDeleteDomainMutationOptions = Apollo.BaseMutationOptions<
  GroupsAndDomainsDeleteDomainMutation,
  GroupsAndDomainsDeleteDomainMutationVariables
>;
export const GroupsAndDomainsUpdateClientDocument = gql`
  mutation GroupsAndDomainsUpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type GroupsAndDomainsUpdateClientMutationFn = Apollo.MutationFunction<
  GroupsAndDomainsUpdateClientMutation,
  GroupsAndDomainsUpdateClientMutationVariables
>;

/**
 * __useGroupsAndDomainsUpdateClientMutation__
 *
 * To run a mutation, you first call `useGroupsAndDomainsUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupsAndDomainsUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupsAndDomainsUpdateClientMutation, { data, loading, error }] = useGroupsAndDomainsUpdateClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupsAndDomainsUpdateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupsAndDomainsUpdateClientMutation,
    GroupsAndDomainsUpdateClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GroupsAndDomainsUpdateClientMutation,
    GroupsAndDomainsUpdateClientMutationVariables
  >(GroupsAndDomainsUpdateClientDocument, options);
}
export type GroupsAndDomainsUpdateClientMutationHookResult = ReturnType<
  typeof useGroupsAndDomainsUpdateClientMutation
>;
export type GroupsAndDomainsUpdateClientMutationResult =
  Apollo.MutationResult<GroupsAndDomainsUpdateClientMutation>;
export type GroupsAndDomainsUpdateClientMutationOptions = Apollo.BaseMutationOptions<
  GroupsAndDomainsUpdateClientMutation,
  GroupsAndDomainsUpdateClientMutationVariables
>;
export const StatusServiceMessagesDocument = gql`
  query StatusServiceMessages {
    serviceMessageHighestSeverity
  }
`;

/**
 * __useStatusServiceMessagesQuery__
 *
 * To run a query within a React component, call `useStatusServiceMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusServiceMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusServiceMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatusServiceMessagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StatusServiceMessagesQuery,
    StatusServiceMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StatusServiceMessagesQuery, StatusServiceMessagesQueryVariables>(
    StatusServiceMessagesDocument,
    options,
  );
}
export function useStatusServiceMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StatusServiceMessagesQuery,
    StatusServiceMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StatusServiceMessagesQuery, StatusServiceMessagesQueryVariables>(
    StatusServiceMessagesDocument,
    options,
  );
}
export type StatusServiceMessagesQueryHookResult = ReturnType<typeof useStatusServiceMessagesQuery>;
export type StatusServiceMessagesLazyQueryHookResult = ReturnType<
  typeof useStatusServiceMessagesLazyQuery
>;
export type StatusServiceMessagesQueryResult = Apollo.QueryResult<
  StatusServiceMessagesQuery,
  StatusServiceMessagesQueryVariables
>;
export const NotesTableDomainDocument = gql`
  query NotesTableDomain($id: ID!) {
    domain(id: $id) {
      id
      notes {
        id
        createdAt
        note
        keywords {
          id
          keyword
        }
        user {
          id
          fullName
        }
      }
    }
  }
`;

/**
 * __useNotesTableDomainQuery__
 *
 * To run a query within a React component, call `useNotesTableDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesTableDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesTableDomainQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotesTableDomainQuery(
  baseOptions: Apollo.QueryHookOptions<NotesTableDomainQuery, NotesTableDomainQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotesTableDomainQuery, NotesTableDomainQueryVariables>(
    NotesTableDomainDocument,
    options,
  );
}
export function useNotesTableDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotesTableDomainQuery, NotesTableDomainQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotesTableDomainQuery, NotesTableDomainQueryVariables>(
    NotesTableDomainDocument,
    options,
  );
}
export type NotesTableDomainQueryHookResult = ReturnType<typeof useNotesTableDomainQuery>;
export type NotesTableDomainLazyQueryHookResult = ReturnType<typeof useNotesTableDomainLazyQuery>;
export type NotesTableDomainQueryResult = Apollo.QueryResult<
  NotesTableDomainQuery,
  NotesTableDomainQueryVariables
>;
export const EditNoteDocument = gql`
  mutation EditNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      note {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type EditNoteMutationFn = Apollo.MutationFunction<
  EditNoteMutation,
  EditNoteMutationVariables
>;

/**
 * __useEditNoteMutation__
 *
 * To run a mutation, you first call `useEditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNoteMutation, { data, loading, error }] = useEditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<EditNoteMutation, EditNoteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditNoteMutation, EditNoteMutationVariables>(EditNoteDocument, options);
}
export type EditNoteMutationHookResult = ReturnType<typeof useEditNoteMutation>;
export type EditNoteMutationResult = Apollo.MutationResult<EditNoteMutation>;
export type EditNoteMutationOptions = Apollo.BaseMutationOptions<
  EditNoteMutation,
  EditNoteMutationVariables
>;
export const PausedDomainsUpdateDomainDocument = gql`
  mutation PausedDomainsUpdateDomain($input: UpdateDomainInput!) {
    updateDomain(input: $input) {
      domain {
        id
      }
      code
      errors {
        field
        messages
      }
    }
  }
`;
export type PausedDomainsUpdateDomainMutationFn = Apollo.MutationFunction<
  PausedDomainsUpdateDomainMutation,
  PausedDomainsUpdateDomainMutationVariables
>;

/**
 * __usePausedDomainsUpdateDomainMutation__
 *
 * To run a mutation, you first call `usePausedDomainsUpdateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePausedDomainsUpdateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pausedDomainsUpdateDomainMutation, { data, loading, error }] = usePausedDomainsUpdateDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePausedDomainsUpdateDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PausedDomainsUpdateDomainMutation,
    PausedDomainsUpdateDomainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PausedDomainsUpdateDomainMutation,
    PausedDomainsUpdateDomainMutationVariables
  >(PausedDomainsUpdateDomainDocument, options);
}
export type PausedDomainsUpdateDomainMutationHookResult = ReturnType<
  typeof usePausedDomainsUpdateDomainMutation
>;
export type PausedDomainsUpdateDomainMutationResult =
  Apollo.MutationResult<PausedDomainsUpdateDomainMutation>;
export type PausedDomainsUpdateDomainMutationOptions = Apollo.BaseMutationOptions<
  PausedDomainsUpdateDomainMutation,
  PausedDomainsUpdateDomainMutationVariables
>;
export const PausedDomainsListDocument = gql`
  query PausedDomainsList {
    pausedDomainsList {
      ...PausedDomain
    }
  }
  ${PausedDomainFragmentDoc}
`;

/**
 * __usePausedDomainsListQuery__
 *
 * To run a query within a React component, call `usePausedDomainsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePausedDomainsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePausedDomainsListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePausedDomainsListQuery(
  baseOptions?: Apollo.QueryHookOptions<PausedDomainsListQuery, PausedDomainsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PausedDomainsListQuery, PausedDomainsListQueryVariables>(
    PausedDomainsListDocument,
    options,
  );
}
export function usePausedDomainsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PausedDomainsListQuery,
    PausedDomainsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PausedDomainsListQuery, PausedDomainsListQueryVariables>(
    PausedDomainsListDocument,
    options,
  );
}
export type PausedDomainsListQueryHookResult = ReturnType<typeof usePausedDomainsListQuery>;
export type PausedDomainsListLazyQueryHookResult = ReturnType<typeof usePausedDomainsListLazyQuery>;
export type PausedDomainsListQueryResult = Apollo.QueryResult<
  PausedDomainsListQuery,
  PausedDomainsListQueryVariables
>;
export const PausedDomainsDeleteDomainDocument = gql`
  mutation PausedDomainsDeleteDomain($input: DeleteDomainInput!) {
    deleteDomain(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type PausedDomainsDeleteDomainMutationFn = Apollo.MutationFunction<
  PausedDomainsDeleteDomainMutation,
  PausedDomainsDeleteDomainMutationVariables
>;

/**
 * __usePausedDomainsDeleteDomainMutation__
 *
 * To run a mutation, you first call `usePausedDomainsDeleteDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePausedDomainsDeleteDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pausedDomainsDeleteDomainMutation, { data, loading, error }] = usePausedDomainsDeleteDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePausedDomainsDeleteDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PausedDomainsDeleteDomainMutation,
    PausedDomainsDeleteDomainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PausedDomainsDeleteDomainMutation,
    PausedDomainsDeleteDomainMutationVariables
  >(PausedDomainsDeleteDomainDocument, options);
}
export type PausedDomainsDeleteDomainMutationHookResult = ReturnType<
  typeof usePausedDomainsDeleteDomainMutation
>;
export type PausedDomainsDeleteDomainMutationResult =
  Apollo.MutationResult<PausedDomainsDeleteDomainMutation>;
export type PausedDomainsDeleteDomainMutationOptions = Apollo.BaseMutationOptions<
  PausedDomainsDeleteDomainMutation,
  PausedDomainsDeleteDomainMutationVariables
>;
export const ChangeUserPasswordDocument = gql`
  mutation ChangeUserPassword($updatePasswordInput: UpdateMyUserPasswordInput!) {
    updateMyUserPassword(input: $updatePasswordInput) {
      errors {
        field
        messages
      }
    }
  }
`;
export type ChangeUserPasswordMutationFn = Apollo.MutationFunction<
  ChangeUserPasswordMutation,
  ChangeUserPasswordMutationVariables
>;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      updatePasswordInput: // value for 'updatePasswordInput'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserPasswordMutation,
    ChangeUserPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>(
    ChangeUserPasswordDocument,
    options,
  );
}
export type ChangeUserPasswordMutationHookResult = ReturnType<typeof useChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationResult = Apollo.MutationResult<ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserPasswordMutation,
  ChangeUserPasswordMutationVariables
>;
export const EditProfileUserSocialConnectionsDocument = gql`
  query EditProfileUserSocialConnections {
    user {
      id
      hasOtpDevice
      socialAuths {
        google
        linkedin
        microsoft
        facebook
      }
    }
  }
`;

/**
 * __useEditProfileUserSocialConnectionsQuery__
 *
 * To run a query within a React component, call `useEditProfileUserSocialConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditProfileUserSocialConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditProfileUserSocialConnectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditProfileUserSocialConnectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EditProfileUserSocialConnectionsQuery,
    EditProfileUserSocialConnectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EditProfileUserSocialConnectionsQuery,
    EditProfileUserSocialConnectionsQueryVariables
  >(EditProfileUserSocialConnectionsDocument, options);
}
export function useEditProfileUserSocialConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditProfileUserSocialConnectionsQuery,
    EditProfileUserSocialConnectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EditProfileUserSocialConnectionsQuery,
    EditProfileUserSocialConnectionsQueryVariables
  >(EditProfileUserSocialConnectionsDocument, options);
}
export type EditProfileUserSocialConnectionsQueryHookResult = ReturnType<
  typeof useEditProfileUserSocialConnectionsQuery
>;
export type EditProfileUserSocialConnectionsLazyQueryHookResult = ReturnType<
  typeof useEditProfileUserSocialConnectionsLazyQuery
>;
export type EditProfileUserSocialConnectionsQueryResult = Apollo.QueryResult<
  EditProfileUserSocialConnectionsQuery,
  EditProfileUserSocialConnectionsQueryVariables
>;
export const EditProfileApiTokenDocument = gql`
  query EditProfileApiToken {
    user {
      id
      apiToken
    }
  }
`;

/**
 * __useEditProfileApiTokenQuery__
 *
 * To run a query within a React component, call `useEditProfileApiTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditProfileApiTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditProfileApiTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditProfileApiTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EditProfileApiTokenQuery,
    EditProfileApiTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EditProfileApiTokenQuery, EditProfileApiTokenQueryVariables>(
    EditProfileApiTokenDocument,
    options,
  );
}
export function useEditProfileApiTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditProfileApiTokenQuery,
    EditProfileApiTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EditProfileApiTokenQuery, EditProfileApiTokenQueryVariables>(
    EditProfileApiTokenDocument,
    options,
  );
}
export type EditProfileApiTokenQueryHookResult = ReturnType<typeof useEditProfileApiTokenQuery>;
export type EditProfileApiTokenLazyQueryHookResult = ReturnType<
  typeof useEditProfileApiTokenLazyQuery
>;
export type EditProfileApiTokenQueryResult = Apollo.QueryResult<
  EditProfileApiTokenQuery,
  EditProfileApiTokenQueryVariables
>;
export const EditProfileDisconnectSocialAuthDocument = gql`
  mutation EditProfileDisconnectSocialAuth($input: UserSocialAuthDisconnectInput!) {
    userSocialAuthDisconnect(input: $input) {
      user {
        id
      }
    }
  }
`;
export type EditProfileDisconnectSocialAuthMutationFn = Apollo.MutationFunction<
  EditProfileDisconnectSocialAuthMutation,
  EditProfileDisconnectSocialAuthMutationVariables
>;

/**
 * __useEditProfileDisconnectSocialAuthMutation__
 *
 * To run a mutation, you first call `useEditProfileDisconnectSocialAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileDisconnectSocialAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileDisconnectSocialAuthMutation, { data, loading, error }] = useEditProfileDisconnectSocialAuthMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditProfileDisconnectSocialAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProfileDisconnectSocialAuthMutation,
    EditProfileDisconnectSocialAuthMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditProfileDisconnectSocialAuthMutation,
    EditProfileDisconnectSocialAuthMutationVariables
  >(EditProfileDisconnectSocialAuthDocument, options);
}
export type EditProfileDisconnectSocialAuthMutationHookResult = ReturnType<
  typeof useEditProfileDisconnectSocialAuthMutation
>;
export type EditProfileDisconnectSocialAuthMutationResult =
  Apollo.MutationResult<EditProfileDisconnectSocialAuthMutation>;
export type EditProfileDisconnectSocialAuthMutationOptions = Apollo.BaseMutationOptions<
  EditProfileDisconnectSocialAuthMutation,
  EditProfileDisconnectSocialAuthMutationVariables
>;
export const EditUserProfileDocument = gql`
  mutation EditUserProfile($updateMyUserInput: UpdateMyUserInput!) {
    updateMyUser(input: $updateMyUserInput) {
      user {
        id
        fullName
        email
        language
        defaultKeywordsPage
        defaultCompareTo
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type EditUserProfileMutationFn = Apollo.MutationFunction<
  EditUserProfileMutation,
  EditUserProfileMutationVariables
>;

/**
 * __useEditUserProfileMutation__
 *
 * To run a mutation, you first call `useEditUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserProfileMutation, { data, loading, error }] = useEditUserProfileMutation({
 *   variables: {
 *      updateMyUserInput: // value for 'updateMyUserInput'
 *   },
 * });
 */
export function useEditUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditUserProfileMutation,
    EditUserProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditUserProfileMutation, EditUserProfileMutationVariables>(
    EditUserProfileDocument,
    options,
  );
}
export type EditUserProfileMutationHookResult = ReturnType<typeof useEditUserProfileMutation>;
export type EditUserProfileMutationResult = Apollo.MutationResult<EditUserProfileMutation>;
export type EditUserProfileMutationOptions = Apollo.BaseMutationOptions<
  EditUserProfileMutation,
  EditUserProfileMutationVariables
>;
export const EditProfileResetApiTokenDocument = gql`
  mutation EditProfileResetApiToken($updateMyUserInput: UpdateMyUserInput!) {
    updateMyUser(input: $updateMyUserInput) {
      errors {
        field
        messages
      }
    }
  }
`;
export type EditProfileResetApiTokenMutationFn = Apollo.MutationFunction<
  EditProfileResetApiTokenMutation,
  EditProfileResetApiTokenMutationVariables
>;

/**
 * __useEditProfileResetApiTokenMutation__
 *
 * To run a mutation, you first call `useEditProfileResetApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileResetApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileResetApiTokenMutation, { data, loading, error }] = useEditProfileResetApiTokenMutation({
 *   variables: {
 *      updateMyUserInput: // value for 'updateMyUserInput'
 *   },
 * });
 */
export function useEditProfileResetApiTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProfileResetApiTokenMutation,
    EditProfileResetApiTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditProfileResetApiTokenMutation,
    EditProfileResetApiTokenMutationVariables
  >(EditProfileResetApiTokenDocument, options);
}
export type EditProfileResetApiTokenMutationHookResult = ReturnType<
  typeof useEditProfileResetApiTokenMutation
>;
export type EditProfileResetApiTokenMutationResult =
  Apollo.MutationResult<EditProfileResetApiTokenMutation>;
export type EditProfileResetApiTokenMutationOptions = Apollo.BaseMutationOptions<
  EditProfileResetApiTokenMutation,
  EditProfileResetApiTokenMutationVariables
>;
export const EditProfileRemoveDriveConnectionDocument = gql`
  mutation EditProfileRemoveDriveConnection {
    removeDriveConnection {
      user {
        id
        googleConnections {
          id
        }
      }
    }
  }
`;
export type EditProfileRemoveDriveConnectionMutationFn = Apollo.MutationFunction<
  EditProfileRemoveDriveConnectionMutation,
  EditProfileRemoveDriveConnectionMutationVariables
>;

/**
 * __useEditProfileRemoveDriveConnectionMutation__
 *
 * To run a mutation, you first call `useEditProfileRemoveDriveConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProfileRemoveDriveConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProfileRemoveDriveConnectionMutation, { data, loading, error }] = useEditProfileRemoveDriveConnectionMutation({
 *   variables: {
 *   },
 * });
 */
export function useEditProfileRemoveDriveConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditProfileRemoveDriveConnectionMutation,
    EditProfileRemoveDriveConnectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditProfileRemoveDriveConnectionMutation,
    EditProfileRemoveDriveConnectionMutationVariables
  >(EditProfileRemoveDriveConnectionDocument, options);
}
export type EditProfileRemoveDriveConnectionMutationHookResult = ReturnType<
  typeof useEditProfileRemoveDriveConnectionMutation
>;
export type EditProfileRemoveDriveConnectionMutationResult =
  Apollo.MutationResult<EditProfileRemoveDriveConnectionMutation>;
export type EditProfileRemoveDriveConnectionMutationOptions = Apollo.BaseMutationOptions<
  EditProfileRemoveDriveConnectionMutation,
  EditProfileRemoveDriveConnectionMutationVariables
>;
export const PublicOverviewHistoryGraphsDocument = gql`
  query PublicOverviewHistoryGraphs(
    $filters: [FilterInput]!
    $domainSlug: String!
    $viewkey: String!
    $charts: [String]
  ) {
    graphs(filters: $filters) {
      publicOverviewHistoryGraphs(
        viewkey: $viewkey
        slug: $domainSlug
        charts: $charts
        allHistory: true
      )
    }
  }
`;

/**
 * __usePublicOverviewHistoryGraphsQuery__
 *
 * To run a query within a React component, call `usePublicOverviewHistoryGraphsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicOverviewHistoryGraphsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicOverviewHistoryGraphsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      domainSlug: // value for 'domainSlug'
 *      viewkey: // value for 'viewkey'
 *      charts: // value for 'charts'
 *   },
 * });
 */
export function usePublicOverviewHistoryGraphsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicOverviewHistoryGraphsQuery,
    PublicOverviewHistoryGraphsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PublicOverviewHistoryGraphsQuery,
    PublicOverviewHistoryGraphsQueryVariables
  >(PublicOverviewHistoryGraphsDocument, options);
}
export function usePublicOverviewHistoryGraphsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicOverviewHistoryGraphsQuery,
    PublicOverviewHistoryGraphsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicOverviewHistoryGraphsQuery,
    PublicOverviewHistoryGraphsQueryVariables
  >(PublicOverviewHistoryGraphsDocument, options);
}
export type PublicOverviewHistoryGraphsQueryHookResult = ReturnType<
  typeof usePublicOverviewHistoryGraphsQuery
>;
export type PublicOverviewHistoryGraphsLazyQueryHookResult = ReturnType<
  typeof usePublicOverviewHistoryGraphsLazyQuery
>;
export type PublicOverviewHistoryGraphsQueryResult = Apollo.QueryResult<
  PublicOverviewHistoryGraphsQuery,
  PublicOverviewHistoryGraphsQueryVariables
>;
export const PublicOverviewSearchIntentDocument = gql`
  query PublicOverviewSearchIntent(
    $filters: [FilterInput]!
    $domainSlug: String!
    $viewkey: String!
  ) {
    graphs(filters: $filters) {
      publicOverviewSearchIntent(viewkey: $viewkey, slug: $domainSlug, allHistory: true) {
        I
        N
        T
        C
        keywordCount
      }
    }
  }
`;

/**
 * __usePublicOverviewSearchIntentQuery__
 *
 * To run a query within a React component, call `usePublicOverviewSearchIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicOverviewSearchIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicOverviewSearchIntentQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      domainSlug: // value for 'domainSlug'
 *      viewkey: // value for 'viewkey'
 *   },
 * });
 */
export function usePublicOverviewSearchIntentQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicOverviewSearchIntentQuery,
    PublicOverviewSearchIntentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PublicOverviewSearchIntentQuery, PublicOverviewSearchIntentQueryVariables>(
    PublicOverviewSearchIntentDocument,
    options,
  );
}
export function usePublicOverviewSearchIntentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicOverviewSearchIntentQuery,
    PublicOverviewSearchIntentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicOverviewSearchIntentQuery,
    PublicOverviewSearchIntentQueryVariables
  >(PublicOverviewSearchIntentDocument, options);
}
export type PublicOverviewSearchIntentQueryHookResult = ReturnType<
  typeof usePublicOverviewSearchIntentQuery
>;
export type PublicOverviewSearchIntentLazyQueryHookResult = ReturnType<
  typeof usePublicOverviewSearchIntentLazyQuery
>;
export type PublicOverviewSearchIntentQueryResult = Apollo.QueryResult<
  PublicOverviewSearchIntentQuery,
  PublicOverviewSearchIntentQueryVariables
>;
export const GetAllPublicKpisDocument = gql`
  query GetAllPublicKpis($filters: [FilterInput]!, $viewkey: String!, $domainSlug: String!) {
    kpis(filters: $filters) {
      allKpis: publicAllKpis(viewkey: $viewkey, slug: $domainSlug) {
        keywords
        keywordsBefore
        trafficValue
        trafficValueBefore
        maxTrafficValue
        shareOfVoiceSum
        shareOfVoiceSumBefore
        maxShareOfVoiceSum
        aiShareOfVoiceSum
        aiShareOfVoiceSumBefore
        maxAiShareOfVoiceSum
        avgRank
        avgRankBefore
        aboveTheFold
        aboveTheFoldBefore
        ctr
        ctrBefore
        maxCtr
        aiTrafficValue
        aiTrafficValueBefore
        maxAiTrafficValue
        avgBaseRank
        avgBaseRankBefore
        avgMaxBaseRank
        pixelsFromTop
        pixelsFromTopBefore
        searchVolume
        searchVolumeBefore
        aiSearchVolume
        aiSearchVolumeBefore
        top1
        top1Before
        top3
        top3Before
        firstPage
        firstPageBefore
        movedUp
        movedDown
      }
      displayCurrency
    }
  }
`;

/**
 * __useGetAllPublicKpisQuery__
 *
 * To run a query within a React component, call `useGetAllPublicKpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPublicKpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPublicKpisQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      viewkey: // value for 'viewkey'
 *      domainSlug: // value for 'domainSlug'
 *   },
 * });
 */
export function useGetAllPublicKpisQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllPublicKpisQuery, GetAllPublicKpisQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPublicKpisQuery, GetAllPublicKpisQueryVariables>(
    GetAllPublicKpisDocument,
    options,
  );
}
export function useGetAllPublicKpisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllPublicKpisQuery, GetAllPublicKpisQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPublicKpisQuery, GetAllPublicKpisQueryVariables>(
    GetAllPublicKpisDocument,
    options,
  );
}
export type GetAllPublicKpisQueryHookResult = ReturnType<typeof useGetAllPublicKpisQuery>;
export type GetAllPublicKpisLazyQueryHookResult = ReturnType<typeof useGetAllPublicKpisLazyQuery>;
export type GetAllPublicKpisQueryResult = Apollo.QueryResult<
  GetAllPublicKpisQuery,
  GetAllPublicKpisQueryVariables
>;
export const MetaDataDocument = gql`
  query MetaData {
    metaData {
      ...PublicMetaData
    }
  }
  ${PublicMetaDataFragmentDoc}
`;

/**
 * __useMetaDataQuery__
 *
 * To run a query within a React component, call `useMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetaDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMetaDataQuery(
  baseOptions?: Apollo.QueryHookOptions<MetaDataQuery, MetaDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MetaDataQuery, MetaDataQueryVariables>(MetaDataDocument, options);
}
export function useMetaDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MetaDataQuery, MetaDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MetaDataQuery, MetaDataQueryVariables>(MetaDataDocument, options);
}
export type MetaDataQueryHookResult = ReturnType<typeof useMetaDataQuery>;
export type MetaDataLazyQueryHookResult = ReturnType<typeof useMetaDataLazyQuery>;
export type MetaDataQueryResult = Apollo.QueryResult<MetaDataQuery, MetaDataQueryVariables>;
export const GetPublicReportSettingsDocument = gql`
  query GetPublicReportSettings($viewkey: String!, $domainSlug: String!) {
    publicReportSettings(viewkey: $viewkey, domainSlug: $domainSlug) {
      ...PublicReportSettings
    }
  }
  ${PublicReportSettingsFragmentDoc}
`;

/**
 * __useGetPublicReportSettingsQuery__
 *
 * To run a query within a React component, call `useGetPublicReportSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicReportSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicReportSettingsQuery({
 *   variables: {
 *      viewkey: // value for 'viewkey'
 *      domainSlug: // value for 'domainSlug'
 *   },
 * });
 */
export function useGetPublicReportSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicReportSettingsQuery,
    GetPublicReportSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPublicReportSettingsQuery, GetPublicReportSettingsQueryVariables>(
    GetPublicReportSettingsDocument,
    options,
  );
}
export function useGetPublicReportSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicReportSettingsQuery,
    GetPublicReportSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPublicReportSettingsQuery, GetPublicReportSettingsQueryVariables>(
    GetPublicReportSettingsDocument,
    options,
  );
}
export type GetPublicReportSettingsQueryHookResult = ReturnType<
  typeof useGetPublicReportSettingsQuery
>;
export type GetPublicReportSettingsLazyQueryHookResult = ReturnType<
  typeof useGetPublicReportSettingsLazyQuery
>;
export type GetPublicReportSettingsQueryResult = Apollo.QueryResult<
  GetPublicReportSettingsQuery,
  GetPublicReportSettingsQueryVariables
>;
export const UpdatePublicReportSettingsDocument = gql`
  mutation UpdatePublicReportSettings($input: PublicReportSettingsInput!) {
    updatePublicReportSettings(input: $input) {
      publicReportSettings {
        id
      }
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type UpdatePublicReportSettingsMutationFn = Apollo.MutationFunction<
  UpdatePublicReportSettingsMutation,
  UpdatePublicReportSettingsMutationVariables
>;

/**
 * __useUpdatePublicReportSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePublicReportSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicReportSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicReportSettingsMutation, { data, loading, error }] = useUpdatePublicReportSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublicReportSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePublicReportSettingsMutation,
    UpdatePublicReportSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePublicReportSettingsMutation,
    UpdatePublicReportSettingsMutationVariables
  >(UpdatePublicReportSettingsDocument, options);
}
export type UpdatePublicReportSettingsMutationHookResult = ReturnType<
  typeof useUpdatePublicReportSettingsMutation
>;
export type UpdatePublicReportSettingsMutationResult =
  Apollo.MutationResult<UpdatePublicReportSettingsMutation>;
export type UpdatePublicReportSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePublicReportSettingsMutation,
  UpdatePublicReportSettingsMutationVariables
>;
export const PublicDomainDocument = gql`
  query publicDomain($viewkey: String!, $domainSlug: String!) {
    publicDomain(viewkey: $viewkey, slug: $domainSlug) {
      ...PublicDomain
    }
  }
  ${PublicDomainFragmentDoc}
`;

/**
 * __usePublicDomainQuery__
 *
 * To run a query within a React component, call `usePublicDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicDomainQuery({
 *   variables: {
 *      viewkey: // value for 'viewkey'
 *      domainSlug: // value for 'domainSlug'
 *   },
 * });
 */
export function usePublicDomainQuery(
  baseOptions: Apollo.QueryHookOptions<PublicDomainQuery, PublicDomainQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PublicDomainQuery, PublicDomainQueryVariables>(
    PublicDomainDocument,
    options,
  );
}
export function usePublicDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PublicDomainQuery, PublicDomainQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PublicDomainQuery, PublicDomainQueryVariables>(
    PublicDomainDocument,
    options,
  );
}
export type PublicDomainQueryHookResult = ReturnType<typeof usePublicDomainQuery>;
export type PublicDomainLazyQueryHookResult = ReturnType<typeof usePublicDomainLazyQuery>;
export type PublicDomainQueryResult = Apollo.QueryResult<
  PublicDomainQuery,
  PublicDomainQueryVariables
>;
export const GetPublicKeywordHistoryDocument = gql`
  query GetPublicKeywordHistory(
    $filters: [FilterInput]!
    $viewkey: String!
    $slug: String!
    $keywordId: ID!
  ) {
    graphs(filters: $filters) {
      publicKeywordHistory(keywordId: $keywordId, viewkey: $viewkey, slug: $slug, allHistory: true)
    }
  }
`;

/**
 * __useGetPublicKeywordHistoryQuery__
 *
 * To run a query within a React component, call `useGetPublicKeywordHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicKeywordHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicKeywordHistoryQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      viewkey: // value for 'viewkey'
 *      slug: // value for 'slug'
 *      keywordId: // value for 'keywordId'
 *   },
 * });
 */
export function useGetPublicKeywordHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicKeywordHistoryQuery,
    GetPublicKeywordHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPublicKeywordHistoryQuery, GetPublicKeywordHistoryQueryVariables>(
    GetPublicKeywordHistoryDocument,
    options,
  );
}
export function useGetPublicKeywordHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicKeywordHistoryQuery,
    GetPublicKeywordHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPublicKeywordHistoryQuery, GetPublicKeywordHistoryQueryVariables>(
    GetPublicKeywordHistoryDocument,
    options,
  );
}
export type GetPublicKeywordHistoryQueryHookResult = ReturnType<
  typeof useGetPublicKeywordHistoryQuery
>;
export type GetPublicKeywordHistoryLazyQueryHookResult = ReturnType<
  typeof useGetPublicKeywordHistoryLazyQuery
>;
export type GetPublicKeywordHistoryQueryResult = Apollo.QueryResult<
  GetPublicKeywordHistoryQuery,
  GetPublicKeywordHistoryQueryVariables
>;
export const GetPublicTableKeywordsDocument = gql`
  query GetPublicTableKeywords(
    $filters: [FilterInput]!
    $pagination: PaginationInput!
    $ordering: OrderingInput!
    $viewkey: String!
    $slug: String!
    $withSearchEngineId: Boolean!
    $withSearchTypeId: Boolean!
    $withSearchIntent: Boolean!
    $withCountrylocaleId: Boolean!
    $withLocation: Boolean!
    $withRankId: Boolean!
    $withRankValue: Boolean!
    $withRankCompare: Boolean!
    $withSearchVolumeValue: Boolean!
    $withValidForSearchVolume: Boolean!
    $withDynamicCtr: Boolean!
  ) {
    publicTableKeywords(
      filters: $filters
      pagination: $pagination
      ordering: $ordering
      viewkey: $viewkey
      slug: $slug
      withSearchEngineId: $withSearchEngineId
      withSearchTypeId: $withSearchTypeId
      withSearchIntent: $withSearchIntent
      withCountrylocaleId: $withCountrylocaleId
      withLocation: $withLocation
      withRankId: $withRankId
      withRankValue: $withRankValue
      withRankCompare: $withRankCompare
      withSearchVolumeValue: $withSearchVolumeValue
      withValidForSearchVolume: $withValidForSearchVolume
      withDynamicCtr: $withDynamicCtr
    ) {
      keywords
      pagination {
        numResults
        startIndex
        stopIndex
      }
    }
  }
`;

/**
 * __useGetPublicTableKeywordsQuery__
 *
 * To run a query within a React component, call `useGetPublicTableKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicTableKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicTableKeywordsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      ordering: // value for 'ordering'
 *      viewkey: // value for 'viewkey'
 *      slug: // value for 'slug'
 *      withSearchEngineId: // value for 'withSearchEngineId'
 *      withSearchTypeId: // value for 'withSearchTypeId'
 *      withSearchIntent: // value for 'withSearchIntent'
 *      withCountrylocaleId: // value for 'withCountrylocaleId'
 *      withLocation: // value for 'withLocation'
 *      withRankId: // value for 'withRankId'
 *      withRankValue: // value for 'withRankValue'
 *      withRankCompare: // value for 'withRankCompare'
 *      withSearchVolumeValue: // value for 'withSearchVolumeValue'
 *      withValidForSearchVolume: // value for 'withValidForSearchVolume'
 *      withDynamicCtr: // value for 'withDynamicCtr'
 *   },
 * });
 */
export function useGetPublicTableKeywordsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicTableKeywordsQuery,
    GetPublicTableKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPublicTableKeywordsQuery, GetPublicTableKeywordsQueryVariables>(
    GetPublicTableKeywordsDocument,
    options,
  );
}
export function useGetPublicTableKeywordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicTableKeywordsQuery,
    GetPublicTableKeywordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPublicTableKeywordsQuery, GetPublicTableKeywordsQueryVariables>(
    GetPublicTableKeywordsDocument,
    options,
  );
}
export type GetPublicTableKeywordsQueryHookResult = ReturnType<
  typeof useGetPublicTableKeywordsQuery
>;
export type GetPublicTableKeywordsLazyQueryHookResult = ReturnType<
  typeof useGetPublicTableKeywordsLazyQuery
>;
export type GetPublicTableKeywordsQueryResult = Apollo.QueryResult<
  GetPublicTableKeywordsQuery,
  GetPublicTableKeywordsQueryVariables
>;
export const RegisterPagePayNowPlanDocument = gql`
  query registerPagePayNowPlan {
    payNowPlan {
      id
      priceMonthly
      maxKeywords
      category
      signonDiscount
      signonDiscountMonths
      currency
      nextPlanAfterTrial {
        priceMonthly
        maxKeywords
        category
        signonDiscountUsd
        signonDiscountEur
        signonDiscountDkk
        signonDiscountMonths
        __typename
      }
    }
  }
`;

/**
 * __useRegisterPagePayNowPlanQuery__
 *
 * To run a query within a React component, call `useRegisterPagePayNowPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterPagePayNowPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterPagePayNowPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegisterPagePayNowPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegisterPagePayNowPlanQuery,
    RegisterPagePayNowPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegisterPagePayNowPlanQuery, RegisterPagePayNowPlanQueryVariables>(
    RegisterPagePayNowPlanDocument,
    options,
  );
}
export function useRegisterPagePayNowPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisterPagePayNowPlanQuery,
    RegisterPagePayNowPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegisterPagePayNowPlanQuery, RegisterPagePayNowPlanQueryVariables>(
    RegisterPagePayNowPlanDocument,
    options,
  );
}
export type RegisterPagePayNowPlanQueryHookResult = ReturnType<
  typeof useRegisterPagePayNowPlanQuery
>;
export type RegisterPagePayNowPlanLazyQueryHookResult = ReturnType<
  typeof useRegisterPagePayNowPlanLazyQuery
>;
export type RegisterPagePayNowPlanQueryResult = Apollo.QueryResult<
  RegisterPagePayNowPlanQuery,
  RegisterPagePayNowPlanQueryVariables
>;
export const RegisterPageUnconfirmedUserDocument = gql`
  query registerPageUnconfirmedUser($id: String!) {
    unconfirmedUser(id: $id) {
      id
      email
      createdByCountry
      createdByIp
      signupType
      trialDays
      phoneNumber
      phoneNumberVerificationSid
      phoneNumberVerified
      prefillFullName
      isSocialSignup
      referer
    }
  }
`;

/**
 * __useRegisterPageUnconfirmedUserQuery__
 *
 * To run a query within a React component, call `useRegisterPageUnconfirmedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterPageUnconfirmedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterPageUnconfirmedUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegisterPageUnconfirmedUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegisterPageUnconfirmedUserQuery,
    RegisterPageUnconfirmedUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegisterPageUnconfirmedUserQuery,
    RegisterPageUnconfirmedUserQueryVariables
  >(RegisterPageUnconfirmedUserDocument, options);
}
export function useRegisterPageUnconfirmedUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisterPageUnconfirmedUserQuery,
    RegisterPageUnconfirmedUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegisterPageUnconfirmedUserQuery,
    RegisterPageUnconfirmedUserQueryVariables
  >(RegisterPageUnconfirmedUserDocument, options);
}
export type RegisterPageUnconfirmedUserQueryHookResult = ReturnType<
  typeof useRegisterPageUnconfirmedUserQuery
>;
export type RegisterPageUnconfirmedUserLazyQueryHookResult = ReturnType<
  typeof useRegisterPageUnconfirmedUserLazyQuery
>;
export type RegisterPageUnconfirmedUserQueryResult = Apollo.QueryResult<
  RegisterPageUnconfirmedUserQuery,
  RegisterPageUnconfirmedUserQueryVariables
>;
export const RegisterPagePricingPlanDocument = gql`
  query registerPagePricingPlan($id: ID!) {
    pricingPlan(id: $id) {
      id
      priceMonthly
      maxKeywords
      category
      signonDiscount
      signonDiscountMonths
      currency
      nextPlanAfterTrial {
        priceMonthly
        maxKeywords
        category
        signonDiscountUsd
        signonDiscountEur
        signonDiscountDkk
        signonDiscountMonths
      }
    }
  }
`;

/**
 * __useRegisterPagePricingPlanQuery__
 *
 * To run a query within a React component, call `useRegisterPagePricingPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterPagePricingPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterPagePricingPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegisterPagePricingPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegisterPagePricingPlanQuery,
    RegisterPagePricingPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegisterPagePricingPlanQuery, RegisterPagePricingPlanQueryVariables>(
    RegisterPagePricingPlanDocument,
    options,
  );
}
export function useRegisterPagePricingPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisterPagePricingPlanQuery,
    RegisterPagePricingPlanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegisterPagePricingPlanQuery, RegisterPagePricingPlanQueryVariables>(
    RegisterPagePricingPlanDocument,
    options,
  );
}
export type RegisterPagePricingPlanQueryHookResult = ReturnType<
  typeof useRegisterPagePricingPlanQuery
>;
export type RegisterPagePricingPlanLazyQueryHookResult = ReturnType<
  typeof useRegisterPagePricingPlanLazyQuery
>;
export type RegisterPagePricingPlanQueryResult = Apollo.QueryResult<
  RegisterPagePricingPlanQuery,
  RegisterPagePricingPlanQueryVariables
>;
export const RegisterPageCountriesDocument = gql`
  query registerPageCountries {
    countries {
      id
      name
      isEu
      vatCode
    }
  }
`;

/**
 * __useRegisterPageCountriesQuery__
 *
 * To run a query within a React component, call `useRegisterPageCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterPageCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterPageCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegisterPageCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegisterPageCountriesQuery,
    RegisterPageCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegisterPageCountriesQuery, RegisterPageCountriesQueryVariables>(
    RegisterPageCountriesDocument,
    options,
  );
}
export function useRegisterPageCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisterPageCountriesQuery,
    RegisterPageCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegisterPageCountriesQuery, RegisterPageCountriesQueryVariables>(
    RegisterPageCountriesDocument,
    options,
  );
}
export type RegisterPageCountriesQueryHookResult = ReturnType<typeof useRegisterPageCountriesQuery>;
export type RegisterPageCountriesLazyQueryHookResult = ReturnType<
  typeof useRegisterPageCountriesLazyQuery
>;
export type RegisterPageCountriesQueryResult = Apollo.QueryResult<
  RegisterPageCountriesQuery,
  RegisterPageCountriesQueryVariables
>;
export const RegisterPageAccountInfoUserLogoutDocument = gql`
  mutation registerPageAccountInfoUserLogout {
    userLogout {
      success
    }
  }
`;
export type RegisterPageAccountInfoUserLogoutMutationFn = Apollo.MutationFunction<
  RegisterPageAccountInfoUserLogoutMutation,
  RegisterPageAccountInfoUserLogoutMutationVariables
>;

/**
 * __useRegisterPageAccountInfoUserLogoutMutation__
 *
 * To run a mutation, you first call `useRegisterPageAccountInfoUserLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPageAccountInfoUserLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPageAccountInfoUserLogoutMutation, { data, loading, error }] = useRegisterPageAccountInfoUserLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegisterPageAccountInfoUserLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPageAccountInfoUserLogoutMutation,
    RegisterPageAccountInfoUserLogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterPageAccountInfoUserLogoutMutation,
    RegisterPageAccountInfoUserLogoutMutationVariables
  >(RegisterPageAccountInfoUserLogoutDocument, options);
}
export type RegisterPageAccountInfoUserLogoutMutationHookResult = ReturnType<
  typeof useRegisterPageAccountInfoUserLogoutMutation
>;
export type RegisterPageAccountInfoUserLogoutMutationResult =
  Apollo.MutationResult<RegisterPageAccountInfoUserLogoutMutation>;
export type RegisterPageAccountInfoUserLogoutMutationOptions = Apollo.BaseMutationOptions<
  RegisterPageAccountInfoUserLogoutMutation,
  RegisterPageAccountInfoUserLogoutMutationVariables
>;
export const RegisterPageUserRegisterDocument = gql`
  mutation registerPageUserRegister($input: RegisterInput!) {
    userRegister(input: $input) {
      errors {
        field
        messages
      }
      token
    }
  }
`;
export type RegisterPageUserRegisterMutationFn = Apollo.MutationFunction<
  RegisterPageUserRegisterMutation,
  RegisterPageUserRegisterMutationVariables
>;

/**
 * __useRegisterPageUserRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterPageUserRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPageUserRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPageUserRegisterMutation, { data, loading, error }] = useRegisterPageUserRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPageUserRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterPageUserRegisterMutation,
    RegisterPageUserRegisterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterPageUserRegisterMutation,
    RegisterPageUserRegisterMutationVariables
  >(RegisterPageUserRegisterDocument, options);
}
export type RegisterPageUserRegisterMutationHookResult = ReturnType<
  typeof useRegisterPageUserRegisterMutation
>;
export type RegisterPageUserRegisterMutationResult =
  Apollo.MutationResult<RegisterPageUserRegisterMutation>;
export type RegisterPageUserRegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterPageUserRegisterMutation,
  RegisterPageUserRegisterMutationVariables
>;
export const UpdateWizardAccountInfoDocument = gql`
  mutation UpdateWizardAccountInfo($input: RegisterCompleteWizardInput!) {
    userRegisterCompleteWizard(input: $input) {
      errors {
        field
        messages
      }
      token
      domainId
      user {
        id
        organization {
          id
          wizardShouldBeShown
        }
      }
    }
  }
`;
export type UpdateWizardAccountInfoMutationFn = Apollo.MutationFunction<
  UpdateWizardAccountInfoMutation,
  UpdateWizardAccountInfoMutationVariables
>;

/**
 * __useUpdateWizardAccountInfoMutation__
 *
 * To run a mutation, you first call `useUpdateWizardAccountInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWizardAccountInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWizardAccountInfoMutation, { data, loading, error }] = useUpdateWizardAccountInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWizardAccountInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWizardAccountInfoMutation,
    UpdateWizardAccountInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWizardAccountInfoMutation,
    UpdateWizardAccountInfoMutationVariables
  >(UpdateWizardAccountInfoDocument, options);
}
export type UpdateWizardAccountInfoMutationHookResult = ReturnType<
  typeof useUpdateWizardAccountInfoMutation
>;
export type UpdateWizardAccountInfoMutationResult =
  Apollo.MutationResult<UpdateWizardAccountInfoMutation>;
export type UpdateWizardAccountInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateWizardAccountInfoMutation,
  UpdateWizardAccountInfoMutationVariables
>;
export const AddReportTemplateDocument = gql`
  mutation AddReportTemplate($input: AddReportTemplateInput!) {
    addReportTemplate(input: $input) {
      reportTemplate {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type AddReportTemplateMutationFn = Apollo.MutationFunction<
  AddReportTemplateMutation,
  AddReportTemplateMutationVariables
>;

/**
 * __useAddReportTemplateMutation__
 *
 * To run a mutation, you first call `useAddReportTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReportTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReportTemplateMutation, { data, loading, error }] = useAddReportTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddReportTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddReportTemplateMutation,
    AddReportTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddReportTemplateMutation, AddReportTemplateMutationVariables>(
    AddReportTemplateDocument,
    options,
  );
}
export type AddReportTemplateMutationHookResult = ReturnType<typeof useAddReportTemplateMutation>;
export type AddReportTemplateMutationResult = Apollo.MutationResult<AddReportTemplateMutation>;
export type AddReportTemplateMutationOptions = Apollo.BaseMutationOptions<
  AddReportTemplateMutation,
  AddReportTemplateMutationVariables
>;
export const EditReportTemplateDocument = gql`
  query EditReportTemplate {
    user {
      id
      organization {
        id
      }
    }
  }
`;

/**
 * __useEditReportTemplateQuery__
 *
 * To run a query within a React component, call `useEditReportTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditReportTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditReportTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditReportTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<EditReportTemplateQuery, EditReportTemplateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EditReportTemplateQuery, EditReportTemplateQueryVariables>(
    EditReportTemplateDocument,
    options,
  );
}
export function useEditReportTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EditReportTemplateQuery,
    EditReportTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EditReportTemplateQuery, EditReportTemplateQueryVariables>(
    EditReportTemplateDocument,
    options,
  );
}
export type EditReportTemplateQueryHookResult = ReturnType<typeof useEditReportTemplateQuery>;
export type EditReportTemplateLazyQueryHookResult = ReturnType<
  typeof useEditReportTemplateLazyQuery
>;
export type EditReportTemplateQueryResult = Apollo.QueryResult<
  EditReportTemplateQuery,
  EditReportTemplateQueryVariables
>;
export const ReportTemplateByIdDocument = gql`
  query ReportTemplateById($id: ID!) {
    reportTemplate(id: $id) {
      id
      name
      systemTemplate
      name
      brandColor
      template
    }
  }
`;

/**
 * __useReportTemplateByIdQuery__
 *
 * To run a query within a React component, call `useReportTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTemplateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportTemplateByIdQuery(
  baseOptions: Apollo.QueryHookOptions<ReportTemplateByIdQuery, ReportTemplateByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportTemplateByIdQuery, ReportTemplateByIdQueryVariables>(
    ReportTemplateByIdDocument,
    options,
  );
}
export function useReportTemplateByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportTemplateByIdQuery,
    ReportTemplateByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportTemplateByIdQuery, ReportTemplateByIdQueryVariables>(
    ReportTemplateByIdDocument,
    options,
  );
}
export type ReportTemplateByIdQueryHookResult = ReturnType<typeof useReportTemplateByIdQuery>;
export type ReportTemplateByIdLazyQueryHookResult = ReturnType<
  typeof useReportTemplateByIdLazyQuery
>;
export type ReportTemplateByIdQueryResult = Apollo.QueryResult<
  ReportTemplateByIdQuery,
  ReportTemplateByIdQueryVariables
>;
export const ReportTemplateUserOrgDocument = gql`
  query ReportTemplateUserOrg {
    user {
      id
      organization {
        id
      }
    }
  }
`;

/**
 * __useReportTemplateUserOrgQuery__
 *
 * To run a query within a React component, call `useReportTemplateUserOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTemplateUserOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTemplateUserOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportTemplateUserOrgQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReportTemplateUserOrgQuery,
    ReportTemplateUserOrgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportTemplateUserOrgQuery, ReportTemplateUserOrgQueryVariables>(
    ReportTemplateUserOrgDocument,
    options,
  );
}
export function useReportTemplateUserOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReportTemplateUserOrgQuery,
    ReportTemplateUserOrgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportTemplateUserOrgQuery, ReportTemplateUserOrgQueryVariables>(
    ReportTemplateUserOrgDocument,
    options,
  );
}
export type ReportTemplateUserOrgQueryHookResult = ReturnType<typeof useReportTemplateUserOrgQuery>;
export type ReportTemplateUserOrgLazyQueryHookResult = ReturnType<
  typeof useReportTemplateUserOrgLazyQuery
>;
export type ReportTemplateUserOrgQueryResult = Apollo.QueryResult<
  ReportTemplateUserOrgQuery,
  ReportTemplateUserOrgQueryVariables
>;
export const UpdateReportTemplateDocument = gql`
  mutation UpdateReportTemplate($input: UpdateReportTemplateInput!) {
    updateReportTemplate(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type UpdateReportTemplateMutationFn = Apollo.MutationFunction<
  UpdateReportTemplateMutation,
  UpdateReportTemplateMutationVariables
>;

/**
 * __useUpdateReportTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateReportTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportTemplateMutation, { data, loading, error }] = useUpdateReportTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReportTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReportTemplateMutation,
    UpdateReportTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateReportTemplateMutation, UpdateReportTemplateMutationVariables>(
    UpdateReportTemplateDocument,
    options,
  );
}
export type UpdateReportTemplateMutationHookResult = ReturnType<
  typeof useUpdateReportTemplateMutation
>;
export type UpdateReportTemplateMutationResult =
  Apollo.MutationResult<UpdateReportTemplateMutation>;
export type UpdateReportTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateReportTemplateMutation,
  UpdateReportTemplateMutationVariables
>;
export const DefaultReportTemplateDocument = gql`
  query DefaultReportTemplate {
    user {
      id
      organization {
        id
        defaultReportTemplate {
          id
        }
      }
    }
  }
`;

/**
 * __useDefaultReportTemplateQuery__
 *
 * To run a query within a React component, call `useDefaultReportTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultReportTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultReportTemplateQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultReportTemplateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DefaultReportTemplateQuery,
    DefaultReportTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DefaultReportTemplateQuery, DefaultReportTemplateQueryVariables>(
    DefaultReportTemplateDocument,
    options,
  );
}
export function useDefaultReportTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DefaultReportTemplateQuery,
    DefaultReportTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DefaultReportTemplateQuery, DefaultReportTemplateQueryVariables>(
    DefaultReportTemplateDocument,
    options,
  );
}
export type DefaultReportTemplateQueryHookResult = ReturnType<typeof useDefaultReportTemplateQuery>;
export type DefaultReportTemplateLazyQueryHookResult = ReturnType<
  typeof useDefaultReportTemplateLazyQuery
>;
export type DefaultReportTemplateQueryResult = Apollo.QueryResult<
  DefaultReportTemplateQuery,
  DefaultReportTemplateQueryVariables
>;
export const ReportTemplatesDocument = gql`
  query ReportTemplates {
    reportTemplates {
      ...ReportTemplate
    }
  }
  ${ReportTemplateFragmentDoc}
`;

/**
 * __useReportTemplatesQuery__
 *
 * To run a query within a React component, call `useReportTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<ReportTemplatesQuery, ReportTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportTemplatesQuery, ReportTemplatesQueryVariables>(
    ReportTemplatesDocument,
    options,
  );
}
export function useReportTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportTemplatesQuery, ReportTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportTemplatesQuery, ReportTemplatesQueryVariables>(
    ReportTemplatesDocument,
    options,
  );
}
export type ReportTemplatesQueryHookResult = ReturnType<typeof useReportTemplatesQuery>;
export type ReportTemplatesLazyQueryHookResult = ReturnType<typeof useReportTemplatesLazyQuery>;
export type ReportTemplatesQueryResult = Apollo.QueryResult<
  ReportTemplatesQuery,
  ReportTemplatesQueryVariables
>;
export const ReportWidgetsDocument = gql`
  query ReportWidgets {
    reportWidgets
  }
`;

/**
 * __useReportWidgetsQuery__
 *
 * To run a query within a React component, call `useReportWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportWidgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportWidgetsQuery(
  baseOptions?: Apollo.QueryHookOptions<ReportWidgetsQuery, ReportWidgetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportWidgetsQuery, ReportWidgetsQueryVariables>(
    ReportWidgetsDocument,
    options,
  );
}
export function useReportWidgetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportWidgetsQuery, ReportWidgetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportWidgetsQuery, ReportWidgetsQueryVariables>(
    ReportWidgetsDocument,
    options,
  );
}
export type ReportWidgetsQueryHookResult = ReturnType<typeof useReportWidgetsQuery>;
export type ReportWidgetsLazyQueryHookResult = ReturnType<typeof useReportWidgetsLazyQuery>;
export type ReportWidgetsQueryResult = Apollo.QueryResult<
  ReportWidgetsQuery,
  ReportWidgetsQueryVariables
>;
export const DeleteGeneratedReportDocument = gql`
  mutation DeleteGeneratedReport($input: UpdateGeneratedReportInput!) {
    updateGeneratedReport(input: $input) {
      generatedReport {
        id
      }
    }
  }
`;
export type DeleteGeneratedReportMutationFn = Apollo.MutationFunction<
  DeleteGeneratedReportMutation,
  DeleteGeneratedReportMutationVariables
>;

/**
 * __useDeleteGeneratedReportMutation__
 *
 * To run a mutation, you first call `useDeleteGeneratedReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeneratedReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeneratedReportMutation, { data, loading, error }] = useDeleteGeneratedReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGeneratedReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGeneratedReportMutation,
    DeleteGeneratedReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGeneratedReportMutation, DeleteGeneratedReportMutationVariables>(
    DeleteGeneratedReportDocument,
    options,
  );
}
export type DeleteGeneratedReportMutationHookResult = ReturnType<
  typeof useDeleteGeneratedReportMutation
>;
export type DeleteGeneratedReportMutationResult =
  Apollo.MutationResult<DeleteGeneratedReportMutation>;
export type DeleteGeneratedReportMutationOptions = Apollo.BaseMutationOptions<
  DeleteGeneratedReportMutation,
  DeleteGeneratedReportMutationVariables
>;
export const GeneratedReportsDocument = gql`
  query GeneratedReports {
    generatedReports {
      ...GeneratedReport
    }
  }
  ${GeneratedReportFragmentDoc}
`;

/**
 * __useGeneratedReportsQuery__
 *
 * To run a query within a React component, call `useGeneratedReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratedReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratedReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeneratedReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<GeneratedReportsQuery, GeneratedReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GeneratedReportsQuery, GeneratedReportsQueryVariables>(
    GeneratedReportsDocument,
    options,
  );
}
export function useGeneratedReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GeneratedReportsQuery, GeneratedReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GeneratedReportsQuery, GeneratedReportsQueryVariables>(
    GeneratedReportsDocument,
    options,
  );
}
export type GeneratedReportsQueryHookResult = ReturnType<typeof useGeneratedReportsQuery>;
export type GeneratedReportsLazyQueryHookResult = ReturnType<typeof useGeneratedReportsLazyQuery>;
export type GeneratedReportsQueryResult = Apollo.QueryResult<
  GeneratedReportsQuery,
  GeneratedReportsQueryVariables
>;
export const DeleteScheduledReportDocument = gql`
  mutation DeleteScheduledReport($input: DeleteScheduledReportInput!) {
    deleteScheduledReport(input: $input) {
      scheduledReport {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type DeleteScheduledReportMutationFn = Apollo.MutationFunction<
  DeleteScheduledReportMutation,
  DeleteScheduledReportMutationVariables
>;

/**
 * __useDeleteScheduledReportMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledReportMutation, { data, loading, error }] = useDeleteScheduledReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteScheduledReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScheduledReportMutation,
    DeleteScheduledReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteScheduledReportMutation, DeleteScheduledReportMutationVariables>(
    DeleteScheduledReportDocument,
    options,
  );
}
export type DeleteScheduledReportMutationHookResult = ReturnType<
  typeof useDeleteScheduledReportMutation
>;
export type DeleteScheduledReportMutationResult =
  Apollo.MutationResult<DeleteScheduledReportMutation>;
export type DeleteScheduledReportMutationOptions = Apollo.BaseMutationOptions<
  DeleteScheduledReportMutation,
  DeleteScheduledReportMutationVariables
>;
export const ScheduledReportsDocument = gql`
  query ScheduledReports {
    scheduledReports(isOneTimeReport: false) {
      ...ScheduledReport
    }
  }
  ${ScheduledReportFragmentDoc}
`;

/**
 * __useScheduledReportsQuery__
 *
 * To run a query within a React component, call `useScheduledReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useScheduledReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<ScheduledReportsQuery, ScheduledReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScheduledReportsQuery, ScheduledReportsQueryVariables>(
    ScheduledReportsDocument,
    options,
  );
}
export function useScheduledReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScheduledReportsQuery, ScheduledReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScheduledReportsQuery, ScheduledReportsQueryVariables>(
    ScheduledReportsDocument,
    options,
  );
}
export type ScheduledReportsQueryHookResult = ReturnType<typeof useScheduledReportsQuery>;
export type ScheduledReportsLazyQueryHookResult = ReturnType<typeof useScheduledReportsLazyQuery>;
export type ScheduledReportsQueryResult = Apollo.QueryResult<
  ScheduledReportsQuery,
  ScheduledReportsQueryVariables
>;
export const CreatePlanDocument = gql`
  mutation CreatePlan($input: CreatePlanInput!) {
    createPlan(input: $input) {
      errors {
        field
        messages
      }
      plan {
        id
      }
    }
  }
`;
export type CreatePlanMutationFn = Apollo.MutationFunction<
  CreatePlanMutation,
  CreatePlanMutationVariables
>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(
    CreatePlanDocument,
    options,
  );
}
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<
  CreatePlanMutation,
  CreatePlanMutationVariables
>;
export const AdminPlanCategoriesDocument = gql`
  query AdminPlanCategories {
    adminPlanCategories {
      id
      name
    }
  }
`;

/**
 * __useAdminPlanCategoriesQuery__
 *
 * To run a query within a React component, call `useAdminPlanCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPlanCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPlanCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminPlanCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminPlanCategoriesQuery,
    AdminPlanCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminPlanCategoriesQuery, AdminPlanCategoriesQueryVariables>(
    AdminPlanCategoriesDocument,
    options,
  );
}
export function useAdminPlanCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminPlanCategoriesQuery,
    AdminPlanCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminPlanCategoriesQuery, AdminPlanCategoriesQueryVariables>(
    AdminPlanCategoriesDocument,
    options,
  );
}
export type AdminPlanCategoriesQueryHookResult = ReturnType<typeof useAdminPlanCategoriesQuery>;
export type AdminPlanCategoriesLazyQueryHookResult = ReturnType<
  typeof useAdminPlanCategoriesLazyQuery
>;
export type AdminPlanCategoriesQueryResult = Apollo.QueryResult<
  AdminPlanCategoriesQuery,
  AdminPlanCategoriesQueryVariables
>;
export const AdminPublicPlansDocument = gql`
  query AdminPublicPlans {
    adminPublicPlans {
      ...PublicPlan
    }
  }
  ${PublicPlanFragmentDoc}
`;

/**
 * __useAdminPublicPlansQuery__
 *
 * To run a query within a React component, call `useAdminPublicPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPublicPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPublicPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminPublicPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminPublicPlansQuery, AdminPublicPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminPublicPlansQuery, AdminPublicPlansQueryVariables>(
    AdminPublicPlansDocument,
    options,
  );
}
export function useAdminPublicPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminPublicPlansQuery, AdminPublicPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminPublicPlansQuery, AdminPublicPlansQueryVariables>(
    AdminPublicPlansDocument,
    options,
  );
}
export type AdminPublicPlansQueryHookResult = ReturnType<typeof useAdminPublicPlansQuery>;
export type AdminPublicPlansLazyQueryHookResult = ReturnType<typeof useAdminPublicPlansLazyQuery>;
export type AdminPublicPlansQueryResult = Apollo.QueryResult<
  AdminPublicPlansQuery,
  AdminPublicPlansQueryVariables
>;
export const AdminSalesManagersDocument = gql`
  query adminSalesManagers {
    adminSalesManagers {
      ...SalesManager
    }
  }
  ${SalesManagerFragmentDoc}
`;

/**
 * __useAdminSalesManagersQuery__
 *
 * To run a query within a React component, call `useAdminSalesManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSalesManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSalesManagersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminSalesManagersQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminSalesManagersQuery, AdminSalesManagersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminSalesManagersQuery, AdminSalesManagersQueryVariables>(
    AdminSalesManagersDocument,
    options,
  );
}
export function useAdminSalesManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminSalesManagersQuery,
    AdminSalesManagersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminSalesManagersQuery, AdminSalesManagersQueryVariables>(
    AdminSalesManagersDocument,
    options,
  );
}
export type AdminSalesManagersQueryHookResult = ReturnType<typeof useAdminSalesManagersQuery>;
export type AdminSalesManagersLazyQueryHookResult = ReturnType<
  typeof useAdminSalesManagersLazyQuery
>;
export type AdminSalesManagersQueryResult = Apollo.QueryResult<
  AdminSalesManagersQuery,
  AdminSalesManagersQueryVariables
>;
export const AdminOrganizationsDocument = gql`
  query adminOrganizations(
    $salesManagerId: ID
    $getAssigned: Boolean
    $dataType: String
    $filters: [FilterInput]
    $ordering: OrderingInput
    $pagination: PaginationInput
  ) {
    adminOrganizations(
      salesManagerId: $salesManagerId
      getAssigned: $getAssigned
      dataType: $dataType
      filters: $filters
      ordering: $ordering
      pagination: $pagination
    ) {
      data {
        ...AdminOrganization
      }
      pagination {
        numResults
        startIndex
        stopIndex
      }
    }
  }
  ${AdminOrganizationFragmentDoc}
`;

/**
 * __useAdminOrganizationsQuery__
 *
 * To run a query within a React component, call `useAdminOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrganizationsQuery({
 *   variables: {
 *      salesManagerId: // value for 'salesManagerId'
 *      getAssigned: // value for 'getAssigned'
 *      dataType: // value for 'dataType'
 *      filters: // value for 'filters'
 *      ordering: // value for 'ordering'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminOrganizationsQuery, AdminOrganizationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminOrganizationsQuery, AdminOrganizationsQueryVariables>(
    AdminOrganizationsDocument,
    options,
  );
}
export function useAdminOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminOrganizationsQuery,
    AdminOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminOrganizationsQuery, AdminOrganizationsQueryVariables>(
    AdminOrganizationsDocument,
    options,
  );
}
export type AdminOrganizationsQueryHookResult = ReturnType<typeof useAdminOrganizationsQuery>;
export type AdminOrganizationsLazyQueryHookResult = ReturnType<
  typeof useAdminOrganizationsLazyQuery
>;
export type AdminOrganizationsQueryResult = Apollo.QueryResult<
  AdminOrganizationsQuery,
  AdminOrganizationsQueryVariables
>;
export const SetSalesManagersDocument = gql`
  mutation setSalesManagers($input: SetSalesManagerInput!) {
    setSalesManager(input: $input) {
      organization {
        id
        salesManager {
          id
          user {
            id
            email
          }
        }
      }
    }
  }
`;
export type SetSalesManagersMutationFn = Apollo.MutationFunction<
  SetSalesManagersMutation,
  SetSalesManagersMutationVariables
>;

/**
 * __useSetSalesManagersMutation__
 *
 * To run a mutation, you first call `useSetSalesManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSalesManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSalesManagersMutation, { data, loading, error }] = useSetSalesManagersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSalesManagersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSalesManagersMutation,
    SetSalesManagersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetSalesManagersMutation, SetSalesManagersMutationVariables>(
    SetSalesManagersDocument,
    options,
  );
}
export type SetSalesManagersMutationHookResult = ReturnType<typeof useSetSalesManagersMutation>;
export type SetSalesManagersMutationResult = Apollo.MutationResult<SetSalesManagersMutation>;
export type SetSalesManagersMutationOptions = Apollo.BaseMutationOptions<
  SetSalesManagersMutation,
  SetSalesManagersMutationVariables
>;
export const InvoiceAdminOrganizationsDocument = gql`
  query InvoiceAdminOrganizations($name: String, $active: Boolean) {
    adminInvoiceCustomers(name: $name, active: $active) {
      ...InvoiceAdminOrganization
    }
  }
  ${InvoiceAdminOrganizationFragmentDoc}
`;

/**
 * __useInvoiceAdminOrganizationsQuery__
 *
 * To run a query within a React component, call `useInvoiceAdminOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceAdminOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceAdminOrganizationsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useInvoiceAdminOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InvoiceAdminOrganizationsQuery,
    InvoiceAdminOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoiceAdminOrganizationsQuery, InvoiceAdminOrganizationsQueryVariables>(
    InvoiceAdminOrganizationsDocument,
    options,
  );
}
export function useInvoiceAdminOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoiceAdminOrganizationsQuery,
    InvoiceAdminOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InvoiceAdminOrganizationsQuery,
    InvoiceAdminOrganizationsQueryVariables
  >(InvoiceAdminOrganizationsDocument, options);
}
export type InvoiceAdminOrganizationsQueryHookResult = ReturnType<
  typeof useInvoiceAdminOrganizationsQuery
>;
export type InvoiceAdminOrganizationsLazyQueryHookResult = ReturnType<
  typeof useInvoiceAdminOrganizationsLazyQuery
>;
export type InvoiceAdminOrganizationsQueryResult = Apollo.QueryResult<
  InvoiceAdminOrganizationsQuery,
  InvoiceAdminOrganizationsQueryVariables
>;
export const SalesMailsTableDocument = gql`
  query SalesMailsTable($startIndex: Int!, $stopIndex: Int!) {
    adminLoggedEmail(startIndex: $startIndex, stopIndex: $stopIndex) {
      id
      createdAt
      messageId
      subject
      recipients
      fromEmail
      replyTo
      mtaAccept
      mtaError
      notificationsTypes
      notifications
      opened
    }
    adminLoggedEmailCount
  }
`;

/**
 * __useSalesMailsTableQuery__
 *
 * To run a query within a React component, call `useSalesMailsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesMailsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesMailsTableQuery({
 *   variables: {
 *      startIndex: // value for 'startIndex'
 *      stopIndex: // value for 'stopIndex'
 *   },
 * });
 */
export function useSalesMailsTableQuery(
  baseOptions: Apollo.QueryHookOptions<SalesMailsTableQuery, SalesMailsTableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesMailsTableQuery, SalesMailsTableQueryVariables>(
    SalesMailsTableDocument,
    options,
  );
}
export function useSalesMailsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SalesMailsTableQuery, SalesMailsTableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SalesMailsTableQuery, SalesMailsTableQueryVariables>(
    SalesMailsTableDocument,
    options,
  );
}
export type SalesMailsTableQueryHookResult = ReturnType<typeof useSalesMailsTableQuery>;
export type SalesMailsTableLazyQueryHookResult = ReturnType<typeof useSalesMailsTableLazyQuery>;
export type SalesMailsTableQueryResult = Apollo.QueryResult<
  SalesMailsTableQuery,
  SalesMailsTableQueryVariables
>;
export const HistoricKeywordUsageDocument = gql`
  query HistoricKeywordUsage($id: ID!) {
    adminOrganization(id: $id) {
      historicKeywordUsages
    }
  }
`;

/**
 * __useHistoricKeywordUsageQuery__
 *
 * To run a query within a React component, call `useHistoricKeywordUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricKeywordUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricKeywordUsageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHistoricKeywordUsageQuery(
  baseOptions: Apollo.QueryHookOptions<
    HistoricKeywordUsageQuery,
    HistoricKeywordUsageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HistoricKeywordUsageQuery, HistoricKeywordUsageQueryVariables>(
    HistoricKeywordUsageDocument,
    options,
  );
}
export function useHistoricKeywordUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HistoricKeywordUsageQuery,
    HistoricKeywordUsageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HistoricKeywordUsageQuery, HistoricKeywordUsageQueryVariables>(
    HistoricKeywordUsageDocument,
    options,
  );
}
export type HistoricKeywordUsageQueryHookResult = ReturnType<typeof useHistoricKeywordUsageQuery>;
export type HistoricKeywordUsageLazyQueryHookResult = ReturnType<
  typeof useHistoricKeywordUsageLazyQuery
>;
export type HistoricKeywordUsageQueryResult = Apollo.QueryResult<
  HistoricKeywordUsageQuery,
  HistoricKeywordUsageQueryVariables
>;
export const SalesOrgSetSalesManagerDocument = gql`
  mutation SalesOrgSetSalesManager($input: SetSalesManagerInput!) {
    setSalesManager(input: $input) {
      organization {
        id
        salesManager {
          id
          user {
            id
            email
          }
        }
      }
    }
  }
`;
export type SalesOrgSetSalesManagerMutationFn = Apollo.MutationFunction<
  SalesOrgSetSalesManagerMutation,
  SalesOrgSetSalesManagerMutationVariables
>;

/**
 * __useSalesOrgSetSalesManagerMutation__
 *
 * To run a mutation, you first call `useSalesOrgSetSalesManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesOrgSetSalesManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesOrgSetSalesManagerMutation, { data, loading, error }] = useSalesOrgSetSalesManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalesOrgSetSalesManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesOrgSetSalesManagerMutation,
    SalesOrgSetSalesManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesOrgSetSalesManagerMutation,
    SalesOrgSetSalesManagerMutationVariables
  >(SalesOrgSetSalesManagerDocument, options);
}
export type SalesOrgSetSalesManagerMutationHookResult = ReturnType<
  typeof useSalesOrgSetSalesManagerMutation
>;
export type SalesOrgSetSalesManagerMutationResult =
  Apollo.MutationResult<SalesOrgSetSalesManagerMutation>;
export type SalesOrgSetSalesManagerMutationOptions = Apollo.BaseMutationOptions<
  SalesOrgSetSalesManagerMutation,
  SalesOrgSetSalesManagerMutationVariables
>;
export const SalesOrgAdminOrganizationDocument = gql`
  query SalesOrgAdminOrganization($id: ID!) {
    adminOrganization(id: $id) {
      id
      name
      active
      slug
      dateAdded
      isPartner
      isAffiliate
      hubspotLink
      keywordOveruseEnabled
      paymentcontact {
        id
        companyName
        paymentMethod
        vatNo
        vatNumber
      }
      organizationClients {
        id
        name
        dateAdded
        domains {
          id
          domain
          displayName
          dateAdded
          totalKeywords
          googleOauthConnectionGa {
            id
          }
          googleOauthConnectionGsc {
            id
          }
          adobeSuiteId
        }
      }
      users {
        id
        dateJoined
        email
        fullName
        userType
        isAccurankerSuperuser
        salesManager {
          id
        }
        hasOtpDevice
      }
      invoices {
        id
        number
        createdAt
        dueDate
        paid
        currency
        vat
        amount
        total
        url
        emailMessageIds
        payment {
          refundedDate
        }
        creditedInvoice {
          id
          number
        }
      }
      multiAccountLinks {
        id
        isOrgAdmin
        fromOrganizationPays
        fromOrganization {
          id
          name
        }
      }
      multiAccounts {
        id
        isOrgAdmin
        createdAt
        fromOrganizationPays
        fromOrganizationDiscountType
        fromOrganizationDiscountPercent
        toOrganization {
          id
          name
        }
      }
      plans {
        ...OrganizationPlan
      }
      trialAbuseLog {
        id
        createdAt
        description
        value
      }
      salesManager {
        id
        name
        user {
          id
          fullName
          email
        }
      }
      failedPayments {
        id
        createdAt
        reason
      }
      acceptedOveruse {
        userId
        name
        multiAccountLink
      }
      organizationNotes {
        ...OrganizationNote
      }
    }
  }
  ${OrganizationPlanFragmentDoc}
  ${OrganizationNoteFragmentDoc}
`;

/**
 * __useSalesOrgAdminOrganizationQuery__
 *
 * To run a query within a React component, call `useSalesOrgAdminOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesOrgAdminOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesOrgAdminOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSalesOrgAdminOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesOrgAdminOrganizationQuery,
    SalesOrgAdminOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesOrgAdminOrganizationQuery, SalesOrgAdminOrganizationQueryVariables>(
    SalesOrgAdminOrganizationDocument,
    options,
  );
}
export function useSalesOrgAdminOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesOrgAdminOrganizationQuery,
    SalesOrgAdminOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesOrgAdminOrganizationQuery,
    SalesOrgAdminOrganizationQueryVariables
  >(SalesOrgAdminOrganizationDocument, options);
}
export type SalesOrgAdminOrganizationQueryHookResult = ReturnType<
  typeof useSalesOrgAdminOrganizationQuery
>;
export type SalesOrgAdminOrganizationLazyQueryHookResult = ReturnType<
  typeof useSalesOrgAdminOrganizationLazyQuery
>;
export type SalesOrgAdminOrganizationQueryResult = Apollo.QueryResult<
  SalesOrgAdminOrganizationQuery,
  SalesOrgAdminOrganizationQueryVariables
>;
export const SalesOrgSoftDeleteUserDocument = gql`
  mutation SalesOrgSoftDeleteUser($input: SoftDeleteUserInput!) {
    softDeleteUser(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type SalesOrgSoftDeleteUserMutationFn = Apollo.MutationFunction<
  SalesOrgSoftDeleteUserMutation,
  SalesOrgSoftDeleteUserMutationVariables
>;

/**
 * __useSalesOrgSoftDeleteUserMutation__
 *
 * To run a mutation, you first call `useSalesOrgSoftDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesOrgSoftDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesOrgSoftDeleteUserMutation, { data, loading, error }] = useSalesOrgSoftDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalesOrgSoftDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesOrgSoftDeleteUserMutation,
    SalesOrgSoftDeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesOrgSoftDeleteUserMutation,
    SalesOrgSoftDeleteUserMutationVariables
  >(SalesOrgSoftDeleteUserDocument, options);
}
export type SalesOrgSoftDeleteUserMutationHookResult = ReturnType<
  typeof useSalesOrgSoftDeleteUserMutation
>;
export type SalesOrgSoftDeleteUserMutationResult =
  Apollo.MutationResult<SalesOrgSoftDeleteUserMutation>;
export type SalesOrgSoftDeleteUserMutationOptions = Apollo.BaseMutationOptions<
  SalesOrgSoftDeleteUserMutation,
  SalesOrgSoftDeleteUserMutationVariables
>;
export const SalesOrgAdminSalesManagersDocument = gql`
  query SalesOrgAdminSalesManagers {
    adminSalesManagers {
      id
      name
      active
    }
  }
`;

/**
 * __useSalesOrgAdminSalesManagersQuery__
 *
 * To run a query within a React component, call `useSalesOrgAdminSalesManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesOrgAdminSalesManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesOrgAdminSalesManagersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSalesOrgAdminSalesManagersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SalesOrgAdminSalesManagersQuery,
    SalesOrgAdminSalesManagersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesOrgAdminSalesManagersQuery, SalesOrgAdminSalesManagersQueryVariables>(
    SalesOrgAdminSalesManagersDocument,
    options,
  );
}
export function useSalesOrgAdminSalesManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesOrgAdminSalesManagersQuery,
    SalesOrgAdminSalesManagersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesOrgAdminSalesManagersQuery,
    SalesOrgAdminSalesManagersQueryVariables
  >(SalesOrgAdminSalesManagersDocument, options);
}
export type SalesOrgAdminSalesManagersQueryHookResult = ReturnType<
  typeof useSalesOrgAdminSalesManagersQuery
>;
export type SalesOrgAdminSalesManagersLazyQueryHookResult = ReturnType<
  typeof useSalesOrgAdminSalesManagersLazyQuery
>;
export type SalesOrgAdminSalesManagersQueryResult = Apollo.QueryResult<
  SalesOrgAdminSalesManagersQuery,
  SalesOrgAdminSalesManagersQueryVariables
>;
export const SalesPlansTableAdminPlansDocument = gql`
  query SalesPlansTableAdminPlans {
    adminPlans {
      ...AdminPlan
    }
  }
  ${AdminPlanFragmentDoc}
`;

/**
 * __useSalesPlansTableAdminPlansQuery__
 *
 * To run a query within a React component, call `useSalesPlansTableAdminPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPlansTableAdminPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPlansTableAdminPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useSalesPlansTableAdminPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SalesPlansTableAdminPlansQuery,
    SalesPlansTableAdminPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesPlansTableAdminPlansQuery, SalesPlansTableAdminPlansQueryVariables>(
    SalesPlansTableAdminPlansDocument,
    options,
  );
}
export function useSalesPlansTableAdminPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesPlansTableAdminPlansQuery,
    SalesPlansTableAdminPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesPlansTableAdminPlansQuery,
    SalesPlansTableAdminPlansQueryVariables
  >(SalesPlansTableAdminPlansDocument, options);
}
export type SalesPlansTableAdminPlansQueryHookResult = ReturnType<
  typeof useSalesPlansTableAdminPlansQuery
>;
export type SalesPlansTableAdminPlansLazyQueryHookResult = ReturnType<
  typeof useSalesPlansTableAdminPlansLazyQuery
>;
export type SalesPlansTableAdminPlansQueryResult = Apollo.QueryResult<
  SalesPlansTableAdminPlansQuery,
  SalesPlansTableAdminPlansQueryVariables
>;
export const ReconcileUnmatchedInvoicesDocument = gql`
  query reconcileUnmatchedInvoices {
    salesUnmatchedInvoices {
      id
      number
      amount
      vat
      total
      vatRate
      currency
      createdAt
      dueDate
      organization {
        name
      }
      paymentContact {
        companyName
        eanNo
      }
    }
  }
`;

/**
 * __useReconcileUnmatchedInvoicesQuery__
 *
 * To run a query within a React component, call `useReconcileUnmatchedInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReconcileUnmatchedInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReconcileUnmatchedInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReconcileUnmatchedInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReconcileUnmatchedInvoicesQuery,
    ReconcileUnmatchedInvoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReconcileUnmatchedInvoicesQuery, ReconcileUnmatchedInvoicesQueryVariables>(
    ReconcileUnmatchedInvoicesDocument,
    options,
  );
}
export function useReconcileUnmatchedInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReconcileUnmatchedInvoicesQuery,
    ReconcileUnmatchedInvoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReconcileUnmatchedInvoicesQuery,
    ReconcileUnmatchedInvoicesQueryVariables
  >(ReconcileUnmatchedInvoicesDocument, options);
}
export type ReconcileUnmatchedInvoicesQueryHookResult = ReturnType<
  typeof useReconcileUnmatchedInvoicesQuery
>;
export type ReconcileUnmatchedInvoicesLazyQueryHookResult = ReturnType<
  typeof useReconcileUnmatchedInvoicesLazyQuery
>;
export type ReconcileUnmatchedInvoicesQueryResult = Apollo.QueryResult<
  ReconcileUnmatchedInvoicesQuery,
  ReconcileUnmatchedInvoicesQueryVariables
>;
export const ReconcileUnmatchedWisePaymentsDocument = gql`
  query reconcileUnmatchedWisePayments {
    salesUnmatchedWisePayments {
      id
      createdAt
      sender
      reference
      amount
      currency
      reconciled
    }
  }
`;

/**
 * __useReconcileUnmatchedWisePaymentsQuery__
 *
 * To run a query within a React component, call `useReconcileUnmatchedWisePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReconcileUnmatchedWisePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReconcileUnmatchedWisePaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReconcileUnmatchedWisePaymentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReconcileUnmatchedWisePaymentsQuery,
    ReconcileUnmatchedWisePaymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReconcileUnmatchedWisePaymentsQuery,
    ReconcileUnmatchedWisePaymentsQueryVariables
  >(ReconcileUnmatchedWisePaymentsDocument, options);
}
export function useReconcileUnmatchedWisePaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReconcileUnmatchedWisePaymentsQuery,
    ReconcileUnmatchedWisePaymentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReconcileUnmatchedWisePaymentsQuery,
    ReconcileUnmatchedWisePaymentsQueryVariables
  >(ReconcileUnmatchedWisePaymentsDocument, options);
}
export type ReconcileUnmatchedWisePaymentsQueryHookResult = ReturnType<
  typeof useReconcileUnmatchedWisePaymentsQuery
>;
export type ReconcileUnmatchedWisePaymentsLazyQueryHookResult = ReturnType<
  typeof useReconcileUnmatchedWisePaymentsLazyQuery
>;
export type ReconcileUnmatchedWisePaymentsQueryResult = Apollo.QueryResult<
  ReconcileUnmatchedWisePaymentsQuery,
  ReconcileUnmatchedWisePaymentsQueryVariables
>;
export const ReconcileSetInvoicePaidDocument = gql`
  mutation ReconcileSetInvoicePaid($invoiceId: UUID, $paymentId: Int!) {
    setInvoicePaid(invoiceId: $invoiceId, paymentId: $paymentId) {
      invoice {
        paid
      }
    }
  }
`;
export type ReconcileSetInvoicePaidMutationFn = Apollo.MutationFunction<
  ReconcileSetInvoicePaidMutation,
  ReconcileSetInvoicePaidMutationVariables
>;

/**
 * __useReconcileSetInvoicePaidMutation__
 *
 * To run a mutation, you first call `useReconcileSetInvoicePaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconcileSetInvoicePaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconcileSetInvoicePaidMutation, { data, loading, error }] = useReconcileSetInvoicePaidMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useReconcileSetInvoicePaidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReconcileSetInvoicePaidMutation,
    ReconcileSetInvoicePaidMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReconcileSetInvoicePaidMutation,
    ReconcileSetInvoicePaidMutationVariables
  >(ReconcileSetInvoicePaidDocument, options);
}
export type ReconcileSetInvoicePaidMutationHookResult = ReturnType<
  typeof useReconcileSetInvoicePaidMutation
>;
export type ReconcileSetInvoicePaidMutationResult =
  Apollo.MutationResult<ReconcileSetInvoicePaidMutation>;
export type ReconcileSetInvoicePaidMutationOptions = Apollo.BaseMutationOptions<
  ReconcileSetInvoicePaidMutation,
  ReconcileSetInvoicePaidMutationVariables
>;
export const SetEanInvoicePaidDocument = gql`
  mutation SetEanInvoicePaid($invoiceId: UUID!) {
    setEanInvoicePaid(invoiceId: $invoiceId) {
      invoice {
        paid
      }
    }
  }
`;
export type SetEanInvoicePaidMutationFn = Apollo.MutationFunction<
  SetEanInvoicePaidMutation,
  SetEanInvoicePaidMutationVariables
>;

/**
 * __useSetEanInvoicePaidMutation__
 *
 * To run a mutation, you first call `useSetEanInvoicePaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEanInvoicePaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEanInvoicePaidMutation, { data, loading, error }] = useSetEanInvoicePaidMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useSetEanInvoicePaidMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetEanInvoicePaidMutation,
    SetEanInvoicePaidMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetEanInvoicePaidMutation, SetEanInvoicePaidMutationVariables>(
    SetEanInvoicePaidDocument,
    options,
  );
}
export type SetEanInvoicePaidMutationHookResult = ReturnType<typeof useSetEanInvoicePaidMutation>;
export type SetEanInvoicePaidMutationResult = Apollo.MutationResult<SetEanInvoicePaidMutation>;
export type SetEanInvoicePaidMutationOptions = Apollo.BaseMutationOptions<
  SetEanInvoicePaidMutation,
  SetEanInvoicePaidMutationVariables
>;
export const SalesSearchAdminSearchDocument = gql`
  query SalesSearchAdminSearch($q: String!, $types: [Int]!) {
    adminSearch(q: $q, types: $types) {
      objId
      name
      type
      data
    }
  }
`;

/**
 * __useSalesSearchAdminSearchQuery__
 *
 * To run a query within a React component, call `useSalesSearchAdminSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesSearchAdminSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesSearchAdminSearchQuery({
 *   variables: {
 *      q: // value for 'q'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useSalesSearchAdminSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesSearchAdminSearchQuery,
    SalesSearchAdminSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesSearchAdminSearchQuery, SalesSearchAdminSearchQueryVariables>(
    SalesSearchAdminSearchDocument,
    options,
  );
}
export function useSalesSearchAdminSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesSearchAdminSearchQuery,
    SalesSearchAdminSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SalesSearchAdminSearchQuery, SalesSearchAdminSearchQueryVariables>(
    SalesSearchAdminSearchDocument,
    options,
  );
}
export type SalesSearchAdminSearchQueryHookResult = ReturnType<
  typeof useSalesSearchAdminSearchQuery
>;
export type SalesSearchAdminSearchLazyQueryHookResult = ReturnType<
  typeof useSalesSearchAdminSearchLazyQuery
>;
export type SalesSearchAdminSearchQueryResult = Apollo.QueryResult<
  SalesSearchAdminSearchQuery,
  SalesSearchAdminSearchQueryVariables
>;
export const SalesTrialsDomainsDocument = gql`
  query SalesTrialsDomains($id: ID!) {
    adminOrganization(id: $id) {
      id
      organizationClients {
        id
        name
        domains {
          id
          domain
          displayName
          totalKeywords
        }
      }
    }
  }
`;

/**
 * __useSalesTrialsDomainsQuery__
 *
 * To run a query within a React component, call `useSalesTrialsDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesTrialsDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesTrialsDomainsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSalesTrialsDomainsQuery(
  baseOptions: Apollo.QueryHookOptions<SalesTrialsDomainsQuery, SalesTrialsDomainsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesTrialsDomainsQuery, SalesTrialsDomainsQueryVariables>(
    SalesTrialsDomainsDocument,
    options,
  );
}
export function useSalesTrialsDomainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesTrialsDomainsQuery,
    SalesTrialsDomainsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SalesTrialsDomainsQuery, SalesTrialsDomainsQueryVariables>(
    SalesTrialsDomainsDocument,
    options,
  );
}
export type SalesTrialsDomainsQueryHookResult = ReturnType<typeof useSalesTrialsDomainsQuery>;
export type SalesTrialsDomainsLazyQueryHookResult = ReturnType<
  typeof useSalesTrialsDomainsLazyQuery
>;
export type SalesTrialsDomainsQueryResult = Apollo.QueryResult<
  SalesTrialsDomainsQuery,
  SalesTrialsDomainsQueryVariables
>;
export const SalesTrialsTableDocument = gql`
  query salesTrialsTable($input: SalesDashboardInput!) {
    adminSalesDashboard(input: $input) {
      organizations {
        ...SalesTrialsOrganization
      }
    }
  }
  ${SalesTrialsOrganizationFragmentDoc}
`;

/**
 * __useSalesTrialsTableQuery__
 *
 * To run a query within a React component, call `useSalesTrialsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesTrialsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesTrialsTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSalesTrialsTableQuery(
  baseOptions: Apollo.QueryHookOptions<SalesTrialsTableQuery, SalesTrialsTableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesTrialsTableQuery, SalesTrialsTableQueryVariables>(
    SalesTrialsTableDocument,
    options,
  );
}
export function useSalesTrialsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SalesTrialsTableQuery, SalesTrialsTableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SalesTrialsTableQuery, SalesTrialsTableQueryVariables>(
    SalesTrialsTableDocument,
    options,
  );
}
export type SalesTrialsTableQueryHookResult = ReturnType<typeof useSalesTrialsTableQuery>;
export type SalesTrialsTableLazyQueryHookResult = ReturnType<typeof useSalesTrialsTableLazyQuery>;
export type SalesTrialsTableQueryResult = Apollo.QueryResult<
  SalesTrialsTableQuery,
  SalesTrialsTableQueryVariables
>;
export const SetLeadScoreDocument = gql`
  mutation setLeadScore($input: SetLeadScoreInput!) {
    setLeadScore(input: $input) {
      organization {
        id
        leadScore
      }
    }
  }
`;
export type SetLeadScoreMutationFn = Apollo.MutationFunction<
  SetLeadScoreMutation,
  SetLeadScoreMutationVariables
>;

/**
 * __useSetLeadScoreMutation__
 *
 * To run a mutation, you first call `useSetLeadScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLeadScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLeadScoreMutation, { data, loading, error }] = useSetLeadScoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetLeadScoreMutation(
  baseOptions?: Apollo.MutationHookOptions<SetLeadScoreMutation, SetLeadScoreMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetLeadScoreMutation, SetLeadScoreMutationVariables>(
    SetLeadScoreDocument,
    options,
  );
}
export type SetLeadScoreMutationHookResult = ReturnType<typeof useSetLeadScoreMutation>;
export type SetLeadScoreMutationResult = Apollo.MutationResult<SetLeadScoreMutation>;
export type SetLeadScoreMutationOptions = Apollo.BaseMutationOptions<
  SetLeadScoreMutation,
  SetLeadScoreMutationVariables
>;
export const SetSalesManagerDocument = gql`
  mutation setSalesManager($input: SetSalesManagerInput!) {
    setSalesManager(input: $input) {
      organization {
        id
        salesManager {
          id
          name
          user {
            id
            email
          }
        }
      }
    }
  }
`;
export type SetSalesManagerMutationFn = Apollo.MutationFunction<
  SetSalesManagerMutation,
  SetSalesManagerMutationVariables
>;

/**
 * __useSetSalesManagerMutation__
 *
 * To run a mutation, you first call `useSetSalesManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSalesManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSalesManagerMutation, { data, loading, error }] = useSetSalesManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSalesManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetSalesManagerMutation,
    SetSalesManagerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetSalesManagerMutation, SetSalesManagerMutationVariables>(
    SetSalesManagerDocument,
    options,
  );
}
export type SetSalesManagerMutationHookResult = ReturnType<typeof useSetSalesManagerMutation>;
export type SetSalesManagerMutationResult = Apollo.MutationResult<SetSalesManagerMutation>;
export type SetSalesManagerMutationOptions = Apollo.BaseMutationOptions<
  SetSalesManagerMutation,
  SetSalesManagerMutationVariables
>;
export const CreateCustomTrialDocument = gql`
  mutation CreateCustomTrial($input: CreateCustomTrialInput!) {
    createCustomTrial(input: $input) {
      unconfirmedUser {
        email
      }
      errors {
        field
        messages
      }
    }
  }
`;
export type CreateCustomTrialMutationFn = Apollo.MutationFunction<
  CreateCustomTrialMutation,
  CreateCustomTrialMutationVariables
>;

/**
 * __useCreateCustomTrialMutation__
 *
 * To run a mutation, you first call `useCreateCustomTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomTrialMutation, { data, loading, error }] = useCreateCustomTrialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomTrialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomTrialMutation,
    CreateCustomTrialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCustomTrialMutation, CreateCustomTrialMutationVariables>(
    CreateCustomTrialDocument,
    options,
  );
}
export type CreateCustomTrialMutationHookResult = ReturnType<typeof useCreateCustomTrialMutation>;
export type CreateCustomTrialMutationResult = Apollo.MutationResult<CreateCustomTrialMutation>;
export type CreateCustomTrialMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomTrialMutation,
  CreateCustomTrialMutationVariables
>;
export const PublicReportDomainPublicUrlDocument = gql`
  query PublicReportDomainPublicUrl($id: ID!) {
    domain(id: $id) {
      id
      publicUrl
    }
  }
`;

/**
 * __usePublicReportDomainPublicUrlQuery__
 *
 * To run a query within a React component, call `usePublicReportDomainPublicUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicReportDomainPublicUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicReportDomainPublicUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublicReportDomainPublicUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicReportDomainPublicUrlQuery,
    PublicReportDomainPublicUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PublicReportDomainPublicUrlQuery,
    PublicReportDomainPublicUrlQueryVariables
  >(PublicReportDomainPublicUrlDocument, options);
}
export function usePublicReportDomainPublicUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicReportDomainPublicUrlQuery,
    PublicReportDomainPublicUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicReportDomainPublicUrlQuery,
    PublicReportDomainPublicUrlQueryVariables
  >(PublicReportDomainPublicUrlDocument, options);
}
export type PublicReportDomainPublicUrlQueryHookResult = ReturnType<
  typeof usePublicReportDomainPublicUrlQuery
>;
export type PublicReportDomainPublicUrlLazyQueryHookResult = ReturnType<
  typeof usePublicReportDomainPublicUrlLazyQuery
>;
export type PublicReportDomainPublicUrlQueryResult = Apollo.QueryResult<
  PublicReportDomainPublicUrlQuery,
  PublicReportDomainPublicUrlQueryVariables
>;
export const PublicReportRequestDomainPublicUrlDocument = gql`
  mutation PublicReportRequestDomainPublicUrl($id: ID!) {
    resetViewkeyDomain(id: $id) {
      domain {
        publicUrl
      }
    }
  }
`;
export type PublicReportRequestDomainPublicUrlMutationFn = Apollo.MutationFunction<
  PublicReportRequestDomainPublicUrlMutation,
  PublicReportRequestDomainPublicUrlMutationVariables
>;

/**
 * __usePublicReportRequestDomainPublicUrlMutation__
 *
 * To run a mutation, you first call `usePublicReportRequestDomainPublicUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublicReportRequestDomainPublicUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publicReportRequestDomainPublicUrlMutation, { data, loading, error }] = usePublicReportRequestDomainPublicUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublicReportRequestDomainPublicUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublicReportRequestDomainPublicUrlMutation,
    PublicReportRequestDomainPublicUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublicReportRequestDomainPublicUrlMutation,
    PublicReportRequestDomainPublicUrlMutationVariables
  >(PublicReportRequestDomainPublicUrlDocument, options);
}
export type PublicReportRequestDomainPublicUrlMutationHookResult = ReturnType<
  typeof usePublicReportRequestDomainPublicUrlMutation
>;
export type PublicReportRequestDomainPublicUrlMutationResult =
  Apollo.MutationResult<PublicReportRequestDomainPublicUrlMutation>;
export type PublicReportRequestDomainPublicUrlMutationOptions = Apollo.BaseMutationOptions<
  PublicReportRequestDomainPublicUrlMutation,
  PublicReportRequestDomainPublicUrlMutationVariables
>;
export const PublicReportClientPublicUrlDocument = gql`
  query PublicReportClientPublicUrl($id: ID!) {
    client(id: $id) {
      id
      publicUrl
    }
  }
`;

/**
 * __usePublicReportClientPublicUrlQuery__
 *
 * To run a query within a React component, call `usePublicReportClientPublicUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicReportClientPublicUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicReportClientPublicUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublicReportClientPublicUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicReportClientPublicUrlQuery,
    PublicReportClientPublicUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PublicReportClientPublicUrlQuery,
    PublicReportClientPublicUrlQueryVariables
  >(PublicReportClientPublicUrlDocument, options);
}
export function usePublicReportClientPublicUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicReportClientPublicUrlQuery,
    PublicReportClientPublicUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicReportClientPublicUrlQuery,
    PublicReportClientPublicUrlQueryVariables
  >(PublicReportClientPublicUrlDocument, options);
}
export type PublicReportClientPublicUrlQueryHookResult = ReturnType<
  typeof usePublicReportClientPublicUrlQuery
>;
export type PublicReportClientPublicUrlLazyQueryHookResult = ReturnType<
  typeof usePublicReportClientPublicUrlLazyQuery
>;
export type PublicReportClientPublicUrlQueryResult = Apollo.QueryResult<
  PublicReportClientPublicUrlQuery,
  PublicReportClientPublicUrlQueryVariables
>;
export const PublicReportRequestClientPublicUrlDocument = gql`
  mutation PublicReportRequestClientPublicUrl($id: ID!) {
    resetViewkeyClient(id: $id) {
      client {
        publicUrl
      }
    }
  }
`;
export type PublicReportRequestClientPublicUrlMutationFn = Apollo.MutationFunction<
  PublicReportRequestClientPublicUrlMutation,
  PublicReportRequestClientPublicUrlMutationVariables
>;

/**
 * __usePublicReportRequestClientPublicUrlMutation__
 *
 * To run a mutation, you first call `usePublicReportRequestClientPublicUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublicReportRequestClientPublicUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publicReportRequestClientPublicUrlMutation, { data, loading, error }] = usePublicReportRequestClientPublicUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublicReportRequestClientPublicUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublicReportRequestClientPublicUrlMutation,
    PublicReportRequestClientPublicUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublicReportRequestClientPublicUrlMutation,
    PublicReportRequestClientPublicUrlMutationVariables
  >(PublicReportRequestClientPublicUrlDocument, options);
}
export type PublicReportRequestClientPublicUrlMutationHookResult = ReturnType<
  typeof usePublicReportRequestClientPublicUrlMutation
>;
export type PublicReportRequestClientPublicUrlMutationResult =
  Apollo.MutationResult<PublicReportRequestClientPublicUrlMutation>;
export type PublicReportRequestClientPublicUrlMutationOptions = Apollo.BaseMutationOptions<
  PublicReportRequestClientPublicUrlMutation,
  PublicReportRequestClientPublicUrlMutationVariables
>;
export const ScheduledReportDocument = gql`
  query ScheduledReport($id: ID!) {
    scheduledReport(id: $id) {
      id
      domain {
        id
        domain
        displayName
      }
      group {
        id
        name
      }
      name
      description
      reportType
      recipients
      sender
      reportFilter {
        id
        filters
      }
      reportTemplate {
        id
        name
      }
      language
      emailSubject
      emailBody
      isGroupReport
      schedule
      scheduledDay
    }
  }
`;

/**
 * __useScheduledReportQuery__
 *
 * To run a query within a React component, call `useScheduledReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScheduledReportQuery(
  baseOptions: Apollo.QueryHookOptions<ScheduledReportQuery, ScheduledReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScheduledReportQuery, ScheduledReportQueryVariables>(
    ScheduledReportDocument,
    options,
  );
}
export function useScheduledReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScheduledReportQuery, ScheduledReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScheduledReportQuery, ScheduledReportQueryVariables>(
    ScheduledReportDocument,
    options,
  );
}
export type ScheduledReportQueryHookResult = ReturnType<typeof useScheduledReportQuery>;
export type ScheduledReportLazyQueryHookResult = ReturnType<typeof useScheduledReportLazyQuery>;
export type ScheduledReportQueryResult = Apollo.QueryResult<
  ScheduledReportQuery,
  ScheduledReportQueryVariables
>;
export const UpdateScheduledReportDocument = gql`
  mutation UpdateScheduledReport($input: UpdateScheduledReportInput!) {
    updateScheduledReport(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type UpdateScheduledReportMutationFn = Apollo.MutationFunction<
  UpdateScheduledReportMutation,
  UpdateScheduledReportMutationVariables
>;

/**
 * __useUpdateScheduledReportMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledReportMutation, { data, loading, error }] = useUpdateScheduledReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScheduledReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduledReportMutation,
    UpdateScheduledReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateScheduledReportMutation, UpdateScheduledReportMutationVariables>(
    UpdateScheduledReportDocument,
    options,
  );
}
export type UpdateScheduledReportMutationHookResult = ReturnType<
  typeof useUpdateScheduledReportMutation
>;
export type UpdateScheduledReportMutationResult =
  Apollo.MutationResult<UpdateScheduledReportMutation>;
export type UpdateScheduledReportMutationOptions = Apollo.BaseMutationOptions<
  UpdateScheduledReportMutation,
  UpdateScheduledReportMutationVariables
>;
export const UsersDocument = gql`
  query Users {
    users {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const TotalUsersDocument = gql`
  query TotalUsers {
    user {
      organization {
        numberOfUsers
        activePlan {
          maxUsers
        }
      }
    }
  }
`;

/**
 * __useTotalUsersQuery__
 *
 * To run a query within a React component, call `useTotalUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<TotalUsersQuery, TotalUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TotalUsersQuery, TotalUsersQueryVariables>(TotalUsersDocument, options);
}
export function useTotalUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TotalUsersQuery, TotalUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TotalUsersQuery, TotalUsersQueryVariables>(
    TotalUsersDocument,
    options,
  );
}
export type TotalUsersQueryHookResult = ReturnType<typeof useTotalUsersQuery>;
export type TotalUsersLazyQueryHookResult = ReturnType<typeof useTotalUsersLazyQuery>;
export type TotalUsersQueryResult = Apollo.QueryResult<TotalUsersQuery, TotalUsersQueryVariables>;
export const EditUserDocument = gql`
  mutation EditUser($updateUserInput: UpdateUserInput!) {
    updateUser(input: $updateUserInput) {
      errors {
        field
        messages
      }
    }
  }
`;
export type EditUserMutationFn = Apollo.MutationFunction<
  EditUserMutation,
  EditUserMutationVariables
>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<
  EditUserMutation,
  EditUserMutationVariables
>;
export const WalletsDocument = gql`
  query Wallets {
    user {
      id
      organization {
        id
        affiliate {
          id
          wallet {
            ...Wallet
          }
        }
      }
    }
  }
  ${WalletFragmentDoc}
`;

/**
 * __useWalletsQuery__
 *
 * To run a query within a React component, call `useWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWalletsQuery(
  baseOptions?: Apollo.QueryHookOptions<WalletsQuery, WalletsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WalletsQuery, WalletsQueryVariables>(WalletsDocument, options);
}
export function useWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WalletsQuery, WalletsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WalletsQuery, WalletsQueryVariables>(WalletsDocument, options);
}
export type WalletsQueryHookResult = ReturnType<typeof useWalletsQuery>;
export type WalletsLazyQueryHookResult = ReturnType<typeof useWalletsLazyQuery>;
export type WalletsQueryResult = Apollo.QueryResult<WalletsQuery, WalletsQueryVariables>;
export const TotalWorkspacesDocument = gql`
  query TotalWorkspaces {
    workspacesByOrganization {
      id
    }
    user {
      organization {
        activePlan {
          maxNumbersOfWorkspaces
        }
      }
    }
  }
`;

/**
 * __useTotalWorkspacesQuery__
 *
 * To run a query within a React component, call `useTotalWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalWorkspacesQuery(
  baseOptions?: Apollo.QueryHookOptions<TotalWorkspacesQuery, TotalWorkspacesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TotalWorkspacesQuery, TotalWorkspacesQueryVariables>(
    TotalWorkspacesDocument,
    options,
  );
}
export function useTotalWorkspacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TotalWorkspacesQuery, TotalWorkspacesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TotalWorkspacesQuery, TotalWorkspacesQueryVariables>(
    TotalWorkspacesDocument,
    options,
  );
}
export type TotalWorkspacesQueryHookResult = ReturnType<typeof useTotalWorkspacesQuery>;
export type TotalWorkspacesLazyQueryHookResult = ReturnType<typeof useTotalWorkspacesLazyQuery>;
export type TotalWorkspacesQueryResult = Apollo.QueryResult<
  TotalWorkspacesQuery,
  TotalWorkspacesQueryVariables
>;
export const GetWorkspacesDocument = gql`
  query GetWorkspaces {
    workspacesByOrganization {
      ...Workspace
    }
  }
  ${WorkspaceFragmentDoc}
`;

/**
 * __useGetWorkspacesQuery__
 *
 * To run a query within a React component, call `useGetWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkspacesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWorkspacesQuery, GetWorkspacesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkspacesQuery, GetWorkspacesQueryVariables>(
    GetWorkspacesDocument,
    options,
  );
}
export function useGetWorkspacesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspacesQuery, GetWorkspacesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkspacesQuery, GetWorkspacesQueryVariables>(
    GetWorkspacesDocument,
    options,
  );
}
export type GetWorkspacesQueryHookResult = ReturnType<typeof useGetWorkspacesQuery>;
export type GetWorkspacesLazyQueryHookResult = ReturnType<typeof useGetWorkspacesLazyQuery>;
export type GetWorkspacesQueryResult = Apollo.QueryResult<
  GetWorkspacesQuery,
  GetWorkspacesQueryVariables
>;
export const DeleteWorkspaceDocument = gql`
  mutation DeleteWorkspace($workspaceId: ID!) {
    deleteWorkspace(workspaceId: $workspaceId) {
      errors {
        messages
      }
    }
  }
`;
export type DeleteWorkspaceMutationFn = Apollo.MutationFunction<
  DeleteWorkspaceMutation,
  DeleteWorkspaceMutationVariables
>;

/**
 * __useDeleteWorkspaceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceMutation, { data, loading, error }] = useDeleteWorkspaceMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDeleteWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWorkspaceMutation,
    DeleteWorkspaceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteWorkspaceMutation, DeleteWorkspaceMutationVariables>(
    DeleteWorkspaceDocument,
    options,
  );
}
export type DeleteWorkspaceMutationHookResult = ReturnType<typeof useDeleteWorkspaceMutation>;
export type DeleteWorkspaceMutationResult = Apollo.MutationResult<DeleteWorkspaceMutation>;
export type DeleteWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  DeleteWorkspaceMutation,
  DeleteWorkspaceMutationVariables
>;
export const ImportExamplePageMappableColumnsDocument = gql`
  query importExamplePageMappableColumns {
    mappableColumns {
      id
      name
      type
      description
      examples
      required
    }
  }
`;

/**
 * __useImportExamplePageMappableColumnsQuery__
 *
 * To run a query within a React component, call `useImportExamplePageMappableColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportExamplePageMappableColumnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportExamplePageMappableColumnsQuery({
 *   variables: {
 *   },
 * });
 */
export function useImportExamplePageMappableColumnsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ImportExamplePageMappableColumnsQuery,
    ImportExamplePageMappableColumnsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImportExamplePageMappableColumnsQuery,
    ImportExamplePageMappableColumnsQueryVariables
  >(ImportExamplePageMappableColumnsDocument, options);
}
export function useImportExamplePageMappableColumnsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImportExamplePageMappableColumnsQuery,
    ImportExamplePageMappableColumnsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImportExamplePageMappableColumnsQuery,
    ImportExamplePageMappableColumnsQueryVariables
  >(ImportExamplePageMappableColumnsDocument, options);
}
export type ImportExamplePageMappableColumnsQueryHookResult = ReturnType<
  typeof useImportExamplePageMappableColumnsQuery
>;
export type ImportExamplePageMappableColumnsLazyQueryHookResult = ReturnType<
  typeof useImportExamplePageMappableColumnsLazyQuery
>;
export type ImportExamplePageMappableColumnsQueryResult = Apollo.QueryResult<
  ImportExamplePageMappableColumnsQuery,
  ImportExamplePageMappableColumnsQueryVariables
>;
export const StoreDomainListDocument = gql`
  query storeDomainList {
    domainsList {
      id
    }
    metaData {
      ...MetaData
    }
  }
  ${MetaDataFragmentDoc}
`;

/**
 * __useStoreDomainListQuery__
 *
 * To run a query within a React component, call `useStoreDomainListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreDomainListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreDomainListQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoreDomainListQuery(
  baseOptions?: Apollo.QueryHookOptions<StoreDomainListQuery, StoreDomainListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreDomainListQuery, StoreDomainListQueryVariables>(
    StoreDomainListDocument,
    options,
  );
}
export function useStoreDomainListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StoreDomainListQuery, StoreDomainListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoreDomainListQuery, StoreDomainListQueryVariables>(
    StoreDomainListDocument,
    options,
  );
}
export type StoreDomainListQueryHookResult = ReturnType<typeof useStoreDomainListQuery>;
export type StoreDomainListLazyQueryHookResult = ReturnType<typeof useStoreDomainListLazyQuery>;
export type StoreDomainListQueryResult = Apollo.QueryResult<
  StoreDomainListQuery,
  StoreDomainListQueryVariables
>;
export const GenericSubscribeDocument = gql`
  subscription genericSubscribe {
    liveChanges {
      id
      obj
      action
    }
  }
`;

/**
 * __useGenericSubscribeSubscription__
 *
 * To run a query within a React component, call `useGenericSubscribeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGenericSubscribeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenericSubscribeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGenericSubscribeSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GenericSubscribeSubscription,
    GenericSubscribeSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GenericSubscribeSubscription,
    GenericSubscribeSubscriptionVariables
  >(GenericSubscribeDocument, options);
}
export type GenericSubscribeSubscriptionHookResult = ReturnType<
  typeof useGenericSubscribeSubscription
>;
export type GenericSubscribeSubscriptionResult =
  Apollo.SubscriptionResult<GenericSubscribeSubscription>;
export const GenericGetUserDocument = gql`
  query genericGetUser {
    user {
      id
      isAuthenticated
      email
      fullName
      intercomHash
      dateJoined
      userType
      isImpersonating
      isOnMultiaccount
      isAffiliateAdmin
      impersonateOriginUser {
        isAccurankerSuperuser
      }
      shouldRecordSession
      language
      isAccurankerSuperuser
      isCfo
      isAffiliate
      salesManager {
        id
      }
      defaultKeywordsColumns
      keywordsGroupOrder
      defaultCompetitorsColumns
      defaultLandingPagesColumns
      defaultTagCloudColumns
      defaultFoldersColumns
      defaultKeydisColumns
      defaultNotesColumns
      defaultKeywordsNotificationsColumns
      defaultHomeColumns
      defaultKeywordsPage
      defaultKeywordsOverviewVersion
      defaultCompareTo
      defaultOverviewCharts
      workspaces {
        isAllGroups
      }
      organization {
        id
        name
        dealType
        wizardShouldBeShown
        wizardProgress
        createdByCountry
        type
        active
        accountBlocked
        accountBlockedReason {
          title
          message
        }
        activePlan {
          ...OrganizationActivePlan
        }
        nextPlan {
          id
        }
        isPartner
        dateAdded
        errors {
          failedPayment
          trialExpired
          planExpired
          keywordRefreshDisabled
          systemHealthNotice
          invoiceStatus
        }
        phoneNumber
        numberOfUsers
        numberOfCompetitors
        domainWithHighestCompetitors
        numberOfDomains
        numberOfDomainsWithGa
        numberOfDomainsWithGwt
        hasValidGscConnection
        numberOfKeywords
        keywordOveruseEnabled
        showKeywordUsage
        multiAccountOwners {
          id
        }
        salesManager {
          meetingLink
        }
        displayCurrency
        showLocalSearchVolume
        primaryCountryCurrency
        logo
        unrankedWeight
      }
      savedFilters {
        id
        name
        type
        defaultForDomains
        defaultForKeywords
        filters
      }
      unconfirmedUserId
      googleConnections {
        id
      }
    }
  }
  ${OrganizationActivePlanFragmentDoc}
`;

/**
 * __useGenericGetUserQuery__
 *
 * To run a query within a React component, call `useGenericGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenericGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenericGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenericGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GenericGetUserQuery, GenericGetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenericGetUserQuery, GenericGetUserQueryVariables>(
    GenericGetUserDocument,
    options,
  );
}
export function useGenericGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GenericGetUserQuery, GenericGetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GenericGetUserQuery, GenericGetUserQueryVariables>(
    GenericGetUserDocument,
    options,
  );
}
export type GenericGetUserQueryHookResult = ReturnType<typeof useGenericGetUserQuery>;
export type GenericGetUserLazyQueryHookResult = ReturnType<typeof useGenericGetUserLazyQuery>;
export type GenericGetUserQueryResult = Apollo.QueryResult<
  GenericGetUserQuery,
  GenericGetUserQueryVariables
>;
export const Generic_UpdateUserSettingsDocument = gql`
  mutation generic_updateUserSettings($input: UpdateUserSettingsInput!) {
    updateUserSettings(input: $input) {
      user {
        id
        defaultKeywordsColumns
        defaultCompetitorsColumns
        defaultLandingPagesColumns
        defaultTagCloudColumns
        defaultFoldersColumns
        defaultNotesColumns
        defaultKeywordsNotificationsColumns
        defaultKeywordsPage
        defaultKeywordsOverviewVersion
        defaultCompareTo
      }
    }
  }
`;
export type Generic_UpdateUserSettingsMutationFn = Apollo.MutationFunction<
  Generic_UpdateUserSettingsMutation,
  Generic_UpdateUserSettingsMutationVariables
>;

/**
 * __useGeneric_UpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useGeneric_UpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneric_UpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [genericUpdateUserSettingsMutation, { data, loading, error }] = useGeneric_UpdateUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeneric_UpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Generic_UpdateUserSettingsMutation,
    Generic_UpdateUserSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Generic_UpdateUserSettingsMutation,
    Generic_UpdateUserSettingsMutationVariables
  >(Generic_UpdateUserSettingsDocument, options);
}
export type Generic_UpdateUserSettingsMutationHookResult = ReturnType<
  typeof useGeneric_UpdateUserSettingsMutation
>;
export type Generic_UpdateUserSettingsMutationResult =
  Apollo.MutationResult<Generic_UpdateUserSettingsMutation>;
export type Generic_UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  Generic_UpdateUserSettingsMutation,
  Generic_UpdateUserSettingsMutationVariables
>;
export const DomainInfoDocument = gql`
  query DomainInfo($id: ID!, $name: String) {
    domain(id: $id, name: $name) {
      ...DomainInfoDomain
    }
  }
  ${DomainInfoDomainFragmentDoc}
`;

/**
 * __useDomainInfoQuery__
 *
 * To run a query within a React component, call `useDomainInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDomainInfoQuery(
  baseOptions: Apollo.QueryHookOptions<DomainInfoQuery, DomainInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DomainInfoQuery, DomainInfoQueryVariables>(DomainInfoDocument, options);
}
export function useDomainInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DomainInfoQuery, DomainInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DomainInfoQuery, DomainInfoQueryVariables>(
    DomainInfoDocument,
    options,
  );
}
export type DomainInfoQueryHookResult = ReturnType<typeof useDomainInfoQuery>;
export type DomainInfoLazyQueryHookResult = ReturnType<typeof useDomainInfoLazyQuery>;
export type DomainInfoQueryResult = Apollo.QueryResult<DomainInfoQuery, DomainInfoQueryVariables>;
export const MixpanelTrackEventDocument = gql`
  mutation mixpanelTrackEvent($input: AccurankerAnalyticsInput!) {
    accurankerAnalytics(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;
export type MixpanelTrackEventMutationFn = Apollo.MutationFunction<
  MixpanelTrackEventMutation,
  MixpanelTrackEventMutationVariables
>;

/**
 * __useMixpanelTrackEventMutation__
 *
 * To run a mutation, you first call `useMixpanelTrackEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMixpanelTrackEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mixpanelTrackEventMutation, { data, loading, error }] = useMixpanelTrackEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMixpanelTrackEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MixpanelTrackEventMutation,
    MixpanelTrackEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MixpanelTrackEventMutation, MixpanelTrackEventMutationVariables>(
    MixpanelTrackEventDocument,
    options,
  );
}
export type MixpanelTrackEventMutationHookResult = ReturnType<typeof useMixpanelTrackEventMutation>;
export type MixpanelTrackEventMutationResult = Apollo.MutationResult<MixpanelTrackEventMutation>;
export type MixpanelTrackEventMutationOptions = Apollo.BaseMutationOptions<
  MixpanelTrackEventMutation,
  MixpanelTrackEventMutationVariables
>;
