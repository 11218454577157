import ValueIndicator from 'Components/ValueIndicator/value-indicator';
import { t } from 'Utilities/i18n';
import Cell from './HelperComponents/AccuCell';

type CtrChangeCellProps = {
  value: {
    data: {
      dynamicCtrValue?: number | null;
      dynamicCtrCompare?: number | null;
      updatingKeyword?: boolean;
    };
    loadingData?: boolean;
  };
};

const CtrChangeCell = (props: CtrChangeCellProps) => {
  const {
    value: { data },
  } = props;
  const { dynamicCtrValue } = data;
  let { dynamicCtrCompare } = data;

  if (dynamicCtrCompare === null) {
    dynamicCtrCompare = 0;
  }

  return (
    <Cell rightAligned>
      <ValueIndicator
        style={'percent'}
        beforeValue={dynamicCtrCompare || null}
        afterValue={dynamicCtrValue || 0}
        maxDecimals={0}
        noCompareString={t('> 100')}
        nullishHyphen={true}
      />
    </Cell>
  );
};

export default CtrChangeCell;
