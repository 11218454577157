import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox } from '@mantine/core';
import { observer } from 'mobx-react';
import AccTooltip from 'Components/AccTooltip/AccTooltip';
import { useRowContext } from 'Components/DataTable';
import { useContextTableStore } from 'Components/DataTable/store/components/TableStoreContext';

const withTableCell = (Component: any) => {
  return observer((props: any) => {
    const tableStore = useContextTableStore();
    const rowId = props?.data?.id;
    const isSelected = tableStore?.getIsRowSelected(rowId, props?.data?.index);
    const toggleRowSelect = useCallback(() => {
      tableStore?.toggleRowSelected(rowId);
    }, [rowId]);
    return <Component isSelected={isSelected} toggleRowSelect={toggleRowSelect} />;
  });
};

const Component = React.memo(({ isSelected, toggleRowSelect, disabled, tooltip }: any) => {
  const { inView } = useRowContext();
  const [selected, setSelected] = useState(isSelected);

  const toggleSelected = () => {
    setSelected(!selected);
    // Updating after checkbox changed to avoid lags in firefox
    // https://accuranker.myjetbrains.com/youtrack/issue/ARR-1356
    setTimeout(() => toggleRowSelect(), 50);
  };

  useEffect(() => {
    if (isSelected !== selected) {
      setSelected(isSelected);
    }
  }, [isSelected]);

  if (!inView) return null;
  return (
    <AccTooltip tooltip={tooltip} disable={!tooltip}>
      <Checkbox
        className="checkboxBodyCell"
        onChange={() => toggleSelected()}
        checked={selected}
        disabled={disabled}
        color={'snorlax'}
      />
    </AccTooltip>
  );
});
Component.displayName = 'CheckboxBodyCell';

export const CheckboxBodyCell = withTableCell(Component);
