import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { AccFastIcon } from 'Components/AccFastIcon';
import {
  ColumnType,
  DataTable,
  TableFetchDataCallBack,
  TableOrder,
  TableSize,
} from 'Components/DataTable';
import DomainDetails from 'Components/DomainDetails';
import {
  EnableOveruseModal,
  RequestEnableOveruseModal,
  RequestUpgradePlanModal,
  UpgradePlanModal,
} from 'Components/OveruseConfirmation';
import ActionsCell from 'Components/Table/TableRow/ActionsCell';
import {
  EnableOveruseArea,
  KeywordOveruseCode,
  PausedDomainFragment,
  PausedDomainsListDocument,
  PausedDomainsListQuery,
  PausedDomainsListQueryVariables,
  usePausedDomainsDeleteDomainMutation,
  usePausedDomainsUpdateDomainMutation,
} from 'Ghql';
import { useModal } from 'Hooks/base/useModal';
import toast from 'Hooks/useToast';
import styles from 'Pages/Domains/DomainsTable/domains-table.module.scss';
import { TableIDs } from 'Types/Table';
import { t } from 'Utilities/i18n';
import { notEmpty } from 'Utilities/underdash';
import { subscribeToDomain } from 'Utilities/websocket';
import styleVariables from 'css/base/variables.module.scss';
import IconPlayerPlay from 'icons/IconPlayerPlay.svg';
import { IconTrash } from 'icons/tag-cloud';
import { EventName, trackEventMixpanel } from '../../Utilities/Analytics/mixpanel';
import { ColumnIDs } from './ColumnIDs';
import DateCell from './cells/DateCell';
import GroupCell from './cells/GroupCell';
import KeywordsCell from './cells/KeywordsCell';

const useFetchData = (): TableFetchDataCallBack => {
  const client = useApolloClient();

  return async () => {
    return client
      .query<PausedDomainsListQuery, PausedDomainsListQueryVariables>({
        query: PausedDomainsListDocument,
        fetchPolicy: 'network-only',
      })
      .then((response) => {
        const PausedDomains = response.data?.pausedDomainsList?.filter(notEmpty) || [];

        return { data: PausedDomains, length: PausedDomains.length ?? 0 };
      });
  };
};

const PausedDomainsTable = () => {
  const [dataKey, setDataKey] = useState<number>(0);

  const handleUpdateTable = () => {
    setDataKey((prev) => prev + 1);
  };

  //Update table when domain is paused/unpaused.
  useEffect(() => {
    const subscription = subscribeToDomain(() => {
      handleUpdateTable();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const { showModal } = useModal();
  const [_updateDomain] = usePausedDomainsUpdateDomainMutation();

  const updateDomain = async (domain: PausedDomainFragment) => {
    try {
      const res = await _updateDomain({
        variables: {
          input: {
            id: domain.id,
            domain: domain.domain,
            displayName: domain.displayName || '',
            includeSubdomains: domain.includeSubdomains,
            exactMatch: domain.exactMatch,
            shareOfVoicePercentage: domain.shareOfVoicePercentage,
            googleBusinessNameList: domain.googleBusinessNameList || [],
            twitterHandle: domain.twitterHandle || '',
            instagramProfile: domain?.instagramProfile || '',
            facebookProfile: domain?.facebookProfile || '',
            tiktokProfile: domain?.tiktokProfile || '',
            linkedinProfile: domain?.linkedinProfile || '',
            client: domain.client.id,
            paused: false,
          },
        },
      });
      return res;
    } catch (err) {
      toast.error(t('Something went wrong'));
      return null;
    }
  };

  const [deleteDomainMutation] = usePausedDomainsDeleteDomainMutation();

  const [responseCode, setResponseCode] = useState<KeywordOveruseCode | null>(null);
  const [selectedDomain, setSelectedDomain] = useState<PausedDomainFragment | null>(null);

  const handleUnpauseDomain = async (domain: PausedDomainFragment) => {
    const res = await updateDomain(domain);
    if (res === null) {
      return;
    }

    const errors = res.data?.updateDomain?.errors;
    if (errors && !isEmpty(errors)) {
      toast.error(errors[0]?.messages[0]);
      return;
    }

    const code = res.data?.updateDomain?.code;
    if (code) {
      setResponseCode(code);
      return;
    }

    toast.success(t('Domain unpaused'));
  };

  const handleDeleteDomain = (domainObj) => {
    const { id } = domainObj;
    deleteDomainMutation({
      variables: {
        input: {
          id,
        },
      },
    }).then((response) => {
      const errors = response.data?.deleteDomain?.errors;

      if (errors && errors.length) {
        toast.error(t('Could not delete domain'));
        trackEventMixpanel(EventName.DeleteDomainFailed, '', {
          'Deleted Domain ID': id,
          Source: 'GroupsAndDomains',
          Error: `${errors?.[0]?.field}: ${errors?.[0]?.messages?.[0]}`,
        });
        return;
      }

      toast.success(t('Domain deleted'));
      trackEventMixpanel(EventName.DeleteDomainSuccess, '', {
        'Deleted Domain ID': id,
        Source: 'GroupsAndDomains',
      });
      handleUpdateTable();
    });
  };

  const showDeleteConfirmation = (domainObj: PausedDomainFragment) => {
    const { domain, displayName } = domainObj;
    showModal({
      modalType: 'Confirmation',
      modalProps: {
        title: t('Delete Domain?'),
        description: t(
          'The domain "%s" and all its keywords will be permanently deleted.',
          displayName || domain,
        ),
        confirmLabel: t('Delete domain'),
        action: () => handleDeleteDomain(domainObj),
      },
    });
  };

  const fetchData = useFetchData();

  const getActions = (record: PausedDomainFragment) => {
    const { snorlax4: iconColor } = styleVariables;
    return [
      {
        onSelect: () => {
          setSelectedDomain(record);
          handleUnpauseDomain(record);
        },
        label: t('Unpause'),
        fastIcon: <AccFastIcon src={IconPlayerPlay} size={20} color={iconColor} />,
      },
      {
        onSelect: () => showDeleteConfirmation(record),
        label: t('Delete'),
        fastIcon: <AccFastIcon src={IconTrash} size={20} color={iconColor} />,
      },
    ];
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const columns: ColumnType<PausedDomainFragment, {}>[] = [
    {
      id: ColumnIDs.DOMAIN_NAME,
      title: t('Domain Name'),
      flex: true,
      width: 325,
      cellRenderer: ({ record }) => (
        <DomainDetails
          domainId={record.id}
          title={record.displayName || ''}
          domain={record.domain}
          logo={record.faviconUrl || ''}
          isDemoDomain={false}
          reset={true}
          small={false}
          shouldLink={false}
          showNavigateIcons={false}
        />
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: 'domain',
          defaultOrder: TableOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.BELONGS_TO_GROUP,
      title: t('Belongs to Group'),
      flex: true,
      width: 250,
      cellRenderer: ({ record }) => <GroupCell record={record} />,
      onHeaderCell: () => ({
        ordering: {
          orderBy: 'client.name',
          defaultOrder: TableOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.KEYWORDS,
      title: t('Keywords'),
      width: 150,
      cellRenderer: ({ record }) => <KeywordsCell record={record} />,
      onHeaderCell: () => ({
        ordering: {
          orderBy: 'totalKeywords',
          defaultOrder: TableOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.CREATED_AT,
      title: t('Created At'),
      width: 125,
      cellRenderer: ({ record }) => <DateCell date={record.dateAdded} />,
      onHeaderCell: () => ({
        ordering: {
          orderBy: 'dateAdded',
          defaultOrder: TableOrder.ASC,
        },
      }),
    },
    {
      id: ColumnIDs.PAUSED_AT,
      title: t('Paused At'),
      width: 125,
      cellRenderer: ({ record }) => (
        <DateCell
          date={record.pausedChangeDate ? moment(record.pausedChangeDate).format('YYYY-MM-DD') : ''}
        />
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: 'pausedChangeDate',
          defaultOrder: TableOrder.DESC,
        },
      }),
    },
    {
      id: ColumnIDs.ACTIONS,
      title: t('Actions'),
      width: 76,
      cellRenderer: ({ record }) => (
        <ActionsCell shouldUpdateIndicator={record} actions={getActions(record)} />
      ),
      onHeaderCell: () => ({
        ordering: {
          orderBy: ColumnIDs.ACTIONS,
          defaultOrder: TableOrder.DESC,
        },
      }),
    },
  ];

  return (
    <>
      {responseCode === KeywordOveruseCode.UpgradePlanActiveLimit && (
        <UpgradePlanModal defaultOpened />
      )}
      {responseCode === KeywordOveruseCode.UpgradePlanTrackedLimit && (
        <UpgradePlanModal defaultOpened trackedLimit />
      )}

      {responseCode === KeywordOveruseCode.RequestUpgradePlanActiveLimit && (
        <RequestUpgradePlanModal defaultOpened />
      )}
      {responseCode === KeywordOveruseCode.RequestUpgradePlanTrackedLimit && (
        <RequestUpgradePlanModal defaultOpened trackedLimit />
      )}

      {responseCode === KeywordOveruseCode.EnableOveruseActiveLimit && (
        <EnableOveruseModal
          area={EnableOveruseArea.Unpause}
          defaultOpened
          onEnableOveruseSuccess={async () => {
            if (!selectedDomain) {
              toast.error(t('Something went wrong'));
              return;
            }
            await handleUnpauseDomain(selectedDomain);
            setSelectedDomain(null);
          }}
        />
      )}
      {responseCode === KeywordOveruseCode.EnableOveruseTrackedLimit && (
        <EnableOveruseModal
          area={EnableOveruseArea.Unpause}
          defaultOpened
          onEnableOveruseSuccess={async () => {
            if (!selectedDomain) {
              toast.error(t('Something went wrong'));
              return;
            }
            await handleUnpauseDomain(selectedDomain);
            setSelectedDomain(null);
          }}
          trackedLimit
        />
      )}

      {responseCode === KeywordOveruseCode.RequestEnableOveruseActiveLimit && (
        <RequestEnableOveruseModal defaultOpened />
      )}
      {responseCode === KeywordOveruseCode.RequestEnableOveruseTrackedLimit && (
        <RequestEnableOveruseModal defaultOpened trackedLimit />
      )}

      <DataTable
        className={styles.domainsTable}
        dataKey={dataKey.toString()}
        tableId={TableIDs.PAUSED_DOMAINS}
        fetchData={fetchData}
        pagination={true}
        columns={columns}
        viewMode={TableSize.DEFAULT}
        emptyOptions={{
          title: t('No Paused Domains'),
          subTitle: t('There are currently no paused domains.'),
        }}
        offlineFilter={{
          tableName: TableIDs.PAUSED_DOMAINS,
          skipAll: true,
        }}
      />
    </>
  );
};

export default PausedDomainsTable;
